import React from 'react'

import * as Dialog from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

import { Button } from '@brenntag/ui/components/Button'
import { Icon } from '@brenntag/ui/components/Icon'

import { useAppHeader } from '../hooks/useAppHeader'

interface Props {
  children: React.ReactNode
}

export const AppHeaderDrawer: React.FC<Props> = ({ children }) => {
  const { isDrawerOpen, setIsDrawerOpen } = useAppHeader()
  return (
    <div className="lg:hidden">
      <Dialog.Root onOpenChange={() => setIsDrawerOpen(!isDrawerOpen)} open={isDrawerOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/20 data-[state=closed]:animate-overlayHide data-[state=open]:animate-overlayShow" />
          <Dialog.Content className="fixed inset-y-0 left-0 z-10 flex w-80 flex-col gap-6 overflow-auto bg-primary px-4 pb-8 pt-3 shadow-md focus:outline-none data-[state=closed]:animate-contentHide data-[state=open]:animate-contentShow">
            <VisuallyHidden.Root>
              <Dialog.Title>AppHeaderDrawer</Dialog.Title>
              <Dialog.Description>This is the drawer content for navigation.</Dialog.Description>
            </VisuallyHidden.Root>
            <Dialog.Close asChild className="self-end text-white focus:outline-none">
              <Button onClick={() => setIsDrawerOpen(!isDrawerOpen)} size="sm" type="button">
                <Icon name="Close" />
              </Button>
            </Dialog.Close>
            {children}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  )
}
