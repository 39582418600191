import { atom } from 'jotai'

import { SampleFilters } from '@brenntag/connect-apis/api/models/sample'

const sampleFilterDefaults: SampleFilters = {
  page: '1',
  sort: '-number',
} as const

export const sampleFilters = atom<SampleFilters>(sampleFilterDefaults)
