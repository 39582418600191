import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCircleInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m19.74 5.68-1.43 1.43a8 8 0 0 1 1.69 4.9c0 4.41-3.59 8-8 8s-8-3.59-8-8S7.59 4 12 4c1.85 0 3.54.63 4.9 1.69l1.43-1.43A9.96 9.96 0 0 0 12.01 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10c0-2.4-.85-4.6-2.26-6.32"
      fill="currentColor"
    />
    <path d="M11 10v8h2v-8zM13 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="currentColor" />
  </svg>
)
export default SvgCircleInfo
