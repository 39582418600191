import { http, HttpResponse } from 'msw'

import { LinkedAttachmentResponse } from '@brenntag/connect-apis/api-attachment/types'

import { config } from '#src/config.js'

import { attachments } from './data'

export const fetchLinkedAttachment = () => {
  return http.get<{ entityType: string; id: string }, null, LinkedAttachmentResponse>(
    `${config.apiGatewayUrl}/attachments/linked/:entityType/:id`,
    async ({ params }) => {
      return HttpResponse.json({
        attachments: attachments.getLinked(params.id),
      })
    },
  )
}
