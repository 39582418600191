import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSquareAdd = createIcon({
  displayName: 'IconSquareAdd',
  path: [
    <path d="M3 3H21V5H5V19H19V7H21V21H3V3Z" fill="currentColor" key="0" />,
    <path d="M13 13V17H11V13H7V11H11V7H13V11H17V13H13Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
