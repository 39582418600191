import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11 22H9v-7.59l-7-7V3h20v4.41l-7 7V20h-2v-6.41l7-7V5H4v1.59l7 7z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFilter
