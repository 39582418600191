import React, { ErrorInfo } from 'react'

import { useLocation } from 'react-router'

import {
  ForbiddenRequestError,
  NotFoundRequestError,
  UnauthorizedRequestError,
} from '#shared/apis/request.js'
import { Translate, useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { AccessRestricted } from '#shared/pages/AccessRestricted/AccessRestricted.js'
import { ErrorPage, ErrorPageContent } from '#shared/pages/Error/ErrorPage.js'
import { NotFoundPage } from '#shared/pages/NotFound/NotFound.js'
import { captureHandledException } from '#shared/utils/errors.js'

interface Props {
  children: React.ReactNode
  persistError?: boolean
}
/**
 * Whenever this component re-renders, it will create a new key for the InternalErrorBoundary
 * so it's children will re-render. This makes sure that if an error boundary triggers,
 * we can recover from the error by going to a different page for example
 *
 * This boundary catches any errors that are thrown through React Query, unless we handle them
 * with a toast popup instead
 */
export const ErrorBoundary = ({ children, persistError }: Props) => {
  const location = useLocation()
  const environment = import.meta.env.VITE_ENVIRONMENT
  const t = useTranslations()

  return (
    <InternalErrorBoundary
      environment={environment}
      key={persistError ? 1 : location.pathname}
      t={t}>
      {children}
    </InternalErrorBoundary>
  )
}

interface ErrorBoundaryState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any | Error | null
  errorInfo: ErrorInfo | null
  hasError: boolean
}

class InternalErrorBoundary extends React.Component<
  { children: React.ReactNode; environment: string; t: Translate },
  ErrorBoundaryState
> {
  ref: React.MutableRefObject<ErrorBoundaryState | null> =
    React.createRef<ErrorBoundaryState | null>()

  public state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
    hasError: false,
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error,
      hasError: true,
      stack: error.message,
    }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState(state => ({
      ...state,
      error,
      errorInfo,
      hasError: true,
    }))
  }

  public render() {
    const { error, errorInfo, hasError } = this.state
    const { children, environment, t } = this.props

    if (!hasError || !error) {
      // no error here
      return children
    }

    if (error instanceof UnauthorizedRequestError || error instanceof ForbiddenRequestError) {
      return <AccessRestricted />
    }

    if (error instanceof NotFoundRequestError) {
      const fallbackLabel = t('label.browseproducts')
      const fallbackUrl = '/:locale/connect/products'

      return <NotFoundPage fallbackLabel={fallbackLabel} fallbackUrl={fallbackUrl} />
    }

    // eslint-disable-next-line no-console
    console.error(environment, error)
    // Send error to Sentry
    captureHandledException(error)
    // eslint-disable-next-line no-console
    console.trace()

    return (
      <ErrorPage>
        <ErrorPageContent
          error={error}
          errorCode={error?.status || error?.response?.status || 500}
          errorInfo={errorInfo}
          reason={error?.message}
        />
      </ErrorPage>
    )
  }
}
