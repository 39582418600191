// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEquals(obj1: any, obj2: any, visited: Set<any> = new Set()): boolean {
  // Check if both objects are the same reference
  if (obj1 === obj2) {
    return true
  }

  // Check if either object is null or not an object
  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  // Check if either object has been visited before to detect cyclic references
  if (visited.has(obj1) || visited.has(obj2)) {
    return false
  }

  // Add both objects to the visited set
  visited.add(obj1)
  visited.add(obj2)

  // Check if the number of keys differs
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) {
    return false
  }

  // Check deep equality of each key-value pair
  for (const key of keys1) {
    if (!deepEquals(obj1[key], obj2[key], visited)) {
      return false
    }
  }

  // Remove objects from visited set before returning
  visited.delete(obj1)
  visited.delete(obj2)

  return true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function merge(a: any, b: any): any {
  if (a === undefined) return b
  if (b === undefined) return a
  return a !== Object(a) || b !== Object(b)
    ? b
    : Object.keys(b)
        .filter(k => !a[k])
        .reduce(
          (o, k) => {
            o[k] = b[k]
            return o
          },
          Object.keys(a).reduce((o, k) => {
            o[k] = b[k] ? merge(a[k], b[k]) : a[k]
            return o
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }, {} as any),
        )
}

export const groupWith = <T>(fn: (a: T, b: T) => boolean, list: T[]): T[][] => {
  const res = []
  let idx = 0
  const len = list.length
  while (idx < len) {
    let nextidx = idx + 1
    while (nextidx < len && fn(list[nextidx - 1], list[nextidx])) {
      nextidx += 1
    }
    res.push(list.slice(idx, nextidx))
    idx = nextidx
  }
  return res
}

export const filterNullValues = <T>(o: T): Partial<T> => {
  if (!o) return o
  return Object.keys(o).reduce((acc, key) => {
    const value = o[key]
    if (value === null) return acc
    return { ...acc, [key]: value } as T
  }, {} as T)
}

export const filterEmptyValues = <T>(o: T): Partial<T> => {
  if (!o) return o
  return Object.keys(o).reduce((acc, key) => {
    const value = o[key]
    if (
      value === undefined ||
      value === '' ||
      value === null ||
      (Array.isArray(value) && !value.length)
    )
      return acc
    return { ...acc, [key]: value } as T
  }, {} as T)
}

export const omitProperties = <T>(obj: T, keys: string[]): Partial<T> =>
  Object.keys(obj).reduce(
    (acc, key) => (keys.includes(key as string) ? acc : { ...acc, [key]: obj[key] }),
    {} as Partial<T>,
  )
