import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4M21 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgDots
