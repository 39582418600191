import {
  VasSearchResponseContent,
  VasSearchResponseFilters,
  VasSearchResponsePage,
  VasStatus,
  VasType,
} from '@brenntag/connect-apis/api-vas/types'

export interface ServiceFilters {
  createdDateGreaterEqual?: string
  createdDateLessEqual?: string
  page?: string
  relatedTo?: string
  searchQuery?: string
  sort?: string
  status?: string
}

export const ServiceFilter = {
  createdDateGreaterEqual: 'filter[createdDate.gte]',
  createdDateLessEqual: 'filter[createdDate.lte]',
  requiredDateGreaterEqual: 'filter[requiredDate.gte]',
  requiredDateLessEqual: 'filter[requiredDate.lte]',
  searchQuery: 'search[query]',
  sort: 'sort',
  status: 'filter[status.in]',
  type: 'filter[type.in]',
}

export interface Service {
  createdDate?: string
  id: string
  number?: string
  projectedCompletionDate?: string
  requiredDate?: string
  status?: VasStatus
  type?: VasType
}

export interface ServiceDetails {
  accountManagerName?: string
  application?: string
  createdDate?: string
  description?: string
  finishedDate?: string
  id: string
  number: string
  projectedCompletionDate?: string
  requiredDate?: string
  status?: VasStatus
  type?: ServiceType
}

export type ServiceFilterKey = keyof ServiceFilters

export interface ServiceList {
  content: VasSearchResponseContent[]
  filters: VasSearchResponseFilters[]
  page: VasSearchResponsePage
  searchQuery?: string
  sort: string
  sorts: string[]
  totalPages: number
}

export type ServiceType = 'Blending Service' | 'Laboratory Service'
