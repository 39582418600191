import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconArrowRight = createIcon({
  displayName: 'IconArrowRight',
  path: [
    <path
      d="M12.29 19.29L13.71 20.71L22.41 12L13.71 3.28999L12.29 4.70999L19.59 12L12.29 19.29Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M15.76 11L16.76 12L15.76 13H2V11H15.76Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
