import { http, HttpResponse } from 'msw'

import { config } from '#src/config.js'

import { attachments } from './data'

export const downloadAttachment = () => {
  return http.get<{ id: string }, null, string>(
    `${config.apiGatewayUrl}/attachments/:id/download`,
    async ({ params }) => {
      const blob = attachments.getDownload(params.id)
      if (!blob) {
        throw new HttpResponse('Attachment not found', { status: 404 })
      }

      return HttpResponse.json(blob)
    },
  )
}
