import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { buildCart } from './builders/cartBuilder'
import { carts } from './data'

export const deleteCheckout = () => {
  return http.delete<{ cartId: string }, null, APICart>(
    `${config.apiBaseUrl}/api-cart/carts/:cartId/orders`,
    async ({ params }) => {
      // delete current cart for this shipTo
      const cart = buildCart(params.cartId)
      carts[params.cartId] = cart
      return HttpResponse.json(cart, { status: 200 })
    },
  )
}
