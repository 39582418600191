import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconShare = createIcon({
  displayName: 'IconShare',
  path: [
    <path d="M22 11H20V4H13V2H22V11Z" fill="currentColor" key="0" />,
    <path d="M20 20H4V4H9V6H6V18H18V15H20V20Z" fill="currentColor" key="1" />,
    <path d="M16.59 6H18V7.41L10.71 14.71L9.28999 13.29L16.59 6Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
