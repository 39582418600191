import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'
import { PricePoint } from '@brenntag/connect-apis/api/models/price'
import { DataTypePricePoint } from '@brenntag/connect-apis/api/types'

import { PostCartItemParams } from '#shared/apis/api-cart/cart/endpoints/postCartItem.js'
import { getSortedPricePoints, getTotalPrice } from '#shared/utils/price-utils.js'
import { config } from '#src/config.js'

import { products } from '../api/products/data'
import { carts } from './data'

export const postCartItem = () => {
  return http.post<never, PostCartItemParams, APICart>(
    `${config.apiBaseUrl}/api-cart/carts/cart-items`,
    async ({ request }) => {
      const body = await request.json()
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      if (!shipToId) {
        return HttpResponse.json(null, { status: 400 })
      }

      const product = products.list().find(product => product.data.id === body.productId)
      if (!product) {
        return HttpResponse.json(null, { status: 404 })
      }

      const pricePoints: PricePoint[] = (product.included ?? [])
        .filter(include => include.type === DataTypePricePoint.PricePoint)
        .map(pricePoint => ({
          isActive: pricePoint.attributes.isActive,
          price: pricePoint.attributes.price,
          scaleStart: pricePoint.attributes.scaleStart,
        }))

      if (!pricePoints.length) {
        return HttpResponse.json(null, { status: 404 })
      }

      const sortedPricepoints = getSortedPricePoints(pricePoints)

      carts[shipToId].items?.push({
        createdDate: new Date().toISOString(),
        id: Date.now().toString(),
        messagesSummary: {},
        name: product.data.attributes.name,
        productDetails: {
          fields: {},
          name: product.data.attributes.name,
          productId: product.data.id,
          sku: '',
        },
        productId: product.data.id,
        quantity: body.quantity,
        tankLocations: (body.tankLocations ?? []).map(tankLocation => ({
          id: tankLocation.id,
          quantity: parseFloat(tankLocation.quantity),
        })),
        totalPrice: getTotalPrice(body.quantity, sortedPricepoints)?.amount || 0,
        type: 'product',
      })

      return HttpResponse.json(carts[shipToId], { status: 201 })
    },
  )
}
