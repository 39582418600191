import * as React from 'react'
import type { SVGProps } from 'react'
const SvgQuote = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.26 20h.24l.01.01c1.15 0 2.24-.44 3.09-1.24a4.5 4.5 0 0 0 1.4-3.26v-.5c0-1.86-1.28-3.43-3-3.87v-.13c0-.55.45-1 1-1h2v-6H9c-3.86 0-7 3.14-7 7v1h2v-1c0-2.76 2.24-5 5-5v2c-1.65 0-3 1.35-3 3v2h1c1.1 0 2 .9 2 2v.5c0 .69-.27 1.34-.78 1.81s-1.17.72-1.86.68C5.06 17.94 4 16.73 4 15.31v-1.3H2v1.3c0 2.5 1.87 4.56 4.26 4.69M17.26 20h.24l.01.01c1.15 0 2.24-.44 3.09-1.24a4.5 4.5 0 0 0 1.4-3.26v-.5c0-1.86-1.28-3.43-3-3.87v-.13c0-.55.45-1 1-1h2v-6h-2c-3.86 0-7 3.14-7 7v1h2v-1c0-2.76 2.24-5 5-5v2c-1.65 0-3 1.35-3 3v2h1c1.1 0 2 .9 2 2v.5c0 .69-.27 1.34-.78 1.81s-1.17.72-1.86.68c-1.3-.06-2.36-1.27-2.36-2.69v-1.3h-2v1.3c0 2.5 1.87 4.56 4.26 4.69"
      fill="currentColor"
    />
  </svg>
)
export default SvgQuote
