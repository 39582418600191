import { APIOrder } from '@brenntag/connect-apis/api'
import { OrderList } from '@brenntag/connect-apis/api/models/order'
import { ManyIncludedOrderResourceUnion } from '@brenntag/connect-apis/api/types'

import { transformAPIOrdersToOrderList } from '#shared/apis/api/orders/transforms/order.transforms.js'
import { convertFiltersToParams } from '#shared/apis/convertFiltersToParams.js'
import { request } from '#shared/apis/request.js'
import { deserialize } from '#shared/utils/deserialize.js'
import { config } from '#src/config.js'

export const orderListSortKeys = [
  '-lastUpdateDate',
  'lastUpdateDate',
  '-orderCreationDate',
  'orderCreationDate',
] as const

export interface OrderFilters {
  deliveryDateGreaterEqual?: Date
  deliveryDateLessEqual?: Date
  myOrders?: string
  orderDateGreaterEqual?: Date
  orderDateLessEqual?: Date
  salesOrderNumberExists?: string
  searchQuery?: string
  shipToLocationId?: string
  status?: 'Confirmed' | 'Delivered/Invoiced' | 'Entered' | 'Picked' | 'Processing'
  updatedDateGreaterEqual?: Date
  updatedDateLessEqual?: Date
}

export type OrderListSortKey = (typeof orderListSortKeys)[number]

type OrderFilter = keyof OrderFilters

export const orderFilters = {
  deliveryDateGreaterEqual: 'filter[requestedDeliveryDate.gte]',
  deliveryDateLessEqual: 'filter[requestedDeliveryDate.lte]',
  myOrders: 'filter[user.id.equals]',
  orderDateGreaterEqual: 'filter[orderCreationDate.gte]',
  orderDateLessEqual: 'filter[orderCreationDate.lte]',
  salesOrderNumberExists: 'filter[salesOrderNumber.exists]',
  searchQuery: 'search[query]',
  shipToLocationId: 'filter[shipTo.id.equals]',
  status: 'filter[status.in]',
  updatedDateGreaterEqual: 'filter[lastUpdateDate.gte]',
  updatedDateLessEqual: 'filter[lastUpdateDate.lte]',
} satisfies Record<OrderFilter, string>

interface Params {
  filters: OrderFilters
  include: string
  pageNumber: number
  pageSize: number
  sort: OrderListSortKey
}

export const fetchOrders = async ({
  filters,
  include,
  pageNumber,
  pageSize,
  sort,
}: Params): Promise<OrderList> => {
  const response = await request<ManyIncludedOrderResourceUnion>({
    method: 'GET',
    params: {
      include,
      'page[number]': pageNumber,
      'page[size]': pageSize,
      sort,
      ...convertFiltersToParams(orderFilters, filters),
    },
    url: `${config.apiBaseUrl}/api/orders`,
  })

  const apiOrders = deserialize<APIOrder[]>(response?.body)
  const meta = response.body?.meta
  return transformAPIOrdersToOrderList(apiOrders, meta, pageSize)
}
