import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 22h-2.5C9.85 22 2 14.15 2 4.5V2h7.57l3.69 6.16-3.07 3.07c.72.99 1.6 1.87 2.59 2.59l3.07-3.07L22 14.44v3.57h-2v-2.43l-3.85-2.31-3.11 3.11-.67-.42a13.7 13.7 0 0 1-4.32-4.32l-.42-.67 3.11-3.11-2.31-3.84H4v.5c0 8.55 6.95 15.5 15.5 15.5H22v2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPhone
