import React, { forwardRef } from 'react'

import { Tab, TabProps } from '@chakra-ui/react'

interface TabWithCountProps extends TabProps {
  count: number
}

export const TabWithCount = forwardRef<HTMLButtonElement, TabWithCountProps>(
  ({ children, count, ...props }, ref) => {
    return (
      <Tab fontWeight="normal" isDisabled={count === 0} {...props} ref={ref} whiteSpace="nowrap">
        {children}
        {<>&nbsp;({count})</>}
      </Tab>
    )
  },
)

TabWithCount.displayName = 'TabWithCount'
