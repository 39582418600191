import { CaseList, CaseListItem } from '@brenntag/connect-apis/api-case/models/case'
import {
  CaseSearchRequest,
  CaseSearchRequestFilter,
  CaseSearchResponse,
  CaseSearchResponseContent,
} from '@brenntag/connect-apis/api-case/types'

import {
  transformParamsToCaseFilter,
  transformSubject,
} from '#shared/apis/api-case/cases/transform/case.transforms.js'
import { request } from '#shared/apis/request.js'
import { sanitizeHtml } from '#shared/apis/sanitizeHtml.js'
import { generateTotalPages } from '#shared/utils/pagination.js'
import { config } from '#src/config.js'

interface FetchCasesParams {
  filters: CaseSearchRequestFilter[]
  pageNumber: number
  pageSize: number
  searchQuery: string
  sort?: string
}

const transformCase = (apiCase: CaseSearchResponseContent): CaseListItem => ({
  ...apiCase,
  description: apiCase.summary && sanitizeHtml(apiCase.summary),
  subject: transformSubject(apiCase.subject, apiCase.summary),
})
export const fetchCases = async ({
  filters = [],
  pageNumber,
  pageSize,
  searchQuery = '',
  sort,
}: FetchCasesParams): Promise<CaseList> => {
  const response = await request<CaseSearchResponse>({
    body: {
      filters: filters,
      page: {
        number: pageNumber,
        size: pageSize,
      },
      searchQuery,
      sort,
    } satisfies CaseSearchRequest,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: `${config.apiGatewayUrl}/cases/search`,
  })

  return {
    ...response.body,
    content: response.body.content.map(transformCase),
    filters: transformParamsToCaseFilter(response.body.filters),
    totalPages: generateTotalPages(response.body.page.total, pageSize),
  }
}
