import { Attachment } from '#api-object/models/attachment.js'

import { CustomerShipToLocation } from './location'
import { Amount, MonetaryAmount } from './price'
import { Product } from './product'

export const validQuoteFilters = {
  page: '',
  requestBy: '',
  requestDateGreaterEqual: '',
  requestDateLessEqual: '',
  searchQuery: '',
  shipToLocationId: '',
  sort: '',
  status: '',
}

export interface Quote {
  attachments: Attachment[]
  commentFromUser: string | undefined
  creationDate: Date | undefined
  id: string
  incoTerms: string | undefined
  number: string | undefined
  quoteLines: QuoteLine[]
  requestedBy: string
  requestedDeliveryDate: Date | undefined
  shipTo?: CustomerShipToLocation
  status: QuoteStatus
  total?: MonetaryAmount
}

export type QuoteFilterKey = keyof QuoteFilters

export interface QuoteFilters {
  page?: string
  requestBy?: string
  requestDateGreaterEqual?: string
  requestDateLessEqual?: string
  searchQuery?: string
  shipToLocationId?: string
  sort?: string
  status?: string
}

export interface QuoteLine {
  allowAddToCart: boolean
  allowRequote: boolean
  annualConsumption: Amount | undefined
  id: string
  isExpired: boolean
  product: Product
  quantity: number | undefined
  quoteStatus: QuoteStatus
  totalPrice: MonetaryAmount | undefined
}

export interface QuoteList {
  quotes: Quote[]
  total: number
  totalPages: number
}

export type QuoteStatus = 'Declined' | 'Done' | 'Processing'
