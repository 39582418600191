import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconServer = createIcon({
  displayName: 'IconServer',
  path: [
    <path d="M3 21H21V15H19V19H5V13H21V3H3V9H5V5H19V11H3V21Z" fill="currentColor" key="0" />,
    <path
      d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M8 17C8.55228 17 9 16.5523 9 16C9 15.4477 8.55228 15 8 15C7.44772 15 7 15.4477 7 16C7 16.5523 7.44772 17 8 17Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
