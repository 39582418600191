import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m13 15.76-1 1-1-1V2h2z" fill="currentColor" />
    <path d="m3.29 13.71 8.71 8.7 8.71-8.7-1.42-1.42-7.29 7.3-7.29-7.3z" fill="currentColor" />
  </svg>
)
export default SvgArrowDown
