import { useIdleTimer } from 'react-idle-timer'

interface UseIdleTimeoutParams {
  onIdle: () => void
}

export const useIdleTimeout = ({ onIdle }: UseIdleTimeoutParams) => {
  useIdleTimer({
    crossTab: true,
    onIdle,
    timeout: 1000 * 60 * 60 * 2,
  })
}
