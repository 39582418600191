import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17 6V2H7v4H3v16h18V6zm2 14H5V8h2v3h2V8h2V6H9V4h6v2h-2v2h2v3h2V8h2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgShop
