import { http, HttpResponse } from 'msw'

import { MeResponse } from '@brenntag/connect-apis/api-user/types'

import { config } from '#src/config.js'

import { users } from './data/me'

export const fetchMe = () => {
  return http.get<never, null, MeResponse>(`${config.apiBaseUrl}/api-user/me`, async () => {
    return HttpResponse.json(users.items.customer)
  })
}
