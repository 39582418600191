import { http, HttpResponse } from 'msw'

import { APICheckout } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { checkouts } from './data'

export const patchCheckout = () => {
  return http.patch<{ orderId: string }, never, APICheckout>(
    `${config.apiBaseUrl}/api-cart/orders/:orderId`,
    async ({ params }) => {
      const checkout = checkouts[params.orderId]
      if (!checkout) {
        return HttpResponse.json(null, { status: 404 })
      }
      return HttpResponse.json(checkout, { status: 200 })
    },
  )
}
