import React, { useEffect } from 'react'

interface KeyPressParams {
  ctrlKey?: boolean
  key: string
  metaKey?: boolean
  shiftKey?: boolean
}

const keyPressListeners: ((e: KeyboardEvent) => void)[] = []

window.addEventListener('keydown', e => keyPressListeners.forEach(listener => listener(e)))
window.addEventListener('keyup', e => keyPressListeners.forEach(listener => listener(e)))

export const useKeyPress = ({ ctrlKey, key, metaKey, shiftKey }: KeyPressParams) => {
  const [pressed, setPressed] = React.useState(false)

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (
        e.key === key &&
        Boolean(e.ctrlKey) === Boolean(ctrlKey) &&
        Boolean(e.metaKey) === Boolean(metaKey) &&
        Boolean(e.shiftKey) === Boolean(shiftKey)
      ) {
        setPressed(e.type === 'keydown')
      }
    }
    keyPressListeners.push(onKeyPress)
    return () => {
      keyPressListeners.splice(keyPressListeners.indexOf(onKeyPress), 1)
    }
  }, [ctrlKey, key, metaKey, shiftKey])
  return pressed
}
