import {
  APIBillToLocation,
  APILocationKey,
  APIShipToLocation,
  APISoldToLocation,
  isAPILocation,
} from '@brenntag/connect-apis/api'
import { transformAPIContactToContact } from '@brenntag/connect-apis/api/models/contact'
import {
  Address,
  CustomerBillToLocation,
  CustomerLocationKey,
  CustomerShipToLocation,
  CustomerSoldToLocation,
} from '@brenntag/connect-apis/api/models/location'

type LocationType = 'bill-to' | 'ship-to' | 'sold-to'

const transformAPIAddressToAddress = (address: Address): Address => ({
  city: address.city,
  country: address.country,
  postalCode: address.postalCode,
  state: address.state,
  street: address.street,
})

export function transformAPILocationToCustomerLocation(
  apiLocation: APIShipToLocation,
): CustomerShipToLocation
export function transformAPILocationToCustomerLocation(
  apiLocation: APISoldToLocation,
): CustomerSoldToLocation
export function transformAPILocationToCustomerLocation(
  apiLocation: APIBillToLocation,
): CustomerBillToLocation
export function transformAPILocationToCustomerLocation(
  apiLocation: APILocationKey,
): CustomerLocationKey

export function transformAPILocationToCustomerLocation(
  apiLocation: APIShipToLocation | undefined,
): CustomerShipToLocation | undefined
export function transformAPILocationToCustomerLocation(
  apiLocation: APISoldToLocation | undefined,
): CustomerSoldToLocation | undefined
export function transformAPILocationToCustomerLocation(
  apiLocation: APIBillToLocation | undefined,
): CustomerBillToLocation | undefined

export function transformAPILocationToCustomerLocation(
  apiLocation: APILocationKey | APIShipToLocation | undefined,
): CustomerLocationKey | CustomerShipToLocation | undefined
export function transformAPILocationToCustomerLocation(
  apiLocation: APILocationKey | APISoldToLocation | undefined,
): CustomerLocationKey | CustomerSoldToLocation | undefined
export function transformAPILocationToCustomerLocation(
  apiLocation: APIBillToLocation | APILocationKey | undefined,
): CustomerBillToLocation | CustomerLocationKey | undefined

export function transformAPILocationToCustomerLocation(apiLocation: undefined): undefined
export function transformAPILocationToCustomerLocation(
  apiLocation:
    | APIBillToLocation
    | APILocationKey
    | APIShipToLocation
    | APISoldToLocation
    | undefined,
):
  | CustomerBillToLocation
  | CustomerLocationKey
  | CustomerShipToLocation
  | CustomerSoldToLocation
  | undefined {
  if (apiLocation === undefined) return undefined

  const locationType: LocationType = apiLocation.type

  if (!isAPILocation(apiLocation)) {
    return apiLocation
      ? ({ id: apiLocation.id, type: apiLocation.type } satisfies CustomerLocationKey)
      : undefined
  }
  if (locationType === 'ship-to' && apiLocation.name !== undefined) {
    return {
      address: apiLocation.address && transformAPIAddressToAddress(apiLocation.address),
      allowPromotions: Boolean(apiLocation.allowPromotions),
      contacts: apiLocation.contacts?.map(transformAPIContactToContact),
      id: apiLocation.id,
      isActive: apiLocation.isActive,
      name: apiLocation.name,
      showPrices: !apiLocation.hidePrices,
      type: locationType,
    } satisfies CustomerShipToLocation
  } else if (locationType === 'bill-to' && apiLocation.name !== undefined) {
    return {
      address: apiLocation.address && transformAPIAddressToAddress(apiLocation.address),
      allowPromotions: Boolean(apiLocation.allowPromotions),
      id: apiLocation.id,
      isActive: apiLocation.isActive,
      name: apiLocation.name,
      showPrices: !apiLocation.hidePrices,
      type: locationType,
    } satisfies CustomerBillToLocation
  } else if (locationType === 'sold-to' && apiLocation.name !== undefined) {
    return {
      address: apiLocation.address && transformAPIAddressToAddress(apiLocation.address),
      allowPromotions: Boolean(apiLocation.allowPromotions),
      id: apiLocation.id,
      isActive: apiLocation.isActive,
      name: apiLocation.name,
      partnerNumber: apiLocation.partnerNumber,
      showPrices: !apiLocation.hidePrices,
      type: locationType,
    } satisfies CustomerSoldToLocation
  }
  throw new Error(
    `Unable to transform location with id ${String(apiLocation?.id)} and type ${String(
      locationType,
    )}`,
  )
}
