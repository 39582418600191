import React from 'react'

import { chakra, IconProps, SpinnerProps, useTheme } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'

import {
  FullScreenStyleProps,
  FullscreenWrapper,
} from '#shared/components/Loader/FullscreenWrapper.js'

type FullScreenLoaderRoundProps = FullScreenStyleProps & LoaderRoundProps

export const FullScreenLoaderRoundBlocking: React.FC<
  Omit<FullScreenLoaderRoundProps, 'blocking'>
> = ({ opacity = 0.2, ...rest }) => (
  <FullscreenWrapper data-testid="loader" isBlocking opacity={opacity}>
    <LoaderRound {...rest} size="md" />
  </FullscreenWrapper>
)

export const FullScreenLoaderRound: React.FC<FullScreenLoaderRoundProps> = props => (
  <LoaderRound {...props} data-testid="loader" left="50vw" position="fixed" size="md" top="50vh" />
)

interface LoaderRoundProps extends IconProps, Pick<SpinnerProps, 'colorScheme' | 'size'> {
  thickness?: number
}
const loaderSizes = {
  lg: 96,
  md: 48,
  sm: 24,
  xl: 160,
  xs: 12,
}

const rotation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`
const LoaderSvg = chakra('svg')

export const LoaderRound: React.FC<LoaderRoundProps> = ({
  colorScheme,
  size = 'sm',
  thickness: t = 3,
  ...props
}) => {
  const theme = useTheme()
  const color80 = colorScheme !== undefined ? theme.colors[colorScheme]?.['80'] : undefined
  const color20 = colorScheme !== undefined ? theme.colors[colorScheme]?.['20'] : undefined

  const s = loaderSizes[size as keyof typeof loaderSizes]
  const colorStart = colorScheme ? color80 : theme.colors.violet[50]
  const colorStop = colorScheme ? color80 : theme.colors.blue[50]
  const colorBack = colorScheme ? color20 : theme.colors.neutral[20]

  return (
    <LoaderSvg
      animation={`${rotation} linear 1s infinite`}
      fill="none"
      height={`${s}px`}
      transformOrigin="center"
      viewBox={`0 0 ${s} ${s}`}
      width={`${s}px`}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        height={s - 2 * t}
        rx={s / 2 - t}
        stroke={colorBack}
        strokeWidth={t}
        width={s - 2 * t}
        x={t}
        y={t}
      />
      <path
        d={`M${s / 2} ${t} A${s / 2 - t} ${s / 2 - t} 0 1 1 ${t} ${s / 2}`}
        stroke={colorScheme ? colorStart : 'url(#linear_gradient_loader)'}
        strokeWidth={t}
      />
      {!colorScheme && (
        <defs>
          <linearGradient
            gradientTransform={`rotate(-45 ${s / 2} ${s / 2})`}
            gradientUnits="userSpaceOnUse"
            id="linear_gradient_loader">
            <stop stopColor={colorStart} />
            <stop offset="1" stopColor={colorStop} />
          </linearGradient>
        </defs>
      )}
    </LoaderSvg>
  )
}
