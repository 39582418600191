import { APIDocument } from '@brenntag/connect-apis/api'
import { Document } from '@brenntag/connect-apis/api/models/document'

export const transformAPIDocumentToDocument = (apiDocument: APIDocument): Document => ({
  createdAt: apiDocument.createdAt,
  id: apiDocument.id,
  isActive: apiDocument.isActive,
  producer: apiDocument.producer,
  type: apiDocument.type,
  updatedAt: apiDocument.updatedAt,
})
