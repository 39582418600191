import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUntrash = createIcon({
  displayName: 'IconUntrash',
  path: [
    <path d="M13 4V2H11V4H3V6H21V4H13Z" fill="currentColor" key="0" />,
    <path d="M19 22H5V8H7V20H17V8H19V22Z" fill="currentColor" key="1" />,
    <path
      d="M13.7891 9.79004L15.2091 11.21L13.4091 13L15.2091 14.79L13.7891 16.21L11.9991 14.41L10.2091 16.21L8.78906 14.79L10.5891 13L8.78906 11.21L10.2091 9.79004L11.9991 11.59L13.7891 9.79004Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
