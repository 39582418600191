import { useEffect } from 'react'

import { getUnixTimestamp, refreshPage } from '#shared/utils/misc.js'

const LOGOUT_KEY = 'logoutTimestamp'

const listenForVisibility = (callback: () => void) => {
  document.addEventListener(
    'visibilitychange',
    () => {
      if (document.visibilityState === 'visible') callback()
    },
    { capture: true },
  )
}

// Do not reload inactive tabs — it will screw login session which is already opened in an active tab
const refreshWhenVisible = () => {
  if (document.visibilityState === 'visible') {
    refreshPage()
  } else {
    listenForVisibility(refreshPage)
  }
}

const listenForStorageUpdates = (openedAt: number) => {
  window.addEventListener('storage', event => {
    if (event.key === LOGOUT_KEY && event.newValue && openedAt < parseInt(event.newValue)) {
      refreshWhenVisible()
    }
  })
}

export function setLogoutTimestamp() {
  localStorage.setItem(LOGOUT_KEY, `${getUnixTimestamp()}`)
}

export function useReloadIfLoggedOut() {
  useEffect(() => {
    const openedAt = getUnixTimestamp()
    listenForStorageUpdates(openedAt)
  }, [])
}
