/* tslint:disable */
/* eslint-disable */
/**
 * DigiB Core Read API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum IncoTermsType {
    Exw = 'EXW',
    Fca = 'FCA',
    Cpt = 'CPT',
    Cip = 'CIP',
    Dat = 'DAT',
    Dap = 'DAP',
    Ddp = 'DDP',
    Fas = 'FAS',
    Fob = 'FOB',
    Cfr = 'CFR',
    Cif = 'CIF'
}


