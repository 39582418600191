import React, { FC } from 'react'

import { Box, chakra, Flex, Stack } from '@chakra-ui/react'

import { OrderLine, OrderList } from '@brenntag/connect-apis/api/models/order'

import { ResultItemTitle } from '#shared/components/GlobalSearch/ResultItem.js'
import { ItemSearchResult } from '#shared/components/GlobalSearch/SearchResults/ItemSearchResult.js'
import { TextHighlight } from '#shared/components/Highlight/Highlight.js'
import { getOrderStatusStyles } from '#shared/components/OrderStatus/getOrderStatusStyles.js'
import { Pill } from '#shared/components/Pill/Pill.js'
import { NoWrap } from '#shared/components/Typography/Typography.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface OrderSearchResultProps {
  onItemClick: VoidFunction
  onViewMoreClick: VoidFunction
  orderList: OrderList
  search: string
}

const OrderLineProductSearchResult: FC<{ orderLines: OrderLine[]; search: string }> = ({
  orderLines,
  search,
}) => {
  const t = useTranslations()
  const searchRegExp = new RegExp(search, 'i')
  const matched: OrderLine[] = []
  const unmatched: OrderLine[] = []

  for (const orderLine of orderLines) {
    if (matched.length < 4 && searchRegExp.test(orderLine.product.name)) {
      matched.push(orderLine)
    } else {
      unmatched.push(orderLine)
    }
  }
  return (
    <>
      <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        <TextHighlight search={search}>
          {matched.reduce(
            (result, orderLine, index) =>
              `${result}${orderLine.product.name}${
                matched.length > 1 && index < matched.length - 1 ? ', ' : ''
              }`,
            '',
          )}
        </TextHighlight>
      </Box>
      <Box>
        {unmatched.length > 0 && (
          <Pill
            color="white"
            colorScheme="neutral"
            fontWeight="600"
            mt="1px"
            size="md"
            variant="chip-solid">
            {`+${unmatched.length} ${t('label.products')}`}
          </Pill>
        )}
      </Box>
    </>
  )
}

export const OrderSearchResult: FC<OrderSearchResultProps> = ({
  onItemClick,
  onViewMoreClick,
  orderList: { orders, total },
  search,
}) => {
  const t = useTranslations()

  return (
    <ItemSearchResult
      items={orders}
      linkToDetails
      onItemClick={onItemClick}
      onViewMoreClick={onViewMoreClick}
      to="/:locale/connect/orders"
      total={total}
      type="orders">
      {order => {
        const status = getOrderStatusStyles(t, order.status)
        return (
          <Stack fontSize="12px" spacing={1}>
            <ResultItemTitle
              label={t('label.orderNumber')}
              search={search}
              status={status}
              value={order.salesOrderNumber || '-'}
            />
            {order.purchaseOrderNumber && (
              <Flex gridGap={2} wrap="wrap">
                <NoWrap>{t('label.customerponumber')}:</NoWrap>
                <chakra.span fontWeight={600}>
                  <TextHighlight search={search}>{order.purchaseOrderNumber || ''}</TextHighlight>
                </chakra.span>
              </Flex>
            )}
            <OrderLineProductSearchResult orderLines={order.orderLines} search={search} />
          </Stack>
        )
      }}
    </ItemSearchResult>
  )
}
