import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPieChart = createIcon({
  displayName: 'IconPieChart',
  path: [
    <path
      d="M13 4.06V11H21.95C21.98 11.33 22 11.66 22 12V13H11V2H12C16.47 2 20.26 4.95 21.54 9H19.39C18.32 6.37 15.91 4.42 13 4.06Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M12 20C15.35 20 18.22 17.93 19.41 15H21.54C20.26 19.05 16.47 22 12 22C6.49 22 2 17.51 2 12C2 7.52996 4.95 3.73996 9 2.45996V4.58996C6.07 5.77996 4 8.64996 4 12C4 16.41 7.59 20 12 20Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
