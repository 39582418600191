import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDocumentation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M8 2h8v2H8zM8 6h8v2H8zM14 14h-4v2h4z" fill="currentColor" />
    <path d="M20 12H6v8h12v-6h2v8H4V4h2v6h12V4h2z" fill="currentColor" />
  </svg>
)
export default SvgDocumentation
