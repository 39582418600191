import { http, HttpResponse } from 'msw'

import {
  DataTypePricebook,
  DataTypeProduct,
  ManyIncludedDocumentResourceProductResource,
  PricebookResource,
  ProductResource,
  SingleIncludedDocumentResourceProductResource,
} from '@brenntag/connect-apis/api/types'
import { buildDocuments } from '@brenntag/legacy-mock-generators/generators/documents/buildDocuments.js'

import {
  documentFilters,
  DocumentListSortKey,
} from '#shared/apis/api/documents/endpoints/fetchDocuments.js'
import { config } from '#src/config.js'

import { documents } from './data/documents'

export const fetchDocuments = () => {
  return http.get<never, null, ManyIncludedDocumentResourceProductResource>(
    `${config.apiBaseUrl}/api/documents`,
    async ({ request }) => {
      let list = documents.list()
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get(documentFilters.shipToLocationId)
      if (shipToId) {
        list = list.filter(document => {
          // Builder adds Pricebooks also to this object, so we can safely get it if it exists
          const included = document.included as (PricebookResource | ProductResource)[]
          return included.some(
            include =>
              include.type === DataTypePricebook.Pricebook &&
              include.relationships?.locations?.data.some(key => key.id === shipToId),
          )
        })
      }

      const searchQuery = searchParams.get(documentFilters.searchQuery)
      if (searchQuery) {
        list = list.filter(document =>
          document.included?.some(
            include =>
              include.type === DataTypeProduct.Product &&
              include.attributes.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
          ),
        )
      }

      const sort = searchParams.get('sort') as DocumentListSortKey
      list = sortDocuments(list, sort)

      return HttpResponse.json(
        buildDocuments({
          documents: list,
        }),
      )
    },
  )
}

const sortDocuments = (
  productsList: SingleIncludedDocumentResourceProductResource[],
  sort: DocumentListSortKey,
): SingleIncludedDocumentResourceProductResource[] => {
  const isDescending = sort.startsWith('-')
  switch (sort) {
    case '-productName':
    case 'productName':
      return productsList.sort((a, b) => {
        const aVal = a.included?.find(product => product)?.attributes.name || ''
        const bVal = b.included?.find(product => product)?.attributes.name || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    default:
      return productsList
  }
}
