import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSquareDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M3 3h18v2H5v14h14V7h2v14H3z" fill="currentColor" />
    <path
      d="m12 10.59 3.29-3.3 1.42 1.42-3.3 3.29 3.3 3.29-1.42 1.42-3.29-3.3-3.29 3.3-1.42-1.42 3.3-3.29-3.3-3.29 1.42-1.42z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSquareDelete
