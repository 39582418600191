import React, { FC } from 'react'

import { Flex, HStack, Text, VStack } from '@chakra-ui/react'

import { Country } from '#lib/countries/getCountries.js'
import { useLocale } from '#lib/countries/useLocale.js'
import { CountryLanguageSelectorOption } from '#shared/components/CountryLanguageSelector/types/types.js'
import { Flag } from '#shared/components/Flag/Flag.js'
import { translateCountryInLanguage } from '#shared/locale/hooks/useIntl.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

import { Option } from './Option'

interface Props {
  onOptionSelect?: (selected: CountryLanguageSelectorOption) => void
  options: Country[]
  selectedOption?: CountryLanguageSelectorOption
}

export const OptionsList: FC<Props> = ({ onOptionSelect, options, selectedOption }) => {
  const t = useTranslations()
  const { languageCode } = useLocale()

  return (
    <VStack alignItems="flex-start" overflowY="scroll" spacing={2} w="100%">
      {options.length === 0 && (
        <Text fontSize="12px" fontWeight={400} lineHeight="16px" py={2}>
          {t('countryLanguageSelector.noResults')}
        </Text>
      )}
      {options.map(country => {
        const isActiveCountry = selectedOption?.countryCode === country.code

        const translatedCountryInSelectedLanguage = translateCountryInLanguage({
          countryCode: country.code,
          languageCode: languageCode || country.defaultLanguage,
        })

        const translatedCountryInDefaultLanguage = translateCountryInLanguage({
          countryCode: country.code,
          languageCode: country.defaultLanguage,
        })

        const showDefaultCountryTranslation =
          translatedCountryInSelectedLanguage !== translatedCountryInDefaultLanguage

        return (
          <VStack
            alignItems="flex-start"
            backgroundColor={isActiveCountry ? 'blue.10' : 'white'}
            border="1px solid"
            borderColor={isActiveCountry ? 'blue.10' : 'neutral.20'}
            borderRadius={4}
            key={country.code}
            p={3}
            spacing={5}
            w="100%">
            <HStack spacing={2}>
              <Flag countryCode={country.code} rounded size={6} />
              <Text fontSize="16px" fontWeight={400} lineHeight="24px">
                {translatedCountryInSelectedLanguage}{' '}
                {showDefaultCountryTranslation && `(${translatedCountryInDefaultLanguage})`}
              </Text>
            </HStack>
            <Flex direction="column">
              <Text fontSize="12px" fontWeight={400} lineHeight="16px" pb={3}>
                {t('countryLanguageSelector.chooseYourLanguage')}
              </Text>
              <HStack spacing={2}>
                {country.languageCodes.map(languageCode => {
                  const isActiveOption =
                    selectedOption?.countryCode === country.code &&
                    selectedOption.languageCode === languageCode

                  return (
                    <Option
                      isActive={isActiveOption}
                      key={`${country.code}-${languageCode}`}
                      onOptionSelect={selected => {
                        onOptionSelect?.(selected)
                      }}
                      option={{ countryCode: country.code, languageCode }}
                    />
                  )
                })}
              </HStack>
            </Flex>
          </VStack>
        )
      })}
    </VStack>
  )
}
