import { atom } from 'jotai'

import { ProductFilters } from '@brenntag/connect-apis/api/models/product'

const productFilterDefaults: ProductFilters = {
  page: '1',
  sort: 'featured',
} as const

export const productFilters = atom<ProductFilters>(productFilterDefaults)
