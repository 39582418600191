import * as React from 'react'
import type { SVGProps } from 'react'
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.298 3.288 3.296 19.29l1.414 1.415L20.712 4.703zM4.71 3.291 3.296 4.706l5.876 5.876 1.414-1.415zM13.408 14.826l1.414-1.415 5.876 5.876-1.414 1.415z"
      fill="currentColor"
    />
  </svg>
)
export default SvgClose
