import React from 'react'

import { Box, BoxProps } from '@chakra-ui/react'

interface HorizontalDivider extends BoxProps {
  outside?: (number | string | undefined)[] | number | string
}
export const HorizontalDivider: React.FC<HorizontalDivider> = ({ outside = 0, ...props }) => {
  const outsideArray = Array.isArray(outside) ? outside : [outside]
  const mx = outsideArray
    .map(value => (typeof value === 'number' ? `${4 * value}px` : value))
    .map(px => (px ? `-${px}` : 0))
  return <Box as="hr" bgColor="neutral.20" gridColumn="1 / -1" mx={mx} my={4} {...props} />
}
