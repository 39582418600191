import React, { ReactNode } from 'react'

import { Button } from '@brenntag/ui/components/Button'
import { Icon } from '@brenntag/ui/components/Icon'
import { cn } from '@brenntag/ui/utils/cn'

import { AppLink } from '#components/AppLink/AppLink.js'
import { useFetchCart } from '#shared/apis/api-cart/cart/hooks/useFetchCart.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

const Circle = ({ children, className }: { children: ReactNode; className: string }) => (
  <div
    className={cn(
      'absolute right-1 top-1.5 inline-flex min-h-4 min-w-4 items-center justify-center rounded-full px-1 text-[10px] font-medium text-white',
      className,
    )}
    data-testid="cart-count">
    {children}
  </div>
)

export const ShoppingCartButton: React.FC = () => {
  const t = useTranslations()

  const { data: cart, isError } = useFetchCart()
  const cartCount = cart?.items?.length || 0
  const showCartCount = !isError && cartCount > 0

  return (
    <AppLink className="relative whitespace-nowrap" to={'/:locale/connect/cart'}>
      <Button aria-label={t('label.shoppingcart')} data-testid="header-cart-link" variant="text">
        <Icon data-testid="header-cart-icon" name="Cart" size="md" />
      </Button>
      {isError && <Circle className="bg-red-60">!</Circle>}

      {showCartCount && (
        <Circle className="bg-gradient-to-r from-violet-50 to-blue-50">{cartCount}</Circle>
      )}
    </AppLink>
  )
}
