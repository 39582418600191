import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOrderProcessed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11 14a1 1 0 1 1 2 0 1 1 0 0 1-2 0m-3 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0m12-7.414V22H4V2h7v2H6v16h12V9h-5V2h2.414zM17.586 7 15 4.414V7zM16 14a1 1 0 1 0-2 0 1 1 0 0 0 2 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgOrderProcessed
