/**
 * Access control and feature toggling are usually handled from different systems like GTM, Auth providers, Backend API logic,
 * AB test providers, feature toggle providers like Launch Darkly and even our own environment variables, start commands and
 * custom headers. With the below setup we essentially centralize all of these entry points into one list of features where
 * each feature has a function to determine if it should be on or off
 *
 * This has a gigantic positive impact on the complexity of our codebase, because now we can simply refer to a feature
 * using simple hooks, HoCs and helper functions instead of manually having to create conditions and exceptions throughout our
 * entire codebase. Imagine this:
 *
 * <CheckoutButton feature="checkout">
 *
 * vs
 *
 * {user.isAuthenticated && cart.items.length > 0 && user.accessLevel = 'full' && (
 *   <CheckoutButton>
 * )}
 */
import { features as userFeatures } from '#src/features.config.js'

export const features = userFeatures

export type FeatureName = keyof typeof userFeatures
