import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTruckWithGradient = createIcon({
  displayName: 'IconTruck',
  path: [
    <path
      d="M19.54 7H16V5H2V7H14V16H8.22C7.67 15.39 6.88 15 6 15C5.23 15 4.53 15.3 4 15.78V9H2V18H3C3 19.65 4.35 21 6 21C7.65 21 9 19.65 9 18H15C15 19.65 16.35 21 18 21C19.65 21 21 19.65 21 18H22V10.7L19.54 7ZM6 19C5.45 19 5 18.55 5 18C5 17.45 5.45 17 6 17C6.55 17 7 17.45 7 18C7 18.55 6.55 19 6 19ZM18.46 9L20 11.3V15.78C19.47 15.3 18.77 15 18 15C17.23 15 16.53 15.3 16 15.78V9H18.46ZM18 19C17.45 19 17 18.55 17 18C17 17.45 17.45 17 18 17C18.55 17 19 17.45 19 18C19 18.55 18.55 19 18 19Z"
      fill="url(#paint0_linear_1092_5215)"
      key="0"
    />,
    <defs key={1}>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1092_5215"
        x1="7"
        x2="42.99"
        y1="48"
        y2="12.01">
        <stop stopColor="#C815E0" />
        <stop offset="1" stopColor="#0C72ED" />
      </linearGradient>
    </defs>,
  ],
  viewBox: '0 0 24 24',
})
