import * as React from 'react'
import type { SVGProps } from 'react'
const SvgStore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 12V5.59L18.41 2h-2.82L20 6.41V10h-2V7h-2v3h-3V7h-2v3H8V7H6v3H4V6.41L6.41 4h8.35l-2-2H5.59L2 5.59V12h1v9h2v-9h4v9h12v-7h-2v5h-8v-7z"
      fill="currentColor"
    />
  </svg>
)
export default SvgStore
