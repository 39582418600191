import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconBriefcase = createIcon({
  displayName: 'IconBriefcase',
  path: [
    <path
      d="M7 9H22V7H17V3H7V7H2V21H22V11H20V19H4V9H7ZM9 5H15V7H9V5Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
