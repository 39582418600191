import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M11 3h2v8h8v2h-8v8h-2v-8H3v-2h8z" fill="currentColor" />
  </svg>
)
export default SvgAdd
