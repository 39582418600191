import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const localStorageAtom = atomWithStorage<Record<string, Record<number, unknown>>>('connect', {})

interface Props {
  key: string
  version: number
}

type UseLocalStorageResult<TGetValue, TSetValue> = [TGetValue, (value: TSetValue) => void]

export function useLocalStorage<T>({ key, version }: Props): UseLocalStorageResult<T | undefined, T>
export function useLocalStorage<T>({
  defaultValue,
  key,
  version,
}: Props & { defaultValue: T }): UseLocalStorageResult<T, T>
export function useLocalStorage<T>({
  defaultValue,
  key,
  version,
}: Props & { defaultValue?: T }): UseLocalStorageResult<T | undefined, T> {
  const [localStorage, setLocalStorage] = useAtom(localStorageAtom)
  const value = localStorage[key]?.[version] as T | undefined

  const returnValue = value !== undefined ? value : defaultValue
  const setValue = (value: T | undefined) => {
    setLocalStorage(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        [version]: value,
      },
    }))
  }

  return [returnValue, setValue]
}
