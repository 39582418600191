import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M20 22h-7v-2h5V4H6v16h5v2H4V2h16z" fill="currentColor" />
    <path
      d="M12 10c-2.21 0-4 1.79-4 4 0 1.86 1.28 3.41 3 3.86v-2.14A2 2 0 0 1 10 14c0-1.1.9-2 2-2s2 .9 2 2a2 2 0 0 1-1 1.72v2.14c1.72-.45 3-2 3-3.86 0-2.21-1.79-4-4-4M13 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgSpeaker
