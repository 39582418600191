import * as React from 'react'
import type { SVGProps } from 'react'
const SvgContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M4 22h17V2H4v2h15v16H4z" fill="currentColor" />
    <path d="M3 15h3v2H3zM6 7H3v2h3zM3 11h3v2H3z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M12 12c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3m0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path d="M14 17h2v-4H8v4h2v-2h4z" fill="currentColor" />
  </svg>
)
export default SvgContacts
