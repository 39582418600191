import { SingleIncludedOrderResourceUnion } from '@brenntag/connect-apis/api/types'

import { deliveredInvoiced } from './deliveredInvoiced'
import { entered } from './entered'
import { multipleOrderLines } from './multipleOrderLines'
import { picked } from './picked'
import { processing } from './processing'
import { withEstimatedDeliveryDate } from './withEstimatedDeliveryDate'
import { withMultipleAttachments } from './withMultipleAttachments'
import { withSingleAttachment } from './withSingleAttachment'

const items = {
  deliveredInvoiced,
  entered,
  multipleOrderLines,
  picked,
  processing,
  withEstimatedDeliveryDate,
  withMultipleAttachments,
  withSingleAttachment,
} as const

type Orders = {
  items: Record<keyof typeof items, SingleIncludedOrderResourceUnion>
  list: () => SingleIncludedOrderResourceUnion[]
}

export const orders: Orders = {
  items,
  list: () => Object.values(items),
}
