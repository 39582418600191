import { APIOrder, APIOrderTracking, ManyMeta } from '@brenntag/connect-apis/api'
import { Order, OrderLine, OrderList, OrderTracking } from '@brenntag/connect-apis/api/models/order'
import { ProductList } from '@brenntag/connect-apis/api/models/product'

import { transformAPIAttachmentsToAttachments } from '#shared/apis/api-object/attachments/transforms/attachment.transforms.js'
import { transformAPIProductToProduct } from '#shared/apis/api/products/transforms/product.transforms.js'
import { transformAPILocationToCustomerLocation } from '#shared/apis/api/shared/transforms/location.transforms.js'
import { transformMonetaryAmount } from '#shared/apis/api/shared/transforms/price.transforms.js'
import { trimLeadingZeros } from '#shared/utils/misc.js'
import { generateTotalPages } from '#shared/utils/pagination.js'

export const transformAPIOrderAndOrderProductsToOrder = (
  apiOrder: APIOrder,
  orderProducts: ProductList | undefined,
): Order => {
  const { surcharges } = apiOrder

  return {
    attachments: transformAPIAttachmentsToAttachments(apiOrder.attachments),
    billTo: transformAPILocationToCustomerLocation(apiOrder.billTo),
    commentFromUser: apiOrder.commentFromUser,
    customerId: apiOrder.customerContext.id,
    /* The api provides originally an array with one item. In this iterface it's a scalar property  */
    delivery: apiOrder.deliveries?.[0] && {
      deliveryDate: apiOrder.deliveries[0].deliveryDate,
      number: apiOrder.deliveries[0].number,
    },

    id: apiOrder.id,

    isDocumentAvailable: apiOrder.isDocumentAvailable,

    /* computed */
    lastUpdateDate: apiOrder.lastUpdateDate,

    /* computed */
    orderCreationDate: apiOrder.orderCreationDate,

    // TODO: fix complexity
    // eslint-disable-next-line complexity
    orderLines: apiOrder.orderLines.map((apiOrderLine): OrderLine => {
      const currentProduct = orderProducts?.products.find(p => p.id === apiOrderLine.product.id)
      const product = transformAPIProductToProduct(apiOrderLine.product)

      return {
        canLink: Boolean(currentProduct),
        /* computed */
        canReorder: Boolean(
          currentProduct?.isOrderable && apiOrderLine.orderQuantity && apiOrder.shipTo?.id,
        ),
        id: apiOrderLine.id,
        orderQuantity: apiOrderLine.orderQuantity,
        pricingUnit: apiOrderLine.pricingUnit,
        pricingUnitValue: apiOrderLine.pricingUnitValue,
        product: {
          ...product,
          customerProductNumber:
            apiOrderLine.customerProductNumber ?? product.customerProductNumber,
          isHazardous: apiOrderLine.productIsHazardous ?? product.isHazardous,
          name: apiOrderLine.productName ?? product.name,
          number: trimLeadingZeros(apiOrderLine.productNumber ?? product.number) ?? '',
          packaging: {
            ...product.packaging,
            name: apiOrderLine.productPackaging?.name ?? product.packaging.name,
            size: {
              quantity: apiOrderLine?.pricingUnit?.quantity ?? product.packaging.size.quantity,
              uom: apiOrderLine.pricingUnit?.uom ?? product.packaging.size.uom,
            },
          },
        },

        /* computed */
        quantityCalculated:
          (apiOrderLine.orderQuantity || 0) *
          ((apiOrderLine?.pricingUnit?.quantity ?? product.packaging.size.quantity) || 1),

        shipToLocationId: apiOrder.shipTo?.id,
        /* WARNING: The status is nullable from the backend */
        status: apiOrder.status || 'Entered',
        subtotalValue: apiOrderLine.subtotalValue,
      }
    }),

    paymentTerms: apiOrder.paymentTerms,

    purchaseOrderNumber: apiOrder.purchaseOrderNumber,

    /* computed */
    requestedDeliveryDate: apiOrder.requestedDeliveryDate,
    salesOrderNumber: trimLeadingZeros(apiOrder.salesOrderNumber),
    shippingInstructions: apiOrder.shippingInstructions,
    shipTo: transformAPILocationToCustomerLocation(apiOrder.shipTo),
    status: apiOrder.status || 'Entered',
    surcharges: {
      cleaningCharge: surcharges?.cleaningCharge,
      containerDeposit: surcharges?.containerDeposit,
      freightCharges: surcharges?.freightCharges,
      fuelSurcharge: surcharges?.fuelSurcharge,
      insuranceSurcharge: surcharges?.insuranceSurcharge,
      minimumOrderSurcharge: surcharges?.minimumSurcharge,
      otherAdditionalCharges: surcharges?.otherAdditionalCharges,
      subTotal: apiOrder.subtotalValue,
      totalSalesTax: surcharges?.totalSalesTax,
    },
    totalValue: transformMonetaryAmount(apiOrder.totalValue?.amount, apiOrder.totalValue?.currency),
    user: apiOrder.user
      ? {
          name: apiOrder.user?.name,
        }
      : apiOrder.isOrderOnBehalf
        ? { name: 'Brenntag' }
        : undefined,
  }
}

export const transformAPIOrdersToOrderList = (
  apiOrders: APIOrder[],
  meta: ManyMeta,
  pageSize: number,
): OrderList => {
  return {
    orders: apiOrders.map(apiOrder =>
      transformAPIOrderAndOrderProductsToOrder(apiOrder, undefined),
    ),
    total: meta?.count || 0,
    totalPages: generateTotalPages(meta?.count, pageSize),
  }
}

export const transformAPIOrdersTrackingToOrdersTracking = (
  apiOrdersTracking: APIOrderTracking,
): OrderTracking => {
  return {
    actualArrivalTime: apiOrdersTracking.actualArrivalTime,
    estimatedArrivalTime: apiOrdersTracking.estimatedArrivalTime,
  }
}
