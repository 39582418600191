import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconLink = createIcon({
  displayName: 'IconLink',
  path: [
    <path d="M12 13V15H16V4H2V15H6V13H4V6H14V13H12Z" fill="currentColor" key="0" />,
    <path d="M8 20H22V9H18V11H20V18H10V11H12V9H8V20Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
