import { APIInvoice, ManyMeta } from '@brenntag/connect-apis/api'
import { Invoice, InvoiceList, InvoiceStatus } from '@brenntag/connect-apis/api/models/invoice'

import { transformAPIOrderAndOrderProductsToOrder } from '#shared/apis/api/orders/transforms/order.transforms.js'
import { transformAPILocationToCustomerLocation } from '#shared/apis/api/shared/transforms/location.transforms.js'
import { trimLeadingZeros } from '#shared/utils/misc.js'
import { generateTotalPages } from '#shared/utils/pagination.js'

import { paidInvoicesCache } from '../cache'

export const determineStatus = (invoice: APIInvoice): InvoiceStatus => {
  if (invoice.status === 'Closed') {
    return 'Closed'
  }

  // We pro-actively "tell" that the invoice is paid.
  // This is to avoid a condition where the invoice is paid, but the status is not reflected in the UI.
  // The reason is that the API is eventually consistent and it can take some seconds for the status to be updated.
  const isPaid = Boolean(paidInvoicesCache.value[invoice.id])

  if (isPaid) {
    return 'Reconciling'
  }

  const amount = invoice.paidAmount?.amount
  if (typeof amount === 'number' && amount > 0) {
    return 'Reconciling'
  }

  return 'Open' // TODO status is atm optional on BE. A fix will be applied to make it required. Then afterwards we can
  // return invoice.status instead of 'Open'
}

export const transformAPIInvoiceToInvoice = (apiInvoice: APIInvoice): Invoice => {
  return {
    coaDocuments: apiInvoice.coaDocuments,
    delivery: apiInvoice.delivery && {
      number: apiInvoice.delivery.number,
      order:
        apiInvoice.delivery.order &&
        transformAPIOrderAndOrderProductsToOrder(apiInvoice.delivery.order, undefined),
    },
    dueDate: apiInvoice.dueDate,
    grossAmount: {
      amount: apiInvoice.grossAmount.amount,
      currency: apiInvoice.grossAmount.currency,
    },
    id: apiInvoice.id,
    invoiceDate: apiInvoice.invoiceDate,
    isInvoiceAvailable: apiInvoice.isInvoiceAvailable ?? false,
    number: trimLeadingZeros(apiInvoice.number),
    paidAmount: apiInvoice.paidAmount,
    purchaseOrderNumber: apiInvoice.purchaseOrderNumber,
    shipTo: transformAPILocationToCustomerLocation(apiInvoice.shipTo),
    status: determineStatus(apiInvoice),
    type: apiInvoice.type,
  }
}

export const transformAPIInvoicesToInvoiceList = (
  apiInvoices: APIInvoice[],
  meta: ManyMeta,
  pageSize: number,
): InvoiceList => ({
  invoices: apiInvoices.map(transformAPIInvoiceToInvoice),
  total: meta?.count || 0,
  totalPages: generateTotalPages(meta?.count, pageSize),
})
