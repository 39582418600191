import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconColumns = createIcon({
  displayName: 'IconColumns',
  path: [
    <path d="M2 4H4V20H2V4Z" fill="currentColor" key="0" />,
    <path d="M20 4H22V20H20V4Z" fill="currentColor" key="1" />,
    <path d="M10 4H8V20H10V4Z" fill="currentColor" key="2" />,
    <path d="M14 4H16V20H14V4Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
