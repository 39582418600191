import consola from 'consola/browser'
import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'
import { DataTypeOrderLine } from '@brenntag/connect-apis/api/types'

import { PostReorderAllParams } from '#shared/apis/api-cart/cart/endpoints/postReorderAll.js'
import { config } from '#src/config.js'

import { orders } from '../api/orders/data'
import { products } from '../api/products/data'
import { carts } from './data'

export const postReorderAll = () => {
  return http.post<{ orderId: string }, PostReorderAllParams, APICart>(
    `${config.cartApiBaseUrl}/carts/reorder/:orderId`,
    async ({ params }) => {
      const order = orders.list().find(order => order.data.id === params.orderId)
      if (!order) {
        consola.log('Order not found')
        return HttpResponse.json(null, { status: 404 })
      }

      const shipToId = order.data.relationships.shipTo?.data.id
      if (!shipToId) {
        consola.log('ShipTo not found')
        return HttpResponse.json(null, { status: 404 })
      }

      const cart = carts[shipToId]
      const orderlines = (order.included ?? []).filter(
        include => include.type === DataTypeOrderLine.OrderLine,
      )

      for (const orderline of orderlines) {
        const productId = orderline.relationships.product?.data.id
        if (!productId) {
          consola.log('Product not found')
          continue
        }

        const product = products.list().find(product => product.data.id === productId)
        if (!product) {
          consola.log('Product not found')
          continue
        }

        cart.items!.push({
          createdDate: new Date().toISOString(),
          id: orderline.id,
          messagesSummary: {},
          name: product.data.attributes.name,
          productDetails: {
            fields: {},
            name: product.data.attributes.name,
            productId: product.data.id,
            sku: '',
          },
          productId: product.data.id,
          quantity: orderline.attributes.orderQuantity,
          type: 'product',
        })
      }

      return HttpResponse.json(cart)
    },
  )
}
