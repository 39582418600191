import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4 10c0-4.407 3.585-7.995 7.99-8 4.405.005 7.99 3.593 7.99 8v1c0 2.2-.85 4.27-2.41 5.83l-2.76 2.76-1.41-1.41 2.76-2.76a6.17 6.17 0 0 0 1.83-4.41v-1c0-3.31-2.69-6-6-6s-6 2.69-6 6v1c0 1.66.65 3.23 1.83 4.41l5.59 5.59L12 22.42l-5.59-5.59A8.23 8.23 0 0 1 4 11zM11.99 2H12h-.02z"
      fill="currentColor"
    />
    <path
      d="M14 10c0-.18-.03-.35-.07-.51l-.01-.01 1.51-1.51c.35.6.57 1.28.57 2.02 0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4c.74 0 1.43.22 2.02.57l-1.51 1.51C12.35 8.03 12.18 8 12 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2"
      fill="currentColor"
    />
  </svg>
)
export default SvgLocation
