import React from 'react'

interface Props {
  children: React.ReactNode
}

export const AppHeaderNavigationBar: React.FC<Props> = ({ children }) => {
  return (
    <div className="hidden bg-primary lg:block">
      <nav className="container mx-auto flex gap-8 px-4 md:px-8">{children}</nav>
    </div>
  )
}
