import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChange = createIcon({
  displayName: 'IconChange',
  path: [
    <path
      d="M3.06 11C3.56 6.51 7.38 3 12 3C13.83 3 15.55 3.55 17 4.51V6H15.58C14.52 5.37 13.3 5 12 5C8.48 5 5.57 7.61 5.08 11H3.06Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M15 10H21V4H19V8H15V10Z" fill="currentColor" key="1" />,
    <path d="M3 20H5V16H9V14H3V20Z" fill="currentColor" key="2" />,
    <path
      d="M18.92 13C18.43 16.39 15.52 19 12 19C10.7 19 9.48 18.63 8.42 18H7V19.49C8.45 20.45 10.17 21 12 21C16.62 21 20.44 17.49 20.94 13H18.92Z"
      fill="currentColor"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
