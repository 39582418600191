import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDuplicate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M3 17h2V5h12V3H3z" fill="currentColor" />
    <path d="M7 21h14V11h-2v8H9V9h12V7H7z" fill="currentColor" />
  </svg>
)
export default SvgDuplicate
