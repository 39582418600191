import { SingleIncludedProductResourceWithPricebookProductUnion } from '@brenntag/connect-apis/api/types'

import { manyPricePoints } from './manyPricePoints'
import { multipleDocuments } from './multipleDocuments'
import { multipleTankLocations } from './multipleTankLocations'
import { noPricePoint } from './noPricePoint'
import { oneDocument } from './oneDocument'
import { oneTankLocation } from './oneTankLocation'
import { onlyForHidePricesLocation } from './onlyForHidePricesLocation'
import { onlyForShowPricesLocation } from './onlyForShowPricesLocation'
import { singlePricePoint } from './singlePricePoint'

const items = {
  manyPricePoints,
  multipleDocuments,
  multipleTankLocations,
  noPricePoint,
  oneDocument,
  oneTankLocation,
  onlyForHidePricesLocation,
  onlyForShowPricesLocation,
  singlePricePoint,
} as const

type Products = {
  items: Record<keyof typeof items, SingleIncludedProductResourceWithPricebookProductUnion>
  list: () => SingleIncludedProductResourceWithPricebookProductUnion[]
}

export const products: Products = {
  items,
  list: () => Object.values(items),
}

// export const dummyProductsResponse = {
//   data: [
//     {
//       attributes: {
//         brand: 'BLUE MT',
//         casNumbers: ['107-21-1', '111-46-6', '3734-33-6', '7732-18-5'],
//         createdAt: '2023-10-19T14:56:27.650504Z',
//         description: 'COOLANT 55G DRM BMAB51',
//         erpId: '242941',
//         isActive: true,
//         isHazardous: false,
//         name: 'BLUE MT 50/50 PREDILUTED AF & COOLANT 55G DRM BMAB51',
//         number: '242941',
//         packaging: {
//           name: 'Drum',
//           type: 'drum',
//         },
//         shippingInformation: {
//           ground: true,
//           productWeightLbs: 490.6,
//           shippingTerms: 'Normally Delivered',
//           shippingWeightLbs: 524.6,
//         },
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         type: 'Trading Goods',
//         updatedAt: '2025-01-06T14:38:19.090884Z',
//       },
//       id: '01t8W000005HuMtQAK',
//       relationships: {
//         baseProduct: {
//           data: {
//             id: '01t8W000005HuMtQAK',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'BaseProduct',
//           },
//         },
//         categories: {
//           data: [],
//         },
//         documents: {
//           data: [],
//         },
//         favoriteItems: {
//           data: [],
//         },
//         pimClassifications: {
//           data: [],
//         },
//         pimSpecifications: {
//           data: [],
//         },
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODvHUQA1',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//         specifications: {
//           data: [],
//         },
//         tankLocations: {
//           data: [],
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'Product',
//     },
//     {
//       attributes: {
//         casNumbers: ['57-13-6', '7732-18-5'],
//         createdAt: '2023-10-19T14:01:42.150660Z',
//         description: '32% G BULK',
//         erpId: '87305',
//         isActive: true,
//         isHazardous: true,
//         name: 'DEF 32.5% DIESEL EXHAUST FLUID ..',
//         number: '87305',
//         packaging: {
//           name: 'T/T',
//           type: 't/t',
//         },
//         shippingInformation: {
//           ground: true,
//           productWeightLbs: 9.08,
//           shippingTerms: 'Normally Delivered',
//           shippingWeightLbs: 9.08,
//         },
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         type: 'Trading Goods',
//         updatedAt: '2025-01-09T15:43:30.741113Z',
//       },
//       id: '01t8W000005H8uVQAS',
//       relationships: {
//         baseProduct: {
//           data: {
//             id: '01t8W000005H8uVQAS',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'BaseProduct',
//           },
//         },
//         categories: {
//           data: [],
//         },
//         documents: {
//           data: [],
//         },
//         favoriteItems: {
//           data: [],
//         },
//         pimClassifications: {
//           data: [],
//         },
//         pimSpecifications: {
//           data: [],
//         },
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODveTQAT',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//         specifications: {
//           data: [],
//         },
//         tankLocations: {
//           data: [],
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'Product',
//     },
//     {
//       attributes: {
//         brand: 'MOBIL',
//         casNumbers: ['113706-15-3', '125643-61-0'],
//         createdAt: '2023-10-19T14:55:19.898170Z',
//         description: '125152',
//         erpId: '46495',
//         isActive: true,
//         isHazardous: true,
//         name: 'MOBIL DELVAC HDEO 15W40 G BULK',
//         number: '46495',
//         packaging: {
//           name: 'T/T',
//           type: 't/t',
//         },
//         shippingInformation: {
//           ground: true,
//           productWeightLbs: 7.3,
//           shippingTerms: 'Normally Delivered',
//           shippingWeightLbs: 7.3,
//         },
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         type: 'Trading Goods',
//         updatedAt: '2025-01-06T14:38:08.726198Z',
//       },
//       id: '01t8W000005HMBZQA4',
//       relationships: {
//         baseProduct: {
//           data: {
//             id: '01t8W000005HMBZQA4',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'BaseProduct',
//           },
//         },
//         categories: {
//           data: [],
//         },
//         documents: {
//           data: [],
//         },
//         favoriteItems: {
//           data: [],
//         },
//         pimClassifications: {
//           data: [],
//         },
//         pimSpecifications: {
//           data: [],
//         },
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODvTfQAL',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//         specifications: {
//           data: [],
//         },
//         tankLocations: {
//           data: [],
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'Product',
//     },
//     {
//       attributes: {
//         brand: 'PEAK',
//         casNumbers: ['107-21-1', '111-46-6', '3734-33-6', '7732-18-5'],
//         createdAt: '2023-10-19T14:55:00.212672Z',
//         description: 'A&C 6X1GAL PXA0B3',
//         erpId: '117533',
//         isActive: true,
//         isHazardous: false,
//         name: 'PEAK GLOBAL LIFETIME CONCENTRA A&C 6X1GAL PXA0B3',
//         number: '117533',
//         packaging: {
//           name: 'CASE',
//           type: 'case',
//         },
//         shippingInformation: {
//           ground: true,
//           productWeightLbs: 56.046,
//           shippingTerms: 'Normally Delivered',
//           shippingWeightLbs: 60.046,
//         },
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         type: 'Trading Goods',
//         updatedAt: '2025-01-06T14:36:43.897644Z',
//       },
//       id: '01t8W000005HEpKQAW',
//       relationships: {
//         baseProduct: {
//           data: {
//             id: '01t8W000005HEpKQAW',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'BaseProduct',
//           },
//         },
//         categories: {
//           data: [],
//         },
//         documents: {
//           data: [],
//         },
//         favoriteItems: {
//           data: [],
//         },
//         pimClassifications: {
//           data: [],
//         },
//         pimSpecifications: {
//           data: [],
//         },
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODvGUQA1',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//         specifications: {
//           data: [],
//         },
//         tankLocations: {
//           data: [],
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'Product',
//     },
//   ],
//   included: [
//     {
//       attributes: {
//         createdAt: '2023-10-19T19:43:37.272416Z',
//         customerProductNumber: '87305',
//         isActive: true,
//         isOrderable: true,
//         isSdsAvailable: true,
//         packagingSize: {
//           quantity: 1.0,
//           uom: 'GAL',
//         },
//         updatedAt: '2025-01-06T22:25:17.529126Z',
//       },
//       id: '01u8W00000ODveTQAT',
//       relationships: {
//         pricebook: {
//           data: {
//             id: '01s8W0000089LtZQAU',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Pricebook',
//           },
//         },
//         pricePoints: {
//           data: [
//             {
//               id: '01u8W00000ODveTQAT',
//               // @ts-expect-error Ignore, data is correct. This is coming from the API
//               type: 'PricePoint',
//             },
//           ],
//         },
//         product: {
//           data: {
//             id: '01t8W000005H8uVQAS',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Product',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricebookProduct',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T19:21:36.048740Z',
//         customerProductNumber: 'BMAB51',
//         isActive: true,
//         isOrderable: true,
//         isSdsAvailable: true,
//         packagingSize: {
//           quantity: 55.0,
//           uom: 'GAL',
//         },
//         updatedAt: '2025-01-07T00:21:17.977808Z',
//       },
//       id: '01u8W00000ODvHUQA1',
//       relationships: {
//         pricebook: {
//           data: {
//             id: '01s8W0000089LtZQAU',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Pricebook',
//           },
//         },
//         pricePoints: {
//           data: [
//             {
//               id: '01u8W00000ODvHUQA1',
//               // @ts-expect-error Ignore, data is correct. This is coming from the API
//               type: 'PricePoint',
//             },
//           ],
//         },
//         product: {
//           data: {
//             id: '01t8W000005HuMtQAK',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Product',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricebookProduct',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T15:10:24.990379Z',
//         isActive: true,
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         name: 'PL-20-229746-29-S',
//         type: 'shipTo',
//         updatedAt: '2025-01-06T14:53:42.577991Z',
//       },
//       id: '01s8W0000089LtZQAU',
//       relationships: {
//         locations: {
//           data: [],
//         },
//         pricebookProducts: {
//           data: [],
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'Pricebook',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T17:03:59.891355Z',
//         isActive: true,
//         isOrderable: true,
//         isSdsAvailable: true,
//         packagingSize: {
//           quantity: 1.0,
//           uom: 'GAL',
//         },
//         updatedAt: '2025-01-06T23:42:29.114592Z',
//       },
//       id: '01u8W00000ODvTfQAL',
//       relationships: {
//         pricebook: {
//           data: {
//             id: '01s8W0000089LtZQAU',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Pricebook',
//           },
//         },
//         pricePoints: {
//           data: [
//             {
//               id: '01u8W00000ODvTfQAL',
//               // @ts-expect-error Ignore, data is correct. This is coming from the API
//               type: 'PricePoint',
//             },
//           ],
//         },
//         product: {
//           data: {
//             id: '01t8W000005HMBZQA4',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Product',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricebookProduct',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T19:10:41.420396Z',
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         discount: 0.0,
//         isActive: true,
//         price: {
//           amount: 73.08,
//           currency: 'USD',
//         },
//         scaleStart: 1,
//         updatedAt: '2025-01-07T00:19:41.010234Z',
//       },
//       id: '01u8W00000ODvGUQA1',
//       relationships: {
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODvGUQA1',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricePoint',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T17:04:06.058886Z',
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         discount: 0.0,
//         isActive: true,
//         price: {
//           amount: 16.67,
//           currency: 'USD',
//         },
//         scaleStart: 1,
//         updatedAt: '2025-01-06T23:42:36.672135Z',
//       },
//       id: '01u8W00000ODvTfQAL',
//       relationships: {
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODvTfQAL',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricePoint',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T19:43:41.300136Z',
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         discount: 0.0,
//         isActive: true,
//         price: {
//           amount: 2.8,
//           currency: 'USD',
//         },
//         scaleStart: 1,
//         updatedAt: '2025-01-06T22:25:26.109865Z',
//       },
//       id: '01u8W00000ODveTQAT',
//       relationships: {
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODveTQAT',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricePoint',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T19:10:37.285466Z',
//         customerProductNumber: 'PXA0B3',
//         isActive: true,
//         isOrderable: true,
//         isSdsAvailable: true,
//         packagingSize: {
//           quantity: 6.0,
//           uom: 'GAL',
//         },
//         updatedAt: '2025-01-07T00:19:24.454297Z',
//       },
//       id: '01u8W00000ODvGUQA1',
//       relationships: {
//         pricebook: {
//           data: {
//             id: '01s8W0000089LtZQAU',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Pricebook',
//           },
//         },
//         pricePoints: {
//           data: [
//             {
//               id: '01u8W00000ODvGUQA1',
//               // @ts-expect-error Ignore, data is correct. This is coming from the API
//               type: 'PricePoint',
//             },
//           ],
//         },
//         product: {
//           data: {
//             id: '01t8W000005HEpKQAW',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'Product',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricebookProduct',
//     },
//     {
//       attributes: {
//         createdAt: '2023-10-19T19:21:44.595979Z',
//         // @ts-expect-error Ignore, data is correct. This is coming from the API
//         discount: 0.0,
//         isActive: true,
//         price: {
//           amount: 325.6,
//           currency: 'USD',
//         },
//         scaleStart: 1,
//         updatedAt: '2025-01-07T00:21:27.775659Z',
//       },
//       id: '01u8W00000ODvHUQA1',
//       relationships: {
//         pricebookProduct: {
//           data: {
//             id: '01u8W00000ODvHUQA1',
//             // @ts-expect-error Ignore, data is correct. This is coming from the API
//             type: 'PricebookProduct',
//           },
//         },
//       },
//       // @ts-expect-error Ignore, data is correct. This is coming from the API
//       type: 'PricePoint',
//     },
//   ],
//   meta: {
//     aggregations: {
//       brands: ['BLUE MT', 'MOBIL', 'PEAK'],
//       categories: [
//         {
//           id: '0ZG8W000000CfmzWAC',
//           name: 'Products',
//           subCategories: [
//             {
//               id: '0ZG8W000000bnWhWAI',
//               name: 'BLENDS (NON PERFORMANCE)',
//               subCategories: [
//                 {
//                   id: '0ZG8W000000bnb7WAA',
//                   name: 'WATER BASED BLENDS',
//                   subCategories: [
//                     {
//                       id: '0ZG8W000000bnj7WAA',
//                       name: 'ANTIFREEZE',
//                       subCategories: [
//                         {
//                           id: '0ZG8W000000bnqUWAQ',
//                           name: 'ANTIFREEZE EXTENDED LIFE',
//                           subCategories: [],
//                         },
//                         {
//                           id: '0ZG8W000000bo8iWAA',
//                           name: 'ANTIFREEZE AUTOMOTIVE',
//                           subCategories: [],
//                         },
//                       ],
//                     },
//                   ],
//                 },
//               ],
//             },
//             {
//               id: '0ZG8W000000bnVtWAI',
//               name: 'DEF',
//               subCategories: [
//                 {
//                   id: '0ZG8W000000bnZcWAI',
//                   name: 'DEF PRODUCT',
//                   subCategories: [
//                     {
//                       id: '0ZG8W000000bnbcWAA',
//                       name: 'DEF PRODUCT',
//                       subCategories: [
//                         {
//                           id: '0ZG8W000000bnrRWAQ',
//                           name: 'DEF PRODUCT',
//                           subCategories: [],
//                         },
//                       ],
//                     },
//                   ],
//                 },
//               ],
//             },
//             {
//               id: '0ZG8W000000bnWwWAI',
//               name: 'LUBRICANTS',
//               subCategories: [
//                 {
//                   id: '0ZG8W000000bnskWAA',
//                   name: 'COMMERCIAL',
//                   subCategories: [
//                     {
//                       id: '0ZG8W000000bo2TWAQ',
//                       name: 'HEAVY DUTY ENGINE OIL',
//                       subCategories: [
//                         {
//                           id: '0ZG8W000000boKUWAY',
//                           name: 'HEAVY DUTY ENGINE OIL CONVENTIONAL',
//                           subCategories: [],
//                         },
//                       ],
//                     },
//                   ],
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//       categories_hash: '162ac66702f70d34295aa256a13723b1dc02f063aac29c1d88311335d1f138d6',
//       'packaging-types': {
//         case: 'CASE',
//         drum: 'Drum',
//         't/t': 'T/T',
//       },
//       pim_classifications: [],
//       pim_classifications_hash: 'd83fe33d9be09858a2383f7e6a2a5a1d6e035e930a97140da44a908d5f4bc985',
//     },
//     count: 4,
//   },
// } satisfies APIProductsResponse
