import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAnnouncement = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m12.59 7 4-4H21v17h-4.41l-4-4H9.5l-.67-2h4.58l4 4H19V5h-1.59l-4 4H4v5h2.72l2 6H11v2H7.28l-2-6H2V7z"
      fill="currentColor"
    />
    <path d="M8 16v-.07l.02.07z" fill="currentColor" />
  </svg>
)
export default SvgAnnouncement
