import { authConfig } from '#lib/auth/config.js'

const TOKEN_VALID_FOR_SEC = 60 * 60 * 2 * 24 // 2 days

const createFakeId = () => {
  const nowSec = new Date().getTime() / 1000
  const expiresAtSec = nowSec + TOKEN_VALID_FOR_SEC

  const payload = {
    decodedToken: {
      claims: {
        aud: authConfig.auth0.clientId,
        email: 'e2e-user@brenntag.com',
        email_verified: true,
        exp: expiresAtSec,
        iat: nowSec,
        iss: 'https://development.login.brenntag.com/',
        name: 'e2e-user@brenntag.com',
        nickname: 'e2e-user',
        picture:
          'https://s.gravatar.com/avatar/572855a9fa46b711c9c99f54f81d7216?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fe2.png',
        sub: 'auth0|600aba63f4c299006b8beafc',
        updated_at: new Date().toISOString(),
      },
      user: {
        brenntag: {
          app: {
            countries: ['US', 'SE'],
            role: 'Customer',
            roles: ['CO2'],
            skipMFA: true,
            status: 'Active',
            type: 'external',
          },
          profile: {
            language: 'en',
            timezone: 'America/North_Dakota/Center',
          },
        },
        email: 'mock-user@brenntag.com',
        email_verified: true,
        family_name: 'User',
        given_name: 'Mock',
        id: 'mockAuth0Id',
        name: 'Mock User',
        nickname: 'mock.nickname',
        picture:
          'https://s.gravatar.com/avatar/572855a9fa46b711c9c99f54f81d7216?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fe2.png',
        sub: 'auth0|600aba63f4c299006b8beafc',
        updated_at: new Date().toISOString(),
      },
    },
    id_token: '<fakeIdToken>',
  }

  return JSON.stringify(payload)
}

export const setAuth0Session = () => {
  window.localStorage.setItem(
    `@@auth0spajs@@::${authConfig.auth0.clientId}::@@user@@`,
    createFakeId(),
  )
}

export const clearAuth0Session = () => {
  window.localStorage.removeItem(`@@auth0spajs@@::${authConfig.auth0.clientId}::@@user@@`)
}
