type Environment = 'acceptance' | 'development' | 'local' | 'production'

export const environment = import.meta.env.VITE_ENV_NAME as Environment
export const environmentColor = import.meta.env.VITE_ENV_COLOR

export const isEnvironment = (...envs: Environment[]): boolean =>
  envs.some(env => env === environment)

export const isDevMode = import.meta.env.DEV
export const isProductionMode = import.meta.env.PROD
export const isMockMode = Boolean(import.meta.env.VITE_MOCK === 'true')
export const isAnalyticsEnabled = Boolean(import.meta.env.VITE_ANALYTICS_KEY)
