import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLaw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m16 10.366-1.551 1.551L15.33 15h-1.051L12 13.481 9.721 15H8.67l.881-3.084L8 10.366v-.903l2.468-.424L11.561 7h.879l1.093 2.039L16 9.463zM22 2H2v9.891c0 .079.112 7.914 9.783 10.061L12 22l.217-.048C21.888 19.805 22 11.97 22 11.891V5.995h-2v5.891c-.003.256-.152 6.217-8 8.066-7.848-1.849-7.997-7.811-8-8.061V3.998h18z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLaw
