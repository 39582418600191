import { Product } from './product'

export const FavoriteFilter = {
  favoriteListId: 'filter[favoriteItems.favorite.id.equals]',
  searchQuery: 'search[query]',
  sort: 'sort',
}

export interface FavoriteFilters {
  favoriteListId?: string
  page?: string
  searchQuery?: string
  sort?: string
}

export interface FavoriteItem {
  createdAt: Date | undefined
  favoriteList: FavoriteList
  id: string
  updatedAt: Date | undefined
}

export interface FavoriteList {
  favoriteItems: FavoriteItem[]
  id: string
  name: string
}

export interface FavoriteListProducts {
  favoriteListId: string
  products: Product[] | undefined
  total: number
  totalPages: number
}
