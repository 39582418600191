import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFileSearch = createIcon({
  displayName: 'IconFileSearch',
  path: [
    <path d="M6 4H11V2H4V22H13V20H6V4Z" fill="currentColor" key="0" />,
    <path
      clipRule="evenodd"
      d="M18 11H20V6.59L15.41 2H13V9H18V11ZM15 7V4.41L17.59 7H15Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
    <path
      clipRule="evenodd"
      d="M19.4417 18.02L21.7117 20.29L20.3017 21.7L18.0317 19.43C17.4317 19.78 16.7517 20 16.0117 20C13.8017 20 12.0117 18.21 12.0117 16C12.0117 13.79 13.8017 12 16.0117 12C18.2217 12 20.0117 13.79 20.0117 16C20.0117 16.74 19.7917 17.43 19.4417 18.02ZM16.0017 14C14.9017 14 14.0017 14.9 14.0017 16C14.0017 17.1 14.9017 18 16.0017 18C17.1017 18 18.0017 17.1 18.0017 16C18.0017 14.9 17.1017 14 16.0017 14Z"
      fill="currentColor"
      fillRule="evenodd"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
