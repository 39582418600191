import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconBookmarkFilled = createIcon({
  displayName: 'IconBookmarkFilled',
  path: [
    <path
      d="M20 22.94L12 17.23L4 22.94V2H20V4H6V19.06L12 14.77L18 19.06V6H20V22.94Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M5 21V4H20V21L11.9643 16.1429L5 21Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
