import React from 'react'

import { Text } from '@chakra-ui/react'

interface Props {
  children?: React.ReactNode
}

export const Subheading = ({ children }: Props) => {
  return (
    <Text fontSize="12px" fontWeight={400} h={3} lineHeight="16px">
      {children}
    </Text>
  )
}
