import * as React from 'react'
import type { SVGProps } from 'react'
const SvgViewOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.707 2.291 2.293 3.706l18.003 18.003 1.414-1.415zM9.692 5.44c.73-.29 1.51-.44 2.31-.44 1.67 0 3.24.65 4.41 1.83l4.59 4.59v1.17l-2.09 2.09 1.41 1.41 2.67-2.67v-2.83l-5.17-5.17a8.18 8.18 0 0 0-5.83-2.41c-1.35 0-2.66.33-3.82.94l1.51 1.51zM12.002 19c.8 0 1.58-.15 2.31-.44l.01-.01 1.51 1.51c-1.16.61-2.47.94-3.82.94-2.2 0-4.27-.85-5.83-2.41l-5.17-5.17v-2.83l2.67-2.67 1.41 1.41-2.09 2.09v1.17l4.59 4.58a6.17 6.17 0 0 0 4.41 1.83"
      fill="currentColor"
    />
    <path
      d="m16.929 12.69.02-.144c.025-.18.05-.356.05-.546 0-2.76-2.24-5-5-5-.19 0-.367.025-.546.05l-.144.02zM7.05 11.454l.02-.144 5.62 5.62-.144.02c-.18.025-.356.05-.546.05-2.76 0-5-2.24-5-5 0-.19.025-.367.05-.546"
      fill="currentColor"
    />
  </svg>
)
export default SvgViewOff
