import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCircleQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m19.74 5.68-1.43 1.43a8 8 0 0 1 1.69 4.9c0 4.41-3.59 8-8 8s-8-3.59-8-8S7.59 4 12 4c1.85 0 3.54.63 4.9 1.69l1.43-1.43A9.96 9.96 0 0 0 12.01 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10c0-2.4-.85-4.6-2.26-6.32"
      fill="currentColor"
    />
    <path
      d="M11 14v-1.41l1.56-1.56c.28-.28.44-.66.44-1.06v-.96c0-.55-.45-1-1-1s-1 .45-1 1v1H9v-1c0-1.65 1.35-3 3-3s3 1.35 3 3v.96c0 .93-.36 1.81-1.03 2.47l-.97.98V14zM13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgCircleQuestion
