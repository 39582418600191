import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSquareRemove = createIcon({
  displayName: 'IconSquareRemove',
  path: [
    <path d="M3 3H21V5H5V19H19V7H21V21H3V3Z" fill="currentColor" key="0" />,
    <path d="M7 11H17V13H7V11Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
