import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m15.125 5.625 1.5-1.5c-3.77-1.96-8.54-1.36-11.7 1.8-3.9 3.9-3.9 10.24 0 14.14l1.41-1.41c-3.12-3.12-3.12-8.2 0-11.31 2.37-2.37 5.86-2.93 8.78-1.7zM19.004 4.571l-8.414 8.415 1.414 1.414 8.415-8.415zM17.664 18.646c2.36-2.37 2.93-5.87 1.7-8.78l1.51-1.51c1.96 3.77 1.36 8.54-1.8 11.7z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDashboard
