import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSocialLinkedIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.63 5.86a1.75 1.75 0 0 1 .56-1.33c.403-.36.93-.55 1.47-.53a2 2 0 0 1 1.44.52 1.8 1.8 0 0 1 .57 1.39 1.7 1.7 0 0 1-.55 1.29 2.06 2.06 0 0 1-1.49.53 1.94 1.94 0 0 1-1.44-.53 1.78 1.78 0 0 1-.56-1.34M3.84 20V9.2h3.59V20zm5.57 0H13v-6c-.01-.295.033-.59.13-.87a2.27 2.27 0 0 1 .69-.93 1.77 1.77 0 0 1 1.15-.38c1.21 0 1.81.81 1.81 2.44V20h3.59v-6.19a5.24 5.24 0 0 0-1.13-3.63 3.86 3.86 0 0 0-3-1.23 3.66 3.66 0 0 0-3.25 1.79V9.2H9.4v3.22c0 1.8.04 4.33.01 7.58"
      fill="currentColor"
    />
  </svg>
)
export default SvgSocialLinkedIn
