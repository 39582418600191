import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSquareDelete = createIcon({
  displayName: 'IconSquareDelete',
  path: [
    <path d="M3 3H21V5H5V19H19V7H21V21H3V3Z" fill="currentColor" key="0" />,
    <path
      d="M11.9991 10.59L15.2891 7.29004L16.7091 8.71004L13.4091 12L16.7091 15.29L15.2891 16.71L11.9991 13.41L8.70906 16.71L7.28906 15.29L10.5891 12L7.28906 8.71004L8.70906 7.29004L11.9991 10.59Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
