import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUserViewer = createIcon({
  displayName: 'IconUserViewer',
  path: [
    <path
      d="M5.5 6.49103C5.5 4.01003 7.519 1.99103 10 1.99103C10.879 1.99103 11.693 2.25403 12.386 2.69103L10.909 4.16803C10.626 4.05803 10.321 3.99103 10 3.99103C8.622 3.99103 7.5 5.11203 7.5 6.49103C7.5 7.87003 8.622 8.99103 10 8.99103C11.378 8.99103 12.5 7.87003 12.5 6.49103C12.5 6.17003 12.434 5.86503 12.323 5.58203L13.8 4.10503C14.237 4.79803 14.5 5.61303 14.5 6.49103C14.5 8.97203 12.481 10.991 10 10.991C7.519 10.991 5.5 8.97303 5.5 6.49103ZM5 18V14H8V12H3V20H11.528C11.066 19.412 10.712 18.735 10.495 18H5ZM21.957 20.594L20.543 22.008L18.269 19.734C17.674 20.085 16.989 20.301 16.25 20.301C14.044 20.301 12.25 18.507 12.25 16.301C12.25 14.095 14.044 12.301 16.25 12.301C18.456 12.301 20.25 14.095 20.25 16.301C20.25 17.041 20.035 17.725 19.683 18.32L21.957 20.594ZM18.25 16.302C18.25 15.199 17.353 14.302 16.25 14.302C15.147 14.302 14.25 15.199 14.25 16.302C14.25 17.405 15.147 18.302 16.25 18.302C17.353 18.302 18.25 17.404 18.25 16.302Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
