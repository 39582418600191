import React from 'react'

import { useLocation } from 'react-router'

import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { NotFoundDocument, NotFoundPage } from '#shared/pages/NotFound/NotFound.js'

export const NotFound: React.FC = () => {
  const t = useTranslations()
  const { pathname } = useLocation()
  const documentNotFound = pathname.includes('document-not-found')

  const fallbackLabel = t('label.browseproducts')
  const fallbackUrl = '/:locale/connect/products'

  return documentNotFound ? (
    <NotFoundDocument />
  ) : (
    <NotFoundPage fallbackLabel={fallbackLabel} fallbackUrl={fallbackUrl} />
  )
}
