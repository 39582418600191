import { http, HttpResponse } from 'msw'

import { FavoriteResponse } from '@brenntag/connect-apis/api-object/types'
import { buildFavoriteList } from '@brenntag/legacy-mock-generators/generators/favorites/buildFavoriteList.js'

import { config } from '#src/config.js'

import { favoriteLists } from '../../api/favorites/data'

export const postFavoriteList = () => {
  return http.post<{ shipToId: string }, { name: string }, FavoriteResponse>(
    `${config.apiBaseUrl}/api-object/favorites`,
    async ({ params, request }) => {
      const body = await request.json()
      const list = buildFavoriteList({
        attributes: { name: body.name },
        relationships: { productIds: [], shipToId: params.shipToId },
      })

      favoriteLists.items[list.data.id as keyof typeof favoriteLists.items] = list

      return HttpResponse.json({ id: list.data.id, name: body.name, shipToId: params.shipToId })
    },
  )
}
