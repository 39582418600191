import * as React from 'react'
import type { SVGProps } from 'react'
const SvgScan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5 8H3V3h5v2H5zM8 21H3v-5h2v3h3zM16 21h5v-5h-2v3h-3zM21 8h-2V5h-3V3h5zM11 7h2v10h-2zM15 7v10h2V7zM7 7h2v10H7z"
      fill="currentColor"
    />
  </svg>
)
export default SvgScan
