import { faker } from '@faker-js/faker'
import { merge, uniqBy } from 'lodash'

import {
  DataTypeBaseProduct,
  DataTypeDocument,
  DataTypeFavoriteItem,
  DataTypePimSpecification,
  DataTypePricebookProduct,
  DataTypeProduct,
  DataTypeSpecification,
  DataTypeTankLocation,
  LocationResource,
  LocationType,
  PimSpecificationResource,
  PricebookProductResource,
  PricebookResource,
  PricePointResource,
  ProductAttributes,
  ProductResourceWithPricebookProduct,
  ProductType,
  SalesGroupResource,
  SingleIncludedDocumentResourceProductResource,
  SingleIncludedFavoriteResourceFavoriteItemResource,
  SingleIncludedProductResourceWithPricebookProductUnion,
  SpecificationResource,
  TankLocationResource,
} from '@brenntag/connect-apis/api/types'

import { staticTestData } from '../../data/static'
import { buildLocation } from '../buildLocation'
import { buildPricePoints } from '../buildPricePoint'
import { buildDocument } from '../documents/buildDocument'
import { buildPimSpecification } from './buildPimSpecification'
import { buildPricebook } from './buildPricebook'
import { buildPricebookProduct } from './buildPricebookProduct'
import { buildSpecification } from './buildSpecification'

interface Props {
  attributes?: Partial<ProductAttributes>
  id?: string
  included?: {
    baseProduct?: SingleIncludedProductResourceWithPricebookProductUnion
    documents?: null | SingleIncludedDocumentResourceProductResource[]
    favoriteItems?: null | SingleIncludedFavoriteResourceFavoriteItemResource[]
    location?: LocationResource | null
    pimSpecifications?: null | PimSpecificationResource[]
    pricebook?: null | PricebookResource
    pricebookProduct?: null | PricebookProductResource
    pricePoints?: null | PricePointResource[]
    salesGroup?: null | SalesGroupResource
    specifications?: null | SpecificationResource[]
    tankLocations?: null | TankLocationResource[]
  }
}

// eslint-disable-next-line complexity
export const buildProduct = ({
  attributes,
  id = faker.string.uuid(),
  included,
}: Props = {}): SingleIncludedProductResourceWithPricebookProductUnion => {
  const pricebookId = included?.pricebook?.id ?? faker.string.uuid()
  const pricebookProductId = included?.pricebookProduct?.id ?? faker.string.uuid()

  const baseProduct = included?.baseProduct
  const documents =
    included?.documents === null
      ? []
      : included?.documents === undefined
        ? [buildDocument()]
        : included?.documents
  const salesGroup = included?.salesGroup === undefined ? null : included?.salesGroup
  const location =
    included?.location === undefined
      ? buildLocation({ attributes: { type: LocationType.ShipTo } })
      : included?.location

  const pricePoints =
    included?.pricePoints === undefined
      ? buildPricePoints({ relationships: { pricebookProductId } })
      : included?.pricePoints

  const pricebookProduct =
    included?.pricebookProduct === undefined
      ? buildPricebookProduct({
          id: pricebookProductId,
          pricebookId,
          pricePointIds: pricePoints ? pricePoints.map(pricePoint => pricePoint.id) : [],
          productId: id,
        })
      : included?.pricebookProduct

  const specifications =
    included?.specifications === undefined
      ? Array.from({ length: faker.number.int({ max: 4, min: 1 }) }, (_, index) =>
          buildSpecification({
            index,
            productId: id,
          }),
        )
      : included?.specifications

  const pimSpecifications =
    included?.pimSpecifications === undefined
      ? Array.from({ length: faker.number.int({ max: 2, min: 1 }) }).map(() =>
          buildPimSpecification({
            productId: id,
          }),
        )
      : included?.pimSpecifications

  const pricebook =
    location && pricebookProduct && salesGroup && included?.pricebook === undefined
      ? buildPricebook({
          id: pricebookId,
          relationships: {
            locationIds: [location.id],
            pricebookProductIds: [pricebookProduct.id],
            salesGroupId: salesGroup.id,
          },
        })
      : included?.pricebook

  const packType = faker.helpers.objectEntry(staticTestData.packagingTypes)

  const favoriteItems = included?.favoriteItems || []

  const tankLocations = included?.tankLocations || []

  const product: ProductResourceWithPricebookProduct = {
    attributes: merge(
      {
        brand: faker.commerce.productName(),
        casNumbers: faker.helpers.multiple(
          () => faker.string.numeric({ length: { max: 10, min: 4 } }),
          {
            count: { max: 5, min: 0 },
          },
        ),
        createdAt: faker.date.past().toISOString(),
        description: faker.word.words(),
        isActive: true,
        isHazardous: faker.datatype.boolean(),
        name: faker.commerce.productName(),
        number: faker.string.numeric({ length: { max: 10, min: 4 } }),
        packaging: { name: packType[0], type: packType[0] },
        shippingInformation: {
          ground: faker.datatype.boolean(),
          productWeightLbs: faker.number.int(10000),
          shippingTerms: faker.word.words({ count: { max: 5, min: 1 } }),
          shippingWeightLbs: faker.number.int(10000),
        },
        type: faker.helpers.arrayElement([
          ProductType.NonValuatedMaterials,
          ProductType.Packaging,
          ProductType.ReturnablePackage,
          ProductType.Sample,
          ProductType.Services,
          ProductType.Tolling,
          ProductType.TollingRawMaterialNonValuated,
          ProductType.TradingGoods,
        ]),
        updatedAt: faker.date.past().toISOString(),
      } satisfies ProductAttributes,
      attributes,
    ),
    id,
    relationships: {
      baseProduct: {
        data: {
          id: baseProduct?.data.id || id,
          type: DataTypeBaseProduct.BaseProduct,
        },
      },
      categories: {
        data: [],
      },
      documents: documents
        ? {
            data: (documents ?? []).map(document => ({
              id: document.data.id,
              type: DataTypeDocument.Document,
            })),
          }
        : undefined,
      favoriteItems: {
        data: favoriteItems.flatMap(favoriteItem =>
          (favoriteItem.included ?? []).flatMap(item => ({
            id: item.id,
            type: DataTypeFavoriteItem.FavoriteItem,
          })),
        ),
      },
      pimSpecifications: {
        data: (pimSpecifications ?? []).map(pimSpecification => ({
          id: pimSpecification.id,
          type: DataTypePimSpecification.PimSpecification,
        })),
      },
      pricebookProduct: pricebookProduct
        ? {
            data: {
              id: pricebookProduct.id,
              type: DataTypePricebookProduct.PricebookProduct,
            },
          }
        : undefined,
      specifications: {
        data: (specifications ?? []).map(specification => ({
          id: specification.id,
          type: DataTypeSpecification.Specification,
        })),
      },
      tankLocations: {
        data: tankLocations.map(tankLocation => ({
          id: tankLocation.id,
          type: DataTypeTankLocation.TankLocation,
        })),
      },
    },
    type: DataTypeProduct.Product,
  }

  return {
    data: product,
    included: uniqBy(
      [
        ...documents.flatMap(document => document.data ?? []),
        ...documents.flatMap(document => document.included || []),
        ...(pimSpecifications || []),
        ...(pricePoints || []),
        pricebook,
        pricebookProduct,
        ...(specifications || []),
        ...tankLocations,
        baseProduct?.data || product,
        ...favoriteItems.flatMap(favoriteItem => favoriteItem.data || []),
        ...favoriteItems.flatMap(favoriteItem => favoriteItem.included || []),
      ].filter(Boolean),
      resource => resource?.id,
    ) as SingleIncludedProductResourceWithPricebookProductUnion['included'],
  }
}
