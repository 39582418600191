import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 20H2V9.13l2 1.77V18h16V8.23l-8 7.11L2 6.45V4h16.73l-2.25 2H4.51L12 12.66l10-8.89z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMail
