import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUnlocked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 21H3V11h2v8h14v-6H7V7c0-2.76 2.24-5 5-5s5 2.24 5 5h-2c0-1.65-1.35-3-3-3S9 5.35 9 7v4h12z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUnlocked
