import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconNews = createIcon({
  displayName: 'IconNews',
  path: [
    <path
      d="M19 18V7H21V18C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18V5H5V16C5 17.65 6.35 19 8 19H13.1C13.25 19.73 13.57 20.39 14 20.97V21H8C5.24 21 3 18.76 3 16V3H17V18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M7 7H13V9H7V7Z" fill="currentColor" key="1" />,
    <path d="M7 11H13V13H7V11Z" fill="currentColor" key="2" />,
    <path d="M13 15H7V17H13V15Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
