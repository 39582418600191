import { APIInvoice } from '@brenntag/connect-apis/api'
import { InvoiceStatus } from '@brenntag/connect-apis/api/models/invoice'
import { ManyIncludedInvoiceResourceUnion } from '@brenntag/connect-apis/api/types'

import { transformAPIInvoicesToInvoiceList } from '#shared/apis/api/invoices/transforms/invoice.transforms.js'
import { convertFiltersToParams } from '#shared/apis/convertFiltersToParams.js'
import { request } from '#shared/apis/request.js'
import { deserialize } from '#shared/utils/deserialize.js'
import { config } from '#src/config.js'

export const invoiceListSortKeys = [
  '-invoiceDate',
  'invoiceDate',
  '-dueDate',
  'dueDate',
  '-grossAmount.amount',
  'grossAmount.amount',
  'lastUpdateDate',
  '-lastUpdateDate',
] as const

export interface InvoiceFilters {
  dueDateGreaterEqual?: Date
  dueDateLessEqual?: Date
  invoiceDateGreaterEqual?: Date
  invoiceDateLessEqual?: Date
  orderId?: string
  searchQuery?: string
  status?: InvoiceStatus
}

export type InvoiceListSortKey = (typeof invoiceListSortKeys)[number]

type InvoiceFilter = keyof InvoiceFilters

export const invoiceFilters = {
  dueDateGreaterEqual: 'filter[dueDate.gte]',
  dueDateLessEqual: 'filter[dueDate.lte]',
  invoiceDateGreaterEqual: 'filter[invoiceDate.gte]',
  invoiceDateLessEqual: 'filter[invoiceDate.lte]',
  orderId: 'filter[delivery.order.id.equals]',
  searchQuery: 'search[query]',
  status: 'filter[status.in]',
} satisfies Record<InvoiceFilter, string>

interface Params {
  filters: InvoiceFilters
  include?: string
  pageNumber: number
  pageSize: number
  sort: InvoiceListSortKey
}

export const fetchInvoices = async ({ filters, include, pageNumber, pageSize, sort }: Params) => {
  const response = await request<ManyIncludedInvoiceResourceUnion>({
    headers: { Accept: 'application/json' },
    method: 'GET',
    params: {
      include,
      'page[number]': pageNumber,
      'page[size]': pageSize,
      ...convertFiltersToParams(invoiceFilters, filters),
      sort,
    },
    url: `${config.apiBaseUrl}/api/invoices`,
  })

  const apiInvoices = deserialize<APIInvoice[]>(response.body)
  const meta = response.body?.meta
  return transformAPIInvoicesToInvoiceList(apiInvoices, meta, pageSize)
}
