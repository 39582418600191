import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLoginFailed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m8.877 4.635-1.5-1.5A9.94 9.94 0 0 1 12 2c5.514 0 10 4.486 10 10a9.94 9.94 0 0 1-1.135 4.623l-1.5-1.5A7.95 7.95 0 0 0 20 12c0-4.411-3.589-8-8-8a7.95 7.95 0 0 0-3.123.635M12 20c-4.411 0-8-3.589-8-8a7.95 7.95 0 0 1 .635-3.123l-1.5-1.5A9.94 9.94 0 0 0 2 12c0 5.514 4.486 10 10 10a9.94 9.94 0 0 0 4.623-1.135l-1.5-1.5A7.95 7.95 0 0 1 12 20M2.293 3.707l18 18 1.414-1.414-18-18z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLoginFailed
