import { ofetch } from 'ofetch'

import { config } from '#config'
import { SalesGroup } from '#types/sales-group.js'

export type FetchSalesGroupsResponse = { data: SalesGroup[]; meta: { count: number } }

export const fetchSalesGroups = async (): Promise<FetchSalesGroupsResponse> => {
  const { getBaseUrl } = config

  return await ofetch<FetchSalesGroupsResponse>(`${getBaseUrl()}/customer/sales-groups`)
}
