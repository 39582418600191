import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPhoneOut = createIcon({
  displayName: 'IconPhoneOut',
  path: [
    <path
      d="M19.5 22H22V20.02H19.5C10.95 20.02 4 13.07 4 4.52V4.02H8.45L10.76 7.86L7.65 10.97L8.07 11.64C9.16 13.38 10.65 14.88 12.39 15.96L13.06 16.38L16.17 13.27L20.01 15.58V18.01H22.01V14.44L15.85 10.75L12.78 13.82C11.78 13.1 10.91 12.22 10.19 11.23L13.26 8.16L9.57 2H2V4.5C2 14.15 9.85 22 19.5 22Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M19 9H21V3H15V5H19V9Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
