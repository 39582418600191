import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconVolumeHigh = createIcon({
  displayName: 'IconVolumeHigh',
  path: [
    <path
      d="M14 4.26001V2.20001C18.56 3.13001 22 7.17001 22 12C22 16.83 18.56 20.87 14 21.8V19.74C17.44 18.85 20 15.72 20 12C20 8.28001 17.44 5.15001 14 4.26001Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M12 21.41L6.59 16H2V8.00003H6.59L12 2.59003V11H10V7.41003L7.41 10H4V14H7.41L10 16.59V13H12V21.41Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M14 6.35001V8.56001C15.19 9.25001 16 10.53 16 12C16 13.47 15.19 14.75 14 15.44V17.65C16.33 16.82 18 14.61 18 12C18 9.39001 16.33 7.18001 14 6.35001Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
