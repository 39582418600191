import React from 'react'

import { cn } from '#utils/cn.js'

interface Props {
  children: React.ReactNode
  className?: string
}
export const AppHeaderMainBar: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'container mx-auto flex items-center justify-between gap-3 p-4 lg:gap-x-8 lg:px-8',
        className,
      )}>
      {children}
    </div>
  )
}
