import { Cart } from '@brenntag/connect-apis/api-cart/models/cart'

import { fetchCartById } from '#shared/apis/api-cart/cart/endpoints/fetchCartById.js'
import { request } from '#shared/apis/request.js'
import { config } from '#src/config.js'

export interface CancelCheckoutParams {
  cartId: string
  shipToLocationId: string
}
let cancelCheckoutInProgress = false

export class CancelCheckoutInProgressError extends Error {}

export const cancelCheckout = async ({
  cartId,
  shipToLocationId,
}: CancelCheckoutParams): Promise<Cart> => {
  if (cancelCheckoutInProgress) throw new CancelCheckoutInProgressError()
  try {
    cancelCheckoutInProgress = true
    await request({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
      url: `${config.apiBaseUrl}/api-cart/carts/${cartId}/orders`,
    })
    return fetchCartById({ cartId, shipToLocationId })
  } finally {
    cancelCheckoutInProgress = false
  }
}
