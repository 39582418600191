import { http, HttpResponse } from 'msw'

import { ChangePasswordParams } from '#shared/apis/api-user/me/endpoints/changePassword.js'
import { config } from '#src/config.js'

export const changePassword = () => {
  return http.post<never, ChangePasswordParams, null>(
    `${config.apiBaseUrl}/api-user/me/change-password`,
    async () => {
      return HttpResponse.json(null, { status: 200 })
    },
  )
}
