import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCompass = createIcon({
  displayName: 'IconCompass',
  path: [
    <path
      d="M18.31 7.11L19.74 5.68C21.15 7.4 22 9.6 22 12C22 17.51 17.51 22 12 22C6.49 22 2 17.51 2 12C2 6.49 6.49 2 12.01 2C14.4 2 16.61 2.85 18.33 4.26L16.9 5.69C15.54 4.63 13.85 4 12 4C7.59 4 4 7.6 4 12.01C4 16.42 7.59 20.01 12 20.01C16.41 20.01 20 16.42 20 12.01C20 10.16 19.36 8.47 18.31 7.11Z"
      fill="currentColor"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M9.21187 9.20992L6.42188 17.5799L14.7919 14.7899L17.5819 6.41992L9.21187 9.20992ZM9.58187 14.4199L10.7919 10.7899L14.4219 9.57992L13.2119 13.2099L9.58187 14.4199Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
