import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 21H3V10h4.62l2 4h4.76l2-4H19V5H5v3H3V3h18v9h-3.38l-2 4H8.38l-2-4H5v7h14v-5h2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgInbox
