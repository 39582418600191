import React from 'react'

import { Input } from '#shared/components/Input/Input.js'
import { IconByName } from '#shared/icons/brand/index.js'

interface Props {
  onChange: (value: string) => void
}
export const Search = React.forwardRef<HTMLInputElement, Props>(({ onChange }, ref) => {
  return (
    <Input
      leftInsert={<IconByName boxSize={6} name="IconSearch" />}
      onChange={e => onChange(e.target.value)}
      placeholder="Search"
      ref={ref}
      size="lg"
      sx={{
        '&::placeholder': {
          color: 'neutral.50',
        },
      }}
    />
  )
})

Search.displayName = 'Search'
