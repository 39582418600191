import React from 'react'

import { useParams } from 'react-router'

import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { CountryLanguageSelectorInPopover } from '#shared/components/CountryLanguageSelector/CountryLanguageSelectorInPopover.js'
import { CountryLanguageSelectorOption } from '#shared/components/CountryLanguageSelector/types/types.js'
import { useNavigate } from '#shared/routing/hooks/useNavigate.js'

export const CountrySelector: React.FC = () => {
  const params = useParams<{ locale?: string }>()
  const { countryCode, languageCode } = parseLocale(params.locale)
  const navigate = useNavigate()

  const handleCountryLanguageSelect = ({
    countryCode,
    languageCode,
  }: CountryLanguageSelectorOption) => {
    navigate(`/${countryCode}-${languageCode}`)
  }

  const selectedOption = countryCode
    ? ({
        countryCode,
        languageCode,
      } as CountryLanguageSelectorOption)
    : undefined

  return (
    <CountryLanguageSelectorInPopover
      onOptionSelect={handleCountryLanguageSelect}
      selectedOption={selectedOption}
    />
  )
}
