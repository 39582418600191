/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO - Rewrite or find an open source deserializer

const initializeFlatResource = (flatResources: any, resource: any) => {
  if (!flatResources[resource['type']]) {
    flatResources[resource['type']] = {}
  }
  if (!flatResources[resource['type']][resource['id']]) {
    flatResources[resource['type']][resource['id']] = {
      id: resource['id'],
    }
  }

  return flatResources[resource['type']][resource['id']]
}

const parseRelationship = (flatResources: any, relationship: any) => {
  if (Array.isArray(relationship)) {
    return relationship.map(resource => initializeFlatResource(flatResources, resource))
  } else if (relationship) {
    return initializeFlatResource(flatResources, relationship)
  } else {
    return relationship
  }
}

const parseResource = (flatResources: any, resource: any) => {
  const flatResource = initializeFlatResource(flatResources, resource)

  if (resource.relationships) {
    for (const name in resource.relationships) {
      flatResource[name] = parseRelationship(flatResources, resource.relationships[name].data)
    }
  }

  if (resource.attributes) {
    for (const name in resource.attributes) {
      flatResource[name] = resource.attributes[name]
    }
  }

  return flatResource
}

const removeHTMLTags = (obj: any, recursionCount = 0) => {
  if (recursionCount > 10) return
  for (const attrib in obj) {
    if (typeof obj[attrib] == 'string') obj[attrib] = obj[attrib].replace(/<[^>]*>?/gm, '')
    else if (typeof obj[attrib] == 'object') removeHTMLTags(obj[attrib], ++recursionCount)
  }
}

// Recursion count is band-aid, fix deserializer to not build circular object structures
const removeNulls = (obj: any, recursionCount = 0) => {
  if (recursionCount > 10) return
  const isArray = obj instanceof Array
  for (const k in obj) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (obj[k] === null) isArray && typeof k === 'number' ? obj.splice(k, 1) : delete obj[k]
    else if (typeof obj[k] == 'object') removeNulls(obj[k], ++recursionCount)
  }
}

// This serializer creates circular dependencies!!!!
export const deserialize = <T>(json: any): T => {
  const flatResources = {}

  if (json.included) {
    json.included.forEach((resource: any) => parseResource(flatResources, resource))
  }
  let result
  if (Array.isArray(json.data)) {
    result = json.data.map((resource: any) => parseResource(flatResources, resource))
  } else {
    result = parseResource(flatResources, json.data)
  }
  removeNulls(result)
  removeHTMLTags(result)
  return result
}
