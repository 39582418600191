import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCalendarRequest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 4v2H3V4h3V2h2v2h8V2h2v2zM3 21h18v-9h-2v7H5v-9h1.75V8H3zM21 10V8h-3.75v2z"
      fill="currentColor"
    />
    <path
      d="m13 13.914 2-2V10.5c0-1.654-1.346-3-3-3s-3 1.346-3 3h2a1.001 1.001 0 0 1 2 0v.586l-2 2V14.5h2zM11 16.5a1 1 0 1 0 2 0 1 1 0 0 0-2 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgCalendarRequest
