import { loadHiddenFrame } from './loadHiddenFrame'
import { SalesforceTokens } from './types'

const AUTHORIZE_TIMEOUT_MS = 15000

/**
 * Retrieves Salesforce tokens from the hidden iframe that calls the Salesforce authorize endpoint.
 * @param authorizeURL - The Salesforce authorize URL.
 */
export const acquireTokens = (
  authorizeURL: string,
  withIframe: boolean = false,
): Promise<SalesforceTokens | undefined> => {
  return new Promise((resolve, reject) => {
    if (!withIframe) {
      window.location.href = authorizeURL
      return
    }

    const iframe = loadHiddenFrame(authorizeURL)
    const timeoutId = setTimeout(() => {
      iframe.remove()
      reject('Salesforce authorization timed out')
    }, AUTHORIZE_TIMEOUT_MS)

    const handleEvent = ({ data }: MessageEvent) => {
      if (data?.type === 'salesforceTokenError') {
        clearTimeout(timeoutId)
        iframe.remove()
        window.removeEventListener('message', handleEvent)
        return reject(data?.error)
      }
      if (data?.type === 'salesforceTokens') {
        clearTimeout(timeoutId)
        iframe.remove()
        window.removeEventListener('message', handleEvent)
        return resolve(data?.data)
      }
    }

    window.addEventListener('message', handleEvent)
  })
}
