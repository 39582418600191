import React, { FC } from 'react'

import { basicLogger, LDOptions, LDProvider } from 'launchdarkly-react-client-sdk'

import { environment, isMockMode } from '#src/env.js'

const errorLogger = basicLogger({ level: 'error' })

const ldOptionsPerEnv: Record<string, LDOptions> = {
  acceptance: { logger: errorLogger },
  development: { logger: errorLogger },
  local: { logger: errorLogger },
  mock: { logger: errorLogger, streaming: false },
  production: { logger: errorLogger },
}

const getLdOptions = (): LDOptions => {
  if (isMockMode) {
    return ldOptionsPerEnv.mock
  }
  return ldOptionsPerEnv[environment] || ldOptionsPerEnv.local
}

interface Props {
  children?: React.ReactNode
}

export const LaunchDarklyProvider: FC<Props> = ({ children }) => {
  return (
    <LDProvider
      clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID}
      options={getLdOptions()}>
      {children}
    </LDProvider>
  )
}
