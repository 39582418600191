import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  fetchSalesGroups,
  FetchSalesGroupsResponse,
} from '@brenntag/api-customer/fetch-sales-groups'

export const useFetchSalesGroups = (): UseQueryResult<FetchSalesGroupsResponse> => {
  return useQuery({
    // we never want to garbage collect this query
    gcTime: Infinity,
    queryFn: () => fetchSalesGroups(),
    queryKey: ['FetchSalesGroups'],
    // we never need to refetch this query
    staleTime: Infinity,
  })
}
