import { theme as chakraTheme, ComponentStyleConfig } from '@chakra-ui/react'

const { baseStyle: baseStyleFn } = chakraTheme.components.Switch

export const Switch: ComponentStyleConfig = {
  baseStyle: props => {
    const baseStyle = baseStyleFn && baseStyleFn(props)
    const { colorScheme } = props
    const classThumb = '& > [class*="_thumb"]'

    return {
      thumb: {
        ...baseStyle?.thumb,
        _active: {
          background: 'var(--chakra-colors-neutral-80) !important',
          outline: '1px solid',
          outlineColor: 'neutral.80',
        },
        _checked: {
          _active: {
            outline: '1px solid',
            outlineColor: 'white',
          },
          background: 'white !important',
        },
        _focus: {
          background: 'neutral.70',
        },
        _hover: {
          background: 'neutral.90',
        },
        background: 'neutral.70',
        outline: 'unset',
      },
      track: {
        ...baseStyle?.track,
        _active: {
          borderColor: 'var(--chakra-colors-neutral-80) !important',
        },
        _checked: {
          _active: {
            background: `var(--chakra-colors-${colorScheme || 'blue'}-60) !important`,
            borderColor: `var(--chakra-colors-${colorScheme || 'blue'}-60) !important`,
          },
          _disabled: {
            background: 'neutral.30',
            borderColor: 'neutral.30',
            [classThumb]: {
              background: 'white',
            },
            pointerEvents: 'none',
          },
          _focus: {
            background: colorScheme ? `${colorScheme}.50` : undefined,
            boxShadow: 'outline',
          },
          _hover: {
            background: colorScheme ? `${colorScheme}.40` : 'blue.50',
            borderColor: colorScheme ? `${colorScheme}.40` : 'blue.50',
          },
          background: colorScheme ? `${colorScheme}.50` : 'unset',
          backgroundOrigin: 'border-box',
          bgGradient: colorScheme ? undefined : `linear(to-r, violet.50, blue.50)`,
          borderColor: colorScheme ? `${colorScheme}.50` : 'transparent',
          borderRadius: 'unset !important',
          transition: 'unset',
        },
        _disabled: {
          borderColor: 'neutral.30',
          [classThumb]: {
            background: 'neutral.30',
          },
          opacity: '1 !important',
          pointerEvents: 'none',
        },
        _focus: {
          borderColor: 'neutral.70',
          boxShadow: 'outline',
        },
        _hover: {
          borderColor: 'neutral.90',
        },
        alignItems: 'center',
        background: 'white',
        border: '2px solid',
        borderColor: 'neutral.70',
        borderRadius: 'unset !important',
        transition: 'unset',
      },
    }
  },
  defaultProps: {
    colorScheme: '',
    size: 'md',
  },
}
