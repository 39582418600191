import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m1.59 12 8.7 8.71 1.42-1.42L4.41 12l7.3-7.29-1.42-1.42z" fill="currentColor" />
    <path d="m8.24 13-1-1 1-1H22v2z" fill="currentColor" />
  </svg>
)
export default SvgArrowLeft
