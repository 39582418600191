import * as React from 'react'
import type { SVGProps } from 'react'
const SvgReload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 21c4.633 0 8.448-3.5 8.945-8H18.93A7.001 7.001 0 0 1 5 12a7 7 0 0 1 10.604-6.002L17 6l-.009-1.49A9 9 0 1 0 12 21"
      fill="currentColor"
    />
    <path d="M15 10h6V4h-2v4h-4z" fill="currentColor" />
  </svg>
)
export default SvgReload
