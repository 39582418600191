import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUntrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M13 4V2h-2v2H3v2h18V4zM19 22H5V8h2v12h10V8h2z" fill="currentColor" />
    <path
      d="m13.79 9.79 1.42 1.42-1.8 1.79 1.8 1.79-1.42 1.42-1.79-1.8-1.79 1.8-1.42-1.42 1.8-1.79-1.8-1.79 1.42-1.42 1.79 1.8z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUntrash
