import { Profile } from '@brenntag/connect-apis/api-user/types'

export const profiles: Profile[] = [
  {
    name: 'Jane Doe',
    userId: '2',
  },
  {
    name: 'John Doe',
    userId: '1',
  },
]
