import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSomethingWrong = createIcon({
  displayName: 'IconSomethingWrong',
  path: [
    <path
      d="M38.46,90.89a34.49,34.49,0,0,0-10.09-.54l9.77-17.9V30.23l-1.38-.69a3.1,3.1,0,0,1-1.71-2.78,3.07,3.07,0,0,1,1.28-2.5h18.8a3,3,0,0,1,1.29,2.5,3.11,3.11,0,0,1-1.72,2.78l-1.38.69V72.45l16.2,29.66C52,110.31,53.71,94.25,38.46,90.89ZM57,112.43a1.51,1.51,0,0,1,0,2.12A1.53,1.53,0,0,1,56,115a1.49,1.49,0,0,1-1.06-.44l-.46-.46-.46.46a1.53,1.53,0,0,1-1.06.44,1.5,1.5,0,0,1-1.06-2.56l.46-.46-.46-.46A1.5,1.5,0,1,1,54,109.39l.46.46.46-.46a1.49,1.49,0,0,1,2.12,0,1.51,1.51,0,0,1,0,2.12l-.46.46Zm.3,12.94a1.5,1.5,0,0,1-2,.75c-10.44-4.74-18.52,2.28-18.86,2.58a1.51,1.51,0,0,1-2.12-.12,1.5,1.5,0,0,1,.12-2.12c.4-.35,9.85-8.64,22.1-3.07a1.5,1.5,0,0,1,.75,2ZM34.42,111.51a1.5,1.5,0,0,1,2.13-2.12l.45.46.46-.46a1.5,1.5,0,1,1,2.13,2.12l-.46.46.46.46a1.51,1.51,0,0,1,0,2.12,1.51,1.51,0,0,1-2.13,0l-.46-.46-.45.46a1.51,1.51,0,0,1-2.13,0,1.51,1.51,0,0,1,0-2.12l.46-.46Zm22-92.25.53.26A8.1,8.1,0,0,1,58.55,33l-.23.18v38l32.3,59.14a7.69,7.69,0,0,1-6.57,11.17H7.7a7.68,7.68,0,0,1-7-10.9l.13-.27,32.3-59.14v-38a8.1,8.1,0,0,1,1.13-13.48l.25-.13.53-.26Zm-3.4,66a2.43,2.43,0,1,0,2.43,2.43A2.43,2.43,0,0,0,53,85.22ZM42.43,56.51a3.43,3.43,0,1,0,3.43,3.43A3.44,3.44,0,0,0,42.43,56.51Zm0,2A1.43,1.43,0,1,1,41,59.94,1.43,1.43,0,0,1,42.43,58.51ZM48.16,43a2.43,2.43,0,1,0,2.42,2.43A2.43,2.43,0,0,0,48.16,43ZM39.72,10.82l.09.08.19.2.2-.2a1,1,0,0,1,1.41,0,1,1,0,0,1,.09,1.32l-.09.1-.19.19.19.19a1,1,0,0,1-.7,1.71,1,1,0,0,1-.61-.2l-.1-.09-.2-.19-.19.19a1,1,0,0,1-1.5-1.32l.09-.1.19-.19-.19-.19a1,1,0,0,1,1.32-1.5ZM59.53,7.9l1.9,1.9a1,1,0,0,1-.7,1.71,1,1,0,0,1-.61-.2l-.1-.09-1.9-1.9a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29A1,1,0,0,0,59.53,7.9Zm-7.12,0a1,1,0,0,0,0,1.42,1,1,0,0,0,1.31.09l.1-.09-1.9,1.9a1,1,0,0,1-1.5-1.32l.09-.1ZM51.83.21l.09.08,1.9,1.91a1,1,0,0,0-1.41,0,1,1,0,0,0-.08,1.32l.08.09-1.9-1.9a1,1,0,0,1,0-1.42A1,1,0,0,1,51.83.21Zm9.6.08a1,1,0,0,1,.09,1.32l-.09.1-1.9,1.9a1,1,0,0,0-1.32-1.5l-.09.08L60,.29A1,1,0,0,1,61.43.29Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
  ],
  viewBox: '0 0 91.46 141.48',
})
