import * as React from 'react'
import type { SVGProps } from 'react'
const SvgView = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m17.5 7.91 1.41-1.41L23 10.59v2.83l-5.17 5.17A8.23 8.23 0 0 1 12 21c-2.2 0-4.27-.85-5.83-2.41L1 13.42v-2.83l5.17-5.17A8.23 8.23 0 0 1 12 3.01c2.04 0 3.97.75 5.48 2.1l-1.41 1.41A6.18 6.18 0 0 0 12 5c-1.66 0-3.23.65-4.41 1.83L3 11.42v1.17l4.59 4.58A6.19 6.19 0 0 0 12 19c1.66 0 3.23-.65 4.41-1.83L21 12.58v-1.17z"
      fill="currentColor"
    />
    <path
      d="M15 12c0-.47-.11-.9-.3-1.29l.01.01 1.47-1.47c.52.79.83 1.73.83 2.75 0 2.76-2.24 5-5 5s-5-2.24-5-5 2.24-5 5-5c1.01 0 1.96.31 2.75.83L13.29 9.3c-.4-.19-.83-.3-1.29-.3-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3"
      fill="currentColor"
    />
  </svg>
)
export default SvgView
