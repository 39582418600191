import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m8.71 21.71-1.42-1.42 8.3-8.29-8.3-8.29 1.42-1.42 9.7 9.71z" fill="currentColor" />
  </svg>
)
export default SvgChevronRight
