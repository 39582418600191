import { RouteObject } from 'react-router'

import { validCountryLanguageLoader } from '../loaders/validCountryLanguageLoader'

export const co2XplorerRoutes: RouteObject = {
  children: [
    {
      lazy: () => import('#modules/co2-xplorer/pages/RequestAccess.js'),
      path: 'request-access',
    },
    {
      // Change this to CalculationReportMocked to preview the PDF in the browser
      // so you don't have to download it every time you make a change
      // lazy: () => import('#modules/co2-xplorer/components/CalculationReportMocked.js'),
      lazy: () => import('#modules/co2-xplorer/pages/Co2Xplorer.js'),
      path: 'connect/co2xplorer',
    },
  ],
  id: 'co2XplorerRoutes',
  loader: validCountryLanguageLoader,
}
