import consola from 'consola/browser'
import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'
import { DataTypePricebookProduct } from '@brenntag/connect-apis/api/types'

import { config } from '#src/config.js'

import { favoriteLists } from '../api/favorites/data'
import { products } from '../api/products/data'
import { carts } from './data'

export const postFavoritesToCart = () => {
  return http.post<never, { favoriteId: string }, APICart>(
    `${config.cartApiBaseUrl}/carts/favorites`,
    async ({ request }) => {
      const body = await request.json()
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      if (!shipToId) {
        consola.log('ShipTo not found')
        return HttpResponse.json(null, { status: 400 })
      }

      const cart = carts[shipToId]

      const productIds = favoriteLists
        .list()
        .find(favoriteList => favoriteList.data.id === body.favoriteId)
        ?.included?.map(include => include.relationships.product.data.id)

      const favoriteProducts = products
        .list()
        .filter(product => productIds?.some(productId => productId === product.data.id))

      for (const product of favoriteProducts) {
        const pricebookProduct = (product.included ?? []).find(
          include => include.type === DataTypePricebookProduct.PricebookProduct,
        )
        if (!pricebookProduct) {
          consola.log('PricebookProduct not found')
          continue
        }
        cart.items!.push({
          createdDate: new Date().toISOString(),
          id: Date.now().toString(),
          messagesSummary: {},
          name: product.data.attributes.name,
          productDetails: {
            fields: {},
            name: product.data.attributes.name,
            productId: product.data.id,
            sku: '',
          },
          productId: product.data.id,
          quantity: pricebookProduct.attributes.packagingSize.quantity,
          type: 'product',
        })
      }

      return HttpResponse.json(cart)
    },
  )
}
