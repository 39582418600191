import { useMatches } from 'react-router'

/**
 * This hook is used to return the current route matches on the page
 */

export const useMatchAccessRoute = () => {
  const matches = useMatches()

  const isPublicRoute = matches.some(match => match.id === 'publicRoutes')
  return {
    isPublicRoute,
  }
}
