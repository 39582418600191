import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M21 21h-7v-2h5v-7.59l-7-7-7 7V19h4v-6h5v2h-3v6H3V10.59l9-9 9 9z" fill="currentColor" />
  </svg>
)
export default SvgHome
