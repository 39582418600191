import type { ConnectLocation } from '@brenntag/api-customer'

import { getCountries } from '#lib/countries/getCountries.js'
import { translateCountryInLanguage } from '#shared/locale/hooks/useIntl.js'

const countries = getCountries()

export const toLocationAddressWithName = ({ address, name }: ConnectLocation): string => {
  if (!address) return ''

  const country = countries.find(c => c.code === address?.country)

  return [
    name,
    address.street,
    [address.postalCode, address.city].filter(Boolean).join(', '),
    country
      ? translateCountryInLanguage({
          countryCode: country.code,
          languageCode: country.defaultLanguage,
        })
      : '',
  ]
    .filter(Boolean)
    .join('\n')
}

export const toLocationAddress = ({ address }: ConnectLocation): string => {
  if (!address) return ''

  const country = countries.find(c => c.code === address?.country)
  return [
    address.street,
    address.postalCode,
    address.city,
    address.state,
    country
      ? translateCountryInLanguage({
          countryCode: country.code,
          languageCode: country.defaultLanguage,
        })
      : '',
  ]
    .filter(Boolean)
    .join(', ')
}

export const locationToString = (location: ConnectLocation | undefined): string => {
  if (!location) return ''

  const { city, country, postalCode, street } = location.address || {}

  return [location.name, street, postalCode, city, country]
    .filter(str => str && str.trim().length > 0)
    .join('\n')
}
