import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDownload = createIcon({
  displayName: 'IconDownload',
  path: [
    <path d="M13 10.76L12 11.76L11 10.76V2H13V10.76Z" fill="currentColor" key="0" />,
    <path
      d="M12 17.41L5.29 10.71L6.71 9.29L12 14.59L17.29 9.29L18.71 10.71L12 17.41Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M20 21V19H4V21H20Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
