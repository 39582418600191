import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconEdit = createIcon({
  displayName: 'IconEdit',
  path: [
    <path
      d="M12 6.59L17 1.59L22.41 7L13.41 16H8V10.59L10.59 8L12 9.41L10 11.41V14H12.59L19.59 7L17 4.41L13.41 8L12 6.59Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M19 19V13.24L21 11.24V21H3V3H12.76L10.76 5H5V19H19Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
