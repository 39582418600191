/* tslint:disable */
/* eslint-disable */
/**
 * Case Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum CaseCategory {
    Product = 'Product',
    Packaging = 'Packaging',
    Quantity = 'Quantity',
    Time = 'Time',
    PriceInvoicing = 'Price/Invoicing',
    Documentation = 'Documentation',
    Safety = 'Safety'
}


