import * as React from 'react'
import type { SVGProps } from 'react'
const SvgThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6 20h13.41L22 17.41V7h-8V3.59L12.41 2H8v4.7l-2 3V16h2v-5.7l2-3V4h1.59l.41.41V9h8v7.59L18.59 18H6zM2 10h2v10H2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgThumbsUp
