import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPause = createIcon({
  displayName: 'IconPause',
  path: [
    <path d="M8 7L8 18H10L10 7L8 7Z" fill="currentColor" key="0" />,
    <path d="M14 7L14 18H16L16 7L14 7Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
