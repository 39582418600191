import * as React from 'react'
import type { SVGProps } from 'react'
const SvgStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 8.16 15.83 7.1 13.1 2h-2.2L8.17 7.1 2 8.16v2.25l3.88 3.88L3.67 22H6.3l5.7-3.8 5.7 3.8h2.63l-2.21-7.71L22 10.41z"
      fill="currentColor"
    />
  </svg>
)
export default SvgStarFilled
