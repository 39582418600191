import { cn } from '@brenntag/ui/utils/cn'

import {
  FeatureFlags as FeatureFlagsType,
  useFeatureFlags,
} from '#shared/hooks/accessControl/useFeatureFlags.js'

import { BooleanFeatureFlag } from './components/BooleanFeatureFlag'
import { ObjectFeatureFlag } from './components/ObjectFeatureFlag'
import { StringFeatureFlag } from './components/StringFeatureFlag'

export const FeatureFlags = () => {
  const { featureFlags, isLocalFeatureFlag } = useFeatureFlags()
  const localOverrides = Object.keys(featureFlags).filter(isLocalFeatureFlag)

  return (
    <section className="border border-gray-200">
      <h2
        className={cn(
          'text-primary flex justify-between gap-2 border-b border-gray-200 bg-gray-50 p-2 text-base font-semibold',
        )}>
        <span>Feature Flags</span>
        <span className="text-blue-50">
          {localOverrides.length} local override{localOverrides.length === 1 ? '' : 's'}
        </span>
      </h2>
      <dl className="-my-3 divide-y divide-gray-100 px-3 py-2 text-xs">
        {Object.entries(featureFlags).map(([key, value]) => {
          const flag = key as keyof FeatureFlagsType

          if (typeof value === 'boolean') {
            return <BooleanFeatureFlag flag={flag} key={key} />
          } else if (typeof value === 'string') {
            return <StringFeatureFlag flag={flag} key={key} value={value} />
          } else {
            return (
              <ObjectFeatureFlag flag={flag} key={key} value={JSON.stringify(value, null, 2)} />
            )
          }
        })}
      </dl>
    </section>
  )
}
