import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCircleFilled = createIcon({
  displayName: 'IconCircleFilled',
  path: [
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
