import { SingleIncludedProductResourceWithPricebookProductUnion } from '@brenntag/connect-apis/api/types'
import { buildPricePoint } from '@brenntag/legacy-mock-generators/generators/buildPricePoint.js'
import { buildPricebookProduct } from '@brenntag/legacy-mock-generators/generators/products/buildPricebookProduct.js'
import { buildProduct } from '@brenntag/legacy-mock-generators/generators/products/buildProduct.js'

import { pricebooks } from '#src/mocks/apis/data/pricebooks.js'

const pricepointValues = [1, 4, 8, 16, 32, 64, 128, 256, 512, 1024].reverse()
const id = 'manyPricePoints'

export const manyPricePoints: SingleIncludedProductResourceWithPricebookProductUnion = buildProduct(
  {
    attributes: {
      name: 'Many pricepoints',
    },
    id,
    included: {
      pricebook: pricebooks.allLocations,
      pricebookProduct: buildPricebookProduct({
        id,
        pricebookId: pricebooks.allLocations.id,
        pricePointIds: pricepointValues.map((_, i) => `pricePoint${i}`),
        productId: id,
      }),
      pricePoints: pricepointValues.map((price, i) =>
        buildPricePoint({
          id: `pricePoint${i}`,
          price,
          relationships: { pricebookProductId: id },
          scaleStart: i * i,
        }),
      ),
    },
  },
)
