import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTruck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.54 7H16V5H2v2h12v9H8.22c-.55-.61-1.34-1-2.22-1-.77 0-1.47.3-2 .78V9H2v9h1c0 1.65 1.35 3 3 3s3-1.35 3-3h6c0 1.65 1.35 3 3 3s3-1.35 3-3h1v-7.3zM6 19c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1M18.46 9 20 11.3v4.48c-.53-.48-1.23-.78-2-.78s-1.47.3-2 .78V9zM18 19c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1"
      fill="currentColor"
    />
  </svg>
)
export default SvgTruck
