import React, { FC } from 'react'

import { Outlet } from 'react-router'

import { Chat } from '#shared/components/Chat/Chat.js'
import { CustomerSelector } from '#shared/components/CustomerSelector/CustomerSelector.js'
import { Footer } from '#shared/components/Footer/Footer.js'
import { Header } from '#shared/components/Header/Header.js'
import { QuestionPro } from '#shared/components/QuestionPro/QuestionPro.js'
import { ResetCart } from '#shared/components/ResetCart/ResetCart.js'
import { useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'
import { PageLayout } from '#shared/layouts/PageLayout.js'
import { ErrorBoundary } from '#shared/pages/Error/ErrorBoundary.js'
import { config } from '#src/config.js'

export const PrivateLayout: FC = () => {
  const {
    featureFlags: { enableCartV2 },
  } = useFeatureFlags()

  return (
    <>
      <CustomerSelector />
      <Header />
      <PageLayout>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </PageLayout>
      <Footer />
      <Chat />
      {config.questionPro.enabled && <QuestionPro />}
      {!enableCartV2 && <ResetCart />}
    </>
  )
}
