// eslint-disable-next-line no-restricted-imports
import { NonIndexRouteObject, redirect } from 'react-router'

import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { getCountryByCode } from '#lib/countries/getCountryByCode.js'

export const validCountryLanguageLoader: NonIndexRouteObject['loader'] = ({ params, request }) => {
  const { countryCode, languageCode = '' } = parseLocale(params.locale)
  const country = getCountryByCode(countryCode)

  if (country && !country.languageCodes.includes(languageCode || '')) {
    const path = new URL(request.url).pathname
    return redirect(
      `/${country.defaultLanguage}-${country.code}${path.replace(`/${params.locale}`, '')}`,
    )
  }

  return true
}
