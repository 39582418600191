import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconVideo = createIcon({
  displayName: 'IconVideo',
  path: [
    <path
      d="M22 20H19.59L16 16.41V20H2V8H4V18H14V14H16.41L20 17.59V6.41L16.41 10H14V6H2V4H16V7.59L19.59 4H22V20Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
