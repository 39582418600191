import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSocialYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21.58 7.15a2.51 2.51 0 0 0-1.77-1.77C18.25 5 12 5 12 5s-6.25 0-7.81.43A2.51 2.51 0 0 0 2.42 7.2 26.3 26.3 0 0 0 2 12c-.01 1.626.13 3.25.42 4.85a2.51 2.51 0 0 0 1.77 1.77c1.56.43 7.81.43 7.81.43s6.25 0 7.81-.43a2.51 2.51 0 0 0 1.77-1.77c.29-1.6.43-3.224.42-4.85.01-1.626-.13-3.25-.42-4.85M10 15V9l5.23 3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSocialYoutube
