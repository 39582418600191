import { http, HttpResponse } from 'msw'

import { SalesGroup } from '@brenntag/api-customer'

import { config } from '#src/config.js'

import { salesGroups as _salesGroups } from './data/salesGroups'

export const fetchSalesGroups = (data: SalesGroup[] = _salesGroups.basic) => {
  return http.get(`${config.customerApiBaseUrl}/customer/sales-groups`, async () => {
    return HttpResponse.json({
      data,
      meta: { count: data.length },
    })
  })
}
