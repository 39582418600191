import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCut = createIcon({
  displayName: 'IconCut',
  path: [
    <path
      d="M21.39 19.79L8.66 10H6C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2C8.21 2 10 3.79 10 6H8C8 4.9 7.1 4 6 4C4.9 4 4 4.9 4 6C4 7.1 4.9 8 6 8H9.34L22.61 18.21L21.39 19.8V19.79Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M16.1791 10.74L22.6091 5.78996L21.3891 4.20996L14.5391 9.47996L16.1791 10.74Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M4 17.9998C4 19.0998 4.9 19.9998 6 19.9998C7.1 19.9998 8 19.0998 8 17.9998H10C10 20.2098 8.21 21.9998 6 21.9998C3.79 21.9998 2 20.2098 2 17.9998C2 15.7898 3.79 13.9998 6 13.9998H8.66L9.62 13.2598L11.26 14.5198L9.34 15.9998H6C4.9 15.9998 4 16.8998 4 17.9998Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
