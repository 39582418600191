import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFilePdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M4 22h16V6.59L15.41 2H13v7h5v11H6V4h5V2H4zM17.59 7H15V4.41z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.341 14.35c0-.69-.4-1.35-1.38-1.35l-.01-.01h-1.33v4h.8v-1.28h.54c.99 0 1.38-.67 1.38-1.36m-.8 0c0 .44-.17.72-.6.72h-.51v-1.43h.51c.42 0 .6.27.6.71M12.092 13c1.11 0 1.73.78 1.73 1.99s-.62 2.01-1.73 2.01h-1.24v-4zm0 3.34c.59 0 .92-.51.92-1.35s-.33-1.33-.92-1.33h-.44v2.68z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path d="M15.21 13.66h1.43V13h-2.23v4h.8v-1.56h1.33v-.66h-1.33z" fill="currentColor" />
  </svg>
)
export default SvgFilePdf
