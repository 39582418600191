import { atom } from 'jotai'

import { QuoteFilters } from '@brenntag/connect-apis/api/models/quote'

const quoteFilterDefaults: QuoteFilters = {
  page: '1',
  sort: '-number',
} as const

export const quoteFilters = atom<QuoteFilters>(quoteFilterDefaults)
