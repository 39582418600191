import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20.88 17H5.07l-1-13H2V2h3.93l1 13h12.19l.87-7H8V6h14l-.01 2.12zM9 21a1 1 0 1 0 0-2 1 1 0 0 0 0 2M18 20a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgCart
