import { useQueryClient } from '@tanstack/react-query'

import { LoggedInUser } from '@brenntag/connect-apis/api-user/models/user'

import { QueryCacheKey } from '#shared/utils/enums.js'

export const useLoggedInUser = (): LoggedInUser | undefined => {
  const client = useQueryClient()

  return client.getQueryData<LoggedInUser | undefined>([QueryCacheKey.LoggedInUser])
}
