import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconHome = createIcon({
  displayName: 'IconHome',
  path: [
    <path
      d="M21 21H14V19H19V11.41L12 4.41003L5 11.41V19H9V13H14V15H11V21H3V10.59L12 1.59003L21 10.59V21Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
