import * as React from 'react'
import type { SVGProps } from 'react'
const SvgParcel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M16.18 5.45 12 4.05l-4.18 1.4-3.16-1.06L12 1.95l7.34 2.44z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="m2 5.61 10 3.34v-.01l10-3.33v13.11l-10 3.33-10-3.33zm9 5.11L4 8.39v8.89l7 2.33zm2 8.89 7-2.33V8.39l-7 2.33z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgParcel
