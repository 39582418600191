import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDashboard = createIcon({
  displayName: 'IconDashboard',
  path: [
    <path
      d="M15.1211 4.64012L16.6211 3.14012C12.8511 1.18012 8.08109 1.78012 4.92109 4.94012C1.02109 8.84012 1.02109 15.1801 4.92109 19.0801L6.33109 17.6701C3.21109 14.5501 3.21109 9.47012 6.33109 6.36012C8.70109 3.99012 12.1911 3.43012 15.1111 4.66012L15.1211 4.64012Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M19.0005 3.58592L10.5859 12.0005L12.0002 13.4147L20.4147 5.00013L19.0005 3.58592Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M17.6602 17.6601C20.0202 15.2901 20.5902 11.7901 19.3602 8.88012L20.8702 7.37012C22.8302 11.1401 22.2302 15.9101 19.0702 19.0701L17.6602 17.6601Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
