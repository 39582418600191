import * as React from 'react'
import type { SVGProps } from 'react'
const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m5 12.76-2 2V3h18v2H5z" fill="currentColor" />
    <path d="M14 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" fill="currentColor" />
    <path
      d="M3 21h18v-2H5v-.59l6-6 3.07 3.07L19 9.56V17h2V7h-2.47l-4.6 5.52L11 9.59l-8 8z"
      fill="currentColor"
    />
  </svg>
)
export default SvgImage
