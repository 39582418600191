import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDuplicate = createIcon({
  displayName: 'IconDuplicate',
  path: [
    <path d="M3 17H5V5H17V3H3V17Z" fill="currentColor" key="0" />,
    <path d="M7 21H21V11H19V19H9V9H21V7H7V21Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
