import React, { forwardRef, useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { Input } from '#shared/components/Input/Input.js'
import { useSearchInput } from '#shared/hooks/search/useSearchInput.js'
import { IconSearch } from '#shared/icons/brand/index.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface SearchInputProps {
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
  onValueChange: (value: string) => void
  setOpen: (cond: boolean) => void
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onBlur, onFocus, onValueChange, setOpen }, triggerRef) => {
    const { input, updateSearchInput } = useSearchInput()
    const [value, setValue] = useState(input)
    const t = useTranslations()

    const onDebouncedValueChange = useDebouncedCallback((value: string) => {
      updateSearchInput(value)
    }, 300)

    useEffect(() => {
      setValue(input)
    }, [input])

    const submitSearch = () => {
      onDebouncedValueChange(value)
      setOpen(false)
    }

    return (
      <Input
        data-testid="search-input"
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            submitSearch()
          }
        }}
        onValueChange={value => {
          setValue(value)
          onValueChange(value)
        }}
        placeholder={t('filter.placeholder.global')}
        ref={triggerRef}
        rightInsert={
          <IconSearch
            boxSize="24px"
            color="primary"
            cursor="pointer"
            mx={2}
            onClick={submitSearch}
          />
        }
        size="lg"
        type="search"
        value={value}
      />
    )
  },
)
SearchInput.displayName = 'SearchInput'
