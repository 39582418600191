export const shadows = {
  '2xl': '0px 25px 50px rgba(0, 0, 0, 0.25)',
  inner: 'inset 0px 2px 4px rgba(0, 0, 0, 0.06)',

  inputOutline: `inset 0 0 0 1px #1A0033`,
  inputOutlineDisabled: `inset 0 0 0 1px #BDB3C7`,
  inputOutlineFocus: `inset 0 0 0 3px #0C72ED`,
  inputOutlineHover: `inset 0 0 0 2px #1A0033`,
  inputOutlineInvalid: `inset 0 0 0 2px #EE0028`,

  lg: '0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
  md: '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
  outline: `0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #1A0033`,

  sm: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
  xl: '0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)',
}
