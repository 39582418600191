import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOrderShipped = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4.385 9.616 2.971 8.202 5.172 6 2.971 3.798l1.414-1.414L8 6zM22 10.697V18h-1c0 1.654-1.346 3-3 3s-3-1.346-3-3H9c0 1.654-1.346 3-3 3s-3-1.346-3-3H2v-7h2v4.78A2.98 2.98 0 0 1 6 15c.883 0 1.67.391 2.22 1H14V7h-4V5h6v2h3.535zM7 18a1.001 1.001 0 0 0-2 0 1.001 1.001 0 0 0 2 0m12 0a1.001 1.001 0 0 0-2 0 1.001 1.001 0 0 0 2 0m1-6.697L18.465 9H16v6.78a2.98 2.98 0 0 1 2-.78 2.98 2.98 0 0 1 2 .78z"
      fill="currentColor"
    />
  </svg>
)
export default SvgOrderShipped
