import { http, HttpResponse } from 'msw'

import { VasSearchRequest, VasSearchResponse } from '@brenntag/connect-apis/api-vas/types'
import { buildServices } from '@brenntag/legacy-mock-generators/generators/services/buildServices.js'

import { config } from '#src/config.js'

import { valueAddedServices } from './data'

export const searchServices = () => {
  return http.post<{ id: string }, VasSearchRequest, VasSearchResponse>(
    `${config.apiGatewayUrl}/vas-service/search`,
    async ({ request }) => {
      const body = await request.json()
      return HttpResponse.json(
        buildServices({
          filters: body.filters ?? [],
          searchQuery: body.searchQuery,
          services: valueAddedServices.list().map(service => {
            return {
              createdDate: service.createdDate,
              id: service.id,
              number: service.number,
              requiredDate: service.requiredDate ?? '',
              status: service.status,
              type: service.type,
            }
          }),
          sort: body.sort,
        }),
      )
    },
  )
}
