import { TranslationKey } from '@brenntag/connect-translations'

import { Filters } from '#shared/filters/filters.js'
import { FormatDate } from '#shared/locale/hooks/useIntl.js'
import { Translate } from '#shared/locale/hooks/useTranslations.js'

export const isValidDate = (date: Date) => date instanceof Date && !isNaN(date.getTime())

export const shortStringDateToLocalDate = (shortStringDate: string) => {
  const d = new Date(shortStringDate)
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
  return d
}
export const getDateFormatAsPlaceholder = (fd: FormatDate) => {
  const d = shortStringDateToLocalDate('2000-01-03')

  return (
    d &&
    fd(d, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
      .replace('2000', 'YYYY')
      .replace('01', 'MM')
      .replace('03', 'DD')
  )
}

export const dateRangeToPillLabel = (
  t: Translate,
  dateFormatter: FormatDate,
  filters: Filters,
  filterKey: string,
) => {
  const startFilter = filters[`${filterKey}GreaterEqual` as keyof Filters]
  const endFilter = filters[`${filterKey}LessEqual` as keyof Filters]

  return `${t(`filter.pill.${filterKey}` as TranslationKey)} ${
    startFilter ? `${t('filter.from')} ${dateFormatter(startFilter)}` : ''
  } ${endFilter ? `${t('filter.until')} ${dateFormatter(endFilter)}` : ''}`
}

export const pickOnlyOneFromDateRange = (key: string, filters: Filters, dateFilters: string[]) => {
  const result = key.match(new RegExp(`(${dateFilters.join('|')})GreaterEqual`))
  // Returns true if date already exists as LessEqual (to prevent two pills from showing)
  return !(
    result &&
    result.length &&
    filters[`${result[1]}LessEqual` as keyof Filters] !== undefined
  )
}

export function createShortDate(date: Date): string
export function createShortDate(date: undefined): undefined
export function createShortDate(date: Date | undefined): string | undefined
export function createShortDate(date: Date | undefined): string | undefined {
  return date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const isWeekend = (date: Date | undefined): boolean =>
  Boolean(date && [0, 6].includes(date.getDay()))
