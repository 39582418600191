import {
  APIPricePoint,
  APIProduct,
  APIProductPimSpecification,
  APIProductSpecification,
  ManyMeta,
} from '@brenntag/connect-apis/api'
import { PricePoint } from '@brenntag/connect-apis/api/models/price'
import {
  Product,
  ProductList,
  ProductSpecification,
} from '@brenntag/connect-apis/api/models/product'

import { trimLeadingZeros } from '#shared/utils/misc.js'
import { generateTotalPages } from '#shared/utils/pagination.js'
import { getHighestActivePricePoint, getSortedPricePoints } from '#shared/utils/price-utils.js'

// TODO: fix complexity
// eslint-disable-next-line complexity
export const transformAPIProductToProduct = (apiProduct: APIProduct): Product => {
  const tankLocations = apiProduct.tankLocations
  const pricepoints = transformAPIPricePointsToPricePoints(
    apiProduct.pricebookProduct?.pricePoints ?? [],
  )

  return {
    casNumbers: apiProduct.casNumbers,
    customerProductNumber:
      trimLeadingZeros(apiProduct.pricebookProduct?.customerProductNumber) ?? '',
    description: apiProduct.description,
    favoriteItems: (apiProduct.favoriteItems ?? []).map(favoriteItem => ({
      favorite: { id: favoriteItem.favorite.id },
      id: favoriteItem.id,
      isActive: favoriteItem.isActive,
    })),
    highestPricePoint: getHighestActivePricePoint(pricepoints),
    id: apiProduct.id,
    isHazardous: Boolean(apiProduct.isHazardous),
    isOrderable: Boolean(apiProduct.pricebookProduct?.isOrderable),
    name: apiProduct.name,
    number: trimLeadingZeros(apiProduct.number) ?? '',
    packaging: {
      isActive: Boolean(apiProduct.pricebookProduct?.isActive),
      isSdsAvailable: Boolean(apiProduct.pricebookProduct?.isSdsAvailable),
      name: apiProduct.packaging.name,
      // TODO-APP: Once the backend can ensure that the packagingSize is always present, remove the check
      // TODO-APP: Also once they can include poricebook into orders, this can be not optional
      size: apiProduct.pricebookProduct?.packagingSize ?? {
        quantity: 1,
        uom: apiProduct.packaging.type || 'UNKNOWN',
      },
      type: apiProduct.packaging.type,
    },
    pricebook: apiProduct.pricebookProduct?.pricebook?.type && {
      type: apiProduct.pricebookProduct?.pricebook.type,
    },
    pricepoints,
    shippingInformation: {
      ground: Boolean(apiProduct.shippingInformation?.ground),
      isHazardous: Boolean(apiProduct.isHazardous),
      productWeightLbs: apiProduct.shippingInformation?.productWeightLbs,
      shippingTerms: apiProduct.shippingInformation?.shippingTerms,
      shippingWeightLbs: apiProduct.shippingInformation?.shippingWeightLbs,
    },
    specifications: combinedSpecifications(apiProduct.specifications, apiProduct.pimSpecifications),
    tankLocations:
      tankLocations?.map(tankLocation => ({
        id: tankLocation.id,
        name: tankLocation.name,
      })) ?? [],
    type: apiProduct.type,
  }
}

export const transformAPIProductsToProductList = (
  apiProducts: APIProduct[],
  meta: ManyMeta,
  pageSize: number,
): ProductList => {
  return {
    brands: meta?.aggregations?.['brands'] ?? [],
    categories: meta?.aggregations?.['categories']?.[0]?.subCategories ?? [],
    categoriesHash: meta?.aggregations?.['categories_hash'] ?? '',
    packagingTypes: meta?.aggregations?.['packaging-types'] ?? [],
    products: apiProducts.map(transformAPIProductToProduct),
    total: meta?.count || 0,
    totalPages: generateTotalPages(meta?.count, pageSize),
  }
}
const transformAPIPricePointsToPricePoints = (pricePoints: APIPricePoint[]): PricePoint[] =>
  getSortedPricePoints(
    pricePoints
      .filter(pricepoint => pricepoint.isActive)
      .map(pricePoint => ({
        isActive: pricePoint.isActive,
        price: { amount: pricePoint.price.amount, currency: pricePoint.price.currency },
        scaleStart: pricePoint.scaleStart,
      })) || [],
  )

const transformAPIProductSpecificationsToProductSpecification = (
  apiProductSpecification: APIProductSpecification,
): ProductSpecification => ({
  id: apiProductSpecification.id,
  label: apiProductSpecification.label,
  value: apiProductSpecification.value,
})

const transformAPIProductPimSpecificationsToProductPimSpecification = (
  apiProductPimSpecification: APIProductPimSpecification,
): ProductSpecification => ({
  id: apiProductPimSpecification.id,
  label: apiProductPimSpecification.label,
  value:
    typeof apiProductPimSpecification.value === 'object' &&
    apiProductPimSpecification.value !== null
      ? JSON.stringify(apiProductPimSpecification.value)
      : apiProductPimSpecification.value,
})

const combinedSpecifications = (
  apiProductSpecifications?: APIProductSpecification[],
  apiProductPimSpecifications?: APIProductPimSpecification[],
): ProductSpecification[] => {
  const pimSpecifications =
    apiProductPimSpecifications?.map(
      transformAPIProductPimSpecificationsToProductPimSpecification,
    ) ?? []
  const basicSpecifications =
    apiProductSpecifications?.map(transformAPIProductSpecificationsToProductSpecification) ?? []
  return basicSpecifications.concat(pimSpecifications)
}
