import { ComponentStyleConfig } from '@chakra-ui/react'

export const Pagination: ComponentStyleConfig = {
  baseStyle: {
    arrow: {},
    button: {},
  },
  defaultProps: {
    variant: 'numbered',
  },
  variants: {
    dots: {
      button: {
        minWidth: '24px',
      },
    },
    numbered: {
      button: {
        height: 'auto',
        lineHeight: '20px',
        minHeight: 0,
        minWidth: 0,
        paddingBottom: '6px',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: '6px',
      },
    },
  },
}
