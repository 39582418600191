import { ComponentStyleConfig } from '@chakra-ui/react'

export const PhoneNumberInput: ComponentStyleConfig = {
  baseStyle: {
    container: {
      flexDirection: { base: 'column', sm: 'row' },
      w: '100%',
    },
    countryCode: {
      flexGrow: 2,
      maxWidth: { sm: '170px' },
      mr: { sm: 2 },
      w: '100%',
    },
    nationalNumber: {
      flexGrow: 3,
      mt: { base: 1, sm: 0 },
      w: '100%',
    },
  },
  parts: [],
}
