import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCode = createIcon({
  displayName: 'IconCode',
  path: [
    <path
      d="M9.01953 20.7808L13.017 2.77926L14.9694 3.21282L10.972 21.2143L9.01953 20.7808Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M6.28984 16.71L1.58984 12L6.28984 7.29004L7.70984 8.71004L4.40984 12L7.70984 15.29L6.28984 16.71Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M17.7091 16.71L16.2891 15.29L19.5891 12L16.2891 8.71004L17.7091 7.29004L22.4091 12L17.7091 16.71Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
