import { APISample, ManyMeta } from '@brenntag/connect-apis/api'
import { Sample, SampleList } from '@brenntag/connect-apis/api/models/sample'

import { transformAPIProductToProduct } from '#shared/apis/api/products/transforms/product.transforms.js'
import { transformAPILocationToCustomerLocation } from '#shared/apis/api/shared/transforms/location.transforms.js'
import { transformAPIUserToUser } from '#shared/apis/api/shared/transforms/user.transforms.js'
import { trimLeadingZeros } from '#shared/utils/misc.js'
import { generateTotalPages } from '#shared/utils/pagination.js'

const transformAPISampleToSample = (apiSample: APISample): Sample => {
  return {
    id: apiSample.id,
    number: trimLeadingZeros(apiSample.number) ?? '',
    product: transformAPIProductToProduct(apiSample.product),
    requestedDate: apiSample.requestedDate,
    requestedDeliveryDate: apiSample.requestedDate,
    requestQuantity:
      apiSample.requestQuantity?.quantity !== undefined &&
      apiSample.requestQuantity?.uom !== undefined
        ? {
            quantity: apiSample.requestQuantity.quantity,
            uom: apiSample.requestQuantity.uom,
          }
        : undefined,
    shipTo: transformAPILocationToCustomerLocation(apiSample.shipTo),
    status: apiSample.status || 'New',
    user: transformAPIUserToUser(apiSample.user),
  }
}

export const transformAPISamplesToSampleList = (
  apiSamples: APISample[],
  meta: ManyMeta,
  pageSize: number,
): SampleList => {
  return {
    samples: apiSamples.map(transformAPISampleToSample),
    total: meta?.count || 0,
    totalPages: generateTotalPages(meta?.count, pageSize),
  }
}
