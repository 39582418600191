import { CartItem } from '@brenntag/connect-apis/api-cart/models/cart'

export const sortCartItemFunctions: Record<string, (_a: CartItem, _b: CartItem) => number> = {
  '-cartAddedDate': (a: CartItem, b: CartItem) =>
    a.createdDate < b.createdDate ? 1 : a.createdDate === b.createdDate ? 0 : -1,
  '-name': (a: CartItem, b: CartItem) => {
    if (!a.product) {
      return 1
    }
    if (!b.product) {
      return -1
    }

    if (a.product.name === b.product.name) {
      return 0
    }
    return a.product.name < b.product.name ? 1 : -1
  },
  cartAddedDate: (a: CartItem, b: CartItem) =>
    a.createdDate > b.createdDate ? 1 : a.createdDate === b.createdDate ? 0 : -1,
  name: (a: CartItem, b: CartItem) => {
    if (!a.product) {
      return -1
    }
    if (!b.product) {
      return 1
    }

    if (a.product.name === b.product.name) {
      return 0
    }

    return a.product.name > b.product.name ? 1 : -1
  },
}
