import React, { type FC, type FunctionComponent } from 'react'

import { consola } from 'consola/browser'

import { Button } from '@brenntag/ui/components/Button'

import { useSession } from '#lib/auth/useSession.js'
import { ErrorPage } from '#shared/pages/Error/ErrorPage.js'

import { useAclContextInfo } from '../Acl/lib/useAclContextInfo.js'

export interface WithPageAclOptions {
  NotAllowedComponent?: FunctionComponent
}

// TODO this approach does not scale. We need to extend the withPageAcl functionality to allow a per
//   check error to be set.
export function withUserMetaCheck(Component: FunctionComponent): FunctionComponent {
  const ComponentWithUserMetaCheck: FC = () => {
    const { country, salesforce, user } = useAclContextInfo()
    const { logout } = useSession()

    if (salesforce.isInternal) {
      return <Component />
    }

    if (!user.isActive) {
      consola.error(`User ${user.id} is inactive, and tried to access: ${window.location.pathname}`)
      return (
        <ErrorPage
          contactUsText="If this is not correct"
          data-testid="page-userInactive"
          subtitle=""
          title="Your account is inactive">
          <Button className="mt-4" onClick={logout} variant="action">
            Logout
          </Button>
        </ErrorPage>
      )
    }

    if (!country) {
      consola.error(
        `User ${user.id} tried to access a page (${window.location.pathname}) without a country`,
      )
      return (
        <ErrorPage
          contactUsText="If this is not correct"
          data-testid="page-noCountry"
          subtitle="No country specified."
          title="Unauthorized">
          <Button className="mt-4" onClick={logout} variant="action">
            Logout
          </Button>
        </ErrorPage>
      )
    }

    if (!user.countries.includes(country.code)) {
      consola.error(
        `User ${user.id} tried to access a page (${window.location.pathname}) for country ${country.code}, but is not allowed`,
      )
      return (
        <ErrorPage
          contactUsText="If this is not correct"
          data-testid="page-userNotInCountry"
          subtitle={`Your account is not configured for ${country?.name}.`}
          title="Unauthorized">
          <Button className="mt-4" onClick={logout} variant="action">
            Logout
          </Button>
        </ErrorPage>
      )
    }

    return <Component />
  }

  ComponentWithUserMetaCheck.displayName = `withUserMetaCheck(${Component.displayName || Component.name || 'Component'})`

  return ComponentWithUserMetaCheck
}
