import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconMicrophone = createIcon({
  displayName: 'IconMicrophone',
  path: [
    <path
      d="M8 13C8 15.21 9.79 17 12 17C14.21 17 16 15.21 16 13V9H14V13C14 14.1 13.1 15 12 15C10.9 15 10 14.1 10 13V6C10 4.9 10.9 4 12 4C13.1 4 14 4.9 14 6V7H16V6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6V13Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M18 13V9H20V13C20 17.07 16.94 20.44 13 20.93V23H11V20.93C7.06 20.44 4 17.07 4 13V9H6V13C6 16.31 8.69 19 12 19C15.31 19 18 16.31 18 13Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
