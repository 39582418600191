import { APIAddedFavoriteItem, APIBatchCartItem, APICart } from '@brenntag/connect-apis/api-cart'
import {
  Cart,
  CartBatchItem,
  CartFavoriteItem,
  CartItem,
} from '@brenntag/connect-apis/api-cart/models/cart'
import { Product } from '@brenntag/connect-apis/api/models/product'

import { transformCartItems } from './transformCartItems'
import { transformDeliveryOptions } from './transformDeliveryOptions'
import { transformMessageSummary } from './transformMessageSummary'
import { transformTotalAmount } from './transformTotalAmount'

export const transformAPICartToCart = (
  shipToLocationId: string,
  apiCart: APICart,
  products: Product[],
): Cart => {
  const items = transformCartItems(apiCart.items, products, apiCart.currencyIsoCode)
  const itemsWithErrorCount = items.filter(item => item.error).length

  return {
    currencyIsoCode: apiCart.currencyIsoCode,
    deliveryOptions: transformDeliveryOptions(apiCart),
    failedBatchItems: apiCart.failedBatchItems?.map(batchItem =>
      tranformAPICartBatchItemToCartBatchItem(batchItem, items),
    ),
    failedFavoriteItems: apiCart.failedFavoriteItems?.map(favItem =>
      tranformAPICartFavoriteItemToCartFavoriteItem(favItem, items),
    ),
    grandTotalAmount: transformTotalAmount(apiCart),
    id: apiCart.id,
    items,
    itemsWithErrorCount,
    messageSummary: transformMessageSummary(apiCart),
    promotionCodes: apiCart.promotionCodes,
    shipToLocationId,
    status: apiCart.status,
    succeededBatchItems: apiCart.succeededBatchItems?.map(batchItem =>
      tranformAPICartBatchItemToCartBatchItem(batchItem, items),
    ),
    succeededFavoriteItems: apiCart.succeededFavoriteItems?.map(favItem =>
      tranformAPICartFavoriteItemToCartFavoriteItem(favItem, items),
    ),
    totalDiscountAmount: apiCart.totalDiscountAmount,
  }
}

const tranformAPICartBatchItemToCartBatchItem = (
  batchItem: APIBatchCartItem,
  allItems: CartItem[],
): CartBatchItem => {
  const thisProduct = allItems.find(item => item.product?.id === batchItem.productId)
  return {
    message: batchItem.messagesSummary?.limitedMessages?.join(',') ?? '',
    name: thisProduct?.product?.name,
    productId: batchItem?.productId,
  }
}

const tranformAPICartFavoriteItemToCartFavoriteItem = (
  favItem: APIAddedFavoriteItem,
  allItems: CartItem[],
): CartFavoriteItem => {
  const thisProduct = allItems.find(item => item.product?.id === favItem.productId)
  return {
    message: favItem.message,
    name: thisProduct?.product?.name,
    productId: favItem.productId,
  }
}
