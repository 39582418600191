import * as React from 'react'
import type { SVGProps } from 'react'
const SvgKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2 22h7v-2h2v-2h2v-2.59l.41-.41h7L22 13.41V9h-2v3.59l-.41.41h-7L11 14.59V16H9v2H7v2H4v-3.59l7-7v-5l.41-.41h8.18l.41.41V7h2V3.59L20.41 2h-9.82L9 3.59v5l-7 7z"
      fill="currentColor"
    />
    <path d="M18 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="currentColor" />
  </svg>
)
export default SvgKey
