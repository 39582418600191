import { FC, useState } from 'react'

import { cn } from '@brenntag/ui/utils/cn'

import { FeatureFlags, useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'

import { ClearLocalFlagButton } from './ClearLocalFlagButton'

type Props = {
  flag: keyof FeatureFlags
  value: string
}

export const ObjectFeatureFlag: FC<Props> = ({ flag, value }) => {
  const [changedValue, setChangedValue] = useState<string | undefined>(undefined)
  const { isLocalFeatureFlag, setLocalFeatureFlag } = useFeatureFlags()

  return (
    <div
      className={cn('flex flex-col gap-2 py-3', isLocalFeatureFlag(flag) && 'text-blue-50')}
      key={flag}>
      <div className="flex items-center justify-between gap-2">
        <span className="flex-1">{flag}</span>
        {isLocalFeatureFlag(flag) && <ClearLocalFlagButton flag={flag} />}
      </div>
      <textarea
        className="min-h-20 bg-neutral-5 "
        minLength={5}
        name={flag}
        onBlur={() => {
          setLocalFeatureFlag(flag, JSON.parse(changedValue || '{}') as boolean)
          setChangedValue(undefined)
        }}
        onChange={e => {
          setChangedValue(e.target.value)
        }}
        value={changedValue ?? value}></textarea>
    </div>
  )
}
