import { CaseComment, CaseItem } from '@brenntag/connect-apis/api-case/models/case'
import {
  CaseSearchResponseFiltersOutput as FiltersOutput,
  CaseSearchRequestTermFilter as TermFilterType,
} from '@brenntag/connect-apis/api-case/models/caseFilter'
import {
  CaseGetCaseResponseComments,
  CaseGetResponse,
  CaseSearchRequestFilterType,
  CaseSearchResponseFilters,
} from '@brenntag/connect-apis/api-case/types'

import { transformAPIAttachmentsAPICommentsToCaseTimeline } from '#shared/apis/api-object/attachments/transforms/attachment.transforms.js'
import { sanitizeHtml } from '#shared/apis/sanitizeHtml.js'

export const isCaseTermFilter = (filter: CaseSearchResponseFilters): filter is TermFilterType =>
  filter.type === CaseSearchRequestFilterType.Term

const transformAPICommentToComment = (apiComment: CaseGetCaseResponseComments): CaseComment => ({
  comment: apiComment.comment,
  createdByName: apiComment.createdByName,
  createdDate: apiComment.createdDate,
  id: apiComment.id,
})

const shortenBigText = (text: string, delimiter: number) =>
  text.length > delimiter ? `${text.slice(0, delimiter)}...` : text
export const transformSubject = (subject?: string, summary?: string): string | undefined => {
  return subject && subject.length > 2
    ? sanitizeHtml(subject)
    : shortenBigText(sanitizeHtml(summary) || '', 247)
}

export const transformAPICaseToCaseItem = (response: CaseGetResponse): CaseItem => ({
  caseTimeline: transformAPIAttachmentsAPICommentsToCaseTimeline([], response.comments),
  category: response.category,
  comments: (response.comments ?? []).map(transformAPICommentToComment),
  contactId: response.contactId,
  correctiveAction: sanitizeHtml(response.correctiveAction),
  createdDate: response.createdDate,
  dateOfOccurrence: response.dateOfOccurrence,
  dueDate: response.dueDate,
  id: response.id,
  immediateAction: sanitizeHtml(response.immediateAction),
  invoiceNumber: response.invoiceNumber,
  materialUsed: response.materialUsed,
  number: response.number,
  orderNumber: response.orderNumber,
  purchaseOrderNumber: response.purchaseOrderNumber,
  relatedTo: response.relatedTo,
  requestedAction: sanitizeHtml(response.requestedAction),
  resolutionDate: response.resolutionDate,
  rootCause: response.rootCause,
  shipToId: response.shipToId,
  status: response.status,
  subject: transformSubject(response.subject, response.summary),
  summary: sanitizeHtml(response.summary),
})

export const transformParamsToCaseFilter = (
  apiFilters: CaseSearchResponseFilters[],
): FiltersOutput[] =>
  apiFilters.map(filter => {
    if (isCaseTermFilter(filter)) {
      return {
        name: filter.name,
        selectedValue: filter?.selectedValue,
        type: CaseSearchRequestFilterType.Term,
        values: filter.values,
      }
    }
    return {
      name: filter.name,
      selectedValue: filter?.selectedValue,
      type: CaseSearchRequestFilterType.DateRange,
      values: filter.values,
    } as FiltersOutput
  })
