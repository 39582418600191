import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconQuote = createIcon({
  displayName: 'IconQuote',
  path: [
    <path
      d="M6.26 19.9998H6.5L6.51 20.0098C7.66 20.0098 8.75 19.5698 9.6 18.7698C10.49 17.9298 11 16.7398 11 15.5098V15.0098C11 13.1498 9.72 11.5798 8 11.1398V11.0098C8 10.4598 8.45 10.0098 9 10.0098H11V4.00977H9C5.14 4.00977 2 7.14977 2 11.0098V12.0098H4V11.0098C4 8.24977 6.24 6.00977 9 6.00977V8.00977C7.35 8.00977 6 9.35977 6 11.0098V13.0098H7C8.1 13.0098 9 13.9098 9 15.0098V15.5098C9 16.1998 8.73 16.8498 8.22 17.3198C7.71 17.7898 7.05 18.0398 6.36 17.9998C5.06 17.9398 4 16.7298 4 15.3098V14.0098H2V15.3098C2 17.8098 3.87 19.8698 6.26 19.9998Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M17.26 19.9998H17.5L17.51 20.0098C18.66 20.0098 19.75 19.5698 20.6 18.7698C21.49 17.9298 22 16.7398 22 15.5098V15.0098C22 13.1498 20.72 11.5798 19 11.1398V11.0098C19 10.4598 19.45 10.0098 20 10.0098H22V4.00977H20C16.14 4.00977 13 7.14977 13 11.0098V12.0098H15V11.0098C15 8.24977 17.24 6.00977 20 6.00977V8.00977C18.35 8.00977 17 9.35977 17 11.0098V13.0098H18C19.1 13.0098 20 13.9098 20 15.0098V15.5098C20 16.1998 19.73 16.8498 19.22 17.3198C18.71 17.7898 18.05 18.0398 17.36 17.9998C16.06 17.9398 15 16.7298 15 15.3098V14.0098H13V15.3098C13 17.8098 14.87 19.8698 17.26 19.9998Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
