import * as React from 'react'
import type { SVGProps } from 'react'
const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M22 11h-2V4h-7V2h9z" fill="currentColor" />
    <path d="M18 7.412 7.412 18H6v-1.412L16.588 6H18z" fill="currentColor" />
    <path d="M4 13H2v9h9v-2H4z" fill="currentColor" />
  </svg>
)
export default SvgExpand
