import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import { VasGetResponse, VasStatus, VasType } from '@brenntag/connect-apis/api-vas/types'
interface Props {
  overrides?: Partial<VasGetResponse>
}

export const buildService = ({ overrides }: Props) => {
  return merge(
    {
      accountManagerName: faker.person.fullName(),
      application: faker.commerce.department(),
      createdDate: faker.date.past().toISOString(),
      description: faker.word.words(),
      finishedDate: faker.date.recent().toISOString(),
      id: faker.string.uuid(),
      lastModifiedDate: faker.date.recent().toISOString(),
      number: faker.number.int().toString(),
      projectedCompletionDate: faker.date.future().toISOString(),
      requiredDate: faker.date.future().toISOString(),
      shipToId: faker.string.uuid(),
      sourceLastModifiedDate: faker.date.recent().toISOString(),
      sourceOrganization: faker.lorem.word(),
      status: faker.helpers.arrayElement([
        VasStatus.Closed,
        VasStatus.InProgress,
        VasStatus.InReview,
        VasStatus.New,
        VasStatus.OnHold,
        VasStatus.SubmittedForApproval,
      ]),
      type: faker.helpers.arrayElement([VasType.BlendingService, VasType.LaboratoryService]),
      userId: faker.string.uuid(),
    } satisfies VasGetResponse,
    overrides,
  )
}
