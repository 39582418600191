import { APICart } from '@brenntag/connect-apis/api-cart'

export const cartItems = [
  {
    createdDate: '2025-01-12T08:48:26.000000Z',
    id: '0a9O8000000dlDZIAY',
    itemizedAdjustmentAmount: 0.0,
    listPrice: 0.0,
    messagesSummary: {
      limitedMessages: [],
    },
    name: 'PEAK GLOBAL LIFETIME CONCENTRA A&amp;C 6X1GAL PXA0B3',
    productDetails: {
      fields: {},
      name: 'PEAK GLOBAL LIFETIME CONCENTRA A&amp;C 6X1GAL PXA0B3',
      productId: '01t8W000005HEpKQAW',
      sku: '117533',
    },
    productId: '01t8W000005HEpKQAW',
    quantity: 5.0,
    salesPrice: 73.08,
    totalAdjustmentAmount: 0.0,
    totalAmount: 365.4,
    totalListPrice: 0.0,
    totalPrice: 365.4,
    totalTax: 0.0,
    type: 'Product',
  },
  {
    createdDate: '2025-01-12T08:48:13.000000Z',
    id: '0a9O8000000dlBxIAI',
    itemizedAdjustmentAmount: 0.0,
    listPrice: 0.0,
    messagesSummary: {
      limitedMessages: [],
    },
    name: 'BLUE MT 50/50 PREDILUTED AF &amp; COOLANT 55G DRM BMAB51',
    productDetails: {
      fields: {},
      name: 'BLUE MT 50/50 PREDILUTED AF &amp; COOLANT 55G DRM BMAB51',
      productId: '01t8W000005HuMtQAK',
      sku: '242941',
    },
    productId: '01t8W000005HuMtQAK',
    quantity: 1.0,
    salesPrice: 325.6,
    totalAdjustmentAmount: 0.0,
    totalAmount: 325.6,
    totalListPrice: 0.0,
    totalPrice: 325.6,
    totalTax: 0.0,
    type: 'Product',
  },
  {
    createdDate: '2025-01-12T08:47:58.000000Z',
    id: '0a9O8000000dlALIAY',
    itemizedAdjustmentAmount: 0.0,
    listPrice: 0.0,
    messagesSummary: {
      limitedMessages: [],
    },
    name: 'DEF 32.5% DIESEL EXHAUST FLUID ..',
    productDetails: {
      fields: {},
      name: 'DEF 32.5% DIESEL EXHAUST FLUID ..',
      productId: '01t8W000005H8uVQAS',
      sku: '87305',
    },
    productId: '01t8W000005H8uVQAS',
    quantity: 1.0,
    salesPrice: 2.8,
    totalAdjustmentAmount: 0.0,
    totalAmount: 2.8,
    totalListPrice: 0.0,
    totalPrice: 2.8,
    totalTax: 0.0,
    type: 'Product',
  },
  {
    createdDate: '2025-01-12T08:47:20.000000Z',
    id: '0a9O8000000dl8jIAA',
    itemizedAdjustmentAmount: 0.0,
    listPrice: 0.0,
    messagesSummary: {
      limitedMessages: [],
    },
    name: 'MOBIL DELVAC HDEO 15W40 G BULK',
    productDetails: {
      fields: {},
      name: 'MOBIL DELVAC HDEO 15W40 G BULK',
      productId: '01t8W000005HMBZQA4',
      sku: '46495',
    },
    productId: '01t8W000005HMBZQA4',
    quantity: 1.0,
    salesPrice: 16.67,
    totalAdjustmentAmount: 0.0,
    totalAmount: 16.67,
    totalListPrice: 0.0,
    totalPrice: 16.67,
    totalTax: 0.0,
    type: 'Product',
  },
] as const satisfies APICart['items']
