import React, { useCallback, useRef, useState } from 'react'

import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'

import { SearchInput } from '#shared/components/GlobalSearch/SearchInput.js'
import { SearchResults } from '#shared/components/GlobalSearch/SearchResults/SearchResult.js'
import { SEARCH_MIN_CHARACTERS } from '#shared/constants.js'
import { useSearch } from '#shared/hooks/search/useSearch.js'
import { useSearchQuery } from '#shared/hooks/search/useSearchQuery.js'

export const GlobalSearch = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const setOpen = (cond: boolean) => (cond ? onOpen() : onClose())

  const { query = '' } = useSearchQuery()
  const [inputValue, setInputValue] = useState(query)

  const { data, isFetching, isPlaceholderData } = useSearch({
    pageNumber: 1,
    pageSize: 5,
    search: inputValue,
  })

  const contentRef = useRef<HTMLElement>(null)
  const triggerRef = useRef<HTMLInputElement>(null)

  const onSearchInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (e.target.value.length >= SEARCH_MIN_CHARACTERS) {
        onOpen()
      }
    },
    [onOpen],
  )

  const onSearchInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!isOpen) return
      if (contentRef.current?.contains(e.relatedTarget)) return
      onClose()
    },
    [isOpen, onClose],
  )

  const handleInputChange = (value: string) => {
    setInputValue(value)
    setOpen(value.length >= SEARCH_MIN_CHARACTERS)
  }

  const popoverWidth = `${triggerRef.current?.getClientRects()[0]?.width || 1024}px`

  return (
    <Popover
      autoFocus={false}
      gutter={0}
      isOpen={Boolean(isOpen && inputValue)}
      matchWidth
      onClose={onClose}
      placement="bottom-start"
      returnFocusOnClose={false}
      trigger={'none' as 'click'}>
      <PopoverTrigger>
        <SearchInput
          onBlur={onSearchInputBlur}
          onFocus={onSearchInputFocus}
          onValueChange={handleInputChange}
          ref={triggerRef}
          setOpen={setOpen}
        />
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        maxWidth={['100vw', popoverWidth, popoverWidth]}
        ref={contentRef}
        width="100%">
        <PopoverBody p={0} zIndex="200">
          {isOpen && (
            <SearchResults
              data={isPlaceholderData ? undefined : data}
              isFetching={isFetching}
              onClick={onClose}
              search={inputValue || ''}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
