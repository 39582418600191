import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconInbound = createIcon({
  displayName: 'IconInbound',
  path: [
    <path
      d="M18 19.9998V11.9998H20V21.9998H2V11.5898L11 2.58984L15.09 6.66984L13.67 8.08984L11 5.40984L4 12.4098V19.9998H18Z"
      fill="currentColor"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M20.2954 4.29106L11 13.5864V10H9V17H16V15H12.4149L21.7096 5.70528L20.2954 4.29106ZM11 15V14.9927L11.0073 15H11Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
