import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCircleInfoFilled = createIcon({
  displayName: 'IconCircleInfoFilled',
  path: [
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 18H11V10H13V18ZM12 8C11.45 8 11 7.55 11 7C11 6.45 11.45 6 12 6C12.55 6 13 6.45 13 7C13 7.55 12.55 8 12 8Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
