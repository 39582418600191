import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBarGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 3h-2v18h2zM13 11h-2v10h2zM5 19H3v2h2zM7 15h2v6H7zM15 7h2v14h-2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgBarGraph
