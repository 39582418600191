import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconClose = createIcon({
  displayName: 'IconClose',
  path: [
    <path
      d="M19.2979 3.28844L3.29608 19.2903L4.7103 20.7045L20.7121 4.70266L19.2979 3.28844Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M4.71019 3.29133L3.29597 4.70555L9.17203 10.5816L10.5862 9.16739L4.71019 3.29133Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M13.4078 14.8255L14.822 13.4113L20.6981 19.2874L19.2839 20.7016L13.4078 14.8255Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
