import { FeatureName } from './lib/features'
import { isAllowed } from './lib/isAllowed'
import { useAclContextInfo } from './lib/useAclContextInfo'

/**
 * Returns a function that when given a feature will return true when allowed or false when not allowed
 * Convenient when you want to check in for example a useEffect callback
 */

export type IsAllowed = (featureName?: FeatureName | FeatureName[]) => boolean

export const useIsAllowedFunction = (): IsAllowed => {
  const contextInfo = useAclContextInfo()

  return (featureName?: FeatureName | FeatureName[]) => isAllowed(contextInfo, featureName)
}
