import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPlay = createIcon({
  displayName: 'IconPlay',
  path: [
    <path
      d="M6 22.91V5.96L8 7.34V19.09L18.24 12L6.69 4L6 3.52V1.09L21.76 12L6 22.91Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
