import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Cart } from '@brenntag/connect-apis/api-cart/models/cart'

import {
  cancelCheckout,
  CancelCheckoutInProgressError,
  CancelCheckoutParams,
} from '#shared/apis/api-cart/orders/endpoints/cancelCheckout.js'
import { TimeoutRequestError } from '#shared/apis/request.js'
import { useToaster } from '#shared/hooks/useToaster.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { QueryCacheKey } from '#shared/utils/enums.js'
import { captureHandledException } from '#shared/utils/errors.js'

interface Params {
  onError?: (error: unknown) => Promise<void> | void
  onSettled?: () => Promise<void> | void
  onSuccess?: () => Promise<void> | void
}

export const useCancelCheckout = ({ onError, onSettled, onSuccess }: Params = {}) => {
  const queryClient = useQueryClient()
  const t = useTranslations()
  const { showErrorToast } = useToaster()

  return useMutation<Cart, unknown, CancelCheckoutParams>({
    mutationFn: cancelCheckout,
    onError: error => {
      if (error instanceof CancelCheckoutInProgressError) return
      captureHandledException(error)
      if (error instanceof TimeoutRequestError) {
        showErrorToast(`${t('toast.error.timeout')}`)
      } else {
        showErrorToast(`${t('toast.error.somethingwrong')} ${t('toast.error.pleasetryagainlater')}`)
      }
      onError?.(error)
    },
    onSettled: () => {
      onSettled?.()
    },
    onSuccess: async cart => {
      queryClient.resetQueries({ queryKey: [QueryCacheKey.Checkout] })
      queryClient.setQueriesData<Cart>(
        { queryKey: [QueryCacheKey.Cart, cart.shipToLocationId] },
        () => cart,
      )
      onSuccess?.()
    },
    throwOnError: false,
  })
}
