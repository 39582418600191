import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { NavigateProps, Navigate as RouterNavigate } from 'react-router'

import { useCreatePath } from '#shared/routing/hooks/useCreatePath.js'

export const Navigate = (props: NavigateProps) => {
  const path = useCreatePath(props.to)
  if (path === undefined)
    throw new Error(`Path cannot be created using '${JSON.stringify(props.to)}' not found`)
  return <RouterNavigate {...props} to={path} />
}
