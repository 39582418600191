export const fonts = {
  body: "BrenntagSans, 'Helvetica Neue', Helvetica, Arial, system, sans-serif",
  heading: "BrenntagSans, 'Helvetica Neue', Helvetica, Arial, system, sans-serif",
}

export const fontSizes = {
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
  body: '0.875rem',
  caption: '0.75rem',
  h1: '3rem',
  h2: '2.125rem',
  h3: '1.75rem',

  h4: '1.5rem',
  h5: '1.25rem',
  h6: '1.125rem',
  lead: '1rem',
  lg: '1rem',
  md: '0.875rem',
  sm: '0.75rem',
  tiny: '0.625rem',
  xl: '1.125rem',
  xs: '0.625rem',
}

export const fontWeights = {
  black: 900,
  bold: 700,
  extrabold: 800,
  hairline: 100,
  light: 300,
  medium: 500,
  normal: 400,
  semibold: 600,
  thin: 200,
}

export const lineHeights = {
  3: '.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',

  base: 1.5,
  body: 1.43,
  caption: 1.43,
  h1: 1,
  h2: 1.11,
  h3: 1.25,
  h4: 1.36,

  h5: 1.5,
  h6: 1.11,
  lead: 1.25,
  none: 1,
  normal: 1.5,
  short: 1.375,
  shorter: 1.25,
  tall: 1.625,
  taller: 2,
  tiny: 1.67,
}

export const letterSpacings = {
  normal: '0',
  tight: '-0.025em',
  tighter: '-0.05em',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
}

export const textStyles = {
  headers: {
    '2xl': {
      fontSize: fontSizes['2xl'],
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.normal,
    },
    '3xl': {
      fontSize: fontSizes['3xl'],
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.normal,
    },
    '4xl': {
      fontSize: fontSizes['4xl'],
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.normal,
    },
    '5xl': {
      fontSize: fontSizes['5xl'],
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.shorter,
    },
    lg: {
      fontSize: fontSizes.lg,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.normal,
    },
    md: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.normal,
    },
    sm: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.normal,
    },
    xl: {
      fontSize: fontSizes.xl,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.normal,
    },
    xs: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.shorter,
    },
  },
  text: {
    '2xl': {
      fontSize: fontSizes['2xl'],
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.normal,
    },
    '3xl': {
      fontSize: fontSizes['3xl'],
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.normal,
    },
    '4xl': {
      fontSize: fontSizes['4xl'],
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.normal,
    },
    '5xl': {
      fontSize: fontSizes['5xl'],
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.shorter,
    },
    lg: {
      fontSize: fontSizes.lg,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.normal,
    },
    md: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.normal,
    },
    sm: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.normal,
    },
    xl: {
      fontSize: fontSizes.xl,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.normal,
    },
    xs: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights.shorter,
    },
  },
}
