import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrophy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 8V4h-4V2h-5v2h3v9.7L14.46 16H9.54L8 13.7V4h3V2H6v2H2v4h2V6h2v6h-.59L4 10.59V10H2v1.41L4.59 14H6v.3L8.46 18H11v2H7v2h10v-2h-4v-2h2.54L18 14.3V14h1.41L22 11.41V10h-2v.59L18.59 12H18V6h2v2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgTrophy
