import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconShipments = createIcon({
  displayName: 'IconShipments',
  path: [
    <path
      clipRule="evenodd"
      d="M16.1617 1.68018L19.8017 12.5702L19.7917 12.5802L11.8917 15.2102L8.26172 4.31018L16.1617 1.68018ZM12.3017 10.0702L13.1717 12.6702L17.2717 11.3002L16.4017 8.70018L12.3017 10.0702ZM15.7717 6.80018L14.9017 4.20018L10.8017 5.57018L11.6717 8.17018L15.7717 6.80018Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M12.9994 19.0598C12.9673 20.6825 11.63 22 10 22C8.35 22 7 20.65 7 19C7 18.0479 7.44952 17.1956 8.14682 16.6451L4.36188 5.28023L2.30187 5.96023L1.67188 4.06023L5.63188 2.74023L10.0619 16.0002H10.0377C10.9798 16.012 11.8209 16.4637 12.3639 17.1583L21.6894 14.0498L22.3194 15.9498L12.9994 19.0598ZM12.7065 17.7097C12.8986 18.0989 13.0094 18.5338 13.0094 18.9998H13C13 18.5387 12.8945 18.101 12.7065 17.7097ZM9 19C9 18.45 9.45 18 10 18C10.55 18 11 18.45 11 19C11 19.55 10.55 20 10 20C9.45 20 9 19.55 9 19Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
