import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMicrophone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8 13c0 2.21 1.79 4 4 4s4-1.79 4-4V9h-2v4c0 1.1-.9 2-2 2s-2-.9-2-2V6c0-1.1.9-2 2-2s2 .9 2 2v1h2V6c0-2.21-1.79-4-4-4S8 3.79 8 6z"
      fill="currentColor"
    />
    <path
      d="M18 13V9h2v4c0 4.07-3.06 7.44-7 7.93V23h-2v-2.07c-3.94-.49-7-3.86-7-7.93V9h2v4c0 3.31 2.69 6 6 6s6-2.69 6-6"
      fill="currentColor"
    />
  </svg>
)
export default SvgMicrophone
