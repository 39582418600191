import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCircleInfo = createIcon({
  displayName: 'IconCircleInfo',
  path: [
    <path
      d="M19.74 5.68L18.31 7.11C19.36 8.47 20 10.16 20 12.01C20 16.42 16.41 20.01 12 20.01C7.59 20.01 4 16.42 4 12.01C4 7.6 7.59 4 12 4C13.85 4 15.54 4.63 16.9 5.69L18.33 4.26C16.61 2.85 14.4 2 12.01 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.6 21.15 7.4 19.74 5.68Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M11 10V18H13V10H11Z" fill="currentColor" key="1" />,
    <path
      d="M13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
