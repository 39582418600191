import React, { FC } from 'react'

import { HStack, Text } from '@chakra-ui/react'

import { IconByName } from '#shared/icons/brand/index.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

export const Header: FC = () => {
  const t = useTranslations()

  return (
    <HStack w="100%">
      <IconByName boxSize={5} color="primary" name="IconGlobe" />
      <Text fontSize="18px" fontWeight={800} lineHeight="24px">
        {t('countryLanguageSelector.title')}
      </Text>
    </HStack>
  )
}
