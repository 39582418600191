import { FC } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import * as AllIcons from '@brenntag/ui-icons/index.js'

import { cn } from '#utils/cn.js'

const iconVariants = cva('inline-block', {
  defaultVariants: {
    size: 'lg',
  },
  variants: {
    size: {
      full: 'size-full',
      lg: 'size-6 min-w-6',
      md: 'size-5 min-w-5',
      sm: 'size-4 min-w-4',
      xl: 'size-8 min-w-8',
      xs: 'size-3 min-w-3',
    },
  },
})

export type IconNames = keyof typeof AllIcons

export interface IconProps extends VariantProps<typeof iconVariants> {
  ariaHidden?: boolean
  ariaLabel?: string
  ariaLabelBy?: string
  className?: string
  name: IconNames
}

const Icon: FC<IconProps> = ({
  ariaHidden = true,
  ariaLabel,
  ariaLabelBy,
  className,
  name,
  size,
  ...props
}) => {
  const Component = AllIcons[name] as FC<{ className: string }>
  return (
    <Component
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelBy}
      className={cn(iconVariants({ size }), className)}
      {...props}
    />
  )
}

Icon.displayName = 'Icon'

export { Icon, iconVariants }
