import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTagWithGradient = createIcon({
  displayName: 'IconTag',
  path: [
    <path
      d="M3 13.41L12.5 22.91L22.91 12.5L13.41 3H7V5H12.59L20.09 12.5L12.5 20.09L5 12.59V3H3V13.41Z"
      fill="url(#paint0_linear_1092_5215)"
      key="0"
    />,
    <path
      d="M10 9C10 9.55228 9.55228 10 9 10C8.44772 10 8 9.55228 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9Z"
      fill="url(#paint0_linear_1092_5215)"
      key="1"
    />,
    <defs key={2}>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1092_5215"
        x1="7"
        x2="42.99"
        y1="48"
        y2="12.01">
        <stop stopColor="#C815E0" />
        <stop offset="1" stopColor="#0C72ED" />
      </linearGradient>
    </defs>,
  ],
  viewBox: '0 0 24 24',
})
