import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeOrder,
  DataTypeOrderLine,
  DataTypeProduct,
  OrderLineAttributes,
  OrderLineResource,
  OrderLineStatus,
  ProductResource,
} from '@brenntag/connect-apis/api/types'
interface Props {
  attributes?: Partial<OrderLineAttributes>
  id?: string
  included: {
    product: ProductResource
  }
  orderId: string
}

export function buildOrderLine({
  attributes,
  id = faker.string.uuid(),
  included,
  orderId,
}: Props): OrderLineResource {
  return {
    attributes: merge(
      {
        createdAt: faker.date.past().toISOString(),
        isActive: true,
        orderQuantity: faker.number.int(100),
        pricingUnit: { quantity: faker.number.int(10), uom: 'KGM' },
        pricingUnitValue: { amount: faker.number.int(10000), currency: 'USD' },
        productIsHazardous: true,
        productName: included.product.attributes.name,
        productNumber: included.product.attributes.number,
        productPackaging: included.product.attributes.packaging,
        status: OrderLineStatus.DeliveredInvoiced,
        subtotalValue: { amount: faker.number.int(10000), currency: 'USD' },
        updatedAt: faker.date.recent().toISOString(),
      } satisfies OrderLineAttributes,
      attributes,
    ),
    id,
    relationships: {
      order: {
        data: { id: orderId, type: DataTypeOrder.Order },
      },
      product: {
        data: {
          id: included.product.id,
          type: DataTypeProduct.Product,
        },
      },
    },
    type: DataTypeOrderLine.OrderLine,
  }
}
