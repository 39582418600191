import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m13 10.76-1 1-1-1V2h2z" fill="currentColor" />
    <path
      d="m12 17.41-6.71-6.7 1.42-1.42 5.29 5.3 5.29-5.3 1.42 1.42zM20 21v-2H4v2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDownload
