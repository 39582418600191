const incoTerms = ['exw', 'fca', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'fas', 'fob', 'cfr', 'cif']
const uom = ['g', 'kg', 'lb', 'oz']
const packagingTypes = {
  BAG: 'Bag',
  Box: 'Box',
  case: 'CASE',
  charge: 'CHARGE',
  drum: 'Drum',
  ibc: 'IBC',
  keg: 'KEG',
  misc: 'MISC',
  pail: 'PAIL',
  TNK: 'Tank',
}

const brands = [
  'MOBIL',
  'CAT',
  'TOYOTA',
  'DETROIT DI',
  'LEXUS',
  'ACDELCO',
  'NISSAN',
  'PRIVLABEL',
  'QUAKER',
]

export const staticTestData = {
  brands,
  incoTerms,
  packagingTypes,
  uom,
}
