import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCheckmark = createIcon({
  displayName: 'IconCheckmark',
  path: [
    <path
      d="M10 17.41L4.29001 11.71L5.71001 10.29L10 14.59L19.29 5.29L20.71 6.71L10 17.41Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
