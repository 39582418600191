import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M11 2h2v2h8v2H3V4h8zM19 22H5V8h2v12h10V8h2z" fill="currentColor" />
    <path d="M13 8h2v10h-2zM11 8H9v10h2z" fill="currentColor" />
  </svg>
)
export default SvgTrash
