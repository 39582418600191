import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUser = createIcon({
  displayName: 'IconUser',
  path: [
    <path
      d="M14.5 6.5C14.5 6.18 14.43 5.87 14.32 5.59L15.8 4.11C16.24 4.81 16.5 5.62 16.5 6.5C16.5 8.98 14.48 11 12 11C9.52 11 7.5 8.98 7.5 6.5C7.5 4.02 9.52 2 12 2C12.88 2 13.7 2.26 14.39 2.7L12.91 4.18C12.63 4.07 12.32 4 12 4C10.62 4 9.5 5.12 9.5 6.5C9.5 7.88 10.62 9 12 9C13.38 9 14.5 7.88 14.5 6.5Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M3 11V21H21V11H16V13H19V19H5V13H8V11H3Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
