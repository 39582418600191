import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUserAdmin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.5 2.5 0 0 0 10 3.991a2.503 2.503 0 0 0-2.5 2.5c0 1.379 1.122 2.5 2.5 2.5s2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5a4.505 4.505 0 0 1-4.5-4.5m9.956 8.956-.64 1.545.64 1.545 1.544.639 1.545-.64.64-1.545-.64-1.545L17 14.808zm2.1-3.455.556 1.111.853.353 1.091-.631 1.111 1.111-.631 1.091.353.853 1.111.556v1.111l-1.111.556-.353.853.631 1.091-1.111 1.111-1.091-.631-.853.353-.556 1.111h-1.111l-.556-1.111-.853-.353-1.091.631-1.111-1.111.631-1.091-.353-.853L12 17.547v-1.111l1.111-.556.353-.853-.631-1.091 1.111-1.111 1.091.631.853-.353.556-1.111zM5 17.991v-4h3v-2H3v8h7v-2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUserAdmin
