import { CustomerShipToLocation } from './location'
import { Order } from './order'
import { MonetaryAmount } from './price'

export const InvoiceFilter = {
  dueDateGreaterEqual: 'filter[dueDate.gte]',
  dueDateLessEqual: 'filter[dueDate.lte]',
  invoiceDateGreaterEqual: 'filter[invoiceDate.gte]',
  invoiceDateLessEqual: 'filter[invoiceDate.lte]',
  orderId: 'filter[delivery.order.id.equals]',
  searchQuery: 'search[query]',
  sort: 'sort',
  status: 'filter[status.in]',
}

export type Invoice = {
  coaDocuments?: string[]
  delivery?: {
    number: string
    order?: Order
  }
  dueDate?: string
  grossAmount: MonetaryAmount
  id: string
  invoiceDate: string
  isInvoiceAvailable: boolean
  localNumber?: string
  number: string | undefined
  paidAmount?: MonetaryAmount
  purchaseOrderNumber?: string
  shipTo?: CustomerShipToLocation
  status: InvoiceStatus
  type: string
}

export type InvoiceFilterKey = keyof InvoiceFilters

export interface InvoiceFilters {
  customerContext?: string
  customerName?: string
  customerNumber?: string
  deliveryNumber?: string
  dueDateGreaterEqual?: string
  dueDateLessEqual?: string
  invoiceDateGreaterEqual?: string
  invoiceDateLessEqual?: string
  invoiceNumber?: string
  orderId?: string
  page?: string
  poNumber?: string
  salesGroup?: string
  searchQuery?: string
  sort?: string
  status?: string
}

export interface InvoiceList {
  invoices: Invoice[]
  total: number
  totalPages: number
}

export type InvoiceStatus = 'Closed' | 'Open' | 'Reconciling'
