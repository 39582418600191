import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHealth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16 12h-3v3h-2v-3H8v-2h3V7h2v3h3zm6-10H2v9.891c0 .079.112 7.914 9.783 10.061L12 22l.217-.048C21.888 19.805 22 11.97 22 11.891V5.995h-2v5.891c-.003.256-.152 6.217-8 8.066-7.848-1.849-7.997-7.811-8-8.061V3.998h18z"
      fill="currentColor"
    />
  </svg>
)
export default SvgHealth
