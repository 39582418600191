export interface DocumentFilters {
  page?: string
  searchQuery?: string
  sort?: string
  type?: string
}

export const DocumentFilter = {
  searchQuery: 'search[query]',
  sort: 'sort',
  type: 'filter[type.in]',
}

export interface Document {
  createdAt?: string
  id: string
  isActive: boolean
  producer?: string
  type?: string
  updatedAt?: string
}
export type DocumentFilterKey = keyof DocumentFilters
