import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFiltersShow = createIcon({
  displayName: 'IconFiltersShow',
  path: [
    <path d="M6 15H1V17H6V15Z" fill="currentColor" key="0" />,
    <path d="M23 7H18V9H23V7Z" fill="currentColor" key="1" />,
    <path d="M14 11H16V5H14V7H1V9H14V11Z" fill="currentColor" key="2" />,
    <path d="M10 13H8V19H10V17H23V15H10V13Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
