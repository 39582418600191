import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconHashtag = createIcon({
  displayName: 'IconHashtag',
  path: [
    <path
      d="M21 9.99998V7.99998H17.96L19.94 2.32998L18.05 1.66998L15.83 7.99998H10.95L12.93 2.32998L11.04 1.66998L8.82 7.99998H3V9.99998H8.14L6.74 14H3V16H6.04L4.06 21.67L5.95 22.33L8.17 16H13.05L11.07 21.67L12.96 22.33L15.18 16H21.02V14H15.88L17.28 9.99998H21.02H21ZM13.74 14H8.86L10.26 9.99998H15.14L13.74 14Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
