export const uomItems = {
  // Hundred Weight (CWT)
  CWT: 'Hundred Weight',
  // Each (EA)
  EA: 'Each',
  // Fluid Ounces (FL OZ)
  'FL OZ': 'Fluid Ounces',
  // Square Feet (FT2)
  'FT²': 'Square Feet',
  // Cubic Feet (FT3)
  'FT³': 'Cubic Feet',
  // Gram (G)
  G: 'Gram',
  // Gallon (GAL)
  GAL: 'Gallon',
  // Kilogram (KG)
  KG: 'Kilogram',
  // Liter (L)
  L: 'Liter',
  // Pound (LB)
  LB: 'Pound',
  // Metric Tons (MT)
  MT: 'Metric Tons',
  // Pints (PT)
  PT: 'Pints',
  // Quarts (QT)
  QT: 'Quarts',
  // Tons (T)
  T: 'Tons',
  // Square Yard (YD2)
  'YD²': 'Square Yard',
} as const satisfies Record<string, string>

export type UOMCode = keyof typeof uomItems
export type UOMItems = Record<UOMCode, string>

export const isSupportedUOM = (uom: string): uom is UOMCode => uom in uomItems
