import { useEffect, useState } from 'react'

import { useLocation } from 'react-router'

import { useFetchCart } from '#shared/apis/api-cart/cart/hooks/useFetchCart.js'

import { useCancelCheckout } from './useCancelCheckout'

// If the cart is in a checkout state, salesforce prevents the user from adding items to the cart. Therefore
// we need to cancel the checkout if the user navigates away from the checkout page when their checkout process
// is not yet completed. This hook is used in App.tsx to check on any route change if we need to cancel the
// checkout

const CART_CHECKOUT_STATUS = 'Checkout'

export const useResetCart = () => {
  const location = useLocation()
  const { data: cart } = useFetchCart()
  const [lastCartSignature, setLastCartSignature] = useState<string | undefined>()
  const { isPending, mutate: cancelCheckout } = useCancelCheckout()

  useEffect(() => {
    const cartSignature = cart ? `${cart.id}-${cart.status}` : undefined
    if (
      isPending ||
      !cart ||
      cart.status !== CART_CHECKOUT_STATUS ||
      location.pathname === '/:locale/connect/checkout' ||
      // Don't reset the cart (again) when the cart didn't change (yet)
      lastCartSignature === cartSignature
    ) {
      return
    }

    setLastCartSignature(cartSignature)
    cancelCheckout({ cartId: cart.id, shipToLocationId: cart.shipToLocationId })
  }, [
    cart?.status,
    isPending,
    cart?.shipToLocationId,
    cancelCheckout,
    cart,
    location.pathname,
    lastCartSignature,
  ])

  return { cancelCheckout, isPending }
}
