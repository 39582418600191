import React from 'react'

import { createIcon } from '@chakra-ui/icons'
export const IconSend = createIcon({
  displayName: 'IconSend',
  path: [
    <path
      d="M21.9993 10.382V13.618L5.23533 22H2.61133L4.94533 15H7.05333L5.51133 19.626L19.9993 12.382V11.618L5.51133 4.374L7.72033 11H15.9993V13H6.27833L2.61133 2H5.23533L21.9993 10.382Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
