import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHashtag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 10V8h-3.04l1.98-5.67-1.89-.66L15.83 8h-4.88l1.98-5.67-1.89-.66L8.82 8H3v2h5.14l-1.4 4H3v2h3.04l-1.98 5.67 1.89.66L8.17 16h4.88l-1.98 5.67 1.89.66L15.18 16h5.84v-2h-5.14l1.4-4h3.74zm-7.26 4H8.86l1.4-4h4.88z"
      fill="currentColor"
    />
  </svg>
)
export default SvgHashtag
