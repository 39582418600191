import { MonetaryAmount } from '@brenntag/connect-apis/api/models/price'
import { Product } from '@brenntag/connect-apis/api/models/product'

export const CartFilter = {
  searchQuery: 'search[query]',
  sort: 'sort',
}

export interface Cart {
  currencyIsoCode?: string
  deliveryOptions: {
    deliveryInstructions: string
    requestedDeliveryDate: Date | undefined
  }
  failedBatchItems?: CartBatchItem[]
  failedFavoriteItems?: CartFavoriteItem[]
  grandTotalAmount?: MonetaryAmount
  id: string
  items: CartItem[]
  itemsWithErrorCount: number
  messageSummary?: {
    errorCount?: number
    hasErrors?: boolean
    limitedMessages?: CartMessage[]
    relatedEntityId?: string
    totalLineItemsWithErrors?: number
  }
  promotionCodes?: {
    codes: string[]
    messages?: {
      freeProducts: { id: string; quantity: number }[]
      invalidCodes: { message: string }[]
    }
  }
  shipToLocationId: string
  status?: string
  succeededBatchItems?: CartBatchItem[]
  succeededFavoriteItems?: CartFavoriteItem[]
  totalDiscountAmount?: number
}

export interface CartBatchItem {
  message?: string
  name?: string
  productId?: string
}

export interface CartFavoriteItem {
  message: string
  name?: string
  productId: string
}

export interface CartFilters {
  page?: string
  searchQuery?: string
  sort?: string
}

export interface CartItem {
  createdDate: string
  error?: CartItemError
  id: string
  name: string
  product?: Product
  productId: string
  quantity: number
  salesPrice?: MonetaryAmount
  tankLocations?: CartTankLocation[]
  totalPrice?: MonetaryAmount
}

export type CartItemError = {
  description: string
  message: string
}

export interface CartMessage {
  message: string
  relatedToId?: string
  severity: string
  type: string
  visible: boolean
}

export interface CartTankLocation {
  id: string
  name: string
  quantity: string
}

export type ValidCartItem = Omit<CartItem, 'error' | 'product'> & { product: Product }
