import * as React from 'react'
import type { SVGProps } from 'react'
const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M3 11h8V3H3zm6-2H5V5h4zM3 21h8v-8H3zm6-2H5v-4h4zM21 11h-8V3h8zm-6-2h4V5h-4zM13 21h8v-8h-8zm6-2h-4v-4h4z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgGrid
