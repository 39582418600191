import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRocket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m9.59 8 6-6H22v6.41l-6 6v6.21L11.24 23H9v-3.76l2-2v3.64l3-1.5v-5.79l6-6V4h-3.59l-6 6H4.62l-1.5 3h3.64l-2 2H1v-2.24L3.38 8z"
      fill="currentColor"
    />
    <path d="m4.297 18.288 6.003-6.003 1.414 1.414-6.003 6.003z" fill="currentColor" />
  </svg>
)
export default SvgRocket
