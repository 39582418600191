import * as React from 'react'

import { RouteObject } from 'react-router'

import { PublicLayout } from '#shared/layouts/PublicLayout.js'
import { NotFound } from '#shared/pages/NotFound/index.js'
import { withRouteErrorBoundary } from '#shared/routing/withRouteErrorBoundary.js'

import { Navigate } from './components/Navigate'
import { validCountryLanguageLoader } from './loaders/validCountryLanguageLoader'

export const publicRoutes: RouteObject = {
  children: [
    withRouteErrorBoundary([
      {
        lazy: () => import('#shared/pages/ScheduleDemo/ScheduleDemoPage.js'),
        path: 'schedule-demo',
      },
      {
        lazy: () => import('#shared/pages/Register/RegisterPage.js'),
        path: 'register',
      },
      {
        /* There is currently no login page, instead redirect to the dashboard.
         * In case a user is not logged in the login page will be shown*/
        element: <Navigate replace to="/:locale/connect/dashboard" />,
        path: 'login',
      },
      { Component: NotFound, path: '*' },
    ]),
  ],
  Component: PublicLayout,
  id: 'publicRoutes',
  loader: validCountryLanguageLoader,
}
