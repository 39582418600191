import { Attachment } from '#api-object/models/attachment.js'

import {
  CaseStatus as APICaseStatus,
  CaseType as APICaseType,
  CaseSearchResponseContent,
} from '../types'
import { CaseSearchResponseFiltersOutput } from './caseFilter'

export interface CaseFilters {
  createdDateGreaterEqual?: string
  createdDateLessEqual?: string
  page?: string
  relatedTo?: string
  searchQuery?: string
  sort?: string
  status?: string
}

export const CaseFilter = {
  createdDateGreaterEqual: 'filter[createdDate.gte]',
  createdDateLessEqual: 'filter[createdDate.lte]',
  relatedTo: 'filter[type.in]',
  searchQuery: 'search[query]',
  sort: 'sort',
  status: 'filter[status.in]',
}

export enum CaseCommentType {
  Attachment = 'attachment',
  Comment = 'comment',
}

export interface CaseComment {
  comment?: string
  createdByName?: string
  createdDate?: string
  id: string
}

export interface CaseCreateResponse {
  id: string
  number: string
}
export interface CaseItem {
  caseTimeline?: TimelineItem[]
  category?: string
  comments: CaseComment[]
  contactId?: string
  correctiveAction?: string
  createdDate?: string
  dateOfOccurrence?: string
  description?: string
  dueDate?: string
  id: string
  immediateAction?: string
  invoiceNumber?: string
  materialUsed?: string
  number?: string
  orderNumber?: string
  product?: caseProduct[]
  purchaseOrderNumber?: string
  relatedTo?: CaseType
  requestedAction?: string
  resolutionDate?: string
  rootCause?: string
  shipToId?: string
  status?: CaseStatus
  subject?: string
  summary?: string
}
export interface CaseList {
  content: CaseSearchResponseContent[]
  filters: CaseSearchResponseFiltersOutput[]
  page: CaseSearchResponsePage
  searchQuery: string
  sort: string
  sorts: string[]
  totalPages: number
}

export interface CaseListItem {
  createdDate: string
  dateOfOccurrence: string
  description?: string
  id: string
  invoiceNumber?: string
  lastModifiedDate: string
  number?: string
  orderNumber?: string
  purchaseOrderNumber?: string
  relatedTo?: CaseType
  status: CaseStatus
  subject?: string
}
export type caseProduct = {
  caseId: string
  id: string
  name: string
}

export type CaseStatus =
  | APICaseStatus.Cancelled
  | APICaseStatus.Closed
  | APICaseStatus.Escalated
  | APICaseStatus.InProgress
  | APICaseStatus.InReview
  | APICaseStatus.New
  | APICaseStatus.OnHold

export type CaseType = APICaseType.Invoice | APICaseType.Order

export interface TimelineAttachment {
  attachments: Attachment[]
  timeToConsider: string
  type: CaseCommentType.Attachment
}
export interface TimelineComment extends CaseComment {
  timeToConsider: string
  type: CaseCommentType.Comment
}
export type TimelineItem = TimelineAttachment | TimelineComment

interface CaseSearchResponsePage {
  number: number
  size: number
  total: number
}
