import { ComponentStyleConfig } from '@chakra-ui/react'

export const Radio: ComponentStyleConfig = {
  baseStyle: {
    _hover: {
      _disabled: {
        bg: 'primary',
      },
      borderColor: 'primary',
      borderWidth: '2px',
    },
    bg: 'primary',

    control: {
      _checked: {
        _disabled: {
          bg: 'white',
          borderColor: 'neutral.20',
          borderWidth: '4px',
          color: 'white',
          cursor: 'not-allowed',
        },
        bg: 'primary',
      },
      _disabled: {
        bg: 'white',
        borderColor: 'neutral.20',
        cursor: 'not-allowed',
      },
      _hover: {
        borderColor: 'primary',
        borderWidth: '2px',
      },
      borderColor: 'primary',
      borderWidth: '1px',
      cursor: 'pointer',
    },
    label: {
      _disabled: {
        color: 'neutral.50',
        cursor: 'not-allowed',
      },
      cursor: 'pointer',
    },
  },
  defaultProps: {
    borderColor: 'primary',
    borderWidth: '1px',
    colorScheme: 'brand',
    size: 'md',
  },
  variants: {},
}

export const RadioGroup: ComponentStyleConfig = {
  baseStyle: {},
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
  },
  variants: {},
}
