import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMyLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13 5.08A7 7 0 0 1 18.92 11H22v2h-5.1A5.002 5.002 0 0 0 12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5c1.62 0 3.06-.79 3.97-2h2.33a7 7 0 0 1-5.3 3.92V22h-2v-3.08A7 7 0 0 1 5.08 13H2v-2h3.08A7 7 0 0 1 11 5.08V2h2z"
      fill="currentColor"
    />
    <path d="M13 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="currentColor" />
  </svg>
)
export default SvgMyLocation
