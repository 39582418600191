import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import { uomItems } from '@brenntag/connect-apis/api/models/uom'
import {
  Amount,
  DataTypePricebookProduct,
  DataTypeProduct,
  DataTypeQuote,
  DataTypeQuoteLine,
  ProductResource,
  QuoteLineAttributes,
  QuoteLineResource,
  ToSinglePricebookProduct,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<
    Omit<QuoteLineAttributes, 'annualConsumption'> & { annualConsumption?: Amount | null }
  >
  id?: string
  included: {
    pricebookProduct?: ToSinglePricebookProduct
    product: ProductResource
  }
  quoteId: string
}

export function buildQuoteLine({
  attributes,
  id = faker.string.uuid(),
  included,
  quoteId,
}: Props): QuoteLineResource {
  return {
    attributes: merge(
      {
        annualConsumption: faker.datatype.boolean()
          ? {
              quantity: faker.number.int({ max: 1500, min: 1 }),
              uom: faker.helpers.arrayElement(Object.keys(uomItems)),
            }
          : undefined,
        createdAt: faker.date.past().toISOString(),
        isActive: true,
        quoteQuantity: faker.number.int({ max: 1500, min: 1 }),
        updatedAt: faker.date.recent().toISOString(),
      } satisfies QuoteLineAttributes,
      attributes,
    ),
    id,
    relationships: {
      pricebookProduct: included.pricebookProduct && {
        data: {
          id: included.pricebookProduct.data.id,
          type: DataTypePricebookProduct.PricebookProduct,
        },
      },
      product: {
        data: {
          id: included.product.id,
          type: DataTypeProduct.Product,
        },
      },
      quote: {
        data: { id: quoteId, type: DataTypeQuote.Quote },
      },
    },
    type: DataTypeQuoteLine.QuoteLine,
  }
}
