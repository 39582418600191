import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  CaseCategory,
  CaseGetCaseResponseComments,
  CaseGetResponse,
  CaseRootCause,
  CaseStatus,
  CaseType,
} from '@brenntag/connect-apis/api-case/types'

import { buildCaseComment } from './buildCaseComment'
interface Props {
  overrides?: Partial<CaseGetResponse>
}

export const buildCase = ({ overrides }: Props) => {
  const caseComments: CaseGetCaseResponseComments[] =
    overrides?.comments?.map(comment => buildCaseComment({ overrides: comment })) ?? []
  return merge(
    {
      category: faker.helpers.arrayElement([
        CaseCategory.Packaging,
        CaseCategory.Documentation,
        CaseCategory.Safety,
        CaseCategory.PriceInvoicing,
        CaseCategory.Product,
        CaseCategory.Quantity,
        CaseCategory.Time,
      ]),
      comments: caseComments,
      contactId: faker.string.uuid(),
      correctiveAction: faker.lorem.sentence(),
      createdDate: faker.date.past().toISOString(),
      dateOfOccurrence: faker.date.past().toISOString(),
      dueDate: faker.date.past().toISOString(),
      id: faker.string.uuid(),
      immediateAction: faker.lorem.sentence(),
      invoiceNumber: faker.string.numeric(),
      number: faker.string.numeric(),
      orderNumber: faker.string.numeric(),
      purchaseOrderNumber: faker.string.numeric(),
      relatedTo: faker.helpers.arrayElement([CaseType.Invoice, CaseType.Order]),
      resolutionDate: faker.date.past().toISOString(),
      rootCause: faker.helpers.arrayElement([
        CaseRootCause.Delivery,
        CaseRootCause.It,
        CaseRootCause.Marketing,
        CaseRootCause.Packaging,
        CaseRootCause.Sales,
        CaseRootCause.Supplier,
      ]),
      shipToId: faker.string.uuid(),
      status: faker.helpers.arrayElement([
        CaseStatus.Cancelled,
        CaseStatus.Closed,
        CaseStatus.Escalated,
        CaseStatus.InProgress,
        CaseStatus.New,
        CaseStatus.OnHold,
      ]),
      summary: faker.lorem.sentence(),
    } satisfies CaseGetResponse,
    overrides,
  )
}
