import { http, HttpResponse } from 'msw'

import { config } from '#config'
import { GetCalculationsResponse } from '#requests/calculations/getCalculations.js'

import { calculations } from './data'

const response: GetCalculationsResponse = {
  data: calculations,
  meta: { count: calculations.length },
}

export default () => {
  const { getBaseUrl } = config
  return [
    http.get(`${getBaseUrl()}/calculations`, async () => {
      return HttpResponse.json(response)
    }),
  ]
}
