import React from 'react'

import { Button, ButtonProps, chakra, LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { Link, NavLink, NavLinkProps } from 'react-router'

import { useCreatePath } from '#shared/routing/hooks/useCreatePath.js'

const sxLink = {
  a: { color: 'blue.60' },
  'a:active': { color: 'blue.70' },
  'a:hover': { color: 'blue.50' },
  'a:visited': { color: 'indigo.60' },
  'a[disabled]': { color: 'neutral.30' },
  'a h1, a h3, a h3, a h4, a h5, a h6, a p': {
    color: 'primary',
  },
}

const ChakraLink = chakra(Link)
const ChakraNavLink = chakra(NavLink)

export const RouterLink: React.FC<ChakraLinkProps & NavLinkProps> = ({
  children,
  to,
  ...props
}) => {
  const path = useCreatePath(to)
  return (
    <ChakraLink disabled={path === undefined} sx={sxLink} to={path} {...props}>
      {children}
    </ChakraLink>
  )
}

export const RouterNavLink: React.FC<ChakraLinkProps & NavLinkProps> = ({
  children,
  to,
  ...props
}) => {
  const path = useCreatePath(to)
  return (
    <ChakraNavLink disabled={path === undefined} sx={sxLink} to={path} {...props}>
      {children}
    </ChakraNavLink>
  )
}

export const RouterButton: React.FC<ButtonProps & NavLinkProps> = ({
  children,
  className,
  to,
  ...props
}) => {
  const path = useCreatePath(to)
  return (
    <Button
      as={ChakraLink}
      disabled={path === undefined}
      {...props}
      aria-hidden="true"
      className={className}
      tabIndex={-1}
      to={path}>
      {children}
    </Button>
  )
}
