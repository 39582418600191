import { faker } from '@faker-js/faker'
import { merge, uniqBy } from 'lodash'

import {
  DataTypeDocument,
  DataTypeInvoice,
  DataTypeOrder,
  DataTypeProduct,
  DocumentAttributes,
  DocumentResource,
  InvoiceResource,
  OrderResource,
  SingleIncludedDocumentResourceProductResource,
  SingleIncludedProductResourceWithPricebookProductUnion,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<DocumentAttributes>
  id?: string
  invoice?: InvoiceResource
  order?: OrderResource
  product?: SingleIncludedProductResourceWithPricebookProductUnion
}

export const buildDocument = ({
  attributes,
  id = faker.string.uuid(),
  invoice,
  order,
  product,
}: Props = {}): SingleIncludedDocumentResourceProductResource => {
  const document: DocumentResource = {
    attributes: merge(
      {
        createdAt: faker.date.past().toISOString(),
        isActive: true,
        producer: faker.company.buzzNoun(),
        type: faker.word.words(3),
        updatedAt: faker.date.past().toISOString(),
      } satisfies DocumentAttributes,
      attributes,
    ),
    id,
    relationships: {
      invoice: invoice && {
        data: {
          id: invoice.id,
          type: DataTypeInvoice.Invoice,
        },
      },
      order: order && {
        data: {
          id: order.id,
          type: DataTypeOrder.Order,
        },
      },
      product: product && {
        data: {
          id: product?.data.id,
          type: DataTypeProduct.Product,
        },
      },
    },
    type: DataTypeDocument.Document,
  }
  return {
    data: document,
    included: uniqBy(
      [product?.data, ...(product?.included || [])].filter(Boolean),
      resource => resource?.id,
    ) as SingleIncludedDocumentResourceProductResource['included'],
  }
}
