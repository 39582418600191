import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFileError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M13 2h2.41L20 6.59V22H4V2h7v2H6v16h12V9h-5zm2 2.41V7h2.59z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="m12 12.59 2.29-2.3 1.42 1.42-2.3 2.29 2.3 2.29-1.42 1.42-2.29-2.3-2.29 2.3-1.42-1.42 2.3-2.29-2.3-2.29 1.42-1.42z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFileError
