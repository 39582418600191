import React from 'react'

import { ColorProps, Text, useToast } from '@chakra-ui/react'

import { Toast } from '#shared/components/Toast/Toast.js'

export const useToaster = (defaultTimeout: number = 9000) => {
  const toast = useToast()

  return {
    showErrorToast: (
      message: React.ReactElement | string,
      timeout: number | undefined = defaultTimeout,
    ) => {
      const id = JSON.stringify(message)
      if (!toast.isActive(id)) {
        return toast({
          duration: timeout,
          id,
          render: props => (
            <Toast color="red.70" description={message} id={props.id} onClose={props.onClose} />
          ),
        })
      }
    },
    showInfoToast: (
      message: React.ReactElement | string,
      timeout: number | undefined = defaultTimeout,
    ) => {
      return toast({
        duration: timeout,
        render: props => (
          <Toast
            color="blue.50"
            description={<Text fontWeight="bold">{message}</Text>}
            id={props.id}
            onClose={props.onClose}
          />
        ),
      })
    },
    showSuccessToast: (
      message: React.ReactElement | string,
      timeout: number | undefined = defaultTimeout,
    ) => {
      return toast({
        duration: timeout,
        render: props => (
          <Toast color="green.50" description={message} id={props.id} onClose={props.onClose} />
        ),
      })
    },
    showToast: (
      message: React.ReactElement | string,
      color: ColorProps['color'],
      timeout: number | undefined = defaultTimeout,
    ) => {
      return toast({
        duration: timeout,
        render: props => (
          <Toast color={color} description={message} id={props.id} onClose={props.onClose} />
        ),
      })
    },
  }
}
