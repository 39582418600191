import React, {
  createContext,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import type { Customer, SalesGroup } from '@brenntag/api-customer'

import {
  Box,
  Center,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  StackProps,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useLocation } from 'react-router'
import { useDebouncedCallback } from 'use-debounce'

import { useFetchCustomers } from '#modules/customer/hooks/customers/useFetchCustomers.js'
import { useGetSalesGroups } from '#modules/customer/hooks/sales-groups/useGetSalesGroups.js'
import { useBreakpointValue } from '#shared/chakra/hooks/useBreakpointValue.js'
import { HorizontalDivider } from '#shared/components/Card/CardDivider.js'
import { TextHighlight } from '#shared/components/Highlight/Highlight.js'
import { Input } from '#shared/components/Input/Input.js'
import { LoaderRound } from '#shared/components/Loader/LoaderRound.js'
import { ChangeSalesOrgModal } from '#shared/components/Modal/ChangeSalesOrgModal.js'
import { NoResultsMessage } from '#shared/components/NoResultsMessage/NoResultsMessage.js'
import { SEARCH_MIN_CHARACTERS } from '#shared/constants.js'
import { useSelectedCustomer } from '#shared/hooks/globalState/useSelectedCustomer.js'
import { useSelectedShipToLocation } from '#shared/hooks/globalState/useSelectedShipToLocation.js'
import { useSearchQuery } from '#shared/hooks/search/useSearchQuery.js'
import {
  IconArrowRight,
  IconCheckmark,
  IconChevronDown,
  IconEnterprise,
} from '#shared/icons/brand/index.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { useNavigate } from '#shared/routing/hooks/useNavigate.js'
import { groupArrayByValue } from '#shared/utils/array-utils.js'
import { trimLeadingZeros } from '#shared/utils/misc.js'

const CLEAR_SEARCH = ''
const INCREASE_POPOVER_WIDTH_WITH_CUSOMER_NAME_LENGTH_GREATER_THAN = 50
const SHOW_SEARCH_INPUT_AFTER = 10
const MAX_NUMBER_OF_LOCAL_CUSTOMERS = 15
const TRUNCATE_CUSTOMER_NAME_AT = 40
const MAX_CUSTOMERS_PER_PAGE = 15

const CustomerSelectorSearchContext = createContext(CLEAR_SEARCH)

interface CustomerSelectorOptionData {
  customers: Customer[]
  salesGroup?: SalesGroup
}

const sxActiveCustomerOption = {
  _hover: {
    bgColor: 'blue.10',
  },
  bgColor: 'blue.10',
  fontWeight: 'bold',
}
const sxCustomerOption = {
  _hover: {
    bgColor: 'neutral.10',
  },
  bgColor: 'white',
  cursor: 'pointer',
}

const sxNoFocus = {}

const getActiveCustomerName = (customer: Customer | undefined, isLong: boolean) => {
  if (!customer) return ''

  const { name, number } = customer
  return isLong
    ? `${
        name.length > TRUNCATE_CUSTOMER_NAME_AT
          ? name.slice(0, TRUNCATE_CUSTOMER_NAME_AT) + '…'
          : name
      } - ${trimLeadingZeros(number)}`
    : `${name} - ${trimLeadingZeros(number)}`
}

const getActiveSalesGroupName = (salesGroup?: SalesGroup, isLong?: boolean) => {
  if (!salesGroup) return ''
  return isLong ? `${salesGroup.name} (${salesGroup.shortName})` : salesGroup.shortName
}

interface CustomerItemProps {
  customer: Customer
  isActive?: boolean
  onClick: MouseEventHandler
}
const CustomerItem = ({ customer, isActive = false, onClick }: CustomerItemProps) => {
  const search = useContext(CustomerSelectorSearchContext)
  const Icon = isActive ? IconCheckmark : IconEnterprise

  return (
    <HStack
      align="center"
      border="1px solid"
      borderColor="neutral.20"
      borderTop="none"
      color="primary"
      justify="space-between"
      key={customer.id}
      onClick={onClick}
      p={3}
      spacing={3}
      sx={isActive ? sxActiveCustomerOption : sxCustomerOption}
      w="full">
      <Icon boxSize={5} color="primary" />
      <Text flex={1}>
        <TextHighlight search={search}>{getActiveCustomerName(customer, true)}</TextHighlight>
      </Text>
      {!isActive && <IconArrowRight boxSize={5} color="neutral.40" />}
    </HStack>
  )
}
interface CustomerSelectorOptionProps {
  children: CustomerSelectorOptionData
  isActiveCustomer: (customer: Customer) => boolean
  onClick: (customer: Customer) => void
}

const CustomerSelectorOption = ({
  children,
  isActiveCustomer,
  onClick,
}: CustomerSelectorOptionProps) => {
  const { customers, salesGroup } = children
  const search = useContext(CustomerSelectorSearchContext)

  return (
    <Flex direction="column" fontSize="md" key={salesGroup?.id} w="full">
      <Flex bgColor="neutral.10" border="1px solid" borderColor="neutral.20" px={2} py={3} w="full">
        <Text color="neutral.90" fontWeight="bold">
          <TextHighlight search={search}>{getActiveSalesGroupName(salesGroup, true)}</TextHighlight>
        </Text>
      </Flex>
      {customers.map(customer => (
        <CustomerItem
          customer={customer}
          isActive={isActiveCustomer(customer)}
          key={customer.id}
          onClick={() => onClick(customer)}
        />
      ))}
    </Flex>
  )
}
interface CustomerSelectorButtonProps extends StackProps {
  customer: Customer | undefined
  isLongVersion?: boolean
}
const CustomerSelectorButton = ({
  customer,
  isLongVersion = false,
  ...props
}: CustomerSelectorButtonProps) => {
  const salesGroups = useGetSalesGroups()
  const salesGroup = salesGroups?.data.find(sg => sg.id === customer?.salesGroupId)
  return (
    <HStack color="neutral.80" cursor="pointer" fontSize={['12px', 'md']} spacing={2} {...props}>
      <IconEnterprise boxSize="20px" color="primary" />
      <Text fontWeight="bold" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {getActiveCustomerName(customer, isLongVersion)}
      </Text>
      <Text>|</Text>
      <Text>{getActiveSalesGroupName(salesGroup, isLongVersion)}</Text>
      <IconChevronDown boxSize="16px" color="neutral.70" />
    </HStack>
  )
}

// TODO: fix complexity
// eslint-disable-next-line complexity
export const CustomerSelector = () => {
  /* HOOKS */
  const t = useTranslations()
  const navigate = useNavigate()
  const location = useLocation()
  const { setShipToLocation } = useSelectedShipToLocation()
  const salesGroups = useGetSalesGroups()
  const isLongVersionSelector = useBreakpointValue([false, true]) || false
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: isModalOpen, onClose: modalClose, onOpen: modalOpen } = useDisclosure()
  const { selectedCustomer, setSelectedCustomer } = useSelectedCustomer()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [modalCustomer, setModalCustomer] = useState<Customer | undefined>()
  const [activeCustomer, setActiveCustomer] = useState<Customer | undefined>(selectedCustomer)
  const [inputValue, setInputValue] = useState(CLEAR_SEARCH)
  const [search, setSearch] = useState(CLEAR_SEARCH)
  const [isLocalSearchMode, setIsLocalSearchMode] = useState(false)
  const { data: customers, isFetching } = useFetchCustomers({
    enabled: !isLocalSearchMode,
    query: search.length >= SEARCH_MIN_CHARACTERS ? search : undefined,
  })
  /* Remember/cache the initial total customer contexts. The total can change when the user starts searching */
  const initialTotalCustomerTotal = useRef(customers?.meta.count ?? 0)

  /* When there are not many customer context this component can work in local mode.
   * This means that there is no need to query more data or filter data on the server.
   * All filtering can be done locally as before. */
  useEffect(() => {
    setIsLocalSearchMode(initialTotalCustomerTotal.current <= MAX_CUSTOMERS_PER_PAGE)
  }, [])

  const total = customers?.meta.count ?? 0

  /* CONDITION VARIABLES */
  const isShowSearchBar =
    /* In local search mode only allow search when there are more than x results*/
    (isLocalSearchMode && initialTotalCustomerTotal.current >= SHOW_SEARCH_INPUT_AFTER) ||
    /* In non local search mode always allow searching */
    !isLocalSearchMode

  /* Show the popup search body, when there are less or equal results than the maximum amount of
   * local customer contexts and show the popup body when a user is searching */
  const isShowSearchBody =
    total <= MAX_NUMBER_OF_LOCAL_CUSTOMERS ||
    (isLocalSearchMode && search.length > 0) ||
    (!isLocalSearchMode && search.length >= SEARCH_MIN_CHARACTERS)

  const isShowMoreResultsMessage = total > MAX_NUMBER_OF_LOCAL_CUSTOMERS

  const isUseSmallerFontForLongCustomerNames = customers?.data
    ?.map((customer: Customer) => getActiveCustomerName(customer, isLongVersionSelector))
    .some(
      (name: string) => name.length >= INCREASE_POPOVER_WIDTH_WITH_CUSOMER_NAME_LENGTH_GREATER_THAN,
    )

  const isActiveCustomer = (c: Customer): boolean => activeCustomer?.id === c.id

  /* EVENT LISTENERS */
  const onChangeActiveCustomer = (selectedCustomer: Customer) => {
    setActiveCustomer(selectedCustomer)
    if (!selectedCustomer?.shipTos?.length) {
      throw new Error(`No shipTo locations for customer context: ${selectedCustomer.id}}`)
    }
    setSelectedCustomer(selectedCustomer)
    setShipToLocation(selectedCustomer.shipTos[0])
    navigate(location)
  }

  const onChangeActiveCustomerModal = (customer: Customer) => {
    // do not open salesgroup modal if we select a customer from the same sales group
    if (selectedCustomer?.salesGroupId === customer.salesGroupId) {
      confirmActiveCustomer(customer)
    } else {
      setModalCustomer(customer)
      modalOpen()
    }
  }

  const confirmActiveCustomer = (customer: Customer) => {
    onChangeActiveCustomer(customer)
    modalClose()
  }

  const handleActiveCustomerModalConfirm = () => {
    if (!modalCustomer) throw new Error('modalCustomer is not set')
    confirmActiveCustomer(modalCustomer)
  }

  // 1. sort Customer[] by the customerName
  // 2. group Customer[] by SalesGroup id and extract array of Customer[] per SalesGroup
  // 3. create dropdown options out of each array with their SalesGroup
  const customerSelectorOptionTransform = useMemo(
    () =>
      (options?: Customer[]): CustomerSelectorOptionData => {
        return {
          customers: options!,
          salesGroup: salesGroups?.data.find(sg => sg.id === options![0].salesGroupId),
        }
      },
    [salesGroups?.data],
  )

  const dropdownOptions: CustomerSelectorOptionData[] = useMemo(() => {
    const sortedCustomers = customers?.data.sort((a, b) => a.name.localeCompare(b.name))
    const groupCustomersBySalesGroup = groupArrayByValue(
      sortedCustomers || [],
      value => value.salesGroupId,
    )

    return Object.entries(groupCustomersBySalesGroup).map(([_, objects]) =>
      customerSelectorOptionTransform(objects),
    )
  }, [customerSelectorOptionTransform, customers?.data])

  const searchedOptions = useMemo(
    () =>
      dropdownOptions
        .map(({ customers, ...rest }) => ({
          ...rest,
          customers: isLocalSearchMode
            ? customers.filter(({ name, number, salesGroupId }) => {
                const salesGroup = salesGroups?.data.find(sg => sg.id === salesGroupId)
                return [salesGroup?.name, salesGroup?.shortName, name, number].some(entity =>
                  search
                    .toLowerCase()
                    .split(' ')
                    .every(term => entity?.toLowerCase().includes(term)),
                )
              })
            : customers,
        }))
        .filter(option => option.customers.length > 0),
    [dropdownOptions, isLocalSearchMode, salesGroups?.data, search],
  )

  const { updateSearchQuery } = useSearchQuery()

  useEffect(() => {
    const selectedOption =
      dropdownOptions.find(dropdownOption => {
        return dropdownOption.customers[0].salesGroupId === selectedCustomer?.salesGroupId
      }) ?? dropdownOptions[0]

    const _selectedCustomer = selectedOption?.customers.find(customer => {
      return customer.id === selectedCustomer?.id
    })

    if (!_selectedCustomer) return
    setActiveCustomer(selectedCustomer)
  }, [dropdownOptions, selectedCustomer?.id, isLongVersionSelector, selectedCustomer])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const onDebouncedValueChange = useDebouncedCallback((value: string) => {
    setSearch(value)
  }, 300)

  if (initialTotalCustomerTotal.current <= 1) return null

  return (
    <CustomerSelectorSearchContext.Provider value={search}>
      <div className="w-full bg-neutral-10 ">
        <div className="container relative z-10 mx-auto flex h-10 max-h-10 justify-end px-4 py-2 md:px-8">
          <Popover isOpen={isOpen} onClose={handleClose} onOpen={onOpen} placement="bottom-end">
            <PopoverTrigger>
              <div>
                <CustomerSelectorButton
                  customer={activeCustomer}
                  isLongVersion={isLongVersionSelector}
                />
              </div>
            </PopoverTrigger>
            {isOpen && (
              <PopoverContent
                _focus={{
                  outline: 'none',
                  WebkitTapHighlightColor: 'transparent',
                }}
                bg="white"
                border="1px solid"
                borderColor="neutral.20"
                borderRadius="4px"
                maxHeight={['100vh', 'lg']}
                maxWidth={['100vw', 'md', isUseSmallerFontForLongCustomerNames ? 'xl' : 'lg']}
                minHeight={['100vh', 'inherit']}
                minWidth={['100vw', 'md', isUseSmallerFontForLongCustomerNames ? 'xl' : 'lg']}
                overflowY="hidden">
                <PopoverCloseButton
                  _focus={sxNoFocus}
                  display={['block', 'none']}
                  right="-6px"
                  top="-5px"
                />
                {isShowSearchBar && (
                  <Box p={4} pb={0}>
                    <Input
                      onValueChange={value => {
                        setInputValue(value)
                        onDebouncedValueChange(value)
                      }}
                      placeholder={t('filter.search-salesorg.placeholder')}
                      ref={inputRef}
                      showClearButton
                      type="search"
                      value={inputValue}
                    />
                    <HorizontalDivider mb={0} outside={4} />
                  </Box>
                )}
                {isShowSearchBody && (
                  <PopoverBody overflowY="scroll" p={4}>
                    {isFetching ? (
                      <Center>
                        <LoaderRound display="inline" />
                      </Center>
                    ) : searchedOptions && searchedOptions.length > 0 ? (
                      <VStack spacing={4}>
                        {searchedOptions.map(option => (
                          <CustomerSelectorOption
                            isActiveCustomer={isActiveCustomer}
                            key={option.salesGroup?.id}
                            onClick={selectedCustomer => {
                              handleClose()
                              onChangeActiveCustomerModal(selectedCustomer)
                              updateSearchQuery('')
                            }}>
                            {option}
                          </CustomerSelectorOption>
                        ))}
                        {isShowMoreResultsMessage && <Text>{t('label.moreresultsavailable')}</Text>}
                      </VStack>
                    ) : (
                      <NoResultsMessage />
                    )}
                  </PopoverBody>
                )}
              </PopoverContent>
            )}
          </Popover>
          <ChangeSalesOrgModal
            isOpen={isModalOpen}
            onClose={modalClose}
            onConfirm={handleActiveCustomerModalConfirm}
          />
        </div>
      </div>
    </CustomerSelectorSearchContext.Provider>
  )
}
