import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFilter = createIcon({
  displayName: 'IconFilter',
  path: [
    <path
      d="M11 22H9V14.41L2 7.41V3H22V7.41L15 14.41V20H13V13.59L20 6.59V5H4V6.59L11 13.59V22Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
