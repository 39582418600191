import { FC } from 'react'

import { init as initApiCart } from '#modules/cart-v2/init.js'
import { init as initCo2Xplorer } from '#modules/co2-xplorer/init.js'
import { init as initApiCustomer } from '#modules/customer/init.js'
import { init as initApiPayment } from '#modules/payment/init.js'
import { RouterProvider } from '#shared/providers/RouterProvider.js'

import './globals.css'

// TODO: Initialize / register modules in a more generic way
initCo2Xplorer()
initApiCustomer()
initApiCart()
initApiPayment()

export const App: FC = () => {
  return <RouterProvider />
}
