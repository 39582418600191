import React, { useEffect } from 'react'
import { FC } from 'react'

import * as Sentry from '@sentry/react'

import { useSentryContext } from './useSentryContext'

type Props = {
  children: React.ReactNode
}

export const SentryIdentifier: FC<Props> = ({ children }) => {
  const {
    loggedInUserId,
    selectedCustomerId,
    selectedSalesGroupId,
    selectedShipToLocation,
    userSub,
  } = useSentryContext()

  useEffect(() => {
    Sentry.setUser(userSub ? { id: userSub } : null)
  }, [userSub])

  useEffect(() => {
    Sentry.setContext('salesGroup', selectedSalesGroupId ? { id: selectedSalesGroupId } : null)
  }, [selectedSalesGroupId])

  useEffect(() => {
    Sentry.setContext('customer', selectedCustomerId ? { id: selectedCustomerId } : null)
  }, [selectedCustomerId])

  useEffect(() => {
    Sentry.setContext('salesforceUser', loggedInUserId ? { id: loggedInUserId } : null)
  }, [loggedInUserId])

  useEffect(() => {
    Sentry.setContext(
      'selectedShipToLocation',
      selectedShipToLocation ? { id: selectedShipToLocation } : null,
    )
  }, [selectedShipToLocation])

  return <>{children}</>
}
