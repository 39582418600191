import React from 'react'

import { IconButton, IconButtonProps } from '@chakra-ui/react'

import { IconClose } from '#shared/icons/brand/index.js'

interface ModalCloseButtonProps extends IconButtonProps {
  onClose: () => void
}

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({ onClose, ...rest }) => (
  <IconButton
    icon={<IconClose h={6} w={6} />}
    onClick={onClose}
    position="absolute"
    right={4}
    top={5}
    {...rest}
  />
)
