import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconBrowser = createIcon({
  displayName: 'IconBrowser',
  path: [
    <path d="M2 4H22V6H4V18H20V8H22V20H2V4Z" fill="currentColor" key="0" />,
    <path d="M6 8H18V10H6V8Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
