import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeFavorite,
  DataTypeFavoriteItem,
  DataTypeProduct,
  DeliveryAttributes,
  FavoriteItemResource,
  SyncStatusEnum,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<DeliveryAttributes>
  id?: string
  relationships: {
    favoriteListId: string
    productId: string
  }
}

export const buildFavoriteItem = ({
  attributes,
  id = faker.string.uuid(),
  relationships,
}: Props): FavoriteItemResource => {
  return {
    attributes: merge(
      {
        createdAt: faker.date.recent().toISOString(),
        isActive: true,
        number: faker.number.int().toString(),
        syncStatus: SyncStatusEnum.Confirmed,
        updatedAt: faker.date.past().toISOString(),
      },
      attributes,
    ),
    id,
    relationships: {
      favorite: {
        data: {
          id: relationships.favoriteListId,
          type: DataTypeFavorite.Favorite,
        },
      },
      product: {
        data: {
          id: relationships.productId,
          type: DataTypeProduct.Product,
        },
      },
    },
    type: DataTypeFavoriteItem.FavoriteItem,
  }
}
