import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 20h-2.41L16 16.41V20H2V8h2v10h10v-4h2.41L20 17.59V6.41L16.41 10H14V6H2V4h14v3.59L19.59 4H22z"
      fill="currentColor"
    />
  </svg>
)
export default SvgVideo
