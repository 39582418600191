import React from 'react'

import { AppHeaderProvider } from '../context/AppHeaderContext'

interface Props {
  children: React.ReactNode
}

export const DefaultAppHeader: React.FC<Props> = ({ children }: Props) => {
  return (
    <AppHeaderProvider>
      <header>{children}</header>
    </AppHeaderProvider>
  )
}
