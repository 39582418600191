import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  CaseSearchRequestFilter,
  CaseSearchRequestFilterType,
  CaseSearchResponse,
  CaseSearchResponseContent,
  CaseSearchResponseFilters,
  CaseStatus,
  CaseType,
  DateRangeFilterValueType,
} from '@brenntag/connect-apis/api-case/types'

interface buildCaseProps {
  overrides?: Partial<CaseSearchResponseContent>
}
interface Props {
  cases: CaseSearchResponseContent[]
  filters: CaseSearchRequestFilter[]
  searchQuery?: string
  sort?: string
  sorts?: string[]
}
export const buildCases = ({ overrides }: buildCaseProps): CaseSearchResponseContent => {
  return merge(
    {
      createdDate: faker.date.past().toISOString(),
      dateOfOccurrence: faker.date.past().toISOString(),
      id: faker.string.uuid(),
      invoiceNumber: faker.string.numeric(),
      lastModifiedDate: faker.date.past().toISOString(),
      number: faker.string.numeric(),
      orderNumber: faker.string.numeric(),
      purchaseOrderNumber: faker.string.numeric(),
      relatedTo: faker.helpers.arrayElement([CaseType.Invoice, CaseType.Order]),
      status: faker.helpers.arrayElement(Object.values(CaseStatus)),
      summary: faker.lorem.sentence(),
    } satisfies CaseSearchResponseContent,
    overrides,
  )
}

export const buildCaseSearch = ({
  cases,
  filters,
  searchQuery,
  sort,
  sorts,
}: Props): CaseSearchResponse => {
  const size = cases.length ?? 10
  const minDate = new Date(
    new Date().getFullYear() - 1,
    Math.floor(Math.random() * 12),
    Math.floor(Math.random() * 28),
  ).toISOString()
  const maxDate = new Date().toISOString()
  const lastYearStartDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1),
  ).toISOString()
  const lastThreeMonthsEndDate = new Date(
    new Date().setMonth(new Date().getMonth() - 3),
  ).toISOString()
  const lastMonthEndDate = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString()
  const lastWeekEndDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()

  const selectedStatus = filters.find(filter => filter.name === 'status')?.values
  const selectedRelatedTo = filters.find(filter => filter.name === 'relatedTo')?.values

  const responseFilters: CaseSearchResponseFilters[] = [
    {
      name: 'status',
      selectedValue: selectedStatus,
      type: CaseSearchRequestFilterType.Term,
      values: [
        {
          count: cases.filter(caseItem => caseItem.status === CaseStatus.New).length,
          value: CaseStatus.New,
        },
        {
          count: cases.filter(caseItem => caseItem.status === CaseStatus.InProgress).length,
          value: CaseStatus.InProgress,
        },
        {
          count: cases.filter(caseItem => caseItem.status === CaseStatus.Escalated).length,
          value: CaseStatus.Escalated,
        },
        {
          count: cases.filter(caseItem => caseItem.status === CaseStatus.InReview).length,
          value: CaseStatus.InReview,
        },
        {
          count: cases.filter(caseItem => caseItem.status === CaseStatus.OnHold).length,
          value: CaseStatus.OnHold,
        },
        {
          count: cases.filter(caseItem => caseItem.status === CaseStatus.Closed).length,
          value: CaseStatus.Closed,
        },
        {
          count: cases.filter(caseItem => caseItem.status === CaseStatus.Cancelled).length,
          value: CaseStatus.Cancelled,
        },
      ],
    },
    {
      name: 'relatedTo',
      selectedValue: filters.find(filter => filter.name === 'relatedTo')?.values,
      type: CaseSearchRequestFilterType.Term,
      values: [
        {
          count: cases.filter(caseItem => caseItem.relatedTo === CaseType.Invoice).length,
          value: CaseType.Invoice,
        },
        {
          count: cases.filter(caseItem => caseItem.relatedTo === CaseType.Order).length,
          value: CaseType.Order,
        },
      ],
    },
    {
      name: 'createdDate',
      type: CaseSearchRequestFilterType.DateRange,
      values: [
        {
          date: minDate,
          value: DateRangeFilterValueType.MinDate,
        },
        {
          date: maxDate,
          value: DateRangeFilterValueType.MaxDate,
        },
        {
          endDate: maxDate,
          startDate: lastYearStartDate,
          value: DateRangeFilterValueType.LastYear,
        },
        {
          endDate: maxDate,
          startDate: lastThreeMonthsEndDate,
          value: DateRangeFilterValueType.LastThreeMonths,
        },
        {
          endDate: maxDate,
          startDate: lastMonthEndDate,
          value: DateRangeFilterValueType.LastMonth,
        },
        {
          endDate: maxDate,
          startDate: lastWeekEndDate,
          value: DateRangeFilterValueType.LastWeek,
        },
      ],
    },
  ]

  if (selectedStatus) {
    cases = cases.filter(caseItem => selectedStatus.includes(caseItem.status))
  }

  if (selectedRelatedTo) {
    cases = cases.filter(caseItem => selectedRelatedTo.includes(caseItem.relatedTo || ''))
  }

  return {
    content: cases.map(caseItem => ({
      createdDate: caseItem.createdDate,
      dateOfOccurrence: caseItem.dateOfOccurrence,
      id: caseItem.id,
      invoiceNumber: caseItem.relatedTo === CaseType.Invoice ? caseItem.invoiceNumber : undefined,
      lastModifiedDate: caseItem.lastModifiedDate,
      number: caseItem.number,
      orderNumber: caseItem.relatedTo === CaseType.Order ? caseItem.orderNumber : undefined,
      purchaseOrderNumber: caseItem.purchaseOrderNumber,
      relatedTo: caseItem.relatedTo,
      status:
        caseItem.status === undefined
          ? faker.helpers.arrayElement(Object.values(CaseStatus))
          : caseItem.status,
      summary: caseItem.summary === undefined ? faker.lorem.sentence() : caseItem.summary,
    })),
    filters: responseFilters,
    page: {
      number: 0,
      size,
      total: cases?.length ?? size,
    },
    searchQuery: searchQuery === undefined ? '' : searchQuery,
    sort: sort === undefined ? 'createdDate' : sort,
    sorts: sorts === undefined ? ['createdDate'] : sorts,
  }
}
