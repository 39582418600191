import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSocialFacebook = createIcon({
  displayName: 'IconSocialFacebook',
  path: [
    <path
      d="M17.0881 12.9993L17.5981 9.0793H13.7081V6.5793C13.7081 5.4493 14.0281 4.67929 15.6481 4.67929H17.7081V1.17929C16.7081 1.07929 15.7081 1.0293 14.7081 1.0293C11.7081 1.0293 9.70812 2.8493 9.70812 6.1993V9.08929H6.32812V12.9993H9.70812V22.9993H13.7081V12.9993H17.0881Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
