import { uniqBy } from 'lodash'

import {
  ManyIncludedFavoriteResourceFavoriteItemResource,
  ManyMeta,
  SingleIncludedFavoriteResourceFavoriteItemResource,
} from '@brenntag/connect-apis/api/types'

interface Props {
  favoriteLists: SingleIncludedFavoriteResourceFavoriteItemResource[]
  meta?: Partial<ManyMeta>
}

export const buildFavoriteLists = ({
  favoriteLists,
  meta,
}: Props): ManyIncludedFavoriteResourceFavoriteItemResource => {
  return {
    data: favoriteLists.map(invoice => invoice.data),
    included: uniqBy(
      favoriteLists.flatMap(invoice => invoice.included || []),
      resource => resource.id,
    ),
    meta: { count: favoriteLists.length, ...meta },
  }
}
