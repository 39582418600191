import * as React from 'react'

import { RouteObject, useParams } from 'react-router'

import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { Loading } from '#lib/auth/Loading.js'
import { salesforceSession } from '#lib/auth/salesforce/salesforceSession.js'
import { loginWithSalesforceInternal } from '#lib/auth/salesforce/startSessionInternal.js'
import { RootLayout } from '#shared/layouts/RootLayout.js'
import { RootErrorBoundary } from '#shared/pages/Error/RootErrorBoundary.js'
import { logoutRoute } from '#shared/routing/connect/logoutRoute.js'
import { protectedRoutes } from '#shared/routing/connect/protectedRoutes.js'
import { publicRoutes } from '#shared/routing/publicRoutes.js'
import { isEnvironment } from '#src/env.js'

import { NotFound } from '../pages/NotFound'
import { co2XplorerRoutes } from './co2xplorer/co2xplorerRoutes'
import { Navigate } from './components/Navigate'
import { validCountryLanguageLoader } from './loaders/validCountryLanguageLoader'

export const routes: RouteObject[] = [
  {
    children: [
      {
        lazy: () => import('./auth0HandlerRoute.js'),
        path: 'auth0-handler',
      },
      {
        lazy: () => import('./loginHandlerRoute.js'),
        path: 'login-handler',
      },
      {
        children: [publicRoutes, co2XplorerRoutes, protectedRoutes],
        path: ':locale',
      },
      {
        children: [
          {
            ...logoutRoute,
          },
        ],
        path: ':locale?/connect',
      },
      {
        loader: () => {
          salesforceSession.reset()
          loginWithSalesforceInternal({
            countryCode: 'US',
            isInternal: true,
          })
          return true
        },
        path: ':locale?/connect/internal',
      },
      {
        Component: () => {
          const isDevOrLocal = isEnvironment('development', 'local')
          const targetPath = isDevOrLocal ? '/' : '/en-US/connect'
          // Workaround: Ensures consistent redirect for `/connect/*`.
          // Client-side navigation is not fully resolve it before redirecting to login.
          React.useEffect(() => {
            window.location.replace(targetPath)
          }, [targetPath])

          return null
        },
        path: '/connect/*',
      },
      {
        // If user lands exactly on :locale/ redirect to the appropriate page
        Component: () => {
          const params = useParams<{ locale?: string }>()
          const { countryCode } = parseLocale(params.locale)

          // TODO: Generalize this
          const suffix = countryCode === 'SE' ? 'co2xplorer' : 'dashboard'
          return <Navigate replace to={`/${params.locale}/connect/${suffix}`} />
        },
        loader: validCountryLanguageLoader,
        path: ':locale/',
      },
      { Component: NotFound, path: '*' },
      {
        index: true,
        lazy: () => import('#shared/pages/CountrySelection/CountrySelectionPage.js'),
      },
    ],
    Component: RootLayout,
    ErrorBoundary: RootErrorBoundary,
    HydrateFallback: () => <Loading text="Initializing Brenntag Connect" />,
  },
]
