export const groupArrayByValue = <T extends object, Q extends string>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => Q,
): Record<Q, T[]> =>
  array.reduce(
    (result, value, index, array) => {
      const key = predicate(value, index, array) as Q
      const groupArray = (result[key] ||= [])
      groupArray.push(value)
      return result
    },
    {} as Record<Q, T[]>,
  )
