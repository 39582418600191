import { createStore } from 'jotai'
import { atomWithStorage, RESET } from 'jotai/utils'

import { isIdTokenExpired } from './lib/isIdTokenExpired'

type SalesforceSessionStore = {
  accessToken?: string
  idToken?: string
  isAuthenticating: boolean
  isInternal: boolean
}

export const salesforceSessionAtom = atomWithStorage<SalesforceSessionStore>(
  'salesforceSession',
  {
    accessToken: undefined,
    idToken: undefined,
    isAuthenticating: false,
    isInternal: false,
  },
  undefined,
  { getOnInit: true },
)

export const salesforceSessionStore: ReturnType<typeof createStore> = createStore()

const getTokens = () => {
  const { accessToken, idToken } = salesforceSessionStore.get(salesforceSessionAtom)

  return { accessToken, idToken }
}

const hasValidSession = () => {
  const { accessToken, idToken } = getTokens()

  return Boolean(accessToken && idToken && !isIdTokenExpired(idToken))
}

const setIsAuthenticating = (isAuthenticating: boolean) => {
  salesforceSessionStore.set(salesforceSessionAtom, current => ({
    ...current,
    isAuthenticating,
  }))
}

const reset = () => {
  salesforceSessionStore.set(salesforceSessionAtom, RESET)
}

const shouldAuthorizeWithSalesforce = (countryCode: string) => {
  return Boolean(['US'].includes(countryCode))
}

export const salesforceSession = {
  getTokens,
  hasValidSession,
  reset,
  setIsAuthenticating,
  shouldAuthorizeWithSalesforce,
}
