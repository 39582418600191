import { http, HttpResponse } from 'msw'

import { config } from '#config'
import { Product } from '#requests/products/types.js'

import { products } from './data'

const fuzzySearch = (search: string, items: Product[], key: 'cas' | 'name' = 'name') => {
  const normalizeString = (str: string) => str.toLowerCase().replace(/\s+/g, '')

  const normalizedQuery = normalizeString(search)

  return items.filter(item => {
    const value = item[key]
    const normalizedValue = normalizeString(value)

    return normalizedValue.includes(normalizedQuery)
  })
}

export default () => {
  const { getBaseUrl } = config

  return [
    http.get(`${getBaseUrl()}/products`, ({ request }) => {
      const url = new URL(request.url)

      const search = url.searchParams.get('search') ?? ''

      return HttpResponse.json({
        data: [...fuzzySearch(search, products, 'name'), ...fuzzySearch(search, products, 'cas')],
        meta: {},
      })
    }),
  ]
}
