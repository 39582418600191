import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M20.29 16.71 12 8.41l-8.29 8.3-1.42-1.42L12 5.59l9.71 9.7z" fill="currentColor" />
  </svg>
)
export default SvgChevronUp
