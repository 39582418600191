import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOrderDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m12.71 15.76-1 1-1-1V3h2z" fill="currentColor" />
    <path d="m7 17.71 4.71 4.7 4.71-4.7L15 16.29l-3.29 3.3-3.29-3.3z" fill="currentColor" />
  </svg>
)
export default SvgOrderDown
