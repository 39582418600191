import { useMemo } from 'react'

import { useSession } from '#lib/auth/useSession.js'
import { useGetSalesGroups } from '#modules/customer/hooks/sales-groups/useGetSalesGroups.js'
import { useLoggedInUser } from '#shared/hooks/globalState/useLoggedInUser.js'
import { useSelectedCustomer } from '#shared/hooks/globalState/useSelectedCustomer.js'

/**
 * Gathers the context for LaunchDarkly. Eventually, we want to use
 * a centralised app context for this.
 */
export const useLaunchDarklyContext = () => {
  const { selectedCustomer } = useSelectedCustomer()
  const { user } = useSession()
  const loggedInUser = useLoggedInUser()
  const salesGroups = useGetSalesGroups()
  const selectedSalesGroup = useMemo(
    () => salesGroups?.data?.find(sg => sg.id === selectedCustomer?.salesGroupId),
    [salesGroups?.data, selectedCustomer],
  )

  const context = useMemo(
    () => ({
      ...(selectedCustomer?.id ? { customer: { key: selectedCustomer.id } } : {}),
      ...(selectedSalesGroup?.id ? { salesGroup: { key: selectedSalesGroup.id } } : {}),
      ...(user?.sub ? { user: { key: user.sub } } : {}),
      ...(loggedInUser?.id ? { sfUser: { key: loggedInUser.id } } : {}),
    }),
    [loggedInUser, selectedCustomer, selectedSalesGroup, user],
  )

  return context
}
