import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m18.5 4.09-1.41-1.42-1.786 1.786-.574-.236L13.62 2h-3.24L9.27 4.22l-.73.3-2.2-1.27-3.09 3.09 1.27 2.2-.3.73L2 10.38v3.24l2.22 1.11.24.57-1.79 1.79 1.42 1.41 2.73-2.73-1.04-2.5L4 12.38v-.76l1.78-.89.97-2.34-1-1.73.91-.91 1.73 1 2.34-.97.89-1.78h.76l.89 1.78 2.5 1.04zM19.91 5.5l-2.73 2.73 1.04 2.5 1.78.89v.76l-1.78.89-.97 2.34 1 1.73-.91.91-1.73-1-2.34.97-.89 1.78h-.76l-.89-1.78-2.5-1.04-2.73 2.73 1.41 1.42 1.79-1.79.57.24L10.38 22h3.24l1.11-2.22.73-.3 2.2 1.27 3.09-3.09-1.27-2.2.3-.73L22 13.62v-3.24l-2.22-1.11-.24-.57 1.79-1.79z"
      fill="currentColor"
    />
    <path
      d="M14 12c0-.18-.03-.35-.07-.51l-.01-.01 1.51-1.51c.35.6.57 1.28.57 2.02 0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4c.74 0 1.43.22 2.02.57l-1.51 1.51c-.16-.04-.33-.07-.51-.07-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2"
      fill="currentColor"
    />
  </svg>
)
export default SvgSettings
