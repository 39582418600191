import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 10.382v3.236L5.236 22H2.612l2.334-7h2.108l-1.542 4.626L20 12.382v-.764L5.512 4.374 7.721 11H16v2H6.279L2.612 2h2.624z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSend
