import { useCallback } from 'react'

import { useIntl } from 'react-intl'

import { TranslationKey } from '@brenntag/connect-translations'

export interface Translate {
  (key: TranslationKey): string
  (key: TranslationKey, values: Record<string, string>): string
}

export function useTranslations(): Translate {
  const { formatMessage } = useIntl()

  function translation(key: TranslationKey): string
  function translation(key: TranslationKey, values: Record<string, string>): string
  function translation(key: TranslationKey, values?: Record<string, string>): string {
    return formatMessage({ id: key }, values)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(translation, [formatMessage])
}
