import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCalendarUpdated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M21 6V4h-3V2h-2v2H8V2H6v2H3v2zM21 21H3V8h18v2H5v9h14v-7h2z" fill="currentColor" />
    <path
      d="M11.555 13.068a1.5 1.5 0 0 1 .983.032l.716-1.867a3.5 3.5 0 1 0 2.127 2.361l-1.932.518a1.5 1.5 0 1 1-1.894-1.044"
      fill="currentColor"
    />
  </svg>
)
export default SvgCalendarUpdated
