import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m3 13.41 9.5 9.5L22.91 12.5 13.41 3H7v2h5.59l7.5 7.5-7.59 7.59-7.5-7.5V3H3z"
      fill="currentColor"
    />
    <path d="M10 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="currentColor" />
  </svg>
)
export default SvgTag
