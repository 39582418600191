import { OrderStatus, SingleIncludedOrderResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildOrder } from '@brenntag/legacy-mock-generators/generators/orders/buildOrder.js'
import { buildOrderLine } from '@brenntag/legacy-mock-generators/generators/orders/buildOrderLine.js'

import { manyPricePoints } from '#src/mocks/apis/api/products/data/manyPricePoints.js'
import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

export const entered: SingleIncludedOrderResourceUnion = buildOrder({
  attributes: {
    salesOrderNumber: 'Entered order',
    status: OrderStatus.Entered,
  },
  id: 'entered',
  included: {
    attachments: [],
    deliveries: [],
    orderLines: [
      [
        buildOrderLine({
          id: 'orderLine1',
          included: { product: manyPricePoints.data },
          orderId: 'entered',
        }),
        manyPricePoints,
      ],
    ],
    shipToLocation: buildLocation({ id: locations.hidePrices.id }),
  },
})
