import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 22.41 8.59 19H3V3h18v2H5v12h4.41L12 19.59 14.59 17H19V7h2v12h-5.59z"
      fill="currentColor"
    />
  </svg>
)
export default SvgChat
