import React, { type ReactNode } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { Button } from '@brenntag/ui/components/Button'
import { Icon } from '@brenntag/ui/components/Icon'
import { Modal } from '@brenntag/ui/components/Modal'

import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { useNavigate } from '#shared/routing/hooks/useNavigate.js'

interface Props {
  children: ReactNode
}

export const LogoutAction = ({ children }: Props) => {
  const navigate = useNavigate()
  const t = useTranslations()

  const {
    isOpen: isConfirmLogoutModalOpen,
    onClose: closeConfirmLogoutModal,
    onOpen: openConfirmLogoutModal,
  } = useDisclosure()

  return (
    <>
      <Button asChild onClick={openConfirmLogoutModal}>
        {children}
      </Button>
      <Modal onOpenChange={closeConfirmLogoutModal} open={isConfirmLogoutModalOpen}>
        <h1 className="pb-8 text-lg font-semibold">{t('logout.confirmlogout')}</h1>
        <div className="flex flex-col gap-4 md:w-96 md:flex-row">
          <Button onClick={() => navigate('/:locale/connect/logout')} variant="solid">
            <Icon name="Logout" />
            {t('label.logout')}
          </Button>
          <Button onClick={closeConfirmLogoutModal} variant="outline">
            <Icon name="Close" />
            {t('label.cancel')}
          </Button>
        </div>
      </Modal>
    </>
  )
}
