import { extendTheme } from '@chakra-ui/react'
import { GlobalStyleProps, mode } from '@chakra-ui/theme-tools'

import brenntag from '@brenntag/ui/presets/brenntag'

import { Badge } from './components/badge'
import { Button } from './components/button'
import { Card } from './components/card'
import { Checkbox } from './components/checkbox'
import { Divider } from './components/divider'
import { FormLabel, Input, Select, Textarea } from './components/form'
import { Heading } from './components/heading'
import { IconBadge } from './components/iconBadge'
import { Modal } from './components/modal'
import { Pagination } from './components/pagination'
import { PhoneNumberInput } from './components/phoneNumberInput'
import { Popover } from './components/popover'
import { Progress } from './components/progress'
import { Radio, RadioGroup } from './components/radio'
import { Switch } from './components/switch'
import { Tabs } from './components/tabs'
import { Tag } from './components/tag'
import { Text } from './components/text'
import { Tooltip } from './components/tooltip'
import { breakpoints } from './foundations/breakpoints'
import { shadows } from './foundations/shadows'
import {
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  textStyles,
} from './foundations/typography'

export const theme = extendTheme({
  breakpoints,
  colors: brenntag.theme?.colors,
  components: {
    Badge,
    Button,
    Card,
    Checkbox,
    Divider,
    FormLabel,
    Heading,
    IconBadge,
    Input,
    Modal,
    Pagination,
    PhoneNumberInput,
    Popover,
    Progress,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Tabs,
    Tag,
    Text,
    Textarea,
    Tooltip,
  },
  direction: 'ltr',
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii: {
    md: '0px',
  },
  shadows,
  sizes: {
    '2xl': '52rem',
    '3xl': '56rem',
    '4xl': '64rem',
    '5xl': '72rem',
    '6xl': '90rem',
    '7xl': '100rem',
    '8xl': '120rem',
    lg: '40rem',
    md: '29rem',
    sm: '25rem',
    xl: '48rem',
  },
  styles: {
    global: (props: GlobalStyleProps) => ({
      '*, *::before, &::after': {
        borderColor: mode('neutral.20', 'whiteAlpha.300')(props),
      },
    }),
  },
  textStyles,
})
