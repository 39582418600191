import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUserAdmin = createIcon({
  displayName: 'IconUserAdmin',
  path: [
    <path
      d="M5.5 6.49103C5.5 4.01003 7.519 1.99103 10 1.99103C10.879 1.99103 11.693 2.25403 12.386 2.69103L10.909 4.16803C10.626 4.05803 10.321 3.99103 10 3.99103C8.622 3.99103 7.5 5.11203 7.5 6.49103C7.5 7.87003 8.622 8.99103 10 8.99103C11.378 8.99103 12.5 7.87003 12.5 6.49103C12.5 6.17003 12.434 5.86503 12.323 5.58203L13.8 4.10503C14.237 4.79803 14.5 5.61303 14.5 6.49103C14.5 8.97203 12.481 10.991 10 10.991C7.519 10.991 5.5 8.97303 5.5 6.49103ZM15.456 15.447L14.816 16.992L15.456 18.537L17 19.176L18.545 18.536L19.185 16.991L18.545 15.446L17 14.808L15.456 15.447ZM17.556 11.992L18.112 13.103L18.965 13.456L20.056 12.825L21.167 13.936L20.536 15.027L20.889 15.88L22 16.436V17.547L20.889 18.103L20.536 18.956L21.167 20.047L20.056 21.158L18.965 20.527L18.112 20.88L17.556 21.991H16.445L15.889 20.88L15.036 20.527L13.945 21.158L12.834 20.047L13.465 18.956L13.112 18.103L12 17.547V16.436L13.111 15.88L13.464 15.027L12.833 13.936L13.944 12.825L15.035 13.456L15.888 13.103L16.444 11.992H17.556ZM5 17.991V13.991H8V11.991H3V19.991H10V17.991H5Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
