import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBarcode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4 5H2v14h2zM7 5H6v14h1zM20 5h2v14h-2zM15 5H9v14h6zM17 5h1v14h-1z"
      fill="currentColor"
    />
  </svg>
)
export default SvgBarcode
