import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFileCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M15.41 2H13v7h5v11H6V4h5V2H4v20h16V6.59zM15 7V4.41L17.59 7z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="m14.71 17.71 2.7-2.71-2.7-2.71-1.42 1.42 1.3 1.29-1.3 1.29zM6.59 15l2.7-2.71 1.42 1.42L9.41 15l1.3 1.29-1.42 1.42z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFileCode
