import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconParcelAdd = createIcon({
  displayName: 'IconParcelAdd',
  path: [
    <path
      d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202Z"
      fill="currentColor"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.93986L16 7.60986V9.71986L13 10.7199V19.6099L20 17.2799ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
    <path d="M18 4H16V6H18V8H20V6H22V4H20V2H18V4Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
