const {
  VITE_ANALYTICS_KEY,
  VITE_API_BASE_URL,
  VITE_API_GATEWAY_URL,
  VITE_CART_API_BASE_URL,
  VITE_CHAT_ENABLED,
  VITE_CO2_API_BASE_URL,
  VITE_CO2_API_REQUEST_ACCESS_KEY,
  VITE_CUSTOMER_API_BASE_URL,
  VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  VITE_PAYMENT_API_BASE_URL,
  VITE_QUESTION_PRO_ENABLED,
  VITE_QUESTION_PRO_ID,
  VITE_SENTRY_DSN,
  VITE_SF_CHAT_BASE_CORE_URL,
  VITE_SF_CHAT_BUTTON_ID,
  VITE_SF_CHAT_COMMUNITY_ENDPOINT_URL,
  VITE_SF_CHAT_DEPLOYMENT_ID,
  VITE_SF_CHAT_ESW_CONFIG_DEV_NAME,
  VITE_SF_CHAT_ESW_LIVE_AGENT_DEV_NAME,
  VITE_SF_CHAT_GSLB_BASE_URL,
  VITE_SF_CHAT_LIVE_AGENT_CONTENT_URL,
  VITE_SF_CHAT_LIVE_AGENT_URL,
  VITE_SF_CHAT_ORG_ID,
  VITE_SF_CHAT_SRC_URL,
} = import.meta.env

interface Config {
  analyticsKey: string
  apiBaseUrl: string
  apiGatewayUrl: string
  cartApiBaseUrl: string
  chat?: {
    sfChatBaseCoreUrl: string
    sfChatButtonId: string
    sfChatCommunityEndpointUrl: string
    sfChatDeploymentId: string
    sfChatEswConfigDevName: string
    sfChatEswLiveAgentDevName: string
    sfChatGslbBaseUrl: string
    sfChatLiveAgentContentUrl: string
    sfChatLiveAgentUrl: string
    sfChatOrgId: string
    sfChatSrcUrl: string
  }
  chatEnabled: boolean
  co2ApiBaseUrl: string
  co2ApiRequestAccessApiKey: string
  customerApiBaseUrl: string
  launchDarklyClientSideId: string
  paymentApiBaseUrl: string
  questionPro?: {
    enabled: boolean
    id: string
  }
  sentryDSN: string
}

export const config = {
  analyticsKey: VITE_ANALYTICS_KEY,
  apiBaseUrl: VITE_API_BASE_URL,
  apiGatewayUrl: VITE_API_GATEWAY_URL,
  cartApiBaseUrl: VITE_CART_API_BASE_URL,
  chat: {
    sfChatBaseCoreUrl: VITE_SF_CHAT_BASE_CORE_URL,
    sfChatButtonId: VITE_SF_CHAT_BUTTON_ID,
    sfChatCommunityEndpointUrl: VITE_SF_CHAT_COMMUNITY_ENDPOINT_URL,
    sfChatDeploymentId: VITE_SF_CHAT_DEPLOYMENT_ID,
    sfChatEswConfigDevName: VITE_SF_CHAT_ESW_CONFIG_DEV_NAME,
    sfChatEswLiveAgentDevName: VITE_SF_CHAT_ESW_LIVE_AGENT_DEV_NAME,
    sfChatGslbBaseUrl: VITE_SF_CHAT_GSLB_BASE_URL,
    sfChatLiveAgentContentUrl: VITE_SF_CHAT_LIVE_AGENT_CONTENT_URL,
    sfChatLiveAgentUrl: VITE_SF_CHAT_LIVE_AGENT_URL,
    sfChatOrgId: VITE_SF_CHAT_ORG_ID,
    sfChatSrcUrl: VITE_SF_CHAT_SRC_URL,
  },
  chatEnabled: VITE_CHAT_ENABLED?.toLowerCase() === 'true',
  co2ApiBaseUrl: VITE_CO2_API_BASE_URL,
  co2ApiRequestAccessApiKey: VITE_CO2_API_REQUEST_ACCESS_KEY,
  customerApiBaseUrl: VITE_CUSTOMER_API_BASE_URL,
  launchDarklyClientSideId: VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  paymentApiBaseUrl: VITE_PAYMENT_API_BASE_URL,
  questionPro: {
    enabled: VITE_QUESTION_PRO_ENABLED?.toLowerCase() === 'true',
    id: VITE_QUESTION_PRO_ID,
  },
  sentryDSN: VITE_SENTRY_DSN,
} satisfies Config
