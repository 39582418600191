import {
  AttachmentAttributes,
  BatchAttributes,
  ContactAttributes,
  CustomerContextAttributes,
  DeliveryAttributes,
  DocumentAttributes,
  FavoriteAttributes,
  FavoriteItemAttributes,
  InvoiceAttributes,
  LocationAttributes,
  LocationType,
  OrderAttributes,
  OrderLineAttributes,
  OrderTrackingResponse,
  PimSpecificationAttributes,
  PricebookAttributes,
  PricebookProductAttributes,
  PricePointAttributes,
  ProductAttributes,
  ProductKey,
  QuoteAttributes,
  QuoteLineAttributes,
  SalesGroupAttributes,
  SampleAttributes,
  SpecificationAttributes,
  TankLocationAttributes,
  UserAttributes,
  ValueAddedServiceAttributes,
} from './types'
import { InvoiceType } from './types/invoice-type'
export type { ManyMeta } from './types'

export interface APILocationKey {
  id: string
  type: LocationType
}

export type APIProductPimSpecification = WithId<PimSpecificationAttributes>
export type APIProductSpecification = WithId<SpecificationAttributes>
export type APISalesGroup = WithId<SalesGroupAttributes>
type APIAttachment = WithId<AttachmentAttributes>

interface APILocation extends WithId<Omit<LocationAttributes, 'type'>> {
  contacts?: APIContact[]
  customerContexts: APICustomerContext[]
  type: 'bill-to' | 'ship-to' | 'sold-to'
}

type WithId<T> = T & { id: string }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAPILocation = (location: any): location is APILocation =>
  location?.name !== undefined

export interface APIBillToLocation extends APILocation {
  type: 'bill-to'
}

export interface APIContact extends WithId<ContactAttributes> {
  shipTo: APIShipToLocation
}

export interface APICustomerContext extends CustomerContextAttributes {
  id: string
  locations: APILocation[]
  salesGroup: WithId<SalesGroupAttributes>
}

export type APIDocument = WithId<DocumentAttributes>

export interface APIFavorite extends WithId<FavoriteAttributes> {
  favoriteItems?: APIFavoriteItem[]
  shipTo: APIShipToLocation
  user: APIUser
}

export interface APIFavoriteItem extends WithId<FavoriteItemAttributes> {
  favorite: APIFavorite
  product: ProductKey
}

export interface APIInvoice extends WithId<InvoiceAttributes> {
  delivery?: DeliveryAttributes &
    WithId<{
      order?: APIOrder
    }>
  shipTo?: APIShipToLocation
  type: InvoiceType
}

export interface APIOrder extends OrderAttributes {
  attachments?: APIAttachment[]
  billTo?: APIBillToLocation
  customerContext: { id: string }
  deliveries: DeliveryAttributesWithBatches[]
  id: string
  orderLines: Orderline[]
  shipTo?: APIShipToLocation
  soldTo?: APILocationKey | APISoldToLocation
  user?: UserAttributes
}

export type APIOrderTracking = OrderTrackingResponse

export type APIPricePoint = WithId<PricePointAttributes>

export interface APIProduct extends ProductAttributes {
  favoriteItems?: APIFavoriteItem[]
  id: string
  pimSpecifications?: APIProductPimSpecification[]
  pricebookProduct?: APIPricebookProduct
  specifications?: APIProductSpecification[]
  tankLocations?: WithId<TankLocationAttributes>[]
}
export interface APIProductDocument extends APIDocument {
  product: APIProduct
}

export interface APIQuote extends WithId<QuoteAttributes> {
  attachments?: APIAttachment[]
  quoteLines?: APIQuoteLine[]
  shipTo?: APIShipToLocation
  user?: APIUser
}

export interface APIQuoteLine extends WithId<QuoteLineAttributes> {
  pricebookProduct?: APIPricebookProduct
  product: APIProduct
}

export interface APISample extends WithId<SampleAttributes> {
  pricePoints: APIPricePoint[]
  product: APIProduct
  shipTo?: APIShipToLocation
  user: APIUser
}

export interface APIService extends ValueAddedServiceAttributes {
  attachments: APIAttachment[]
  id: string
  user: APIUser
}

export interface APIShipToLocation extends APILocation {
  type: 'ship-to'
}

export interface APISoldToLocation extends APILocation {
  type: 'sold-to'
}
export type APIUser = WithId<UserAttributes>

type APIPricebook = WithId<PricebookAttributes>

interface APIPricebookProduct extends WithId<PricebookProductAttributes> {
  pricebook?: APIPricebook
  pricePoints: WithId<PricePointAttributes>[]
}

type DeliveryAttributesWithBatches = DeliveryAttributes & {
  batches: WithId<BatchAttributes>[]
}

type Orderline = OrderLineAttributes &
  WithId<{
    batches: WithId<BatchAttributes>[]
    product: APIProduct
  }>
