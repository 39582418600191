import { consola } from 'consola/browser'

import { APICart } from '@brenntag/connect-apis/api-cart'
import { CartItem } from '@brenntag/connect-apis/api-cart/models/cart'
import { Product } from '@brenntag/connect-apis/api/models/product'

import { transformCartItem } from './transformCartItem'

/**
 * Transforms a list of cart items into a list of CartItem objects.
 * @returns a filtered list of CartItem objects and the number of items that could not be transformed either because the product
 * was missing or some other issue
 */
export const transformCartItems = (
  cartItems: APICart['items'] = [],
  products: Product[],
  currencyIsoCode?: string,
) => {
  return cartItems.map<CartItem>(apiItem => {
    const product = products.find(apiProduct => apiProduct.id === apiItem.productId)
    if (!product) {
      consola.error(
        `Shopping cart item ${apiItem.id} has a missing product with id ${apiItem.productId}`,
      )
    }

    return transformCartItem({
      currencyIsoCode,
      error: !product
        ? {
            description: 'Please remove this product from your cart before proceeding',
            message: `Product unavailable`,
          }
        : undefined,
      item: apiItem,
      product,
    })
  })
}
