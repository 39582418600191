import React from 'react'

import { findAll } from 'highlight-words-core'

interface TextHighlightProps {
  children: string
  search?: string | string[]
}

export const TextHighlight = ({ children, search }: TextHighlightProps) => {
  if (!search) {
    return <>{children}</>
  }

  const searchWords = Array.isArray(search) ? search : search.split(' ')
  const chunks = findAll({
    caseSensitive: false,
    searchWords,
    textToHighlight: children,
  })

  return (
    <>
      {chunks.map(({ end, highlight, start }, index) => {
        const text = children.substring(start, end)
        return highlight ? (
          <span className="text-blue-50" key={index}>
            {text}
          </span>
        ) : (
          <React.Fragment key={index}>{text}</React.Fragment>
        )
      })}
    </>
  )
}
