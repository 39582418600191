import { useCallback } from 'react'

import { useAtom } from 'jotai'
import { atomWithReset, RESET } from 'jotai/utils'

import { SEARCH_MIN_CHARACTERS } from '#shared/constants.js'

const searchAtom = atomWithReset('')

export const useSearchQuery = () => {
  const [query, setQuery] = useAtom(searchAtom)

  const resetSearchQuery = () => {
    setQuery(RESET)
  }

  const updateSearchQuery = useCallback(
    (value: string) => {
      const updatedValue = value.length >= SEARCH_MIN_CHARACTERS ? value : ''
      if (updatedValue === query) return

      setQuery(updatedValue)
    },
    [setQuery, query],
  )

  return {
    query,
    resetSearchQuery,
    updateSearchQuery,
  }
}
