import { ComponentStyleConfig } from '@chakra-ui/react'

export const Modal: ComponentStyleConfig = {
  baseStyle: {
    body: {
      p: [4, null, 6],
    },
    closeButton: {
      color: 'primary',
      insetEnd: 5,
      top: 4,
    },
    dialog: {
      mx: 4,
    },
    dialogContainer: {
      // For Safari on iPhone, see https://maxschmitt.me/posts/css-100vh-mobile-browsers/
      bottom: 0,
      height: 'auto',
    },
    footer: {
      p: [4, null, 6],
    },
    header: {
      bgColor: 'neutral.5',
      mx: [0, null, 2],
      p: 4,
      textStyle: 'headers.2xl',
    },
    overlay: {
      backdropFilter: 'blur(1px)',
    },
  },
}
