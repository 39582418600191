import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUserCSR = createIcon({
  displayName: 'IconUserCSR',
  path: [
    <path
      d="M19.7 11.992H14V21.992H22V14.292L19.7 11.992ZM20 19.992H16V13.992H18V15.992H20V19.992ZM5.5 6.49103C5.5 4.01003 7.519 1.99103 10 1.99103C10.879 1.99103 11.693 2.25403 12.386 2.69103L10.909 4.16803C10.626 4.05803 10.321 3.99103 10 3.99103C8.622 3.99103 7.5 5.11203 7.5 6.49103C7.5 7.87003 8.622 8.99103 10 8.99103C11.378 8.99103 12.5 7.87003 12.5 6.49103C12.5 6.17003 12.434 5.86503 12.323 5.58203L13.8 4.10503C14.237 4.79803 14.5 5.61303 14.5 6.49103C14.5 8.97203 12.481 10.991 10 10.991C7.519 10.991 5.5 8.97303 5.5 6.49103ZM12 17.991V19.991H3V11.991H8V13.991H5V17.991H12Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
