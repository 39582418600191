import React, { FC } from 'react'

import { ServiceList } from '@brenntag/connect-apis/api-vas/models/service'

import { useFetchLoggedInUser } from '#shared/apis/api-user/me/hooks/useFetchLoggedInUser.js'
import { getServiceStatusStyles } from '#shared/components/Card/ServiceCard/getServiceStatusStyles.js'
import { ResultItem, ResultItemTitle } from '#shared/components/GlobalSearch/ResultItem.js'
import { ItemSearchResult } from '#shared/components/GlobalSearch/SearchResults/ItemSearchResult.js'
import { useFormatDate } from '#shared/locale/hooks/useIntl.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface ServiceSearchResultProps {
  onItemClick: VoidFunction
  onViewMoreClick: VoidFunction
  search: string
  serviceList: ServiceList
}

export const ServiceSearchResult: FC<ServiceSearchResultProps> = ({
  onItemClick,
  onViewMoreClick,
  search,
  serviceList: { content: services, page },
}) => {
  const t = useTranslations()
  const formatDate = useFormatDate()
  const { loggedInUser } = useFetchLoggedInUser()
  const user = loggedInUser && `${loggedInUser.firstName} ${loggedInUser.lastName}`

  return (
    <ItemSearchResult
      items={services}
      linkToDetails
      onItemClick={onItemClick}
      onViewMoreClick={onViewMoreClick}
      to="/:locale/connect/services"
      total={page?.total ?? 0}
      type="services">
      {service => {
        const status = getServiceStatusStyles(t, service.status)

        return (
          <ResultItem
            fields={{
              items: [
                {
                  key: 'service-type',
                  label: t('label.servicetype'),
                  value: service.type,
                },
                {
                  key: 'service-username',
                  label: t('label.requestedby'),
                  value: user,
                },
                {
                  key: 'date-issued',
                  label: t('label.createddate'),
                  value: formatDate(service.createdDate),
                },
                {
                  key: 'date-required',
                  label: t('label.requireddate'),
                  value: formatDate(service.requiredDate),
                },
              ],
              search,
            }}
            title={
              <ResultItemTitle
                label={t('label.servicenumber')}
                search={search}
                status={status}
                value={service.id}
              />
            }
          />
        )
      }}
    </ItemSearchResult>
  )
}
