import {
  NavigateOptions,
  To,
  // eslint-disable-next-line no-restricted-imports
  useNavigate as useNavigateOriginal,
} from 'react-router'

import { useCreatePath } from './useCreatePath'

/**
 * The hook `useNavigate` is a wrapper around `react-router`'s `useNavigate` hook.
 * This version of the hook can work with place holders in the path (e.g. `/:locale/connect`).
 */
export const useNavigate = () => {
  const navigateOriginal = useNavigateOriginal()
  const createRoutePath = useCreatePath()
  return (to: To, options?: NavigateOptions) => {
    const path = createRoutePath(to)
    if (path === undefined)
      throw new Error(`Path cannot be created using '${JSON.stringify(to)}' not found`)
    navigateOriginal(path, options)
  }
}
