import React from 'react'

interface Props {
  className?: string
}
export const AppLogo: React.FC<Props> = ({ className = 'h-32' }) => {
  const id = className.split(' ').join('')
  return (
    <svg className={className} viewBox="0 0 203 48" xmlns="http://www.w3.org/2000/svg">
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id={id}
        x1="-4.552"
        x2="33.343"
        y1="43.448"
        y2="5.552">
        <stop offset="0" stopColor="#c815e0" />
        <stop offset=".8" stopColor="#0c72ed" />
      </linearGradient>
      <path
        d="M34.461 24.02c-1.536 1.918-3.798 3.755-6.549 4.842 1.656 2.04 2.492 4.312 1.889 6.674-.575 2.255-2.731 4.456-5.8 4.456H8V27.994h14c7.72 0 14-6.279 14-13.997S29.72 0 22 0H0v17.996h8V7.998h14c3.31 0 6 2.689 6 5.999s-2.69 5.999-6 5.999H0V48h24c7.72 0 14-6.279 14-13.997 0-3.424-.963-7.268-3.539-9.983z"
        fill={`url(#${id})`}
      />
      <g fill="#1A0033">
        <path d="M55.878 24.19c0-7.207 4.669-12.215 11.775-12.215 5.854 0 9.914 3.384 10.76 8.831h-3.417c-.609-3.756-3.553-5.854-7.343-5.854-5.075 0-8.323 3.722-8.323 9.237 0 5.481 3.248 9.169 8.323 9.169 3.79 0 6.767-2.132 7.376-5.921h3.451c-.879 5.481-4.94 8.898-10.827 8.898-7.106.001-11.775-4.972-11.775-12.145zM82.484 27.438c0-5.177 3.857-8.933 8.933-8.933 5.075 0 8.933 3.756 8.933 8.933 0 5.143-3.857 8.898-8.933 8.898-5.075 0-8.933-3.755-8.933-8.898zm14.651 0c0-3.925-2.132-6.26-5.718-6.26-3.553 0-5.684 2.335-5.684 6.26 0 3.958 2.132 6.259 5.684 6.259 3.587 0 5.718-2.301 5.718-6.259zM121.271 25.273v10.726h-3.181v-10.32c0-2.876-1.726-4.601-4.398-4.601-2.673 0-4.839 1.658-4.839 5.481V36h-3.147V18.844h2.977l.135 3.248c1.116-2.504 3.316-3.586 5.921-3.586 4.096-.001 6.532 2.571 6.532 6.767zM142.476 25.273v10.726h-3.18v-10.32c0-2.876-1.726-4.601-4.399-4.601-2.672 0-4.838 1.658-4.838 5.481V36h-3.147V18.844h2.977l.136 3.248c1.116-2.504 3.315-3.586 5.921-3.586 4.094-.001 6.53 2.571 6.53 6.767zM164.797 28.182h-13.636c.203 3.756 2.165 5.718 5.515 5.718 2.606 0 4.501-1.183 4.974-3.485h3.079c-.744 3.757-3.823 5.921-8.053 5.921-5.447 0-8.797-3.417-8.797-8.898 0-5.346 3.451-8.933 8.628-8.933 5.652 0 8.527 3.722 8.29 9.677zm-13.567-2.233h10.454c-.338-3.384-2.233-5.007-5.109-5.007-3.011 0-4.939 1.691-5.345 5.007zM169.467 27.438c0-5.346 3.688-8.933 8.864-8.933 4.839 0 7.985 2.707 8.426 6.868h-3.181c-.406-2.673-2.3-4.196-5.176-4.196-3.654 0-5.685 2.267-5.685 6.26s2.031 6.259 5.685 6.259c2.977 0 4.905-1.59 5.245-4.501h3.18c-.406 4.467-3.722 7.139-8.493 7.139-5.176.002-8.865-3.584-8.865-8.896zM203 33.427v2.537c-1.658.136-2.876.136-4.635.136-2.91 0-4.365-1.252-4.365-3.417V21.415h-3.417v-2.572h.507c1.997 0 2.91-.677 2.91-2.741V13.87h3.18v4.974h5.719v2.572h-5.719v11.843c0 .135.034.169.17.169H203z" />
      </g>
    </svg>
  )
}
