import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeWebstore,
  WebstoreAttributes,
  WebstoreResource,
} from '@brenntag/connect-apis/api-user/types'

interface Props {
  attributes?: Partial<WebstoreAttributes>
  id?: string
}

export const buildWebstore = ({
  attributes = {},
  id = faker.string.uuid(),
}: Props = {}): WebstoreResource => {
  return {
    attributes: merge(
      {
        country: faker.helpers.arrayElement(['US', 'CA', 'ES', 'PT']),
        createdAt: faker.date.recent().toISOString(),
        isActive: true,
        name: faker.company.name(),
        sourceNetworkId: faker.string.uuid(),
        supportedCurrencies: ['EUR'],
        supportedLanguages: ['en_US'],
        updatedAt: faker.date.recent().toISOString(),
        urlPathPrefix: faker.internet.domainWord(),
      } satisfies WebstoreAttributes,
      attributes,
    ),
    id,
    type: DataTypeWebstore.Webstore,
  }
}
