import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2 12C2 6.49 6.49 2 12 2s10 4.49 10 10-4.49 10-10 10v-2c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8a7.95 7.95 0 0 0 3 6.219V14h2v7H2v-2h2.87C3.1 17.19 2 14.73 2 12"
      fill="currentColor"
    />
    <path d="M14.29 15.71 11 12.41V6h2v5.59l2.71 2.7z" fill="currentColor" />
  </svg>
)
export default SvgHistory
