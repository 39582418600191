import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVolumeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.706 2.291 2.292 3.706l18.003 18.003 1.414-1.415zM7 11.24V14h3.41L15 18.59v2.82L9.59 16H5V9.24zM15 2.59l-4.09 4.08 1.42 1.42.67-.68v1.35l2 2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgVolumeOff
