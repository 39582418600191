import { faker } from '@faker-js/faker'
import { merge, uniqBy } from 'lodash'

import {
  DataTypeLocation,
  DataTypeProduct,
  DataTypeSample,
  DataTypeUser,
  LocationResource,
  SampleAttributes,
  SampleStatus,
  SingleIncludedProductResourceWithPricebookProductUnion,
  SingleIncludedSampleResourceUnion,
} from '@brenntag/connect-apis/api/types'

import { staticTestData } from '../../data/static'

interface Props {
  attributes?: Partial<SampleAttributes>
  id?: string
  included: {
    product: SingleIncludedProductResourceWithPricebookProductUnion
    shipTo: LocationResource
  }
}

export function buildSample({
  attributes,
  id = faker.string.uuid(),
  included,
}: Props): SingleIncludedSampleResourceUnion {
  const product = included.product
  return {
    data: {
      attributes: merge(
        {
          application: faker.science.chemicalElement().name,
          createdAt: faker.date.recent().toISOString(),
          isActive: true,
          number: faker.number.int().toString(),
          requestedDate: faker.date.recent().toISOString(),
          requestQuantity: {
            quantity: faker.number.int(),
            uom: faker.helpers.arrayElement(staticTestData.uom),
          },
          status: faker.helpers.arrayElement([
            SampleStatus.InProcess,
            SampleStatus.New,
            SampleStatus.Requested,
            SampleStatus.Shipped,
          ]),
        } satisfies SampleAttributes,
        attributes,
      ),
      id,
      relationships: {
        product: {
          data: {
            id: product.data.id,
            type: DataTypeProduct.Product,
          },
        },
        shipTo: included.shipTo && {
          data: {
            id: included.shipTo.id,
            type: DataTypeLocation.Location,
          },
        },
        user: {
          data: { id: '123', type: DataTypeUser.User },
        },
      },
      type: DataTypeSample.Sample,
    },
    included: uniqBy(
      [product.data, ...(product.included || [])].filter(
        Boolean,
      ) as SingleIncludedSampleResourceUnion['included'],
      v => v.id,
    ),
  }
}
