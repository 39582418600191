import { faker } from '@faker-js/faker'
import { merge, uniqBy } from 'lodash'

import {
  DataTypeFavorite,
  DataTypeFavoriteItem,
  DataTypeLocation,
  DataTypeUser,
  FavoriteAttributes,
  FavoriteResource,
  SingleIncludedFavoriteResourceFavoriteItemResource,
  SyncStatusEnum,
} from '@brenntag/connect-apis/api/types'

import { buildFavoriteItem } from './buildFavoriteItem'

interface Props {
  attributes?: Partial<FavoriteAttributes>
  id?: string
  relationships?: {
    productIds?: string[]
    shipToId?: string
    userId?: string
  }
}

export const buildFavoriteList = ({
  attributes,
  id = faker.string.uuid(),
  relationships,
}: Props): SingleIncludedFavoriteResourceFavoriteItemResource => {
  const shipToId = relationships?.shipToId ?? faker.string.uuid()
  const userId = relationships?.userId ?? faker.string.uuid()
  const favoriteItems = (relationships?.productIds ?? []).map(productId =>
    buildFavoriteItem({ relationships: { favoriteListId: id, productId } }),
  )

  const favorite: FavoriteResource = {
    attributes: merge(
      {
        createdAt: faker.date.recent().toISOString(),
        isActive: true,
        name: faker.word.words(1),
        syncStatus: SyncStatusEnum.Confirmed,
      },
      attributes,
    ),
    id,
    relationships: {
      favoriteItems: {
        data: favoriteItems.map(favoriteItem => ({
          id: favoriteItem.id,
          type: DataTypeFavoriteItem.FavoriteItem,
        })),
      },
      shipTo: {
        data: {
          id: shipToId,
          type: DataTypeLocation.Location,
        },
      },
      user: {
        data: {
          id: userId,
          type: DataTypeUser.User,
        },
      },
    },
    type: DataTypeFavorite.Favorite,
  }

  return {
    data: favorite,
    included: uniqBy(
      [...favoriteItems].filter(Boolean),
      resource => resource?.id,
    ) as SingleIncludedFavoriteResourceFavoriteItemResource['included'],
  }
}
