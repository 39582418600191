import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFileSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M6 4h5V2H4v20h9v-2H6z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M18 11h2V6.59L15.41 2H13v7h5zm-3-4V4.41L17.59 7zM19.442 18.02l2.27 2.27-1.41 1.41-2.27-2.27c-.6.35-1.28.57-2.02.57-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4c0 .74-.22 1.43-.57 2.02M16.002 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgFileSearch
