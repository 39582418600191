import { http, HttpResponse } from 'msw'

import { config } from '#src/config.js'

import { attachments } from './data'

export const deleteLinkedAttachment = () => {
  return http.delete<{ id: string }, null, string>(
    `${config.apiGatewayUrl}/attachments/:id`,
    async ({ params }) => {
      const attachment = attachments.getAttachment(params.id)

      if (!attachment) {
        throw new HttpResponse('Attachment not found', { status: 404 })
      }

      attachments.remove(params.id)

      return HttpResponse.text(null, { status: 200 })
    },
  )
}
