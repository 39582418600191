/* tslint:disable */
/* eslint-disable */
/**
 * DigiB Core Read API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProductType {
    TradingGoods = 'Trading Goods',
    NonValuatedMaterials = 'Non-Valuated Materials',
    Packaging = 'Packaging',
    Services = 'Services',
    Sample = 'Sample',
    ReturnablePackage = 'Returnable Package',
    Tolling = 'Tolling',
    TollingRawMaterialNonValuated = 'Tolling raw material non-valuated'
}


