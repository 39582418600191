import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFinance = createIcon({
  displayName: 'IconFinance',
  path: [
    <path
      d="M3 7.44982L12 1.81982L21 7.44982V9.99982H13V7.99982H18.11L12 4.17982L5.89 7.99982H11V9.99982H3V7.44982Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M19 12H17V18H19V12Z" fill="currentColor" key="1" />,
    <path d="M21 22V20H3V22H21Z" fill="currentColor" key="2" />,
    <path d="M11 18V12H13V18H11Z" fill="currentColor" key="3" />,
    <path d="M7 12H5V18H7V12Z" fill="currentColor" key="4" />,
  ],
  viewBox: '0 0 24 24',
})
