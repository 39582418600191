import { VasStatus } from '@brenntag/connect-apis/api-vas/types'

import { StatusStyle } from '#shared/components/StatusPill/StatusPill.js'
import { Translate } from '#shared/locale/hooks/useTranslations.js'

import { StatusStyle as StatusStyle2 } from './types.js'

export const getServiceStatusStyles = (t: Translate, status?: VasStatus): StatusStyle => {
  switch (status) {
    case 'Closed':
      return {
        color: 'primary',
        icon: 'IconCircleCheckmark',
        iconName: 'CircleCheckmark',
        statusColor: 'bg-gray-50',
        text: t('label.service.status.Closed'),
      }
    case 'In Progress':
      return {
        color: 'violet.50',
        icon: 'IconReload',
        iconName: 'Reload',
        statusColor: 'bg-violet-50',
        text: t('label.service.status.InProgress'),
      }
    case 'In Review':
      return {
        color: 'blue.70',
        icon: 'IconReload',
        iconName: 'Reload',
        statusColor: 'bg-blue-70',
        text: t('label.service.status.InReview'),
      }
    case 'On-Hold':
      return {
        color: 'red.60',
        icon: 'IconCirclePause',
        iconName: 'CircleRemove',
        statusColor: 'bg-red-60',
        text: t('label.service.status.OnHold'),
      }
    case 'Submitted for Approval':
      return {
        color: 'yellow.40',
        icon: 'IconCircleAlert',
        iconName: 'CircleInfo',
        statusColor: 'bg-yellow-40',
        text: t('label.service.status.SubmittedForApproval'),
      }
    case 'New':
    default:
      return {
        color: 'blue.50',
        icon: 'IconCircleAlert',
        iconName: 'CircleInfo',
        statusColor: 'bg-blue-50',
        text: t('label.service.status.New'),
      }
  }
}

export const getServiceStatusStylesV2 = (t: Translate, status?: VasStatus): StatusStyle2 => {
  switch (status) {
    case 'Closed':
      return {
        icon: 'CircleCheckmark',
        name: 'neutral',
        text: t('label.service.status.Closed'),
        type: VasStatus.Closed,
      }
    case 'In Progress':
      return {
        icon: 'Reload',
        name: 'info',
        text: t('label.service.status.InProgress'),
        type: VasStatus.InProgress,
      }
    case 'In Review':
      return {
        icon: 'Reload',
        name: 'info',
        text: t('label.service.status.InReview'),
        type: VasStatus.InReview,
      }
    case 'On-Hold':
      return {
        icon: 'CirclePause',
        name: 'warning',
        text: t('label.service.status.OnHold'),
        type: VasStatus.OnHold,
      }
    case 'Submitted for Approval':
      return {
        icon: 'CircleAlert',
        name: 'info',
        text: t('label.service.status.SubmittedForApproval'),
        type: VasStatus.SubmittedForApproval,
      }
    case 'New':
    default:
      return {
        icon: 'CircleAlert',
        name: 'info',
        text: t('label.service.status.New'),
        type: VasStatus.New,
      }
  }
}
