import { SingleIncludedFavoriteResourceFavoriteItemResource } from '@brenntag/connect-apis/api/types'
import { buildFavoriteList } from '@brenntag/legacy-mock-generators/generators/favorites/buildFavoriteList.js'

import { manyPricePoints } from '../../products/data/manyPricePoints'

export const list1: SingleIncludedFavoriteResourceFavoriteItemResource = buildFavoriteList({
  attributes: {
    name: 'List 1',
  },
  id: 'list1',
  relationships: {
    productIds: [manyPricePoints.data.id],
  },
})
