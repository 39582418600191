import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEnterprise = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M20 22h-6v-2h4V4H6v16h4v-2h2v4H4V2h16z" fill="currentColor" />
    <path
      d="M11 10H8v2h3zM13 10h3v2h-3zM11 14H8v2h3zM13 14h3v2h-3zM11 6H8v2h3zM13 6h3v2h-3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgEnterprise
