import { APIProductDocument, ManyMeta } from '@brenntag/connect-apis/api'
import { DocumentList, ProductDocument } from '@brenntag/connect-apis/api/models/productDocument'

import { transformAPIDocumentToDocument } from '#shared/apis/api/documents/transforms/document.transforms.js'
import { transformAPIProductToProduct } from '#shared/apis/api/products/transforms/product.transforms.js'
import { generateTotalPages } from '#shared/utils/pagination.js'

const transformAPIDocumentToProductDocument = (
  apiDocument: APIProductDocument,
): ProductDocument => {
  const { product, ...document } = apiDocument
  return {
    ...transformAPIDocumentToDocument(document),
    product: transformAPIProductToProduct(product),
  }
}

export const transformAPIDocumentsToDocumentList = (
  apiDocuments: APIProductDocument[],
  meta: ManyMeta,
  pageSize: number,
): DocumentList => ({
  documents: apiDocuments.map(transformAPIDocumentToProductDocument),
  total: meta?.count || 0,
  totalPages: generateTotalPages(meta?.count, pageSize),
  types: meta?.aggregations?.['types'] ?? [],
})
