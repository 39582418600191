import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTranslate = createIcon({
  displayName: 'IconTranslate',
  path: [
    <path
      d="M17 4V6.01H14.37C13.18 8.33 11.73 10.5 10.05 12.47C10.75 13.09 11.51 13.63 12.34 14.08C12.29 14.24 12.24 14.4 12.2 14.57C12.07 15.06 11.94 15.55 11.81 16.06C10.68 15.5 9.64 14.78 8.7 13.94C7.16 15.53 5.47 16.98 3.63 18.24L2.5 16.59C4.24 15.39 5.85 14.02 7.31 12.5C6.2 11.18 5.32 9.66 4.75 8H6.88C7.32 9.09 7.93 10.1 8.66 11C9.97 9.45 11.12 7.78 12.11 6H1V4H8V2H10V4H17Z"
      fill="currentColor"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M19.0919 9C19.7119 10.96 20.2919 12.99 20.8519 15.06L20.8619 15.07C21.4219 17.15 22.0019 19.46 22.5919 22.01H20.3019C20.2819 21.84 20.2519 21.69 20.2119 21.55C20.1719 21.41 20.1419 21.27 20.1219 21.13C19.9419 20.32 19.7719 19.56 19.6019 18.83H15.4319C15.4052 18.95 15.3785 19.069 15.3519 19.188C15.2585 19.6046 15.1652 20.0211 15.0719 20.48C15.0271 20.6463 14.9905 20.8085 14.9543 20.9692C14.9338 21.0598 14.9135 21.1499 14.8919 21.24C14.8319 21.49 14.7719 21.74 14.7119 22H12.4219C13.0219 19.47 13.5919 17.16 14.1419 15.07C14.6919 12.98 15.2819 10.96 15.9119 9H19.0919ZM16.2919 15.08C16.1319 15.7 15.9719 16.38 15.8119 17.07L15.8219 17.06H19.1919C19.1511 16.8887 19.1111 16.7189 19.0716 16.5514C18.9658 16.1028 18.8638 15.6705 18.7619 15.27C18.3919 13.77 17.9619 12.31 17.5119 10.87C17.0619 12.25 16.6519 13.64 16.2919 15.08Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
