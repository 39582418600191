import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
const inputBaseStyle = {
  '::placeholder': {
    color: 'neutral.60',
  },
  boxShadow: 'inputOutline',
  color: 'primary',
  fontFamily: 'body',
  fontSize: 'body',
  lineHeight: 'body',
  outline: 'transparent',
}

const invalidStyle = {
  bg: 'red.10',
  boxShadow: 'inputOutlineInvalid',
}

const focusStyle = {
  boxShadow: 'inputOutlineFocus',
  outline: 'transparent',
}
const hoverStyle = {
  boxShadow: 'inputOutlineHover',
  outline: 'transparent',
}
const disabledStyle = {
  '::placeholder': {
    color: 'neutral.40',
  },
  _focus: focusStyle,
  bgColor: 'neutral.10',
  boxShadow: 'inputOutlineDisabled',
  cursor: 'not-allowed',
  opacity: 1,
}

const inputSizes = {
  md: {
    field: {
      h: '36px',
    },
  },
}

export const FormLabel = defineStyle({
  baseStyle: {
    fontSize: 'sm',
    fontWeight: 'normal',
  },
})

export const Input = defineStyleConfig({
  baseStyle: {
    field: inputBaseStyle,
  },
  sizes: inputSizes,
  variants: {
    outline: {
      field: {
        _disabled: disabledStyle,
        _focus: focusStyle,
        _hover: hoverStyle,
        _invalid: invalidStyle,
        borderWidth: '0px',
      },
    },
  },
})

export const Select = defineStyleConfig({
  baseStyle: {
    field: inputBaseStyle,
  },
  sizes: inputSizes,
  variants: {
    outline: {
      field: {
        _disabled: disabledStyle,
        _focus: focusStyle,
        _hover: hoverStyle,
        _invalid: invalidStyle,
        borderWidth: '0px',
      },
    },
  },
})

export const Textarea = defineStyleConfig({
  baseStyle: {
    field: inputBaseStyle,
  },
  sizes: inputSizes,
  variants: {
    outline: {
      field: {
        _disabled: disabledStyle,
        _focus: focusStyle,
        _hover: hoverStyle,
        _invalid: invalidStyle,
        borderWidth: '0px',
      },
    },
  },
})
