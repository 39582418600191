import { APILoggedInUser } from '@brenntag/connect-apis/api-user'
import { LoggedInUser } from '@brenntag/connect-apis/api-user/models/user'

export const transformAPILoggedInUserToLoggedInUser = (
  apiLoggedInUser: APILoggedInUser,
): LoggedInUser => {
  const phoneNumbers =
    apiLoggedInUser.phoneNumbers?.map(p => ({
      isPrimary: p.isPrimary,
      number: p.number,
      type: p.type,
    })) || []

  const countryCode = apiLoggedInUser.isInternal ? 'US' : apiLoggedInUser.webstore?.country || 'US'

  return {
    countryCode,
    email: apiLoggedInUser.email,
    firstName: apiLoggedInUser.firstName,
    id: apiLoggedInUser.id,
    isInternal: Boolean(apiLoggedInUser.isInternal),
    languageCode: 'en',
    lastName: apiLoggedInUser.lastName,
    locale: apiLoggedInUser.locale,
    name: apiLoggedInUser.name,
    networkId: apiLoggedInUser.webstore?.sourceNetworkId,
    phoneNumbers,
    primaryPhoneNumber: phoneNumbers.find(p => p.isPrimary),
    role: apiLoggedInUser.role,
    timezone: apiLoggedInUser.timezone,
    username: apiLoggedInUser.username,
  }
}
