interface Config {
  getBaseUrl: () => string
  getRequestAccessApiKey: () => string
  getToken: () => Promise<string> | string
}

export const config: Config = {
  getBaseUrl: () => '',
  getRequestAccessApiKey: () => '',
  getToken: async () => '',
}
