import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconShip = createIcon({
  displayName: 'IconShip',
  path: [
    <path
      d="M18 11H22V13H18V11ZM16 11H12V13H16V11ZM7 16.5C6.448 16.5 6 16.948 6 17.5C6 18.052 6.448 18.5 7 18.5C7.552 18.5 8 18.052 8 17.5C8 16.948 7.552 16.5 7 16.5ZM18 7V9H22V7H18ZM11.145 15H22V16.277L18.565 22H3.107L2 13H3.992V4H5.993V2H7.994V4H9.995V11H7.994V6H5.993V13H9.983L11.145 15ZM19.234 17H9.999L8.838 15H4.27L4.885 20H17.434L19.234 17Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
