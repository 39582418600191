import React, { MouseEventHandler } from 'react'

import { Tag, TagCloseButton, TagLabel, TagProps } from '@chakra-ui/react'

import { allIconsBrand } from '#shared/icons/brand/allIconsBrand.js'
import { IconClose, IconNames } from '#shared/icons/brand/index.js'

export type PillProps = PillExclusiveProps & PillNormalProps
type Alignment = 'left' | 'right'
type PillExclusiveProps =
  | { icon?: IconNames; onClick?: never }
  | { icon?: never; onClick?: MouseEventHandler<Element> }

interface PillNormalProps extends TagProps {
  iconAlign?: Alignment
  iconSize?: string
  isDisabled?: boolean
}

export const Pill: React.FC<PillProps> = ({
  children,
  colorScheme = 'neutral',
  icon,
  iconAlign = 'right',
  iconSize = '12px',
  onClick,
  ...props
}) => {
  const Icon = icon && allIconsBrand[icon]

  return (
    <Tag
      alignItems="center"
      as="div"
      colorScheme={colorScheme}
      flexDirection={iconAlign === 'right' ? 'row' : 'row-reverse'}
      gridGap={1.5}
      justifyContent={onClick ? 'space-between' : 'center'}
      {...props}>
      <TagLabel>{children}</TagLabel>
      {Icon ? (
        <Icon boxSize={iconSize} data-testid="pill-icon" />
      ) : onClick ? (
        <TagCloseButton data-testid="pill-icon" onClick={onClick}>
          <IconClose boxSize="12px" />
        </TagCloseButton>
      ) : null}
    </Tag>
  )
}
