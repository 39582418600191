import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUploadcontained = createIcon({
  displayName: 'IconUploadcontained',
  path: [
    <path d="M20 19H4V21H20V19Z" fill="currentColor" key="0" />,
    <path
      d="M11.9991 1.58984L18.7091 8.28984L17.2891 9.70984L11.9991 4.40984L6.70906 9.70984L5.28906 8.28984L11.9991 1.58984Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M12 7.24023L11 8.24023V17.0002H13V8.24023L12 7.24023Z" fill="currentColor" key="2" />,
    <path d="M18 15V21H20V15H18Z" fill="currentColor" key="3" />,
    <path d="M4 15L4 21H6L6 15H4Z" fill="currentColor" key="4" />,
  ],
  viewBox: '0 0 24 24',
})
