import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSizeMinimise = createIcon({
  displayName: 'IconSizeMinimise',
  path: [
    <path
      d="M13 3.99996H15L15 8.99996L21 8.99996L21 11L13 11L13 3.99996Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M11.42 19.58H9.42002L9.42002 14.58L4.42002 14.58L4.42002 12.58L11.42 12.58L11.42 19.58Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M17.0001 6.99996L18.4101 6.99996L22.42 2.99999L21 1.57999L17.0001 5.58996V6.99996Z"
      fill="currentColor"
      key="2"
    />,
    <path
      d="M7.42004 16.58H6.01004L2.00006 20.58L3.42006 22L7.42004 17.99L7.42004 16.58Z"
      fill="currentColor"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
