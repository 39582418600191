import {
  ManyIncludedOrderResourceUnion,
  ManyMeta,
  SingleIncludedOrderResourceUnion,
} from '@brenntag/connect-apis/api/types'

interface Props {
  meta?: Partial<ManyMeta>
  orders: SingleIncludedOrderResourceUnion[]
}
export const buildOrders = ({ meta, orders }: Props): ManyIncludedOrderResourceUnion => ({
  data: orders.map(order => order.data),
  included: orders.flatMap(order => order.included || []),
  meta: { count: orders.length, ...meta },
})
