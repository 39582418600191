import { isToleratedLocaleFormat } from './isToleratedLocaleFormat/isToleratedLocaleFormat'
import { isTwoCharacters } from './isTwoCharacters/isTwoCharacters'
import { pathToSegments } from './pathToSegments/pathToSegments'

export type Locale = {
  countryCode: string | undefined
  languageCode: string | undefined
}

export const parseLocale = (path: string = ''): Locale => {
  const segments = pathToSegments(path)
  const localeSegment = segments[0]
  const isTolerated = isToleratedLocaleFormat(localeSegment)

  if (!isTolerated) {
    return {
      countryCode: undefined,
      languageCode: undefined,
    }
  }

  if (isTwoCharacters(localeSegment)) {
    return {
      countryCode: localeSegment.toUpperCase(),
      languageCode: undefined,
    }
  }

  const chunks = localeSegment.split(/[-_]/)
  return {
    countryCode: chunks[1].toUpperCase(),
    languageCode: chunks[0].toLowerCase(),
  }
}
