import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSpinner = createIcon({
  displayName: 'IconSpinner',
  path: [
    <path
      d="M12 22C6.49 22 2 17.51 2 12H4C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4V2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
