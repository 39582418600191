import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSquareRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M3 3h18v2H5v14h14V7h2v14H3z" fill="currentColor" />
    <path d="M7 11h10v2H7z" fill="currentColor" />
  </svg>
)
export default SvgSquareRemove
