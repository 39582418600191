import * as React from 'react'

import * as Sentry from '@sentry/react'
import { createBrowserRouter } from 'react-router'
import { RouterProvider as ReactRouterProvider } from 'react-router/dom'

import { routes } from '#shared/routing/routes.js'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)

export const router = sentryCreateBrowserRouter(routes)

export const RouterProvider = () => {
  return <ReactRouterProvider router={router} />
}
