import { Customer } from '@brenntag/api-customer'

import { locations } from './locations'
import { salesGroup, salesGroups } from './salesGroups'

export const customer = {
  basic: {
    allowPromotions: true,
    id: 'basic',
    name: 'Basic Customer',
    number: '123456',
    salesGroupId: salesGroup.generic.id,
    shipTos: [locations.showPrices, locations.hidePrices],
  },
  promotionsOff: {
    allowPromotions: false,
    id: 'promotionsOff',
    name: 'Promotions off Customer',
    number: '123457',
    salesGroupId: salesGroup.anotherSalesgroup.id,
    shipTos: [locations.showPrices, locations.hidePrices],
  },
} satisfies Record<string, Customer>

export const customers = {
  basic: [customer.basic],
  manyCustomers: Array.from({ length: 200 }, (_, i) => {
    const salesGroup = salesGroups.manyCustomers[i % salesGroups.manyCustomers.length]
    const allCustomers = [customer.basic, customer.promotionsOff]
    const thisCustomer = allCustomers[i % allCustomers.length]
    return {
      allowPromotions: true,
      id: `${thisCustomer.id}-${i}`,
      name: `${thisCustomer.name} ${i}`,
      number: `1234${i.toString().padStart(2, '0')}`,
      salesGroupId: salesGroup.id,
      shipTos: thisCustomer.shipTos,
    }
  }),
  promotionsOff: [customer.promotionsOff],
} satisfies Record<string, Customer[]>
