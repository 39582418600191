import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPrint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16 6h2V2H6v4h2V4h8zM22 18h-6v-3H8v3H2V8h9v2H4v6h2v-3h12v3h2v-6h-7V8h9zM17 20H7v2h10z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPrint
