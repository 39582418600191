import React, { FC, ReactElement, ReactNode } from 'react'

import { chakra, Flex, Stack } from '@chakra-ui/react'

import { useBreakpointValue } from '#shared/chakra/hooks/useBreakpointValue.js'
import { TextHighlight } from '#shared/components/Highlight/Highlight.js'
import { StatusPill, StatusStyle } from '#shared/components/StatusPill/StatusPill.js'
import { NoWrap } from '#shared/components/Typography/Typography.js'

interface ResultItemTitleProps {
  label?: string
  search: string
  status?: StatusStyle
  value?: string
}

export const ResultItemTitle: FC<ResultItemTitleProps> = ({ label, search, status, value }) => {
  return (
    <Flex fontSize="16px" fontWeight={600} gridGap={2} wrap="wrap">
      <chakra.span>
        {label && <>{label}:&nbsp;</>}
        {value && <TextHighlight search={search}>{value}</TextHighlight>}
      </chakra.span>
      {status && <StatusPill status={status} />}
    </Flex>
  )
}

interface ResultItemField {
  key: string
  label: string
  value: string | undefined
}

interface ResultItemProps {
  children?: ReactNode
  divider?: boolean
  fields?: {
    items: ResultItemField[]
    search: string
  }
  subtitle?: ReactElement | ReactElement[]
  subtitleHeading?: ReactElement | ReactElement[]
  title: ReactElement | ReactElement[]
}

export const ResultItem: FC<ResultItemProps> = ({
  children,
  divider,
  fields,
  subtitle,
  subtitleHeading,
  title,
}) => {
  const showDivider = useBreakpointValue({ base: false, sm: true })
  return (
    <Stack spacing={1}>
      <Stack
        color="primary"
        direction={{ base: 'column', sm: 'row' }}
        divider={showDivider ? <>{` | `}</> : undefined}
        flexWrap="wrap"
        fontWeight={600}
        spacing={0}
        whiteSpace="pre-wrap">
        {title}
      </Stack>
      {subtitleHeading && (
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          divider={divider || showDivider ? <>{` | `}</> : undefined}
          flexWrap="wrap"
          spacing={0}
          textStyle="text.sm"
          whiteSpace="pre-wrap">
          {subtitleHeading}
        </Stack>
      )}
      {subtitle && (
        <Stack
          color="neutral.80"
          direction={{ base: 'column', sm: 'row' }}
          divider={showDivider ? <>{` | `}</> : undefined}
          flexWrap="wrap"
          spacing={0}
          textStyle="text.sm"
          whiteSpace="pre-wrap">
          {subtitle}
        </Stack>
      )}
      {fields && (
        <Stack
          color="neutral.80"
          direction={{ base: 'column', sm: 'row' }}
          divider={showDivider ? <>{` | `}</> : undefined}
          flexWrap="wrap"
          spacing={0}
          textStyle="text.sm"
          whiteSpace="pre-wrap">
          {fields.items
            .filter(({ value }) => Boolean(value))
            .map(({ key, label, value }) => (
              <React.Fragment key={key}>
                <NoWrap isTruncated>
                  {label}
                  :&nbsp;
                  <chakra.span fontWeight={600}>
                    <TextHighlight search={fields.search}>{value || ''}</TextHighlight>
                  </chakra.span>
                </NoWrap>
              </React.Fragment>
            ))}
        </Stack>
      )}
      {children}
    </Stack>
  )
}
