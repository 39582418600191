import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUserAdd = createIcon({
  displayName: 'IconUserAdd',
  path: [
    <path d="M20 2H18V4H16V6H18V8H20V6H22V4H20V2Z" fill="currentColor" key="0" />,
    <path d="M2 12V21H18V12H14V14H16V19H4V14H6V12H2Z" fill="currentColor" key="1" />,
    <path
      d="M12.5 7.5C12.5 7.18 12.43 6.87 12.32 6.59L13.8 5.11C14.24 5.81 14.5 6.62 14.5 7.5C14.5 9.98 12.48 12 10 12C7.52 12 5.5 9.98 5.5 7.5C5.5 5.02 7.52 3 10 3C10.88 3 11.7 3.26 12.39 3.7L10.91 5.18C10.63 5.07 10.32 5 10 5C8.62 5 7.5 6.12 7.5 7.5C7.5 8.88 8.62 10 10 10C11.38 10 12.5 8.88 12.5 7.5Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
