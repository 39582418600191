import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconParcelScan = createIcon({
  displayName: 'IconParcelScan',
  path: [
    <path
      d="M13.0002 2.2802L12.0002 1.9502L4.66016 4.3902L7.82016 5.4502L12.0002 4.0502L13.0002 4.3902V2.2802Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M15 2H16V8H15V2Z" fill="currentColor" key="1" />,
    <path d="M18 2H17V8H18V2Z" fill="currentColor" key="2" />,
    <path d="M19 2H20V8H19V2Z" fill="currentColor" key="3" />,
    <path d="M21 2H22V8H21V2Z" fill="currentColor" key="4" />,
    <path
      clipRule="evenodd"
      d="M20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.94986L14 8.27986V10.3899L13 10.7199V19.6099L20 17.2799ZM4 17.2799L11 19.6099V10.7199L4 8.38986V17.2799Z"
      fill="currentColor"
      fillRule="evenodd"
      key="5"
    />,
  ],
  viewBox: '0 0 24 24',
})
