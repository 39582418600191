import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconNight = createIcon({
  displayName: 'IconNight',
  path: [
    <path
      d="M20.39 11.95C19.39 12.64 18.21 13 17 13C13.69 13 11 10.31 11 7C11 5.78 11.36 4.61 12.05 3.61L13.25 1.87L11.15 2.05C6.02 2.48 2 6.85 2 12C2 14.4 2.85 16.6 4.26 18.32L5.69 16.89C4.64 15.53 4 13.84 4 11.99C4 8.53 6.27 5.51 9.42 4.42C9.14 5.24 9 6.1 9 6.99C9 11.4 12.59 14.99 17 14.99C17.88 14.99 18.75 14.85 19.57 14.57C18.49 17.73 15.46 19.99 12 19.99C10.15 19.99 8.46 19.36 7.1 18.3L5.67 19.73C7.39 21.14 9.6 21.99 11.99 21.99C17.14 21.99 21.51 17.97 21.95 12.84L22.13 10.74L20.39 11.94V11.95Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
