import { http, HttpResponse } from 'msw'

import {
  DataTypeProduct,
  ManyIncludedSampleResourceUnion,
  SingleIncludedSampleResourceUnion,
} from '@brenntag/connect-apis/api/types'
import { buildSamples } from '@brenntag/legacy-mock-generators/generators/samples/buildSamples.js'

import {
  sampleFilters,
  SampleListSortKey,
} from '#shared/apis/api/samples/endpoints/fetchSamples.js'
import { config } from '#src/config.js'

import { samples } from './data'

export const fetchSamples = () => {
  return http.get<never, null, ManyIncludedSampleResourceUnion>(
    `${config.apiBaseUrl}/api/samples`,
    async ({ request }) => {
      let list = samples.list()

      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const status = searchParams.get(sampleFilters.status)
      if (status) {
        list = list.filter(sample => sample.data.attributes.status === status)
      }

      const shipToId = searchParams.get(sampleFilters.shipToLocationId)
      if (shipToId) {
        list = list.filter(sample => sample.data.relationships.shipTo?.data.id === shipToId)
      }

      const searchQuery = searchParams.get(sampleFilters.searchQuery)
      if (searchQuery) {
        list = list.filter(sample =>
          sample.data.attributes.number.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      }

      const sort = searchParams.get('sort') as SampleListSortKey
      list = sortSamples(list, sort)

      return HttpResponse.json(buildSamples({ samples: list }))
    },
  )
}

const sortSamples = (
  productsList: SingleIncludedSampleResourceUnion[],
  sort: SampleListSortKey,
): SingleIncludedSampleResourceUnion[] => {
  const isDescending = sort.startsWith('-')
  switch (sort) {
    case '-number':
    case 'number':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.number || ''
        const bVal = b.data.attributes.number || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-productName':
    case 'productName':
      return productsList.sort((a, b) => {
        const aVal =
          a.included?.find(include => include.type === DataTypeProduct.Product)?.attributes?.name ||
          ''
        const bVal =
          b.included?.find(include => include.type === DataTypeProduct.Product)?.attributes?.name ||
          ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-requestedDate':
    case 'requestedDate':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.requestedDate || ''
        const bVal = b.data.attributes.requestedDate || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    default:
      return productsList
  }
}
