import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconThumbsUp = createIcon({
  displayName: 'IconThumbsUp',
  path: [
    <path
      d="M6 20H19.41L22 17.41V7H14V3.59L12.41 2H8V6.7L6 9.7V16H8V10.3L10 7.3V4H11.59L12 4.41V9H20V16.59L18.59 18H6V20Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M2 10H4V20H2V10Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
