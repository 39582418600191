import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUpload = createIcon({
  displayName: 'IconUpload',
  path: [
    <path
      d="M11.9991 1.58984L18.7091 8.28984L17.2891 9.70984L11.9991 4.40984L6.70906 9.70984L5.28906 8.28984L11.9991 1.58984Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M20 21V19H4V21H20Z" fill="currentColor" key="1" />,
    <path d="M11 8.24023L12 7.24023L13 8.24023V17.0002H11V8.24023Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
