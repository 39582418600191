import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOrderUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M11.71 4.41 15 7.71l1.42-1.42-4.71-4.7L7 6.29l1.42 1.42z" fill="currentColor" />
    <path d="m10.71 8.24 1-1 1 1V21h-2z" fill="currentColor" />
  </svg>
)
export default SvgOrderUp
