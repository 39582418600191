import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCartAdd = createIcon({
  displayName: 'IconCartAdd',
  path: [
    <path d="M2 4V2H6L7 15H19L19.5 11H21.5L21 17H5L4 4H2Z" fill="currentColor" key="0" />,
    <path d="M8 6H14V8H8V6Z" fill="currentColor" key="1" />,
    <path
      d="M10 20C10 20.5523 9.55228 21 9 21C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19C9.55228 19 10 19.4477 10 20Z"
      fill="currentColor"
      key="2"
    />,
    <path
      d="M18 20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20C16 19.4477 16.4477 19 17 19C17.5523 19 18 19.4477 18 20Z"
      fill="currentColor"
      key="3"
    />,
    <path d="M20 5V3H18V5H16V7H18V9H20V7H22V5H20Z" fill="currentColor" key="4" />,
  ],
  viewBox: '0 0 24 24',
})
