import consola from 'consola/browser'
import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'
import { PricePoint } from '@brenntag/connect-apis/api/models/price'
import { DataTypePricePoint } from '@brenntag/connect-apis/api/types'

import { PatchCartItemParams } from '#shared/apis/api-cart/cart/endpoints/patchCartItem.js'
import { getSortedPricePoints, getTotalPrice } from '#shared/utils/price-utils.js'
import { config } from '#src/config.js'

import { products } from '../api/products/data'
import { carts } from './data'

export const patchCartItem = () => {
  return http.patch<{ cartItemId: string }, PatchCartItemParams, APICart>(
    `${config.apiBaseUrl}/api-cart/carts/cart-items/:cartItemId`,
    async ({ params, request }) => {
      const body = await request.json()
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      if (!shipToId) {
        consola.log('shipToId not found')
        return HttpResponse.json(null, { status: 400 })
      }

      if (!carts[shipToId]) {
        consola.log('cart not found')
        return HttpResponse.json(null, { status: 404 })
      }
      const cartItem = carts[shipToId].items?.find(item => item.id === params.cartItemId)
      const cartItemIndex = carts[shipToId].items?.findIndex(item => item.id === params.cartItemId)

      if (!cartItem || cartItemIndex === -1 || cartItemIndex === undefined) {
        consola.log('cartItem not found')
        return HttpResponse.json(null, { status: 404 })
      }

      const product = products.list().find(product => product.data.id === cartItem.productId)
      if (!product) {
        consola.log('product not found')
        return HttpResponse.json(null, { status: 404 })
      }

      const pricePoints: PricePoint[] = (product.included ?? [])
        .filter(include => include.type === DataTypePricePoint.PricePoint)
        .map(pricePoint => ({
          isActive: pricePoint.attributes.isActive,
          price: pricePoint.attributes.price,
          scaleStart: pricePoint.attributes.scaleStart,
        }))

      if (!pricePoints.length) {
        consola.log('pricePoints not found')
        return HttpResponse.json(null, { status: 404 })
      }

      const sortedPricepoints = getSortedPricePoints(pricePoints)
      cartItem.tankLocations = (body.tankLocations ?? []).map(tankLocation => ({
        id: tankLocation.id,
        quantity: parseFloat(tankLocation.quantity),
      }))
      cartItem.quantity = body.quantity
      cartItem.totalPrice = getTotalPrice(body.quantity, sortedPricepoints)?.amount || 0

      carts[shipToId].items![cartItemIndex] = cartItem

      return HttpResponse.json(carts[shipToId], { status: 201 })
    },
  )
}
