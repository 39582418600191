import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.06 11c.5-4.49 4.32-8 8.94-8 1.83 0 3.55.55 5 1.51V6h-1.42C14.52 5.37 13.3 5 12 5c-3.52 0-6.43 2.61-6.92 6z"
      fill="currentColor"
    />
    <path d="M15 10h6V4h-2v4h-4zM3 20h2v-4h4v-2H3z" fill="currentColor" />
    <path
      d="M18.92 13c-.49 3.39-3.4 6-6.92 6-1.3 0-2.52-.37-3.58-1H7v1.49A9.03 9.03 0 0 0 12 21c4.62 0 8.44-3.51 8.94-8z"
      fill="currentColor"
    />
  </svg>
)
export default SvgChange
