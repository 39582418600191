import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHandover = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M22 9h-2V5.416l-9.293 9.293-1.414-1.415L18.587 4H15V2h7z" fill="currentColor" />
    <path
      d="m18 12.41-1.09-1.08 1.42-1.42L20 11.59V22H2V11.59l9-9 3.09 3.08-1.42 1.42L11 5.41l-7 7V20h14z"
      fill="currentColor"
    />
  </svg>
)
export default SvgHandover
