import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLicensePlate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M23 19H1V5h22v2H3v10h18V9h2z" fill="currentColor" />
    <path
      d="M7 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0M19 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0M9 11h6v2H9z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLicensePlate
