import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconArchive = createIcon({
  displayName: 'IconArchive',
  path: [
    <path d="M13 5V3H22V9H2V3H11V5H4V7H20V5H13Z" fill="currentColor" key="0" />,
    <path d="M18 20H6V11H4V22H20V11H18V20Z" fill="currentColor" key="1" />,
    <path d="M15 12H9V14H15V12Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
