import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFactory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5 17H3V3h2zm5-2H8v2h2zm8 0h-2v2h2zm-4 0h-2v2h2zm5.586-8L16 10.586V7h-2.414L10 10.586V3H8v10h2.414L14 9.414V13h2.414L20 9.414V19H3v2h19V7z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFactory
