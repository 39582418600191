import { theme as chakraTheme, StyleFunctionProps } from '@chakra-ui/react'

const { baseStyle } = chakraTheme.components.Checkbox

const disabled = {
  borderColor: 'neutral.30',
  color: 'white',
  cursor: 'not-allowed',
}
const disabledStyle = {
  ...disabled,
  background: 'white',
}
const disabledCheckedStyle = {
  ...disabled,
  background: 'neutral.30',
}

export const Checkbox = {
  baseStyle: (props: StyleFunctionProps) => ({
    control: {
      ...(baseStyle && baseStyle(props).control),
      _checked: {
        _disabled: {
          ...disabledCheckedStyle,
          _active: disabledCheckedStyle,
        },
      },
      _disabled: {
        ...disabledStyle,
        _active: disabledStyle,
      },
      bg: 'white',
      border: '2px solid',
      borderRadius: 0,
      color: 'white',
    },
    label: {
      ...(baseStyle && baseStyle(props).label),
      _disabled: {
        color: 'neutral.50',
        cursor: 'not-allowed',
      },
      color: 'primary',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
  defaultProps: {
    colorScheme: '',
    size: 'lg',
    variant: 'gradient',
  },
  sizes: {
    lg: { control: { height: 6, width: 6 } },
    md: { control: { height: 5, width: 5 } },
    sm: { control: { height: 4, width: 4 } },
  },
  variants: {
    gradient: () => ({
      control: {
        _active: {
          background: 'neutral.20',
          borderColor: 'neutral.90',
        },
        _checked: {
          _active: {
            background: 'blue.60',
            borderColor: 'blue.60',
          },
          _focus: {
            backgroundOrigin: 'border-box',
            bgGradient: `linear(to-r, violet.50, blue.50)`,
            borderColor: 'transparent',
            boxShadow: 'outline',
          },
          _hover: {
            background: 'blue.50',
            borderColor: 'blue.50',
          },
          backgroundOrigin: 'border-box',
          bgGradient: `linear(to-r, violet.50, blue.50)`,
          borderColor: 'transparent',
        },
        _focus: {
          background: `neutral.30`,
          boxShadow: 'outline',
        },
        _hover: {
          background: 'neutral.10',
          borderColor: 'neutral.80',
        },
        borderColor: 'neutral.70',
      },
    }),
    solid: ({ colorScheme }: { colorScheme: string }) => ({
      control: {
        _active: {
          background: colorScheme ? `${colorScheme}.20` : 'neutral.20',
          borderColor: colorScheme ? `${colorScheme}.90` : 'neutral.90',
        },
        _checked: {
          _active: {
            background: colorScheme ? `${colorScheme}.90` : 'primary',
            borderColor: colorScheme ? `${colorScheme}.90` : 'primary',
          },
          _focus: {
            background: colorScheme ? `${colorScheme}.90` : 'primary',
            borderColor: colorScheme ? `${colorScheme}.90` : 'primary',
            boxShadow: 'outline',
          },
          _hover: {
            background: colorScheme ? `${colorScheme}.80` : 'neutral.80',
            borderColor: colorScheme ? `${colorScheme}.80` : 'neutral.80',
          },
          background: colorScheme ? `${colorScheme}.90` : 'primary',
          borderColor: colorScheme ? `${colorScheme}.90` : 'primary',
        },
        _focus: {
          background: colorScheme ? `${colorScheme}.30` : 'neutral.30',
          borderColor: colorScheme ? `${colorScheme}.30` : 'neutral.30',
          boxShadow: 'outline',
        },
        _hover: {
          background: colorScheme ? `${colorScheme}.10` : 'neutral.10',
          borderColor: colorScheme ? `${colorScheme}.80` : 'neutral.80',
        },
        borderColor: 'neutral.70',
      },
    }),
  },
}
