import { http, HttpResponse } from 'msw'

import { APICheckout } from '@brenntag/connect-apis/api-cart'
import { OrderStatus } from '@brenntag/connect-apis/api/types'
import { buildOrder } from '@brenntag/legacy-mock-generators/generators/orders/buildOrder.js'

import { config } from '#src/config.js'

import { orders } from '../api/orders/data'
import { products } from '../api/products/data'
import { carts, checkouts } from './data'

export const completeCheckout = () => {
  return http.post<{ orderId: string }, never, APICheckout>(
    `${config.apiBaseUrl}/api-cart/orders/:orderId/confirm`,
    async ({ params }) => {
      const checkout = checkouts[params.orderId]
      const cart = carts[checkout.cartId]
      if (!checkout || !cart) {
        return HttpResponse.json(null, { status: 404 })
      }
      orders.items[params.orderId as keyof typeof orders.items] = buildOrder({
        attributes: {
          billToAddress: checkout.billingAddress,
          createdAt: checkout.orderDate,
          purchaseOrderNumber: checkout.poNumber,
          salesOrderNumber: checkout.salesOrderNumber,
          shipToAddress: checkout.shippingAddress,
          status: OrderStatus.Entered,
        },
        id: params.orderId,
        included: {
          products: products
            .list()
            .filter(product => cart.items?.some(item => item.productId === product.data.id)),
        },
      })
      return HttpResponse.json(checkout, { status: 200 })
    },
  )
}
