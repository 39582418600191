import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconRocket = createIcon({
  displayName: 'IconRocket',
  path: [
    <path
      d="M9.59 8L15.59 2H22V8.41L16 14.41V20.62L11.24 23H9V19.24L11 17.24V20.88L14 19.38V13.59L20 7.59V4H16.41L10.41 10H4.62L3.12 13H6.76L4.76 15H1V12.76L3.38 8H9.59Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M4.29688 18.2881L10.3002 12.2847L11.7144 13.699L5.71109 19.7023L4.29688 18.2881Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
