import { buildPricebook } from '@brenntag/legacy-mock-generators/generators/products/buildPricebook.js'

import { locations } from '../customer-api/data/locations'

const allLocations = buildPricebook({
  relationships: { locationIds: [locations.showPrices.id, locations.hidePrices.id] },
})

const onlyShowPricesLocation = buildPricebook({
  relationships: { locationIds: [locations.showPrices.id] },
})

const onlyHidePricesLocation = buildPricebook({
  relationships: { locationIds: [locations.hidePrices.id] },
})

export const pricebooks = {
  allLocations,
  onlyHidePricesLocation,
  onlyShowPricesLocation,
}
