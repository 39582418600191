import qs from 'query-string'

import { salesforceSession } from '#lib/auth/salesforce/salesforceSession.js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isResponse = (response: any): response is Response => Boolean(response?.json)

interface RequestErrorOptions {
  body?: unknown
  context?: string
  options?: RequestOptions
}

class RequestError extends Error {
  public body?: unknown
  public context?: string
  public isRequestError = true
  public options?: RequestOptions
  public status: number = -1
  public statusText?: string

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    responseOrError: any | Response,
    options?: RequestErrorOptions,
    status?: number,
    statusText?: string,
  ) {
    super(
      isResponse(responseOrError)
        ? `${responseOrError.status || status || -1}: ${responseOrError.statusText || statusText}`
        : responseOrError?.message || responseOrError,
      {
        cause: responseOrError,
      },
    )
    if (isResponse(responseOrError)) {
      this.status = responseOrError.status || status || -1
      this.statusText = responseOrError.statusText || statusText
    } else {
      this.status = -1
    }
    this.context = options?.context
    this.body = options?.body
    this.options = options?.options
    this.name = this.name || this.constructor.name || 'RequestError'
  }

  toJSON() {
    return {
      context: this.context,
      error: {
        message: this.message,
        stack: this.stack,
      },
      isRequestError: this.isRequestError,
      request: {
        ...this.options,
      },
      response: {
        body: this.body,
        status: this.status,
        statusText: this.statusText,
      },
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRequestError = (error: any): error is RequestError => Boolean(error?.isRequestError)

class MethodNotAllowedRequestError extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(responseOrError, options, 405, 'method not allowed')
    this.name = 'MethodNotAllowedRequestError'
  }
}

export class BadRequestError extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(responseOrError, options, 400, 'bad request')
    this.name = 'BadRequestError'
  }
}

export class ForbiddenRequestError extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(responseOrError, options, 403, 'forbidden')
    this.name = 'ForbiddenRequestError'
  }
}

export class InternalServerErrorRequest extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(responseOrError, options, 500, 'internal server error')
    this.name = 'InternalServerErrorRequest'
  }
}

export class NotFoundRequestError extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(responseOrError, options, 404, 'not found')
    this.name = 'NotFoundRequestError'
  }
}

export class TimeoutRequestError extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(
      responseOrError,
      options,
      -1,
      `timeout occured after ${options?.options?.timeout || 'unknown'}ms`,
    )
    this.name = 'TimeoutRequestError'
  }
}

export class UnauthorizedRequestError extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(responseOrError, options, 401, 'unauthorized')
    this.name = 'UnauthorizedRequestError'
  }
}

export class UnprocessableContentRequestError extends RequestError {
  constructor(responseOrError: Response | unknown, options?: RequestErrorOptions) {
    super(responseOrError, options, 422, 'unprocessable content')
    this.name = 'UnprocessableContentRequestError'
  }
}

const createRequestError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseOrError: any | Response,
  options?: RequestErrorOptions,
  flags?: { isTimeout?: boolean },
) => {
  if (isResponse(responseOrError)) {
    switch (responseOrError.status) {
      case 400:
        return new BadRequestError(responseOrError, options)
      case 401:
        return new UnauthorizedRequestError(responseOrError, options)
      case 403:
        return new ForbiddenRequestError(responseOrError, options)
      case 404:
        return new NotFoundRequestError(responseOrError, options)
      case 405:
        return new MethodNotAllowedRequestError(responseOrError, options)
      case 422:
        return new UnprocessableContentRequestError(responseOrError, options)
      case 500:
        return new InternalServerErrorRequest(responseOrError, options)
      default:
        return new RequestError(responseOrError, options)
    }
  } else if (isRequestError(responseOrError)) {
    return !options ? responseOrError : new RequestError(responseOrError, options)
  } else {
    if (flags?.isTimeout) {
      throw new TimeoutRequestError(
        new Error(`timeout occurred after ${options?.options?.timeout || 'unknown'}ms`),
        options,
      )
    }
    return new RequestError(responseOrError, options)
  }
}

export type RequestHeaders = Record<string, null | string | undefined>
export interface RequestResponse<T> {
  body: T
  status?: number
}

type AfterRequestHandler<T> = (args: {
  body: T | undefined
  error?: RequestError
  response?: Response
}) => void

interface BaseRequestOptions {
  headers?: RequestHeaders
  method?: 'DELETE' | 'GET' | 'HEAD' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>
  timeout?: number
  url: string
}

type BeforeRequestHandler = (options?: RequestOptions) => RequestOptions | void

interface Request {
  <T>(options: RequestOptions): Promise<RequestResponse<T>>
  _afterHandlers: AfterRequestHandler<unknown>[]
  _beforeHandlers: BeforeRequestHandler[]
  after<T = unknown>(handler: AfterRequestHandler<T>): void
  before(handler: BeforeRequestHandler): void
  headers: Omit<RequestHeaders, 'Content-Type'>
}

type RequestHeadersWithBody = RequestHeaders & { 'Content-Type': string }

type RequestOptions =
  | RequestOptionsWithBody
  | RequestOptionsWithFormData
  | RequestOptionsWithoutBody

interface RequestOptionsWithBody extends BaseRequestOptions {
  body: unknown
  headers: RequestHeadersWithBody
}

interface RequestOptionsWithFormData extends BaseRequestOptions {
  body: FormData
  headers: RequestHeaders
}

interface RequestOptionsWithoutBody extends BaseRequestOptions {
  body?: never
  headers?: RequestHeaders
}

const isJsonBody = (options: RequestOptions): boolean =>
  Boolean(
    options.headers?.['content-type']?.match(/json/i) ||
      (options.body && typeof options.body === 'object'),
  )

const isFormData = (body: unknown): body is FormData => body instanceof FormData

const createBody = (options: RequestOptions): FormData | string | undefined => {
  const { body, method, url } = options
  if (!body || method === 'GET' || method === 'HEAD') return undefined
  if (typeof body === 'string') return body
  if (isFormData(body)) return body
  if (isJsonBody(options)) return JSON.stringify(options.body)
  throw new Error(`request: Unknown body type for request ${url}. Unable to send request.`)
}

const createHeaders = (...headers: (RequestHeaders | undefined)[]): Record<string, string> => {
  const allHeaders: Record<string, string> = {}

  for (const header of headers) {
    if (!header) continue

    for (const key of Object.keys(header)) {
      if (header[key]) allHeaders[key] = header[key] as string
    }
  }

  return allHeaders
}

export const request: Request = async <T>(options: RequestOptions): Promise<RequestResponse<T>> => {
  let response: Response | undefined
  let body: T | undefined
  let error: RequestError | undefined
  let isTimeout = false

  try {
    options = request._beforeHandlers.reduce(
      (options, handler) => handler(options) || options,
      options,
    )

    options.timeout = options.timeout || 1000 * 60 * 2

    const url = qs.stringifyUrl({ query: options.params, url: options.url })

    const controller = new AbortController()
    const id = options.timeout
      ? setTimeout(() => {
          isTimeout = true
          controller.abort()
        }, options.timeout)
      : -1

    response = await fetch(url, {
      body: createBody(options),
      credentials: 'include', // 'include' sends cookies and HTTP authentication to the server
      headers: createHeaders(options.headers, request.headers),
      method: options.method || 'GET',
      signal: controller.signal,
    })

    clearTimeout(id)

    const contentType = response.headers.get('content-type')

    if (contentType?.includes('json')) {
      body = (await response.json()) as T
    } else {
      body = (await response.text()) as T
    }

    if (!response.ok) throw response

    return { body, status: response.status }
  } catch (err) {
    error = createRequestError(err, { body, options }, { isTimeout })
    throw error
  } finally {
    request._afterHandlers.forEach(handler => handler({ body, error, response }))
  }
}

// These request headers are only used for Salesforce calls so we put US hardcoded here
request.headers = {
  'x-country': 'US',
}

request._afterHandlers = []
request._beforeHandlers = []

request.after = <T = unknown>(handler: AfterRequestHandler<T>): void => {
  request._afterHandlers.push(handler as AfterRequestHandler<unknown>)
}

request.before = (handler: BeforeRequestHandler): void => {
  request._beforeHandlers.push(handler)
}

// Update request headers with latest tokens before request
// Reset Salesforce session if tokens are missing
// TODO: fix token expiration and session management for this case (auth0 also need to be logged out?, if not, how do we recover?)
request.before(() => {
  const { accessToken, idToken } = salesforceSession.getTokens()
  if (accessToken && idToken) {
    request.headers['Authorization'] = `Bearer ${idToken}`
    request.headers['X-Salesforce-Access-Token'] = accessToken
  } else {
    salesforceSession.reset()
    delete request.headers['Authorization']
    delete request.headers['X-Salesforce-Access-Token']
  }
})
