import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFile = createIcon({
  displayName: 'IconFile',
  path: [
    <path
      d="M20 22H4V2H11V4H6V20H18V9H13V2H15.41L20 6.59V22ZM15 7H17.59L15 4.41V7Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
