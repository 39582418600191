import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDesktop = createIcon({
  displayName: 'IconDesktop',
  path: [
    <path d="M2 18H22V8H20V16H4V6H22V4H2V18Z" fill="currentColor" key="0" />,
    <path d="M7 20H17V22H7V20Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
