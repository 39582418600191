import React from 'react'

import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'

import { theme as digibTheme } from '#shared/chakra/theme/index.js'

const overrideTheme = {
  styles: {
    global: () => ({
      'html, body': {
        color: 'primary',
        fontFeatureSettings: '"tnum" 1, "lnum" 1',
        height: '100%',
      },
    }),
  },
}

const theme = extendTheme(overrideTheme, digibTheme)

// This is nonce is needed for CSP style tag that is being injected by Chakra UI
// see: https://github.com/chakra-ui/chakra-ui/pull/8523
const emotionCache = createCache({
  key: 'css',
  nonce: 'sha256-GNF74DLkXb0fH3ILHgILFjk1ozCF3SNXQ5mQb7WLu/Y=',
})

interface Props {
  children?: React.ReactNode
}

export const ChakraThemeProvider: React.FC<Props> = ({ children }) => (
  <CacheProvider value={emotionCache}>
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: { position: 'bottom-left' },
      }}>
      {children}
    </ChakraProvider>
  </CacheProvider>
)
