import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDialogue = createIcon({
  displayName: 'IconDialogue',
  path: [
    <path d="M2 2H18V4H4V15H2V2Z" fill="currentColor" key="0" />,
    <path
      d="M22 6V8H8V17H11.41L14 19.59L16.59 17H20V10H22V19H17.41L14 22.41L10.59 19H6V6H22Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
