import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconBarGraph = createIcon({
  displayName: 'IconBarGraph',
  path: [
    <path d="M21 3H19V21H21V3Z" fill="currentColor" key="0" />,
    <path d="M13 11H11V21H13V11Z" fill="currentColor" key="1" />,
    <path d="M5 19H3V21H5V19Z" fill="currentColor" key="2" />,
    <path d="M7 15H9V21H7V15Z" fill="currentColor" key="3" />,
    <path d="M15 7H17V21H15V7Z" fill="currentColor" key="4" />,
  ],
  viewBox: '0 0 24 24',
})
