import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M10 9H8v8h2zM14 7h2v8h-2z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="m22 22.62-7.08-3.54-6 2L2 17.62V1.38l7.08 3.54 6-2L22 6.38zm-6.92-5.7L20 19.38V7.62l-5.08-2.54-6 2L4 4.62v11.76l5.08 2.54z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgMap
