export const trimLeadingZeros = (s: string | undefined) => s?.replace(/^0+/, '')

export const trimToLengthWithEllipsis = (s: string | undefined, maxLength = 20) =>
  (s?.length || 0) < maxLength ? s : `${s!.substring(0, maxLength)}...`

export const isStringBoolean = (s: string | undefined) => s === 'true' || s === 'false'

export const getUnixTimestamp = () => {
  return new Date().getTime()
}

export const refreshPage = () => {
  window.location.reload()
}
