import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M21 6H3v2h18zM21 11H3v2h18zM3 16h18v2H3z" fill="currentColor" />
  </svg>
)
export default SvgMenu
