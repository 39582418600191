import { FC, useState } from 'react'

import { FormInput } from '@brenntag/ui/components/FormInput'
import { FormItem } from '@brenntag/ui/components/FormItem'
import { cn } from '@brenntag/ui/utils/cn'

import { FeatureFlags, useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'

import { ClearLocalFlagButton } from './ClearLocalFlagButton'

type Props = {
  flag: keyof FeatureFlags
  value: string
}

export const StringFeatureFlag: FC<Props> = ({ flag, value }) => {
  const { isLocalFeatureFlag, setLocalFeatureFlag } = useFeatureFlags()
  const [changedValue, setChangedValue] = useState<string | undefined>(undefined)

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-x-4 py-3',
        isLocalFeatureFlag(flag) && 'text-blue-50',
      )}
      key={flag}>
      <div className="flex-1">{flag}</div>
      <FormItem>
        <FormInput
          name={flag}
          onBlur={() => setLocalFeatureFlag(flag, (changedValue || '') as unknown as boolean)}
          onChange={e => setChangedValue(e.target.value)}
          scale="sm"
          value={changedValue ?? value}
        />
      </FormItem>
      {isLocalFeatureFlag(flag) && <ClearLocalFlagButton flag={flag} />}
    </div>
  )
}
