import { useCallback, useMemo } from 'react'

import { atom, PrimitiveAtom, useAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'

import { DateSelectionChange } from '#shared/components/Dropdown/types.js'
import { cartFilters } from '#shared/filters/cart.filters.js'
import { caseFilters } from '#shared/filters/cases.filters.js'
import { documentFilters } from '#shared/filters/documents.filters.js'
import { favoriteFilters } from '#shared/filters/favorites.filters.js'
import { FilterEndpoint, Filters, PageFilterTypes, validFilters } from '#shared/filters/filters.js'
import { invoiceFilters } from '#shared/filters/invoices.filters.js'
import { orderFilters } from '#shared/filters/orders.filters.js'
import { productFilters } from '#shared/filters/products.filters.js'
import { quoteFilters } from '#shared/filters/quotes.filters.js'
import { sampleFilters } from '#shared/filters/samples.filters.js'
import { serviceFilters } from '#shared/filters/services.filters.js'
import { useSearchQuery } from '#shared/hooks/search/useSearchQuery.js'
import { filterEmptyValues } from '#shared/utils/object-utils.js'

const defaultAtom = atom({})

const filterAtoms: Record<FilterEndpoint, PrimitiveAtom<Filters>> = {
  cart: cartFilters,
  cases: caseFilters,
  documents: documentFilters,
  favorites: favoriteFilters,
  invoices: invoiceFilters,
  orders: orderFilters,
  products: productFilters,
  quotes: quoteFilters,
  samples: sampleFilters,
  services: serviceFilters,
}

const removeInvalidFilters = <F extends FilterEndpoint, T>(endpoint: F, filters: T) => {
  if (!filters) return filters
  const validFilterKeys = Object.keys<Record<string, string>>(validFilters[endpoint]).concat('page')
  return Object.keys(filters)
    .filter(key => validFilterKeys.includes(key as string))
    .reduce((acc, key) => ({ ...acc, [key]: filters[key] }) as Partial<T>, {} as Partial<T>)
}

interface UseFiltersOptions<F extends FilterEndpoint> {
  initialValues?: PageFilterTypes[F]
}

interface UseFiltersResponse<F extends FilterEndpoint> {
  filters: PageFilterTypes[F]
  getActiveFilterCount(omitFilters?: string[]): number
  pageNumber: number
  sort: string | undefined
  updateDateFilterChange(
    filterStartName: keyof PageFilterTypes[F],
    filterEndName: keyof PageFilterTypes[F],
    dateChange: DateSelectionChange,
  ): void
  updateFilters(newFilters: PageFilterTypes[F]): void
  updateOneFilter(filterName: keyof PageFilterTypes[F], value: string | undefined): void
  updatePageNumber(pageNumber: string): void
  updateSort(sort: string): void
}

export const useFilters = <F extends FilterEndpoint>(
  page: F,
  { initialValues }: UseFiltersOptions<F> = {},
): UseFiltersResponse<F> => {
  const [atomFilters, setAtomFilters] = useAtom(filterAtoms[page] ?? defaultAtom)
  const { query: searchQuery } = useSearchQuery()

  useHydrateAtoms<Map<PrimitiveAtom<Filters>, unknown>>(
    new Map<PrimitiveAtom<Filters>, unknown>(
      initialValues
        ? [
            [
              filterAtoms[page satisfies FilterEndpoint],
              removeInvalidFilters(page, filterEmptyValues(initialValues)),
            ],
          ]
        : [],
    ),
  )

  const updateFilters = useCallback(
    (newFilters: PageFilterTypes[F]) => {
      const updatedFilters = removeInvalidFilters(page, filterEmptyValues(newFilters))

      setAtomFilters(updatedFilters)
    },
    [page, setAtomFilters],
  )

  const filters = useMemo(() => {
    return filterEmptyValues({ ...atomFilters, searchQuery })
  }, [atomFilters, searchQuery])

  const getActiveFilterCount = useCallback(
    (omitFilters: string[] = []) => {
      const omittedFilters = ['page', 'sort'].concat(omitFilters)

      // TODO: This needs to be default for /connect/products but this mixes responsibilities
      // where to do this?
      if (page === 'products') {
        omittedFilters.push('shipToLocationId')
      }
      const filterKeys = Object.keys(filters).filter(key => !omittedFilters.includes(key))
      return filterKeys.length || 0
    },
    [filters, page],
  )

  const updateDateFilterChange = useCallback(
    (
      filterStartName: keyof PageFilterTypes[F],
      filterEndName: keyof PageFilterTypes[F],
      dateChange: DateSelectionChange,
    ) => {
      updateFilters({
        ...filters,
        [filterEndName]: dateChange.endDate?.toISOString(),
        [filterStartName]: dateChange.startDate?.toISOString(),
      })
    },
    [filters, updateFilters],
  )

  const updateOneFilter = useCallback(
    (filterName: keyof PageFilterTypes[F], value: string | undefined): void => {
      const newFilters = {
        ...filters,
        [filterName]: value,
      }

      updateFilters(newFilters)
    },
    [filters, updateFilters],
  )

  const updatePageNumber = useCallback(
    (pageNumber: string): void => {
      const newFilters = {
        ...filters,
        page: pageNumber,
      }
      updateFilters(newFilters)
    },
    [filters, updateFilters],
  )

  const updateSort = useCallback(
    (sort: string) => {
      const newFilters = {
        ...filters,
        page: '1',
        sort,
      }
      updateFilters(newFilters)
    },
    [filters, updateFilters],
  )

  return {
    filters,
    getActiveFilterCount,
    pageNumber: parseInt(filters.page ?? '1', 10),
    sort: filters.sort,
    updateDateFilterChange,
    updateFilters,
    updateOneFilter,
    updatePageNumber,
    updateSort,
  }
}
