import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFilm = createIcon({
  displayName: 'IconFilm',
  path: [
    <path
      d="M21 5V3H3V5H5V7H3V9H5V11H3V13H5V15H3V17H5V19H3V21H21V19H19V17H21V15H19V13H21V11H19V9H21V7H19V5H21ZM17 19H7V13H17V19ZM17 11H7V5H17V11Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
