import { atom } from 'jotai'

import { DocumentFilters } from '@brenntag/connect-apis/api/models/document'

const documentFilterDefaults: DocumentFilters = {
  page: '1',
  sort: 'productName',
} as const

export const documentFilters = atom<DocumentFilters>(documentFilterDefaults)
