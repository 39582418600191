import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCart = createIcon({
  displayName: 'IconCart',
  path: [
    <path
      d="M20.88 17H5.07L4.07 4H2V2H5.93L6.93 15H19.12L19.99 8H8V6H22L21.99 8.12L20.88 17Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M9 21C9.55228 21 10 20.5523 10 20C10 19.4477 9.55228 19 9 19C8.44772 19 8 19.4477 8 20C8 20.5523 8.44772 21 9 21Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M18 20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20C16 19.4477 16.4477 19 17 19C17.5523 19 18 19.4477 18 20Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
