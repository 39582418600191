import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconExpand = createIcon({
  displayName: 'IconExpand',
  path: [
    <path d="M22 11H20V4H13V2H22V11Z" fill="currentColor" key="0" />,
    <path d="M18 7.41176L7.41176 18H6V16.5882L16.5882 6H18V7.41176Z" fill="currentColor" key="1" />,
    <path d="M4 13H2V22H11V20H4L4 13Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
