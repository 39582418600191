export const Badge = {
  baseStyle: {
    borderRadius: 'md',
    fontWeight: 'normal',
    px: '2',
    py: '1',
    textTransform: 'none',
  },
  variants: {
    'dangerous-goods': () => ({
      backgroundColor: 'yellow.10',
      borderRadius: '2xl',
      color: 'yellow.80',
    }),
  },
}
