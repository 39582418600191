import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconNewsWithGradient = createIcon({
  displayName: 'IconNews',
  path: [
    <path
      d="M19 18V7H21V18C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18V5H5V16C5 17.65 6.35 19 8 19H13.1C13.25 19.73 13.57 20.39 14 20.97V21H8C5.24 21 3 18.76 3 16V3H17V18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18Z"
      fill="url(#paint0_linear_1092_5215)"
      key="0"
    />,
    <path d="M7 7H13V9H7V7Z" fill="url(#paint0_linear_1092_5215)" key="1" />,
    <path d="M7 11H13V13H7V11Z" fill="url(#paint0_linear_1092_5215)" key="2" />,
    <path d="M13 15H7V17H13V15Z" fill="url(#paint0_linear_1092_5215)" key="3" />,
    <defs key={4}>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1092_5215"
        x1="7"
        x2="42.99"
        y1="48"
        y2="12.01">
        <stop stopColor="#C815E0" />
        <stop offset="1" stopColor="#0C72ED" />
      </linearGradient>
    </defs>,
  ],
  viewBox: '0 0 24 24',
})
