import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19 18V7h2v11c0 1.65-1.35 3-3 3s-3-1.35-3-3V5H5v11c0 1.65 1.35 3 3 3h5.1c.15.73.47 1.39.9 1.97V21H8c-2.76 0-5-2.24-5-5V3h14v15c0 .55.45 1 1 1s1-.45 1-1"
      fill="currentColor"
    />
    <path d="M7 7h6v2H7zM7 11h6v2H7zM13 15H7v2h6z" fill="currentColor" />
  </svg>
)
export default SvgNews
