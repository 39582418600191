import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m12 6.59 5-5L22.41 7l-9 9H8v-5.41L10.59 8 12 9.41l-2 2V14h2.59l7-7L17 4.41 13.41 8z"
      fill="currentColor"
    />
    <path d="M19 19v-5.76l2-2V21H3V3h9.76l-2 2H5v14z" fill="currentColor" />
  </svg>
)
export default SvgEdit
