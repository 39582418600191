import React from 'react'

import { IconProps } from '@chakra-ui/react'

import { allIconsBrand, IconNames, IconNamesBrand } from '#shared/icons/brand/allIconsBrand.js'
import { IconAdd } from '#shared/icons/brand/IconAdd.js'
import { IconAddTruck } from '#shared/icons/brand/IconAddTruck.js'
import { IconAnnouncement } from '#shared/icons/brand/IconAnnouncement.js'
import { IconArchive } from '#shared/icons/brand/IconArchive.js'
import { IconArrowDown } from '#shared/icons/brand/IconArrowDown.js'
import { IconArrowLeft } from '#shared/icons/brand/IconArrowLeft.js'
import { IconArrowRight } from '#shared/icons/brand/IconArrowRight.js'
import { IconArrowUp } from '#shared/icons/brand/IconArrowUp.js'
import { IconAt } from '#shared/icons/brand/IconAt.js'
import { IconAttachment } from '#shared/icons/brand/IconAttachment.js'
import { IconBarcode } from '#shared/icons/brand/IconBarcode.js'
import { IconBarGraph } from '#shared/icons/brand/IconBarGraph.js'
import { IconBook } from '#shared/icons/brand/IconBook.js'
import { IconBookmark } from '#shared/icons/brand/IconBookmark.js'
import { IconBookmarkFilled } from '#shared/icons/brand/IconBookmarkFilled.js'
import { IconBriefcase } from '#shared/icons/brand/IconBriefcase.js'
import { IconBrowser } from '#shared/icons/brand/IconBrowser.js'
import { IconCalendar } from '#shared/icons/brand/IconCalendar.js'
import { IconCamera } from '#shared/icons/brand/IconCamera.js'
import { IconCart } from '#shared/icons/brand/IconCart.js'
import { IconCartAdd } from '#shared/icons/brand/IconCartAdd.js'
import { IconChange } from '#shared/icons/brand/IconChange.js'
import { IconChangeOrder } from '#shared/icons/brand/IconChangeOrder.js'
import { IconChat } from '#shared/icons/brand/IconChat.js'
import { IconCheckmark } from '#shared/icons/brand/IconCheckmark.js'
import { IconChemistry } from '#shared/icons/brand/IconChemistry.js'
import { IconChevronDown } from '#shared/icons/brand/IconChevronDown.js'
import { IconChevronLeft } from '#shared/icons/brand/IconChevronLeft.js'
import { IconChevronRight } from '#shared/icons/brand/IconChevronRight.js'
import { IconChevronUp } from '#shared/icons/brand/IconChevronUp.js'
import { IconCircleAdd } from '#shared/icons/brand/IconCircleAdd.js'
import { IconCircleAlert } from '#shared/icons/brand/IconCircleAlert.js'
import { IconCircleCheckmark } from '#shared/icons/brand/IconCircleCheckmark.js'
import { IconCircleDelete } from '#shared/icons/brand/IconCircleDelete.js'
import { IconCircleFilled } from '#shared/icons/brand/IconCircleFilled.js'
import { IconCircleInfo } from '#shared/icons/brand/IconCircleInfo.js'
import { IconCircleInfoFilled } from '#shared/icons/brand/IconCircleInfoFilled.js'
import { IconCirclePause } from '#shared/icons/brand/IconCirclePause.js'
import { IconCircleQuestion } from '#shared/icons/brand/IconCircleQuestion.js'
import { IconCircleRemove } from '#shared/icons/brand/IconCircleRemove.js'
import { IconClipboard } from '#shared/icons/brand/IconClipboard.js'
import { IconClose } from '#shared/icons/brand/IconClose.js'
import { IconCode } from '#shared/icons/brand/IconCode.js'
import { IconColumns } from '#shared/icons/brand/IconColumns.js'
import { IconCompass } from '#shared/icons/brand/IconCompass.js'
import { IconContacts } from '#shared/icons/brand/IconContacts.js'
import { IconCut } from '#shared/icons/brand/IconCut.js'
import { IconDash } from '#shared/icons/brand/IconDash.js'
import { IconDashboard } from '#shared/icons/brand/IconDashboard.js'
import { IconDelete } from '#shared/icons/brand/IconDelete.js'
import { IconDesktop } from '#shared/icons/brand/IconDesktop.js'
import { IconDialogue } from '#shared/icons/brand/IconDialogue.js'
import { IconDocumentation } from '#shared/icons/brand/IconDocumentation.js'
import { IconDots } from '#shared/icons/brand/IconDots.js'
import { IconDownload } from '#shared/icons/brand/IconDownload.js'
import { IconDownloadcontained } from '#shared/icons/brand/IconDownloadcontained.js'
import { IconDuplicate } from '#shared/icons/brand/IconDuplicate.js'
import { IconEdit } from '#shared/icons/brand/IconEdit.js'
import { IconEnterprise } from '#shared/icons/brand/IconEnterprise.js'
import { IconExpand } from '#shared/icons/brand/IconExpand.js'
import { IconFile } from '#shared/icons/brand/IconFile.js'
import { IconFileAdd } from '#shared/icons/brand/IconFileAdd.js'
import { IconFileCertificate } from '#shared/icons/brand/IconFileCertificate.js'
import { IconFileCode } from '#shared/icons/brand/IconFileCode.js'
import { IconFileCSV } from '#shared/icons/brand/IconFileCSV.js'
import { IconFileError } from '#shared/icons/brand/IconFileError.js'
import { IconFilePDF } from '#shared/icons/brand/IconFilePDF.js'
import { IconFileRemove } from '#shared/icons/brand/IconFileRemove.js'
import { IconFileSearch } from '#shared/icons/brand/IconFileSearch.js'
import { IconFileText } from '#shared/icons/brand/IconFileText.js'
import { IconFileXLS } from '#shared/icons/brand/IconFileXLS.js'
import { IconFilm } from '#shared/icons/brand/IconFilm.js'
import { IconFilter } from '#shared/icons/brand/IconFilter.js'
import { IconFilters } from '#shared/icons/brand/IconFilters.js'
import { IconFiltersNotshow } from '#shared/icons/brand/IconFiltersNotshow.js'
import { IconFiltersShow } from '#shared/icons/brand/IconFiltersShow.js'
import { IconFinance } from '#shared/icons/brand/IconFinance.js'
import { IconFlag } from '#shared/icons/brand/IconFlag.js'
import { IconFolder } from '#shared/icons/brand/IconFolder.js'
import { IconFolderMinus } from '#shared/icons/brand/IconFolderMinus.js'
import { IconFolderPlus } from '#shared/icons/brand/IconFolderPlus.js'
import { IconGlobe } from '#shared/icons/brand/IconGlobe.js'
import { IconGrid } from '#shared/icons/brand/IconGrid.js'
import { IconGroup } from '#shared/icons/brand/IconGroup.js'
import { IconHandover } from '#shared/icons/brand/IconHandover.js'
import { IconHappy } from '#shared/icons/brand/IconHappy.js'
import { IconHashtag } from '#shared/icons/brand/IconHashtag.js'
import { IconHeart } from '#shared/icons/brand/IconHeart.js'
import { IconHistory } from '#shared/icons/brand/IconHistory.js'
import { IconHome } from '#shared/icons/brand/IconHome.js'
import { IconImage } from '#shared/icons/brand/IconImage.js'
import { IconInbound } from '#shared/icons/brand/IconInbound.js'
import { IconInbox } from '#shared/icons/brand/IconInbox.js'
import { IconInfo } from '#shared/icons/brand/IconInfo.js'
import { IconInvoice } from '#shared/icons/brand/IconInvoice.js'
import { IconKey } from '#shared/icons/brand/IconKey.js'
import { IconLicensePlate } from '#shared/icons/brand/IconLicensePlate.js'
import { IconLink } from '#shared/icons/brand/IconLink.js'
import { IconLocation } from '#shared/icons/brand/IconLocation.js'
import { IconLocked } from '#shared/icons/brand/IconLocked.js'
import { IconLogout } from '#shared/icons/brand/IconLogout.js'
import { IconMail } from '#shared/icons/brand/IconMail.js'
import { IconMap } from '#shared/icons/brand/IconMap.js'
import { IconMenu } from '#shared/icons/brand/IconMenu.js'
import { IconMicrophone } from '#shared/icons/brand/IconMicrophone.js'
import { IconMusic } from '#shared/icons/brand/IconMusic.js'
import { IconMyLocation } from '#shared/icons/brand/IconMyLocation.js'
import { IconNews } from '#shared/icons/brand/IconNews.js'
import { IconNight } from '#shared/icons/brand/IconNight.js'
import { IconNotification } from '#shared/icons/brand/IconNotification.js'
import { IconOrderDown } from '#shared/icons/brand/IconOrderDown.js'
import { IconOrderUp } from '#shared/icons/brand/IconOrderUp.js'
import { IconPaletts } from '#shared/icons/brand/IconPaletts.js'
import { IconParcel } from '#shared/icons/brand/IconParcel.js'
import { IconParcelAdd } from '#shared/icons/brand/IconParcelAdd.js'
import { IconParcelCheck } from '#shared/icons/brand/IconParcelCheck.js'
import { IconParcelLocation } from '#shared/icons/brand/IconParcelLocation.js'
import { IconParcelScan } from '#shared/icons/brand/IconParcelScan.js'
import { IconPause } from '#shared/icons/brand/IconPause.js'
import { IconPhone } from '#shared/icons/brand/IconPhone.js'
import { IconPhoneIn } from '#shared/icons/brand/IconPhoneIn.js'
import { IconPhoneOut } from '#shared/icons/brand/IconPhoneOut.js'
import { IconPieChart } from '#shared/icons/brand/IconPieChart.js'
import { IconPlay } from '#shared/icons/brand/IconPlay.js'
import { IconPointer } from '#shared/icons/brand/IconPointer.js'
import { IconPricequote } from '#shared/icons/brand/IconPricequote.js'
import { IconPrint } from '#shared/icons/brand/IconPrint.js'
import { IconPuzzle } from '#shared/icons/brand/IconPuzzle.js'
import { IconQuestion } from '#shared/icons/brand/IconQuestion.js'
import { IconQuote } from '#shared/icons/brand/IconQuote.js'
import { IconReload } from '#shared/icons/brand/IconReload.js'
import { IconRepeat } from '#shared/icons/brand/IconRepeat.js'
import { IconRocket } from '#shared/icons/brand/IconRocket.js'
import { IconSad } from '#shared/icons/brand/IconSad.js'
import { IconSample } from '#shared/icons/brand/IconSample.js'
import { IconScan } from '#shared/icons/brand/IconScan.js'
import { IconSearch } from '#shared/icons/brand/IconSearch.js'
import { IconServer } from '#shared/icons/brand/IconServer.js'
import { IconSettings } from '#shared/icons/brand/IconSettings.js'
import { IconShare } from '#shared/icons/brand/IconShare.js'
import { IconShip } from '#shared/icons/brand/IconShip.js'
import { IconShipments } from '#shared/icons/brand/IconShipments.js'
import { IconShop } from '#shared/icons/brand/IconShop.js'
import { IconSizeEnlarge } from '#shared/icons/brand/IconSizeEnlarge.js'
import { IconSizeMinimise } from '#shared/icons/brand/IconSizeMinimise.js'
import { IconSmartphone } from '#shared/icons/brand/IconSmartphone.js'
import { IconSocialFacebook } from '#shared/icons/brand/IconSocialFacebook.js'
import { IconSocialLinkedIn } from '#shared/icons/brand/IconSocialLinkedIn.js'
import { IconSocialTwitter } from '#shared/icons/brand/IconSocialTwitter.js'
import { IconSocialYoutube } from '#shared/icons/brand/IconSocialYoutube.js'
import { IconSomethingWrong } from '#shared/icons/brand/IconSomethingWrong.js'
import { IconSpeaker } from '#shared/icons/brand/IconSpeaker.js'
import { IconSpinner } from '#shared/icons/brand/IconSpinner.js'
import { IconSquareAdd } from '#shared/icons/brand/IconSquareAdd.js'
import { IconSquareDelete } from '#shared/icons/brand/IconSquareDelete.js'
import { IconSquareRemove } from '#shared/icons/brand/IconSquareRemove.js'
import { IconStar } from '#shared/icons/brand/IconStar.js'
import { IconStarFilled } from '#shared/icons/brand/IconStarFilled.js'
import { IconStore } from '#shared/icons/brand/IconStore.js'
import { IconTag } from '#shared/icons/brand/IconTag.js'
import { IconThumbsDown } from '#shared/icons/brand/IconThumbsDown.js'
import { IconThumbsUp } from '#shared/icons/brand/IconThumbsUp.js'
import { IconTime } from '#shared/icons/brand/IconTime.js'
import { IconTouchpoint } from '#shared/icons/brand/IconTouchpoint.js'
import { IconTrack } from '#shared/icons/brand/IconTrack.js'
import { IconTranslate } from '#shared/icons/brand/IconTranslate.js'
import { IconTrendingDown } from '#shared/icons/brand/IconTrendingDown.js'
import { IconTrendingUp } from '#shared/icons/brand/IconTrendingUp.js'
import { IconTrophy } from '#shared/icons/brand/IconTrophy.js'
import { IconTruck } from '#shared/icons/brand/IconTruck.js'
import { IconTrucks } from '#shared/icons/brand/IconTrucks.js'
import { IconUndo } from '#shared/icons/brand/IconUndo.js'
import { IconUnfold } from '#shared/icons/brand/IconUnfold.js'
import { IconUnlocked } from '#shared/icons/brand/IconUnlocked.js'
import { IconUntrash } from '#shared/icons/brand/IconUntrash.js'
import { IconUpload } from '#shared/icons/brand/IconUpload.js'
import { IconUploadcontained } from '#shared/icons/brand/IconUploadcontained.js'
import { IconUser } from '#shared/icons/brand/IconUser.js'
import { IconUserAdd } from '#shared/icons/brand/IconUserAdd.js'
import { IconUserAdmin } from '#shared/icons/brand/IconUserAdmin.js'
import { IconUserCheck } from '#shared/icons/brand/IconUserCheck.js'
import { IconUserCSR } from '#shared/icons/brand/IconUserCSR.js'
import { IconUserRemove } from '#shared/icons/brand/IconUserRemove.js'
import { IconUserViewer } from '#shared/icons/brand/IconUserViewer.js'
import { IconVideo } from '#shared/icons/brand/IconVideo.js'
import { IconView } from '#shared/icons/brand/IconView.js'
import { IconViewOff } from '#shared/icons/brand/IconViewOff.js'
import { IconVolumeHigh } from '#shared/icons/brand/IconVolumeHigh.js'
import { IconVolumeLow } from '#shared/icons/brand/IconVolumeLow.js'
import { IconVolumeMid } from '#shared/icons/brand/IconVolumeMid.js'
import { IconVolumeOff } from '#shared/icons/brand/IconVolumeOff.js'
import { IconWarehouse } from '#shared/icons/brand/IconWarehouse.js'
import { IconZoomIn } from '#shared/icons/brand/IconZoomIn.js'
import { IconZoomOut } from '#shared/icons/brand/IconZoomOut.js'

export {
  IconAdd,
  IconAddTruck,
  IconAnnouncement,
  IconArchive,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconAt,
  IconAttachment,
  IconBarcode,
  IconBarGraph,
  IconBook,
  IconBookmark,
  IconBookmarkFilled,
  IconBriefcase,
  IconBrowser,
  IconCalendar,
  IconCamera,
  IconCart,
  IconCartAdd,
  IconChange,
  IconChangeOrder,
  IconChat,
  IconCheckmark,
  IconChemistry,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconCircleAdd,
  IconCircleAlert,
  IconCircleCheckmark,
  IconCircleDelete,
  IconCircleFilled,
  IconCircleInfo,
  IconCircleInfoFilled,
  IconCirclePause,
  IconCircleQuestion,
  IconCircleRemove,
  IconClipboard,
  IconClose,
  IconCode,
  IconColumns,
  IconCompass,
  IconContacts,
  IconCut,
  IconDash,
  IconDashboard,
  IconDelete,
  IconDesktop,
  IconDialogue,
  IconDocumentation,
  IconDots,
  IconDownload,
  IconDownloadcontained,
  IconDuplicate,
  IconEdit,
  IconEnterprise,
  IconExpand,
  IconFile,
  IconFileAdd,
  IconFileCertificate,
  IconFileCode,
  IconFileCSV,
  IconFileError,
  IconFilePDF,
  IconFileRemove,
  IconFileSearch,
  IconFileText,
  IconFileXLS,
  IconFilm,
  IconFilter,
  IconFilters,
  IconFiltersNotshow,
  IconFiltersShow,
  IconFinance,
  IconFlag,
  IconFolder,
  IconFolderMinus,
  IconFolderPlus,
  IconGlobe,
  IconGrid,
  IconGroup,
  IconHandover,
  IconHappy,
  IconHashtag,
  IconHeart,
  IconHistory,
  IconHome,
  IconImage,
  IconInbound,
  IconInbox,
  IconInfo,
  IconInvoice,
  IconKey,
  IconLicensePlate,
  IconLink,
  IconLocation,
  IconLocked,
  IconLogout,
  IconMail,
  IconMap,
  IconMenu,
  IconMicrophone,
  IconMusic,
  IconMyLocation,
  IconNews,
  IconNight,
  IconNotification,
  IconOrderDown,
  IconOrderUp,
  IconPaletts,
  IconParcel,
  IconParcelAdd,
  IconParcelCheck,
  IconParcelLocation,
  IconParcelScan,
  IconPause,
  IconPhone,
  IconPhoneIn,
  IconPhoneOut,
  IconPieChart,
  IconPlay,
  IconPointer,
  IconPricequote,
  IconPrint,
  IconPuzzle,
  IconQuestion,
  IconQuote,
  IconReload,
  IconRepeat,
  IconRocket,
  IconSad,
  IconSample,
  IconScan,
  IconSearch,
  IconServer,
  IconSettings,
  IconShare,
  IconShip,
  IconShipments,
  IconShop,
  IconSizeEnlarge,
  IconSizeMinimise,
  IconSmartphone,
  IconSocialFacebook,
  IconSocialLinkedIn,
  IconSocialTwitter,
  IconSocialYoutube,
  IconSomethingWrong,
  IconSpeaker,
  IconSpinner,
  IconSquareAdd,
  IconSquareDelete,
  IconSquareRemove,
  IconStar,
  IconStarFilled,
  IconStore,
  IconTag,
  IconThumbsDown,
  IconThumbsUp,
  IconTime,
  IconTouchpoint,
  IconTrack,
  IconTranslate,
  IconTrendingDown,
  IconTrendingUp,
  IconTrophy,
  IconTruck,
  IconTrucks,
  IconUndo,
  IconUnfold,
  IconUnlocked,
  IconUntrash,
  IconUpload,
  IconUploadcontained,
  IconUser,
  IconUserAdd,
  IconUserAdmin,
  IconUserCheck,
  IconUserCSR,
  IconUserRemove,
  IconUserViewer,
  IconVideo,
  IconView,
  IconViewOff,
  IconVolumeHigh,
  IconVolumeLow,
  IconVolumeMid,
  IconVolumeOff,
  IconWarehouse,
  IconZoomIn,
  IconZoomOut,
}

export interface IconByNameProps extends IconProps {
  inverted?: boolean
  name: IconNames
}

export const IconByName: React.FC<IconByNameProps> = ({
  color,
  fill,
  inverted,
  name,
  ...props
}) => {
  const Icon = allIconsBrand[name]

  return Icon ? (
    <Icon
      background={inverted ? fill || color : undefined}
      borderRadius={inverted ? '50%' : 0}
      color={inverted ? 'white' : fill || color}
      {...props}
    />
  ) : null
}

export type { IconNames, IconNamesBrand }
