import { Customer, SalesGroup, Webstore } from '@brenntag/api-customer'

import { SalesforceSession, useSalesforceSession } from '#lib/auth/salesforce/useSession.js'
import { useSession } from '#lib/auth/useSession.js'
import { Country } from '#lib/countries/getCountries.js'
import { useCountry } from '#lib/countries/useCountry.js'
import { useGetCustomers } from '#modules/customer/hooks/customers/useGetCustomers.js'
import { useGetSalesGroups } from '#modules/customer/hooks/sales-groups/useGetSalesGroups.js'
import { useGetWebstores } from '#modules/customer/hooks/webstores/useGetWebstores.js'
import { FeatureFlags, useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'
import { useSelectedCustomer } from '#shared/hooks/globalState/useSelectedCustomer.js'
import { useSelectedShipToLocation } from '#shared/hooks/globalState/useSelectedShipToLocation.js'
import { environment, isMockMode, isProductionMode as isOptimisedBuild } from '#src/env.js'

/**
 * TODO this AclContextInfo type is actually dictated from the outside just like the feature functions
 *  Find a good way to allow "injecting" context info and extending the type
 */
export type AclContextInfo = {
  country?: Country
  customers: Customer[] | undefined
  devTools?: boolean
  environment: string
  featureFlags: FeatureFlags
  isMockMode: boolean
  isOptimisedBuild: boolean
  salesforce: SalesforceSession
  salesGroups: SalesGroup[] | undefined
  selectedCustomer?: Customer
  selectedSalesGroup?: SalesGroup
  selectedWebstore?: Webstore
  user: {
    countries: string[]
    id?: string
    isActive: boolean
    isAuthenticated: boolean
    roles: 'CO2'[]
  }
  webstores: undefined | Webstore[]
}

/**
 * Collects context information from different static or reactive sources like other context providers,
 * hooks, env vars, query params, SSR hydration data (if applicable), etc
 * TODO expose this to outside like features.config.ts
 */
export const useAclContextInfo = (): AclContextInfo => {
  const webstores = useGetWebstores()
  const salesGroups = useGetSalesGroups()
  const customers = useGetCustomers({})
  const { featureFlags } = useFeatureFlags()
  const country = useCountry()
  const { selectedCustomer } = useSelectedCustomer()
  const salesforce = useSalesforceSession()
  const { isAuthenticated, user } = useSession()
  const { shipToLocation } = useSelectedShipToLocation()
  const selectedSalesGroup = salesGroups?.data?.find(sg => sg.id === selectedCustomer?.salesGroupId)
  const selectedWebstore = webstores?.data.find(
    webstore => webstore.id === shipToLocation?.webstoreId,
  )
  const appMeta = user?.brenntag?.app

  return {
    country,
    customers: customers?.data,
    environment,
    featureFlags,
    isMockMode,
    isOptimisedBuild,
    salesforce,
    salesGroups: salesGroups?.data,
    selectedCustomer,
    selectedSalesGroup,
    selectedWebstore,
    user: {
      countries: appMeta?.countries ?? [],
      id: user?.id,
      // Only US / Salesforce users have an "active" status. All other users are considered active when able
      // to log in using Auth0
      isActive: Boolean(appMeta?.status?.toLowerCase() === 'active' || !appMeta?.status),
      isAuthenticated,
      roles: appMeta?.roles ?? [],
    },
    webstores: webstores?.data,
  }
}
