export const Card = {
  baseStyle: {
    borderColor: 'neutral.20',
    borderWidth: '1px',
    boxShadow: 'md',
    px: 8,
    py: 6,
  },
  variants: {
    stack: {
      boxShadow: 'none',
    },
  },
}
