import React, { FC } from 'react'

import { InvoiceList } from '@brenntag/connect-apis/api/models/invoice'

import { getInvoiceStatusStyles } from '#shared/components/Card/InvoiceCard/getColorByStatus.js'
import { ResultItem, ResultItemTitle } from '#shared/components/GlobalSearch/ResultItem.js'
import { ItemSearchResult } from '#shared/components/GlobalSearch/SearchResults/ItemSearchResult.js'
import { useFormatDate } from '#shared/locale/hooks/useIntl.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface InvoiceSearchResultProps {
  invoiceList: InvoiceList
  onItemClick: VoidFunction
  onViewMoreClick: VoidFunction
  search: string
}

export const InvoiceSearchResult: FC<InvoiceSearchResultProps> = ({
  invoiceList: { invoices, total },
  onItemClick,
  onViewMoreClick,
  search,
}) => {
  const t = useTranslations()
  const formatDate = useFormatDate()

  return (
    <ItemSearchResult
      items={invoices}
      linkToDetails
      onItemClick={onItemClick}
      onViewMoreClick={onViewMoreClick}
      to="/:locale/connect/invoices"
      total={total}
      type="invoices">
      {invoice => {
        const status = getInvoiceStatusStyles(t, invoice.status)
        return (
          <ResultItem
            fields={{
              items: [
                {
                  key: 'order-number',
                  label: t('label.brenntagordernumber'),
                  value: invoice.delivery?.order?.salesOrderNumber,
                },
                {
                  key: 'po-number',
                  label: t('label.purchaseOrderNumber'),
                  value: invoice.purchaseOrderNumber,
                },
                {
                  key: 'date-issued',
                  label: t('label.invoice.dateIssued'),
                  value: formatDate(invoice.invoiceDate),
                },
                {
                  key: 'due-date',
                  label: t('invoice.dueDate'),
                  value: formatDate(invoice.dueDate),
                },
              ],
              search,
            }}
            title={
              <ResultItemTitle
                label={t('label.invoice')}
                search={search}
                status={status}
                value={invoice.number}
              />
            }
          />
        )
      }}
    </ItemSearchResult>
  )
}
