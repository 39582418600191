import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTranslate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17 4v2.01h-2.63a33.6 33.6 0 0 1-4.32 6.46c.7.62 1.46 1.16 2.29 1.61-.05.16-.1.32-.14.49-.13.49-.26.98-.39 1.49-1.13-.56-2.17-1.28-3.11-2.12a33 33 0 0 1-5.07 4.3L2.5 16.59c1.74-1.2 3.35-2.57 4.81-4.09A14.1 14.1 0 0 1 4.75 8h2.13c.44 1.09 1.05 2.1 1.78 3 1.31-1.55 2.46-3.22 3.45-5H1V4h7V2h2v2z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M19.092 9c.62 1.96 1.2 3.99 1.76 6.06l.01.01c.56 2.08 1.14 4.39 1.73 6.94h-2.29c-.02-.17-.05-.32-.09-.46q-.06-.21-.09-.42c-.18-.81-.35-1.57-.52-2.3h-4.17l-.08.358c-.094.417-.187.833-.28 1.292-.045.166-.081.328-.118.49q-.03.135-.062.27c-.06.25-.12.5-.18.76h-2.29c.6-2.53 1.17-4.84 1.72-6.93s1.14-4.11 1.77-6.07zm-2.8 6.08c-.16.62-.32 1.3-.48 1.99l.01-.01h3.37l-.12-.509c-.106-.448-.208-.88-.31-1.281-.37-1.5-.8-2.96-1.25-4.4-.45 1.38-.86 2.77-1.22 4.21"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgTranslate
