import { OrderStatus, SingleIncludedOrderResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildOrder } from '@brenntag/legacy-mock-generators/generators/orders/buildOrder.js'
import { buildOrderLine } from '@brenntag/legacy-mock-generators/generators/orders/buildOrderLine.js'

import { manyPricePoints } from '#src/mocks/apis/api/products/data/manyPricePoints.js'

export const picked: SingleIncludedOrderResourceUnion = buildOrder({
  attributes: {
    salesOrderNumber: 'Picked order',
    status: OrderStatus.Picked,
  },
  id: 'picked',
  included: {
    attachments: [],
    deliveries: [],
    orderLines: [
      [
        buildOrderLine({
          id: 'orderLine1',
          included: { product: manyPricePoints.data },
          orderId: 'picked',
        }),
        manyPricePoints,
      ],
    ],
  },
})
