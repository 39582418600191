import React from 'react'

import { consola } from 'consola/browser'

import { AppHeaderDrawer } from '@brenntag/ui/components/AppHeader/AppHeaderDrawer'
import { AppHeaderDrawerFooter } from '@brenntag/ui/components/AppHeader/AppHeaderDrawerFooter'
import { AppHeaderDrawerToggle } from '@brenntag/ui/components/AppHeader/AppHeaderDrawerToggle'
import { AppHeaderMainBar } from '@brenntag/ui/components/AppHeader/AppHeaderMainBar'
import { AppHeaderNavigationBar } from '@brenntag/ui/components/AppHeader/AppHeaderNavigationBar'
import { AppHeaderProvider } from '@brenntag/ui/components/AppHeader/AppHeaderProvider'
import { Button } from '@brenntag/ui/components/Button'
import { Icon } from '@brenntag/ui/components/Icon'

import { AppLink } from '#components/AppLink/AppLink.js'
import { AppLogo } from '#components/AppLogo/AppLogo.js'
import { CountrySelector } from '#containers/CountrySelector.js'
import { ShoppingCartButton } from '#containers/ShoppingCartButton.js'
import { useIsAllowedFunction } from '#lib/Acl/index.js'
import { useSession } from '#lib/auth/useSession.js'
import { useCountry } from '#lib/countries/useCountry.js'
import { GlobalSearch } from '#shared/components/GlobalSearch/GlobalSearch.js'
import { homeLinksMap, navItemsMap } from '#shared/components/Header/appNavMapping.js'
import { LogoutAction } from '#shared/components/Header/LogoutAction.js'
import { NavigationItem } from '#shared/components/Header/NavigationItem.js'
import { useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'
import { useLocationSearch } from '#shared/hooks/search/useLocationSearch.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { useMatchAccessRoute } from '#shared/routing/hooks/useMatchAccessRoute.js'

import { PrivateHeaderMenu } from './PrivateHeaderMenu'

type AccessType = 'private' | 'public'

const getNavItems = (accessType: AccessType, countryCode: string) => {
  const countryNavItems = navItemsMap[countryCode]
  return countryNavItems[accessType]
}

const getHomeLink = (accessType: AccessType, countryCode: string) => {
  const countryHomeLink = homeLinksMap[countryCode]
  return countryHomeLink[accessType]
}

const LogoutMenuItem = () => {
  const t = useTranslations()
  return (
    <LogoutAction>
      <Button className="hidden lg:flex" data-testid="header-logout-link" variant="text">
        {t('label.logout')}
      </Button>
    </LogoutAction>
  )
}

const ProfileMenuItem = () => {
  const t = useTranslations()

  return (
    <Button asChild className="px-3.5 lg:px-6" data-testid="header-profile-link" variant="text">
      <AppLink to={'/:locale/connect/profile'}>
        <Icon className="lg:mr-2" data-testid="header-profile-icon" name="User" size="md" />
        <span className="hidden lg:flex">{t('label.myprofile')}</span>
      </AppLink>
    </Button>
  )
}

const CartMenuItem = () => {
  const {
    featureFlags: { enableCartV2 },
  } = useFeatureFlags()
  if (enableCartV2) {
    return null
  }

  return <ShoppingCartButton />
}

export const Header = () => {
  const t = useTranslations()
  const { isAuthenticated } = useSession()
  const { isPublicRoute } = useMatchAccessRoute()

  const locationSearch = useLocationSearch({ parseBooleans: true })
  const isRegisterCampaign = locationSearch.campaign === 'register'

  const country = useCountry()
  const isAllowed = useIsAllowedFunction()

  if (!country) {
    consola.warn('Public header not loaded, no country found')
    return null
  }

  const accessType: AccessType = isPublicRoute ? 'public' : 'private'
  const homeLink = getHomeLink(accessType, country.code)
  const navItems = isRegisterCampaign ? [] : getNavItems(accessType, country.code)
  const navItemsLinks = navItems.map(item => {
    if (!isAllowed(item.features)) {
      return null
    }

    return <NavigationItem item={item} key={item.labelKey} />
  })

  return (
    <AppHeaderProvider>
      <header>
        <AppHeaderMainBar className="flex-wrap border-b border-neutral-20 md:flex-nowrap lg:border-none">
          <div className="flex items-center gap-3 lg:gap-x-8">
            <AppHeaderDrawerToggle />
            <AppLink to={homeLink.to}>
              <AppLogo className="h-6 shrink-0 md:h-8 lg:h-10" data-testid="header-brenntag-logo" />
            </AppLink>
          </div>
          <div className="order-last min-w-full flex-1 md:order-none md:min-w-fit">
            {!isPublicRoute && <GlobalSearch />}
          </div>
          {isAuthenticated && !isPublicRoute ? (
            <PrivateHeaderMenu>
              <ProfileMenuItem />
              <CartMenuItem />
              <LogoutMenuItem />
            </PrivateHeaderMenu>
          ) : (
            <CountrySelector />
          )}
        </AppHeaderMainBar>

        <AppHeaderNavigationBar>{navItemsLinks}</AppHeaderNavigationBar>
        <AppHeaderDrawer>
          <div className="flex flex-col">{navItemsLinks}</div>
          <AppHeaderDrawerFooter>
            {isAuthenticated && (
              <LogoutAction>
                <Button
                  className="text-white outline-white lg:hidden"
                  data-testid="header-logout-link"
                  variant="outline">
                  {t('label.logout')}
                </Button>
              </LogoutAction>
            )}
          </AppHeaderDrawerFooter>
        </AppHeaderDrawer>
      </header>
    </AppHeaderProvider>
  )
}
