import { SingleIncludedSampleResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildSample } from '@brenntag/legacy-mock-generators/generators/samples/buildSample.js'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

import { products } from '../../products/data'

export const sampleForProductWithMultipleTankLocations: SingleIncludedSampleResourceUnion =
  buildSample({
    attributes: {},
    id: 'multipleTankLocations',
    included: {
      product: products.items.multipleTankLocations,
      shipTo: buildLocation({ id: locations.showPrices.id }),
    },
  })
