import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconOrderDown = createIcon({
  displayName: 'IconOrderDown',
  path: [
    <path
      d="M12.7109 15.76L11.7109 16.76L10.7109 15.76V3H12.7109V15.76Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M7 17.71L11.71 22.41L16.42 17.71L15 16.29L11.71 19.59L8.42 16.29L7 17.71Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
