import { initApi } from '@brenntag/api-payment/initApi'

import { salesforceSession } from '#lib/auth/salesforce/salesforceSession.js'
import { config as appConfig } from '#src/config.js'

export const init = () => {
  initApi({
    baseUrl: appConfig.paymentApiBaseUrl,
    getTokens: () => {
      const legacyTokens = salesforceSession.getTokens()

      if (!legacyTokens?.accessToken || !legacyTokens?.idToken) {
        return Promise.reject(new Error('No tokens found'))
      }

      return Promise.resolve({
        access_token: legacyTokens.accessToken,
        id_token: legacyTokens.idToken,
      })
    },
  })
}
