import { InvoiceStatus } from '@brenntag/connect-apis/api/models/invoice'

import { StatusStyle } from '#shared/components/StatusPill/StatusPill.js'
import { Translate } from '#shared/locale/hooks/useTranslations.js'

import { StatusStyle as StatusStyle2 } from './types'

export const getInvoiceStatusStyles = (t: Translate, status?: InvoiceStatus): StatusStyle => {
  switch (status) {
    case 'Closed':
      return {
        color: 'primary',
        icon: 'IconCircleCheckmark',
        text: t('label.invoice.status.Closed'),
      }
    case 'Open':
    default:
      return {
        color: 'green.50',
        icon: 'IconTime',
        text: t('label.invoice.status.Open'),
      }
  }
}

{
  /* TODO remove above getInvoiceStatusStyles when we replace status pills in:
  - src/shared/components/GlobalSearch/SearchResults/InvoiceSearchResult.tsx:69:17
  - src/shared/components/GlobalSearch/ResultItem.tsx:13:3
  - src/shared/components/OrderInvoiceSearch/InvoiceSearchResultItem.tsx:81:13
  - src/shared/components/GlobalSearch/ResultItem.tsx:13:3
  */
}
export const getInvoiceStatusStylesV2 = (t: Translate, status?: InvoiceStatus): StatusStyle2 => {
  switch (status) {
    case 'Closed':
      return {
        icon: 'CircleCheckmark',
        name: 'neutral',
        text: t('label.invoice.status.Closed'),
        type: 'Closed',
      }
    case 'Open':
    default:
      return {
        icon: 'Time',
        name: 'info',
        text: t('label.invoice.status.Open'),
        type: 'Open',
      }
  }
}
