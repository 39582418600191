import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFileText = createIcon({
  displayName: 'IconFileText',
  path: [
    <path
      clipRule="evenodd"
      d="M15.41 2H13V9H18V20H6V4H11V2H4V22H20V6.59L15.41 2ZM15 7V4.41L17.59 7H15Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path d="M16 12H8V14H16V12Z" fill="currentColor" key="1" />,
    <path d="M8 16H16V18H8V16Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
