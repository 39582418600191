import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCircleRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m18.31 7.11 1.43-1.43A9.94 9.94 0 0 1 22 12c0 5.51-4.49 10-10 10S2 17.51 2 12 6.49 2 12.01 2c2.39 0 4.6.85 6.32 2.26L16.9 5.69A7.92 7.92 0 0 0 12 4c-4.41 0-8 3.6-8 8.01s3.59 8 8 8 8-3.59 8-8c0-1.85-.64-3.54-1.69-4.9"
      fill="currentColor"
    />
    <path d="M7 11h10v2H7z" fill="currentColor" />
  </svg>
)
export default SvgCircleRemove
