import { http, HttpResponse } from 'msw'

import { CreditLimit } from '@brenntag/api-customer'

import { config } from '#src/config.js'

import { creditLimit as _creditLimit } from './data/creditLimit'

export const fetchCreditLimit = (data: CreditLimit = _creditLimit.eighty) => {
  return http.get(
    `${config.customerApiBaseUrl}/customer/customers/:customerId/credit-limit`,
    async () => {
      return HttpResponse.json(data)
    },
  )
}
