import { theme as chakraTheme, ComponentStyleConfig } from '@chakra-ui/react'

const { variants } = chakraTheme.components.Button

export const Button: ComponentStyleConfig = {
  baseStyle: {
    _disabled: {
      borderColor: 'neutral.30',
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
    _focus: {
      borderColor: 'transparent',
      boxShadow: 'outline',
    },
    borderRadius: 0,
    fontSize: 'lg',
    fontWeight: 700,
    transition: 'none',
  },
  defaultProps: {
    colorScheme: '',
    size: 'md',
    variant: 'text',
  },
  sizes: {
    lg: { fontSize: 'lg', height: '56px', px: 8, py: 4 },
    md: { fontSize: 'lg', height: '48px', px: 6, py: 3 },
    sm: { fontSize: 'lg', height: '40px', px: 4, py: 2 },
  },
  variants: {
    action: props => ({
      ...variants?.solid(props),
      _active: {
        bg: 'blue.60',
      },
      _disabled: {
        bg: 'neutral.30',
      },
      _hover: {
        _disabled: {
          bg: 'neutral.30',
        },
        bg: 'blue.50',
      },
      background: 'transparent',
      bgGradient: `linear(to-r, violet.50, blue.50)`,
      color: 'white',
    }),
    download: props => ({
      ...variants?.link(props),
      _active: {
        color: props.color || 'blue.50',
      },
      _disabled: {
        color: 'neutral.30',
      },
      _focus: {
        border: 0,
        boxShadow: 'none',
        outline: '0px',
        textDecoration: 'underline',
        textDecorationColor: props.color || 'blue.50',
        textDecorationThickness: '2px',
        textUnderlineOffset: '4px',
      },
      _hover: {
        color: props.color || 'blue.50',
        textDecoration: 'underline',
        textDecorationThickness: '2px',
        textUnderlineOffset: '4px',
      },
      color: 'blue.50',
      fontWeight: 700,
    }),
    dropdown: _ => ({
      _active: {
        boxShadow: 'inputOutlineFocus',
      },
      _disabled: {
        boxShadow: 'inputOutlineDisabled',
        cursor: 'not-allowed',
      },
      _focus: {
        boxShadow: 'inputOutlineFocus',
      },
      _hover: {
        boxShadow: 'inputOutlineHover',
      },
      borderWidth: 0,
      boxShadow: 'inputOutline',
      fontWeight: 400,
    }),
    error: props => ({
      ...variants?.outline(props),
      _active: {
        bg: 'red.80',
        borderColor: 'red.80',
        color: 'white',
      },
      _disabled: {
        borderColor: 'neutral.30',
        color: 'neutral.30',
      },
      _focus: {
        borderColor: 'red.70',
      },
      _hover: {
        _disabled: {
          borderColor: 'neutral.30',
          color: 'neutral.30',
        },
        bg: 'red.70',
        color: 'white',
      },
      border: '1px solid',
      borderColor: 'red.70',
      color: 'red.70',
    }),
    ghost: props => ({
      ...variants?.ghost(props),
      _focus: {
        boxShadow: 'none',
      },
      height: 'auto',
      minHeight: 6,
      minWidth: 6,
      p: 0,
      width: 'auto',
    }),
    gray: props => ({
      ...variants?.outline(props),
      _active: {
        bg: 'neutral.10',
      },
      _disabled: {
        color: 'neutral.30',
      },
      _focus: {
        bg: 'neutral.10',
        border: '0',
      },
      _hover: {
        _disabled: {
          color: 'neutral.30',
        },
        bg: 'neutral.10',
        border: '0',
      },
      bg: 'neutral.5',
      border: '0',
      color: 'primary',
      fontWeight: 700,
      outline: '0',
    }),
    outline: props => ({
      ...variants?.outline(props),
      _active: {
        bg: 'neutral.90',
        borderColor: 'neutral.90',
        color: 'white',
      },
      _disabled: {
        borderColor: 'neutral.30',
        color: 'neutral.30',
      },
      _focus: { bg: 'primary', borderColor: 'primary', color: 'white' },
      _hover: {
        _disabled: {
          borderColor: 'neutral.30',
          color: 'neutral.30',
        },
        bg: 'neutral.80',
        borderColor: 'neutral.80',
        color: 'white',
      },
      border: '2px solid',
      borderColor: 'primary',
      color: 'primary',
    }),
    red: props => ({
      ...variants?.solid(props),
      _active: {
        bg: 'red.60',
      },
      _disabled: {
        bg: 'neutral.30',
      },
      _hover: {
        _disabled: {
          bg: 'neutral.30',
        },
        bg: 'red.40',
      },
      bg: 'red.50',
    }),
    solid: props => ({
      ...variants?.solid(props),
      _active: {
        bg: 'neutral.90',
      },
      _disabled: {
        bg: 'neutral.30',
      },
      _hover: {
        _disabled: {
          bg: 'neutral.30',
        },
        bg: 'neutral.80',
      },
      bg: 'primary',
      color: 'white',
    }),
    success: props => ({
      ...variants?.outline(props),
      _active: {
        bg: 'green.80',
        borderColor: 'green.80',
        color: 'white',
      },
      _disabled: {
        borderColor: 'neutral.30',
        color: 'neutral.30',
      },
      _focus: {
        borderColor: 'green.70',
      },
      _hover: {
        _disabled: {
          borderColor: 'neutral.30',
          color: 'neutral.30',
        },
        bg: 'green.70',
        color: 'white',
      },
      border: '1px solid',
      borderColor: 'green.70',
      color: 'green.70',
    }),
    text: props => ({
      ...variants?.link(props),
      _active: {
        color: props.color || 'primary',
      },
      _disabled: {
        color: 'neutral.30',
      },
      _focus: {
        border: 0,
        boxShadow: 'none',
        outline: '0px',
        textDecoration: 'underline',
        textDecorationColor: props.color || 'primary',
      },
      _hover: {
        color: props.color || 'primary',
      },
      color: 'primary',
      fontWeight: 400,
    }),
  },
}
