import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrademark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.585 9.8v.786h1.248V14h.96v-3.414h1.242V9.8zm6.658 1.938L13.241 9.8h-1.152V14h.948v-2.838l1.188 2.238h.006l1.236-2.262V14h.948V9.8h-1.152zM22 12c0 5.514-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2c2.398 0 4.6.85 6.324 2.262l-1.427 1.427A7.94 7.94 0 0 0 12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8a7.95 7.95 0 0 0-1.688-4.898l1.427-1.427A9.96 9.96 0 0 1 22 12"
      fill="currentColor"
    />
  </svg>
)
export default SvgTrademark
