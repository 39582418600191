import type { Translations } from '@brenntag/connect-translations'

import en from '@brenntag/connect-translations/languages/en'

const modules: Record<string, () => Promise<{ default: Translations }>> = {
  en: () => import('@brenntag/connect-translations/languages/en'),
}

export const defaultTranslation = { languageCode: 'en', translations: en as Translations }

export const getTranslationForLanguage = (language: string): Promise<Translations> => {
  if (!modules[language]) {
    return Promise.resolve(defaultTranslation.translations)
  }
  return modules[language]().then(module => module.default)
}
