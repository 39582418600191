import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUserViewer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.5 2.5 0 0 0 10 3.991a2.503 2.503 0 0 0-2.5 2.5c0 1.379 1.122 2.5 2.5 2.5s2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5a4.505 4.505 0 0 1-4.5-4.5M5 18v-4h3v-2H3v8h8.528a6 6 0 0 1-1.033-2zm16.957 2.594-1.414 1.414-2.274-2.274a3.96 3.96 0 0 1-2.019.567c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4c0 .74-.215 1.424-.567 2.019zm-3.707-4.292c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.898 2-2"
      fill="currentColor"
    />
  </svg>
)
export default SvgUserViewer
