export const convertFiltersToParams = <T extends object>(
  productFilters: Record<keyof T, string>,
  filters: T,
): Record<string, unknown> => {
  const filterNames = Object.keys(filters) as (keyof T)[]
  return filterNames.reduce(
    (result, filterName) => {
      const paramName = productFilters[filterName]

      if (paramName === undefined || paramName === null) {
        throw new Error(`Unknown filter name: ${String(filterName)}`)
      }
      const filterValue = filters[filterName]

      if (filterValue === undefined || filterValue === null) return result

      let paramValue: string
      if (Array.isArray(filterValue)) {
        paramValue = filterValue.join(',')
      } else if (filterValue instanceof Date) {
        paramValue = filterValue.toISOString()
      } else {
        paramValue = String(filterValue)
      }

      return {
        ...result,
        [paramName]: paramValue,
      }
    },
    {} as Record<string, unknown>,
  )
}
