import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChangeOrder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m7 4.41 3.29 3.3 1.42-1.42L7 1.59l-4.71 4.7 1.42 1.42zM12.29 17.71l4.71 4.7 4.71-4.7-1.42-1.42-3.29 3.3-3.29-3.3z"
      fill="currentColor"
    />
    <path d="m17 16.76 1-1V3h-2v12.76zM6 8.24l1-1 1 1V21H6z" fill="currentColor" />
  </svg>
)
export default SvgChangeOrder
