import { StyleFunctionProps } from '@chakra-ui/react'
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getOutlineColor = (props: Record<string, any>) => {
  const { colorScheme: c, theme } = props
  const darkColor = transparentize(`${c}.200`, 0.8)(theme)
  const lightColor = getColor(theme, `${c}.500`)
  return mode(lightColor, darkColor)(props)
}

export const IconBadge = {
  baseStyle: (props: StyleFunctionProps) => {
    const color = getOutlineColor(props)
    return {
      badge: {
        alignItems: 'center',
        borderRadius: 'md',
        boxShadow: `inset 0 0 0px 1px ${color}`,
        display: 'inline-flex',
        fontWeight: 'normal',
        whiteSpace: 'nowrap',
      },
      icon: {
        bg: color,
        borderBottomLeftRadius: 'md',
        borderTopLeftRadius: 'md',
        color: mode(`white`, `whiteAlpha.800`)(props),
        textAlign: 'center',
        width: '1.5em',
      },
      label: {
        color,
        fontSize: 'xs',
        px: '2',
        py: '1',
      },
    }
  },
  defaultProps: {
    colorScheme: 'gray',
    variant: 'regular',
  },
  variants: {
    iconOnly: {
      icon: {
        borderRadius: 'md',
      },
      label: {
        display: 'none',
      },
    },
  },
}
