import { SingleIncludedProductResourceWithPricebookProductUnion } from '@brenntag/connect-apis/api/types'
import { buildPricePoint } from '@brenntag/legacy-mock-generators/generators/buildPricePoint.js'
import { buildPricebookProduct } from '@brenntag/legacy-mock-generators/generators/products/buildPricebookProduct.js'
import { buildProduct } from '@brenntag/legacy-mock-generators/generators/products/buildProduct.js'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'
import { pricebooks } from '#src/mocks/apis/data/pricebooks.js'

const id = 'onlyForShowPricesLocation'

export const onlyForShowPricesLocation: SingleIncludedProductResourceWithPricebookProductUnion =
  buildProduct({
    attributes: {
      name: `Only for ${locations.showPrices.name}`,
    },
    id,
    included: {
      pricebook: pricebooks.onlyShowPricesLocation,
      pricebookProduct: buildPricebookProduct({
        id,
        pricebookId: pricebooks.onlyShowPricesLocation.id,
        pricePointIds: ['pricePoint'],
        productId: id,
      }),
      pricePoints: [
        buildPricePoint({
          id: 'pricePoint',
          price: 10,
          relationships: { pricebookProductId: id },
        }),
      ],
    },
  })
