import { salesforceSession } from '../salesforceSession'
import { acquireTokens } from './acquireTokens'
import { createAuthorizeUrl } from './createAuthorizeUrl'

/**
 * Uses a hidden iframe that calls the Salesforce authorize endpoint to start a Salesforce session.
 * If the session is already started, it will return the existing tokens.
 * If the session has expired, it will start a new session.
 * If the session is about to expire, it will refresh the session.
 * @param params - The state object. You can pass any data you want to be returned in the state parameter when the user is redirected back to your app.
 * @returns Resolves when the Salesforce tokens have been acquired.
 */
export const getAccessTokenSilently = async (state: Record<string, unknown>): Promise<void> => {
  if (!salesforceSession.hasValidSession()) {
    const authUrl = createAuthorizeUrl(state)
    acquireTokens(authUrl).catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error starting Salesforce session:', error)
      return Promise.reject(error)
    })
  }

  return Promise.resolve()
}
