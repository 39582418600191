import React from 'react'

import { Box, HStack, Text, TextProps } from '@chakra-ui/react'

interface HelperProps extends TextProps {
  error?: boolean
  inverted?: boolean
  success?: boolean
}

const HelperText: React.FC<HelperProps> = ({ error, inverted, success, ...props }) => {
  const primaryColor = error ? 'red.70' : success ? 'green.50' : undefined
  const bgColor = primaryColor && (inverted ? primaryColor : 'transparent')
  const color = primaryColor && (inverted ? 'white' : primaryColor)
  return (
    <HStack alignItems="center" spacing={1}>
      <Text
        as="span"
        bgColor={bgColor}
        color={color}
        fontSize="sm"
        px={inverted ? 1 : 0}
        {...props}
      />
    </HStack>
  )
}

export const Helper: React.FC<HelperProps> = props => (
  <Box minW="100%" mt={1} width="min-content">
    <HelperText {...props} />
  </Box>
)

Helper.displayName = 'Helper'
