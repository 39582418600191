import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCalendarConfirmed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M21 6V4h-3V2h-2v2H8V2H6v2H3v2zM21 21H3V8h18v2H5v9h14v-7h2z" fill="currentColor" />
    <path
      d="m15.457 12.707-4.207 4.207-2.707-2.707 1.414-1.414 1.293 1.293 2.793-2.793z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCalendarConfirmed
