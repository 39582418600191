import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19 17h2v-4.41l-4-4V7c0-2.76-2.24-5-5-5S7 4.24 7 7v1.59l-4 4V17h14v-2H5v-1.59l4-4V7c0-1.65 1.35-3 3-3s3 1.35 3 3v2.41l4 4zM8 19c0 2.21 1.79 4 4 4s4-1.79 4-4h-2c0 1.1-.9 2-2 2s-2-.9-2-2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgNotification
