import React from 'react'

import { RouteObject } from 'react-router'

import { PrivateLayout } from '#shared/layouts/PrivateLayout.js'
import { NotFound } from '#shared/pages/NotFound/index.js'
import { withRouteErrorBoundary } from '#shared/routing/withRouteErrorBoundary.js'

export const privateRoutes: RouteObject = {
  children: [
    withRouteErrorBoundary([
      { lazy: () => import('#modules/cart-v2/containers/cart/Cart.js'), path: 'cartv2' },
      { lazy: () => import('#shared/pages/Cart/EnsureEcommContextWrapper.js'), path: 'cart' },
      {
        lazy: () => import('#shared/pages/Dashboard/EnsureEcommContextWrapper.js'),
        path: 'dashboard',
      },
      { lazy: () => import('#shared/pages/Profile/ProfileView.js'), path: 'profile' },
      {
        children: [
          {
            index: true,
            lazy: () => import('#shared/pages/ProductList/EnsureEcommContextWrapper.js'),
          },
          {
            lazy: () => import('#shared/pages/ProductDetails/EnsureEcommContextWrapper.js'),
            path: ':productId',
          },
        ],
        path: 'products',
      },
      {
        children: [
          {
            index: true,
            lazy: () => import('#shared/pages/InvoiceList/EnsureEcommContextWrapper.js'),
          },
          {
            lazy: () => import('#shared/pages/InvoiceDetails/EnsureEcommContextWrapper.js'),
            path: ':invoiceId',
          },
        ],
        path: 'invoices',
      },
      {
        children: [
          { index: true, lazy: () => import('#shared/pages/Cases/CaseList/CaseListPage.js') },
          {
            lazy: () => import('#shared/pages/Cases/CaseDetails/CaseDetailsView.js'),
            path: ':caseId',
          },
        ],
        path: 'cases',
      },
      {
        lazy: () => import('#shared/pages/DocumentList/EnsureEcommContextWrapper.js'),
        path: 'documents',
      },
      {
        children: [
          {
            index: true,
            lazy: () => import('#shared/pages/OrderList/EnsureEcommContextWrapper.js'),
          },
          {
            lazy: () => import('#shared/pages/OrderDetails/EnsureEcommContextWrapper.js'),
            path: ':orderId',
          },
        ],
        path: 'orders',
      },
      {
        lazy: () => import('#shared/pages/FavoriteList/EnsureEcommContextWrapper.js'),
        path: 'favorites',
      },
      {
        children: [
          { index: true, lazy: () => import('#shared/pages/ServiceList/ServiceListPage.js') },
          {
            lazy: () => import('#shared/pages/ServiceDetails/ServiceDetailsView.js'),
            path: ':serviceId',
          },
        ],
        path: 'services',
      },
      {
        lazy: () => import('#shared/pages/SampleList/EnsureEcommContextWrapper.js'),
        path: 'samples',
      },
      {
        children: [
          {
            index: true,
            lazy: () => import('#shared/pages/QuoteList/EnsureEcommContextWrapper.js'),
          },
          {
            lazy: () => import('#shared/pages/QuoteDetails/EnsureEcommContextWrapper.js'),
            path: ':quoteId',
          },
        ],
        path: 'quotes',
      },
      {
        lazy: () => import('#shared/pages/OrderConfirmation/EnsureEcommContextWrapper.js'),
        path: 'checkout/confirmed/:orderId',
      },
    ]),
    { Component: NotFound, path: '*' },
  ],
  element: <PrivateLayout />,
}
