import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconKey = createIcon({
  displayName: 'IconKey',
  path: [
    <path
      d="M2 22H9V20H11V18H13V15.41L13.41 15H20.41L22 13.41V9H20V12.59L19.59 13H12.59L11 14.59V16H9V18H7V20H4V16.41L11 9.41V4.41L11.41 4H19.59L20 4.41V7H22V3.59L20.41 2H10.59L9 3.59V8.59L2 15.59V22Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
