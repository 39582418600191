import React, { FC } from 'react'

import { useCSSLink } from '#shared/hooks/dom/useCSSLink.js'
import { useScript } from '#shared/hooks/dom/useScript.js'
import { config } from '#src/config.js'

const LoadChat: FC = () => {
  window.chat = {
    config: config.chat,
  }

  useCSSLink(`/assets/chat/chat.css?v=${__COMMIT_HASH__}`)
  useScript(`/assets/chat/chat.js?v=${__COMMIT_HASH__}`)
  return null
}

export const Chat: FC = () => {
  if (!config.chatEnabled) return null

  return <LoadChat />
}
