import React from 'react'

interface Props {
  children: React.ReactNode
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="container relative mx-auto flex size-full grow flex-col p-4 lg:px-8 lg:pb-16 lg:pt-10">
      {children}
    </div>
  )
}
