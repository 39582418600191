import { faker } from '@faker-js/faker'
import { merge, range, uniqBy } from 'lodash'

import { MeResponse } from '@brenntag/connect-apis/api-user/types'
import {
  AttachmentResource,
  DataTypeLocation,
  DataTypeQuote,
  DataTypeQuoteLine,
  DataTypeUser,
  IncoTermsType,
  LocationResource,
  LocationType,
  QuoteAttributes,
  QuoteLineResource,
  QuoteStatus,
  SingleIncludedProductResourceWithPricebookProductUnion,
  SingleIncludedQuoteResourceUnion,
} from '@brenntag/connect-apis/api/types'

import { buildAttachment } from '../buildAttachment'
import { buildLocation } from '../buildLocation'
import { buildUser } from '../buildUser'
import { buildPricebookProduct } from '../products/buildPricebookProduct'
import { buildProduct } from '../products/buildProduct'
import { buildQuoteLine } from './buildQuoteLine'

interface Props {
  attributes?: Partial<QuoteAttributes>
  id?: string
  included?: {
    attachments?: AttachmentResource[] | null
    shipTo?: LocationResource
    user?: MeResponse | null
  } & (
    | {
        products?: never
        quoteLines?: [QuoteLineResource, SingleIncludedProductResourceWithPricebookProductUnion][]
      }
    | {
        products?: SingleIncludedProductResourceWithPricebookProductUnion[]
        quoteLines?: never
      }
  )
  numberOfQuoteLines?: number
}

// eslint-disable-next-line complexity
export function buildQuote({
  attributes,
  id,
  included,
  numberOfQuoteLines,
}: Props = {}): SingleIncludedQuoteResourceUnion {
  const quoteId = id ?? faker.string.uuid()

  const shipTo =
    included?.shipTo === undefined
      ? buildLocation({ attributes: { type: LocationType.ShipTo } })
      : included?.shipTo

  const user =
    included?.user === undefined ? buildUser() : included?.user ? included.user : undefined

  const products =
    included?.products ??
    included?.quoteLines?.map(([_, product]) => product) ??
    range(0, numberOfQuoteLines ?? 0).map(() => buildProduct())

  const quoteLines =
    included?.quoteLines?.map(([quoteLine]) => quoteLine) ??
    range(0, numberOfQuoteLines ?? products.length ?? 0).map((_, index) =>
      buildQuoteLine({
        included: {
          pricebookProduct: faker.datatype.boolean()
            ? products[index].data.relationships.pricebookProduct
            : {
                data: buildPricebookProduct({
                  pricebookId: faker.string.uuid(),
                  pricePointIds: [],
                  productId: products[index].data.id,
                }),
              },
          product: products[index].data,
        },
        quoteId,
      }),
    )

  const attachments =
    included?.attachments ??
    (included?.attachments === null
      ? []
      : faker.helpers.multiple(() => buildAttachment(), { count: { max: 9, min: 0 } }))

  return {
    data: {
      attributes: merge(
        {
          commentFromUser: faker.lorem.paragraph(),
          createdAt: faker.date.past().toISOString(),
          incoTerms: faker.helpers.arrayElement(Object.values(IncoTermsType)),
          isActive: true,
          lastUpdateDate: faker.date.recent().toISOString(),
          number: faker.number.int().toString(),
          quoteCreationDate: faker.date.past().toISOString(),
          requestedDeliveryDate: faker.date.future().toISOString(),
          status: faker.helpers.arrayElement([
            QuoteStatus.Processing,
            QuoteStatus.Done,
            QuoteStatus.Declined,
          ]),
          updatedAt: faker.date.recent().toISOString(),
        } satisfies QuoteAttributes,
        attributes,
      ),
      id: quoteId,
      relationships: {
        attachments: {
          data: attachments.map(attachment => ({
            id: attachment.id,
            type: attachment.type,
          })),
        },
        quoteLines: {
          data: quoteLines.map(quoteLine => ({
            id: quoteLine.id,
            type: DataTypeQuoteLine.QuoteLine,
          })),
        },
        shipTo: shipTo && {
          data: {
            id: shipTo.id,
            type: DataTypeLocation.Location,
          },
        },
        user: user && { data: { id: user.data.id, type: DataTypeUser.User } },
      },
      type: DataTypeQuote.Quote,
    },
    included: uniqBy(
      [
        ...quoteLines,
        shipTo,
        ...attachments,
        user?.data,
        ...products.flatMap(product => product.data || []),
        ...products.flatMap(product => product.included || []),
      ] as SingleIncludedQuoteResourceUnion['included'],
      v => v.id,
    ),
  }
}
