import { atom } from 'jotai'

import { CartFilters } from '@brenntag/connect-apis/api-cart/models/cart'

const cartFilterDefaults: CartFilters = {
  page: '1',
  sort: '-cartAddedDate',
} as const

export const cartFilters = atom<CartFilters>(cartFilterDefaults)
