import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUndo = createIcon({
  displayName: 'IconUndo',
  path: [
    <path
      d="M1.59 9L8.29 15.71L9.71 14.29L4.41 9L9.71 3.71L8.29 2.29L1.59 9Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M7.24 9L8.24 10H15.5C17.98 10 20 12.02 20 14.5C20 16.98 17.98 19 15.5 19H7V21H15.5C19.08 21 22 18.08 22 14.5C22 10.92 19.08 8 15.5 8H8.24L7.24 9Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
