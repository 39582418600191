import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChangeOrder = createIcon({
  displayName: 'IconChangeOrder',
  path: [
    <path
      d="M6.99906 4.40984L10.2891 7.70984L11.7091 6.28984L6.99906 1.58984L2.28906 6.28984L3.70906 7.70984L6.99906 4.40984Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M12.2891 17.71L16.9991 22.41L21.7091 17.71L20.2891 16.29L16.9991 19.59L13.7091 16.29L12.2891 17.71Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M17 16.76L18 15.76V3H16V15.76L17 16.76Z" fill="currentColor" key="2" />,
    <path d="M6 8.24023L7 7.24023L8 8.24023V21.0002H6V8.24023Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
