import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChat = createIcon({
  displayName: 'IconChat',
  path: [
    <path
      d="M12 22.41L8.59 19H3V3H21V5H5V17H9.41L12 19.59L14.59 17H19V7H21V19H15.41L12 22.41Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
