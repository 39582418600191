import * as React from 'react'
import type { SVGProps } from 'react'
const SvgParcelCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m14 4.72-2-.67-4.18 1.4-3.16-1.06L12 1.95l2 .66zM18.5 7.91l-2.71-2.7 1.42-1.42 1.29 1.3 2.79-2.8 1.42 1.42z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M20 10v7.28l-7 2.33v-8.89l3-1V7.61l-4 1.33L2 5.61v13.11l10 3.33 10-3.33V10zM4 8.39l7 2.33v8.89l-7-2.33z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgParcelCheck
