import { authConfig } from '../config'

/**
 * Redirects the user to the Salesforce login page for internal users.
 * @param state - Any object that will be passed back to the app after login.
 */
export const loginWithSalesforceInternal = (state: Record<string, unknown>) => {
  const stateStr = JSON.stringify(state)

  const searchParams = new URLSearchParams({
    client_id: authConfig.salesforce.clientId,
    prompt: authConfig.salesforce.additionalParameters?.prompt || 'none',
    redirect_uri: `${window.location.origin}/login-handler`,
    response_type: 'code',
    scope: 'openid profile email api',
    state: stateStr,
  })

  const sfUrl = `https://${authConfig.salesforce.internalDomain}/services/oauth2/authorize`

  window.location.href = `${sfUrl}?${searchParams.toString()}`
}
