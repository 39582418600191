import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSquareAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M3 3h18v2H5v14h14V7h2v14H3z" fill="currentColor" />
    <path d="M13 13v4h-2v-4H7v-2h4V7h2v4h4v2z" fill="currentColor" />
  </svg>
)
export default SvgSquareAdd
