import { MonetaryAmount } from '@brenntag/connect-apis/api/models/price'

export const transformMonetaryAmount = (
  amount: number | undefined,
  currencyIsoCode: string | undefined,
): MonetaryAmount | undefined =>
  amount !== undefined && currencyIsoCode !== undefined
    ? {
        amount,
        currency: currencyIsoCode,
      }
    : undefined
