import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m14.88 6.53 1.43-1.43A7.92 7.92 0 0 1 18 10c0 4.41-3.59 8-8 8s-8-3.59-8-8 3.59-8 7.99-8.01c1.85 0 3.54.64 4.9 1.69l-1.43 1.43A6.03 6.03 0 0 0 9.99 4c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6c0-1.29-.41-2.49-1.11-3.47M16.33 17.73c.51-.42.99-.89 1.41-1.41l3.97 3.97-1.41 1.41z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSearch
