import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeCustomerContext,
  DataTypeLocation,
  LocationAttributes,
  LocationResource,
  LocationType,
  SingleIncludedCustomerContextResourceUnion,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<LocationAttributes>
  id?: string
  relationships?: {
    customerContexts?: SingleIncludedCustomerContextResourceUnion[]
  }
}

export const buildLocation = ({
  attributes,
  id = faker.string.uuid(),
  relationships,
}: Props = {}): LocationResource => {
  return {
    attributes: merge(
      {
        address: {
          city: faker.location.city(),
          country: faker.location.country(),
          postalCode: faker.location.zipCode(),
          street: faker.location.street(),
        },
        createdAt: faker.date.past().toISOString(),
        hidePrices: false,
        isActive: true,
        name: faker.company.name(),
        partnerNumber: faker.number.int(1000000).toString(),
        type: LocationType.ShipTo,
        updatedAt: faker.date.recent().toISOString(),
      } satisfies LocationAttributes,
      attributes,
    ),
    id,
    relationships: {
      customerContexts: relationships?.customerContexts
        ? {
            data: relationships?.customerContexts.map(context => ({
              id: context.data.id,
              type: DataTypeCustomerContext.CustomerContext,
            })),
          }
        : undefined,
    },
    type: DataTypeLocation.Location,
  }
}
