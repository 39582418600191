import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSad = createIcon({
  displayName: 'IconSad',
  path: [
    <path
      d="M18.31 7.11L19.74 5.68C21.15 7.4 22 9.6 22 12C22 17.51 17.51 22 12 22C6.49 22 2 17.51 2 12C2 6.49 6.49 2 12.01 2C14.4 2 16.61 2.85 18.33 4.26L16.9 5.69C15.54 4.63 13.85 4 12 4C7.59 4 4 7.6 4 12.01C4 16.42 7.59 20.01 12 20.01C16.41 20.01 20 16.42 20 12.01C20 10.16 19.36 8.47 18.31 7.11Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M8.29 10.7099C8.48 10.8999 8.74 10.9999 9 10.9999C9.13 10.9999 9.26 10.9699 9.38 10.9199C9.5 10.8699 9.61 10.7999 9.71 10.7099C9.9 10.5199 10 10.2599 10 9.99987C10 9.73987 9.89 9.47987 9.71 9.28987C9.43 9.00987 8.98 8.91987 8.62 9.07987C8.49 9.12987 8.39 9.19987 8.29 9.28987C8.11 9.47987 8 9.72987 8 9.99987C8 10.2699 8.1 10.5199 8.29 10.7099Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M15.2841 16.705L15.2891 16.71H15.2791L15.2841 16.705Z" fill="currentColor" key="2" />,
    <path
      d="M16.6991 15.3L15.2841 16.705L14.8291 16.25C13.2691 14.69 10.7291 14.69 9.16906 16.25L8.70906 16.71L7.28906 15.3L7.74906 14.84C10.0891 12.5 13.8991 12.5 16.2391 14.84L16.6991 15.3Z"
      fill="currentColor"
      key="3"
    />,
    <path
      d="M15 11C14.73 11 14.48 10.9 14.29 10.71C14.1 10.52 14 10.27 14 10C14 9.73001 14.11 9.48001 14.29 9.29001C14.3067 9.27668 14.3233 9.26225 14.34 9.24782C14.3733 9.21893 14.4067 9.19001 14.44 9.17001C14.5 9.13001 14.56 9.10001 14.62 9.08001C14.68 9.05001 14.74 9.03001 14.8 9.02001C15.13 8.96001 15.48 9.06001 15.71 9.29001C15.89 9.48001 16 9.73001 16 10C16 10.27 15.9 10.52 15.71 10.71C15.52 10.9 15.27 11 15 11Z"
      fill="currentColor"
      key="4"
    />,
  ],
  viewBox: '0 0 24 24',
})
