import { faker } from '@faker-js/faker'
import { merge, uniqBy } from 'lodash'

import {
  DataTypeDelivery,
  DataTypeInvoice,
  DataTypeLocation,
  DeliveryResource,
  InvoiceAttributes,
  InvoiceResource,
  InvoiceStatus,
  LocationResource,
  LocationType,
  SingleIncludedCustomerContextResourceUnion,
  SingleIncludedInvoiceResourceUnion,
  SingleIncludedOrderResourceUnion,
} from '@brenntag/connect-apis/api/types'

import { buildLocation } from '../buildLocation'
import { buildOrder } from '../orders/buildOrder'
import { buildDelivery } from './buildDelivery'

interface Props {
  attributes?: Partial<InvoiceAttributes>
  id?: string
  included?: {
    billTo?: LocationResource | null
    customerContext?: SingleIncludedCustomerContextResourceUnion
    delivery?: DeliveryResource | null
    order?: null | SingleIncludedOrderResourceUnion
    shipTo?: LocationResource | null
  }
}

export const buildInvoice = ({
  attributes,
  id = faker.string.uuid(),
  included,
}: Props = {}): SingleIncludedInvoiceResourceUnion => {
  const shipTo = !included?.shipTo
    ? buildLocation({
        attributes: { hidePrices: false, name: 'Ship-To Company', type: LocationType.ShipTo },
      })
    : included.shipTo

  const billTo = !included?.billTo
    ? buildLocation({
        attributes: { hidePrices: false, name: 'Bill-To Company', type: LocationType.BillTo },
      })
    : included.billTo

  const order =
    included?.order ||
    buildOrder({
      included: {
        billToLocation: billTo,
        shipToLocation: shipTo,
      },
    })

  const delivery =
    included?.delivery === undefined
      ? buildDelivery({
          relationships: {
            orderId: order.data.id,
          },
        })
      : included.delivery

  const invoice: InvoiceResource = {
    attributes: merge(
      {
        coaDocuments: faker.helpers.multiple(() => faker.word.words({ count: { max: 3, min: 1 } })),
        createdAt: faker.date.past().toISOString(),
        dueDate: faker.date.future().toISOString(),
        grossAmount: {
          amount: parseFloat(faker.finance.amount()),
          currency: 'USD',
        },
        invoiceDate: faker.date.past().toISOString(),
        isActive: true,
        isInvoiceAvailable: faker.datatype.boolean(),
        netAmount: {
          amount: parseFloat(faker.finance.amount()),
          currency: 'USD',
        },
        number: `${faker.number.int()}`,
        paidAmount: {
          amount: 0,
          currency: 'USD',
        },
        purchaseOrderNumber: `${faker.number.int()}`,
        status: faker.helpers.arrayElement([InvoiceStatus.Open, InvoiceStatus.Closed]),
        tax: {
          amount: parseFloat(faker.finance.amount()),
          currency: 'USD',
        },
        updatedAt: faker.date.past().toISOString(),
      },
      attributes,
    ),
    id,
    relationships: {
      delivery: delivery
        ? {
            data: {
              id: delivery.id,
              type: DataTypeDelivery.Delivery,
            },
          }
        : undefined,
      shipTo: {
        data: {
          id: shipTo.id,
          type: DataTypeLocation.Location,
        },
      },
    },
    type: DataTypeInvoice.Invoice,
  }

  return {
    data: invoice,
    included: uniqBy(
      [delivery, order.data, ...(order.included || []), shipTo, billTo].filter(Boolean),
      resource => resource?.id,
    ) as SingleIncludedInvoiceResourceUnion['included'],
  }
}
