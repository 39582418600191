/*
 * Don't add breakpoints to this list, as it will add entries to
 * the chakra array of breakpoints, meaning when we use the recommended
 * approach of using array notation to access the breakpoints, all
 * breakpoints smaller than the newly added one will be shifted,
 * potentially breaking the layout.
 * Also see: https://chakra-ui.com/docs/styled-system/responsive-styles#the-array-syntax
 */
export const breakpoints = {
  '2xl': '160rem', // 2560px
  lg: '90rem', // 1440px
  md: '64rem', // 1024px
  sm: '40rem', // 640px
  xl: '120rem', // 1920px
}
