import { ConnectLocation } from '@brenntag/api-customer'

import { webstore } from './webstores'

export const locations = {
  hidePrices: {
    address: {
      city: 'City',
      country: 'Country',
      postalCode: '12345',
      state: 'State',
      street: 'US Hide Prices',
    },
    contacts: [],
    id: 'us-hide-prices',
    name: 'US Hide Prices',
    number: '123456',
    showPrices: false,
    sourceBusShipToId: 'bus-us-hide-prices',
    type: 'ship-to',
    webstoreId: webstore.us.id,
  },

  showPrices: {
    address: {
      city: 'City',
      country: 'Country',
      postalCode: '12345',
      state: 'State',
      street: 'US Show Prices',
    },
    contacts: [
      {
        email: 'contact1@email.com',
        id: 'contact1',
        name: 'Contact 1',
        phone: '1234567890',
      },

      {
        email: 'contact2@email.com',
        id: 'contact2',
        name: 'Contact 2',
        phone: '1234567890',
      },
    ],
    id: 'us-show-prices',
    name: 'US Show Prices',
    number: '123456',
    showPrices: true,
    sourceBusShipToId: 'bus-us-show-prices',
    type: 'ship-to',
    webstoreId: webstore.us.id,
  },
} satisfies Record<string, ConnectLocation>
