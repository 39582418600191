import { consola } from 'consola/browser'

declare global {
  interface Window {
    msw: ReturnType<typeof setupWorker>
  }
}
import { createStore } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { setupWorker } from 'msw/browser'

import { isMockMode } from '#src/env.js'

import { clearAuth0Session, setAuth0Session } from './apis/auth/auth0'
import { clearSalesforceSession, setSalesforceSession } from './apis/auth/salesforce'
import { defaultHandlers } from './defaultHandlers'
import { scenarios } from './scenarios'

const mocksAtom = atomWithStorage<boolean>('useMocks', isMockMode, undefined, { getOnInit: true })
const store = createStore()

const scenarioName = new URLSearchParams(window.location.search).get('mockScenario')

const runtimeScenarios = scenarios?.[scenarioName ?? ''] || []

const worker = setupWorker()

const enableMocks = async () => {
  setAuth0Session()
  setSalesforceSession()
  const started = new Promise(resolve => {
    worker.use(...runtimeScenarios, ...defaultHandlers)

    worker
      .start({
        onUnhandledRequest: request => {
          if (request.url.startsWith('/node_modules/')) {
            return 'bypass'
          }
          return 'error'
        },
      })
      .then(() => {
        window.msw = worker

        resolve(worker)
      })
  })

  return await started
}

const disableMocks = () => {
  worker.stop()
  clearAuth0Session()
  clearSalesforceSession()
  return
}

export const toggleMocks = async () => {
  const isMockingEnabled = store.get(mocksAtom)

  if (isMockingEnabled) {
    disableMocks()
  } else {
    await enableMocks()
  }
  store.set(mocksAtom, !isMockingEnabled)
  return Promise.resolve()
}

export const setup = async () => {
  if (store.get(mocksAtom) || isMockMode) {
    return await enableMocks().catch(consola.error)
  }
  return Promise.resolve()
}
const keyboardShortcut = (event: KeyboardEvent) => {
  // Toggle mocks with Ctrl + Shift + U
  const combo = event.ctrlKey && event.shiftKey && event.key === 'U'

  if (!combo) {
    return
  }

  toggleMocks().catch(consola.error)
}

document.addEventListener('keydown', keyboardShortcut)
