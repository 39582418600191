import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTrendingUp = createIcon({
  displayName: 'IconTrendingUp',
  path: [
    <path d="M20 14H22V6H14V8H20V14Z" fill="currentColor" key="0" />,
    <path
      d="M16.5891 9.99984H17.9991V11.4098L12.9991 16.4098L8.49906 11.9098L2.70906 17.7098L1.28906 16.2898L8.49906 9.08984L12.9991 13.5898L16.5891 9.99984Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
