import { useCallback } from 'react'

import { SEARCH_MIN_CHARACTERS } from '#shared/constants.js'
import { useSearchQuery } from '#shared/hooks/search/useSearchQuery.js'

export const useSearchInput = () => {
  const { query, resetSearchQuery, updateSearchQuery } = useSearchQuery()

  const updateSearchInput = useCallback(
    (value: string) => {
      if (value.length >= SEARCH_MIN_CHARACTERS) {
        updateSearchQuery(value)
      } else {
        resetSearchQuery()
      }
    },
    [resetSearchQuery, updateSearchQuery],
  )

  return { input: query, updateSearchInput }
}
