import { http, HttpResponse } from 'msw'

import { config } from '#src/config.js'

import { favoriteLists } from '../../api/favorites/data'

export const deleteFavoriteList = () => {
  return http.delete<{ id: string }, null, null>(
    `${config.apiBaseUrl}/api-object/favorites/:id`,
    async ({ params }) => {
      const listId = params.id as keyof typeof favoriteLists.items

      if (!favoriteLists.items[listId]) {
        return HttpResponse.json(null, { status: 404 })
      }

      delete favoriteLists.items[listId]

      return HttpResponse.json(null)
    },
  )
}
