import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconOrderUp = createIcon({
  displayName: 'IconOrderUp',
  path: [
    <path
      d="M11.71 4.40984L15 7.70984L16.42 6.28984L11.71 1.58984L7 6.28984L8.42 7.70984L11.71 4.40984Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M10.7109 8.24023L11.7109 7.24023L12.7109 8.24023V21.0002H10.7109V8.24023Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
