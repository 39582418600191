import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFileXLS = createIcon({
  displayName: 'IconFileXLS',
  path: [
    <path
      d="M20 22H4V2H11V4H6V20H18V9H13V2H15.41L20 6.59V22ZM15 7H17.59L15 4.41V7Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M7.01697 17C7.27297 16.2847 7.5892 15.5977 7.96567 14.9388L7.06214 13.0471H8.08991L8.77885 14.713H8.54167C8.68097 14.457 8.80332 14.201 8.90873 13.945C9.01414 13.6852 9.12144 13.4028 9.23062 13.0979C9.23062 13.0904 9.2325 13.0828 9.23626 13.0753C9.24003 13.064 9.24379 13.0546 9.24756 13.0471H10.2414C10.0118 13.6871 9.7125 14.3177 9.34356 14.9388L10.2979 17H9.28144L8.53038 15.1873H8.7732C8.62638 15.4734 8.49273 15.7633 8.37226 16.057C8.25556 16.3506 8.13697 16.665 8.0165 17H7.01697Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M10.9187 17V13.0471H11.8166V16.633L11.4496 16.2602H13.2114V17H10.9187Z"
      fill="currentColor"
      key="2"
    />,
    <path
      d="M15.1047 17.0565C14.8035 17.0565 14.5456 17.0075 14.3311 16.9097C14.1202 16.808 13.9565 16.6518 13.8398 16.441C13.7231 16.2301 13.6609 15.9628 13.6534 15.6391H14.54C14.5588 15.8838 14.6172 16.0645 14.7151 16.1812C14.8167 16.2979 14.9598 16.3562 15.1442 16.3562C15.2986 16.3562 15.4134 16.3224 15.4887 16.2546C15.5678 16.1831 15.6073 16.0871 15.6073 15.9666C15.6073 15.8574 15.5753 15.769 15.5113 15.7012C15.4473 15.6334 15.3682 15.5807 15.2741 15.5431C15.18 15.5017 15.0464 15.4565 14.8732 15.4075C14.6247 15.3398 14.4176 15.2682 14.252 15.193C14.0901 15.1177 13.9508 14.9991 13.8341 14.8372C13.7212 14.6753 13.6647 14.4588 13.6647 14.1878C13.6647 13.9393 13.7155 13.7266 13.8172 13.5497C13.9226 13.369 14.0769 13.2315 14.2802 13.1374C14.4873 13.0395 14.7358 12.9906 15.0256 12.9906C15.4548 12.9906 15.7861 13.1073 16.0195 13.3407C16.2567 13.5741 16.3753 13.913 16.3753 14.3572H15.4944C15.4906 14.1238 15.4473 13.9544 15.3645 13.849C15.2816 13.7398 15.1574 13.6852 14.9918 13.6852C14.8449 13.6852 14.7301 13.7191 14.6473 13.7868C14.5682 13.8508 14.5287 13.9487 14.5287 14.0805C14.5287 14.1972 14.5607 14.2932 14.6247 14.3685C14.6925 14.44 14.7753 14.4965 14.8732 14.5379C14.9711 14.5755 15.1066 14.617 15.2798 14.6621C15.5245 14.7299 15.724 14.7995 15.8784 14.8711C16.0365 14.9426 16.1701 15.0574 16.2793 15.2155C16.3922 15.3737 16.4487 15.5882 16.4487 15.8593C16.4487 16.2395 16.3339 16.5351 16.1042 16.7459C15.8746 16.953 15.5414 17.0565 15.1047 17.0565Z"
      fill="currentColor"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
