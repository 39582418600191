import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m12 4.41 7.29 7.3 1.42-1.42L12 1.59l-8.71 8.7 1.42 1.42z" fill="currentColor" />
    <path d="m11 8.24 1-1 1 1V22h-2z" fill="currentColor" />
  </svg>
)
export default SvgArrowUp
