import { http, HttpResponse } from 'msw'

import { FavoriteList } from '@brenntag/connect-apis/api/models/favorite'

import { config } from '#src/config.js'

import { favoriteLists } from '../../api/favorites/data'

export const patchFavoriteList = () => {
  return http.patch<{ id: string }, { name: string }, FavoriteList>(
    `${config.apiBaseUrl}/api-object/favorites/:id`,
    async ({ params, request }) => {
      const listId = params.id as keyof typeof favoriteLists.items
      if (!favoriteLists.items[listId]) {
        return HttpResponse.json(null, { status: 404 })
      }

      const body = await request.json()
      favoriteLists.items[listId].data.attributes.name = body.name

      return HttpResponse.json({
        // TODO: this type for the response makes no sense, we should fix it
        favoriteItems: (favoriteLists.items[listId].included ?? [])?.map(item => ({
          createdAt: undefined,
          favoriteList: { favoriteItems: [], id: listId, name: body.name },
          id: item.id,
          updatedAt: undefined,
        })),
        id: params.id,
        name: body.name,
      })
    },
  )
}
