import { atom } from 'jotai'

import { CaseFilters } from '@brenntag/connect-apis/api-case/models/case'

const caseFilterDefaults: CaseFilters = {
  page: '1',
  sort: '-createdDate',
} as const

export const caseFilters = atom<CaseFilters>(caseFilterDefaults)
