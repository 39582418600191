import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRepeat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m16.29 9.29 1.42 1.42L22.41 6l-4.7-4.71-1.42 1.42L19.59 6zM1.59 18l4.7 4.71 1.42-1.42L4.41 18l3.3-3.29-1.42-1.42z"
      fill="currentColor"
    />
    <path d="M18.998 13v4H8.238l-1 1 1 1h12.76v-6zM15.76 5l1 1-1 1H5v4H3V5z" fill="currentColor" />
  </svg>
)
export default SvgRepeat
