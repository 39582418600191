import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVolumeLow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16 21.41 10.59 16H6V8h4.59L16 2.59V11h-2V7.41L11.41 10H8v4h3.41L14 16.59V13h2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgVolumeLow
