import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconLicensePlate = createIcon({
  displayName: 'IconLicensePlate',
  path: [
    <path d="M23 19H1V5H23V7H3V17H21V9H23V19Z" fill="currentColor" key="0" />,
    <path
      d="M7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12Z"
      fill="currentColor"
      key="2"
    />,
    <path d="M9 11H15V13H9V11Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
