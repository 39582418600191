import { Attachment } from '#api-object/models/attachment.js'

import { CustomerBillToLocation, CustomerShipToLocation } from './location'
import { Amount, MonetaryAmount } from './price'
import { Product } from './product'

export const OrderFilter = {
  deliveryDateGreaterEqual: 'filter[requestedDeliveryDate.gte]',
  deliveryDateLessEqual: 'filter[requestedDeliveryDate.lte]',
  myOrders: 'filter[user.id.equals]',
  orderDateGreaterEqual: 'filter[orderCreationDate.gte]',
  orderDateLessEqual: 'filter[orderCreationDate.lte]',
  salesOrderNumberExists: 'filter[salesOrderNumber.exists]',
  searchQuery: 'search[query]',
  shipToLocationId: 'filter[shipTo.id.equals]',
  sort: 'sort',
  status: 'filter[status.in]',
  updatedDateGreaterEqual: 'filter[lastUpdateDate.gte]',
  updatedDateLessEqual: 'filter[lastUpdateDate.lte]',
}

export type OrderFilterKey = keyof OrderFilters

export interface OrderFilters {
  deliveryDateGreaterEqual?: string
  deliveryDateLessEqual?: string
  myOrders?: string
  orderDateGreaterEqual?: string
  orderDateLessEqual?: string
  page?: string
  salesOrderNumberExists?: string
  searchQuery?: string
  shipToLocationId?: string
  sort?: string
  status?: string
  updatedDateGreaterEqual?: string
  updatedDateLessEqual?: string
}

const orderStatuses = {
  Confirmed: 'Confirmed',
  DeliveredInvoiced: 'Delivered/Invoiced',
  Entered: 'Entered',
  Picked: 'Picked',
  Processing: 'Processing',
} as const

export type OrderStatus = (typeof orderStatuses)[keyof typeof orderStatuses]

const orderStatusSequence: OrderStatus[] = [
  orderStatuses.Entered,
  orderStatuses.Processing,
  orderStatuses.Picked,
  orderStatuses.Confirmed,
  orderStatuses.DeliveredInvoiced,
]

export const isOrderStatusBefore = (status: OrderStatus, before: OrderStatus) => {
  return orderStatusSequence.indexOf(status) < orderStatusSequence.indexOf(before)
}

export const isOrderStatusBeforeOrEqual = (status: OrderStatus, before: OrderStatus) => {
  return orderStatusSequence.indexOf(status) <= orderStatusSequence.indexOf(before)
}

export const isOrderStatusAfterOrEqual = (status: OrderStatus, after: OrderStatus) => {
  return orderStatusSequence.indexOf(status) >= orderStatusSequence.indexOf(after)
}

export interface Order {
  attachments?: Attachment[]
  billTo: CustomerBillToLocation | undefined
  commentFromUser?: string
  customerId: string
  /* The api provides originally an array with one item. In this iterface it's a scalar property  */
  delivery?: {
    deliveryDate: string
    number: string
  }
  /* computed */
  id: string
  isDocumentAvailable: boolean
  /* computed */
  lastUpdateDate?: string
  /* computed */
  orderCreationDate?: string
  orderLines: OrderLine[]
  paymentTerms?: string
  purchaseOrderNumber?: string
  requestedDeliveryDate?: string
  salesOrderNumber?: string
  shippingInstructions?: string
  shipTo?: CustomerShipToLocation
  status: OrderStatus
  surcharges: OrderSurcharges
  totalValue?: MonetaryAmount
  user?: { name?: string }
}

export interface OrderLine {
  /* computed */
  canLink: boolean
  /* computed */
  canReorder: boolean
  id: string
  orderQuantity?: number
  pricingUnit?: Amount
  pricingUnitValue?: MonetaryAmount
  product: Product
  quantityCalculated?: number
  shipToLocationId?: string
  status: OrderStatus
  subtotalValue?: MonetaryAmount
}

export interface OrderList {
  orders: Order[]
  total: number
  totalPages: number
}

export interface OrderSurcharges {
  cleaningCharge?: MonetaryAmount
  containerDeposit?: MonetaryAmount
  freightCharges?: MonetaryAmount
  fuelSurcharge?: MonetaryAmount
  insuranceSurcharge?: MonetaryAmount
  minimumOrderSurcharge?: MonetaryAmount
  otherAdditionalCharges?: MonetaryAmount
  promotionCodeDiscount?: MonetaryAmount
  subTotal?: MonetaryAmount
  totalSalesTax?: MonetaryAmount
}

export interface OrderTracking {
  actualArrivalTime?: string
  estimatedArrivalTime?: string
}
