import { http, HttpResponse } from 'msw'

import { PatchLoggedInUserParams } from '#shared/apis/api-user/me/endpoints/patchLoggedInUser.js'
import { config } from '#src/config.js'

import { users } from './data/me'

export const patchMe = () => {
  return http.patch<never, PatchLoggedInUserParams, null>(
    `${config.apiBaseUrl}/api-user/me/update`,
    async ({ request }) => {
      const body = await request.json()

      if (body.email) {
        users.items.customer.data.attributes.email = body.email
      }

      if (body.languageCode) {
        users.items.customer.data.attributes.language = body.languageCode
      }

      if (body.locale) {
        users.items.customer.data.attributes.locale = body.locale
      }

      if (body.timezone) {
        users.items.customer.data.attributes.timezone = body.timezone
      }

      return HttpResponse.json(null, { status: 200 })
    },
  )
}
