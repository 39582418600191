import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m10 17.41-5.71-5.7 1.42-1.42 4.29 4.3 9.29-9.3 1.42 1.42z" fill="currentColor" />
  </svg>
)
export default SvgCheckmark
