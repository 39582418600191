import React from 'react'

import { createIcon } from '@chakra-ui/react'

export const IconHeartWithGradient = createIcon({
  displayName: 'IconHeartWithGradient',
  path: [
    <path
      d="M16.5 5C15.73 5 15.02 5.26 14.45 5.68C14.19 5.05 13.85 4.47 13.44 3.93C14.32 3.34 15.37 3 16.5 3C19.53 3 22 5.47 22 8.5V9C22 11.2 21.14 13.27 19.59 14.83L12 22.42L4.41 14.83C2.85 13.27 2 11.2 2 9V8.5C2 5.47 4.47 3 7.5 3C10.53 3 13 5.47 13 8.5V10H11V8.5C11 6.57 9.43 5 7.5 5C5.57 5 4 6.57 4 8.5V9C4 10.67 4.65 12.24 5.83 13.41L12 19.58L18.17 13.41C19.35 12.23 20 10.66 20 9V8.5C20 6.57 18.43 5 16.5 5Z"
      fill="url(#paint0_linear_1092_5215)"
      key={0}
    />,
    <defs key={1}>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1092_5215"
        x1="7"
        x2="42.99"
        y1="48"
        y2="12.01">
        <stop stopColor="#C815E0" />
        <stop offset="1" stopColor="#0C72ED" />
      </linearGradient>
    </defs>,
  ],
  viewBox: '0 0 24 24',
})
