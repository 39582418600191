import type { Customer } from '@brenntag/api-customer'

import { useLocalStorage } from '../dom/useLocalStorage'

interface UseSelectedCustomerResult {
  selectedCustomer: Customer | undefined
  setSelectedCustomer(customer: Customer | undefined): void
}

export const useSelectedCustomer = (): UseSelectedCustomerResult => {
  const [selectedCustomer, setSelectedCustomer] = useLocalStorage<Customer | undefined>({
    key: 'selectedCustomer',
    version: 1,
  })

  return {
    selectedCustomer,
    setSelectedCustomer,
  }
}
