import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAttachment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 22c-3.86 0-7-3.14-7-7V7c0-2.76 2.24-5 5-5s5 2.24 5 5v7c0 1.65-1.35 3-3 3s-3-1.35-3-3V9h2v5c0 .55.45 1 1 1s1-.45 1-1V7c0-1.65-1.35-3-3-3S7 5.35 7 7v8c0 2.76 2.24 5 5 5s5-2.24 5-5V3h2v12c0 3.86-3.14 7-7 7"
      fill="currentColor"
    />
  </svg>
)
export default SvgAttachment
