import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSmartphone = createIcon({
  displayName: 'IconSmartphone',
  path: [
    <path d="M13 22H19V2H5V22H11V20H7V4H17V20H13V22Z" fill="currentColor" key="0" />,
    <path
      d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
