import { APIContact } from '#api/apiModel.js'

export interface Contact {
  contactType?: string
  email?: string
  name?: string
  phone?: string
}

export const transformAPIContactToContact = (contact: APIContact): Contact => ({
  contactType: contact.contactType,
  email: contact.email,
  name: contact.name,
  phone: contact.phone,
})
