import { http, HttpResponse } from 'msw'

import { config } from '#config'

import { calculationPrices } from './data'

export default () => {
  const { getBaseUrl } = config

  return [
    http.get(`${getBaseUrl()}/products/:productId/next-calculation-price`, () => {
      const randomItem = calculationPrices[Math.floor(Math.random() * calculationPrices.length)]
      return HttpResponse.json({ data: randomItem })
    }),
  ]
}
