import tailwindAnimate from 'tailwindcss-animate'
import { default as defaultColors } from 'tailwindcss/colors'
import { PresetsConfig } from 'tailwindcss/types/config'

export const colors = {
  black: '#000000',
  blue: {
    5: '#F0F7FF',
    10: '#DCECFF',
    20: '#B4D5FE',
    30: '#84BBFC',
    40: '#4496F8',
    50: '#0C72ED',
    60: '#0651AD',
    70: '#04489B',
    80: '#023574',
    90: '#002757',
  },
  current: 'currentColor',
  gray: defaultColors.gray,
  green: {
    5: '#E9FBF5',
    10: '#C3F4E4',
    20: '#77E7C2',
    30: '#21CF95',
    40: '#13A977',
    50: '#0A875E',
    60: '#046142',
    70: '#02573A',
    80: '#013F2A',
    90: '#002F1F',
  },
  indigo: {
    5: '#FAF5FF',
    10: '#F2E5FF',
    20: '#E4C8FF',
    30: '#D1A3FF',
    40: '#BA73FF',
    50: '#A03FFF',
    60: '#7100E2',
    70: '#6600C9',
    80: '#4C0097',
    90: '#3A0073',
  },
  neutral: {
    5: '#F7F7F8',
    10: '#F0EEF1',
    20: '#D6D2DA',
    30: '#BDB3C7',
    40: '#9C8DAA',
    50: '#826F95',
    60: '#5E4C71',
    70: '#554466',
    80: '#3F2F4E',
    90: '#301F41',
  },
  primary: '#1A0033',
  red: {
    5: '#FFF0F1',
    10: '#FFDFE0',
    20: '#FFB2B5',
    30: '#FF8F93',
    40: '#FF5257',
    50: '#EE0028',
    60: '#AD001E',
    70: '#9C001B',
    80: '#740014',
    90: '#58000F',
  },
  tan: {
    5: '#FAF7F5',
    10: '#F2EDE7',
    20: '#DFD1C3',
    30: '#CAB39C',
    40: '#B08E6D',
    50: '#92704F',
    60: '#685038',
    70: '#5D4832',
    80: '#423324',
    90: '#2E2419',
  },
  transparent: 'transparent',
  violet: {
    5: '#FEF5FF',
    10: '#FCE3FF',
    20: '#F3C2F9',
    30: '#E99BF3',
    40: '#DA64E9',
    50: '#C815E0',
    60: '#920BA3',
    70: '#830892',
    80: '#62046E',
    90: '#4C0056',
  },
  white: '#FFFFFF',
  yellow: {
    5: '#FFF6E5',
    10: '#FFE8BA',
    20: '#FFCB64',
    30: '#FABB1C',
    40: '#FFB10A',
    50: '#F59D05',
    60: '#A55F03',
    70: '#784503',
    80: '#5F3602',
    90: '#481A00',
  },
}

export type SystemColorPalette = {
  5: string
  10: string
  20: string
  30: string
  40: string
  50: string
  60: string
  70: string
  80: string
  90: string
  DEFAULT: string
}

export type SystemColors = {
  danger: SystemColorPalette
  info: SystemColorPalette
  neutral: SystemColorPalette
  success: SystemColorPalette
  warning: SystemColorPalette
}

const systemColors: SystemColors = {
  danger: {
    ...colors.red,
    DEFAULT: colors.red[50],
  },
  info: {
    5: colors.blue[5],
    10: colors.blue[10],
    20: colors.blue[20],
    30: colors.blue[30],
    40: colors.blue[40],
    50: colors.blue[50],
    60: colors.blue[60],
    70: colors.blue[70],
    80: colors.blue[80],
    90: colors.blue[90],
    DEFAULT: colors.blue[50],
  },
  neutral: {
    DEFAULT: colors.neutral[50],
    ...colors.neutral,
  },
  success: {
    DEFAULT: colors.green[50],
    ...colors.green,
  },
  warning: {
    DEFAULT: colors.yellow[50],
    ...colors.yellow,
  },
} as const

const pictogramColorClasses = Object.keys(colors).reduce<string[]>((list, color) => {
  return [...list, `stroke-${color}-20`, `fill-${color}-70`, `fill-${color}-20`]
}, [])

const preset: PresetsConfig = {
  plugins: [tailwindAnimate],
  safelist: [...pictogramColorClasses, { pattern: /bg-(red|green|blue|yellow)-(50|80)/ }],
  theme: {
    colors: {
      ...colors,
      ...systemColors,
    },
    container: {
      screens: {
        '2xl': '1400px',
      },
    },
    extend: {
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        contentHide: 'contentHide 250ms ease-in-out',
        contentShow: 'contentShow 250ms ease-in-out',
        dropDownAndFade: 'dropDownAndFade 250ms ease-out forwards',
        overlayHide: 'overlayHide 250ms ease-in-out',
        overlayShow: 'overlayShow 250ms ease-in-out',
      },
      fontFamily: {
        sans: ['BrenntagSans', 'Arial', 'Helvetica Neue', 'Helvetica', 'system', 'sans-serif'],
      },
      fontSize: {
        '2xl': ['2rem', { letterSpacing: 'normal', lineHeight: '1' }],
        '3xl': ['2.5rem', { letterSpacing: 'normal', lineHeight: '1' }],
        '4xl': ['3rem', { letterSpacing: 'normal', lineHeight: '1' }],
        '5xl': ['3.5rem', { letterSpacing: 'normal', lineHeight: '1' }],
        '6xl': ['4rem', { letterSpacing: 'normal', lineHeight: '1' }],
        '7xl': ['4.5rem', { letterSpacing: 'normal', lineHeight: '1' }],
        '8xl': ['5rem', { letterSpacing: 'normal', lineHeight: '1' }],
        '9xl': ['6rem', { letterSpacing: 'normal', lineHeight: '1' }],
        base: ['1rem', { letterSpacing: 'normal', lineHeight: '1.5rem' }],
        lg: ['1.125rem', { letterSpacing: 'normal', lineHeight: '1.5rem' }],
        sm: ['0.875rem', { letterSpacing: '0.14px', lineHeight: '1.25rem' }],
        xl: ['1.5rem', { letterSpacing: 'normal', lineHeight: '1' }],
        xs: ['0.75rem', { letterSpacing: '0.24px', lineHeight: '1rem' }],
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        contentHide: {
          from: { opacity: '1', transform: 'translate(0, 0)' },
          to: { opacity: '0', transform: 'translate(-100%, 0)' },
        },
        contentShow: {
          from: { opacity: '0', transform: 'translate(-100%, 0)' },
          to: { opacity: '1', transform: 'translate(0, 0)' },
        },
        dropDownAndFade: {
          '0%': {
            height: '0',
            minWidth: 'var(--radix-dropdown-menu-trigger-width)',
            opacity: '0',
            overflow: 'hidden',
          },
          '100%': {
            height: '100%',
            minWidth: 'var(--radix-dropdown-menu-trigger-width)',
            opacity: '1',
          },
        },
        overlayHide: {
          from: { opacity: '1' },
          to: { opacity: '0' },
        },
        overlayShow: {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
      },
      lineHeight: {
        '12': '3rem',
      },
      outlineWidth: {
        3: '3px',
      },
    },
  },
}
export default preset
