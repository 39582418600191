import { CartFilter, CartFilters } from '@brenntag/connect-apis/api-cart/models/cart'
import { CaseFilter, CaseFilters } from '@brenntag/connect-apis/api-case/models/case'
import { ServiceFilter, ServiceFilters } from '@brenntag/connect-apis/api-vas/models/service'
import { DocumentFilter, DocumentFilters } from '@brenntag/connect-apis/api/models/document'
import { FavoriteFilter, FavoriteFilters } from '@brenntag/connect-apis/api/models/favorite'
import { InvoiceFilter, InvoiceFilters } from '@brenntag/connect-apis/api/models/invoice'
import { OrderFilter, OrderFilters } from '@brenntag/connect-apis/api/models/order'
import { ProductFilter, ProductFilters } from '@brenntag/connect-apis/api/models/product'
import { QuoteFilters, validQuoteFilters } from '@brenntag/connect-apis/api/models/quote'
import { SampleFilter, SampleFilters } from '@brenntag/connect-apis/api/models/sample'

export interface PageFilterTypes {
  cart: CartFilters
  cases: CaseFilters
  documents: DocumentFilters
  favorites: FavoriteFilters
  invoices: InvoiceFilters
  orders: OrderFilters
  products: ProductFilters
  quotes: QuoteFilters
  samples: SampleFilters
  services: ServiceFilters
}

export const validFilters = {
  cart: CartFilter,
  cases: CaseFilter,
  documents: DocumentFilter,
  favorites: FavoriteFilter,
  invoices: InvoiceFilter,
  orders: OrderFilter,
  products: ProductFilter,
  quotes: validQuoteFilters,
  samples: SampleFilter,
  services: ServiceFilter,
} as const

export type FilterEndpoint = keyof PageFilterTypes
export type Filters = PageFilterTypes[FilterEndpoint]
