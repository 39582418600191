import { useParams } from 'react-router'

import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { Country } from './getCountries'
import { getCountryByCode } from './getCountryByCode'

/**
 * @returns The country object based on the country code in the URL locale
 */
export const useCountry = (): Country | undefined => {
  const params = useParams<{ locale: string }>()
  const { countryCode } = parseLocale(params.locale)

  return getCountryByCode(countryCode)
}
