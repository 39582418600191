import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconNotification = createIcon({
  displayName: 'IconNotification',
  path: [
    <path
      d="M19 17H21V12.59L17 8.59V7C17 4.24 14.76 2 12 2C9.24 2 7 4.24 7 7V8.59L3 12.59V17H17V15H5V13.41L9 9.41V7C9 5.35 10.35 4 12 4C13.65 4 15 5.35 15 7V9.41L19 13.41V17Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M8 19C8 21.21 9.79 23 12 23C14.21 23 16 21.21 16 19H14C14 20.1 13.1 21 12 21C10.9 21 10 20.1 10 19H8Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
