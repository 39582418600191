import { APIUser } from '@brenntag/connect-apis/api'
import { User } from '@brenntag/connect-apis/api/models/user'

export const transformAPIUserToUser = (apiUser: APIUser): User => {
  const phoneNumbers =
    apiUser.phoneNumbers?.map(p => ({
      isPrimary: p.isPrimary,
      number: p.number,
      type: p.type,
    })) || []

  return {
    email: apiUser.email,
    id: apiUser.id,
    name: apiUser.name,
    phoneNumbers,
    primaryPhoneNumber: phoneNumbers.find(p => p.isPrimary),
  }
}
