import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFileText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M15.41 2H13v7h5v11H6V4h5V2H4v20h16V6.59zM15 7V4.41L17.59 7z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path d="M16 12H8v2h8zM8 16h8v2H8z" fill="currentColor" />
  </svg>
)
export default SvgFileText
