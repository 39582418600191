import { http, HttpResponse } from 'msw'

import { buildFavoriteList } from '@brenntag/legacy-mock-generators/generators/favorites/buildFavoriteList.js'

import { config } from '#src/config.js'

import { favoriteLists } from '../../api/favorites/data'

export const deleteFavoriteItem = () => {
  return http.delete<{ favoriteItemId: string; id: string }, null, null>(
    `${config.apiBaseUrl}/api-object/favorites/:id/favorite-items/:favoriteItemId`,
    async ({ params }) => {
      const listId = params.id as keyof typeof favoriteLists.items
      const oldItem = favoriteLists.items[listId]

      if (!oldItem) {
        return HttpResponse.json(null, { status: 404 })
      }

      // Recreate the entire favorites list and add the new product to it.
      // It's easier than manipulating the existing list.
      favoriteLists.items[listId] = buildFavoriteList({
        attributes: oldItem.data.attributes,
        id: params.id,
        relationships: {
          productIds: [
            ...(oldItem.included ?? [])
              .filter(item => item.id !== params.favoriteItemId)
              .map(item => item.relationships.product.data.id),
          ],
          shipToId: oldItem.data.relationships.shipTo.data.id,
          userId: oldItem.data.relationships.user.data.id,
        },
      })
      return HttpResponse.json(null)
    },
  )
}
