import { http, HttpResponse } from 'msw'

import { RegisterParams } from '#shared/apis/api-user/user/endpoints/register.js'
import { config } from '#src/config.js'

export const register = () => {
  return http.post<never, RegisterParams, null>(
    `${config.apiBaseUrl}/api-user/register`,
    async () => {
      return HttpResponse.json(null, { status: 201 })
    },
  )
}
