import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconMyLocation = createIcon({
  displayName: 'IconMyLocation',
  path: [
    <path
      d="M13 5.08C16.06 5.52 18.48 7.94 18.92 11H22V13H16.9C16.96 12.68 17 12.34 17 12C17 9.24 14.76 7 12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C13.62 17 15.06 16.21 15.97 15H18.3C17.31 17.07 15.35 18.58 13 18.92V22H11V18.92C7.94 18.48 5.52 16.06 5.08 13H2V11H5.08C5.52 7.94 7.94 5.52 11 5.08V2H13V5.08Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
