import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUndo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m1.59 9 6.7 6.71 1.42-1.42L4.41 9l5.3-5.29-1.42-1.42z" fill="currentColor" />
    <path
      d="m7.24 9 1 1h7.26c2.48 0 4.5 2.02 4.5 4.5S17.98 19 15.5 19H7v2h8.5c3.58 0 6.5-2.92 6.5-6.5S19.08 8 15.5 8H8.24z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUndo
