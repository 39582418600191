import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVolumeHigh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14 4.26V2.2c4.56.93 8 4.97 8 9.8s-3.44 8.87-8 9.8v-2.06c3.44-.89 6-4.02 6-7.74s-2.56-6.85-6-7.74M12 21.41 6.59 16H2V8h4.59L12 2.59V11h-2V7.41L7.41 10H4v4h3.41L10 16.59V13h2z"
      fill="currentColor"
    />
    <path
      d="M14 6.35v2.21c1.19.69 2 1.97 2 3.44s-.81 2.75-2 3.44v2.21c2.33-.83 4-3.04 4-5.65s-1.67-4.82-4-5.65"
      fill="currentColor"
    />
  </svg>
)
export default SvgVolumeHigh
