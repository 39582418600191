import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChevronDown = createIcon({
  displayName: 'IconChevronDown',
  path: [
    <path
      d="M12 18.41L2.28999 8.71L3.70999 7.29L12 15.59L20.29 7.29L21.71 8.71L12 18.41Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
