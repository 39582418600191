import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPhone = createIcon({
  displayName: 'IconPhone',
  path: [
    <path
      d="M22 22H19.5C9.85 22 2 14.15 2 4.5V2H9.57L13.26 8.16L10.19 11.23C10.91 12.22 11.79 13.1 12.78 13.82L15.85 10.75L22 14.44V18.01H20V15.58L16.15 13.27L13.04 16.38L12.37 15.96C10.63 14.87 9.14 13.38 8.05 11.64L7.63 10.97L10.74 7.86L8.43 4.02H4V4.52C4 13.07 10.95 20.02 19.5 20.02H22V22.02V22Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
