import type { ConnectLocation } from '@brenntag/api-customer'

import { useLocalStorage } from '#shared/hooks/dom/useLocalStorage.js'

interface UseShipToLocationResult {
  setShipToLocation(shipToLocation: ConnectLocation | undefined): void
  shipToLocation: ConnectLocation | undefined
}

export const useShipToLocation = (): UseShipToLocationResult => {
  const [shipToLocation, setShipToLocation] = useLocalStorage<ConnectLocation>({
    key: 'shipToLocation',
    version: 1,
  })

  return {
    setShipToLocation,
    shipToLocation,
  }
}
