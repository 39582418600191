import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6 22.91V5.96l2 1.38v11.75L18.24 12 6.69 4 6 3.52V1.09L21.76 12z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPlay
