import * as React from 'react'
import type { SVGProps } from 'react'
const SvgIdea = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17.607 4.807 16.17 6.244a4.995 4.995 0 0 1-1.671 7.088L14 13.62V16h-4v-2.38l-.499-.289A4.989 4.989 0 0 1 7 9c0-2.757 2.243-5 5-5 1.018 0 1.965.306 2.756.83l1.437-1.437A6.95 6.95 0 0 0 12 2c-1.87 0-3.628.728-4.95 2.05A6.95 6.95 0 0 0 5 9a7 7 0 0 0 3 5.745v4.669L10.586 22h2.828L16 19.414v-4.669A6.995 6.995 0 0 0 19 9a6.95 6.95 0 0 0-1.393-4.193M14 18.586 12.586 20h-1.172L10 18.586V18h4z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIdea
