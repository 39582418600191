import {
  ManyIncludedQuoteResourceUnion,
  ManyMeta,
  SingleIncludedQuoteResourceUnion,
} from '@brenntag/connect-apis/api/types'

interface Props {
  meta?: Partial<ManyMeta>
  quotes: SingleIncludedQuoteResourceUnion[]
}

export const buildQuotes = ({ meta, quotes }: Props): ManyIncludedQuoteResourceUnion => ({
  data: quotes.map(quote => quote.data),
  included: quotes.flatMap(quote => quote.included || []),
  meta: { count: quotes.length, ...meta },
})
