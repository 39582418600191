import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrendingUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M20 14h2V6h-8v2h6z" fill="currentColor" />
    <path
      d="M16.59 10H18v1.41l-5 5-4.5-4.5-5.79 5.8-1.42-1.42 7.21-7.2 4.5 4.5z"
      fill="currentColor"
    />
  </svg>
)
export default SvgTrendingUp
