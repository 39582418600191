export const generateAccessToken = () => {
  const payload = {
    aud: ['https://global.brenntag.com/api', 'https://bca-develop.eu.auth0.com/userinfo'],
    azp: 'nLFhDVU8prjiPCq1fD6PbGGLRae3LrCF',
    exp: new Date().getTime() / 1000 + 3 * 60 * 60,
    iat: new Date().getTime() / 1000,
    iss: 'https://login.develop.connect.digib.it/',
    scope: 'openid profile email',
    sub: 'auth0|5eccd7e3b97fa70cd59ccf7d',
  }
  return (
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ind6X1ktekN2ZWliX1FYSU1HTWdKaSJ9.' +
    btoa(JSON.stringify(payload)) +
    '.VVY6Wdrh2GzFrXyG_PJjDQiRSclU4fQx8kL6HhzItwSiOKil'
  )
}

export const setSalesforceSession = () => {
  window.localStorage.setItem(
    'salesforceSession',
    JSON.stringify({
      accessToken: generateAccessToken(),
      idToken: generateAccessToken(),
      isAuthenticated: true,
      isInternal: false,
    }),
  )
}

export const clearSalesforceSession = () => {
  window.localStorage.removeItem('salesforceSession')
}
