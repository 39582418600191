import React, { useEffect } from 'react'

import type { Customer } from '@brenntag/api-customer'

import { consola } from 'consola/browser'

import { Loading } from '#lib/auth/Loading.js'
import { useFetchCustomers } from '#modules/customer/hooks/customers/useFetchCustomers.js'
import { useFetchSalesGroups } from '#modules/customer/hooks/sales-groups/useFetchSalesGroups.js'
import { useFetchWebstores } from '#modules/customer/hooks/webstores/useFetchWebstores.js'
import { useFetchLoggedInUser } from '#shared/apis/api-user/me/hooks/useFetchLoggedInUser.js'
import { useSelectedCustomer } from '#shared/hooks/globalState/useSelectedCustomer.js'
import { useShipToLocation } from '#shared/hooks/globalState/useShipToLocation.js'
import { useIdleTimeout } from '#shared/hooks/session/useIdleTimeout.js'
import { useReloadIfLoggedOut } from '#shared/hooks/session/useReloadIfLoggedOut.js'
import { useNavigate } from '#shared/routing/hooks/useNavigate.js'
import { InternalLoaderFC } from '#shared/routing/PrerequisiteLoader/PrerequisiteLoader.js'

const useLastSelectedCustomer = (): {
  isFetching: boolean
  lastSelectedCustomer: Customer | undefined
} => {
  const { selectedCustomer } = useSelectedCustomer()

  const { data: customers, isFetching: isFetchingCustomers } = useFetchCustomers({})

  const hasMoreCustomersOnServer = Boolean(
    // When the total number of customers is greater than the number of customers
    // fetched it means there are more customers to fetch
    (customers?.meta.count ?? 0) > (customers?.data.length ?? 0),
  )

  const shouldFetchLastCustomer = Boolean(selectedCustomer && hasMoreCustomersOnServer)

  const { data: fetchedLastCustomer, isFetching: isFetchingLastCustomer } = useFetchCustomers({
    enabled: shouldFetchLastCustomer,
    id: selectedCustomer?.id,
    pageSize: 1,
  })

  const isFetching = isFetchingCustomers || isFetchingLastCustomer

  if (isFetching || !customers?.data.length) {
    return { isFetching, lastSelectedCustomer: undefined }
  }

  if (fetchedLastCustomer?.data.length === 1) {
    // When the customer is found/fetched by id, then return it
    return {
      isFetching,
      lastSelectedCustomer: fetchedLastCustomer.data[0],
    }
  }

  // Set up a fallback customer (the first one in the list) in there is no last customer
  const fallbackCustomer = customers.data[0]

  if (!fallbackCustomer) {
    throw new Error(`User has no assigned customers `)
  }

  if (selectedCustomer) {
    // Try to find the last customer in the list of customers of the user
    return {
      isFetching: isFetching,
      lastSelectedCustomer:
        customers.data.find(
          customer =>
            customer.id === selectedCustomer.id && customer.number === selectedCustomer.number,
        ) || fallbackCustomer,
    }
  }

  return {
    isFetching,
    lastSelectedCustomer: fallbackCustomer,
  }
}

export const ProtectedPrerequisiteLoader: InternalLoaderFC = ({ onReady }) => {
  const navigate = useNavigate()

  const { loggedInUser } = useFetchLoggedInUser({})
  const { setSelectedCustomer } = useSelectedCustomer()

  const { isFetching: isFetchingCustomers } = useFetchCustomers({})
  const { isFetching: isFetchingWebstores } = useFetchWebstores()
  const { isFetching: isFetchingSalesGroups } = useFetchSalesGroups()

  const { isFetching, lastSelectedCustomer } = useLastSelectedCustomer()
  const { setShipToLocation, shipToLocation } = useShipToLocation()

  useReloadIfLoggedOut()
  useIdleTimeout({ onIdle: () => navigate('/:locale/connect/logout') })

  useEffect(() => {
    if (
      !loggedInUser ||
      isFetching ||
      isFetchingCustomers ||
      isFetchingSalesGroups ||
      isFetchingWebstores
    )
      return

    if (!lastSelectedCustomer) {
      consola.error(`No customers for user: ${loggedInUser.email}}`)
    } else if (!lastSelectedCustomer?.shipTos.length) {
      consola.error(`No shipTo locations for customer: ${lastSelectedCustomer?.id}}`, {
        lastSelectedCustomer,
        loggedInUser,
      })
    }

    /* Set the previous selected customer from local storage, if any */
    setSelectedCustomer(lastSelectedCustomer)

    /* Set the previous selected ship to location from local storage, if any */
    const currentShipToLocation =
      (shipToLocation &&
        lastSelectedCustomer?.shipTos.find(shipTo => shipTo.id === shipToLocation.id)) ||
      lastSelectedCustomer?.shipTos[0]

    setShipToLocation(currentShipToLocation)

    onReady()
  }, [
    isFetching,
    loggedInUser,
    onReady,
    setSelectedCustomer,
    setShipToLocation,
    lastSelectedCustomer,
    shipToLocation,
    isFetchingCustomers,
    isFetchingSalesGroups,
    isFetchingWebstores,
  ])

  return <Loading text="Initiating E-commerce profile..." />
}
