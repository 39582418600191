import * as React from 'react'
import type { SVGProps } from 'react'
const SvgParcelAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m14 4.72-2-.67-4.18 1.4-3.16-1.06L12 1.95l2 .66z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M20 17.28V10h2v8.72l-10 3.33-10-3.33V5.61l10 3.33 4-1.33v2.11l-3 1v8.89zm-9-6.56L4 8.39v8.89l7 2.33z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path d="M18 4h-2v2h2v2h2V6h2V4h-2V2h-2z" fill="currentColor" />
  </svg>
)
export default SvgParcelAdd
