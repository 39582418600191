import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M15 16c0 .55.45 1 1 1h1c2.76 0 5-2.24 5-5 0-5.51-4.49-10-10-10S2 6.49 2 12s4.49 10 10 10a9.97 9.97 0 0 0 7.13-3h-3.26c-1.15.64-2.46 1-3.86 1-4.41 0-8-3.59-8-8S7.59 4 12 4s8 3.59 8 8c0 1.65-1.35 3-3 3v-3c0-2.76-2.24-5-5-5s-5 2.24-5 5a5.002 5.002 0 0 0 6 4.9v-2.08c-.31.11-.65.18-1 .18-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgAt
