import { useQuery, useQueryClient } from '@tanstack/react-query'

import { Cart } from '@brenntag/connect-apis/api-cart/models/cart'

import { fetchCart } from '#shared/apis/api-cart/cart/endpoints/fetchCart.js'
import { useFilters } from '#shared/hooks/filters/useFilters.js'
import { useShipToLocation } from '#shared/hooks/globalState/useShipToLocation.js'
import { QueryCacheKey } from '#shared/utils/enums.js'

import { sortCartItemFunctions } from '../sortCartItemFunctions'

const defaultSortOrder = '-cartAddedDate'

interface Params {
  isEnabled?: boolean
  useErrorBoundary?: boolean
}

export const useFetchCart = (
  { isEnabled = true, useErrorBoundary = false }: Params = { isEnabled: true },
) => {
  const client = useQueryClient()
  const { shipToLocation } = useShipToLocation()
  const shipToLocationId = shipToLocation?.id

  const { filters } = useFilters('cart')

  const { sort = defaultSortOrder } = filters

  const { data, ...rest } = useQuery({
    enabled: isEnabled && Boolean(shipToLocationId),
    meta: { toastMessageKey: 'toast.error.cart' },
    queryFn: async () => {
      await client.invalidateQueries({
        queryKey: [QueryCacheKey.Cart, shipToLocationId],
        refetchType: 'none',
      })

      return fetchCart({
        shipToLocationId: shipToLocationId!,
      })
    },
    queryKey: [QueryCacheKey.Cart, shipToLocationId],
    staleTime: 2000,
    throwOnError: useErrorBoundary,
  })

  const cacheKeyWithSort = [QueryCacheKey.Cart, shipToLocationId, sort]
  let cartData = client.getQueryData<Cart>(cacheKeyWithSort)

  if (!cartData && data && data.shipToLocationId === shipToLocationId) {
    cartData = { ...data, items: [...data.items].sort(sortCartItemFunctions[sort]) }
    client.setQueryData(cacheKeyWithSort, cartData)
  }

  // Make sure to update the status of the cart if it changes
  if (
    cartData &&
    data &&
    data?.shipToLocationId === shipToLocationId &&
    cartData.status !== data.status
  ) {
    cartData.status = data.status
    client.setQueryData(cacheKeyWithSort, cartData)
  }

  return { data: cartData, ...rest }
}
