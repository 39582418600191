import { RouteObject } from 'react-router'

import { RouteErrorBoundary } from '#shared/pages/Error/RouteErrorBoundary.js'

export const withRouteErrorBoundary = (children: RouteObject[]) => {
  return {
    children,
    ErrorBoudnary: RouteErrorBoundary,
  }
}
