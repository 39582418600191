import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEuro = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14 10v-.5c0-.827-.673-1.5-1.5-1.5S11 8.673 11 9.5v1h2v1h-2v1h2v1h-2v1c0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5V14h2v.5c0 1.93-1.57 3.5-3.5 3.5S9 16.43 9 14.5v-1H8v-1h1v-1H8v-1h1v-1C9 7.57 10.57 6 12.5 6S16 7.57 16 9.5v.5zm6-4h-2v14H6V4h14V2H4v20h16z"
      fill="currentColor"
    />
  </svg>
)
export default SvgEuro
