import { http, HttpResponse } from 'msw'

import { CaseGetResponse } from '@brenntag/connect-apis/api-case/types'

import { config } from '#src/config.js'

import { cases } from './data/cases'

export const fetchCase = () => {
  return http.get<{ id: string }, null, CaseGetResponse>(
    `${config.apiGatewayUrl}/cases/:id`,
    async ({ params }) => {
      const caseItem = cases.items[params.id]

      if (!caseItem) {
        return HttpResponse.json(null, { status: 404 })
      }

      return HttpResponse.json(caseItem)
    },
  )
}
