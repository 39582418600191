import React, { FC } from 'react'

import { createStandaloneToast } from '@chakra-ui/react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { Toast } from '#shared/components/Toast/Toast.js'
import { captureHandledException } from '#shared/utils/errors.js'
import { isEnvironment } from '#src/env.js'

import { intl } from './IntlProvider'
import { router } from './RouterProvider'

const queryCache = new QueryCache({
  onError: (error, query) => {
    if (error.status === 401) {
      const { countryCode, languageCode } = parseLocale(window.location.pathname)
      router.navigate(`/${languageCode}-${countryCode}/connect/logout`)
      return
    }

    captureHandledException(error)

    if (query.meta?.toastMessageKey) {
      const { toast } = createStandaloneToast()

      const message = intl.formatMessage(
        {
          id: query.meta.toastMessageKey,
        },
        query.meta.toastMessageValues,
      )

      toast({
        position: 'bottom-left',
        render: props => (
          <Toast color="red.70" description={message} id={props.id} onClose={props.onClose} />
        ),
      })
    }
  },
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return error.status !== 401 && failureCount < 3
      },
      throwOnError: error => Boolean(error.status && error.status >= 401),
    },
  },
  queryCache,
})

interface Props {
  children?: React.ReactNode
}

export const ReactQueryProvider: FC<Props> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {!isEnvironment('production') && <ReactQueryDevtools position="right" />}
      {children}
    </QueryClientProvider>
  )
}
