import React, { FC } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useIntl } from 'react-intl'

import { LoggedInUser } from '@brenntag/connect-apis/api-user/models/user'

import { useAclContextInfo } from '#lib/Acl/lib/useAclContextInfo.js'
import { getCountries } from '#lib/countries/getCountries.js'
import { useShipToLocation } from '#shared/hooks/globalState/useShipToLocation.js'
import { locationToString } from '#shared/utils/address-utils.js'
import { QueryCacheKey } from '#shared/utils/enums.js'
import { environment } from '#src/env.js'

import { FeatureFlags } from './components/FeatureFlags/FeatureFlags'
import { DebugSection } from './DebugPanelSection'

const countries = getCountries()

export const DebugDialogContents: FC = () => {
  const queryClient = useQueryClient()
  const loggedInUser = queryClient.getQueryData<LoggedInUser | undefined>([
    QueryCacheKey.LoggedInUser,
  ])

  const { selectedCustomer, selectedSalesGroup } = useAclContextInfo()
  const { shipToLocation } = useShipToLocation()
  const intl = useIntl()

  const appInfo = [
    { key: 'Environment', value: environment },
    { key: 'Version', value: __VERSION__ },
    { key: 'Git hash', value: __COMMIT_HASH__ },
    { key: 'Locale', value: intl.locale },
  ]

  const loggedInUserItems = [
    { key: 'ID', value: loggedInUser?.id },
    { key: 'Name', value: loggedInUser?.name },
    { key: 'Username', value: loggedInUser?.username },
    { key: 'Email', value: loggedInUser?.email },
    { key: 'Role', value: loggedInUser?.role },
    { key: 'Country code', value: loggedInUser?.countryCode },
    { key: 'Language code', value: loggedInUser?.languageCode },
  ]

  const selectedCustomerItems = [
    { key: 'ID', value: selectedCustomer?.id },
    { key: 'Name', value: selectedCustomer?.name },
    { key: 'Allow promotions', value: selectedCustomer?.allowPromotions ? 'Yes' : 'No' },
    { key: 'Salesgroup ID', value: selectedCustomer?.salesGroupId },
  ]

  const selectedSalesGroupItems = [
    { key: 'ID', value: selectedSalesGroup?.id },
    { key: 'Name', value: selectedSalesGroup?.name },
    { key: 'Short name', value: selectedSalesGroup?.shortName },
  ]

  const selectedShipToLocationItems = [
    { key: 'ID', value: shipToLocation?.id },
    { key: 'Show prices', value: shipToLocation?.showPrices ? 'Yes' : 'No' },
    { key: 'Address', value: locationToString(shipToLocation) },
  ]

  const supportedCountryItems = countries.map(country => ({
    key: country.code,
    value: country.languageCodes.join(', '),
  }))

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 items-stretch gap-4">
        <DebugSection items={appInfo} title="Application" />
        <DebugSection items={loggedInUserItems} title="Loggedin User" />
        <DebugSection items={selectedCustomerItems} title="Selected Customer" />
        <DebugSection items={selectedSalesGroupItems} title="Selected Sales Group" />
        <DebugSection items={selectedShipToLocationItems} title="Selected Ship To Location" />
        <DebugSection items={supportedCountryItems} title="Supported Countries" />
      </div>

      <FeatureFlags />
    </div>
  )
}
