import React from 'react'

import { cn } from '#utils/cn.js'

interface Props {
  children?: React.ReactNode
  className?: string
}
export const AppHeaderDrawerFooter: React.FC<Props> = ({ children, className }) => {
  return <div className={cn('flex flex-col gap-6 mt-auto', className)}>{children}</div>
}
