import {
  VasSearchRequestFilter,
  VasSearchRequestFilterType,
} from '@brenntag/connect-apis/api-vas/types'

export type VasFilterType = DateRangeFilter | TermFilter

type DateRangeFilter = {
  name: string
  type: VasSearchRequestFilterType.DateRange
  value: [string, string]
}

type TermFilter = {
  name: string
  type: VasSearchRequestFilterType.Term
  value: string
}

export const filtersToParams = (filters: VasFilterType[]): VasSearchRequestFilter[] =>
  filters?.map(filter => {
    if (filter.type === VasSearchRequestFilterType.DateRange) {
      return {
        ...filter,
        endDate: filter.value[1],
        startDate: filter.value[0],
      }
    }
    return {
      ...filter,
      values: [filter.value],
    }
  })
