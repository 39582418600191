import { http, HttpResponse } from 'msw'

import { FeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'

const flags = {
  enableCartV2: { value: false },
  enableCreditLimit: { value: true },
  enableInvoicesV2: { value: false },
  enableMaintenanceOverlay: { value: false },
  enableOnlinePayment: { value: false },
  enablePimSpecifications: { value: false },
  enableServices: { value: true },
}

export const fetchLaunchDarklyFlags = (overrides?: Partial<FeatureFlags>) => {
  const data = {
    ...flags,
    ...overrides,
  }

  return http.get('https://app.launchdarkly.com/sdk/evalx/*', async () => {
    return HttpResponse.json(data)
  })
}
