import { faker } from '@faker-js/faker'
import { merge, uniqBy } from 'lodash'

import {
  CustomerContextAttributes,
  DataTypeCustomerContext,
  DataTypeLocation,
  DataTypePricebook,
  DataTypeSalesGroup,
  LocationResource,
  ManyIncludedCustomerContextResourceUnion,
  PricebookResource,
  SalesGroupResource,
  SingleIncludedCustomerContextResourceUnion,
} from '@brenntag/connect-apis/api/types'

import { buildSalesGroup } from './buildSalesGroup'
import { buildPricebook } from './products/buildPricebook'

interface Props {
  attributes?: Partial<CustomerContextAttributes>
  id?: string
  included?: {
    locations?: LocationResource[]
    pricebook?: PricebookResource
    salesGroup?: SalesGroupResource
  }
}

export const buildCustomerContext = ({
  attributes = {},
  id = faker.string.uuid(),
  included,
}: Props = {}): SingleIncludedCustomerContextResourceUnion => {
  const pricebook = included?.pricebook ?? buildPricebook()
  const salesGroup =
    included?.salesGroup ?? buildSalesGroup({ relationships: { pricebookId: pricebook.id } })
  const locations = included?.locations || []
  return {
    data: {
      attributes: merge(
        {
          createdAt: faker.date.past().toISOString(),
          customerName: faker.company.name(),
          customerNumber: faker.string.numeric(10),
          incoTerms: faker.word.words(),
          isActive: true,
          updatedAt: faker.date.recent().toISOString(),
          vatNumber: faker.number.int().toString(),
        } satisfies CustomerContextAttributes,
        attributes,
      ),
      id,
      relationships: {
        locations: {
          data: locations.map(({ id }) => ({
            id,
            type: DataTypeLocation.Location,
          })),
        },
        pricebook: {
          data: { id: pricebook.id, type: DataTypePricebook.Pricebook },
        },
        salesGroup: {
          data: { id: salesGroup.id, type: DataTypeSalesGroup.SalesGroup },
        },
      },
      type: DataTypeCustomerContext.CustomerContext,
    },
    included: uniqBy([...locations, salesGroup].filter(Boolean), v => v?.id),
  }
}

export const buildCustomerContexts = ({
  contexts,
}: {
  contexts: SingleIncludedCustomerContextResourceUnion[]
}): ManyIncludedCustomerContextResourceUnion => ({
  data: contexts.map(({ data }) => data),
  included: contexts.flatMap(context => context.included || []),
  meta: { count: contexts.length },
})
