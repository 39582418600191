import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconHistory = createIcon({
  displayName: 'IconHistory',
  path: [
    <path
      d="M2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22V20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 14.5167 5.17361 16.7556 7 18.219V14H9V21H2V19H4.87C3.1 17.19 2 14.73 2 12Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M14.29 15.71L11 12.41V6H13V11.59L15.71 14.29L14.29 15.71Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
