import { http, HttpResponse } from 'msw'

import {
  DataTypeLocation,
  DataTypeProduct,
  DataTypeUser,
  ManyIncludedQuoteResourceUnion,
  SingleIncludedQuoteResourceUnion,
} from '@brenntag/connect-apis/api/types'
import { buildQuotes } from '@brenntag/legacy-mock-generators/generators/quotes/buildQuotes.js'

import { quoteFilters, QuoteListSortKey } from '#shared/apis/api/quotes/endpoints/fetchQuotes.js'
import { config } from '#src/config.js'

import { quotes } from './data'

export const fetchQuotes = () => {
  return http.get<never, null, ManyIncludedQuoteResourceUnion>(
    `${config.apiBaseUrl}/api/quotes`,
    async ({ request }) => {
      let list = quotes.list()
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const status = searchParams.get(quoteFilters.status)
      if (status) {
        list = list.filter(quote => quote.data.attributes.status === status)
      }

      const requestBy = searchParams.get(quoteFilters.requestBy)
      if (requestBy) {
        list = list.filter(quote =>
          quote.included?.find(
            include => include.type === DataTypeUser.User && include.id === requestBy,
          ),
        )
      }

      const searchQuery = searchParams.get(quoteFilters.searchQuery)
      if (searchQuery) {
        list = list.filter(quote =>
          quote.included?.find(
            include =>
              include.type === DataTypeProduct.Product &&
              include.attributes.name.includes(searchQuery),
          ),
        )
      }

      const shipToId = searchParams.get(quoteFilters.shipToLocationId)
      if (shipToId) {
        list = list.filter(quote =>
          quote.included?.some(
            include => include.type === DataTypeLocation.Location && include.id === shipToId,
          ),
        )
      }

      const sort = searchParams.get('sort') as QuoteListSortKey
      list = sortQuotes(list, sort)

      return HttpResponse.json(buildQuotes({ quotes: list }))
    },
  )
}

const sortQuotes = (
  productsList: SingleIncludedQuoteResourceUnion[],
  sort: QuoteListSortKey,
): SingleIncludedQuoteResourceUnion[] => {
  const isDescending = sort.startsWith('-')
  switch (sort) {
    case '-number':
    case 'number':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.number || ''
        const bVal = b.data.attributes.number || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-requestedDeliveryDate':
    case 'requestedDeliveryDate':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.requestedDeliveryDate || ''
        const bVal = b.data.attributes.requestedDeliveryDate || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    default:
      return productsList
  }
}
