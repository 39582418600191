import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDownloadcontained = createIcon({
  displayName: 'IconDownloadcontained',
  path: [
    <path d="M20 19H4V21H20V19Z" fill="currentColor" key="0" />,
    <path
      d="M12 17.41L5.29001 10.71L6.71001 9.29L12 14.59L17.29 9.29L18.71 10.71L12 17.41Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M12 11.76L13 10.76V2H11V10.76L12 11.76Z" fill="currentColor" key="2" />,
    <path d="M18 15V21H20V15H18Z" fill="currentColor" key="3" />,
    <path d="M4 15L4 21H6L6 15H4Z" fill="currentColor" key="4" />,
  ],
  viewBox: '0 0 24 24',
})
