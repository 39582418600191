import { Amount, PricePoint } from './price'

export const ProductFilter = {
  brand: 'filter[brand.in]',
  category: 'filter[categories.id.equals]',
  dangerousGoods: 'filter[dangerousGoodsClass.equals]',
  favoriteListId: 'filter[favoriteItems.favorite.id.equals]',
  hazardous: 'filter[isHazardous.equals]',
  packageType: 'filter[packaging.type.in]',
  previouslyOrdered: 'filter[ordered]',
  pricePointExists: 'filter[pricebookProduct.pricePoints.exists]',
  searchQuery: 'search[query]',
  sort: 'sort',
}

export interface Packaging {
  isActive: boolean
  isSdsAvailable: boolean
  name?: string
  size: Amount
  type?: string
}

export interface Product {
  casNumbers?: string[]
  customerProductNumber?: string
  description?: string
  favoriteItems: { favorite: { id: string }; id: string; isActive: boolean }[]
  highestPricePoint: PricePoint | undefined
  id: string
  isHazardous: boolean
  isOrderable: boolean
  name: string
  number: string
  packaging: Packaging
  pricebook?: { type: 'customer' | 'regional' | 'shipTo' }
  pricepoints: PricePoint[]
  shippingInformation: ShippingInformation
  specifications: ProductSpecification[]
  tankLocations: ProductTankLocation[]
  type: ProductType
}

export interface ProductCategory {
  id: string
  name: string
  subCategories: ProductCategory[]
}

export type ProductFilterKey = keyof ProductFilters

export interface ProductFilters {
  brand?: string
  category?: string
  dangerousGoods?: string
  favoriteListId?: string
  hazardous?: string
  packageType?: string
  page?: string
  previouslyOrdered?: string
  pricePointExists?: string
  searchQuery?: string
  sort?: string
}

export interface ProductList {
  brands: string[]
  categories: ProductCategory[]
  categoriesHash: string
  packagingTypes: ProductPackagingTypes
  products: Product[]
  total: number
  totalPages: number
}

export type ProductPackagingTypes = Record<string, string>

export interface ProductPimSpecification {
  groupId?: string
  groupName?: string
  id: string
  label: string
  value: boolean | number | string
}

export interface ProductSpecification {
  id: string
  label: string
  value: string
}

export type ProductType =
  | 'Non-Valuated Materials'
  | 'Packaging'
  | 'Returnable Package'
  | 'Sample'
  | 'Services'
  | 'Tolling'
  | 'Tolling raw material non-valuated'
  | 'Trading Goods'

export interface ShippingInformation {
  ground: boolean
  isHazardous: boolean
  productWeightLbs?: number
  shippingTerms?: string
  shippingWeightLbs?: number
}

interface ProductTankLocation {
  id: string
  name: string
}
