import { APICart } from '@brenntag/connect-apis/api-cart'

export const buildCart = (id: string, currency: string = 'USD'): APICart => {
  return {
    currencyIsoCode: currency,
    deliveryOptions: {},
    grandTotalAmount: 0,
    id,
    items: [],
    messagesSummary: {
      errorCount: 0,
      hasErrors: false,
      limitedMessages: [],
      relatedEntityId: id,
      totalLineItemsWithErrors: 0,
    },
    promotionCodes: {
      codes: [],
    },
    status: 'Active',
    taxType: 'Net',
    totalChargeAmount: 0,
    totalDiscountAmount: 0,
    totalListPrice: 0,
    totalProductAmount: 0,
    totalProductAmountAfterAdjustments: 0,
    totalProductCount: 0,
    totalPromotionalAdjustmentAmount: 0,
    totalTaxAmount: 0,
    uniqueProductCount: 0,
  }
}
