import { RouteObject } from 'react-router'

import { NotFound } from '#shared/pages/NotFound/index.js'
import { withRouteErrorBoundary } from '#shared/routing/withRouteErrorBoundary.js'

export const checkoutRoutes: RouteObject = {
  children: [
    withRouteErrorBoundary([
      { lazy: () => import('#shared/pages/Checkout/EnsureEcommContextWrapper.js'), path: ':id' },
      { Component: NotFound, path: '*' },
    ]),
  ],
  lazy: () => import('#shared/layouts/CheckoutLayout.js'),
  path: 'checkout',
}
