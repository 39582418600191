import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWarehouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M20 21h2V8.46L12 1.8 2 8.46V21h2V9.54l8-5.34 8 5.34z" fill="currentColor" />
    <path d="M18 21h-2v-8H8v8H6V11h12z" fill="currentColor" />
    <path
      d="M13 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0M13 20a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgWarehouse
