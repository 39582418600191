import { APISample } from '@brenntag/connect-apis/api'
import { SampleList, SampleStatus } from '@brenntag/connect-apis/api/models/sample'
import { ManyIncludedSampleResourceUnion } from '@brenntag/connect-apis/api/types'

import { transformAPISamplesToSampleList } from '#shared/apis/api/samples/transforms/sample.transforms.js'
import { convertFiltersToParams } from '#shared/apis/convertFiltersToParams.js'
import { request } from '#shared/apis/request.js'
import { deserialize } from '#shared/utils/deserialize.js'
import { config } from '#src/config.js'

export const sampleListSortKeys = [
  'number',
  '-number',
  'productName',
  '-productName',
  'requestedDate',
  '-requestedDate',
] as const

export interface SampleFilters {
  deliveryDateGreaterEqual?: Date
  deliveryDateLessEqual?: Date
  searchQuery?: string
  shipToLocationId: string
  status?: SampleStatus
}

export type SampleListSortKey = (typeof sampleListSortKeys)[number]

type SampleFilter = keyof SampleFilters

export const sampleFilters = {
  deliveryDateGreaterEqual: 'filter[requestedDate.gte]',
  deliveryDateLessEqual: 'filter[requestedDate.lte]',
  searchQuery: 'search[query]',
  shipToLocationId: 'filter[shipTo.id.equals]',
  status: 'filter[status.in]',
} satisfies Record<SampleFilter, string>

interface Params {
  filters: SampleFilters
  include?: string
  pageNumber: number
  pageSize: number
  sort: SampleListSortKey
}

export const fetchSamples = async ({
  filters,
  include,
  pageNumber,
  pageSize,
  sort,
}: Params): Promise<SampleList> => {
  const { body: sampleData } = await request<ManyIncludedSampleResourceUnion>({
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
    params: {
      include: include || 'user,shipTo,product.pricebookProduct.pricePoints',
      'page[number]': pageNumber,
      'page[size]': pageSize,
      sort,
      ...convertFiltersToParams(sampleFilters, filters),
    },
    url: `${config.apiBaseUrl}/api/samples`,
  })

  const apiSamples = deserialize<APISample[]>(sampleData)
  const meta = sampleData?.meta

  return transformAPISamplesToSampleList(apiSamples, meta, pageSize)
}
