import { http, HttpResponse } from 'msw'

import { CaseCreateResponse } from '@brenntag/connect-apis/api-case/types'
import { buildCase } from '@brenntag/legacy-mock-generators/generators/cases/buildCase.js'

import { PostCaseParams } from '#shared/apis/api-case/cases/endpoints/postCase.js'
import { config } from '#src/config.js'

import { cases } from './data/cases'

export const postCase = () => {
  return http.post<never, PostCaseParams, CaseCreateResponse>(
    `${config.apiGatewayUrl}/cases`,
    async ({ request }) => {
      const body = await request.json()
      const caseItem = buildCase({ overrides: body })

      cases.items[caseItem.id] = caseItem

      return HttpResponse.json({
        id: caseItem.id,
        number: caseItem.number,
      })
    },
  )
}
