import React from 'react'

import { RouteObject } from 'react-router'

import { withAuthenticationRequired } from '#lib/auth/withAuthenticationRequired.js'
import { withUserMetaCheck } from '#lib/connect/withUserMetaCheck.js'
import { RootErrorBoundary } from '#shared/pages/Error/RootErrorBoundary.js'
import { Navigate } from '#shared/routing/components/Navigate.js'
import { checkoutRoutes } from '#shared/routing/connect/checkoutRoutes.js'
import { privateRoutes } from '#shared/routing/connect/privateRoutes.js'
import { PrerequisiteLoader } from '#shared/routing/PrerequisiteLoader/PrerequisiteLoader.js'
import { ProtectedPrerequisiteLoader } from '#shared/routing/PrerequisiteLoader/ProtectedPrerequisiteLoader.js'

import { validCountryLanguageLoader } from '../loaders/validCountryLanguageLoader'

export const protectedRoutes: RouteObject = {
  children: [
    {
      children: [
        privateRoutes,
        checkoutRoutes,
        { element: <Navigate replace to="dashboard" />, index: true },
      ],
      Component: withAuthenticationRequired(
        withUserMetaCheck(() => <PrerequisiteLoader Loaders={[ProtectedPrerequisiteLoader]} />),
      ),
      ErrorBoundary: () => <RootErrorBoundary />,
    },
  ],
  id: 'protectedRoutes',
  loader: validCountryLanguageLoader,
  path: 'connect',
}
