import React, { FC } from 'react'

import { To, useParams } from 'react-router'

import { AppLink } from '#components/AppLink/AppLink.js'
import { BrenntagLogo } from '#components/BrenntagLogo/BrenntagLogo.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

enum FooterLinkType {
  External,
  Internal,
}

type FooterLink = {
  dataTestId?: string
  label: string
  payload?: object
  to: To
  type?: FooterLinkType
}

export const Footer: FC = () => {
  const t = useTranslations()
  const { locale } = useParams<{ locale?: string }>()
  const links: FooterLink[] = [
    {
      dataTestId: 'footer-terms-of-use',
      label: t('footer.termsofuse'),
      to: `https://www.brenntag.com/${locale?.toLowerCase()}/services/brenntag-connect-online-services/terms`,
      type: FooterLinkType.External,
    },
    {
      dataTestId: 'footer-data-and-privacy',
      label: t('footer.privacypolicy'),
      to: `https://www.brenntag.com/${locale?.toLowerCase()}/privacy-policy.html`,
      type: FooterLinkType.External,
    },
  ]

  return (
    <footer className="border-t border-neutral-20">
      <div className="container mx-auto flex flex-col items-start gap-3 p-4 text-sm md:flex-row md:items-center lg:gap-8 lg:px-8">
        <BrenntagLogo className="w-40" />
        <p className="text-neutral-80" data-testid="footer-brenntag-se">
          &copy; Brenntag SE
        </p>
        {locale &&
          links.map(({ dataTestId, label, to, type }) => {
            switch (type) {
              case FooterLinkType.External:
                return (
                  <a
                    className="text-neutral-80"
                    data-testid={dataTestId}
                    href={to?.toString()}
                    key={dataTestId}
                    rel="noopener noreferrer"
                    target="_blank">
                    {label}
                  </a>
                )
              case FooterLinkType.Internal:
                return (
                  <AppLink
                    data-testid={dataTestId}
                    key={dataTestId}
                    target="_blank"
                    to={to.toString()}>
                    {label}
                  </AppLink>
                )
            }
          })}
      </div>
    </footer>
  )
}
