import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFolderMinus = createIcon({
  displayName: 'IconFolderMinus',
  path: [
    <path
      d="M2 21H22V9H20V19H4V5H9.59L11.59 7H22V5H12.41L10.41 3H2V21Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M8 12H16V14H8V12Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
