import type { AclContextInfo } from './useAclContextInfo'

import { FeatureName, features } from './features'

export type IsAllowedFunc = (
  contextInfo: AclContextInfo,
  feature?: FeatureName | FeatureName[],
) => boolean

export const isAllowed: IsAllowedFunc = (contextInfo, feature): boolean => {
  if (!feature) {
    return true
  }

  if (Array.isArray(feature)) {
    return feature.some(name => isAllowed(contextInfo, name))
  }

  if (!features[feature]) {
    // eslint-disable-next-line no-console
    console.warn(`Feature "${String(feature)}" requested, but feature does not exist in ACL`)
    return false
  }

  return features[feature] && features[feature](contextInfo)
}
