import React, { type FC } from 'react'

import * as Sentry from '@sentry/react'
import { Outlet, ScrollRestoration } from 'react-router'

import EnvironmentInfo from '#components/EnvironmentInfo/EnvironmentInfo.js'
import { LaunchDarklyIdentifier } from '#components/LaunchDarklyIdentifier/LaunchDarklyIdentifier.js'
import { SentryIdentifier } from '#components/SentryIdentifier/SentryIdentifier.js'
import { AuthProvider } from '#lib/auth/AuthProvider.js'
import { MaintenancePage } from '#shared/pages/MaintenancePage/MaintenancePage.js'
import { ChakraThemeProvider } from '#shared/providers/ChakraThemeProvider.js'
import { IntlProvider } from '#shared/providers/IntlProvider.js'
import { LaunchDarklyProvider } from '#shared/providers/LaunchDarklyProvider.js'
import { ReactQueryProvider } from '#shared/providers/ReactQueryProvider.js'
import { isEnvironment } from '#src/env.js'

export const RootLayout: FC = () => (
  <Sentry.Profiler>
    <AuthProvider>
      <LaunchDarklyProvider>
        <IntlProvider>
          <ChakraThemeProvider>
            <ReactQueryProvider>
              <SentryIdentifier>
                <LaunchDarklyIdentifier>
                  <MaintenancePage>
                    <Outlet />
                    <ScrollRestoration />
                  </MaintenancePage>
                  {!isEnvironment('production') && <EnvironmentInfo />}
                </LaunchDarklyIdentifier>
              </SentryIdentifier>
            </ReactQueryProvider>
          </ChakraThemeProvider>
        </IntlProvider>
      </LaunchDarklyProvider>
    </AuthProvider>
  </Sentry.Profiler>
)
