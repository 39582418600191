import React from 'react'

import { BoxProps, ColorProps, Heading, HeadingProps, Text, TextProps } from '@chakra-ui/react'

import { filterNullValues, omitProperties } from '#shared/utils/object-utils.js'

interface TypographyProps {
  alignRight?: boolean
  bold?: boolean
  capitalize?: boolean
  center?: boolean
  color?: ColorProps['color']
  lowercase?: boolean
  overline?: boolean
  strike?: boolean
  underline?: boolean
  uppercase?: boolean
  whitespace?: 'break-spaces' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap'
  wrapText?: boolean
}

const typographyPropNames = [
  'alignRight',
  'bold',
  'capitalize',
  'center',
  'color',
  'lowercase',
  'overline',
  'strike',
  'underline',
  'uppercase',
  'whitespace',
  'wrapText',
]

const getTypographyStyles = (props: TypographyProps): Partial<BoxProps> =>
  filterNullValues({
    fontWeight: props.bold ? 'bold' : undefined,
    textAlign: props.center ? 'center' : props.alignRight ? 'right' : 'left',
    textDecoration: props.underline
      ? 'underline'
      : props.overline
        ? 'overline'
        : props.strike
          ? 'line-through'
          : undefined,
    textTransform: props.uppercase
      ? 'uppercase'
      : props.capitalize
        ? 'capitalize'
        : props.lowercase
          ? 'lowercase'
          : undefined,
    whiteSpace: props.wrapText ? 'pre-line' : props.whitespace,
  })

const getDefaultHeaderStyles = (): Partial<BoxProps> =>
  filterNullValues({
    fontFamily: 'body',
    fontWeight: 'medium',
    margin: 0,
  })

/**
 * Headers
 */

export const H1: React.FC<HeadingProps & TypographyProps> = ({ color = 'primary', ...props }) => (
  <Heading
    as="h1"
    {...getDefaultHeaderStyles()}
    {...getTypographyStyles(props)}
    color={color}
    fontSize="h1"
    lineHeight="h1"
    marginBottom="28px"
    {...omitProperties(props, typographyPropNames)}
  />
)

export const H2: React.FC<HeadingProps & TypographyProps> = ({ color = 'primary', ...props }) => (
  <Heading
    as="h2"
    fontWeight="light"
    {...getDefaultHeaderStyles()}
    {...getTypographyStyles(props)}
    color={color}
    fontSize="h2"
    lineHeight="h2"
    {...omitProperties(props, typographyPropNames)}
  />
)

export const H3: React.FC<HeadingProps & TypographyProps> = ({ color = 'primary', ...props }) => (
  <Heading
    as="h3"
    {...getDefaultHeaderStyles()}
    {...getTypographyStyles(props)}
    color={color}
    fontSize="h3"
    lineHeight="h3"
    {...omitProperties(props, typographyPropNames)}
  />
)

export const H4: React.FC<HeadingProps & TypographyProps> = ({ color = 'primary', ...props }) => (
  <Heading
    as="h4"
    {...getDefaultHeaderStyles()}
    {...getTypographyStyles(props)}
    color={color}
    fontSize="h4"
    lineHeight="h4"
    {...omitProperties(props, typographyPropNames)}
  />
)

export const H6: React.FC<HeadingProps & TypographyProps> = ({ color = 'primary', ...props }) => (
  <Heading
    as="h6"
    {...getDefaultHeaderStyles()}
    {...getTypographyStyles(props)}
    color={color}
    fontSize="h6"
    lineHeight="h6"
    {...omitProperties(props, typographyPropNames)}
  />
)

export const LeadText: React.FC<TextProps & TypographyProps> = ({
  color = 'neutral.90',
  ...props
}) => (
  <Text
    as="p"
    fontWeight="medium"
    {...getTypographyStyles(props)}
    color={color}
    fontSize="lead"
    lineHeight="lead"
    margin={0}
    {...omitProperties(props, typographyPropNames)}
  />
)

export const StandardText: React.FC<TextProps & TypographyProps> = ({
  color = 'neutral.90',
  ...props
}) => (
  <Text
    as="p"
    fontWeight="light"
    {...getTypographyStyles(props)}
    color={color}
    fontSize="body"
    lineHeight="body"
    margin={0}
    wordBreak="break-word"
    {...omitProperties(props, typographyPropNames)}
  />
)

export const NoWrap: React.FC<TextProps & TypographyProps> = ({ ...props }) => (
  <Text
    as="span"
    {...getTypographyStyles(props)}
    margin={0}
    {...omitProperties(props, typographyPropNames)}
    whiteSpace="nowrap"
  />
)
