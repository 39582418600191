import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M22 11h-2V4h-7V2h9zM20 20H4V4h5v2H6v12h12v-3h2z" fill="currentColor" />
    <path d="M16.59 6H18v1.41l-7.29 7.3-1.42-1.42z" fill="currentColor" />
  </svg>
)
export default SvgShare
