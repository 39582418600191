import { CaseStatus } from '@brenntag/connect-apis/api-case/models/case'

import { StatusStyle } from '#shared/components/StatusPill/StatusPill.js'
import { Translate } from '#shared/locale/hooks/useTranslations.js'

import { StatusStyle as StatusStyle2 } from './types'

export const getCaseStatusStyles = (t: Translate, status?: CaseStatus): StatusStyle => {
  switch (status) {
    case 'Cancelled':
      return {
        color: 'neutral.30',
        icon: 'IconCircleDelete',
        text: t('label.case.status.Cancelled'),
      }
    case 'Closed':
      return {
        color: 'green.50',
        icon: 'IconCircleCheckmark',
        text: t('label.case.status.Closed'),
      }
    case 'Escalated':
      return {
        color: 'red.60',
        icon: 'IconTrendingUp',
        text: t('label.case.status.Escalated'),
      }
    case 'In Progress':
      return {
        color: 'violet.50',
        icon: 'IconReload',
        text: t('label.case.status.InProgress'),
      }
    case 'In Review':
      return {
        color: 'blue.70',
        icon: 'IconReload',
        text: t('label.case.status.InReview'),
      }
    case 'On Hold':
      return {
        color: 'red.60',
        icon: 'IconCirclePause',
        text: t('label.case.status.OnHold'),
      }
    case 'New':
    default:
      return {
        color: 'blue.50',
        icon: 'IconCircleAlert',
        text: t('label.case.status.New'),
      }
  }
}

{
  /* TODO remove above getInvoiceStatusStyles when we replace status pills in:
  - src/shared/components/GlobalSearch/SearchResults/CaseSearchResult.tsx - line 73
  - src/shared/components/GlobalSearch/ResultItem.tsx - line 24
  */
}
export const getCaseStatusStylesV2 = (t: Translate, status?: CaseStatus): StatusStyle2 => {
  switch (status) {
    case 'Cancelled':
      return {
        icon: 'CircleDelete',
        name: 'danger',
        text: t('label.case.status.Cancelled'),
      }
    case 'Closed':
      return {
        icon: 'CircleCheckmark',
        name: 'neutral',
        text: t('label.case.status.Closed'),
      }
    case 'Escalated':
      return {
        icon: 'TrendingUp',
        name: 'danger',
        text: t('label.case.status.Escalated'),
      }
    case 'In Progress':
      return {
        icon: 'Reload',
        name: 'info',
        text: t('label.case.status.InProgress'),
      }
    case 'In Review':
      return {
        icon: 'Reload',
        name: 'info',
        text: t('label.case.status.InReview'),
      }
    case 'On Hold':
      return {
        icon: 'CirclePause',
        name: 'warning',
        text: t('label.case.status.OnHold'),
      }
    case 'New':
    default:
      return {
        icon: 'CircleAlert',
        name: 'info',
        text: t('label.case.status.New'),
      }
  }
}
