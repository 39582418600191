import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconVolumeMid = createIcon({
  displayName: 'IconVolumeMid',
  path: [
    <path
      d="M8.59 16L14 21.41V13H12V16.59L9.41 14H6V9.99997H9.41L12 7.40997V11H14V2.58997L8.59 7.99997H4V16H8.59Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M16 8.55998V6.34998C18.33 7.17998 20 9.38998 20 12C20 14.61 18.33 16.82 16 17.65V15.44C17.19 14.75 18 13.47 18 12C18 10.53 17.19 9.24998 16 8.55998Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
