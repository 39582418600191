import { authConfig } from '../config'
import { salesforceSession } from './salesforceSession'

/**
 * Logs the user out of Salesforce.
 * This function will remove the access token and ID token from local storage.
 * @todo Consider somehow waiting for logout iframe to succeed before removing tokens. Allowing a loading state.
 */
export const endSession = () => {
  salesforceSession.reset()

  const { domain, pathPrefix } = authConfig.salesforce

  // TODO: use iframe for this? Check safari compatibility
  window.location.href = `https://${domain}${pathPrefix}/services/auth/idp/oidc/logout`
}
