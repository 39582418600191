import { useEffect } from 'react'

const loadedCSS: Record<string, boolean> = {}

export const useCSSLink = (
  url: string,
  { enabled = true }: { enabled: boolean } = { enabled: true },
): void => {
  useEffect(() => {
    if (!enabled || loadedCSS[url]) return
    const link = document.createElement('link')

    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url

    document.body.appendChild(link)
    loadedCSS[url] = true

    return () => {
      document.body.removeChild(link)
    }
  }, [url, enabled])
}
