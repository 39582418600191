import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18 11h4v2h-4zm-2 0h-4v2h4zm-9 5.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2M18 7v2h4V7zm-6.855 8H22v1.277L18.565 22H3.107L2 13h1.992V4h2.001V2h2.001v2h2.001v7H7.994V6H5.993v7h3.99zm8.089 2H9.999l-1.161-2H4.27l.615 5h12.549z"
      fill="currentColor"
    />
  </svg>
)
export default SvgShip
