import { http, HttpResponse } from 'msw'

import {
  DataTypeFavoriteItem,
  DataTypePricebook,
  SingleIncludedProductResourceWithPricebookProductUnion,
} from '@brenntag/connect-apis/api/types'
import { buildProducts } from '@brenntag/legacy-mock-generators/generators/products/buildProducts.js'

import {
  FetchProductsParams,
  productFilters,
  ProductListSortKey,
} from '#shared/apis/api/products/endpoints/fetchProducts.js'
import { config } from '#src/config.js'

import { favoriteLists } from '../favorites/data'
import { products } from './data'

export const fetchProducts = () => {
  return http.get<never, FetchProductsParams>(
    `${config.apiBaseUrl}/api/products`,
    async ({ request }) => {
      let list = products.list()

      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const pricePointExists = searchParams.get(productFilters.pricePointExists)
      if (pricePointExists === 'true') {
        list = list.filter(product => product.data.relationships.pricebookProduct?.data.id)
      }

      const hazardous = searchParams.get(productFilters.hazardous)
      if (hazardous === 'true') {
        list = list.filter(product => product.data.attributes.isHazardous)
      }

      const shipToId = searchParams.get(productFilters.shipToLocationId)
      if (shipToId) {
        list = filterByShipToId(list, shipToId)
      }

      const favoriteListId = searchParams.get(productFilters.favoriteListId)
      if (favoriteListId) {
        list = filterByFavoriteListId(list, favoriteListId)
      }

      const searchQuery = searchParams.get(productFilters.searchQuery)
      if (searchQuery) {
        list = list.filter(product =>
          product.data.attributes.name.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      }

      const sort = searchParams.get('sort') as ProductListSortKey
      list = sortProducts(list, sort)

      return HttpResponse.json(
        buildProducts({
          products: list,
        }),
      )
    },
  )
}

/** Quickest way to determine if a product belongs to a favorites list */
const filterByFavoriteListId = (
  productsList: SingleIncludedProductResourceWithPricebookProductUnion[],
  favoriteListId: string,
): SingleIncludedProductResourceWithPricebookProductUnion[] => {
  const idsToFilter: string[] = []
  const lists = Object.values(favoriteLists.items)

  lists.forEach(list => {
    const favoriteItems = (list.included ?? [])?.filter(
      item =>
        item.type === DataTypeFavoriteItem.FavoriteItem &&
        item.relationships.favorite.data.id === favoriteListId,
    )

    for (const favoriteItem of favoriteItems) {
      idsToFilter.push(favoriteItem.relationships.product.data.id)
    }
  })

  return productsList.filter(product => idsToFilter.includes(product.data.id))
}

const filterByShipToId = (
  productsList: SingleIncludedProductResourceWithPricebookProductUnion[],
  shipToId: string,
): SingleIncludedProductResourceWithPricebookProductUnion[] => {
  const list = productsList.filter(product => {
    const isForShipTo = (product.included ?? []).find(
      include =>
        include.type === DataTypePricebook.Pricebook &&
        include.relationships?.locations?.data.some(item => item.id === shipToId),
    )
    return isForShipTo
  })
  return list
}

const sortProducts = (
  productsList: SingleIncludedProductResourceWithPricebookProductUnion[],
  sort: ProductListSortKey,
): SingleIncludedProductResourceWithPricebookProductUnion[] => {
  const isDescending = sort.startsWith('-')
  switch (sort) {
    case '-name':
    case 'name':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.name || ''
        const bVal = b.data.attributes.name || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-number':
    case 'number':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.number || ''
        const bVal = b.data.attributes.number || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-packaging.name':
    case 'packaging.name':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.packaging.name || ''
        const bVal = b.data.attributes.packaging.name || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    default:
      return productsList
  }
}
