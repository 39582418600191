export const Tag = {
  baseStyle: {
    closeButton: {
      _active: {
        background: 'transparent',
        borderColor: 'transparent',
        boxShadow: 'unset',
      },
      _disabled: {
        cursor: 'default',
        pointerEvents: 'none',
      },
      _focus: {
        background: 'transparent',
        borderColor: 'transparent',
        boxShadow: 'unset',
      },
      cursor: 'pointer',
      marginLeft: 1.5,
      opacity: '1 !important',
    },
    container: {
      _hover: {},
      px: 2,
    },
    label: {
      lineHeight: '16px',
    },
  },
  defaultProps: {
    variant: 'chip-outline',
  },
  parts: ['closeButton', 'label', 'container'],
  variants: {
    'chip-outline': {
      closeButton: {
        _disabled: {
          color: 'neutral.30',
        },
        _focus: {
          color: 'blue.50',
        },
        _hover: {
          color: 'blue.50',
        },
        borderRadius: '50%',
        color: 'currentColor',
      },
      container: {
        backgroundColor: 'white',
        border: `1px solid`,
        borderColor: 'neutral.60',
        borderRadius: 'full',
        color: 'neutral.80',
      },
    },
    'chip-solid': ({ colorScheme }: { colorScheme: string }) => ({
      closeButton: {
        _active: {
          color: colorScheme ? `${colorScheme}.50` : 'neutral.10',
        },
        _disabled: {
          color: 'white',
        },
        _focus: {
          color: colorScheme ? `${colorScheme}.50` : 'neutral.10',
        },
        _hover: {
          color: colorScheme ? `${colorScheme}.50` : 'neutral.10',
        },
        borderRadius: '50%',
        color: 'white',
      },
      container: {
        backgroundColor: colorScheme ? `${colorScheme}.80` : 'primary',
        border: 'none',
        borderRadius: 'full',
        outline: 'none',
      },
      label: {
        color: 'white',
      },
    }),
    error: () => ({
      closeButton: {
        display: 'none',
      },
      container: {
        bg: `red.60`,
        border: 'none',
        borderRadius: '50%',
      },
      label: {
        color: 'white',
        lineHeight: 3,
      },
    }),
    gradient: () => ({
      closeButton: {
        display: 'none',
      },
      container: {
        bgGradient: `linear(to-r, violet.50, blue.50)`,
        border: 'none',
        borderRadius: '50%',
      },
      label: {
        color: 'white',
        lineHeight: 3,
      },
    }),
    'tag-outline': ({ colorScheme }: { colorScheme: string }) => ({
      closeButton: {
        display: 'none',
      },
      container: {
        backgroundColor: 'transparent',
        border: '1px solid #FFCB64',
        borderRadius: 'unset',
        color: '#481A00',
        minW: 12,
      },
      label: {
        color: colorScheme ? `${colorScheme}.80` : 'primary',
      },
    }),
    'tag-shade': ({ colorScheme }: { colorScheme: string }) => ({
      closeButton: {
        display: 'none',
      },
      container: {
        backgroundColor: colorScheme ? `${colorScheme}.10` : 'neutral.10',
        borderRadius: 'unset',
      },
      label: {
        color: colorScheme ? `${colorScheme}.80` : 'primary',
      },
    }),
    'tag-solid': ({ colorScheme }: { colorScheme: string }) => ({
      closeButton: {
        display: 'none',
      },
      container: {
        backgroundColor: colorScheme ? `${colorScheme}.10` : 'neutral.10',
        border: 'none',
        borderRadius: '2xl',
      },
      label: {
        color: colorScheme ? `${colorScheme}.80` : 'primary',
      },
    }),
  },
}
