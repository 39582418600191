import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconBookmark = createIcon({
  displayName: 'IconBookmark',
  path: [
    <path
      d="M20 22.94L12 17.23L4 22.94V2H20V4H6V19.06L12 14.77L18 19.06V6H20V22.94Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
