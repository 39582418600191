import { CartItem, CartItemError } from '@brenntag/connect-apis/api-cart/models/cart'
import { CartItem as APICartItem } from '@brenntag/connect-apis/api-cart/types'
import { Product } from '@brenntag/connect-apis/api/models/product'

import { transformMonetaryAmount } from '#shared/apis/api/shared/transforms/price.transforms.js'

import { transformTanklocations } from './transformTanklocations'

export type TransformCartItemParams = {
  currencyIsoCode?: string
  error?: CartItemError
  item: APICartItem
  product?: Product
}

export const transformCartItem = ({
  currencyIsoCode,
  error,
  item,
  product,
}: TransformCartItemParams): CartItem => ({
  createdDate: item.createdDate,
  error,
  id: item.id,
  name: item.name,
  product,
  productId: item.productId,
  quantity: item.quantity,
  salesPrice: transformMonetaryAmount(item.salesPrice, currencyIsoCode),
  tankLocations: product
    ? transformTanklocations(product.tankLocations, item.tankLocations)
    : undefined,
  totalPrice: transformMonetaryAmount(item.totalPrice, currencyIsoCode),
})
