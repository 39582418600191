import { http, HttpResponse } from 'msw'

import { FetchCartParams } from '@brenntag/api-cart'
import { APICart } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { carts } from './data'

export const fetchCartById = () => {
  return http.get<FetchCartParams, null, APICart>(
    `${config.cartApiBaseUrl}/carts/:cartId`,
    async ({ params }) => {
      const cart = carts[params.cartId || '']
      if (!cart) {
        // what to do?
        return HttpResponse.json(null, { status: 404 })
      }

      return HttpResponse.json(cart)
    },
  )
}
