import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCalendar = createIcon({
  displayName: 'IconCalendar',
  path: [
    <path d="M8 2H6V4H3V6H21V4H18V2H16V4H8V2Z" fill="currentColor" key="0" />,
    <path d="M3 21H21V12H19V19H5V10H21V8H3V21Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
