import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAddTruck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M6 9H4V7H2V5h2V3h2v2h2v2H6z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M16 7h3.54L22 10.7V18h-1c0 1.65-1.35 3-3 3s-3-1.35-3-3H9c0 1.65-1.35 3-3 3s-3-1.35-3-3H2v-7h2v4.78c.53-.48 1.23-.78 2-.78.88 0 1.67.39 2.22 1H14V7h-4V5h6zM5 18c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1m15-6.7L18.46 9H16v6.78c.53-.48 1.23-.78 2-.78s1.47.3 2 .78zM17 18c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgAddTruck
