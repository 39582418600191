import { MonetaryAmount, PricePoint } from '@brenntag/connect-apis/api/models/price'

import { Translate } from '#shared/locale/hooks/useTranslations.js'

export const getSortedPricePoints = (pricePoints: PricePoint[], ascending = true) =>
  [...pricePoints].sort((p1, p2) => {
    const p1ScaleStartQuantity = p1.scaleStart
    const p2ScaleStartQuantity = p2.scaleStart
    return ascending
      ? p1ScaleStartQuantity - p2ScaleStartQuantity
      : p2ScaleStartQuantity - p1ScaleStartQuantity
  })

export const getHighestActivePricePoint = (pricePoints: PricePoint[]) =>
  pricePoints.length > 0
    ? getSortedPricePoints(pricePoints).filter(pricePoint => pricePoint.isActive)[0]
    : undefined

export const getMinimumOrderQuantity = (pricePoints: PricePoint[]): number =>
  getHighestActivePricePoint(pricePoints)?.scaleStart || 1

export const getPricePointIndex = (pricePoints: PricePoint[], quantity: number) => {
  return pricePoints.findIndex(
    (_, i) => i === pricePoints.length - 1 || quantity < pricePoints[i + 1].scaleStart,
  )
}

export const getScalePricePoint = (pricePoints: PricePoint[], quantity: number) =>
  pricePoints.length > 0 ? pricePoints[getPricePointIndex(pricePoints, quantity)] : undefined

export const getTotalPrice = (
  quantity: number,
  pricePoints: PricePoint[],
): MonetaryAmount | undefined => {
  const currentTier = getPricePointIndex(pricePoints, quantity)
  const pricePoint = pricePoints[currentTier]
  if (!pricePoint) return
  return {
    amount: quantity * pricePoint.price.amount,
    currency: pricePoint.price.currency,
  }
}

export const getPercentDifference = (a: PricePoint, b: PricePoint) => {
  return (getPriceDifference(a, b) / a.price.amount) * 100
}

export const getPriceDifference = (a: PricePoint, b: PricePoint) => {
  return a.price.amount - b.price.amount
}

type ScaleFromToUnits = [number, number | undefined]

export const getPriceScaleLabels = (
  pricePoints: PricePoint[],
  t: Translate,
  packageName?: string,
) =>
  pricePoints
    .map<ScaleFromToUnits>((point, index, array) => [
      point.scaleStart,
      array[index + 1] ? array[index + 1].scaleStart - 1 : undefined,
    ])
    .map(([from, to]) => `${from}${to ? `-${to}` : ` ${t('label.ormore')}`} ${packageName}`)
