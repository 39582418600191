import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20.39 11.95c-1 .69-2.18 1.05-3.39 1.05-3.31 0-6-2.69-6-6 0-1.22.36-2.39 1.05-3.39l1.2-1.74-2.1.18C6.02 2.48 2 6.85 2 12c0 2.4.85 4.6 2.26 6.32l1.43-1.43A8 8 0 0 1 4 11.99c0-3.46 2.27-6.48 5.42-7.57C9.14 5.24 9 6.1 9 6.99c0 4.41 3.59 8 8 8 .88 0 1.75-.14 2.57-.42-1.08 3.16-4.11 5.42-7.57 5.42-1.85 0-3.54-.63-4.9-1.69l-1.43 1.43a9.96 9.96 0 0 0 6.32 2.26c5.15 0 9.52-4.02 9.96-9.15l.18-2.1-1.74 1.2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgNight
