import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPricequote = createIcon({
  displayName: 'IconPricequote',
  path: [
    <path d="M8.5 2H4V22H20V2H15.5V4H18V20H6V4H8.5V2Z" fill="currentColor" key="0" />,
    <path
      d="M14 4.67112V3.33556H12.6689V2H11.3378V3.36227C10.6589 3.49583 10.1398 4.09683 10.1398 4.81803V4.95159C10.1398 5.5192 10.4725 6.04675 10.985 6.29382L12.5757 7.04841C12.629 7.07513 12.6622 7.12855 12.6622 7.18197C12.6622 7.2621 12.5957 7.32888 12.5158 7.32888H10V8.66444H11.3311V10H12.6622V8.65109C13.4077 8.57763 13.9933 7.94992 13.9933 7.18197C13.9933 6.61436 13.6606 6.08681 13.1481 5.83973L11.5574 5.08514C11.5042 5.05843 11.4709 5.00501 11.4709 4.95159V4.81803C11.4709 4.7379 11.5374 4.67112 11.6173 4.67112H14Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M16 16H8V18H16V16Z" fill="currentColor" key="2" />,
    <path d="M16 12H8V14H16V12Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
