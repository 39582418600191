import { InvoiceStatus, SingleIncludedInvoiceResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildInvoice } from '@brenntag/legacy-mock-generators/generators/invoices/buildInvoice.js'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

import { orders } from '../../orders/data'

export const invoiceFullyPaidNotClosed: SingleIncludedInvoiceResourceUnion = buildInvoice({
  attributes: {
    grossAmount: {
      amount: 32012,
      currency: 'USD',
    },
    number: '3484343209128',
    paidAmount: {
      amount: 32012,
      currency: 'USD',
    },
    status: InvoiceStatus.Open,
  },
  id: 'invoiceFullyPaidNotClosed',
  included: {
    billTo: buildLocation({ id: locations.showPrices.id }),
    order: orders.items.entered,
    shipTo: buildLocation({ id: locations.showPrices.id }),
  },
})
