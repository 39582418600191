import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPieChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13 4.06V11h8.95c.03.33.05.66.05 1v1H11V2h1c4.47 0 8.26 2.95 9.54 7h-2.15C18.32 6.37 15.91 4.42 13 4.06"
      fill="currentColor"
    />
    <path
      d="M12 20c3.35 0 6.22-2.07 7.41-5h2.13c-1.28 4.05-5.07 7-9.54 7-5.51 0-10-4.49-10-10 0-4.47 2.95-8.26 7-9.54v2.13C6.07 5.78 4 8.65 4 12c0 4.41 3.59 8 8 8"
      fill="currentColor"
    />
  </svg>
)
export default SvgPieChart
