import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconClipboard = createIcon({
  displayName: 'IconClipboard',
  path: [
    <path
      clipRule="evenodd"
      d="M8 8H16V2H8V8ZM14 6H10V4H14V6Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path d="M4 22H20V3H18V20H6V3H4V22Z" fill="currentColor" key="1" />,
    <path d="M16 16H8V18H16V16Z" fill="currentColor" key="2" />,
    <path d="M8 12H16V14H8V12Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
