import * as React from 'react'
import type { SVGProps } from 'react'
const SvgParcelLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M19 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M13 6c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5m2 0c0-1.65 1.35-3 3-3s3 1.35 3 3-1.35 3-3 3-3-1.35-3-3"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M20 12.71v4.57l-7 2.33v-8.72a7.1 7.1 0 0 1-1.41-2.08L2 5.61v13.11l10 3.33 10-3.33v-6.98c-.6.42-1.28.75-2 .97m-9 6.91-7-2.33v-8.9l7 2.33z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path d="m12 1.95.24.08a7.2 7.2 0 0 0-1.03 2.29L7.82 5.45 4.66 4.4z" fill="currentColor" />
  </svg>
)
export default SvgParcelLocation
