import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOrderConfirmed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m15.457 12.707-4.207 4.207-2.707-2.707 1.414-1.414 1.293 1.293 2.793-2.793zM20 6.586V22H4V2h7v2H6v16h12V9h-5V2h2.414zM17.586 7 15 4.414V7z"
      fill="currentColor"
    />
  </svg>
)
export default SvgOrderConfirmed
