import { delay, http, HttpResponse } from 'msw'

import { config } from '#config'

export default () => {
  const { getBaseUrl } = config
  return [
    http.post(`${getBaseUrl()}/request-access`, async () => {
      await delay(2000)
      return HttpResponse.json()
    }),
  ]
}
