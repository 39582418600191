import React from 'react'

import consola from 'consola/browser'
import { isRouteErrorResponse, useRouteError } from 'react-router'

import { UntranslatedErrorPage, UntranslatedErrorPageContent } from './ErrorPage'

export const RootErrorBoundary = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routerError = useRouteError() as any
  consola.error('RootErrorBoundary', routerError)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let error: any | undefined

  if (isRouteErrorResponse(routerError)) {
    error = routerError
  } else {
    // Sometimes we get something back that's not a RouteResponseError
    // in that case convert it to something that resembles it on a best
    // effort basis
    error = {
      status: routerError.status,
      statusText: routerError.statusText || routerError.toString(),
    }
  }

  // eslint-disable-next-line no-console
  console.trace()

  return (
    <UntranslatedErrorPage
      translations={{
        'label.contactus': 'Contact us',
        'label.error': 'Error',
        'label.error.subtitle1': 'We are sorry, but something went wrong.',
        'label.iftheproblempersists': 'If the problem persists',
        'label.somethingwentwrong': 'Something went wrong',
      }}>
      <UntranslatedErrorPageContent
        error={error}
        errorCode={error?.status || 500}
        reason={error?.statusText}
        translations={{
          'label.errorcode': 'Error code',
          'label.reason': 'Reason',
          'label.reloadpage': 'Reload page',
          'label.unauthorized': 'Restart session',
        }}
      />
    </UntranslatedErrorPage>
  )
}
