import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconParcelCheck = createIcon({
  displayName: 'IconParcelCheck',
  path: [
    <path
      d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M18.4991 7.91004L15.7891 5.21004L17.2091 3.79004L18.4991 5.09004L21.2891 2.29004L22.7091 3.71004L18.4991 7.91004Z"
      fill="currentColor"
      key="1"
    />,
    <path
      clipRule="evenodd"
      d="M20 9.99986V17.2799L13 19.6099V10.7199L16 9.71986V7.60986L12 8.93986L2 5.60986V18.7199L12 22.0499L22 18.7199V9.99986H20ZM4 8.38986L11 10.7199V19.6099L4 17.2799V8.38986Z"
      fill="currentColor"
      fillRule="evenodd"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
