import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconInfo = createIcon({
  displayName: 'IconInfo',
  path: [
    <path
      d="M13.5 5.5C13.5 6.32843 12.8284 7 12 7C11.1716 7 10.5 6.32843 10.5 5.5C10.5 4.67157 11.1716 4 12 4C12.8284 4 13.5 4.67157 13.5 5.5Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M13 9V18H16V20H8V18H11V11H9V9H13Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
