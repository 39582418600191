import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconArrowDown = createIcon({
  displayName: 'IconArrowDown',
  path: [
    <path d="M13 15.76L12 16.76L11 15.76V2H13V15.76Z" fill="currentColor" key="0" />,
    <path
      d="M3.28998 13.71L12 22.41L20.71 13.71L19.29 12.29L12 19.59L4.70998 12.29L3.28998 13.71Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
