import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconReload = createIcon({
  displayName: 'IconReload',
  path: [
    <path
      d="M12 21C16.6326 21 20.4476 17.5 20.9451 13H18.9291C18.4439 16.3923 15.5265 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C13.3181 5 14.5511 5.3643 15.6038 5.9977L17 6L16.9911 4.50958C15.5627 3.55595 13.8463 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M15 10H21V4H19V8H15V10Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
