import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M5 22H3V3h19.87l-4.67 7 4.67 7H7v-2h12.13l-3.33-5 3.33-5H5z" fill="currentColor" />
  </svg>
)
export default SvgFlag
