import * as React from 'react'

import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '#utils/cn.js'

const formInputVariants = cva(
  'w-full truncate bg-transparent text-base outline-0 transition-colors placeholder:text-neutral-50',
  {
    defaultVariants: {
      disabled: false,
      hasError: false,
      scale: 'md',
    },
    variants: {
      disabled: {
        false: '',
        true: 'pointer-events-none cursor-not-allowed text-neutral-30 outline-neutral-30 placeholder:text-neutral-30 hover:outline-1 hover:outline-offset-0 hover:outline-neutral-30',
      },
      hasError: {
        false: '',
        true: '',
      },
      scale: {
        lg: 'px-4 py-3.5 text-lg',
        md: 'px-4 py-3 text-base',
        sm: 'px-4 py-2.5 text-sm',
      },
    },
  },
)

export type FormInputType = 'email' | 'hidden' | 'number' | 'password' | 'text'

interface FormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof formInputVariants> {
  autoFocus?: boolean
  disabled?: boolean
  name: string
  placeholder?: string
  type?: FormInputType
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      autoFocus = false,
      className,
      disabled,
      hasError,
      placeholder,
      scale,
      type = 'text',
      ...props
    },
    ref,
  ) => {
    return (
      <input
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        ref={ref}
        type={type}
        {...props}
        className={cn(formInputVariants({ disabled, hasError, scale }), className)}
      />
    )
  },
)

FormInput.displayName = 'FormInput'

export { FormInput, formInputVariants }
