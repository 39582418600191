import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m12 18.41-9.71-9.7 1.42-1.42 8.29 8.3 8.29-8.3 1.42 1.42z" fill="currentColor" />
  </svg>
)
export default SvgChevronDown
