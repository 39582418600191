import { config } from '@brenntag/api-customer/config'

import { salesforceSession } from '#lib/auth/salesforce/salesforceSession.js'
import { config as appConfig } from '#src/config.js'

export const init = () => {
  config.getBaseUrl = () => appConfig.customerApiBaseUrl
  config.getToken = () => salesforceSession.getTokens().idToken ?? 'ERROR: No ID token found'
  config.getSalesforceAccessToken = () =>
    salesforceSession.getTokens().accessToken ?? 'ERROR: No Salesforce token found'
}
