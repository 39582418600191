import { APICart } from '@brenntag/connect-apis/api-cart'
import { Cart } from '@brenntag/connect-apis/api-cart/models/cart'

import { fetchCartProducts } from '#shared/apis/api-cart/cart/endpoints/fetchCartProducts.js'
import { transformAPICartToCart } from '#shared/apis/api-cart/cart/transforms/cart.transforms.js'
import { request } from '#shared/apis/request.js'
import { config } from '#src/config.js'

interface Params {
  cartId: string
  shipToLocationId: string
}

export const fetchCartById = async ({ cartId, shipToLocationId }: Params): Promise<Cart> => {
  const { body } = await request<APICart>({
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
    params: {
      pageSize: 100,
      ship_to_id: shipToLocationId,
    },
    url: `${config.apiBaseUrl}/api-cart/carts/${cartId}`,
  })

  const productList = await fetchCartProducts({ apiCart: body, shipToLocationId })

  return transformAPICartToCart(shipToLocationId, body, productList.products)
}
