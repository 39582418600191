import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSocialFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m17.088 13 .51-3.92h-3.89v-2.5c0-1.13.32-1.9 1.94-1.9h2.06v-3.5q-1.5-.15-3-.15c-3 0-5 1.82-5 5.17v2.89h-3.38V13h3.38v10h4V13z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSocialFacebook
