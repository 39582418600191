import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPrint = createIcon({
  displayName: 'IconPrint',
  path: [
    <path d="M16 6H18V2H6V6H8V4H16V6Z" fill="currentColor" key="0" />,
    <path
      d="M22 18H16V15H8V18H2V8H11V10H4V16H6V13H18V16H20V10H13V8H22V18Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M17 20H7V22H17V20Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
