import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconInbox = createIcon({
  displayName: 'IconInbox',
  path: [
    <path
      d="M21 21H3V10H7.62L9.62 14H14.38L16.38 10H19V5H5V8H3V3H21V12H17.62L15.62 16H8.38L6.38 12H5V19H19V14H21V21Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
