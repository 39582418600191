import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 21H3V3h7v9.13l2-1.33 2 1.33V3h7v2h-5v10.87l-4-2.67-4 2.67V5H5v14h14V7h2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgBook
