import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconThumbsDown = createIcon({
  displayName: 'IconThumbsDown',
  path: [
    <path d="M2 4H4V14H2V4Z" fill="currentColor" key="0" />,
    <path
      d="M8 22H12.41L14 20.41V17H22V6.59L19.41 4H6V6H18.59L20 7.41V15H12V19.59L11.59 20H10V16.7L8 13.7V8H6V14.3L8 17.3V22Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
