import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconPaletts = createIcon({
  displayName: 'IconPaletts',
  path: [
    <path d="M2 4H22V6H2V4Z" fill="currentColor" key="0" />,
    <path d="M22 9H2V11H22V9Z" fill="currentColor" key="1" />,
    <path d="M2 13H22V15H2V13Z" fill="currentColor" key="2" />,
    <path d="M2 18H22V20H2V18Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
