import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypePricebookProduct,
  DataTypePricePoint,
  PricePointAttributes,
  PricePointResource,
} from '@brenntag/connect-apis/api/types'

interface PricePointProps {
  attributes?: Partial<PricePointAttributes>
  id?: string
  price?: number
  relationships: {
    pricebookProductId: string
  }
  scaleStart?: number
}

export const buildPricePoint = ({
  attributes,
  id = faker.string.uuid(),
  price = faker.number.float(),
  relationships,
  scaleStart = 1,
}: PricePointProps): PricePointResource => ({
  attributes: merge(
    {
      createdAt: faker.date.past().toISOString(),
      isActive: true,
      price: {
        amount: price,
        currency: 'USD',
      },
      scaleStart,
      updatedAt: faker.date.past().toISOString(),
    } satisfies PricePointAttributes,
    attributes,
  ),
  id,
  relationships: {
    pricebookProduct: {
      data: {
        id: relationships.pricebookProductId,
        type: DataTypePricebookProduct.PricebookProduct,
      },
    },
  },
  type: DataTypePricePoint.PricePoint,
})

interface Props {
  max?: number
  min?: number
  relationships?: {
    pricebookProductId?: string
  }
}

export const buildPricePoints = ({
  max = 10,
  min = 1,
  relationships,
}: Props): PricePointResource[] =>
  Array.from({ length: faker.number.int({ max, min }) }, (_, index) =>
    buildPricePoint({
      attributes: {
        price: {
          amount: faker.number.float({ max: 100 * (index + 1), min: 100 * index + 1 }),
          currency: 'USD',
        },
        scaleStart: 1 + faker.number.int({ max: 10 * (index + 1), min: 10 * index + 1 }) * index,
      },
      relationships: {
        pricebookProductId: relationships?.pricebookProductId ?? faker.string.uuid(),
      },
    }),
  )
