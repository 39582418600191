import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChevronUp = createIcon({
  displayName: 'IconChevronUp',
  path: [
    <path
      d="M20.29 16.71L12 8.41L3.70999 16.71L2.28999 15.29L12 5.59L21.71 15.29L20.29 16.71Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
