import { UserRole, UserStatus } from '@brenntag/connect-apis/api-user/types'
import { PhoneType } from '@brenntag/connect-apis/api/types'
import { buildUser } from '@brenntag/legacy-mock-generators/generators/buildUser.js'
import { buildWebstore } from '@brenntag/legacy-mock-generators/generators/buildWebstore.js'

import { webstore } from '#src/mocks/apis/customer-api/data/webstores.js'

const items = {
  customer: buildUser({
    attributes: {
      auth0Id: 'auth0|6627d23c9622a9093bad00b0',
      email: 'customer@brenntag.com',
      firstName: 'Customer',
      lastName: 'Brenntag',
      phoneNumbers: [
        {
          isPrimary: true,
          number: '1234567890',
          type: PhoneType.Mobile,
        },
      ],
      role: UserRole.Customer,
      status: UserStatus.Active,
      username: 'customerusername@brenntag.com',
    },
    id: 'customer',
    included: {
      webstore: buildWebstore(webstore.us),
    },
  }),
}

export const users = {
  items,
}
