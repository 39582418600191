import { ServiceDetails, ServiceType } from '@brenntag/connect-apis/api-vas/models/service'
import { VasSearchResponseFilters as ApiVasSearchResponseFilters } from '@brenntag/connect-apis/api-vas/types'
import {
  DateRangeFilterValue,
  DateRangeSelectedValue,
  VasGetResponse,
  VasSearchRequestFilterType,
  VasStatus,
} from '@brenntag/connect-apis/api-vas/types'

import { trimLeadingZeros } from '#shared/utils/misc.js'

interface TermFilterValue {
  count: number
  value: string
}

export const transformGetVasResponseToService = (apiService: VasGetResponse): ServiceDetails => ({
  accountManagerName: apiService.accountManagerName,
  application: apiService.application,
  createdDate: apiService.createdDate,
  description: apiService.description,
  finishedDate: apiService.finishedDate,
  id: apiService.id,
  number: trimLeadingZeros(apiService.number) ?? '',
  projectedCompletionDate: apiService.projectedCompletionDate,
  requiredDate: apiService.requiredDate,
  status: apiService.status as VasStatus,
  type: apiService.type as ServiceType,
})

export interface VasDateRangeFilter {
  name: string
  selectedValue?: DateRangeSelectedValue
  type: VasSearchRequestFilterType.DateRange
  values: DateRangeFilterValue[]
}

export interface VasSearchRequestTermFilter {
  name: string
  selectedValue?: string[]
  type: VasSearchRequestFilterType.Term
  values: TermFilterValue[]
}

export type VasSearchResponseFiltersOutput = VasDateRangeFilter | VasSearchRequestTermFilter

export interface VasTermFilterType {
  name: string
  selectedValue?: string[]
  type: VasSearchRequestFilterType.Term
  values: TermFilterValue[]
}
export const isVasTermFilter = (filter: ApiVasSearchResponseFilters): filter is VasTermFilterType =>
  filter.type === VasSearchRequestFilterType.Term

export const transformParamsToVasFilter = (
  apiFilters: ApiVasSearchResponseFilters[],
): VasSearchResponseFiltersOutput[] =>
  apiFilters.map(filter => {
    if (isVasTermFilter(filter)) {
      return {
        name: filter.name,
        selectedValue: filter?.selectedValue,
        type: VasSearchRequestFilterType.Term,
        values: filter.values,
      }
    }
    return {
      name: filter.name,
      selectedValue: filter?.selectedValue,
      type: VasSearchRequestFilterType.DateRange,
      values: filter.values,
    } as VasSearchResponseFiltersOutput
  })
