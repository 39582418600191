import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTouchscreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.259 12.627A7.45 7.45 0 0 0 20.5 8.5C20.5 4.364 17.136 1 13 1 9.032 1 5.775 4.097 5.517 8h2.006A5.51 5.51 0 0 1 13 3c3.032 0 5.5 2.468 5.5 5.5 0 1.265-.43 2.432-1.151 3.363L16 11.323V8c0-1.654-1.346-3-3-3s-3 1.346-3 3v3.414L8.586 10H6.349L3.71 12.595 9.964 22H21v-8.677zM19 20h-7.964L6.29 12.862 7.167 12h.591L12 16.242V8a1 1 0 0 1 2 0v4.677l5 2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgTouchscreen
