import { http, HttpResponse } from 'msw'

import {
  DataTypeOrder,
  ManyIncludedInvoiceResourceUnion,
  SingleIncludedInvoiceResourceUnion,
} from '@brenntag/connect-apis/api/types'
import { buildInvoices } from '@brenntag/legacy-mock-generators/generators/invoices/buildInvoices.js'

import {
  invoiceFilters,
  InvoiceListSortKey,
} from '#shared/apis/api/invoices/endpoints/fetchInvoices.js'
import { config } from '#src/config.js'

import { invoices } from './data'

export const fetchInvoices = () => {
  return http.get<never, null, ManyIncludedInvoiceResourceUnion>(
    `${config.apiBaseUrl}/api/invoices`,
    async ({ request }) => {
      let list = [...invoices.list]
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const orderId = searchParams.get(invoiceFilters.orderId)
      if (orderId) {
        list = list.filter(invoice =>
          invoice.included?.some(
            include => include.type === DataTypeOrder.Order && include.id === orderId,
          ),
        )
      }

      const status = searchParams.get(invoiceFilters.status)
      if (status) {
        list = list.filter(invoice => invoice.data.attributes.status === status)
      }

      const searchQuery = searchParams.get(invoiceFilters.searchQuery)
      if (searchQuery) {
        list = list.filter(invoice => invoice.data.attributes.number?.includes(searchQuery))
      }

      const sort = searchParams.get('sort') as InvoiceListSortKey
      list = sortInvoices(list, sort)

      return HttpResponse.json(buildInvoices({ invoices: list }))
    },
  )
}

const sortInvoices = (
  productsList: SingleIncludedInvoiceResourceUnion[],
  sort: InvoiceListSortKey,
): SingleIncludedInvoiceResourceUnion[] => {
  const isDescending = sort.startsWith('-')
  switch (sort) {
    case '-dueDate':
    case 'dueDate':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.dueDate || ''
        const bVal = b.data.attributes.dueDate || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    case '-grossAmount.amount':
    case 'grossAmount.amount':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.grossAmount.amount || 0
        const bVal = b.data.attributes.grossAmount.amount || 0

        const comparison = aVal - bVal
        return isDescending ? comparison : -comparison
      })
    case '-invoiceDate':
    case 'invoiceDate':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.invoiceDate || ''
        const bVal = b.data.attributes.invoiceDate || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    case '-lastUpdateDate':
    case 'lastUpdateDate':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.updatedAt || ''
        const bVal = b.data.attributes.updatedAt || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    default:
      return productsList
  }
}
