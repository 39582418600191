import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFolderPlus = createIcon({
  displayName: 'IconFolderPlus',
  path: [
    <path
      d="M22 21H2V3H10.41L12.41 5H22V7H11.59L9.59 5H4V19H20V9H22V21Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M11 12H8V14H11V17H13V14H16V12H13V9H11V12Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
