import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Outlet } from 'react-router'

export type InternalLoaderFC = FC<{ onReady: () => void }>

export const PrerequisiteLoader: FC<{ Loaders: InternalLoaderFC | InternalLoaderFC[] }> = ({
  Loaders,
}) => {
  const loaders = useMemo(() => (Array.isArray(Loaders) ? Loaders : [Loaders]), [Loaders])

  const [currentLoaderIndex, setCurrentLoaderIndex] = useState(0)
  const CurrentLoader = useMemo(() => loaders[currentLoaderIndex], [loaders, currentLoaderIndex])

  const [areReady, setAreReady] = useState(loaders.map(() => false))
  const [isReady, setIsReady] = useState(loaders.length === 0 || areReady.every(Boolean))

  const onReady = useCallback(
    (index: number) => {
      setAreReady(loaderStates => loaderStates.map((state, i) => (i === index ? true : state)))
      if (index === loaders.length - 1) return setIsReady(true)
      setCurrentLoaderIndex(index + 1)
    },
    [loaders.length],
  )

  useEffect(() => {
    if (isReady || !areReady.every(Boolean)) return
    setIsReady(true)
  }, [areReady, isReady])

  return isReady ? <Outlet /> : <CurrentLoader onReady={() => onReady(currentLoaderIndex)} />
}
