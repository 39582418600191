import { http, HttpResponse } from 'msw'

import {
  DataTypeFavoriteItem,
  SingleIncludedProductResourceWithPricebookProductUnion,
} from '@brenntag/connect-apis/api/types'

import { FetchProductParams } from '#shared/apis/api/products/endpoints/fetchProduct.js'
import { config } from '#src/config.js'

import { favoriteLists } from '../favorites/data'
import { products } from './data'

export const fetchProduct = () => {
  return http.get<
    { id: string },
    FetchProductParams,
    SingleIncludedProductResourceWithPricebookProductUnion
  >(`${config.apiBaseUrl}/api/products/:id`, async ({ params }) => {
    const id = params.id as keyof typeof products.items

    if (!products.items[id]) {
      return HttpResponse.json(null, { status: 404 })
    }

    const product = products.items[id]

    return HttpResponse.json(enhanceProductWithFavoriteItems(product))
  })
}

const enhanceProductWithFavoriteItems = (
  product: SingleIncludedProductResourceWithPricebookProductUnion,
): SingleIncludedProductResourceWithPricebookProductUnion => {
  // Find all favorite lists this product belongs to and get the FavoriteItem resources
  const favoriteItems = Object.values(favoriteLists.items).flatMap(list => {
    return (list.included ?? [])?.filter(
      item =>
        item.type === DataTypeFavoriteItem.FavoriteItem &&
        item.relationships.product.data.id === product.data.id,
    )
  })

  const enhancedProduct: SingleIncludedProductResourceWithPricebookProductUnion = {
    ...product,
    data: {
      ...product.data,
      relationships: {
        ...product.data.relationships,
        favoriteItems: { data: favoriteItems.map(item => ({ id: item.id, type: item.type })) },
      },
    },
    included: [...(product.included ?? []), ...favoriteItems],
  }
  return enhancedProduct
}
