import { cn } from '@brenntag/ui/utils/cn'

import { environment, environmentColor, isMockMode } from '#src/env.js'

export const InfoWidget = () => {
  const firstLetter = isMockMode ? 'M' : environment[0].toUpperCase()
  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={cn(
        `bg-${environmentColor}-50 flex items-center gap-1 rounded-full pr-4 text-white`,
      )}
      data-testid="header-app-version">
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={cn(
          `bg-${environmentColor}-80 flex size-10 items-center justify-center rounded-full`,
        )}>
        {firstLetter}
      </div>
      <div className="py-1 text-left text-xs">
        <p>
          {__VERSION__} {__COMMIT_TAG__ ? <span>({__COMMIT_TAG__})</span> : null}
        </p>
        <p>{__COMMIT_HASH__}</p>
      </div>
    </div>
  )
}
