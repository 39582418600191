import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconMail = createIcon({
  displayName: 'IconMail',
  path: [
    <path
      d="M22 20H2V9.12999L4 10.9V18H20V8.22999L12 15.34L2 6.44999V3.99999H18.73L16.48 5.99999H4.51L12 12.66L22 3.76999V20Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
