import { http, HttpResponse } from 'msw'

import {
  ManyIncludedOrderResourceUnion,
  SingleIncludedOrderResourceUnion,
} from '@brenntag/connect-apis/api/types'
import { buildOrders } from '@brenntag/legacy-mock-generators/generators/orders/buildOrders.js'

import { orderFilters, OrderListSortKey } from '#shared/apis/api/orders/endpoints/fetchOrders.js'
import { config } from '#src/config.js'

import { orders } from './data'

export const fetchOrders = () => {
  return http.get<never, null, ManyIncludedOrderResourceUnion>(
    `${config.apiBaseUrl}/api/orders`,
    async ({ request }) => {
      let list = orders.list()

      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get(orderFilters.shipToLocationId)
      if (shipToId) {
        list = list.filter(order => order.data.relationships.shipTo?.data.id === shipToId)
      }

      const status = searchParams.get(orderFilters.status)
      if (status) {
        list = list.filter(order => order.data.attributes.status === status)
      }

      const searchQuery = searchParams.get(orderFilters.searchQuery)
      if (searchQuery) {
        list = list.filter(order =>
          order.data.attributes.salesOrderNumber?.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      }

      const sort = searchParams.get('sort') as OrderListSortKey
      list = sortOrders(list, sort)

      return HttpResponse.json(buildOrders({ orders: list }))
    },
  )
}

const sortOrders = (
  productsList: SingleIncludedOrderResourceUnion[],
  sort: OrderListSortKey,
): SingleIncludedOrderResourceUnion[] => {
  const isDescending = sort.startsWith('-')
  switch (sort) {
    case '-lastUpdateDate':
    case 'lastUpdateDate':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.updatedAt || ''
        const bVal = b.data.attributes.updatedAt || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-orderCreationDate':
    case 'orderCreationDate':
      return productsList.sort((a, b) => {
        const aVal = a.data.attributes.orderCreationDate || ''
        const bVal = b.data.attributes.orderCreationDate || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    default:
      return productsList
  }
}
