import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.5 6.5c0-.32-.07-.63-.18-.91l1.48-1.48c.44.7.7 1.51.7 2.39 0 2.48-2.02 4.5-4.5 4.5S7.5 8.98 7.5 6.5 9.52 2 12 2c.88 0 1.7.26 2.39.7l-1.48 1.48A2.5 2.5 0 0 0 12 4a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 2.5-2.5M3 11v10h18V11h-5v2h3v6H5v-6h3v-2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUser
