import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSocialTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.9 8.137c.013.175.013.35.013.526 0 5.382-4.097 11.588-11.588 11.588v-.003a11.53 11.53 0 0 1-6.243-1.826q.483.058.97.059a8.18 8.18 0 0 0 5.057-1.747 4.08 4.08 0 0 1-3.804-2.828 4.06 4.06 0 0 0 1.838-.07 4.07 4.07 0 0 1-3.267-3.992v-.052c.567.316 1.2.49 1.849.51a4.08 4.08 0 0 1-1.26-5.438 11.56 11.56 0 0 0 8.393 4.255 4.077 4.077 0 0 1 6.94-3.715 8.2 8.2 0 0 0 2.586-.988 4.1 4.1 0 0 1-1.79 2.252 8.1 8.1 0 0 0 2.339-.641 8.3 8.3 0 0 1-2.032 2.11"
      fill="currentColor"
    />
  </svg>
)
export default SvgSocialTwitter
