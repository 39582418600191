import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTrophy = createIcon({
  displayName: 'IconTrophy',
  path: [
    <path
      d="M22 8V4H18V2H13V4H16V13.7L14.46 16H9.54L8 13.7V4H11V2H6V4H2V8H4V6H6V12H5.41L4 10.59V10H2V11.41L4.59 14H6V14.3L8.46 18H11V20H7V22H17V20H13V18H15.54L18 14.3V14H19.41L22 11.41V10H20V10.59L18.59 12H18V6H20V8H22Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
