import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPuzzle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 21h-8v-1.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5V21H4v-6.04a3.51 3.51 0 0 1-3-3.46 3.51 3.51 0 0 1 3-3.46V4h5.04c.24-1.69 1.7-3 3.46-3s3.22 1.31 3.46 3H21v2h-7V4.5c0-.83-.67-1.5-1.5-1.5S11 3.67 11 4.5V6H6v4H4.5c-.83 0-1.5.67-1.5 1.5S3.67 13 4.5 13H6v6h2.04c.24-1.69 1.7-3 3.46-3s3.22 1.31 3.46 3H19v-2.04a3.51 3.51 0 0 1-3-3.46 3.51 3.51 0 0 1 3-3.46V8h2v4h-1.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5H21z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPuzzle
