export enum QueryCacheKey {
  AlternativeSizes = 'AlternativeSizes',
  AttachmentPreview = 'AttachmentPreview',
  Attachments = 'Attachments',
  Authentication = 'Authentication',
  Cart = 'Cart',
  Case = 'Case',
  Cases = 'Cases',
  Checkout = 'Checkout',
  Customers = 'Customers',
  Documents = 'Documents',
  FavoriteProducts = 'FavoriteProducts',
  Favorites = 'Favorites',
  Invoice = 'Invoice',
  InvoiceDocuments = 'InvoiceDocuments',
  Invoices = 'Invoices',
  LoggedInUser = 'LoggedInUser',
  OrderDetails = 'OrderDetails',
  OrderDetailsTracking = 'OrderDetailsTracking',
  OrderLogs = 'OrderLogs',
  OrderProducts = 'OrderProducts',
  Orders = 'Orders',
  PaymentDetails = 'PaymentDetails',
  Product = 'Product',
  ProductDocuments = 'ProductDocuments',
  Products = 'Products',
  Profiles = 'Profiles',
  Quote = 'Quote',
  Quotes = 'Quotes',
  SalesGroup = 'SalesGroup',
  SalesGroups = 'SalesGroups',
  Samples = 'Samples',
  Search = 'Search',
  Service = 'Service',
  Services = 'Services',
  UserDocuments = 'UserDocuments',
  Users = 'Users',
}
