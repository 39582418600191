import consola from 'consola/browser'
import { http, HttpResponse } from 'msw'

import { APICheckout } from '@brenntag/connect-apis/api-cart'

import { StartCheckoutParams } from '#shared/apis/api-cart/orders/endpoints/startCheckout.js'
import { config } from '#src/config.js'

import { locations } from '../customer-api/data/locations'
import { carts, checkouts } from './data'

export const postOrder = () => {
  return http.post<never, StartCheckoutParams, APICheckout>(
    `${config.apiBaseUrl}/api-cart/orders`,
    async ({ request }) => {
      const body = await request.json()
      const cartId = body.cartId
      const cart = carts[cartId]

      if (!cart) {
        consola.log('Cart not found')
        return HttpResponse.json(null, { status: 404 })
      }
      const shipTo = Object.values(locations).find(location => location.id === body.cartId)

      if (!shipTo) {
        consola.log('ShipTo not found')
        return HttpResponse.json(null, { status: 404 })
      }
      // delete current cart for this shipTo
      const checkout: APICheckout = {
        cartId,
        currencyIsoCode: cart.currencyIsoCode || 'USD',
        orderDate: new Date().toISOString(),
        orderId: cartId,
        orderStatus: 'Open',
        poNumber: body.poNumber,
        resellerPoNumber: body.resellerPoNumber,
        salesOrderNumber: Date.now().toString(),
        shippingAddress: {
          city: shipTo.address.city,
          country: shipTo.address.country,
          locationId: shipTo.id,
          name: shipTo.name,
          postalCode: shipTo.address.postalCode,
          state: shipTo.address.state,
          street: shipTo.address.street,
        },
      }
      checkouts[cartId] = checkout
      return HttpResponse.json(checkout, { status: 200 })
    },
  )
}
