import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m9.02 20.78 3.997-18 1.952.433-3.997 18.001zM6.29 16.71 1.59 12l4.7-4.71 1.42 1.42L4.41 12l3.3 3.29zM17.71 16.71l-1.42-1.42 3.3-3.29-3.3-3.29 1.42-1.42 4.7 4.71z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCode
