import * as React from 'react'
import type { SVGProps } from 'react'
const SvgGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M15 3c-.41 0-.79.07-1.16.17.48.54.88 1.15 1.16 1.83a2.5 2.5 0 0 1 0 5c-.28.68-.68 1.29-1.16 1.83.37.1.76.17 1.16.17 2.48 0 4.5-2.02 4.5-4.5S17.48 3 15 3M2 13v8h14v-8h-5v2h3v4H4v-4h3v-2zM18 19v2h4v-8h-4v2h2v4zM11.5 7.5c0-.32-.07-.63-.18-.91l1.48-1.48c.44.7.7 1.51.7 2.39 0 2.48-2.02 4.5-4.5 4.5S4.5 9.98 4.5 7.5 6.52 3 9 3c.88 0 1.7.26 2.39.7L9.91 5.18A2.5 2.5 0 0 0 9 5a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 2.5-2.5"
      fill="currentColor"
    />
  </svg>
)
export default SvgGroup
