import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDialogue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M2 2h16v2H4v11H2z" fill="currentColor" />
    <path
      d="M22 6v2H8v9h3.41L14 19.59 16.59 17H20v-7h2v9h-4.59L14 22.41 10.59 19H6V6z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDialogue
