import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTrendingDown = createIcon({
  displayName: 'IconTrendingDown',
  path: [
    <path
      d="M16.5891 14H17.9991V12.59L12.9991 7.59004L8.49906 12.09L2.70906 6.29004L1.28906 7.71004L8.49906 14.91L12.9991 10.41L16.5891 14Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M20 10H22V18H14V16H20V10Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
