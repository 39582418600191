import { http, HttpResponse } from 'msw'

import { CaseSearchRequest, CaseSearchResponse } from '@brenntag/connect-apis/api-case/types'
import { buildCaseSearch } from '@brenntag/legacy-mock-generators/generators/cases/buildCases.js'

import { config } from '#src/config.js'

import { cases } from './data/cases'

export const searchCases = () => {
  return http.post<never, CaseSearchRequest, CaseSearchResponse>(
    `${config.apiGatewayUrl}/cases/search`,
    async ({ request }) => {
      const body = await request.json()
      return HttpResponse.json(
        buildCaseSearch({
          cases: cases.list(),
          filters: body.filters ?? [],
          searchQuery: body.searchQuery,
          sort: body.sort,
        }),
      )
    },
  )
}
