import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconGlobe = createIcon({
  displayName: 'IconGlobe',
  path: [
    <path
      d="M19.74 5.68L18.31 7.1C19.1922 8.22992 19.752 9.57759 19.93 11H16C15.9726 8.77905 15.5598 6.57973 14.78 4.5C15.5432 4.78634 16.2581 5.18764 16.9 5.69L18.32 4.26C16.3332 2.63746 13.7989 1.84045 11.241 2.03377C8.68319 2.22708 6.29744 3.39594 4.57709 5.29865C2.85673 7.20136 1.93333 9.69242 1.99782 12.2567C2.06232 14.8211 3.10977 17.2626 4.92359 19.0764C6.73742 20.8902 9.17891 21.9377 11.7432 22.0022C14.3076 22.0667 16.7986 21.1433 18.7013 19.4229C20.6041 17.7025 21.7729 15.3168 21.9662 12.759C22.1595 10.2011 21.3625 7.66678 19.74 5.68ZM12 20C11.52 20 10.19 17.6 10 13H14C13.81 17.6 12.48 20 12 20ZM14 11H10C10.17 6.4 11.5 4 12 4C12.5 4 13.81 6.4 14 11ZM9.23999 4.5C8.4534 6.57852 8.03383 8.77787 7.99999 11H4.07C4.25424 9.54712 4.83377 8.1727 5.74541 7.02653C6.65706 5.88036 7.86583 5.00643 9.23999 4.5ZM4.07 13H7.99999C8.02743 15.2209 8.44023 17.4203 9.22 19.5C7.84957 18.9906 6.64495 18.1154 5.737 16.9694C4.82906 15.8235 4.25251 14.4506 4.07 13ZM14.76 19.5C15.5466 17.4215 15.9662 15.2221 16 13H19.95C19.764 14.4552 19.1815 15.8312 18.2662 16.9775C17.3508 18.1239 16.1379 18.9966 14.76 19.5Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
