import { atom } from 'jotai'

import { OrderFilters } from '@brenntag/connect-apis/api/models/order'

const orderFilterDefaults: OrderFilters = {
  page: '1',
  sort: '-lastUpdateDate',
} as const

export const orderFilters = atom<OrderFilters>(orderFilterDefaults)
