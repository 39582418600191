import React, { cloneElement, createRef, useMemo, useRef } from 'react'

import { Box, TabList, TabListProps } from '@chakra-ui/react'

import { TabListController } from '#shared/components/GlobalSearch/TabListController.js'

const noScrollStyle = {
  '::-webkit-scrollbar': {
    background: 'transparent',
    display: 'none',
    width: 0,
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
}

interface ScrollingTabListProps extends TabListProps {
  children: JSX.Element[]
  controlsOffsetX?: string
  controlsOffsetY?: string
  fixedScrollBy?: number
}
export const ScrollingTabList = ({
  children,
  controlsOffsetX,
  controlsOffsetY,
  fixedScrollBy,
  ...props
}: ScrollingTabListProps) => {
  const tabList = useRef<HTMLDivElement>(null)

  const refs = useMemo(() => React.Children.map(children, () => createRef<Element>()), [children])
  const childrenWithRef = useMemo(
    () =>
      React.Children.map(children, (element, index) =>
        cloneElement(element, { ...element.props, ref: refs[index] }),
      ),
    [children, refs],
  )

  return (
    <Box>
      <TabListController
        elementRefs={refs}
        fixedScrollBy={fixedScrollBy}
        left={controlsOffsetX}
        targetRef={tabList}
        top={controlsOffsetY}
        {...props}
      />
      <TabList
        m={0}
        overflowX="auto"
        overflowY="hidden"
        p={0}
        ref={tabList}
        sx={noScrollStyle}
        {...props}>
        {childrenWithRef}
      </TabList>
    </Box>
  )
}
