import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFileCode = createIcon({
  displayName: 'IconFileCode',
  path: [
    <path
      clipRule="evenodd"
      d="M15.41 2H13V9H18V20H6V4H11V2H4V22H20V6.59L15.41 2ZM15 7V4.41L17.59 7H15Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path
      d="M14.7091 17.71L17.4091 15L14.7091 12.29L13.2891 13.71L14.5891 15L13.2891 16.29L14.7091 17.71Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M6.58984 15L9.28984 12.29L10.7098 13.71L9.40984 15L10.7098 16.29L9.28984 17.71L6.58984 15Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
