import React from 'react'

import { Box } from '@chakra-ui/react'

interface Props {
  isOpen: boolean
}
export const Backdrop = ({ isOpen }: Props) => (
  <Box
    backgroundColor="black"
    bottom={0}
    height="100vh"
    left={0}
    opacity={isOpen ? '20%' : '0%'}
    pointerEvents="none"
    position="absolute"
    right={0}
    top={0}
    transition="opacity 0.25s"
    zIndex={2}
  />
)
