import { VasSearchResponse } from '@brenntag/connect-apis/api-vas/types'

import { VasFilterType } from '#shared/apis/api-vas/transforms/filtersToParams.js'
import { filtersToParams } from '#shared/apis/api-vas/transforms/filtersToParams.js'
import { request } from '#shared/apis/request.js'
import { generateTotalPages } from '#shared/utils/pagination.js'
import { config } from '#src/config.js'

import { transformParamsToVasFilter } from '../transforms/service.transforms'

export const serviceListSortKeys = [
  'serviceNumber',
  '-serviceNumber',
  '-requiredDate',
  'requiredDate',
  '-createdDate',
  'createdDate',
] as const

export type ServiceListSortKey = (typeof serviceListSortKeys)[number]

interface Params {
  filters: VasFilterType[]
  pageNumber: number
  pageSize: number
  searchQuery: string
  sort?: string
}

export const fetchServices = async ({
  filters = [],
  pageNumber,
  pageSize,
  searchQuery = '',
  sort,
}: Params) => {
  const response = await request<VasSearchResponse>({
    body: {
      filters: filtersToParams(filters),
      page: {
        number: pageNumber,
        size: pageSize,
      },
      searchQuery,
      sort,
    },
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    url: `${config.apiGatewayUrl}/vas-service/search`,
  })
  return {
    ...response.body,
    filters: transformParamsToVasFilter(response.body.filters),
    totalPages: generateTotalPages(response.body.page.total, pageSize),
  }
}
