import { SingleIncludedQuoteResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildQuote } from '@brenntag/legacy-mock-generators/generators/quotes/buildQuote.js'
import { buildQuoteLine } from '@brenntag/legacy-mock-generators/generators/quotes/buildQuoteLine.js'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

import { products } from '../../products/data'

export const quoteForProductWithManyPricePoints: SingleIncludedQuoteResourceUnion = buildQuote({
  id: 'quoteForProductWithManyPricePoints',
  included: {
    quoteLines: [
      [
        buildQuoteLine({
          included: { product: products.items.manyPricePoints.data },
          quoteId: 'quote',
        }),
        products.items.manyPricePoints,
      ],
    ],
    shipTo: buildLocation({
      attributes: { name: locations.showPrices.name },
      id: locations.showPrices.id,
    }),
  },
})
