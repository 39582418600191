import { FC } from 'react'

import { Close, Content, Dialog, DialogTitle, Overlay, Portal } from '@radix-ui/react-dialog'

import { Button } from '@brenntag/ui/components/Button'
import { Icon } from '@brenntag/ui/components/Icon'
import { cn } from '@brenntag/ui/utils/cn'

import { toggleMocks } from '#src/mocks/browser.js'

import { DebugDialogContents } from './DebugPanelContents'

type Props = {
  isOpen: boolean
  onOpenChange: () => undefined | void
}

const DebugPanel: FC<Props> = ({ isOpen, onOpenChange }) => {
  return (
    <Dialog onOpenChange={onOpenChange} open={isOpen}>
      <Portal>
        <Overlay className="fixed inset-0 z-[100] w-full bg-black/20 data-[state=open]:animate-overlayShow" />
        <Content
          aria-describedby={undefined}
          className={cn(
            'fixed left-1/2 top-1/2 z-[100] max-h-[95vh] w-11/12 max-w-full -translate-x-1/2 -translate-y-1/2 overflow-auto rounded-sm bg-white p-6 shadow-lg focus:outline-none md:max-h-[85vh] md:min-h-[80%] md:w-[80vw] md:max-w-3xl',
          )}>
          <Close asChild>
            <button className="absolute right-5 top-5 z-[2]">
              <Icon name="Close" />
            </button>
          </Close>
          <DialogTitle className="-mx-6 mb-4 border-b px-6 pb-4 text-xl font-bold">
            Debug Info
          </DialogTitle>
          <Button onClick={toggleMocks}>Close</Button>
          <DebugDialogContents />
        </Content>
      </Portal>
    </Dialog>
  )
}
export default DebugPanel
