import { http, HttpResponse } from 'msw'

import { QuoteRequest } from '@brenntag/connect-apis/api-object/types'
import { QuoteStatus } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildQuote } from '@brenntag/legacy-mock-generators/generators/quotes/buildQuote.js'

import { config } from '#src/config.js'

import { products } from '../../api/products/data'
import { quotes } from '../../api/quotes/data'
import { locations } from '../../customer-api/data/locations'

export const postRequestQuote = () => {
  return http.post<never, QuoteRequest, null>(
    `${config.apiBaseUrl}/api-object/requests/quotes`,
    async ({ request }) => {
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      const body = await request.json()
      const product = products.list().find(product => product.data.id === body.productId)
      const shipTo = Object.values(locations).find(location => location.id === shipToId)

      if (!product) {
        return HttpResponse.json(null, { status: 404 })
      }

      if (!shipTo) {
        return HttpResponse.json(null, { status: 404 })
      }

      const quote = buildQuote({
        attributes: {
          commentFromUser: body.comment || '',
          createdAt: new Date().toISOString(),
          incoTerms: body.incoterm,
          lastUpdateDate: new Date().toISOString(),
          number: body.quantity.toString(),
          quoteCreationDate: new Date().toISOString(),
          requestedDeliveryDate: new Date().toISOString(),
          status: QuoteStatus.Processing,
          updatedAt: new Date().toISOString(),
        },
        included: {
          attachments: null,
          products: [product],
          shipTo: buildLocation({ id: shipTo.id }),
        },
      })

      quotes.items[quote.data.id as keyof typeof quotes.items] = quote

      return HttpResponse.json(null, { status: 201 })
    },
  )
}
