import React, { FC, ReactNode } from 'react'

import * as Dialog from '@radix-ui/react-dialog'

import { Icon } from '#components/Icon.js'

interface Props {
  children: ReactNode
  onOpenChange?: () => void
  open: boolean
}

export const Modal: FC<Props> = ({ children, onOpenChange, open }) => {
  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-10 w-full bg-black/20">
          <Dialog.Content className="fixed left-1/2 top-1/2 max-h-[95vh] w-11/12 max-w-full -translate-x-1/2 -translate-y-1/2 overflow-auto rounded-sm bg-white p-6 shadow-lg focus:outline-none md:max-h-[85vh] md:w-fit md:max-w-3xl">
            <Dialog.Close asChild>
              <button className="absolute right-5 top-5 z-[2]">
                <Icon name="Close" />
              </button>
            </Dialog.Close>
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
