import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSettings = createIcon({
  displayName: 'IconSettings',
  path: [
    <path
      d="M18.5 4.09L17.09 2.67L15.3041 4.45592L14.73 4.22L13.62 2H10.38L9.27 4.22L8.54 4.52L6.34 3.25L3.25 6.34L4.52 8.54L4.22 9.27L2 10.38V13.62L4.22 14.73L4.46 15.3L2.67 17.09L4.09 18.5L6.82 15.77L5.78 13.27L4 12.38V11.62L5.78 10.73L6.75 8.39L5.75 6.66L6.66 5.75L8.39 6.75L10.73 5.78L11.62 4H12.38L13.27 5.78L15.77 6.82L18.5 4.09Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M19.91 5.5L17.18 8.23L18.22 10.73L20 11.62V12.38L18.22 13.27L17.25 15.61L18.25 17.34L17.34 18.25L15.61 17.25L13.27 18.22L12.38 20H11.62L10.73 18.22L8.23 17.18L5.5 19.91L6.91 21.33L8.7 19.54L9.27 19.78L10.38 22H13.62L14.73 19.78L15.46 19.48L17.66 20.75L20.75 17.66L19.48 15.46L19.78 14.73L22 13.62V10.38L19.78 9.27L19.54 8.7L21.33 6.91L19.91 5.5Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M14 12C14 11.82 13.97 11.65 13.93 11.49L13.92 11.48L15.43 9.97001C15.78 10.57 16 11.25 16 11.99C16 14.2 14.21 15.99 12 15.99C9.79 15.99 8 14.2 8 11.99C8 9.78001 9.79 7.99001 12 7.99001C12.74 7.99001 13.43 8.21001 14.02 8.56001L12.51 10.07C12.35 10.03 12.18 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
