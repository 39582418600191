import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSocialYoutube = createIcon({
  displayName: 'IconSocialYoutube',
  path: [
    <path
      d="M21.5805 7.14999C21.4662 6.72588 21.2425 6.33921 20.9319 6.02861C20.6213 5.71801 20.2346 5.49436 19.8105 5.38C18.2505 5 12.0005 5 12.0005 5C12.0005 5 5.75054 4.99999 4.19054 5.42999C3.76642 5.54435 3.37975 5.76801 3.06914 6.07861C2.75854 6.38922 2.53489 6.77589 2.42053 7.2C2.13384 8.78376 1.99325 10.3905 2.00053 12C1.99017 13.6262 2.13077 15.2498 2.42053 16.85C2.53489 17.2741 2.75854 17.6608 3.06914 17.9714C3.37975 18.282 3.76642 18.5056 4.19054 18.62C5.75054 19.05 12.0005 19.05 12.0005 19.05C12.0005 19.05 18.2505 19.05 19.8105 18.62C20.2346 18.5056 20.6213 18.282 20.9319 17.9714C21.2425 17.6608 21.4662 17.2741 21.5805 16.85C21.8703 15.2498 22.0109 13.6262 22.0005 12C22.0109 10.3738 21.8703 8.75018 21.5805 7.14999ZM10.0005 15V9L15.2305 12L10.0005 15Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
