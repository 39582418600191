import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M8 2H6v2H3v2h18V4h-3V2h-2v2H8zM3 21h18v-9h-2v7H5v-9h16V8H3z" fill="currentColor" />
  </svg>
)
export default SvgCalendar
