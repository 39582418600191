import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10 21H3V3h7v2H5v14h5zM15.71 18.71l-1.42-1.42 5.3-5.29-5.3-5.29 1.42-1.42 6.7 6.71z"
      fill="currentColor"
    />
    <path d="m15.76 11 1 1-1 1H7v-2z" fill="currentColor" />
  </svg>
)
export default SvgLogout
