import { Webstore } from '@brenntag/api-customer'

export const webstore = {
  us: {
    country: 'US',
    id: 'webstore-us',
    name: 'US Webstore',
    sourceNetworkId: 'source-network-us',
    supportedCurrencies: ['USD'],
    supportedLanguages: ['en'],
    urlPathPrefix: 'us',
  },
} satisfies Record<string, Webstore>

export const webstores = {
  us: [webstore.us],
} satisfies Record<string, Webstore[]>
