import { FC } from 'react'

import { FormCheckbox } from '@brenntag/ui/components/FormCheckbox'
import { cn } from '@brenntag/ui/utils/cn'

import { FeatureFlags, useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'

import { ClearLocalFlagButton } from './ClearLocalFlagButton'

type Props = {
  flag: keyof FeatureFlags
}

export const BooleanFeatureFlag: FC<Props> = ({ flag }) => {
  const { featureFlags, isLocalFeatureFlag, setLocalFeatureFlag } = useFeatureFlags()
  const value = featureFlags[flag] as boolean
  return (
    <div
      className={cn(
        'flex items-center justify-between gap-x-4 py-3',
        isLocalFeatureFlag(flag) && 'text-blue-50',
      )}
      key={flag}>
      <div className="flex-1">{flag}</div>
      <FormCheckbox checked={value} onCheckedChange={() => setLocalFeatureFlag(flag, !value)}>
        {value}
      </FormCheckbox>
      {isLocalFeatureFlag(flag) && <ClearLocalFlagButton flag={flag} />}
    </div>
  )
}
