import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUserRemove = createIcon({
  displayName: 'IconUserRemove',
  path: [
    <path
      d="M12.5 7.5C12.5 7.18 12.43 6.87 12.32 6.59L13.8 5.11C14.24 5.81 14.5 6.62 14.5 7.5C14.5 9.98 12.48 12 10 12C7.52 12 5.5 9.98 5.5 7.5C5.5 5.02 7.52 3 10 3C10.88 3 11.7 3.26 12.39 3.7L10.91 5.18C10.63 5.07 10.32 5 10 5C8.62 5 7.5 6.12 7.5 7.5C7.5 8.88 8.62 10 10 10C11.38 10 12.5 8.88 12.5 7.5Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M22 4H16V6H22V4Z" fill="currentColor" key="1" />,
    <path d="M18 12V21H2V12H6V14H4V19H16V14H14V12H18Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
