import React from 'react'

import { Button, ButtonProps, HStack, Text } from '@chakra-ui/react'

import { IconDownloadcontained, IconFilePDF } from '#shared/icons/brand/index.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

export interface DocumentDownloadButtonProps extends ButtonProps {
  icon?: false | React.ReactElement
  url?: string
}

const sxDownloadIcon = { path: { strokeWidth: '2px' } }

export const DocumentDownloadButton: React.FC<DocumentDownloadButtonProps> = ({
  children,
  icon = <IconDownloadcontained boxSize="24px" color="blue.50" mx={-1} sx={sxDownloadIcon} />,
  url,
  variant = 'action',
  ...props
}) => {
  const t = useTranslations()

  return (
    <Button
      leftIcon={!icon ? undefined : icon}
      onClick={e => {
        e.preventDefault()
        open(url, '_blank', 'noreferrer')
      }}
      type="button"
      variant={variant}
      {...props}>
      {children ?? (
        <HStack alignItems="center" cursor="pointer" py={1} spacing={1}>
          <IconFilePDF boxSize="20px" />
          <Text as="span">{t('label.download.pdf')}</Text>
        </HStack>
      )}
    </Button>
  )
}
