import { SingleIncludedProductResourceWithPricebookProductUnion } from '@brenntag/connect-apis/api/types'
import { buildPricePoint } from '@brenntag/legacy-mock-generators/generators/buildPricePoint.js'
import { buildPricebookProduct } from '@brenntag/legacy-mock-generators/generators/products/buildPricebookProduct.js'
import { buildProduct } from '@brenntag/legacy-mock-generators/generators/products/buildProduct.js'

import { pricebooks } from '#src/mocks/apis/data/pricebooks.js'

const id = 'oneDocument'

export const oneDocument: SingleIncludedProductResourceWithPricebookProductUnion = buildProduct({
  attributes: {
    name: 'One document',
  },
  id,
  included: {
    pricebook: pricebooks.allLocations,
    pricebookProduct: buildPricebookProduct({
      id,
      pricebookId: pricebooks.allLocations.id,
      pricePointIds: ['pricePoint'],
      productId: id,
    }),
    pricePoints: [
      buildPricePoint({
        id: 'pricePoint',
        price: 10,
        relationships: { pricebookProductId: id },
      }),
    ],
  },
})
