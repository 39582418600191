import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSmartphone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M13 22h6V2H5v20h6v-2H7V4h10v16h-4z" fill="currentColor" />
    <path d="M13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="currentColor" />
  </svg>
)
export default SvgSmartphone
