import { http, HttpResponse } from 'msw'

import { FavoriteItemResponse } from '@brenntag/connect-apis/api-object/types'
import { buildFavoriteList } from '@brenntag/legacy-mock-generators/generators/favorites/buildFavoriteList.js'

import { config } from '#src/config.js'

import { favoriteLists } from '../../api/favorites/data'

export const postFavoriteItem = () => {
  return http.post<{ id: string }, { productId: string }, FavoriteItemResponse>(
    `${config.apiBaseUrl}/api-object/favorites/:id/favorite-items`,
    async ({ params, request }) => {
      const listId = params.id as keyof typeof favoriteLists.items

      const oldList = favoriteLists.items[listId]
      if (!oldList) {
        return HttpResponse.json(null, { status: 404 })
      }

      const body = await request.json()
      // Recreate the entire favorites list and add the new product to it. It's easier than manipulating the existing list.
      favoriteLists.items[listId] = buildFavoriteList({
        attributes: oldList.data.attributes,
        id: params.id,
        relationships: {
          productIds: [
            ...(oldList.included?.map(item => item.relationships.product.data.id) ?? []),
            body.productId,
          ],
          shipToId: oldList.data.relationships.shipTo.data.id,
          userId: oldList.data.relationships.user.data.id,
        },
      })
      return HttpResponse.json({ id: params.id, productId: body.productId })
    },
  )
}
