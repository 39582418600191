import { FC } from 'react'

import { Button } from '@brenntag/ui/components/Button'
import { Icon } from '@brenntag/ui/components/Icon'

import { FeatureFlags, useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'

type Props = {
  flag: keyof FeatureFlags
}

export const ClearLocalFlagButton: FC<Props> = ({ flag }) => {
  const { clearLocalFeatureFlag } = useFeatureFlags()
  return (
    <Button
      className="min-h-0 !p-0 text-blue-50"
      onClick={() => clearLocalFeatureFlag(flag)}
      size="sm"
      title="Clear local flag">
      <Icon name="CircleDelete" />
    </Button>
  )
}
