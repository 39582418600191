import { useState } from 'react'

import { Country } from '#lib/countries/getCountries.js'
import { CountryLanguageSelectorOption } from '#shared/components/CountryLanguageSelector/types/types.js'
import {
  translateCountryInLanguage,
  translateLanguageInLanguage,
} from '#shared/locale/hooks/useIntl.js'

interface Props {
  countries: Country[]
  selectedOption?: CountryLanguageSelectorOption
}

const getTransalationsForCountryInLanguage = (country: Country, toLanguageCode: string) => {
  const countryInLanguage = translateCountryInLanguage({
    countryCode: country.code,
    languageCode: toLanguageCode,
  })

  const languages = country.languageCodes.map(languageCode => {
    return translateLanguageInLanguage({
      fromLanguageCode: languageCode,
      toLanguageCode,
    })
  })

  return [countryInLanguage, ...languages]
}

export const useFilteredCountries = ({ countries, selectedOption }: Props) => {
  const [searchTerm, setSearchTerm] = useState('')

  const options = countries.filter(country => {
    if (country.code === selectedOption?.countryCode) {
      return false
    }

    const translations = getTransalationsForCountryInLanguage(
      country,
      selectedOption?.languageCode || country.defaultLanguage,
    )
    return translations.some(possibleMatch => possibleMatch?.toLowerCase().includes(searchTerm))
  })

  return {
    options,
    setSearchTerm,
  }
}
