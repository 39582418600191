import {
  ManyIncludedSampleResourceUnion,
  ManyMeta,
  SingleIncludedSampleResourceUnion,
} from '@brenntag/connect-apis/api/types'

interface Props {
  meta?: Partial<ManyMeta>
  samples: SingleIncludedSampleResourceUnion[]
}

export const buildSamples = ({ meta, samples }: Props): ManyIncludedSampleResourceUnion => ({
  data: samples.map(quote => quote.data),
  included: samples.flatMap(quote => quote.included || []),
  meta: { count: samples.length, ...meta },
})
