import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeDelivery,
  DataTypeOrder,
  DeliveryAttributes,
  DeliveryResource,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<DeliveryAttributes>
  id?: string
  relationships: {
    orderId: string
  }
}

export const buildDelivery = ({
  attributes,
  id = faker.string.uuid(),
  relationships,
}: Props): DeliveryResource => {
  return {
    attributes: merge(
      {
        createdAt: '2023-05-25T14:21:15.296443Z',
        deliveryDate: '2023-06-12',
        isActive: true,
        number: faker.number.int().toString(),
        updatedAt: faker.date.past().toISOString(),
      },
      attributes,
    ),
    id,
    relationships: {
      order: {
        data: {
          id: relationships.orderId,
          type: DataTypeOrder.Order,
        },
      },
    },
    type: DataTypeDelivery.Delivery,
  }
}
