import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconImage = createIcon({
  displayName: 'IconImage',
  path: [
    <path d="M5 12.76L3 14.76V3H21V5H5V12.76Z" fill="currentColor" key="0" />,
    <path
      d="M14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M3 21H21V19H5V18.41L11 12.41L14.07 15.48L19 9.56V17H21V7H18.53L13.93 12.52L11 9.59L3 17.59V21Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
