import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeProduct,
  DataTypeTankLocation,
  TankLocationAttributes,
  TankLocationResource,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<TankLocationAttributes>
  id?: string
  productId: string
}

export const buildTankLocation = ({
  attributes,
  id = faker.string.uuid(),
  productId,
}: Props): TankLocationResource => {
  return {
    attributes: merge(
      {
        isActive: true,
        name: 'Tank location',
      } satisfies TankLocationAttributes,
      attributes,
    ),
    id,
    relationships: {
      product: {
        data: {
          id: productId,
          type: DataTypeProduct.Product,
        },
      },
    },
    type: DataTypeTankLocation.TankLocation,
  }
}
