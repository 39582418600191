import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2 5v15h20V9h-2v9H4V7h3.62l1-2h6.76l1 2H22V5h-4.38l-1-2H7.38l-1 2z"
      fill="currentColor"
    />
    <path
      d="M14 12c0-.18-.03-.35-.07-.51l-.01-.01 1.51-1.51c.35.6.57 1.28.57 2.02 0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4c.74 0 1.43.22 2.02.57l-1.51 1.51c-.16-.04-.33-.07-.51-.07-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2"
      fill="currentColor"
    />
  </svg>
)
export default SvgCamera
