import { InvoiceStatus, SingleIncludedInvoiceResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildInvoice } from '@brenntag/legacy-mock-generators/generators/invoices/buildInvoice.js'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

import { orders } from '../../orders/data'

export const invoiceForMultipleOrderLineOrders: SingleIncludedInvoiceResourceUnion = buildInvoice({
  attributes: {
    number: '1234',
    status: InvoiceStatus.Open,
  },
  id: 'invoiceForMultipleOrderLineOrders',
  included: {
    billTo: buildLocation({ id: locations.showPrices.id }),
    order: orders.items.multipleOrderLines,
    shipTo: buildLocation({ id: locations.showPrices.id }),
  },
})
