import { SingleIncludedQuoteResourceUnion } from '@brenntag/connect-apis/api/types'

import { quoteForMultipleProducts } from './quoteForMultipleProducts'
import { quoteForProductWithManyPricePoints } from './quoteForProductWithMultiplePricePoints'

const items = {
  quoteForMultipleProducts,
  quoteForProductWithManyPricePoints,
} as const

type Quotes = {
  items: Record<keyof typeof items, SingleIncludedQuoteResourceUnion>
  list: () => SingleIncludedQuoteResourceUnion[]
}

export const quotes: Quotes = {
  items,
  list: () => Object.values(items),
}
