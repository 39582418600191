import { uniqBy } from 'lodash'

import {
  ManyIncludedInvoiceResourceUnion,
  ManyMeta,
  SingleIncludedInvoiceResourceUnion,
} from '@brenntag/connect-apis/api/types'

interface Props {
  invoices: SingleIncludedInvoiceResourceUnion[]
  meta?: Partial<ManyMeta>
}

export const buildInvoices = ({ invoices, meta }: Props): ManyIncludedInvoiceResourceUnion => {
  return {
    data: invoices.map(invoice => invoice.data),
    included: uniqBy(
      invoices.flatMap(invoice => invoice.included || []),
      resource => resource.id,
    ),
    meta: { count: invoices.length, ...meta },
  }
}
