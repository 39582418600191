import { createPath, To, useParams } from 'react-router'

/**
 * This hook is used to create a path (with "placeholders").
 * The placeholders are replaced with the actual values from the URL
 * using the useParams hook from react-router.
 *
 * Example:
 *
 *   The URL `/:locale/connect/dashboard` has a placeholder `:locale`.
 *   This hook will lookup the value of `:locale` from the URL and replace it in the path.
 *   When the placeholder is not found in the URL, the hook will return undefined.
 */
export function useCreatePath(): (to: string | To) => string | undefined
export function useCreatePath(to: string): string | undefined
export function useCreatePath(to: To): string | undefined
export function useCreatePath(
  to?: string | To,
): ((to: string | To) => string | undefined) | string | undefined {
  const params = useParams()

  const createRoutePath = (to: string | To): string | undefined => {
    const path = typeof to === 'string' ? to : createPath(to)
    const pathParams = path.match(/:\w+/g)
    if (!pathParams) return path

    return pathParams.reduce((path: string | undefined, param): string | undefined => {
      if (path === undefined) return undefined
      const paramValue = params[param.slice(1)]
      if (!paramValue) return undefined
      return path.replaceAll(param, paramValue)
    }, path)
  }

  if (to === undefined) return createRoutePath
  if (typeof to === 'string') return createRoutePath(to)

  return createRoutePath(createPath(to))
}
