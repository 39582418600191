import { OrderStatus, SingleIncludedOrderResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildOrder } from '@brenntag/legacy-mock-generators/generators/orders/buildOrder.js'
import { buildOrderLine } from '@brenntag/legacy-mock-generators/generators/orders/buildOrderLine.js'

import { manyPricePoints } from '#src/mocks/apis/api/products/data/manyPricePoints.js'

export const withEstimatedDeliveryDate: SingleIncludedOrderResourceUnion = buildOrder({
  attributes: {
    salesOrderNumber: 'With estimated delivery date',
    status: OrderStatus.Confirmed,
  },
  id: 'withEstimatedDeliveryDate',
  included: {
    attachments: [],
    deliveries: [],
    orderLines: [
      [
        buildOrderLine({
          id: 'orderLine1',
          included: { product: manyPricePoints.data },
          orderId: 'withEstimatedDeliveryDate',
        }),
        manyPricePoints,
      ],
    ],
  },
})
