import React from 'react'

import { isRouteErrorResponse, useRouteError } from 'react-router'

import { ErrorPage, ErrorPageContent } from '#shared/pages/Error/ErrorPage.js'
import { captureHandledException } from '#shared/utils/errors.js'

export const RouteErrorBoundary = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routerError = useRouteError() as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let error: any | undefined

  // Send error to Sentry
  captureHandledException(routerError)

  if (isRouteErrorResponse(routerError)) {
    error = routerError
  } else {
    // Sometimes we get something back that's not a RouteResponseError
    // in that case convert it to something that resembles it on a best
    // effort basis
    error = {
      status: routerError.status,
      statusText: routerError.statusText || routerError.toString(),
    }
  }

  // eslint-disable-next-line no-console
  console.trace()

  return (
    <ErrorPage data-testid="page-route-error-boundary">
      <ErrorPageContent error={error} errorCode={error?.status} reason={error?.statusText} />
    </ErrorPage>
  )
}
