import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconQuestion = createIcon({
  displayName: 'IconQuestion',
  path: [
    <path
      d="M12 22.41L8.59 19H3V3H21V5H5V17H9.41L12 19.59L14.59 17H19V7H21V19H15.41L12 22.41Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M13 14H11V12.59L13 10.59V10C13 9.45 12.55 9 12 9C11.45 9 11 9.45 11 10H9C9 8.35 10.35 7 12 7C13.65 7 15 8.35 15 10V11.41L13 13.41V14Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
