import { ofetch } from 'ofetch'

import { config } from '#config'
import { ListResponseCustomer } from '#types/list-response-customer.js'

import { Customer } from './types'

export type FetchCustomersResponse = { data: Customer[]; meta: { count: number } }

export const fetchCustomers = async (params: {
  id?: string
  page?: string
  pageSize?: string
  query?: string
  sort?: string
}): Promise<FetchCustomersResponse> => {
  const { getBaseUrl, getSalesforceAccessToken, getToken } = config

  const result = await ofetch<ListResponseCustomer>(`${getBaseUrl()}/customer/customers`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'X-Salesforce-Access-Token': `${getSalesforceAccessToken()}`,
    },
    params: {
      'id.in': params.id,
      page_number: params.page,
      page_size: params.pageSize,
      search: params.query,
      sort: params.sort,
    },
    retry: 1,
  })

  return {
    data: result.data.map(customer => ({
      allowPromotions: Boolean(customer.allowPromotions),
      id: customer.id,
      name: customer.name,
      number: customer.number,
      salesGroupId: customer.salesGroupId,
      shipTos:
        customer.shipTos?.map(location => ({
          address: location.address,
          contacts: location.contacts ?? [],
          id: location.id,
          name: location.name,
          number: location.number,
          showPrices: !location.hidePrices,
          sourceBusShipToId: location.sourceBusShipToId,
          type: location.type,
          webstoreId: location.webstoreId,
        })) ?? [],
    })),
    meta: result.meta,
  }
}
