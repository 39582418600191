import { FC } from 'react'

type Props = {
  items: { key: string; value?: string }[]
  title: string
}
export const DebugSection: FC<Props> = ({ items, title }) => (
  <section className="border border-gray-200">
    <h2 className="border-b border-gray-200 bg-gray-50 p-2 text-base font-semibold text-primary">
      {title}
    </h2>
    <dl className="-my-3 divide-y divide-gray-100 px-3 py-2 text-xs">
      {items.map(({ key, value }) => (
        <div className="flex justify-between gap-x-4 py-3" key={key}>
          <dt className="text-gray-500">{key}</dt>
          <dd className="text-gray-900">{value}</dd>
        </div>
      ))}
    </dl>
  </section>
)
