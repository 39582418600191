import React from 'react'

import {
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'

import { GenericModalFooter } from '#shared/components/Modal/GenericModalFooter.js'
import { ModalCloseButton } from '#shared/components/Modal/ModalCloseButton.js'
import { LeadText } from '#shared/components/Typography/Typography.js'
import { IconEnterprise } from '#shared/icons/brand/index.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface ChangeSalesOrgModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ChangeSalesOrgModal = ({ isOpen, onClose, onConfirm }: ChangeSalesOrgModalProps) => {
  const t = useTranslations()

  return (
    <Modal isCentered isOpen={isOpen} motionPreset="scale" onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading color="primary" size="xl" textAlign="left" textTransform="capitalize">
            {t('label.changesalesorg')}
          </Heading>
        </ModalHeader>
        <ModalCloseButton aria-label="close" onClose={onClose} />
        <ModalBody>
          <VStack alignItems="flex-start" spacing={5} w="100%">
            <Heading color="neutral.90" size="lg" textAlign="left">
              {t('label.changesalesorgwarning')}
            </Heading>
            <Center w="100%">
              <IconEnterprise boxSize={100} color="neutral.50" />
            </Center>
            <LeadText>{t('message.changesalesorg')}</LeadText>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <GenericModalFooter
            actionButtonId="modal-change-sales-org-btn"
            actionIcon="IconCheckmark"
            actionTextLabel="label.button.changesalesorg"
            onAction={onConfirm}
            onCancel={onClose}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
