import React, { createContext, useMemo, useState } from 'react'

export interface AppHeaderContextProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppHeaderContext = createContext<AppHeaderContextProps | undefined>(undefined)

interface AppHeaderProviderProps {
  children: React.ReactNode
}

export const AppHeaderProvider: React.FC<AppHeaderProviderProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const value = useMemo(() => ({ isDrawerOpen, setIsDrawerOpen }), [isDrawerOpen, setIsDrawerOpen])

  return <AppHeaderContext.Provider value={value}>{children}</AppHeaderContext.Provider>
}
