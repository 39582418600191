// Chakra wants these hooks to work with SRR out of the box, so it uses the
// fallback option on each first render. Then it measures the screen width and
// re-renders the component with the correct value. This causes a flash of
// unstyled content. This re-exports the chakra hooks with this feature disabled
// so that the first render is correct.
//
// More info
// https://chakra-ui.com/docs/hooks/use-breakpoint-value#usage
// https://github.com/chakra-ui/chakra-ui/issues/6452

import {
  // eslint-disable-next-line no-restricted-imports
  useBreakpointValue as chakraUseBreakpointValue,
  UseBreakpointOptions,
} from '@chakra-ui/react'

import { SSR } from '#shared/constants.js'

export const useBreakpointValue = <T = unknown>(
  values: Array<null | T> | Partial<Record<string, T>>,
  arg?: UseBreakpointOptions,
): T | undefined => {
  return chakraUseBreakpointValue(values, { ssr: SSR, ...arg })
}
