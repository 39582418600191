import { FormatDateOptions, FormatNumberOptions, useIntl } from 'react-intl'

import { MonetaryAmount } from '@brenntag/connect-apis/api/models/price'

export interface FormatDate {
  (value: Date | number | string, opts?: FormatDateOptions | undefined): string
  (
    value: Date | number | string | undefined,
    opts?: FormatDateOptions | undefined,
  ): string | undefined
  (value: undefined, opts?: FormatDateOptions | undefined): undefined
}

type FormatCurrencyFn = (value: number, currency: string, options?: FormatNumberOptions) => string

type FormatMonetaryAmountFn = (
  monetaryAmount: MonetaryAmount | undefined,
  options?: FormatNumberOptions,
) => string
type FormatNumberFn = (value: number, options?: FormatNumberOptions) => string

const useFormatCurrency = () => {
  const { formatNumber } = useIntl()

  const formatCurrencyFn: FormatCurrencyFn = (value, currency, options) =>
    formatNumber(value, {
      currency,
      currencyDisplay: 'code',
      maximumFractionDigits: 4,
      minimumFractionDigits: 2,
      style: 'currency',
      ...options,
    })
  return formatCurrencyFn
}

export const useFormatMonetaryAmount = () => {
  const formatCurrency = useFormatCurrency()

  const formatMonetaryAmountFn: FormatMonetaryAmountFn = (monetaryAmount, options) =>
    monetaryAmount ? formatCurrency(monetaryAmount.amount, monetaryAmount.currency, options) : ''
  return formatMonetaryAmountFn
}

export const useFormatDate = (): FormatDate => {
  const { formatDate: formatDateIntl } = useIntl()

  function formatDate(value: Date | number | string, opts?: FormatDateOptions | undefined): string
  function formatDate(value: undefined, opts?: FormatDateOptions | undefined): undefined
  function formatDate(
    value: Date | number | string | undefined,
    opts?: FormatDateOptions | undefined,
  ): string | undefined
  function formatDate(
    value: Date | number | string | undefined,
    opts?: FormatDateOptions | undefined,
  ): string | undefined {
    return value === undefined ? undefined : formatDateIntl(value, opts)
  }

  return formatDate
}

export const useFormatTime = () => {
  const { formatTime: formatDateIntl } = useIntl()

  function formatTime(value: Date | number | string, opts?: FormatDateOptions | undefined): string
  function formatTime(value: undefined, opts?: FormatDateOptions | undefined): undefined
  function formatTime(
    value: Date | number | string | undefined,
    opts?: FormatDateOptions | undefined,
  ): string | undefined
  function formatTime(
    value: Date | number | string | undefined,
    opts?: FormatDateOptions | undefined,
  ): string | undefined {
    return value === undefined ? undefined : formatDateIntl(value, opts)
  }

  return formatTime
}

export const useFormatNumber = () => {
  const { formatNumber } = useIntl()

  const formatNumberFn: FormatNumberFn = (value, options) =>
    formatNumber(value, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      ...options,
    })

  return formatNumberFn
}

export const translateCountryInLanguage = ({
  countryCode,
  languageCode,
}: {
  countryCode: string
  languageCode: string
}) => {
  const regions = new Intl.DisplayNames([languageCode], { type: 'region' })
  return regions.of(countryCode.toUpperCase()) || countryCode
}

export const translateLanguageInLanguage = ({
  fromLanguageCode,
  toLanguageCode,
}: {
  fromLanguageCode: string
  toLanguageCode: string
}): string => {
  const languages = new Intl.DisplayNames([toLanguageCode], { type: 'language' })
  return languages.of(fromLanguageCode) || toLanguageCode
}
