import { SingleIncludedInvoiceResourceUnion } from '@brenntag/connect-apis/api/types'

import { invoiceForEnteredOrder } from './invoiceForEnteredOrder'
import { invoiceForMultipleOrderLineOrders } from './invoiceForMultipleOrderLinesOrder'
import { invoiceFullyPaidNotClosed } from './invoiceFullyPaidNotClosed'

const items = {
  invoiceForEnteredOrder,
  invoiceForMultipleOrderLineOrders,
  invoiceFullyPaidNotClosed,
} as const

type Invoices = {
  items: Record<keyof typeof items, SingleIncludedInvoiceResourceUnion>
  list: SingleIncludedInvoiceResourceUnion[]
}
export const invoices: Invoices = {
  items,
  list: Object.values(items),
}
