import { uniqBy } from 'lodash'

import {
  ManyIncludedProductResourceWithPricebookProductUnion,
  ManyMeta,
  SingleIncludedProductResourceWithPricebookProductUnion,
} from '@brenntag/connect-apis/api/types'

interface Props {
  meta?: Partial<ManyMeta>
  products: SingleIncludedProductResourceWithPricebookProductUnion[]
}

export const buildProducts = ({
  meta,
  products,
}: Props): ManyIncludedProductResourceWithPricebookProductUnion => {
  return {
    data: products.map(product => product.data),
    included: uniqBy(
      products.flatMap(product => product.included || []),
      resource => resource.id,
    ),
    meta: { count: products.length, ...meta },
  }
}
