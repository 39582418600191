import type { CartItem } from '@brenntag/connect-apis/api-cart/models/cart'
import type { CartItem as APICartItem } from '@brenntag/connect-apis/api-cart/types'
import type { Product } from '@brenntag/connect-apis/api/models/product'

export const transformTanklocations = (
  productTankLocations: Product['tankLocations'],
  cartItemTankLocations: APICartItem['tankLocations'],
): CartItem['tankLocations'] => {
  return productTankLocations?.map(tankLocation => {
    const quantity = cartItemTankLocations
      ?.find(item => item.id === tankLocation.id)
      ?.quantity?.toString()

    return {
      id: tankLocation.id,
      name: tankLocation.name,
      quantity: quantity || '0',
    }
  })
}
