import { FC } from 'react'

import { useScript } from '#shared/hooks/dom/useScript.js'
import { config } from '#src/config.js'

export const QuestionPro: FC = () => {
  window.questionPro = config.questionPro
  useScript(`/assets/questionPro/questionPro.js?v=${__COMMIT_HASH__}`)
  return null
}
