import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChevronLeft = createIcon({
  displayName: 'IconChevronLeft',
  path: [
    <path
      d="M15.29 21.71L5.59 12L15.29 2.28999L16.71 3.70999L8.41 12L16.71 20.29L15.29 21.71Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
