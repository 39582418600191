import type { ConnectLocation } from '@brenntag/api-customer'

import { useLocalStorage } from '../dom/useLocalStorage'

interface UseSelectedLocationResponse {
  setShipToLocation(shipToLocation: ConnectLocation): void
  shipToLocation: ConnectLocation | undefined
}
export const useSelectedShipToLocation = (): UseSelectedLocationResponse => {
  const [shipToLocation, setShipToLocation] = useLocalStorage<ConnectLocation>({
    key: 'shipToLocation',
    version: 1,
  })

  return {
    setShipToLocation,
    shipToLocation,
  }
}
