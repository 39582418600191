import { ComponentStyleConfig } from '@chakra-ui/react'

export const Tabs: ComponentStyleConfig = {
  defaultProps: {
    variant: 'gradient',
  },
  variants: {
    gradient: {
      tab: {
        _active: {
          background: 'neutral.30',
          color: 'primary',
        },
        _disabled: {
          background: 'unset',
          boxShadow: 'unset',
          color: 'neutral.30',
          pointerEvents: 'none',
        },
        _hover: {
          background: 'neutral.10',
          borderBottomColor: 'primary',
          color: 'primary',
        },
        _selected: {
          _active: {
            background: 'blue.30',
            borderBottom: '3px solid',
            borderBottomColor: 'blue.60',
            color: 'primary',
          },
          _focus: {
            background: 'blue.10',
            borderBottomColor: 'blue.50',
            color: 'primary',
          },
          _hover: {
            background: 'blue.10',
            borderBottomColor: 'blue.50',
            color: 'primary',
          },
          background: 'unset',
          borderBottom: '3px solid',
          borderImageSlice: 1,
          borderImageSource: 'linear-gradient(90deg, #C815E0, #0C72ED)',
          boxShadow: 'unset',
          color: 'primary',
          fontWeight: 700,
        },
        background: 'unset',
        border: 'unset',
        boxShadow: 'unset',
        color: 'neutral.70',
        fontWeight: 400,
      },
      tablist: {
        borderBottom: '1px solid',
        borderBottomColor: 'neutral.30',
      },
    },
  },
}
