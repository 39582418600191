import React from 'react'

import { Button, Text } from '@chakra-ui/react'

import { RouterButton } from '#shared/components/RouterLink/RouterLink.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { ErrorPage } from '#shared/pages/Error/ErrorPage.js'
import { setPageTitle } from '#shared/utils.js'

interface NotFoundProps {
  fallbackLabel: string
  fallbackUrl: string
}
export const NotFoundPage = ({ fallbackLabel, fallbackUrl }: NotFoundProps) => {
  const t = useTranslations()
  setPageTitle(t('page.NotFound'))

  const app = window.location.href.split('/')[4]

  return (
    <ErrorPage
      data-testid="page-notfound"
      heading={t('page.NotFound')}
      subtitle={t('message.pagenotfound')}>
      <Text color="neutral.50" mb={4}>
        {t('label.errorcode')}: 404
      </Text>
      {app && (
        <RouterButton to={fallbackUrl} variant="action">
          {fallbackLabel}
        </RouterButton>
      )}
    </ErrorPage>
  )
}

export const NotFoundDocument = () => {
  const t = useTranslations()
  setPageTitle(t('page.NotFound'))

  return (
    <ErrorPage heading={t('page.NotFound')} subtitle={t('message.pagenotfound')}>
      <Text color="neutral.50" mb={4}>
        {t('label.errorcode')}: 404
      </Text>
      <Button onClick={() => window.history.back()} variant="action">
        {t('label.retry')}
      </Button>
    </ErrorPage>
  )
}
