import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconParcelWithGradient = createIcon({
  displayName: 'IconParcel',
  path: [
    <path
      d="M16.1802 5.4502L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L19.3402 4.3902L16.1802 5.4502Z"
      fill="url(#paint0_linear_1092_5215)"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M2 5.60986L12 8.94986V8.93986L22 5.60986V18.7199L12 22.0499L2 18.7199V5.60986ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM13 19.6099L20 17.2799V8.38986L13 10.7199V19.6099Z"
      fill="url(#paint0_linear_1092_5215)"
      fillRule="evenodd"
      key="1"
    />,
    <defs key={2}>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1092_5215"
        x1="7"
        x2="42.99"
        y1="48"
        y2="12.01">
        <stop stopColor="#C815E0" />
        <stop offset="1" stopColor="#0C72ED" />
      </linearGradient>
    </defs>,
  ],
  viewBox: '0 0 24 24',
})
