import { atom } from 'jotai'

import { FavoriteFilters } from '@brenntag/connect-apis/api/models/favorite'

const favoriteFilterDefaults: FavoriteFilters = {
  page: '1',
  sort: 'name',
} as const

export const favoriteFilters = atom<FavoriteFilters>(favoriteFilterDefaults)
