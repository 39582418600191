import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.5 22C3.02 22 1 19.98 1 17.5S3.02 13 5.5 13H6v2h-.5a2.5 2.5 0 0 0 0 5A2.5 2.5 0 0 0 8 17.5V5.24l13-3.55V15.5c0 2.48-2.02 4.5-4.5 4.5S12 17.98 12 15.5s2.02-4.5 4.5-4.5h.5v2h-.5a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 2.5-2.5V4.31l-9 2.45V17.5c0 2.48-2.02 4.5-4.5 4.5"
      fill="currentColor"
    />
  </svg>
)
export default SvgMusic
