import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSizeEnlarge = createIcon({
  displayName: 'IconSizeEnlarge',
  path: [
    <path d="M22 9H20V4H14V2H22V9Z" fill="currentColor" key="0" />,
    <path d="M2 15H4L4 20H9V22H2L2 15Z" fill="currentColor" key="1" />,
    <path d="M18 6H16.59L13 9.57998L14.42 11L18 7.41V6Z" fill="currentColor" key="2" />,
    <path
      d="M6.00002 18H7.41002L11.5 13.92L10.08 12.5L6.00002 16.59V18Z"
      fill="currentColor"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
