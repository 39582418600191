import React from 'react'

import { chakra } from '@chakra-ui/react'

import { HorizontalDivider } from '#shared/components/Card/CardDivider.js'
import { ViewMoreLink, ViewMoreLinkProps } from '#shared/components/GlobalSearch/ViewMoreLink.js'
import { RouterLink } from '#shared/components/RouterLink/RouterLink.js'

interface ItemSearchResultProps<T extends SearchResultItem> {
  children: (item: T) => React.ReactNode
  items: T[]
  linkToDetails?: boolean
  onItemClick?: VoidFunction
  onViewMoreClick: VoidFunction
  showHover?: boolean
  to: string
  total: number
  type: ViewMoreLinkProps['type']
}

interface SearchResultItem {
  id: string
}

const sxLastDividerThicker = { '&:nth-last-of-type(-n+1)': { height: '2px' } }

export function ItemSearchResult<T extends SearchResultItem>({
  children,
  items,
  linkToDetails,
  onItemClick,
  onViewMoreClick,
  showHover = true,
  to,
  total,
  type,
}: ItemSearchResultProps<T>) {
  return (
    <>
      {items.map(item => (
        <React.Fragment key={item.id}>
          <chakra.div
            _hover={{ bg: showHover && 'neutral.10', cursor: showHover && 'pointer' }}
            px={2}
            py={3}>
            {linkToDetails && (
              <RouterLink onClick={onItemClick} to={`${to}/${item.id}`}>
                {children(item)}
              </RouterLink>
            )}
            {!linkToDetails && children(item)}
          </chakra.div>
          <HorizontalDivider m={0} sx={sxLastDividerThicker} />
        </React.Fragment>
      ))}
      {items.length < total && (
        <ViewMoreLink onClick={onViewMoreClick} to={to} total={total} type={type} />
      )}
    </>
  )
}
