import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  AttachmentAttributes,
  AttachmentResource,
  DataTypeAttachment,
  SyncStatusEnum,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<AttachmentAttributes>
}

export const buildAttachment = ({ attributes }: Props = {}): AttachmentResource => ({
  attributes: merge(
    {
      filename: faker.system.fileName(),
      isActive: true,
      ownerName: faker.person.fullName(),
      syncStatus: SyncStatusEnum.Confirmed,
      uploadedAt: faker.date.past().toISOString(),
    } satisfies AttachmentAttributes,
    attributes,
  ),
  id: faker.string.uuid(),
  relationships: {},
  type: DataTypeAttachment.Attachment,
})
