import { SingleIncludedFavoriteResourceFavoriteItemResource } from '@brenntag/connect-apis/api/types'

import { list1 } from './list1'

const items = {
  list1,
} as const

type FavoriteLists = {
  items: Partial<Record<keyof typeof items, SingleIncludedFavoriteResourceFavoriteItemResource>>
  list: () => SingleIncludedFavoriteResourceFavoriteItemResource[]
}
export const favoriteLists: FavoriteLists = {
  items,
  list: () => Object.values(items),
}
