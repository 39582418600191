import React, { useEffect, useState } from 'react'

import { createIntl, createIntlCache, IntlConfig, RawIntlProvider } from 'react-intl'
import { useParams } from 'react-router'

import { Translations } from '@brenntag/connect-translations'
import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { getCountryByCode } from '#lib/countries/getCountryByCode.js'
import { defaultTranslation, getTranslationForLanguage } from '#shared/translations/index.js'

const cache = createIntlCache()

export const intl = createIntl(
  {
    locale: 'en-US',
    messages: defaultTranslation.translations as unknown as Record<string, string>,
  },
  cache,
)

export const IntlProvider = ({ children }: React.PropsWithChildren<Omit<IntlConfig, 'locale'>>) => {
  const params = useParams<{ locale?: string }>()
  const { countryCode, languageCode = 'en' } = parseLocale(params.locale)
  const [translations, setTranslations] = useState<Translations>(defaultTranslation.translations)

  const country = getCountryByCode(countryCode)
  const locale = country ? `${languageCode}-${countryCode}` : 'en-US'

  useEffect(() => {
    if (!languageCode) return
    getTranslationForLanguage(languageCode).then(setTranslations)
    intl.locale = locale
    intl.messages = translations as unknown as Record<string, string>
  }, [languageCode, locale, translations])

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>
}
