import { LinkedAttachment } from '@brenntag/connect-apis/api-attachment/types'
import { OrderStatus, SingleIncludedOrderResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildOrder } from '@brenntag/legacy-mock-generators/generators/orders/buildOrder.js'
import { buildOrderLine } from '@brenntag/legacy-mock-generators/generators/orders/buildOrderLine.js'

import { manyPricePoints } from '#src/mocks/apis/api/products/data/manyPricePoints.js'

import { attachments, dummyBlob } from '../../../api-attachment/data'

const orderId = 'withMultipleAttachments'
const orderWithMultipleAttachmentsAttachment1: LinkedAttachment = {
  createdAt: '2021-09-01T00:00:00Z',
  id: 'orderWithMultipleAttachmentsAttachment1',
  name: 'attachment for document with multiple attachments',
  owner: {
    id: orderId,
    name: 'order with multiple attachments',
  },
  updatedAt: '2021-09-01T00:00:00Z',
}
const orderWithMultipleAttachmentsAttachment2: LinkedAttachment = {
  createdAt: '2021-09-01T00:00:00Z',
  id: 'orderWithMultipleAttachmentsAttachment2',
  name: 'attachment for document with multiple attachments',
  owner: {
    id: orderId,
    name: 'order with multiple attachments',
  },
  updatedAt: '2021-09-01T00:00:00Z',
}

attachments.add(orderWithMultipleAttachmentsAttachment1, dummyBlob)
attachments.add(orderWithMultipleAttachmentsAttachment2, dummyBlob)

export const withMultipleAttachments: SingleIncludedOrderResourceUnion = buildOrder({
  attributes: {
    salesOrderNumber: 'With multiple attachments',
    status: OrderStatus.Entered,
  },
  id: orderId,
  included: {
    deliveries: [],
    orderLines: [
      [
        buildOrderLine({
          id: 'orderLine1',
          included: { product: manyPricePoints.data },
          orderId: 'entered',
        }),
        manyPricePoints,
      ],
    ],
  },
})
