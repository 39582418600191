import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconAddTruck = createIcon({
  displayName: 'IconAddTruck',
  path: [
    <path d="M6 9H4V7H2V5H4V3H6V5H8V7H6V9Z" fill="currentColor" key="0" />,
    <path
      clipRule="evenodd"
      d="M16 7H19.54L22 10.7V18H21C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18H9C9 19.65 7.65 21 6 21C4.35 21 3 19.65 3 18H2V11H4V15.78C4.53 15.3 5.23 15 6 15C6.88 15 7.67 15.39 8.22 16H14V7H10V5H16V7ZM5 18C5 18.55 5.45 19 6 19C6.55 19 7 18.55 7 18C7 17.45 6.55 17 6 17C5.45 17 5 17.45 5 18ZM20 11.3L18.46 9H16V15.78C16.53 15.3 17.23 15 18 15C18.77 15 19.47 15.3 20 15.78V11.3ZM17 18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18C19 17.45 18.55 17 18 17C17.45 17 17 17.45 17 18Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
