import React, { FC, ReactNode, useEffect } from 'react'

import { AppHeaderMainBar } from '@brenntag/ui/components/AppHeader/AppHeaderMainBar'
import { DefaultAppHeader } from '@brenntag/ui/components/AppHeader/DefaultAppHeader'

import { AppLogo } from '#components/AppLogo/AppLogo.js'
import maintenance from '#shared/assets/errors/maintenance.svg?url'
import { Footer } from '#shared/components/Footer/Footer.js'
import { useFeatureFlags } from '#shared/hooks/accessControl/useFeatureFlags.js'
import { useKeyPress } from '#shared/hooks/dom/useKeyPress.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

export const MaintenancePage: FC<{ children: ReactNode }> = ({ children }) => {
  const t = useTranslations()
  const {
    featureFlags: { enableMaintenanceOverlay },
    setLocalFeatureFlag,
  } = useFeatureFlags()
  const pressed = useKeyPress({ ctrlKey: true, key: 'M', shiftKey: true })

  useEffect(() => {
    if (!pressed) return
    setLocalFeatureFlag('enableMaintenanceOverlay', !enableMaintenanceOverlay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressed])

  if (!enableMaintenanceOverlay) {
    return <>{children}</>
  }

  return (
    <div
      className="fixed inset-0 z-10 flex min-h-screen w-full flex-col"
      data-testid="page-maintenance">
      <DefaultAppHeader>
        <AppHeaderMainBar>
          <AppLogo className="h-6 shrink-0 md:h-8" data-testid="header-brenntag-logo" />
        </AppHeaderMainBar>
      </DefaultAppHeader>
      <div className="flex h-full flex-col items-center justify-center px-36 py-20">
        <h1 className="py-8 text-4xl font-bold">{t('maintenance.header')}</h1>
        <h2 className="py-4 text-2xl font-semibold">{t('maintenance.subheader')}</h2>
        <p>{t('maintenance.body1')}</p>
        <p>{t('maintenance.body2')}</p>
        <img
          alt="Currently in maintenance, check back soon"
          height="429px"
          src={maintenance}
          width="643px"
        />
      </div>
      <Footer />
    </div>
  )
}
