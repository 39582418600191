import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCreditcard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M0 0h24v24H0z" />
    <path
      clipRule="evenodd"
      d="M2 4h20v2H4v2h18v12H2zm2 14h16v-6H4z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgCreditcard
