import {
  DateRangeFilterValueType,
  VasSearchRequestFilter,
  VasSearchRequestFilterType,
  VasSearchResponse,
  VasSearchResponseContent,
  VasSearchResponseFilters,
  VasStatus,
  VasType,
} from '@brenntag/connect-apis/api-vas/types'

interface Props {
  filters: VasSearchRequestFilter[]
  searchQuery?: string
  services: VasSearchResponseContent[]
  sort?: string
  sorts?: string[]
}

// eslint-disable-next-line complexity
export const buildServices = ({
  filters,
  searchQuery,
  services,
  sort,
  sorts,
}: Props): VasSearchResponse => {
  const size = services?.length ?? 10
  const minDate = new Date(
    new Date().getFullYear() - 1,
    Math.floor(Math.random() * 12),
    Math.floor(Math.random() * 28),
  ).toISOString()
  const currentDate = new Date().toISOString()
  const lastYearStartDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1),
  ).toISOString()
  const lastThreeMonthsStartDate = new Date(
    new Date().setMonth(new Date().getMonth() - 3),
  ).toISOString()
  const lastMonthStartDate = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString()
  const lastWeekStartDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()

  const selectedStatus = filters.find(filter => filter.name === 'status')?.values
  const selectedType = filters.find(filter => filter.name === 'type')?.values
  const selectedStartCreatedDate =
    filters.find(filter => filter.name === 'createdDate')?.startDate || ''
  const selectedEndCreatedDate =
    filters.find(filter => filter.name === 'createdDate')?.endDate || ''
  const selectedDateRange =
    selectedStartCreatedDate && selectedEndCreatedDate
      ? {
          endDate: selectedEndCreatedDate,
          startDate: selectedStartCreatedDate,
        }
      : undefined

  const defaultFilters: VasSearchResponseFilters[] = [
    {
      name: 'status',
      selectedValue: selectedStatus,
      type: VasSearchRequestFilterType.Term,
      values: [
        {
          count: services.filter(service => service.status === VasStatus.New).length,
          value: VasStatus.New,
        },
        {
          count: services.filter(service => service.status === VasStatus.InProgress).length,
          value: VasStatus.InProgress,
        },
        {
          count: services.filter(service => service.status === VasStatus.Closed).length,
          value: VasStatus.Closed,
        },
        {
          count: services.filter(service => service.status === VasStatus.OnHold).length,
          value: VasStatus.OnHold,
        },
        {
          count: services.filter(service => service.status === VasStatus.InReview).length,
          value: VasStatus.InReview,
        },
        {
          count: services.filter(service => service.status === VasStatus.SubmittedForApproval)
            .length,
          value: VasStatus.SubmittedForApproval,
        },
      ],
    },
    {
      name: 'type',
      selectedValue: selectedType,
      type: VasSearchRequestFilterType.Term,
      values: [
        {
          count: services.filter(service => service.type === VasType.BlendingService).length,
          value: VasType.BlendingService,
        },
        {
          count: services.filter(service => service.type === VasType.LaboratoryService).length,
          value: VasType.LaboratoryService,
        },
      ],
    },
    {
      name: 'createdDate',
      selectedValue: selectedDateRange,
      type: VasSearchRequestFilterType.DateRange,
      values: [
        {
          date: minDate,
          value: DateRangeFilterValueType.MinDate,
        },
        {
          date: currentDate,
          value: DateRangeFilterValueType.MaxDate,
        },
        {
          endDate: currentDate,
          startDate: lastWeekStartDate,
          value: DateRangeFilterValueType.LastWeek,
        },
        {
          endDate: currentDate,
          startDate: lastMonthStartDate,
          value: DateRangeFilterValueType.LastMonth,
        },
        {
          endDate: currentDate,
          startDate: lastThreeMonthsStartDate,
          value: DateRangeFilterValueType.LastThreeMonths,
        },
        {
          endDate: currentDate,
          startDate: lastYearStartDate,
          value: DateRangeFilterValueType.LastYear,
        },
      ],
    },
  ]

  if (selectedStatus?.length) {
    services = services.filter(service => selectedStatus[0].includes(service.status || ''))
  }

  if (selectedType?.length) {
    services = services.filter(service => selectedType[0].includes(service.type || ''))
  }

  if (selectedStartCreatedDate && selectedEndCreatedDate) {
    services = services.filter(
      service =>
        new Date(service.createdDate) >= new Date(selectedStartCreatedDate) &&
        new Date(service.createdDate) <= new Date(selectedEndCreatedDate),
    )
  }

  if (searchQuery) {
    services = services.filter(service =>
      service.number?.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }

  sortServices(services, sort)

  return {
    content: services.map(service => ({
      createdDate: service.createdDate,
      id: service.id,
      number: service.number,
      requiredDate: service.requiredDate,
      status: service.status,
      type: service.type,
    })),
    filters: defaultFilters,
    page: {
      number: 0,
      size,
      total: services?.length ?? size,
    },
    searchQuery: searchQuery === undefined ? '' : searchQuery,
    sort: sort === undefined ? 'createdDate' : sort,
    sorts: sorts === undefined ? ['createdDate', 'number', 'requiredDate'] : sorts,
  }
}

const sortServices = (
  services: VasSearchResponseContent[],
  sort?: string,
): VasSearchResponseContent[] => {
  const isDescending = sort?.startsWith('-')
  switch (sort) {
    case '-createdDate':
    case 'createdDate':
      return services.sort((a, b) => {
        const aVal = a.createdDate || ''
        const bVal = b.createdDate || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-number':
    case 'number':
      return services.sort((a, b) => {
        const aVal = a.number || ''
        const bVal = b.number || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })

    case '-requiredDate':
    case 'requiredDate':
      return services.sort((a, b) => {
        const aVal = a.requiredDate || ''
        const bVal = b.requiredDate || ''

        const comparison = aVal.localeCompare(bVal)
        return isDescending ? comparison : -comparison
      })
    default:
      return services
  }
}
