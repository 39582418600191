import { faker } from '@faker-js/faker'
import { merge, range, uniqBy } from 'lodash'

import {
  AttachmentResource,
  CustomerContextResource,
  DataTypeAttachment,
  DataTypeCustomerContext,
  DataTypeDelivery,
  DataTypeLocation,
  DataTypeOrder,
  DataTypeOrderLine,
  DeliveryResource,
  LocationResource,
  LocationType,
  OrderAttributes,
  OrderLineResource,
  OrderResource,
  OrderStatus,
  SingleIncludedOrderResourceUnion,
  SingleIncludedProductResourceWithPricebookProductUnion,
  SurchargesType,
} from '@brenntag/connect-apis/api/types'

import { buildAttachment } from '../buildAttachment'
import { buildCustomerContext } from '../buildCustomerContext'
import { buildLocation } from '../buildLocation'
import { buildProduct } from '../products/buildProduct'
import { buildOrderLine } from './buildOrderLine'

interface Props {
  attributes?: Partial<OrderAttributes>
  id?: string
  included?: {
    attachments?: AttachmentResource[]
    billToLocation?: LocationResource | null
    customerContext?: CustomerContextResource
    deliveries?: DeliveryResource[] | null
    shipToLocation?: LocationResource | null
    soldToLocation?: LocationResource
  } & (
    | {
        orderLines?: [OrderLineResource, SingleIncludedProductResourceWithPricebookProductUnion][]
        products?: never
      }
    | {
        orderLines?: never
        products?: SingleIncludedProductResourceWithPricebookProductUnion[]
      }
  )
  numberOfOrderLines?: number
}

// eslint-disable-next-line complexity
export function buildOrder({
  attributes,
  id,
  included,
  numberOfOrderLines,
}: Props = {}): SingleIncludedOrderResourceUnion {
  const orderId = id ?? faker.string.uuid()
  const customerContext = included?.customerContext ?? buildCustomerContext().data
  const products =
    included?.products ??
    included?.orderLines?.map(([_, product]) => product) ??
    range(0, numberOfOrderLines ?? 0).map(() => buildProduct())

  const orderLines =
    included?.orderLines?.map(([orderLine]) => orderLine) ??
    range(0, numberOfOrderLines ?? products.length ?? 0).map((_, index) =>
      buildOrderLine({ included: { product: products[index].data }, orderId }),
    )

  const billToLocation =
    included?.billToLocation === undefined
      ? buildLocation({
          attributes: {
            hidePrices: false,
            type: LocationType.BillTo,
          },
        })
      : included?.billToLocation
  const shipToLocation =
    included?.shipToLocation === undefined
      ? buildLocation({
          attributes: {
            hidePrices: false,
            type: LocationType.ShipTo,
          },
        })
      : included?.shipToLocation
  const soldToLocation =
    included?.soldToLocation === undefined
      ? buildLocation({
          attributes: {
            hidePrices: false,
            type: LocationType.SoldTo,
          },
        })
      : included?.soldToLocation

  const attachments =
    included?.attachments ??
    (included?.attachments === null
      ? []
      : faker.helpers.multiple(() => buildAttachment(), { count: { max: 3, min: 0 } }))

  const surcharges: SurchargesType | undefined = faker.datatype.boolean()
    ? {
        containerDeposit: faker.datatype.boolean()
          ? { amount: parseFloat(faker.finance.amount()), currency: 'USD' }
          : undefined,
        freightCharges: faker.datatype.boolean()
          ? { amount: parseFloat(faker.finance.amount()), currency: 'USD' }
          : undefined,
        fuelSurcharge: faker.datatype.boolean()
          ? { amount: parseFloat(faker.finance.amount()), currency: 'USD' }
          : undefined,
        insuranceSurcharge: faker.datatype.boolean()
          ? { amount: parseFloat(faker.finance.amount()), currency: 'USD' }
          : undefined,
        minimumSurcharge: faker.datatype.boolean()
          ? { amount: parseFloat(faker.finance.amount()), currency: 'USD' }
          : undefined,
        otherAdditionalCharges: faker.datatype.boolean()
          ? { amount: parseFloat(faker.finance.amount()), currency: 'USD' }
          : undefined,
        totalSalesTax: faker.datatype.boolean()
          ? { amount: parseFloat(faker.finance.amount()), currency: 'USD' }
          : undefined,
      }
    : undefined

  const status = faker.helpers.arrayElement([
    OrderStatus.Entered,
    OrderStatus.DeliveredInvoiced,
    OrderStatus.Confirmed,
    OrderStatus.Picked,
    OrderStatus.Processing,
  ])
  const deliveries =
    included?.deliveries ??
    (status === OrderStatus.Confirmed || status === OrderStatus.DeliveredInvoiced
      ? [
          {
            attributes: {
              deliveryDate: faker.date.future().toISOString(),
              number: faker.number.int().toString(),
            },
            id: faker.string.uuid(),
            relationships: {
              order: {
                data: {
                  id: orderId,
                  type: 'Order',
                },
              },
            },
            type: 'Delivery',
          },
        ]
      : [])

  const order: OrderResource = {
    attributes: merge(
      {
        createdAt: faker.date.past().toISOString(),
        isActive: true,
        isDocumentAvailable: faker.datatype.boolean(),
        isOrderOnBehalf: faker.datatype.boolean(),
        lastUpdateDate: faker.date.recent().toISOString(),
        orderCreationDate: faker.date.past().toISOString(),
        paymentTerms: faker.word.words(1),
        purchaseOrderNumber: faker.number.int().toString(),
        requestedDeliveryDate: faker.date.future().toISOString(),
        salesOrderNumber: faker.number.int().toString(),
        shippingInstructions: faker.lorem.sentence(),
        status,
        subtotalValue: undefined,
        surcharges,
        totalValue: {
          amount: parseFloat(faker.finance.amount()),
          currency: 'USD',
        },
        updatedAt: faker.date.recent().toISOString(),
      } satisfies OrderAttributes,
      attributes,
    ),
    id: orderId,
    relationships: {
      attachments: attachments && {
        data: attachments.map(attachment => ({
          id: attachment.id,
          type: DataTypeAttachment.Attachment,
        })),
      },
      billTo: billToLocation
        ? {
            data: {
              id: billToLocation.id,
              type: DataTypeLocation.Location,
            },
          }
        : undefined,
      customerContext: {
        data: {
          id: customerContext.id,
          type: DataTypeCustomerContext.CustomerContext,
        },
      },
      deliveries: {
        data: deliveries.map(delivery => ({
          id: delivery.id,
          type: DataTypeDelivery.Delivery,
        })),
      },
      documents: { data: [] },
      orderLines: {
        data: orderLines.map(orderLine => ({
          id: orderLine.id,
          type: DataTypeOrderLine.OrderLine,
        })),
      },
      shipTo: shipToLocation
        ? {
            data: {
              id: shipToLocation.id,
              type: DataTypeLocation.Location,
            },
          }
        : undefined,
      soldTo: {
        data: {
          id: soldToLocation.id,
          type: DataTypeLocation.Location,
        },
      },
    },
    type: DataTypeOrder.Order,
  }

  return {
    data: order,
    included: uniqBy(
      [
        ...orderLines,
        shipToLocation,
        soldToLocation,
        ...attachments,
        billToLocation,
        customerContext,
        deliveries[0],
        ...products.flatMap(product => product.data || []),
        ...products.flatMap(product => product.included || []),
      ].filter(Boolean) as SingleIncludedOrderResourceUnion['included'],
      v => v?.id,
    ),
  }
}
