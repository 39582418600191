interface Config {
  getBaseUrl: () => string
  getSalesforceAccessToken: () => string
  getToken: () => string
}

export const config: Config = {
  getBaseUrl: () => '',
  getSalesforceAccessToken: () => '',
  getToken: () => '',
}
