import consola from 'consola/browser'
import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { carts } from './data'

export const patchCart = () => {
  return http.patch<never, { deliveryDate?: string; deliveryInstructions?: string }, APICart>(
    `${config.apiBaseUrl}/api-cart/carts`,
    async ({ request }) => {
      const body = await request.json()
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      if (!shipToId) {
        consola.log('shipToId not found')
        return HttpResponse.json(null, { status: 400 })
      }

      const cart = carts[shipToId]
      if (!cart) {
        consola.log('cart not found')
        return HttpResponse.json(null, { status: 404 })
      }

      cart.deliveryOptions = {
        deliveryInstructions: body.deliveryInstructions || '',
        requestedDeliveryDate: body.deliveryDate || '',
      }

      return HttpResponse.json(cart, { status: 200 })
    },
  )
}
