import * as React from 'react'

import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '#utils/cn.js'

const formItemVariants = cva(
  'relative z-0 flex w-full truncate bg-white text-base outline outline-1 outline-primary transition-colors placeholder:text-neutral-50 focus-within:outline-3 focus-within:outline-offset-[-2px] focus-within:outline-blue-50',
  {
    defaultVariants: {
      disabled: false,
      hasError: false,
    },
    variants: {
      disabled: {
        false: '',
        true: 'pointer-events-none outline-neutral-30 focus-within:outline-1 focus-within:outline-offset-0 focus-within:outline-neutral-30',
      },
      hasError: {
        false: '',
        true: 'bg-red-10 outline-2 outline-offset-[-1px] outline-red-50',
      },
    },
  },
)

interface Props extends VariantProps<typeof formItemVariants> {
  children: React.ReactNode
  className?: string
}

const FormItem: React.FC<Props> = ({ children, className, disabled, hasError }) => {
  return <div className={cn(formItemVariants({ disabled, hasError }), className)}>{children}</div>
}

export { FormItem, formItemVariants }
