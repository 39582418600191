import { NavigationItem } from '#shared/components/Header/types.js'

const featureNavItemsMap: Record<string, NavigationItem> = {
  cases: {
    className: 'menu-caselist',
    labelKey: 'page.CaseList',
    name: 'cases',
    to: '/:locale/connect/cases',
  },
  co2xplorer: {
    labelKey: 'page.Co2Explorer',
    name: 'co2xplorer',
    to: '/:locale/connect/co2xplorer',
  },
  dashboard: {
    labelKey: 'page.Dashboard',
    name: 'dashboard',
    to: '/:locale/connect/dashboard',
  },
  documents: {
    labelKey: 'page.DocumentList',
    name: 'documents',
    to: '/:locale/connect/documents',
  },
  favorites: {
    labelKey: 'page.FavoriteList',
    name: 'favorites',
    to: '/:locale/connect/favorites',
  },
  invoices: {
    labelKey: 'page.InvoiceList',
    name: 'invoices',
    to: '/:locale/connect/invoices',
  },
  login: {
    labelKey: 'page.Login',
    name: 'login',
    to: '/:locale/connect',
  },
  orders: {
    labelKey: 'page.OrderList',
    name: 'orders',
    to: '/:locale/connect/orders',
  },
  products: {
    labelKey: 'page.ProductList',
    name: 'products',
    to: '/:locale/connect/products',
  },
  quotes: {
    labelKey: 'page.QuoteList',
    name: 'quotes',
    to: '/:locale/connect/quotes',
  },
  register: {
    labelKey: 'page.Register',
    name: 'register',
    to: '/:locale/register',
  },
  requestAccess: {
    labelKey: 'page.RequestAccess',
    name: 'request-access',
    to: '/:locale/request-access',
  },
  samples: {
    labelKey: 'page.SampleList',
    name: 'samples',
    to: '/:locale/connect/samples',
  },
  scheduleDemo: {
    labelKey: 'page.ScheduleDemo',
    name: 'schedule-demo',
    to: '/:locale/schedule-demo',
  },
  services: {
    features: ['services'],
    labelKey: 'page.ServiceList',
    name: 'services',
    to: '/:locale/connect/services',
  },
}

type NavItemsMap = Record<string, { private: NavigationItem[]; public: NavigationItem[] }>
export const navItemsMap: NavItemsMap = {
  SE: {
    private: [featureNavItemsMap.co2xplorer],
    public: [featureNavItemsMap.login, featureNavItemsMap.requestAccess],
  },
  US: {
    private: [
      featureNavItemsMap.dashboard,
      featureNavItemsMap.products,
      featureNavItemsMap.orders,
      featureNavItemsMap.invoices,
      featureNavItemsMap.documents,
      featureNavItemsMap.quotes,
      featureNavItemsMap.favorites,
      featureNavItemsMap.cases,
      featureNavItemsMap.services,
      featureNavItemsMap.samples,
    ],
    public: [
      featureNavItemsMap.login,
      featureNavItemsMap.register,
      featureNavItemsMap.scheduleDemo,
    ],
  },
}

export const homeLinksMap: Record<string, { private: NavigationItem; public: NavigationItem }> = {
  SE: {
    private: featureNavItemsMap.dashboard,
    public: featureNavItemsMap.login,
  },
  US: {
    private: featureNavItemsMap.dashboard,
    public: featureNavItemsMap.login,
  },
}
