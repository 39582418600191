import { http, HttpResponse } from 'msw'

import { ManyIncludedFavoriteResourceFavoriteItemResource } from '@brenntag/connect-apis/api/types'
import { buildFavoriteLists } from '@brenntag/legacy-mock-generators/generators/favorites/buildFavoriteLists.js'

import { config } from '#src/config.js'

import { favoriteLists } from './data'

export const fetchFavoritesLists = () => {
  return http.get<never, null, ManyIncludedFavoriteResourceFavoriteItemResource>(
    `${config.apiBaseUrl}/api/favorites`,
    async () => {
      const list = favoriteLists.list()

      return HttpResponse.json(buildFavoriteLists({ favoriteLists: list }))
    },
  )
}
