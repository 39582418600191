import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconAnnouncement = createIcon({
  displayName: 'IconAnnouncement',
  path: [
    <path
      d="M12.59 7L16.59 3H21V20H16.59L12.59 16H9.5L8.83 14H13.41L17.41 18H19V5H17.41L13.41 9H4V14H6.72L8.72 20H11V22H7.28L5.28 16H2V7H12.59Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M8 16.0002V15.9302L8.02 16.0002H8Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
