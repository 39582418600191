import { SingleIncludedDocumentResourceProductResource } from '@brenntag/connect-apis/api/types'
import { buildDocument } from '@brenntag/legacy-mock-generators/generators/documents/buildDocument.js'

import { multipleDocuments } from '../../products/data/multipleDocuments'
import { oneDocument } from '../../products/data/oneDocument'
import { onlyForHidePricesLocation } from '../../products/data/onlyForHidePricesLocation'
import { onlyForShowPricesLocation } from '../../products/data/onlyForShowPricesLocation'

const items = {
  documentForMultipleDocumentsProduct1: buildDocument({ product: multipleDocuments }),
  documentForMultipleDocumentsProduct2: buildDocument({ product: multipleDocuments }),
  documentForMultipleDocumentsProduct3: buildDocument({ product: multipleDocuments }),
  documentForMultipleDocumentsProduct4: buildDocument({ product: multipleDocuments }),
  forHidePricesShipTo: buildDocument({ product: onlyForHidePricesLocation }),
  forOneDocumentProduct: buildDocument({ product: oneDocument }),
  forShowPricesShipTo: buildDocument({ product: onlyForShowPricesLocation }),
} as const

type Documents = {
  items: Record<keyof typeof items, SingleIncludedDocumentResourceProductResource>
  list: () => SingleIncludedDocumentResourceProductResource[]
}

export const documents: Documents = {
  items,
  list: () => Object.values(items),
}
