import React from 'react'

import { Box, ColorProps, Flex, ToastId } from '@chakra-ui/react'

import { StandardText } from '#shared/components/Typography/Typography.js'
import { IconByName, IconClose, IconNames } from '#shared/icons/brand/index.js'

interface Props {
  color: ColorProps['color']
  description: React.ReactElement | string
  icon?: IconNames
  id?: ToastId
  onClose?: () => void
}

export const Toast: React.FC<Props> = ({ color, description, icon, onClose }) => {
  return (
    <Flex
      bgColor={color}
      borderRadius={4}
      boxShadow="0 2px 6px 0 rgba(0, 0, 0, 0.1)"
      color="white"
      pointerEvents="all"
      position="relative"
      sx={{
        '.typography-text': {
          '& > a': {
            color: 'white',
            textDecoration: 'underline',
          },
          color: 'white',
        },
      }}
      userSelect="none"
      w="100%"
      whiteSpace="pre-wrap"
      zIndex={10000}>
      <Flex gap={2} justifyContent="space-between" p={4} w="100%">
        <Box>
          {icon && (
            <Flex alignItems="center" background="rgba(0, 0, 0, 0.1)" justifyContent="center">
              <IconByName boxSize="24px" name={icon} />
            </Flex>
          )}
          <StandardText color="white">{description}</StandardText>
        </Box>
        {onClose && (
          <Flex color="white" cursor="pointer" justifyContent="center" onClick={onClose}>
            <IconClose boxSize="20px" />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
