import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeProduct,
  DataTypeSpecification,
  SpecificationAttributes,
  SpecificationResource,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<SpecificationAttributes>
  id?: string
  index: number
  productId: string
}

export const buildSpecification = ({
  attributes,
  id = faker.string.uuid(),
  index,
  productId,
}: Props): SpecificationResource => {
  const specificationInfoVariety = [
    {
      label: 'Flash Point',
      value: faker.number.float(100).toString(),
    },
    {
      label: 'Weight/Gallon',
      value: faker.number.int(100).toString(),
    },
    {
      label: 'Product Grade',
      value: faker.word.words({ count: { max: 4, min: 1 } }),
    },
    {
      label: 'Vendor Part',
      value: faker.number.int(10000).toString(),
    },
  ]

  const { label, value } = specificationInfoVariety[index % 4]

  return {
    attributes: merge(
      {
        createdAt: faker.date.past().toISOString(),
        isActive: true,
        label,
        updatedAt: faker.date.past().toISOString(),
        value,
      } satisfies SpecificationAttributes,
      attributes,
    ),
    id,
    relationships: {
      product: {
        data: {
          id: productId,
          type: DataTypeProduct.Product,
        },
      },
    },
    type: DataTypeSpecification.Specification,
  }
}
