import { APIProductDocument } from '@brenntag/connect-apis/api'
import { DocumentList } from '@brenntag/connect-apis/api/models/productDocument'
import { ManyIncludedDocumentResourceProductResource } from '@brenntag/connect-apis/api/types'

import { transformAPIDocumentsToDocumentList } from '#shared/apis/api/shared/transforms/product.document.transforms.js'
import { convertFiltersToParams } from '#shared/apis/convertFiltersToParams.js'
import { request } from '#shared/apis/request.js'
import { deserialize } from '#shared/utils/deserialize.js'
import { config } from '#src/config.js'

export const documentListSortKeys = ['productName', '-productName'] as const

export interface DocumentFilters {
  searchQuery?: string
  shipToLocationId: string
  type?: string
}

export type DocumentListSortKey = (typeof documentListSortKeys)[number]

type DocumentFilter = keyof DocumentFilters

export const documentFilters = {
  searchQuery: 'search[query]',
  shipToLocationId: 'filter[shipTo.id.equals]',
  type: 'filter[type.in]',
} satisfies Record<DocumentFilter, string>

interface Params {
  filters: DocumentFilters
  include?: string
  pageNumber: number
  pageSize: number
  sort: DocumentListSortKey
}

export const fetchDocuments = async ({
  filters,
  include,
  pageNumber,
  pageSize,
  sort,
}: Params): Promise<DocumentList> => {
  const response = await request<ManyIncludedDocumentResourceProductResource>({
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
    params: {
      include: include ?? 'product,order,invoice',
      'page[number]': pageNumber,
      'page[size]': pageSize,
      sort,
      ...convertFiltersToParams(documentFilters, filters),
    },
    url: `${config.apiBaseUrl}/api/documents`,
  })

  const apiDocuments = deserialize<APIProductDocument[]>(response.body).filter(
    product => product.product,
  )
  const meta = response.body?.meta
  return transformAPIDocumentsToDocumentList(apiDocuments, meta, pageSize)
}
