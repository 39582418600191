import React, { FC, ReactNode } from 'react'

import { type AppState, Auth0Provider, type User } from '@auth0/auth0-react'
import { consola } from 'consola/browser'
import { Provider } from 'jotai'
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router'

import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { salesforceSession } from '#lib/auth/salesforce/salesforceSession.js'

import { authConfig } from './config'
import { salesforceSessionStore } from './salesforce/salesforceSession'
import { startSession } from './salesforce/startSession'

export type AuthProviderProps = {
  children: ReactNode
}

/**
 * Handles authentication mainly for Auth0. Also starts Salesforce session after login if the country code is 'US'.
 * @param param0
 * @returns
 */
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate()

  // For clarification. This country "code" is used 'before' login and will give a hint to the Auth0 Universal Login page
  // which country the user is trying to access. This is needed for custom theming, email templates and
  // other country specific settings on Auth0's Universal Login.
  const { countryCode = 'US', languageCode = 'en' } = parseLocale(window.location.pathname)

  // This callback 'cannot' throw, because that would result in an infinite login loop.
  // We expect the pages themselves to handle any permission or configuration related errors.
  // One example would be "no salesforce session" which would be handled by the ACL.
  const onRedirectCallback = (appState: AppState = {}, user?: User) => {
    if (!user) {
      return consola.error(
        `User session did not start. Can't determine permissions. Check Auth0 configuration.`,
      )
    }

    // Use the country code from the returnTo URL to determine if the user is allowed to access the country.
    // This is needed, because the user will first always be redirected to the auth0-handler page which does
    // not have the country code in the URL and then redirected to the returnTo URL (see bottom of this function).
    const path = appState.returnTo ?? window.location.pathname
    const { countryCode: returnCountryCode = '' } = parseLocale(path)

    if (returnCountryCode === 'SE') {
      navigate('/en-SE/connect/co2xplorer')
    }

    // TODO The metadata checks are similar to the ones in the ACL. This should be refactored to a shared function along the lines of:
    //  checkAppMetadata(user)
    const appMeta = user?.brenntag.app

    if (appMeta.status?.toLowerCase() !== 'active') {
      return consola.error(`User is not active. Skipping Salesforce authorize.`)
    }

    // In this setup, a Salesforce session determines permissions. Not if you're logged in.
    // The access control library (ACL) will load or skip loading features and services based
    // Salesforce session can already be established here, so check if it is before trying to start it
    // We need to reset the salesforce session since the user might have started the login flow for
    // a country with salesforce, setting the isAuthenticating flag to true.
    if (!salesforceSession.hasValidSession()) {
      if (salesforceSession.shouldAuthorizeWithSalesforce(returnCountryCode)) {
        return startSession({ countryCode: returnCountryCode, returnTo: appState.returnTo })
      } else {
        salesforceSession.reset()
      }
    }

    navigate(appState.returnTo ?? '/en-US/connect/dashboard')
  }

  return (
    <Provider store={salesforceSessionStore}>
      <Auth0Provider
        authorizationParams={{
          countryCode,
          'ext-appOrigin': window.location.origin,
          'ext-countryCode': countryCode,
          'ext-languageCode': languageCode,
          languageCode,
          redirect_uri: `${window.location.origin}/auth0-handler`,
          response_type: 'id_token token',
          scope: 'openid profile email',
        }}
        cacheLocation="localstorage"
        clientId={authConfig.auth0.clientId}
        domain={authConfig.auth0.domain}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens>
        {children}
      </Auth0Provider>
    </Provider>
  )
}
