import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPhoneIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.5 22H22v-1.98h-2.5C10.95 20.02 4 13.07 4 4.52v-.5h4.45l2.31 3.84-3.11 3.11.42.67c1.09 1.74 2.58 3.24 4.32 4.32l.67.42 3.11-3.11 3.84 2.31v2.43h2v-3.57l-6.16-3.69-3.07 3.07c-1-.72-1.87-1.6-2.59-2.59l3.07-3.07L9.57 2H2v2.5C2 14.15 9.85 22 19.5 22M16 8h6V6h-4V2h-2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPhoneIn
