import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconEnterprise = createIcon({
  displayName: 'IconEnterprise',
  path: [
    <path d="M20 22H14V20H18V4H6V20H10V18H12V22H4V2H20V22Z" fill="currentColor" key="0" />,
    <path d="M11 10H8V12H11V10Z" fill="currentColor" key="1" />,
    <path d="M13 10H16V12H13V10Z" fill="currentColor" key="2" />,
    <path d="M11 14H8V16H11V14Z" fill="currentColor" key="3" />,
    <path d="M13 14H16V16H13V14Z" fill="currentColor" key="4" />,
    <path d="M11 6H8V8H11V6Z" fill="currentColor" key="5" />,
    <path d="M13 6H16V8H13V6Z" fill="currentColor" key="6" />,
  ],
  viewBox: '0 0 24 24',
})
