import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconBook = createIcon({
  displayName: 'IconBook',
  path: [
    <path
      d="M21 21H3V3H10V12.13L12 10.8L14 12.13V3H21V5H16V15.87L12 13.2L8 15.87V5H5V19H19V7H21V21Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
