import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFileCsv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M4 22h16V6.59L15.41 2H13v7h5v11H6V4h5V2H4zM17.59 7H15V4.41z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M8.858 12.95c-1.01 0-1.61.82-1.61 2.06h-.01c0 1.23.6 2.05 1.61 2.05.85 0 1.39-.6 1.5-1.64h-.79c-.06.68-.34.94-.71.94-.5 0-.79-.45-.79-1.35s.29-1.36.79-1.36c.37 0 .65.26.71.92h.79c-.1-1.03-.64-1.62-1.49-1.62M10.762 15.61h.78c.03.56.26.82.68.82.36 0 .54-.18.54-.46 0-.389-.37-.498-.79-.621-.553-.162-1.19-.35-1.19-1.229 0-.72.47-1.17 1.32-1.17s1.32.48 1.33 1.38h-.78c-.02-.53-.21-.75-.58-.75-.33 0-.52.15-.52.46 0 .424.381.534.808.657.54.156 1.152.333 1.152 1.193 0 .74-.45 1.18-1.31 1.18s-1.41-.45-1.43-1.45zM15.918 17c.41-1.3.74-2.6 1.04-4h-.82c-.19.94-.41 1.89-.78 3.21-.38-1.32-.6-2.22-.79-3.21h-.83c.28 1.36.62 2.69 1.03 4z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFileCsv
