import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconStore = createIcon({
  displayName: 'IconStore',
  path: [
    <path
      d="M22 12V5.59L18.41 2H15.59L20 6.41V10H18V7H16V10H13V7H11V10H8V7H6V10H4V6.41L6.41 4H14.76L12.76 2H5.59L2 5.59V12H3V21H5V12H9V21H21V14H19V19H11V12H22Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
