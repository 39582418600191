import { VasGetResponse } from '@brenntag/connect-apis/api-vas/types'

import { closed } from './closed'
import { inProgress } from './inProgress'
import { newService } from './new'
import { onHold } from './onHold'
import { submittedForApproval } from './submittedForApproval'

const items: Record<string, VasGetResponse> = {
  closed,
  inProgress,
  newService,
  onHold,
  submittedForApproval,
}

export const valueAddedServices = {
  items,
  list: () => Object.values(items),
}
