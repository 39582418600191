import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m19.07 4.93-1.41 1.41A7.95 7.95 0 0 1 20 12c0 4.41-3.59 8-8 8s-8-3.59-8-8c0-4.07 3.06-7.44 7-7.94v2.03C8.17 6.57 6 9.03 6 12c0 3.31 2.69 6 6 6s6-2.69 6-6c0-1.6-.62-3.11-1.76-4.24l-1.41 1.41C15.59 9.92 16 10.93 16 12c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.86 1.28-3.41 3-3.86V13h2V2h-1C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10c0-2.67-1.04-5.18-2.93-7.07"
      fill="currentColor"
    />
  </svg>
)
export default SvgTrack
