import React from 'react'

import { Button } from '#components/Button.js'
import { Icon } from '#components/Icon.js'

import { useAppHeader } from '../hooks/useAppHeader'

export const AppHeaderDrawerToggle: React.FC = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useAppHeader()

  return (
    <Button className="lg:hidden" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
      <Icon name="Menu" />
    </Button>
  )
}
