import { http, HttpResponse } from 'msw'

import { SampleRequest } from '@brenntag/connect-apis/api-object/types'
import { SampleStatus } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildSample } from '@brenntag/legacy-mock-generators/generators/samples/buildSample.js'

import { config } from '#src/config.js'

import { products } from '../../api/products/data'
import { samples } from '../../api/samples/data'
import { locations } from '../../customer-api/data/locations'

export const postRequestSample = () => {
  return http.post<never, SampleRequest, null>(
    `${config.apiBaseUrl}/api-object/requests/samples`,
    async ({ request }) => {
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      const body = await request.json()
      const product = products.list().find(product => product.data.id === body.productId)
      const shipTo = Object.values(locations).find(location => location.id === shipToId)

      if (!product) {
        return HttpResponse.json(null, { status: 404 })
      }

      if (!shipTo) {
        return HttpResponse.json(null, { status: 404 })
      }

      const sample = buildSample({
        attributes: {
          requestQuantity: {
            quantity: body.quantity,
            uom: body.uom || 'unknown UOM',
          },
          status: SampleStatus.Requested,
        },
        included: {
          product,
          shipTo: buildLocation({ id: shipTo.id }),
        },
      })

      samples.items[sample.data.id as keyof typeof samples.items] = sample

      return HttpResponse.json(null, { status: 201 })
    },
  )
}
