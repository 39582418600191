import { HttpHandler } from 'msw'

import { cart } from './cart'
import { cartMissingProduct } from './cartMissingProduct'
import { manyCustomers } from './manyCustomers'
import { servicesDisabled } from './servicesDisabled'

export const scenarios: Record<string, HttpHandler[]> = {
  cart,
  cartMissingProduct,
  manyCustomers,
  servicesDisabled,
}
