import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFiltersNotshow = createIcon({
  displayName: 'IconFiltersNotshow',
  path: [
    <path d="M14 15H1V17H14V15Z" fill="currentColor" key="0" />,
    <path d="M23 7H10V9H23V7Z" fill="currentColor" key="1" />,
    <path d="M6 11H8V5H6V7H1V9H6V11Z" fill="currentColor" key="2" />,
    <path d="M18 13H16V19H18V17H23V15H18V13Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
