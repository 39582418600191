import { APICart } from '@brenntag/connect-apis/api-cart'
import { Cart } from '@brenntag/connect-apis/api-cart/models/cart'

import { shortStringDateToLocalDate } from '#shared/utils/date-utils.js'

export const transformDeliveryOptions = (apiCart: APICart): Cart['deliveryOptions'] => {
  const deliveryInstructions = apiCart.deliveryOptions?.deliveryInstructions || ''
  const requestedDeliveryDate = apiCart.deliveryOptions?.requestedDeliveryDate
    ? shortStringDateToLocalDate(apiCart.deliveryOptions.requestedDeliveryDate)
    : undefined

  return {
    deliveryInstructions,
    requestedDeliveryDate,
  }
}
