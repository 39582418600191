import { http, HttpResponse } from 'msw'

import { VasGetResponse } from '@brenntag/connect-apis/api-vas/types'

import { config } from '#src/config.js'

import { valueAddedServices } from './data'

export const fetchService = () => {
  return http.get<{ id: string }, null, VasGetResponse>(
    `${config.apiGatewayUrl}/vas-service/:id`,
    async ({ params }) => {
      const item = valueAddedServices.items[params.id]

      if (!item) {
        return HttpResponse.json(null, { status: 404 })
      }

      return HttpResponse.json(item)
    },
  )
}
