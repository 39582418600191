import { useEffect } from 'react'

const loadedScripts: Record<string, boolean> = {}

export const useScript = (
  url: string,
  { enabled = true }: { enabled: boolean } = { enabled: true },
): void => {
  useEffect(() => {
    if (!enabled || loadedScripts[url]) return
    const script = document.createElement('script')

    script.src = url
    script.async = true

    document.body.appendChild(script)
    loadedScripts[url] = true

    return () => {
      document.body.removeChild(script)
    }
  }, [url, enabled])
}
