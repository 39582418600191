import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '#utils/cn.js'

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-x-2 whitespace-nowrap rounded-none font-bold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary focus-visible:ring-2 focus-visible:ring-white dark:disabled:opacity-25 [&>svg]:align-top',
  {
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
    variants: {
      size: {
        lg: 'px-8 py-4 text-lg has-[svg:only-child]:px-4',
        md: 'px-6 py-3.5 text-base leading-5 has-[svg:only-child]:px-3.5',
        sm: 'min-h-10 px-4 py-2.5 text-sm has-[svg:only-child]:px-3',
      },
      variant: {
        action: `bg-gradient-to-r from-violet-50 to-blue-50 hover:bg-blue-50 hover:from-transparent active:bg-blue-60 disabled:cursor-not-allowed disabled:bg-neutral-30 disabled:bg-gradient-to-r disabled:from-neutral-30 disabled:to-neutral-30`,
        default: 'text-primary disabled:cursor-not-allowed disabled:bg-neutral-30',
        error: `bg-red-50 hover:bg-red-40 active:bg-red-60 disabled:cursor-not-allowed disabled:bg-neutral-30  disabled:bg-gradient-to-r disabled:from-neutral-30 disabled:to-neutral-30`,
        outline: `bg-transparent text-primary outline outline-2 outline-offset-[-2px] outline-primary hover:bg-neutral-20 hover:text-primary focus-visible:border-transparent focus-visible:bg-neutral-30 focus-visible:text-primary focus-visible:outline-offset-0 focus-visible:outline-white focus-visible:ring-4 focus-visible:ring-primary active:bg-neutral-30 active:text-primary disabled:cursor-not-allowed disabled:text-neutral-30 disabled:outline-neutral-30 disabled:hover:bg-transparent dark:text-white dark:outline-white dark:hover:bg-none dark:active:bg-neutral-70`,
        solid: `bg-primary hover:bg-neutral-80 active:bg-neutral-90 disabled:cursor-not-allowed disabled:bg-neutral-30 disabled:bg-gradient-to-r disabled:from-neutral-30 disabled:to-neutral-30 dark:bg-white dark:text-primary dark:hover:bg-neutral-10 dark:active:bg-neutral-20`,
        success: `bg-green-50 hover:bg-green-40 active:bg-green-60 disabled:cursor-not-allowed disabled:bg-neutral-30 disabled:bg-gradient-to-r disabled:from-neutral-30 disabled:to-neutral-30`,
        text: `border-0 text-primary hover:bg-neutral-20 focus-visible:bg-neutral-20 focus-visible:outline-2 active:bg-neutral-20 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-neutral-30 dark:text-white dark:hover:bg-none`,
      },
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  children: React.ReactNode
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, children = 'Button', className, size, variant, ...props }, ref) => {
    const Component = asChild ? Slot : 'button'
    return (
      <Component className={cn(buttonVariants({ size, variant }), className)} ref={ref} {...props}>
        {children}
      </Component>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
