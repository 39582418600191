import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconHandover = createIcon({
  displayName: 'IconHandover',
  path: [
    <path
      d="M22 9H20V5.41583L10.7072 14.7086L9.29297 13.2944L18.5874 4H15V2H22V9Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M18 12.4098L16.91 11.3298L18.33 9.90984L20 11.5898V21.9998H2V11.5898L11 2.58984L14.09 5.66984L12.67 7.08984L11 5.40984L4 12.4098V19.9998H18V12.4098Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
