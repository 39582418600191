import { atom } from 'jotai'

import { InvoiceFilters } from '@brenntag/connect-apis/api/models/invoice'

const invoiceFilterDefaults: InvoiceFilters = {
  page: '1',
  sort: '-invoiceDate',
} as const

export const invoiceFilters = atom<InvoiceFilters>(invoiceFilterDefaults)
