import type { APICart } from '@brenntag/connect-apis/api-cart'
import type { Cart } from '@brenntag/connect-apis/api-cart/models/cart'

export type CartTotalAmount = Pick<APICart, 'currencyIsoCode' | 'grandTotalAmount'>

export const transformTotalAmount = ({
  currencyIsoCode,
  grandTotalAmount,
}: CartTotalAmount): Cart['grandTotalAmount'] => {
  if (!grandTotalAmount || !currencyIsoCode) {
    return undefined
  }

  return {
    amount: grandTotalAmount,
    currency: currencyIsoCode,
  }
}
