import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconZoomIn = createIcon({
  displayName: 'IconZoomIn',
  path: [
    <path
      d="M16.31 5.10023L14.88 6.53023C15.58 7.51023 15.99 8.71023 15.99 10.0002C15.99 13.3102 13.3 16.0002 9.99 16.0002C6.68 16.0002 3.99 13.3102 3.99 10.0002C3.99 6.69023 6.68 4.00023 9.99 4.00023C11.28 4.00023 12.48 4.42023 13.46 5.11023L14.89 3.68023C13.53 2.63023 11.84 1.99023 9.99 1.99023C5.59 2.00023 2 5.59023 2 10.0002C2 14.4102 5.59 18.0002 10 18.0002C14.41 18.0002 18 14.4102 18 10.0002C18 8.15023 17.37 6.46023 16.31 5.10023Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M17.7381 16.3198C17.3181 16.8398 16.8381 17.3098 16.3281 17.7298L20.2981 21.6998L21.7081 20.2898L17.7381 16.3198Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M11 9H14V11H11V14H9V11H6V9H9V6H11V9Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
