import getCalculations from './calculations/getCalculations'
import postCalculation from './calculations/postCalculation'
import getNextCalculationPrice from './products/getNextCalculationPrice'
import getProducts from './products/getProducts'
import postRequestAccess from './requestAccess/postRequestAccess'

export const getMockHandlers = () => {
  return [
    ...getProducts(),
    ...getCalculations(),
    ...postCalculation(),
    ...postRequestAccess(),
    ...getNextCalculationPrice(),
  ]
}
