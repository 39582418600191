import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconScan = createIcon({
  displayName: 'IconScan',
  path: [
    <path d="M5 8H3V3H8V5H5V8Z" fill="currentColor" key="0" />,
    <path d="M8 21H3V16H5V19H8V21Z" fill="currentColor" key="1" />,
    <path d="M16 21H21V16H19V19H16V21Z" fill="currentColor" key="2" />,
    <path d="M21 8H19V5H16V3H21V8Z" fill="currentColor" key="3" />,
    <path d="M11 7H13V17H11V7Z" fill="currentColor" key="4" />,
    <path d="M15 7V17H17V7H15Z" fill="currentColor" key="5" />,
    <path d="M7 7H9V17H7V7Z" fill="currentColor" key="6" />,
  ],
  viewBox: '0 0 24 24',
})
