import { consola } from 'consola/browser'
import { jwtDecode } from 'jwt-decode'

/**
 * Checks if the idToken is expired based on the exp claim.
 * @param {String} idToken
 * @returns {Boolean} - True if the idToken is expired, false otherwise.
 */
export const isIdTokenExpired = (idToken: string) => {
  try {
    const payload = jwtDecode(idToken)
    const expiresAtMs = payload.exp ? payload.exp * 1000 : 0

    return new Date().getTime() > expiresAtMs
  } catch (error) {
    consola.error(error)
    return true
  }
}
