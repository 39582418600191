import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFileCertificate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M13 2h2.41L20 6.59V22H4V2h7v2H6v16h12V9h-5zm2 2.41V7h2.59z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12 10c1.65 0 3 1.35 3 3h-.02c0 1.08-.57 2.02-1.43 2.55l1.27 1.9-1.66 1.11-1.17-1.75-1.17 1.75-1.66-1.11 1.27-1.9A3.01 3.01 0 0 1 9 13c0-1.65 1.35-3 3-3m-.78 3.6c.19.24.46.4.78.4s.6-.16.78-.4c.04-.05.07-.1.1-.15l.025-.06.025-.06c.04-.11.07-.22.07-.34 0-.55-.45-1-1-1s-1 .45-1 1c0 .12.03.23.07.34.01.04.03.08.05.12.03.05.06.1.1.15"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgFileCertificate
