import { http, HttpResponse } from 'msw'

import {
  AttachmentsResponse,
  PostAttachmentsParams,
} from '#shared/apis/api-attachment/endpoints/postAttachments.js'
import { config } from '#src/config.js'

import { attachments } from './data'

export const postAttachment = () => {
  return http.post<never, PostAttachmentsParams, AttachmentsResponse>(
    `${config.apiGatewayUrl}/attachments`,
    async ({ request }) => {
      const data = await request.formData()
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)
      const entityId = searchParams.get('entityId') || ''

      const files = (data.get('base64_files') as string) || ''

      const response: AttachmentsResponse = {
        created: [],
        errors: [],
      }

      const parsedFiles = JSON.parse(files) as unknown as {
        files: {
          encoded_data: string
          extension: string
          filename: string
        }[]
      }

      for (const file of parsedFiles.files) {
        // generate random ID
        const id = Date.now().toString()
        const filename = `${file.filename}.${file.extension}`
        const linkedAttachment = {
          createdAt: new Date().toISOString(),
          id,
          name: filename,
          owner: {
            id: entityId,
            name: 'Attachment Owner',
          },
          updatedAt: new Date().toISOString(),
        }
        attachments.add(linkedAttachment, file.encoded_data)

        response.created.push({
          id,
          name: filename,
        })
      }

      return HttpResponse.json(response)
    },
  )
}
