import React, { useMemo } from 'react'

import { Flex, SpinnerProps } from '@chakra-ui/react'

export const FullscreenWrapper: React.FC<FullScreenLoaderProps> = ({
  isBlocking: blocking,
  opacity = 0,
  ...props
}) => {
  const before = useMemo(
    () => ({
      background: 'white',
      bottom: 0,
      content: '""',
      left: 0,
      opacity,
      pointerEvents: !blocking ? 'none' : undefined,
      position: 'absolute',
      right: 0,
      top: 0,
    }),
    [opacity, blocking],
  )
  return (
    <Flex
      _before={blocking && opacity ? before : undefined}
      alignItems="center"
      display="flex"
      height="100vh"
      justifyContent="center"
      left={0}
      opacity={1}
      pointerEvents={!blocking ? 'none' : undefined}
      position="fixed"
      top={0}
      width="100%"
      zIndex={1000}
      {...props}
    />
  )
}

export interface FullScreenStyleProps {
  isBlocking?: boolean
  opacity?: number
}
type FullScreenLoaderProps = FullScreenStyleProps & SpinnerProps
