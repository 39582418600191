import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSocialTwitter = createIcon({
  displayName: 'IconSocialTwitter',
  path: [
    <path
      d="M19.9006 8.13663C19.9127 8.31163 19.9127 8.48663 19.9127 8.66324C19.9127 14.0447 15.8159 20.2511 8.32477 20.2511V20.2479C6.11187 20.2511 3.94493 19.6173 2.08203 18.4221C2.40381 18.4608 2.72719 18.4802 3.05139 18.481C4.88526 18.4826 6.66671 17.8673 8.10945 16.7342C6.36671 16.7011 4.83848 15.5649 4.30461 13.906C4.9151 14.0237 5.54413 13.9995 6.14332 13.8358C4.24332 13.452 2.87639 11.7826 2.87639 9.84389C2.87639 9.82614 2.87639 9.80921 2.87639 9.79227C3.44252 10.1076 4.07639 10.2826 4.72477 10.302C2.93526 9.10598 2.38364 6.72534 3.46429 4.86405C5.53203 7.4084 8.58284 8.95518 11.8578 9.11889C11.5296 7.70437 11.978 6.22211 13.0361 5.22776C14.6764 3.68582 17.2562 3.76485 18.7982 5.40437C19.7103 5.22453 20.5844 4.88985 21.3845 4.41566C21.0804 5.3584 20.4441 6.15921 19.5941 6.66808C20.4014 6.57292 21.1901 6.35679 21.9328 6.02695C21.3861 6.84631 20.6974 7.56001 19.9006 8.13663Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
