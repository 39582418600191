import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypePricebook,
  DataTypeSalesGroup,
  SalesGroupAttributes,
  SalesGroupResource,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<SalesGroupAttributes>
  id?: string
  relationships: {
    pricebookId: string
  }
}

export const buildSalesGroup = ({
  attributes,
  id = faker.string.uuid(),
  relationships,
}: Props): SalesGroupResource => {
  return {
    attributes: merge(
      {
        country: { code: faker.location.countryCode(), country: faker.location.country() },
        isActive: true,
        languages: [{ code: faker.location.countryCode(), language: faker.location.country() }],
        name: faker.company.name(),
        shortName: faker.company.name(),
      } satisfies SalesGroupAttributes,
      attributes,
    ),
    id,
    relationships: {
      pricebook: { data: { id: relationships.pricebookId, type: DataTypePricebook.Pricebook } },
    },
    type: DataTypeSalesGroup.SalesGroup,
  }
}
