import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTrack = createIcon({
  displayName: 'IconTrack',
  path: [
    <path
      d="M19.07 4.93L17.66 6.34C19.17 7.85 20 9.86 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.93 7.06 4.56 11 4.06V6.09C8.17 6.57 6 9.03 6 12C6 15.31 8.69 18 12 18C15.31 18 18 15.31 18 12C18 10.4 17.38 8.89 16.24 7.76L14.83 9.17C15.59 9.92 16 10.93 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12C8 10.14 9.28 8.59 11 8.14V13H13V2H12C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.33 20.96 6.82 19.07 4.93Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
