import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSustainability = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 3v8.996a8.94 8.94 0 0 1-2.635 6.361 8.94 8.94 0 0 1-6.361 2.635 8.93 8.93 0 0 1-5.616-1.967L4.214 21.2 2.8 19.786l9.996-9.996 1.414 1.414-6.397 6.397a6.94 6.94 0 0 0 4.191 1.393 6.96 6.96 0 0 0 4.948-2.049 6.95 6.95 0 0 0 2.049-4.948V4.999h-6.997a6.96 6.96 0 0 0-4.948 2.049 6.95 6.95 0 0 0-2.049 4.948c0 .814.138 1.608.404 2.353l-1.528 1.528a8.96 8.96 0 0 1-.875-3.88c0-2.403.936-4.662 2.635-6.361A8.93 8.93 0 0 1 12.004 3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSustainability
