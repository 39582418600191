import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChevronRight = createIcon({
  displayName: 'IconChevronRight',
  path: [
    <path
      d="M8.71001 21.71L7.29001 20.29L15.59 12L7.29001 3.70999L8.71001 2.28999L18.41 12L8.71001 21.71Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
