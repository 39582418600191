import { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import { config } from '#src/config.js'
import { environment } from '#src/env.js'

export function initSentry() {
  try {
    Sentry.init({
      dsn: config.sentryDSN,
      environment,
      integrations: [
        Sentry.reactRouterV7BrowserTracingIntegration({
          createRoutesFromChildren,
          matchRoutes,
          useEffect,
          useLocation,
          useNavigationType,
        }),
        Sentry.captureConsoleIntegration(),
        Sentry.replayIntegration({
          maskAllText: true,
        }),
      ],
      release: `clientui-${environment}-${__COMMIT_HASH__}`,
      // Always try to send a replay for error events
      replaysOnErrorSampleRate: 1.0,
      // In 5% of sessions, send a replay event. We can lower this maybe? Let's see how it goes.
      replaysSessionSampleRate: 0.05,
      tracePropagationTargets: [
        new RegExp(`^${config.apiBaseUrl}.*`),
        new RegExp(`^${config.apiGatewayUrl}.*`),
        new RegExp(`^${config.cartApiBaseUrl}.*`),
        new RegExp(`^${config.customerApiBaseUrl}.*`),
        new RegExp(`^${config.co2ApiBaseUrl}.*`),
        new RegExp(`^${config.paymentApiBaseUrl}.*`),
      ],
      tracesSampleRate: 1.0,
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Sentry was unable to initialize', e)
  }
}
