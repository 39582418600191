import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconVolumeOff = createIcon({
  displayName: 'IconVolumeOff',
  path: [
    <path
      d="M3.70621 2.29138L2.29199 3.7056L20.2949 21.7085L21.7091 20.2943L3.70621 2.29138Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M7 11.24V14H10.41L15 18.59V21.41L9.59 16H5V9.23996L7 11.24Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M15 2.58997L10.91 6.66997L12.33 8.08997L13 7.40997V8.75997L15 10.76V2.58997Z"
      fill="currentColor"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
