import { SingleIncludedSampleResourceUnion } from '@brenntag/connect-apis/api/types'

import { hidePrices } from './hidePrices'
import { newSample } from './new'
import { requested } from './requested'
import { sampleForProductWithManyPricePoints } from './sampleForProductWithManyPricePoints'
import { sampleForProductWithMultipleTankLocations } from './sampleForProductWithTankLocations'
import { shipped } from './shipped'

const items = {
  hidePrices,
  newSample,
  requested,
  sampleForProductWithManyPricePoints,
  sampleForProductWithMultipleTankLocations,
  shipped,
} as const

type Samples = {
  items: Record<keyof typeof items, SingleIncludedSampleResourceUnion>
  list: () => SingleIncludedSampleResourceUnion[]
}

export const samples: Samples = {
  items,
  list: () => Object.values(items),
}
