import React, { RefObject, useEffect, useState } from 'react'

import { Button, Flex, FlexProps, Spacer } from '@chakra-ui/react'

import { IconChevronLeft, IconChevronRight } from '#shared/icons/brand/index.js'

const MAGIC_WIDTH_TO_SCROLL = 50
const arrowButtonHoverStyle = {
  backgroundColor: 'neutral.20',
}

interface TabListControllerProps extends FlexProps {
  elementRefs?: RefObject<Element>[]
  fixedScrollBy?: number
  targetRef: RefObject<Element>
}

export const TabListController = ({
  elementRefs = [],
  fixedScrollBy,
  targetRef,
  ...props
}: TabListControllerProps) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
  const [showLeft, setShowLeft] = useState<boolean>(false)
  const [showRight, setShowRight] = useState<boolean>(true)
  const [, reRender] = React.useState({})

  // we need this re-render as the ref.current is not assign on the first render
  useEffect(() => {
    reRender({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef.current, elementRefs])

  const elements = elementRefs.map(ref => ref.current) as Element[]
  const { current: container } = targetRef

  const isVisible = Boolean(container && container.clientWidth < container.scrollWidth)

  const onLeftUpdateVisibility = () => {
    if (!container) return

    const atLeftEdge = container.scrollLeft <= 1
    const atRightEdge = container.clientWidth + container.scrollLeft >= container.scrollWidth - 1
    // sometimes the browser underscrools by 0.5 pix making the exact comparison impossible
    if (showLeft && atLeftEdge) {
      setShowLeft(false)
    }
    if (!showRight && !atRightEdge) {
      setShowRight(true)
    }
  }
  const onRightUpdateVisibility = () => {
    if (!container) return
    const atLeftEdge = container.scrollLeft <= 1
    const atRightEdge = container.clientWidth + container.scrollLeft >= container.scrollWidth - 1
    // sometimes the browser underscrools by 0.5 pix making the exact comparison impossible
    if (showRight && atRightEdge) {
      setShowRight(false)
    }
    if (!showLeft && !atLeftEdge) {
      setShowLeft(true)
    }
  }

  const findCurrentTabByScrollAmount = () => {
    let width = 0
    for (let index = 0; index < elements.length; index++) {
      width += elements[index]?.clientWidth
      if (container!.scrollLeft < width) return index
    }
    return 0
  }

  const moveLeft = () => {
    if (!container) return

    container.scrollBy({
      behavior: 'smooth',
      left: -(
        fixedScrollBy ??
        elements[Math.max(currentTabIndex - 1, 0)]?.clientWidth ??
        MAGIC_WIDTH_TO_SCROLL
      ),
    })
    if (!fixedScrollBy) {
      setCurrentTabIndex(index => Math.max(index - 1, 0))
    }

    onLeftUpdateVisibility()
  }
  const moveRight = () => {
    if (!container) return

    container.scrollBy({
      behavior: 'smooth',
      left:
        fixedScrollBy ??
        elements[Math.min(currentTabIndex, elements.length - 1)]?.clientWidth ??
        MAGIC_WIDTH_TO_SCROLL,
    })
    if (!fixedScrollBy) {
      setCurrentTabIndex(index => Math.min(index + 1, elements.length - 1))
    }

    onRightUpdateVisibility()
  }

  useEffect(() => {
    const scrollEventListener = (_event: Event) => {
      onLeftUpdateVisibility()
      onRightUpdateVisibility()
      const newTabIndex = findCurrentTabByScrollAmount()

      if (!fixedScrollBy && currentTabIndex !== newTabIndex) {
        setCurrentTabIndex(newTabIndex)
      }
    }

    if (container) {
      container.addEventListener('scroll', scrollEventListener)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', scrollEventListener)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, showLeft, showRight, fixedScrollBy])

  if (!isVisible) return null

  return (
    <Flex
      alignItems="center"
      height="40px"
      justifyContent="space-between"
      pointerEvents="none"
      position="absolute"
      px={2}
      width="100%"
      zIndex={10}
      {...props}>
      {showLeft && (
        <Button
          _hover={arrowButtonHoverStyle}
          backgroundColor="neutral.10"
          borderRadius="8px"
          cursor="pointer"
          minWidth="auto"
          onClick={moveLeft}
          onKeyPress={e => {
            if (e.key !== 'enter' && e.key !== ' ') return
            e.preventDefault()
            moveLeft()
          }}
          p={1}
          paddingInlineEnd={1}
          paddingInlineStart={1}
          pointerEvents="auto"
          tabIndex={0}>
          <IconChevronLeft boxSize="20px" />
        </Button>
      )}
      <Spacer flex={1} />
      {showRight && (
        <Button
          _hover={arrowButtonHoverStyle}
          backgroundColor="neutral.10"
          borderRadius="8px"
          cursor="pointer"
          minWidth="auto"
          onClick={moveRight}
          onKeyPress={e => {
            if (e.key !== 'enter' && e.key !== ' ') return
            e.preventDefault()
            moveRight()
          }}
          p={1}
          paddingInlineEnd={1}
          paddingInlineStart={1}
          pointerEvents="auto"
          tabIndex={0}>
          <IconChevronRight boxSize="20px" />
        </Button>
      )}
    </Flex>
  )
}
