import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInvoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M4 2h16v2H6v16h12V6h2v16H4z" fill="currentColor" />
    <path
      d="M14.998 10h-3.58c-.12 0-.22.1-.22.22v.2c0 .08.05.16.13.2l2.39 1.13c.77.37 1.27 1.16 1.27 2.01 0 1.15-.88 2.09-2 2.2v2.02h-2v-2h-2v-2h3.78c.12 0 .22-.1.22-.22a.23.23 0 0 0-.13-.2l-2.39-1.13a2.25 2.25 0 0 1-1.27-2.01v-.2c0-1.08.78-1.98 1.8-2.18V6h2v2h2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgInvoice
