import React from 'react'

import { consola } from 'consola/browser'
import { matchPath, useLocation } from 'react-router'

import { TranslationKey } from '@brenntag/connect-translations'
import { AppHeaderNavigationItem } from '@brenntag/ui/components/AppHeader/AppHeaderNavigationItem'
import { useAppHeader } from '@brenntag/ui/components/AppHeader/useAppHeader'

import { AppLink } from '#components/AppLink/AppLink.js'
import { useCountry } from '#lib/countries/useCountry.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

type Props = {
  item: {
    labelKey: TranslationKey
    name: string
    to: string
  }
}

export const NavigationItem: React.FC<Props> = ({ item }) => {
  const { labelKey, name, to } = item
  const t = useTranslations()
  const country = useCountry()
  const location = useLocation()
  const { setIsDrawerOpen } = useAppHeader()

  if (!country) {
    consola.warn('Public header not loaded, no country found')
    return null
  }

  const isActive = Boolean(matchPath(`${item.to}/*`, location.pathname))

  return (
    <AppHeaderNavigationItem isActive={isActive} key={labelKey}>
      <AppLink data-testid={'menu-' + name} onClick={() => setIsDrawerOpen(false)} to={to}>
        {t(labelKey)}
      </AppLink>
    </AppHeaderNavigationItem>
  )
}
