import { CreditLimit } from '@brenntag/api-customer'

export const creditLimit = {
  eighty: {
    creditAvailable: 1000,
    creditLimit: 1000,
    creditLimitPercentage: 80,
    creditLimitUsed: 80,
  },
} satisfies Record<string, CreditLimit>
