import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSad = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m18.31 7.11 1.43-1.43A9.94 9.94 0 0 1 22 12c0 5.51-4.49 10-10 10S2 17.51 2 12 6.49 2 12.01 2c2.39 0 4.6.85 6.32 2.26L16.9 5.69A7.92 7.92 0 0 0 12 4c-4.41 0-8 3.6-8 8.01s3.59 8 8 8 8-3.59 8-8c0-1.85-.64-3.54-1.69-4.9"
      fill="currentColor"
    />
    <path
      d="M8.29 10.71a.995.995 0 0 0 1.09.21q.18-.075.33-.21c.19-.19.29-.45.29-.71s-.11-.52-.29-.71c-.28-.28-.73-.37-1.09-.21-.13.05-.23.12-.33.21-.18.19-.29.44-.29.71s.1.52.29.71M15.284 16.705l.005.005h-.01z"
      fill="currentColor"
    />
    <path
      d="m16.7 15.3-1.416 1.405-.455-.455a4.01 4.01 0 0 0-5.66 0l-.46.46-1.42-1.41.46-.46a6.01 6.01 0 0 1 8.49 0zM15 11a.99.99 0 0 1-1-1c0-.27.11-.52.29-.71l.05-.042a.757.757 0 0 1 .28-.168q.09-.045.18-.06c.33-.06.68.04.91.27.18.19.29.44.29.71a.99.99 0 0 1-1 1"
      fill="currentColor"
    />
  </svg>
)
export default SvgSad
