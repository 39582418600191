import { APIQuote } from '@brenntag/connect-apis/api'
import { QuoteList } from '@brenntag/connect-apis/api/models/quote'
import { ManyIncludedQuoteResourceUnion, QuoteStatus } from '@brenntag/connect-apis/api/types'

import { transformAPIQuotesToQuoteList } from '#shared/apis/api/quotes/transforms/quote.transforms.js'
import { convertFiltersToParams } from '#shared/apis/convertFiltersToParams.js'
import { request } from '#shared/apis/request.js'
import { deserialize } from '#shared/utils/deserialize.js'
import { config } from '#src/config.js'

export const quoteListSortKeys = [
  '-number',
  'number',
  '-requestedDeliveryDate',
  'requestedDeliveryDate',
] as const
export interface QuoteFilters {
  quoteCreationDateGreaterEqual?: Date
  quoteCreationDateLessEqual?: Date
  requestBy?: string
  searchQuery?: string
  shipToLocationId?: string
  status?: `${QuoteStatus}`
}

export type QuoteListSortKey = (typeof quoteListSortKeys)[number]

type QuoteFilter = keyof QuoteFilters

export const quoteFilters = {
  quoteCreationDateGreaterEqual: 'filter[quoteCreationDate.gte]',
  quoteCreationDateLessEqual: 'filter[quoteCreationDate.lte]',
  requestBy: 'filter[user.id.equals]',
  searchQuery: 'search[query]',
  shipToLocationId: 'filter[shipTo.id.equals]',
  status: 'filter[status.in]',
} satisfies Record<QuoteFilter, string>

interface Params {
  filters: QuoteFilters
  include?: string
  pageNumber: number
  pageSize: number
  sort: QuoteListSortKey
}

export const fetchQuotes = async ({
  filters,
  pageNumber,
  pageSize,
  sort,
}: Params): Promise<QuoteList> => {
  const response = await request<ManyIncludedQuoteResourceUnion>({
    method: 'GET',
    params: {
      include: 'user,ship_to,quoteLines.product',
      'page[number]': pageNumber,
      'page[size]': pageSize,
      sort,
      ...convertFiltersToParams(quoteFilters, filters),
    },
    url: `${config.apiBaseUrl}/api/quotes`,
  })

  const apiQuotes = deserialize<APIQuote[]>(response?.body)
  const meta = response.body?.meta
  return transformAPIQuotesToQuoteList(apiQuotes, meta, pageSize)
}
