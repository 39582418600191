import { http, HttpResponse } from 'msw'

import { FetchCartParams } from '@brenntag/api-cart'
import { APICart } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { locations } from '../customer-api/data/locations'
import { carts } from './data'

export const fetchCart = () => {
  return http.get<FetchCartParams, null, APICart>(`${config.cartApiBaseUrl}/carts`, async () => {
    return HttpResponse.json(carts[locations.showPrices.id])
  })
}
