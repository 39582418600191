import { http, HttpResponse } from 'msw'

import { config } from '#src/config.js'

import { attachments } from './data'

export const fetchAttachmentPreview = () => {
  return http.get<{ id: string }, null, string>(
    `${config.apiGatewayUrl}/attachments/:id/preview`,
    async ({ params }) => {
      const blob = attachments.getPreview(params.id)
      if (!blob) {
        throw new HttpResponse('Attachment not found', { status: 404 })
      }

      return HttpResponse.json(blob)
    },
  )
}
