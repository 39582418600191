import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSpinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 22C6.49 22 2 17.51 2 12h2c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8V2c5.51 0 10 4.49 10 10s-4.49 10-10 10"
      fill="currentColor"
    />
  </svg>
)
export default SvgSpinner
