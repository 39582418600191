import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDocumentation = createIcon({
  displayName: 'IconDocumentation',
  path: [
    <path d="M8 2H16V4H8V2Z" fill="currentColor" key="0" />,
    <path d="M8 6H16V8H8V6Z" fill="currentColor" key="1" />,
    <path d="M14 14H10V16H14V14Z" fill="currentColor" key="2" />,
    <path d="M20 12H6V20H18V14H20V22H4V4H6V10H18V4H20V12Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
