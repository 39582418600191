import React from 'react'

import { Button, ButtonProps, Flex } from '@chakra-ui/react'

import { TranslationKey } from '@brenntag/connect-translations'

import { IconByName, IconNamesBrand } from '#shared/icons/brand/index.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface GenericModalFooterProps {
  actionButtonId?: string
  actionIcon?: IconNamesBrand
  actionTextLabel: TranslationKey
  actionType?: ButtonProps['type']
  actionVariant?: ButtonProps['variant']
  cancelIcon?: IconNamesBrand
  cancelTextLabel?: TranslationKey
  children?: React.ReactNode
  isActionDisabled?: boolean
  isCancelDisabled?: boolean
  isLoadingAction?: boolean
  isReactionRequired?: boolean
  onAction?: React.MouseEventHandler<HTMLButtonElement>
  onCancel?: VoidFunction
  onReaction?: VoidFunction
  reactionButtonId?: string
  reactionIcon?: IconNamesBrand
  reactionTextLabel?: TranslationKey
  reactionVariant?: ButtonProps['variant']
}

export const GenericModalFooter = ({
  actionButtonId = 'modal-action-btn',
  actionIcon,
  actionTextLabel,
  actionType = 'button',
  actionVariant = 'action',
  cancelIcon,
  cancelTextLabel,
  children,
  isActionDisabled,
  isCancelDisabled,
  isLoadingAction,
  isReactionRequired = false,
  onAction,
  onCancel,
  onReaction,
  reactionButtonId = 'modal-reaction-btn',
  reactionIcon,
  reactionTextLabel,
  reactionVariant = 'solid',
}: GenericModalFooterProps) => {
  const t = useTranslations()
  return (
    <Flex
      alignItems="flex-end"
      direction={['column', 'row']}
      gridGap={4}
      justifyContent="flex-start"
      w="100%">
      <Button
        data-analyticsid={actionButtonId}
        data-testid={actionButtonId}
        id={actionButtonId}
        isDisabled={isActionDisabled}
        isLoading={isLoadingAction}
        leftIcon={actionIcon && <IconByName boxSize="24px" name={actionIcon} />}
        onClick={onAction}
        type={actionType}
        variant={actionVariant}
        width={['100%', 'auto']}>
        {t(actionTextLabel)}
      </Button>

      {isReactionRequired ? (
        <Button
          data-analyticsid={reactionButtonId}
          data-testid={reactionButtonId}
          id={reactionButtonId}
          isDisabled={isActionDisabled}
          isLoading={isLoadingAction}
          leftIcon={reactionIcon && <IconByName boxSize="24px" name={reactionIcon} />}
          onClick={onReaction}
          variant={reactionVariant}
          width={['100%', 'auto']}>
          {reactionTextLabel && t(reactionTextLabel)}
        </Button>
      ) : onCancel ? (
        <Button
          data-analyticsid="modal-cancel-btn"
          data-testid="modal-cancel-btn"
          id="modal-cancel-btn"
          isDisabled={isCancelDisabled}
          leftIcon={<IconByName boxSize="24px" name={cancelIcon || 'IconClose'} />}
          onClick={onCancel}
          variant="outline"
          width={['100%', 'auto']}>
          {cancelTextLabel ? t(cancelTextLabel) : t('label.cancel')}
        </Button>
      ) : null}

      {children && (
        <Flex
          flex={['auto', null, '1 1']}
          h="48px"
          justifyContent={['inherit', 'flex-end']}
          order={[-1, 'unset']}
          w="100%">
          {children}
        </Flex>
      )}
    </Flex>
  )
}
