import { CaseGetResponse } from '@brenntag/connect-apis/api-case/types'
import { buildCase } from '@brenntag/legacy-mock-generators/generators/cases/buildCase.js'
import { buildCases } from '@brenntag/legacy-mock-generators/generators/cases/buildCases.js'

const items: Record<string, CaseGetResponse> = {
  case1: buildCase({ overrides: { id: 'case1', subject: 'Case 1', summary: 'Summary 1' } }),
  case2: buildCase({ overrides: { id: 'case2', subject: 'Case 2', summary: 'Summary 2' } }),
}

export const cases = {
  items,
  list: () =>
    Object.values(items).map(
      ({ id, number, purchaseOrderNumber, relatedTo, status, subject, summary }) =>
        buildCases({
          overrides: { id, number, purchaseOrderNumber, relatedTo, status, subject, summary },
        }),
    ),
}
