import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUnfold = createIcon({
  displayName: 'IconUnfold',
  path: [
    <path
      d="M12 4.41L17.29 9.71L18.71 8.29L12 1.59L5.29001 8.29L6.71001 9.71L12 4.41Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M5.29001 15.71L12 22.41L18.71 15.71L17.29 14.29L12 19.59L6.71001 14.29L5.29001 15.71Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
