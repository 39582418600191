import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { fetchCustomers, FetchCustomersResponse } from '@brenntag/api-customer/fetch-customers'

export interface FetchCustomers {
  enabled?: boolean
  id?: string
  page?: number
  pageSize?: number
  query?: string
}

export const useFetchCustomers = ({
  enabled = true,
  id,
  page = 1,
  pageSize = 15,
  query,
}: FetchCustomers): UseQueryResult<FetchCustomersResponse> => {
  return useQuery({
    enabled,
    queryFn: () =>
      fetchCustomers({
        id: id?.toString(),
        page: page.toString(),
        pageSize: pageSize.toString(),
        query,
      }),
    queryKey: ['FetchCustomers', pageSize, page, query],
    retry: 1,
  })
}
