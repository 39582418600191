import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconArrowUp = createIcon({
  displayName: 'IconArrowUp',
  path: [
    <path
      d="M12 4.41L19.29 11.71L20.71 10.29L12 1.59L3.29001 10.29L4.71001 11.71L12 4.41Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M11 8.24L12 7.24L13 8.24V22H11V8.24Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
