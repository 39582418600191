import * as React from 'react'
import type { SVGProps } from 'react'
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m19.78 9.8-3.9 3.91 1.65 5.77L12 15.8l-5.53 3.68 1.65-5.77-3.9-3.91 5.25-.9L12 4.18l2.53 4.72 2.84.49 1.73-1.73-3.27-.56L13.1 2h-2.2L8.17 7.1 2 8.16v2.25l3.88 3.88L3.67 22H6.3l5.7-3.8 5.7 3.8h2.63l-2.21-7.71L22 10.41V8.16l-.49-.09z"
      fill="currentColor"
    />
  </svg>
)
export default SvgStar
