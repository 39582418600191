import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFilters = createIcon({
  displayName: 'IconFilters',
  path: [
    <path d="M6 11H8V5H6V7H1V9H6V11Z" fill="currentColor" key="0" />,
    <path d="M10 7H23V9H10V7Z" fill="currentColor" key="1" />,
    <path d="M1 15H14V17H1V15Z" fill="currentColor" key="2" />,
    <path d="M16 13H18V15H23V17H18V19H16V13Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
