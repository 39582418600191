import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconUnlocked = createIcon({
  displayName: 'IconUnlocked',
  path: [
    <path
      d="M21 21H3V11H5V19H19V13H7V7C7 4.24 9.24 2 12 2C14.76 2 17 4.24 17 7H15C15 5.35 13.65 4 12 4C10.35 4 9 5.35 9 7V11H21V21Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
