import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUnfold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m12 4.41 5.29 5.3 1.42-1.42L12 1.59l-6.71 6.7 1.42 1.42zM5.29 15.71l6.71 6.7 6.71-6.7-1.42-1.42-5.29 5.3-5.29-5.3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUnfold
