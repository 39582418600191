import { http, HttpResponse } from 'msw'

import { OrderTrackingResponse } from '@brenntag/connect-apis/api/types'

import { config } from '#src/config.js'

import { estimated } from './data/order-tracking/estimated'
import { withEstimatedDeliveryDate } from './data/withEstimatedDeliveryDate'

export const fetchOrderTracking = () => {
  return http.get<{ id: string }, null, OrderTrackingResponse>(
    `${config.apiBaseUrl}/api/orders/:id/tracking`,
    async ({ params }) => {
      if (params.id === withEstimatedDeliveryDate.data.id) {
        return HttpResponse.json(estimated)
      }

      return HttpResponse.json(null, { status: 404 })
    },
  )
}
