import { CalculationPrice, Product } from '#requests/products/types.js'

export const products: Product[] = [
  { cas: '107-21-1', id: '1', name: 'Ethylene Glycol' },
  { cas: '67-64-1', id: '2', name: 'Acetone' },
  { cas: '7681-52-9', id: '3', name: 'Sodium Hypochlorite' },
  { cas: '7664-93-9', id: '4', name: 'Sulfuric Acid' },
  { cas: '67-63-0', id: '5', name: 'Isopropyl Alcohol' },
  { cas: '67-56-1', id: '6', name: 'Methanol' },
  { cas: '1310-73-2', id: '7', name: 'Sodium Hydroxide' },
  { cas: '7647-01-0', id: '8', name: 'Hydrochloric Acid' },
  { cas: '471-34-1', id: '9', name: 'Calcium Carbonate' },
  { cas: '144-55-8', id: '10', name: 'Sodium Bicarbonate' },
  { cas: '56-81-5', id: '11', name: 'Glycerol' },
  { cas: '108-88-3', id: '12', name: 'Toluene' },
  { cas: '1336-21-6', id: '13', name: 'Ammonium Hydroxide' },
  { cas: '1310-58-3', id: '14', name: 'Potassium Hydroxide' },
  { cas: '71-43-2', id: '15', name: 'Benzene' },
  { cas: '50-00-0', id: '16', name: 'Formaldehyde' },
  { cas: '7722-84-1', id: '17', name: 'Hydrogen Peroxide' },
  { cas: '7697-37-2', id: '18', name: 'Nitric Acid' },
  { cas: '64-19-7', id: '19', name: 'Acetic Acid' },
  { cas: '6484-52-2', id: '20', name: 'Ammonium Nitrate' },
  { cas: '7757-82-6', id: '21', name: 'Sodium Sulfate' },
  { cas: '77-92-9', id: '22', name: 'Citric Acid' },
  { cas: '1314-13-2', id: '23', name: 'Zinc Oxide' },
  { cas: '7487-88-9', id: '24', name: 'Magnesium Sulfate' },
  { cas: '7647-14-5', id: '25', name: 'Sodium Chloride' },
  { cas: '141-78-6', id: '26', name: 'Ethyl Acetate' },
  { cas: '497-19-8', id: '27', name: 'Sodium Carbonate' },
  { cas: '7757-83-7', id: '28', name: 'Sodium Sulfite' },
  { cas: '7758-98-7', id: '29', name: 'Copper Sulfate' },
  { cas: '10043-52-4', id: '30', name: 'Calcium Chloride' },
  { cas: '10043-35-3', id: '31', name: 'Boric Acid' },
  { cas: '554-13-2', id: '32', name: 'Lithium Carbonate' },
  { cas: '1344-09-8', id: '33', name: 'Sodium Silicate' },
  { cas: '78-93-3', id: '34', name: 'Methyl Ethyl Ketone' },
  { cas: '7664-38-2', id: '35', name: 'Phosphoric Acid' },
  { cas: '10043-01-3', id: '36', name: 'Aluminum Sulfate' },
  { cas: '7772-98-7', id: '37', name: 'Sodium Thiosulfate' },
  { cas: '7757-79-1', id: '38', name: 'Potassium Nitrate' },
  { cas: '1305-62-0', id: '39', name: 'Calcium Hydroxide' },
  { cas: '7631-99-4', id: '40', name: 'Sodium Nitrate' },
  { cas: '532-32-1', id: '41', name: 'Sodium Benzoate' },
  { cas: '7783-20-2', id: '42', name: 'Ammonium Sulfate' },
  { cas: '1305-78-8', id: '43', name: 'Calcium Oxide' },
  { cas: '7705-08-0', id: '44', name: 'Ferric Chloride' },
  { cas: '7786-30-3', id: '45', name: 'Magnesium Chloride' },
  { cas: '7447-40-7', id: '46', name: 'Potassium Chloride' },
  { cas: '9002-89-5', id: '47', name: 'Polyvinyl Alcohol' },
  { cas: '7727-43-7', id: '48', name: 'Barium Sulfate' },
  { cas: '7778-18-9', id: '49', name: 'Calcium Sulfate' },
  { cas: '7601-54-9', id: '50', name: 'Sodium Phosphate' },
  { cas: '68-04-2', id: '51', name: 'Sodium Citrate' },
  { cas: '7681-57-4', id: '52', name: 'Sodium Metabisulfite' },
  { cas: '1066-33-7', id: '53', name: 'Ammonium Bicarbonate' },
  { cas: '10124-56-8', id: '54', name: 'Sodium Hexametaphosphate' },
  { cas: '4075-81-4', id: '55', name: 'Calcium Propionate' },
  { cas: '151-21-3', id: '56', name: 'Sodium Lauryl Sulfate' },
  { cas: '1309-48-4', id: '57', name: 'Magnesium Oxide' },
  { cas: '7722-64-7', id: '58', name: 'Potassium Permanganate' },
  { cas: '1330-43-4', id: '59', name: 'Sodium Borate' },
  { cas: '1313-82-2', id: '60', name: 'Sodium Sulfide' },
  { cas: '7681-53-0', id: '61', name: 'Sodium Hypophosphite' },
  { cas: '7646-85-7', id: '62', name: 'Zinc Chloride' },
  { cas: '10124-37-5', id: '63', name: 'Calcium Nitrate' },
  { cas: '822-16-2', id: '64', name: 'Sodium Stearate' },
  { cas: '21645-51-2', id: '65', name: 'Aluminum Hydroxide' },
  { cas: '546-93-0', id: '66', name: 'Magnesium Carbonate' },
  { cas: '7758-87-4', id: '67', name: 'Calcium Phosphate' },
  { cas: '7722-76-1', id: '68', name: 'Ammonium Phosphate' },
  { cas: '584-08-7', id: '69', name: 'Potassium Carbonate' },
  { cas: '7733-02-0', id: '70', name: 'Zinc Sulfate' },
  { cas: '813-94-5', id: '71', name: 'Calcium Citrate' },
  { cas: '7775-27-1', id: '72', name: 'Sodium Persulfate' },
  { cas: '7446-70-0', id: '73', name: 'Aluminum Chloride' },
  { cas: '1313-60-6', id: '74', name: 'Sodium Peroxide' },
  { cas: '14807-96-6', id: '75', name: 'Magnesium Silicate' },
  { cas: '12135-76-1', id: '76', name: 'Ammonium Sulfide' },
  { cas: '298-14-6', id: '77', name: 'Potassium Bicarbonate' },
]

export const calculationPrices: CalculationPrice[] = [
  { price: { amount: 29.99, currency: 'USD' } },
  { price: { amount: 0, currency: 'USD' } },
  { price: { amount: 50.0, currency: 'USD' } },
]
