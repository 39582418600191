import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21.39 19.79 8.66 10H6c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4H8c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2h3.34l13.27 10.21-1.22 1.59z"
      fill="currentColor"
    />
    <path
      d="m16.18 10.74 6.43-4.95-1.22-1.58-6.85 5.27zM4 18c0 1.1.9 2 2 2s2-.9 2-2h2c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4h2.66l.96-.74 1.64 1.26L9.34 16H6c-1.1 0-2 .9-2 2"
      fill="currentColor"
    />
  </svg>
)
export default SvgCut
