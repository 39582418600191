import consola from 'consola/browser'
import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { carts } from './data'

export const deletePromotionCode = () => {
  return http.delete<{ cartId: string; code: string }, null, APICart>(
    `${config.apiBaseUrl}/api-cart/carts/:cartId/promotion-codes/:code`,
    async ({ params, request }) => {
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      if (!shipToId) {
        return HttpResponse.json(null, { status: 400 })
      }

      const cart = carts[shipToId]
      if (!cart) {
        consola.log('cart not found')
        return HttpResponse.json(null, { status: 404 })
      }

      cart.promotionCodes!.codes = cart.promotionCodes!.codes.filter(code => code !== params.code)

      return HttpResponse.json(carts[shipToId], { status: 201 })
    },
  )
}
