import { http, HttpResponse } from 'msw'

import { SingleIncludedInvoiceResourceUnion } from '@brenntag/connect-apis/api/types'

import { config } from '#src/config.js'

import { invoices } from './data'

export const fetchInvoice = () => {
  return http.get<{ id: string }, null, SingleIncludedInvoiceResourceUnion>(
    `${config.apiBaseUrl}/api/invoices/:id`,
    async ({ params }) => {
      const id = params.id as keyof typeof invoices.items
      if (!invoices.items[id]) {
        return HttpResponse.json(null, { status: 404 })
      }

      const invoice = invoices.items[id]

      return HttpResponse.json(invoice)
    },
  )
}
