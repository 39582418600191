import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconGrid = createIcon({
  displayName: 'IconGrid',
  path: [
    <path
      clipRule="evenodd"
      d="M3 11H11V3H3V11ZM9 9H5V5H9V9Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M3 21H11V13H3V21ZM9 19H5V15H9V19Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
    <path
      clipRule="evenodd"
      d="M21 11H13V3H21V11ZM15 9H19V5H15V9Z"
      fill="currentColor"
      fillRule="evenodd"
      key="2"
    />,
    <path
      clipRule="evenodd"
      d="M13 21H21V13H13V21ZM19 19H15V15H19V19Z"
      fill="currentColor"
      fillRule="evenodd"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
