import { authConfig } from '../../config.js'

const { clientId, domain, pathPrefix } = authConfig.salesforce

/**
 * Constructs the Salesforce authorize URL with the given state.
 * @param state - The state object. You can pass any data you want to be returned in the state parameter when the user is redirected back to your app.
 * @returns the Salesforce authorize URL.
 * @todo Make nonce dynamic (for example based on a random number or a timestamp). Ensure it will still work.
 */
export const createAuthorizeUrl = (state: Record<string, unknown>) =>
  `https://${domain}${pathPrefix}/services/oauth2/authorize` +
  '?response_type=code' +
  `&state=${encodeURIComponent(JSON.stringify(state))}` +
  `&redirect_uri=${window.location.origin}/login-handler` +
  `&client_id=${clientId}` +
  '&scope=openid+profile+email+api' +
  '&nonce=12345' +
  '&sso_provider=Auth0' +
  '&prompt=none'
