import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconVolumeLow = createIcon({
  displayName: 'IconVolumeLow',
  path: [
    <path
      d="M16 21.41L10.59 16H6V7.99997H10.59L16 2.58997V11H14V7.40997L11.41 9.99997H8V14H11.41L14 16.59V13H16V21.41Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
