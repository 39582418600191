export type ApiTokens = {
  access_token: string
  id_token: string
}

export type Config = {
  baseUrl: string
  getTokens: () => Promise<ApiTokens>
}

const externalConfig: Partial<Config> = {}

export type ExternalConfig = Omit<Config, 'getTokens'> & {
  getTokens: () => Promise<ApiTokens | undefined>
}

export const setConfig = (config: ExternalConfig) => {
  Object.assign(externalConfig, config)
}

export const getConfig = (): Config => {
  if (!externalConfig.baseUrl) {
    throw new Error('"baseUrl" is not set')
  }

  return {
    baseUrl: externalConfig.baseUrl,
    getTokens: async () => {
      if (!externalConfig.getTokens) {
        throw new Error('"getTokens" is not set')
      }

      const tokens = await externalConfig.getTokens()
      if (!tokens) {
        throw new Error('Cannot fetch, missing access_token or id_token')
      }

      return tokens
    },
  }
}
