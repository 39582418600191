import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { cartItems } from '../apis/api-cart/data/cartItems'

const dummyCart = {
  currencyIsoCode: 'USD',
  deliveryOptions: {},
  grandTotalAmount: 710.47,
  id: '0a6O80000006U9tIAE',
  items: [],
  messagesSummary: {
    errorCount: 0,
    hasErrors: false,
    limitedMessages: [],
    relatedEntityId: '0a6O80000006U9tIAE',
    totalLineItemsWithErrors: 0,
  },
  promotionCodes: {
    codes: [],
  },
  status: 'Active',
  taxType: 'Net',
  totalChargeAmount: 0.0,
  totalDiscountAmount: 0.0,
  totalListPrice: 0.0,
  totalProductAmount: 710.47,
  totalProductAmountAfterAdjustments: 710.47,
  totalProductCount: 8.0,
  totalPromotionalAdjustmentAmount: 0.0,
  totalTaxAmount: 0.0,
  uniqueProductCount: 4,
} as const satisfies APICart

const itemWithMissingProduct = {
  id: 'does-not-exist',
  name: undefined,
  product: undefined,
  productDetails: {
    ...cartItems[1].productDetails,
    name: undefined,
  },
  totalPrice: 0.0,
}

const missingCartItemProductData = {
  ...dummyCart,
  grandTotalAmount: dummyCart.grandTotalAmount - cartItems[1].totalPrice,
  items: [
    cartItems[0],
    // @ts-expect-error API generated types are wrong. Mock is based on real production API output and correct.
    itemWithMissingProduct,
    cartItems[2],
    cartItems[3],
  ],
} satisfies APICart

export const cartMissingProduct = [
  http.get(`${config.apiGatewayUrl}/api-cart/carts`, async () => {
    return HttpResponse.json(missingCartItemProductData)
  }),
]
