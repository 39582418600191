import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFileAdd = createIcon({
  displayName: 'IconFileAdd',
  path: [
    <path
      clipRule="evenodd"
      d="M13 2H15.41L20 6.59V22H4V2H11V4H6V20H18V9H13V2ZM15 4.41V7H17.59L15 4.41Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path d="M11 13V11H13V13H15V15H13V17H11V15H9V13H11Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
