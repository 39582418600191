import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypePimSpecification,
  DataTypeProduct,
  PimSpecificationAttributes,
  PimSpecificationResource,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<PimSpecificationAttributes>
  id?: string
  productId: string
}

export const buildPimSpecification = ({
  attributes,
  id = faker.string.uuid(),
  productId,
}: Props): PimSpecificationResource => {
  return {
    attributes: merge(
      {
        createdAt: faker.date.past().toISOString(),
        groupId: faker.helpers.arrayElement(['PHYSICALPROPERTIES', 'SDS']),
        groupName: faker.helpers.arrayElement(['Physical Properties', 'SDS']),
        isActive: true,
        label: `${faker.science.chemicalElement().name} Content`,
        updatedAt: faker.date.past().toISOString(),
        value: `${faker.number.int(50).toString()} g/100g`,
      } satisfies PimSpecificationAttributes,
      attributes,
    ),
    id,
    relationships: {
      product: {
        data: {
          id: productId,
          type: DataTypeProduct.Product,
        },
      },
    },
    type: DataTypePimSpecification.PimSpecification,
  }
}
