import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconStarFilled = createIcon({
  displayName: 'IconStarFilled',
  path: [
    <path
      d="M22 8.16L15.83 7.1L13.1 2H10.9L8.17 7.1L2 8.16V10.41L5.88 14.29L3.67 22H6.3L12 18.2L17.7 22H20.33L18.12 14.29L22 10.41V8.16Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
