import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFileError = createIcon({
  displayName: 'IconFileError',
  path: [
    <path
      clipRule="evenodd"
      d="M13 2H15.41L20 6.59V22H4V2H11V4H6V20H18V9H13V2ZM15 4.41V7H17.59L15 4.41Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path
      d="M11.9991 12.59L14.2891 10.29L15.7091 11.71L13.4091 14L15.7091 16.29L14.2891 17.71L11.9991 15.41L9.70906 17.71L8.28906 16.29L10.5891 14L8.28906 11.71L9.70906 10.29L11.9991 12.59Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
