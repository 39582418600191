import React from 'react'

import { Link, LinkProps } from 'react-router'

import { cn } from '@brenntag/ui/utils/cn'

import { useCreatePath } from '#shared/routing/hooks/useCreatePath.js'

interface Props extends LinkProps {
  activeClassName?: string
  children: React.ReactNode
  className?: string
  to: string
}

export const AppLink = ({ activeClassName, children, className, to, ...props }: Props) => {
  const path = useCreatePath(to) ?? ''
  const isActive = window.location.pathname.includes(path)

  return (
    <Link className={cn(className, isActive && activeClassName)} to={path} {...props}>
      {children}
    </Link>
  )
}
