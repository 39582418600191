import * as React from 'react'
import type { SVGProps } from 'react'
const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 22.41 8.59 19H3V3h18v2H5v12h4.41L12 19.59 14.59 17H19V7h2v12h-5.59z"
      fill="currentColor"
    />
    <path
      d="M13 14h-2v-1.41l2-2V10c0-.55-.45-1-1-1s-1 .45-1 1H9c0-1.65 1.35-3 3-3s3 1.35 3 3v1.41l-2 2zM13 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      fill="currentColor"
    />
  </svg>
)
export default SvgQuestion
