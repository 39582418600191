import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconStar = createIcon({
  displayName: 'IconStar',
  path: [
    <path
      d="M19.78 9.8L15.88 13.71L17.53 19.48L12 15.8L6.47 19.48L8.12 13.71L4.22 9.8L9.47 8.9L12 4.18L14.53 8.9L17.37 9.39L19.1 7.66L15.83 7.1L13.1 2H10.9L8.17 7.1L2 8.16V10.41L5.88 14.29L3.67 22H6.3L12 18.2L17.7 22H20.33L18.12 14.29L22 10.41V8.16L21.51 8.07L19.78 9.8Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
