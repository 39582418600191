import { useEffect, useState } from 'react'

import consola from 'consola/browser'
import { useLDClient } from 'launchdarkly-react-client-sdk'

/*
 * Correctly handle the LaunchDarkly client initialization
 */
export const useLaunchDarklyClient = () => {
  const ldClient = useLDClient()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (!ldClient) return
    ldClient
      .waitForInitialization(5)
      .then(() => setIsInitialized(true))
      .catch(error => {
        consola.error('Error initializing LaunchDarkly client', error)
      })
  }, [ldClient])

  return { isInitialized, ldClient }
}
