import React, { type FC } from 'react'

export const Loading: FC<{ text?: string }> = ({ text }) => (
  <div className="fixed inset-0 flex flex-col items-center justify-center gap-6">
    <svg
      fill="none"
      height="48px"
      viewBox="0 0 262 48"
      width="262px"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="glow" x1="0%" x2="100%" y1="0%" y2="100%">
          <stop offset="-80%" stopColor="#1A0033">
            <animate
              attributeName="stop-color"
              begin="1.2s"
              dur="0.8s"
              fill="freeze"
              from="#1A0033"
              id="color"
              to="#1A0033"
            />
          </stop>
          <stop offset="100%" stopColor="#1A0033" />
        </linearGradient>
        <linearGradient id="shine" x1="0%" x2="100%" y1="0%" y2="100%">
          <stop offset="-20%" stopColor="white">
            <animate
              attributeName="offset"
              begin="color.end"
              dur="2s"
              repeatCount="indefinite"
              values="-.2;.95"
            />
          </stop>
          <stop offset="-10%" stopColor="black">
            <animate
              attributeName="offset"
              begin="color.end"
              dur="2s"
              repeatCount="indefinite"
              values="0;1"
            />
          </stop>
          <stop offset="0%" stopColor="white">
            <animate
              attributeName="offset"
              begin="color.end"
              dur="2s"
              repeatCount="indefinite"
              values=".05;1.05"
            />
          </stop>
        </linearGradient>

        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1092_5215"
          x1="7"
          x2="42.99"
          y1="48"
          y2="12.01">
          <stop stopColor="#C815E0"></stop>
          <stop offset="1" stopColor="#0C72ED"></stop>
        </linearGradient>

        <mask id="myShiningMask">
          <rect fill="url(#shine)" height="100%" width="100%" x="0" y="0" />
        </mask>
      </defs>

      <path
        d="M41.17 24.43c-1.46 1.72-3.56 3.4-6.2 4.45-.01 0-.11.05-.19.08l.13.13c.55.5 2.02 2.17 1.98 4.91-.04 3.31-2.59 6-5.9 6H15V28h14c7.72 0 14-6.28 14-14S36.72 0 29 0H7v18.01h8v-10h14c3.31 0 6 2.69 6 6s-2.69 6-6 6H7V48h24c7.72.01 14-6.27 14-13.99 0-3.73-1.45-7.08-3.83-9.59v.01z"
        fill="url(#paint0_linear_1092_5215)"
        mask="url(#myShiningMask)"></path>
      <path
        clipRule="evenodd"
        d="M249 26v-3.99h12c.1.54.21 2 .21 2.64 0 6.81-4.86 11.86-11.74 11.86S237 30.79 237 24.01c0-6.81 5.59-12.34 12.53-12.34 3.76 0 7.19 1.71 9.45 4.39l-3 2.35a8.503 8.503 0 00-6.45-2.94c-4.76 0-8.53 3.8-8.53 8.53 0 4.73 3.77 8.5 8.47 8.5 3.92 0 6.4-1.96 7.38-5.47.05-.15.16-.7.16-1.03H249zM83 29c0 3.78-3 7-7.22 7H64V12h11.17C79 12 82 15.22 82 19c0 1.81-.59 3.45-2 4.5 1.6.83 3 2.95 3 5.5zm-7.99-13H68v6h7.01C76.8 22 78 20.86 78 19s-1.2-3-2.99-3zm.64 16c1.79 0 3.35-1.12 3.35-2.98 0-1.86-1.56-3.02-3.35-3.02H68v6h7.65zm21.6-5l6.75 9v.01h5l-7-9.5c.124-.059.356-.148.549-.222l.251-.098c2.76-1.12 4.2-3.74 4.2-6.79 0-4.09-2.85-7.4-7.26-7.4H89v24h4v-9h4.25zM93 23v-7h6.58c2.3 0 3.42 1.29 3.42 3.4s-1.12 3.6-3.42 3.6H93zm24.98-7H130v-4h-16v24h16v-4h-12.02v-6H128v-4h-10.02v-6zM154 12.01h4v23.98h-4.89L141 18v18h-4V12h5l12 18.14V12.01zm32 0h-4v18.13L170 12h-5v24h4V18l12.11 17.99H186V12.01zm26-.01v4h-9v20h-4V16h-9v-4h22zm16 17l3 6.99h4L225 12h-4l-10 24h4l3-7h10zm-5-12l3.55 8.5h-7.1L223 17z"
        fill="url(#glow)"
        fillRule="evenodd"
        mask="url(#myShiningMask)"></path>
    </svg>
    {text && (
      <p
        className="w-full text-center transition delay-150 ease-in-out"
        style={{ viewTransitionName: 'global-loader-text' }}>
        {text}
      </p>
    )}
  </div>
)
