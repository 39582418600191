import { http, HttpResponse } from 'msw'

import { Customer } from '@brenntag/api-customer'

import { config } from '#src/config.js'

import { customers as _customers } from './data/customers'

export const fetchCustomers = (data: Customer[] = _customers.basic) => {
  return http.get(`${config.customerApiBaseUrl}/customer/customers`, async ({ request }) => {
    const url = new URL(request.url)
    const searchParams = new URLSearchParams(url.search)
    const query = searchParams.get('search')

    const filteredData = query
      ? data.filter(
          customer =>
            customer.name.toLowerCase().includes(query.toLowerCase()) ||
            customer.number.toLowerCase().includes(query.toLowerCase()),
        )
      : data

    return HttpResponse.json({
      data: filteredData,
      meta: { count: data.length },
    })
  })
}
