import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShipments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="m16.162 1.68 3.64 10.89-.01.01-7.9 2.63-3.63-10.9zm-3.86 8.39.87 2.6 4.1-1.37-.87-2.6zm3.47-3.27-.87-2.6-4.1 1.37.87 2.6z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13 19.06A3.01 3.01 0 0 1 10 22c-1.65 0-3-1.35-3-3 0-.952.45-1.804 1.147-2.355L4.362 5.28l-2.06.68-.63-1.9 3.96-1.32L10.062 16h-.024a3 3 0 0 1 2.326 1.158l9.325-3.108.63 1.9zm-.293-1.35c.192.389.302.824.302 1.29H13c0-.461-.105-.899-.293-1.29M9 19c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgShipments
