import { useContext } from 'react'

import { AppHeaderContext, AppHeaderContextProps } from '../context/AppHeaderContext'

export const useAppHeader = (): AppHeaderContextProps => {
  const context = useContext(AppHeaderContext)
  if (!context) {
    throw new Error('useAppHeader must be used within an AppHeaderProvider')
  }
  return context
}
