import { http, HttpResponse } from 'msw'

import { APICart } from '@brenntag/connect-apis/api-cart'

import { DeleteCartParams } from '#shared/apis/api-cart/cart/endpoints/deleteCart.js'
import { config } from '#src/config.js'

import { buildCart } from './builders/cartBuilder'
import { carts } from './data'

export const deleteCart = () => {
  return http.delete<never, DeleteCartParams, APICart>(
    `${config.apiBaseUrl}/api-cart/carts`,
    async ({ request }) => {
      const url = new URL(request.url)
      const searchParams = new URLSearchParams(url.search)

      const shipToId = searchParams.get('ship_to_id')
      if (!shipToId) {
        return HttpResponse.json(null, { status: 400 })
      }

      carts[shipToId] = buildCart(shipToId)

      return HttpResponse.json(carts[shipToId], { status: 201 })
    },
  )
}
