import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVolumeMid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.59 16 14 21.41V13h-2v3.59L9.41 14H6v-4h3.41L12 7.41V11h2V2.59L8.59 8H4v8zM16 8.56V6.35c2.33.83 4 3.04 4 5.65s-1.67 4.82-4 5.65v-2.21c1.19-.69 2-1.97 2-3.44s-.81-2.75-2-3.44"
      fill="currentColor"
    />
  </svg>
)
export default SvgVolumeMid
