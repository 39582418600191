import { useEffect } from 'react'

import { RouteObject } from 'react-router'

import { useSalesforceSession } from '#lib/auth/salesforce/useSession.js'
import { useSession } from '#lib/auth/useSession.js'

export const logoutRoute: RouteObject = {
  children: [
    {
      Component: () => {
        const { endSession } = useSalesforceSession()

        useEffect(() => {
          endSession()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return null
      },
      path: 'salesforce',
    },
  ],
  // This route provides a backup where the user can logout in case the dashboard is not available
  // In normal scenarios, the user would logout on logout button click
  Component: () => {
    const { logout } = useSession()

    useEffect(() => {
      logout()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
  },
  path: 'logout',
}
