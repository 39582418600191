import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m12.29 19.29 1.42 1.42 8.7-8.71-8.7-8.71-1.42 1.42 7.3 7.29z" fill="currentColor" />
    <path d="m15.76 11 1 1-1 1H2v-2z" fill="currentColor" />
  </svg>
)
export default SvgArrowRight
