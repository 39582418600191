import { atom } from 'jotai'

import { ServiceFilters } from '@brenntag/connect-apis/api-vas/models/service'

const serviceFilterDefaults: ServiceFilters = {
  page: '1',
  sort: '-createdDate',
} as const

export const serviceFilters = atom<ServiceFilters>(serviceFilterDefaults)
