import React, { FC } from 'react'

import { CaseList } from '@brenntag/connect-apis/api-case/models/case'

import { getCaseStatusStyles } from '#shared/components/Card/CaseCard/getCaseStatusStyles.js'
import { ResultItem, ResultItemTitle } from '#shared/components/GlobalSearch/ResultItem.js'
import { ItemSearchResult } from '#shared/components/GlobalSearch/SearchResults/ItemSearchResult.js'
import { useFormatDate } from '#shared/locale/hooks/useIntl.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface CaseSearchResultProps {
  caseList: CaseList
  onItemClick: VoidFunction
  onViewMoreClick: VoidFunction
  search: string
}

export const CaseSearchResult: FC<CaseSearchResultProps> = ({
  caseList: { content: cases, page },
  onItemClick,
  onViewMoreClick,
  search,
}) => {
  const t = useTranslations()
  const formatDate = useFormatDate()
  return (
    <ItemSearchResult
      items={cases}
      linkToDetails
      onItemClick={onItemClick}
      onViewMoreClick={onViewMoreClick}
      to="/:locale/connect/cases"
      total={page?.total ?? 0}
      type="cases">
      {caseItem => {
        const status = getCaseStatusStyles(t, caseItem.status)
        return (
          <ResultItem
            fields={{
              items: [
                {
                  key: 'date-created',
                  label: t('label.createddate'),
                  value: formatDate(caseItem.createdDate),
                },
                {
                  key: 'order-number',
                  label: t('label.orderNumber'),
                  value: caseItem.orderNumber,
                },
                {
                  key: 'invoice-number',
                  label: t('label.invoiceNumber'),
                  value: caseItem.invoiceNumber,
                },
                {
                  key: 'purchase-order-number',
                  label: t('label.purchaseOrderNumber'),
                  value: caseItem.purchaseOrderNumber,
                },
                {
                  key: 'subject',
                  label: t('label.subject'),
                  value: caseItem.subject,
                },
              ],
              search,
            }}
            title={
              <ResultItemTitle
                label={t('label.case')}
                search={search}
                status={status}
                value={caseItem.number}
              />
            }
          />
        )
      }}
    </ItemSearchResult>
  )
}
