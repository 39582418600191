import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconHappy = createIcon({
  displayName: 'IconHappy',
  path: [
    <path
      d="M18.31 7.11L19.74 5.68C21.15 7.4 22 9.6 22 12C22 17.51 17.51 22 12 22C6.49 22 2 17.51 2 12C2 6.49 6.49 2 12.01 2C14.4 2 16.61 2.85 18.33 4.26L16.9 5.69C15.54 4.63 13.85 4 12 4C7.59 4 4 7.6 4 12.01C4 16.42 7.59 20.01 12 20.01C16.41 20.01 20 16.42 20 12.01C20 10.16 19.36 8.47 18.31 7.11Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M8.29 10.7099C8.48 10.8999 8.73 10.9999 9 10.9999C9.27 10.9999 9.52 10.8999 9.71 10.7099C9.9 10.5199 10 10.2699 10 9.99987C10 9.72987 9.89 9.47987 9.71 9.28987C9.43 9.00987 8.98 8.91987 8.62 9.07987C8.49 9.12987 8.38 9.19987 8.29 9.28987C8.11 9.47987 8 9.72987 8 9.99987C8 10.2699 8.1 10.5199 8.29 10.7099Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M14.62 10.9199C14.74 10.9699 14.87 10.9999 15 10.9999C15.27 10.9999 15.52 10.8999 15.71 10.7099C15.9 10.5199 16 10.2599 16 9.99987C16 9.73987 15.89 9.47987 15.71 9.28987C15.43 9.00987 14.99 8.91987 14.62 9.07987C14.49 9.12987 14.39 9.19987 14.29 9.28987C14.11 9.47987 14 9.72987 14 9.99987C14 10.2699 14.1 10.5199 14.29 10.7099C14.39 10.7999 14.5 10.8699 14.62 10.9199Z"
      fill="currentColor"
      key="2"
    />,
    <path
      d="M12 16.9998C10.4 16.9998 8.89 16.3798 7.76 15.2398L7.25 14.6498L8.76 13.3398L9.22 13.8798C9.93 14.5798 10.93 14.9998 12 14.9998C13.07 14.9998 14.07 14.5798 14.83 13.8298L15.25 13.3498L16.76 14.6598L16.3 15.1998C15.12 16.3898 13.61 17.0098 12.01 17.0098L12 16.9998Z"
      fill="currentColor"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
