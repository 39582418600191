import consola from 'consola/browser'

import { APIQuote, APIQuoteLine, ManyMeta } from '@brenntag/connect-apis/api'
import { Quote, QuoteLine, QuoteList, QuoteStatus } from '@brenntag/connect-apis/api/models/quote'

import { transformAPIAttachmentsToAttachments } from '#shared/apis/api-object/attachments/transforms/attachment.transforms.js'
import { transformAPIProductToProduct } from '#shared/apis/api/products/transforms/product.transforms.js'
import { transformAPILocationToCustomerLocation } from '#shared/apis/api/shared/transforms/location.transforms.js'
import { generateTotalPages } from '#shared/utils/pagination.js'
import { getTotalPrice } from '#shared/utils/price-utils.js'

const DEFAULT_QUOTE_STATUS: QuoteStatus = 'Processing'

const quoteStatusWithAddToCart: QuoteStatus[] = ['Done']
const quoteStatusWithRequote: QuoteStatus[] = ['Declined', 'Done']

const quoteStatusWithExpiredPriceState: QuoteStatus[] = ['Declined', 'Done']

const transformAPIQuoteLineToQuoteLine = (quote: Quote, apiQuoteLine: APIQuoteLine): QuoteLine => {
  const product = transformAPIProductToProduct(apiQuoteLine.product)
  const isPricebookProductEqual = Boolean(
    apiQuoteLine.product.pricebookProduct?.id === apiQuoteLine.pricebookProduct?.id,
  )
  return {
    allowAddToCart:
      product.isOrderable &&
      isPricebookProductEqual &&
      quoteStatusWithAddToCart.includes(quote.status),
    allowRequote: !product.isOrderable && quoteStatusWithRequote.includes(quote.status),
    annualConsumption: apiQuoteLine.annualConsumption,
    id: apiQuoteLine.id,
    isExpired:
      product.isOrderable &&
      !isPricebookProductEqual &&
      quoteStatusWithExpiredPriceState.includes(quote.status),
    product,
    quantity: apiQuoteLine.quoteQuantity,
    quoteStatus: quote.status || DEFAULT_QUOTE_STATUS,
    totalPrice: getTotalPrice(apiQuoteLine.quoteQuantity, product.pricepoints),
  }
}

export const transformAPIQuoteToQuote = (apiQuote: APIQuote): Quote => {
  const status: QuoteStatus = apiQuote.status ?? DEFAULT_QUOTE_STATUS

  const quote: Quote = {
    attachments: transformAPIAttachmentsToAttachments(apiQuote.attachments),
    commentFromUser: apiQuote.commentFromUser,
    creationDate: apiQuote.quoteCreationDate ? new Date(apiQuote.quoteCreationDate) : undefined,
    id: apiQuote.id,
    incoTerms: apiQuote.incoTerms,
    number: apiQuote.number,
    quoteLines: [],
    requestedBy: apiQuote.user ? `${apiQuote.user.firstName} ${apiQuote.user.lastName}` : 'unknown',
    requestedDeliveryDate: apiQuote.requestedDeliveryDate
      ? new Date(apiQuote.requestedDeliveryDate)
      : undefined,
    shipTo: transformAPILocationToCustomerLocation(apiQuote.shipTo),
    status,
  }

  quote.quoteLines = (apiQuote.quoteLines || ([] satisfies APIQuoteLine[]))
    // Somehow, sometimes the backend does not return the product object in the quote line
    // if that happens, we just filter it out, since we only have an ID and nothing else
    .filter(quoteline => {
      if (!quoteline.product?.createdAt) {
        consola.error('Quote line without product included', quote, quoteline)
      }
      return quoteline.product?.createdAt
    })
    .map(quoteLine => transformAPIQuoteLineToQuoteLine(quote, quoteLine))

  if (quote.quoteLines.length) {
    quote.total = quote.quoteLines
      .filter(quoteLine => Boolean(quoteLine.totalPrice && quoteLine.allowAddToCart))
      .reduce(
        (acc, { totalPrice }) => ({
          amount: acc.amount + (totalPrice?.amount || 0),
          currency: totalPrice?.currency || acc.currency,
        }),
        { amount: 0, currency: '' },
      )
  }
  return quote
}

export const transformAPIQuotesToQuoteList = (
  apiQuotes: APIQuote[],
  meta: ManyMeta,
  pageSize: number,
): QuoteList => ({
  quotes: apiQuotes.map(transformAPIQuoteToQuote),
  total: meta?.count || 0,
  totalPages: generateTotalPages(meta?.count, pageSize),
})
