import React, { StrictMode } from 'react'

import { createRoot } from 'react-dom/client'

import { initSentry } from '#components/SentryIdentifier/initSentry.js'

import { App } from './App'
import { isEnvironment } from './env'

initSentry()

async function loadMocks() {
  if (isEnvironment('production')) {
    return
  }

  const { setup } = await import('./mocks/browser')
  return setup()
}

const container = document.getElementById('react-root')
const root = createRoot(container!)

loadMocks().then(() => {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  )
})
