import { http, HttpResponse } from 'msw'

import { Webstore } from '@brenntag/api-customer'

import { config } from '#src/config.js'

import { webstores as _webstores } from './data/webstores'

export const fetchWebstores = (data: Webstore[] = _webstores.us) => {
  return http.get(`${config.customerApiBaseUrl}/customer/webstores`, async () => {
    return HttpResponse.json({
      data,
      meta: { count: data.length },
    })
  })
}
