import { getMockHandlers as co2MockHandlers } from '@brenntag/api-co2/mocks/handlers'

import { deleteLinkedAttachment } from './apis/api-attachment/deleteLinkAttachment'
import { downloadAttachment } from './apis/api-attachment/downloadAttachment'
import { fetchAttachmentPreview } from './apis/api-attachment/fetchAttachmentPreview'
import { fetchLinkedAttachment } from './apis/api-attachment/fetchLinkedAttachment'
import { postAttachment } from './apis/api-attachment/postAttachments'
import { completeCheckout } from './apis/api-cart/completeCheckout'
import { deleteCart } from './apis/api-cart/deleteCart'
import { deleteCheckout } from './apis/api-cart/deleteCheckout'
import { deletePromotionCode } from './apis/api-cart/deletePromotionCode'
import { fetchCart } from './apis/api-cart/fetchCart'
import { fetchCartById } from './apis/api-cart/fetchCartById'
import { fetchCheckout } from './apis/api-cart/fetchCheckout'
import { patchCart } from './apis/api-cart/patchCart'
import { patchCartItem } from './apis/api-cart/patchCartItem'
import { patchCheckout } from './apis/api-cart/patchCheckout'
import { postCartItem } from './apis/api-cart/postCartItem'
import { postOrder } from './apis/api-cart/postCheckout'
import { postFavoritesToCart } from './apis/api-cart/postFavoritesToCart'
import { postPromotionCodes } from './apis/api-cart/postPromotionCodes'
import { postReorderAll } from './apis/api-cart/postReorderAll'
import { fetchCase } from './apis/api-case/fetchCase'
import { postCase } from './apis/api-case/postCase'
import { postCaseComment } from './apis/api-case/postCaseComment'
import { searchCases } from './apis/api-case/searchCases'
import { postFavoriteItem } from './apis/api-object/favorites/createFavoriteItem'
import { postFavoriteList } from './apis/api-object/favorites/createFavoriteList'
import { deleteFavoriteItem } from './apis/api-object/favorites/deleteFavoriteItem'
import { deleteFavoriteList } from './apis/api-object/favorites/deleteFavoriteList'
import { patchFavoriteList } from './apis/api-object/favorites/updateFavoriteList'
import { postRequestQuote } from './apis/api-object/quotes/postRequestQuote'
import { postRequestSample } from './apis/api-object/samples/postRequestSample'
import { changePassword } from './apis/api-user/changePassword'
import { fetchProfiles } from './apis/api-user/fetchProfiles'
import { fetchMe } from './apis/api-user/me'
import { patchMe } from './apis/api-user/patchMe'
import { register } from './apis/api-user/register'
import { scheduleDemo } from './apis/api-user/scheduleDemo'
import { postService } from './apis/api-vas/createValueAddedService'
import { fetchService } from './apis/api-vas/fetchService'
import { searchServices } from './apis/api-vas/searchServices'
import { fetchDocuments } from './apis/api/documents/fetchDocuments'
import { fetchFavoritesLists } from './apis/api/favorites/fetchFavoritesList'
import { fetchInvoice } from './apis/api/invoices/fetchInvoice'
import { fetchInvoices } from './apis/api/invoices/fetchInvoices'
import { fetchOrder } from './apis/api/orders/fetchOrder'
import { fetchOrders } from './apis/api/orders/fetchOrders'
import { fetchOrderTracking } from './apis/api/orders/fetchOrderTracking'
import { fetchProduct } from './apis/api/products/fetchProduct'
import { fetchProducts } from './apis/api/products/fetchProducts'
import { fetchQuote } from './apis/api/quotes/fetchQuote'
import { fetchQuotes } from './apis/api/quotes/fetchQuotes'
import { fetchSamples } from './apis/api/samples/fetchSamples'
import { fetchCreditLimit } from './apis/customer-api/fetchCreditLimit'
import { fetchCustomers } from './apis/customer-api/fetchCustomers'
import { fetchSalesGroups } from './apis/customer-api/fetchSalesGroups'
import { fetchWebstores } from './apis/customer-api/fetchWebstores'
import { fetchLaunchDarklyFlags } from './apis/launch-darkly/fetchFlags'
import { fetchGoals } from './apis/launch-darkly/fetchGoals'
import { postEvents } from './apis/launch-darkly/postEvents'
import { qualtrics } from './apis/qualtrics/qualtrics'
import { fetchSentry } from './apis/sentry/sentry'

export const defaultHandlers = [
  ...co2MockHandlers(),

  fetchMe(),
  fetchProfiles(),
  changePassword(),
  patchMe(),
  register(),
  scheduleDemo(),

  fetchProduct(),
  fetchProducts(),

  fetchInvoices(),
  fetchInvoice(),

  fetchSamples(),
  postRequestSample(),

  fetchQuotes(),
  fetchQuote(),
  postRequestQuote(),

  fetchDocuments(),

  fetchFavoritesLists(),

  fetchOrders(),
  fetchOrder(),

  fetchCart(),
  fetchCartById(),
  patchCart(),
  postCartItem(),
  patchCartItem(),
  deleteCart(),
  postPromotionCodes(),
  deletePromotionCode(),
  postReorderAll(),
  postFavoritesToCart(),
  fetchCheckout(),
  postOrder(),
  deleteCheckout(),
  completeCheckout(),
  patchCheckout(),

  fetchCustomers(),

  fetchSalesGroups(),
  fetchOrderTracking(),
  fetchWebstores(),
  fetchCreditLimit(),

  fetchService(),
  postService(),
  searchServices(),

  postFavoriteList(),
  postFavoriteItem(),
  deleteFavoriteItem(),
  deleteFavoriteList(),
  patchFavoriteList(),

  fetchCase(),
  searchCases(),
  postCase(),
  postCaseComment(),

  postAttachment(),
  downloadAttachment(),
  deleteLinkedAttachment(),
  fetchAttachmentPreview(),
  fetchLinkedAttachment(),

  fetchSentry(),
  fetchLaunchDarklyFlags(),
  fetchGoals(),
  postEvents(),
  qualtrics(),
]
