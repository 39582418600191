import React, { FC } from 'react'

import { HStack, VStack } from '@chakra-ui/react'

import { DocumentList } from '@brenntag/connect-apis/api/models/productDocument'

import { DocumentDownloadButton } from '#shared/components/DocumentDownload/DocumentDownloadButton.js'
import { ResultItem, ResultItemTitle } from '#shared/components/GlobalSearch/ResultItem.js'
import { ItemSearchResult } from '#shared/components/GlobalSearch/SearchResults/ItemSearchResult.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { getDocumentDownloadUrl } from '#shared/utils/lib/downloadUrl.js'

interface DocumentSearchResultProps {
  documentList: DocumentList
  onViewMoreClick: VoidFunction
  search: string
}

export const DocumentSearchResult: FC<DocumentSearchResultProps> = ({
  documentList: { documents, total },
  onViewMoreClick,
  search,
}) => {
  const t = useTranslations()

  return (
    <ItemSearchResult
      items={documents}
      onViewMoreClick={onViewMoreClick}
      to="/:locale/connect/documents"
      total={total}
      type="documents">
      {document => {
        const documentUrl = getDocumentDownloadUrl({ id: document.id, type: 'document' })
        return (
          <HStack justifyContent="space-between">
            <ResultItem
              fields={{
                items: [
                  {
                    key: 'brenntagproductnumber',
                    label: t('label.brenntagproductnumber'),
                    value: document.product.number,
                  },
                  {
                    key: 'producer',
                    label: t('label.producer'),
                    value: document.producer,
                  },
                  {
                    key: 'documenttype',
                    label: t('label.documenttype'),
                    value: document.type,
                  },
                ],
                search,
              }}
              title={<ResultItemTitle search={search} value={document.product.name} />}
            />
            <VStack justifyContent="center" p={4}>
              <DocumentDownloadButton url={documentUrl} variant="download">
                {t('label.download')}
              </DocumentDownloadButton>
            </VStack>
          </HStack>
        )
      }}
    </ItemSearchResult>
  )
}
