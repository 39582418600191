export const loadHiddenFrame = (url: string): HTMLIFrameElement => {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'

  iframe.src = url
  iframe.className = 'absolute top-0 left-0 w-[900px] h-[900px]'

  document.body.prepend(iframe)

  return iframe
}
