import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFilm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 5V3H3v2h2v2H3v2h2v2H3v2h2v2H3v2h2v2H3v2h18v-2h-2v-2h2v-2h-2v-2h2v-2h-2V9h2V7h-2V5zm-4 14H7v-6h10zm0-8H7V5h10z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFilm
