import { APIProduct } from '@brenntag/connect-apis/api'
import { ProductList } from '@brenntag/connect-apis/api/models/product'
import { ManyIncludedProductResourceWithPricebookProductUnion } from '@brenntag/connect-apis/api/types'

import { transformAPIProductsToProductList } from '#shared/apis/api/products/transforms/product.transforms.js'
import { convertFiltersToParams } from '#shared/apis/convertFiltersToParams.js'
import { request } from '#shared/apis/request.js'
import { deserialize } from '#shared/utils/deserialize.js'
import { config } from '#src/config.js'

export const productListSortKeys = [
  'featured',
  'mostOrdered',
  'lastOrdered',
  'number',
  '-number',
  'name',
  '-name',
  'packaging.name',
  '-packaging.name',
] as const

export interface ProductFilters {
  brand?: string
  category?: string
  dangerousGoods?: string
  favoriteListId?: string
  hazardous?: boolean
  packageType?: string
  previouslyOrdered?: string
  pricePointExists?: boolean
  productIds?: string[]
  searchQuery?: string
  shipToLocationId: string
}

export type ProductListSortKey = (typeof productListSortKeys)[number]

type ProductFilter = keyof ProductFilters

export const productFilters = {
  brand: 'filter[brand.in]',
  category: 'filter[categories.id.equals]',
  dangerousGoods: 'filter[dangerousGoodsClass.equals]',
  favoriteListId: 'filter[favoriteItems.favorite.id.equals]',
  hazardous: 'filter[isHazardous.equals]',
  packageType: 'filter[packaging.type.in]',
  previouslyOrdered: 'filter[ordered]',
  pricePointExists: 'filter[pricebookProduct.pricePoints.exists]',
  productIds: 'filter[id.in]',
  searchQuery: 'search[query]',
  shipToLocationId: 'filter[shipTo.id.equals]',
} satisfies Record<ProductFilter, string>

export interface FetchProductsParams {
  filters: ProductFilters
  include?: string
  pageNumber: number
  pageSize: number
  sort: ProductListSortKey
}

export const fetchProducts = async ({
  filters,
  include,
  pageNumber,
  pageSize,
  sort,
}: FetchProductsParams): Promise<ProductList> => {
  const response = await request<ManyIncludedProductResourceWithPricebookProductUnion>({
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
    params: {
      include: `pricebookProduct.pricePoints,pricebookProduct.pricebook,tankLocations,${include}`,
      'page[number]': pageNumber,
      'page[size]': pageSize,
      sort,
      ...convertFiltersToParams(productFilters, filters),
    },
    url: `${config.apiBaseUrl}/api/products`,
  })

  const apiProducts = deserialize<APIProduct[]>(response.body)
  const meta = response.body?.meta
  return transformAPIProductsToProductList(apiProducts, meta, pageSize)
}
