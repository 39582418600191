import { salesforceSession } from '#lib/auth/salesforce/salesforceSession.js'
import { config } from '#src/config.js'

type DocumentDownloadType = 'document' | 'invoice' | 'order' | 'quote' | 'sds'
type FormatUrlFn = (documentType: DocumentDownloadType, documentId: string) => string

const formatUrlDefault: FormatUrlFn | VoidFunction = (documentType, documentId) =>
  `${config.apiBaseUrl}/api-object/downloads/${documentType}/${documentId}`

interface DocumentDownloadUrlParams {
  formatUrl?: FormatUrlFn
  id: string
  queryParams?: Record<string, string>
  type: DocumentDownloadType
}

export const getDocumentDownloadUrl = ({
  formatUrl = formatUrlDefault,
  id,
  queryParams,
  type,
}: DocumentDownloadUrlParams) => {
  const { accessToken } = salesforceSession.getTokens()
  const query = new URLSearchParams({ token: accessToken ?? '' })
  if (queryParams && queryParams.shipToId) {
    query.append('ship_to_id', queryParams.shipToId)
  }
  return `${formatUrl(type, id)}?${query.toString()}`
}
