export const authConfig = {
  auth0: {
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
  },
  salesforce: {
    additionalParameters: {
      prompt: 'login',
    },
    clientId: import.meta.env.VITE_SALESFORCE_CLIENT_ID,
    domain: import.meta.env.VITE_SALESFORCE_DOMAIN,
    internalDomain: import.meta.env.VITE_SALESFORCE_INTERNAL_DOMAIN,
    pathPrefix: import.meta.env.VITE_SALESFORCE_PATH_PREFIX ?? '',
  },
} as const
