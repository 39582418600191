import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSample = createIcon({
  displayName: 'IconSample',
  path: [
    <path
      d="M66.8334 48.7867V63.5H0.166748V48.7867L23.5001 25.4533V0.166656H30.1667V28.2133L6.83342 51.5467V56.8333H60.1668V51.5467L36.8334 28.2133V0.166656H43.5001V25.4533L66.8334 48.7867ZM17.6401 50.1667H49.3568L42.6901 43.5H24.3067L17.6401 50.1667Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 67 64',
})
