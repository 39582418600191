import { http, HttpResponse } from 'msw'

import { APICheckout } from '@brenntag/connect-apis/api-cart'

import { config } from '#src/config.js'

import { checkouts } from './data'

export const fetchCheckout = () => {
  return http.get<{ orderId: string }, null, APICheckout>(
    `${config.cartApiBaseUrl}/orders/:orderId`,
    async ({ params }) => {
      const order = checkouts[params.orderId]
      if (!order) {
        return HttpResponse.json(null, { status: 404 })
      }
      return HttpResponse.json(order)
    },
  )
}
