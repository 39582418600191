import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNoPreview = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="m3 10.76-2 2V1h18v2H3z" fill="currentColor" />
    <path d="M12 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2" fill="currentColor" />
    <path
      d="M1 19h18v-2H3v-.59l6-6 3.07 3.07L17 7.56V15h2V5h-2.47l-4.6 5.52L9 7.59l-8 8z"
      fill="currentColor"
    />
  </svg>
)
export default SvgNoPreview
