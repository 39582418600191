import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19 3H5L2 9l10 12 1.298-1.558L5.45 10h13.1l-5.25 6.313 1.301 1.565L22 9zM7.4 8H4.75l1.5-3H8.9zm2.225 0 1.5-3h1.75l1.5 3zM16.6 8l-1.5-3h2.65l1.5 3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDiamond
