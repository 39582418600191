import React from 'react'

import {
  Box,
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { getCountries } from '#lib/countries/getCountries.js'
import { Flag } from '#shared/components/Flag/Flag.js'
import { IconByName } from '#shared/icons/brand/index.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

import { Backdrop } from './components/Backdrop'
import { Header } from './components/Header'
import { LargeHeading } from './components/LargeHeading'
import { OptionsList } from './components/OptionsList'
import { Search } from './components/Search'
import { Subheading } from './components/Subheading'
import { CountryLanguageSelectorProps } from './CountryLanguageSelector'
import { useFilteredCountries } from './hooks/useFilteredCountries'
import { CountryLanguageSelectorOption } from './types/types'

const countries = getCountries()

export const CountryLanguageSelectorInPopover = ({
  onOptionSelect,
  selectedOption,
}: CountryLanguageSelectorProps) => {
  const t = useTranslations()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { options, setSearchTerm } = useFilteredCountries({ countries, selectedOption })
  const allowSearch = countries.length > 1

  const handleOptionSelect = (selected: CountryLanguageSelectorOption) => {
    onOptionSelect?.(selected)
    onClose()
  }

  const hasOneLanguage = options[0]?.languageCodes?.length < 2

  if (options.length === 0 || !selectedOption) {
    return null
  }

  if (options.length === 1 && hasOneLanguage) {
    return (
      <div className="flex items-center gap-2">
        <Flag countryCode={selectedOption.countryCode} rounded />
        <div>|</div>
        <span className="text-base font-bold">{selectedOption.languageCode.toUpperCase()}</span>
      </div>
    )
  }

  return (
    <Box>
      <Backdrop isOpen={isOpen} />
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        placement="bottom-end"
        returnFocusOnClose={false}>
        <PopoverTrigger>
          <Button px={4} py={2} variant="ghost">
            {selectedOption ? (
              <HStack divider={<Box borderColor="primary" h={4} w="1px" />} spacing={2}>
                <Flag countryCode={selectedOption.countryCode} rounded />
                <Text fontSize="16px" fontWeight="bold">
                  {selectedOption.languageCode.toUpperCase()}
                </Text>
              </HStack>
            ) : (
              <LargeHeading>{t('countryLanguageSelector.selectLanguage')}</LargeHeading>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent
          bg="white"
          borderColor="neutral.30"
          boxShadow="none"
          maxH="650px"
          p={6}
          width="400px">
          <PopoverArrow bg="white" />

          <VStack alignItems="flex-start" spacing={4}>
            <HStack w="100%">
              <Header />
              <IconButton
                aria-label="Close"
                icon={<IconByName boxSize={6} name="IconClose" />}
                onClick={onClose}
              />
            </HStack>

            {allowSearch && <Search onChange={setSearchTerm} />}

            {selectedOption && (
              <VStack alignItems="flex-start" spacing={2} w="100%">
                <Subheading>{t('countryLanguageSelector.yourSelection')}</Subheading>

                <OptionsList
                  onOptionSelect={handleOptionSelect}
                  options={options}
                  selectedOption={selectedOption}
                />
              </VStack>
            )}
          </VStack>

          {allowSearch && selectedOption && (
            <>
              <Box py={2}>
                <Subheading>{t('countryLanguageSelector.otherCountriesAndLanguages')}</Subheading>
              </Box>

              <OptionsList
                onOptionSelect={handleOptionSelect}
                options={options}
                selectedOption={selectedOption}
              />
            </>
          )}
        </PopoverContent>
      </Popover>
    </Box>
  )
}
