import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconCamera = createIcon({
  displayName: 'IconCamera',
  path: [
    <path
      d="M2 5V20H22V9H20V18H4V7H7.62L8.62 5H15.38L16.38 7H22V5H17.62L16.62 3H7.38L6.38 5H2Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M14 12C14 11.82 13.97 11.65 13.93 11.49L13.92 11.48L15.43 9.97001C15.78 10.57 16 11.25 16 11.99C16 14.2 14.21 15.99 12 15.99C9.79 15.99 8 14.2 8 11.99C8 9.78001 9.79 7.99001 12 7.99001C12.74 7.99001 13.43 8.21001 14.02 8.56001L12.51 10.07C12.35 10.03 12.18 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
