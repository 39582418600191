export type Country = {
  code: string
  defaultLanguage: string
  isDefault: boolean
  languageCodes: string[]
  name: string
}

export const getCountries = (): Country[] => countries

const countries = [
  {
    code: 'US',
    defaultLanguage: 'en',
    isDefault: true,
    languageCodes: ['en'],
    name: 'United States',
  },
  {
    code: 'SE',
    defaultLanguage: 'en',
    isDefault: false,
    languageCodes: ['en'],
    name: 'Sweden',
  },
]
