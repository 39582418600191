import React, { FC } from 'react'

import { Outlet } from 'react-router'

import { Footer } from '#shared/components/Footer/Footer.js'
import { Header } from '#shared/components/Header/Header.js'
import { ErrorBoundary } from '#shared/pages/Error/ErrorBoundary.js'

export const PublicLayout: FC = () => {
  return (
    <>
      <Header />
      <ErrorBoundary>
        <div className="flex grow flex-col">
          <Outlet />
        </div>
      </ErrorBoundary>
      <Footer />
    </>
  )
}
