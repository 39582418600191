import { useQueryClient } from '@tanstack/react-query'

import { FetchCustomersResponse } from '@brenntag/api-customer/fetch-customers'

export interface GetCustomers {
  params?: {
    page?: string
    pageSize?: string
  }
}

export const useGetCustomers = ({
  params = {},
}: GetCustomers): FetchCustomersResponse | undefined => {
  const client = useQueryClient()

  return client.getQueryData<FetchCustomersResponse>([
    'FetchCustomers',
    params?.pageSize,
    params?.page,
  ])
}
