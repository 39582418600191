import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFilePDF = createIcon({
  displayName: 'IconFilePDF',
  path: [
    <path
      clipRule="evenodd"
      d="M4 22H20V6.59L15.41 2H13V9H18V20H6V4H11V2H4V22ZM17.59 7H15V4.41L17.59 7Z"
      fill="currentColor"
      fillRule="evenodd"
      key="0"
    />,
    <path
      clipRule="evenodd"
      d="M10.3411 14.3502C10.3411 13.6602 9.94109 13.0002 8.96109 13.0002L8.95109 12.9902H7.62109V16.9902H8.42109V15.7102H8.96109C9.95109 15.7102 10.3411 15.0402 10.3411 14.3502ZM9.54109 14.3502C9.54109 14.7902 9.37109 15.0702 8.94109 15.0702H8.43109V13.6402H8.94109C9.36109 13.6402 9.54109 13.9102 9.54109 14.3502Z"
      fill="currentColor"
      fillRule="evenodd"
      key="1"
    />,
    <path
      clipRule="evenodd"
      d="M12.0916 13C13.2016 13 13.8216 13.78 13.8216 14.99C13.8216 16.2 13.2016 17 12.0916 17H10.8516V13H12.0916ZM12.0916 16.34C12.6816 16.34 13.0116 15.83 13.0116 14.99C13.0116 14.15 12.6816 13.66 12.0916 13.66H11.6516V16.34H12.0916Z"
      fill="currentColor"
      fillRule="evenodd"
      key="2"
    />,
    <path
      d="M15.2102 13.66H16.6402V13H14.4102V17H15.2102V15.44H16.5402V14.78H15.2102V13.66Z"
      fill="currentColor"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
