import React, { FC } from 'react'

import { ProductList } from '@brenntag/connect-apis/api/models/product'

import { ResultItem, ResultItemTitle } from '#shared/components/GlobalSearch/ResultItem.js'
import { ItemSearchResult } from '#shared/components/GlobalSearch/SearchResults/ItemSearchResult.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface ProductSearchResultProps {
  onItemClick: VoidFunction
  onViewMoreClick: VoidFunction
  productList: ProductList
  search: string
}

export const ProductSearchResult: FC<ProductSearchResultProps> = ({
  onItemClick,
  onViewMoreClick,
  productList: { products, total },
  search,
}) => {
  const t = useTranslations()

  return (
    <ItemSearchResult
      items={products}
      linkToDetails
      onItemClick={onItemClick}
      onViewMoreClick={onViewMoreClick}
      to="/:locale/connect/products"
      total={total}
      type="products">
      {product => (
        <ResultItem
          fields={{
            items: [
              {
                key: 'product-number',
                label: t('label.productnumber'),
                value: product.number,
              },
              {
                key: 'customer-product-number',
                label: t('label.customerproductnumber'),
                value: product.customerProductNumber,
              },
              {
                key: 'cas-number',
                label: t('label.casnumber'),
                value: product.casNumbers?.reduce(
                  (result, casNumber, index, array) =>
                    `${result}${casNumber}${index < array.length - 1 ? ' |' : ''}`,
                  '',
                ),
              },
            ],
            search,
          }}
          title={<ResultItemTitle search={search} value={product.name} />}
        />
      )}
    </ItemSearchResult>
  )
}
