import { http, HttpResponse } from 'msw'

import { config } from '#config'
import { Calculation } from '#requests/calculations/types.js'

import { calculations } from './data'

const createCalculation = (): Calculation => {
  const randomItem = calculations[Math.floor(Math.random() * calculations.length)]
  return {
    ...randomItem,
    id: Math.random().toString(36).substring(7),
  }
}

export default () => {
  const { getBaseUrl } = config
  return [
    http.post(`${getBaseUrl()}/calculations`, async () => {
      const randomItem = createCalculation()
      calculations.push(randomItem)
      return HttpResponse.json({ data: randomItem })
    }),
  ]
}
