import * as React from 'react'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, VariantProps } from 'class-variance-authority'

import { Icon } from '#components/Icon.js'
import { cn } from '#utils/cn.js'

const formCheckboxVariants = cva(
  'peer size-6 min-h-6 min-w-6 outline outline-2 outline-offset-[-2px] outline-neutral-70 hover:outline-neutral-80 focus-visible:border-transparent focus-visible:bg-neutral-30 focus-visible:text-primary focus-visible:outline-offset-0 focus-visible:outline-white focus-visible:ring-4 focus-visible:ring-primary',
  {
    compoundVariants: [
      {
        className:
          'bg-gradient-to-r from-red-10 to-red-10 text-red-50 outline-2 outline-red-50 focus-within:outline-red-50 focus-visible:text-red-50 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary focus-visible:ring-4 focus-visible:ring-white',
        hasError: true,
        isChecked: true,
      },
    ],
    defaultVariants: {
      disabled: false,
      hasError: false,
      isChecked: false,
    },
    variants: {
      disabled: {
        false: '',
        true: 'disabled:cursor-not-allowed disabled:opacity-50',
      },
      hasError: {
        false: '',
        true: 'bg-gradient-to-r from-red-10 to-red-10 text-red-50 outline-2 outline-red-50 focus-within:outline-red-50 focus-visible:text-white focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary focus-visible:ring-4 focus-visible:ring-white',
      },
      isChecked: {
        false: '',
        true: 'bg-gradient-to-r from-violet-50 to-blue-50 text-white outline-0 hover:bg-blue-50 focus-visible:text-white focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary focus-visible:ring-4 focus-visible:ring-white',
      },
    },
  },
)

export interface FormCheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof formCheckboxVariants> {
  disabled?: boolean
  hasError?: boolean
}

const FormCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  FormCheckboxProps
>(({ className, disabled, hasError, ...props }, ref) => {
  const isChecked = props.checked === true
  return (
    <CheckboxPrimitive.Root
      className={cn(formCheckboxVariants({ disabled, hasError, isChecked }), className)}
      disabled={disabled}
      ref={ref}
      {...props}>
      <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
        <Icon name="Checkmark" size="full" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
})

FormCheckbox.displayName = CheckboxPrimitive.Root.displayName

export { FormCheckbox, formCheckboxVariants }
