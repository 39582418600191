import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypePricebook,
  DataTypePricebookProduct,
  DataTypePricePoint,
  DataTypeProduct,
  PricebookProductAttributes,
  PricebookProductResource,
} from '@brenntag/connect-apis/api/types'

import { staticTestData } from '../../data/static'
interface Props {
  attributes?: Partial<PricebookProductAttributes>
  id?: string
  pricebookId: string
  pricePointIds: string[]
  productId: string
}

export const buildPricebookProduct = ({
  attributes,
  id = faker.string.uuid(),
  pricebookId,
  pricePointIds,
  productId,
}: Props): PricebookProductResource => ({
  attributes: merge(
    {
      createdAt: faker.date.past().toISOString(),
      customerProductNumber: faker.number.int(100000).toString(),
      isActive: true,
      isOrderable: Boolean(pricePointIds.length),
      isSdsAvailable: faker.datatype.boolean(),
      packagingSize: {
        quantity: faker.number.int(100),
        uom: faker.helpers.arrayElement(staticTestData.uom),
      },
      updatedAt: faker.date.past().toISOString(),
    } satisfies PricebookProductAttributes,
    attributes,
  ),
  id,
  relationships: {
    pricebook: {
      data: {
        id: pricebookId,
        type: DataTypePricebook.Pricebook,
      },
    },
    pricePoints: {
      data: pricePointIds.map(id => ({
        id,
        type: DataTypePricePoint.PricePoint,
      })),
    },
    product: {
      data: {
        id: productId,
        type: DataTypeProduct.Product,
      },
    },
  },
  type: DataTypePricebookProduct.PricebookProduct,
})
