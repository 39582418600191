import { Calculation } from '#requests/calculations/types.js'

export const calculations: Calculation[] = [
  {
    address: {
      country: 'United States',
      label: '123 Main St, San Francisco, CA 94103, USA',
      latitude: 37.7749,
      longitude: -122.4194,
      municipality: 'San Francisco',
      neighbourhood: 'SOMA',
      placeId: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
      postcode: '94103',
      region: 'California',
      subregion: 'Bay Area',
    },
    auth0User: {
      email: 'john.doe@example.com',
      id: '1234567890',
      name: 'John Doe',
    },
    creationDate: '2022-01-01',
    id: 'abc123',
    isPaid: true,
    paidDate: '2022-01-02',
    productCas: '123-45-6',
    productName: 'Example Product',
    request: {
      packed: true,
      placeId: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
      productId: 'xyz789',
      quantity: 10,
    },
    response: {
      manufacturingPerKg: {
        bioCarbonContent: 0.5,
        biogenicEmissions: 0.2,
        biogenicRemoval: 0.3,
        fossil: 0.8,
        fossilCarbonContent: 0.7,
        globalWarmingPotential: 1.5,
        landUse: 0.4,
      },
      matchAccuracy: {
        geographicLevel: 'City',
        matchedDocuments: 5,
        product: 'Example Product',
      },
      packagingPerKg: 0.1,
      supplyChainInboundPerKg: 0.2,
      supplyChainOutboundPerKg: 0.3,
      total: {
        biogenicContent: 0.6,
        biogenicFossil: 0.9,
        gwp100Biogenic: 1.2,
        gwp100ExcludingBiogenic: 1.8,
        gwp100IncludingBiogenic: 2.0,
      },
      totalPerKg: {
        biogenicContent: 0.6,
        biogenicFossil: 0.9,
        gwp100Biogenic: 1.2,
        gwp100ExcludingBiogenic: 1.8,
        gwp100IncludingBiogenic: 2.0,
      },
      warehousingPerKg: 0.4,
    },
    sequenceNumber: 1,
    version: '1.0.0',
  },

  {
    address: {
      country: 'Canada',
      label: '123 Main St, San Francisco, CA 94103, USA',
      latitude: 43.65107,
      longitude: -79.347015,
      municipality: 'Toronto',
      neighbourhood: 'Downtown',
      placeId: 'ChIJpTvG15DL1IkRd8S0KlBVNTI',
      postcode: 'M5V 2T6',
      region: 'Ontario',
      subregion: 'Greater Toronto Area',
    },
    auth0User: { email: 'jane.doe@example.com', id: '0987654321', name: 'Jane Doe' },
    creationDate: '2022-02-01',
    id: 'def456',
    isPaid: false,
    paidDate: '2023-12-09',
    productCas: '789-10-2',
    productName: 'Another Product',
    request: {
      packed: false,
      placeId: 'ChIJpTvG15DL1IkRd8S0KlBVNTI',
      productId: 'abc123',
      quantity: 5,
    },
    response: {
      manufacturingPerKg: {
        bioCarbonContent: 0.3,
        biogenicEmissions: 0.1,
        biogenicRemoval: 0.2,
        fossil: 0.6,
        fossilCarbonContent: 0.5,
        globalWarmingPotential: 1.0,
        landUse: 0.2,
      },
      matchAccuracy: { geographicLevel: 'City', matchedDocuments: 3, product: 'Another Product' },
      packagingPerKg: 0.05,
      supplyChainInboundPerKg: 0.1,
      supplyChainOutboundPerKg: 0.15,
      total: {
        biogenicContent: 0.4,
        biogenicFossil: 0.7,
        gwp100Biogenic: 0.9,
        gwp100ExcludingBiogenic: 1.3,
        gwp100IncludingBiogenic: 1.5,
      },
      totalPerKg: {
        biogenicContent: 0.4,
        biogenicFossil: 0.7,
        gwp100Biogenic: 0.9,
        gwp100ExcludingBiogenic: 1.3,
        gwp100IncludingBiogenic: 1.5,
      },
      warehousingPerKg: 0.3,
    },
    sequenceNumber: 2,
    version: '1.0.0',
  },

  {
    address: {
      country: 'Canada',
      label: '123 Main St, San Francisco, CA 94103, USA',
      latitude: 43.65107,
      longitude: -79.347015,
      municipality: 'Toronto',
      neighbourhood: 'Downtown',
      placeId: 'ChIJpTvG15DL1IkRd8S0KlBVNTI',
      postcode: 'M5V 2T6',
      region: 'Ontario',
      subregion: 'Greater Toronto Area',
    },
    auth0User: { email: 'jane.doe@example.com', id: '0987654321', name: 'Jane Doe' },
    creationDate: '2022-02-01',
    id: 'ghi789',
    isPaid: false,
    paidDate: '2022-02-01',
    productCas: '789-10-2',
    productName: 'Another Product',
    request: {
      packed: false,
      placeId: 'ChIJpTvG15DL1IkRd8S0KlBVNTI',
      productId: 'abc123',
      quantity: 5,
    },
    response: {
      manufacturingPerKg: {
        bioCarbonContent: 0.3,
        biogenicEmissions: 0.1,
        biogenicRemoval: 0.2,
        fossil: 0.6,
        fossilCarbonContent: 0.5,
        globalWarmingPotential: 1.0,
        landUse: 0.2,
      },
      matchAccuracy: { geographicLevel: 'City', matchedDocuments: 3, product: 'Another Product' },
      packagingPerKg: 0.05,
      supplyChainInboundPerKg: 0.1,
      supplyChainOutboundPerKg: 0.15,
      total: {
        biogenicContent: 0.4,
        biogenicFossil: 0.7,
        gwp100Biogenic: 0.9,
        gwp100ExcludingBiogenic: 1.3,
        gwp100IncludingBiogenic: 1.5,
      },
      totalPerKg: {
        biogenicContent: 0.4,
        biogenicFossil: 0.7,
        gwp100Biogenic: 0.9,
        gwp100ExcludingBiogenic: 1.3,
        gwp100IncludingBiogenic: 1.5,
      },
      warehousingPerKg: 0.3,
    },
    sequenceNumber: 2,
    version: '1.0.0',
  },
]
