import { useAuth0, User } from '@auth0/auth0-react'

import { parseLocale } from '@brenntag/locale-utils/parseLocale'

import { useSalesforceSession } from './salesforce/useSession'

type Auth0User = User & {
  brenntag?: {
    app?: {
      countries?: string[]
      roles?: 'CO2'[]
      status?: string
    }
  }
}

type Session = {
  isAuthenticated: boolean
  isAuthenticating: boolean
  logout: () => Promise<void>
  user?: Auth0User
}

/**
 * Returns the current session of the logged in user. Throws an error if the user is not logged in.
 * @returns {Session}
 */
export const useSession = (): Session => {
  const salesforce = useSalesforceSession()
  const auth0 = useAuth0<Auth0User>()

  const logout = async () => {
    if (auth0?.user) {
      const url = new URL(window.location.href)
      const { countryCode, languageCode } = parseLocale(url.pathname)
      const locale = languageCode ? `${languageCode}-${countryCode}` : undefined
      let returnTo = `${window.location.origin}/${locale ?? 'en-US'}`
      if (salesforce.isAuthenticated) {
        returnTo += '/connect/logout/salesforce'
      }

      await auth0.logout({
        logoutParams: { returnTo },
      })
    } else if (salesforce.isAuthenticated) {
      const { isInternal } = salesforce
      salesforce.endSession()

      if (isInternal) {
        window.location.href = '/connect/internal'
      } else {
        window.location.href = '/'
      }
      return
    }
  }

  return {
    isAuthenticated: auth0?.isAuthenticated || salesforce.isAuthenticated,
    isAuthenticating: auth0?.isLoading,
    logout,
    user: auth0?.user,
  }
}
