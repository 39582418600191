import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconBarcode = createIcon({
  displayName: 'IconBarcode',
  path: [
    <path d="M4 5H2V19H4V5Z" fill="currentColor" key="0" />,
    <path d="M7 5H6V19H7V5Z" fill="currentColor" key="1" />,
    <path d="M20 5H22V19H20V5Z" fill="currentColor" key="2" />,
    <path d="M15 5H9V19H15V5Z" fill="currentColor" key="3" />,
    <path d="M17 5H18V19H17V5Z" fill="currentColor" key="4" />,
  ],
  viewBox: '0 0 24 24',
})
