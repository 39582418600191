import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChemistry = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M2 21h20v-4.41l-7-7V2h-2v8.41l7 7V19H4v-1.59l7-7V2H9v7.59l-7 7z" fill="currentColor" />
    <path d="m14.758 15 2 2h-9.52l2-2z" fill="currentColor" />
  </svg>
)
export default SvgChemistry
