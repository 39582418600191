import { SampleStatus, SingleIncludedSampleResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildSample } from '@brenntag/legacy-mock-generators/generators/samples/buildSample.js'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

import { products } from '../../products/data'

export const requested: SingleIncludedSampleResourceUnion = buildSample({
  attributes: {
    status: SampleStatus.Requested,
  },
  id: 'requested',
  included: {
    product: products.items.multipleDocuments,
    shipTo: buildLocation({ id: locations.showPrices.id }),
  },
})
