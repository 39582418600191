import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBriefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M7 9h15V7h-5V3H7v4H2v14h20V11h-2v8H4V9zm2-4h6v2H9z" fill="currentColor" />
  </svg>
)
export default SvgBriefcase
