import React, { FC } from 'react'

import { Box } from '@chakra-ui/react'

import './css/flag-icons.min.css'

interface Props {
  countryCode: string
  rounded?: boolean
  size?: number
}

export const Flag: FC<Props> = ({ countryCode, rounded = false, size = 6 }) => {
  return (
    <Box
      borderRadius={rounded ? '50%' : '0'}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={`fi fi-${countryCode.toLowerCase()}`}
      h={size}
      sx={{
        backgroundSize: 'cover',
      }}
      w={size}
    />
  )
}
