import { defineFeatures } from '#lib/Acl/defineFeatures.js'

export const features = defineFeatures({
  creditLimit: ({ featureFlags }) => featureFlags.enableCreditLimit,
  devTools: ({ devTools, environment, isOptimisedBuild }) => {
    return Boolean(!isOptimisedBuild || devTools) && environment !== 'production'
  },
  invoicesV2: ({ featureFlags }) => featureFlags.enableInvoicesV2,
  onlinePayment: ({ featureFlags }) => featureFlags.enableOnlinePayment,
  pimSpecifications: ({ featureFlags }) => featureFlags.enablePimSpecifications,
  services: ({ featureFlags }) => featureFlags.enableServices,
})
