import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconMap = createIcon({
  displayName: 'IconMap',
  path: [
    <path d="M10 9H8V17H10V9Z" fill="currentColor" key="0" />,
    <path d="M14 7H16V15H14V7Z" fill="currentColor" key="1" />,
    <path
      clipRule="evenodd"
      d="M22 22.6199L14.92 19.0799L8.92 21.0799L2 17.6199V1.37988L9.08 4.91988L15.08 2.91988L22 6.37988V22.6199ZM15.08 16.9199L20 19.3799V7.61988L14.92 5.07988L8.92 7.07988L4 4.61988V16.3799L9.08 18.9199L15.08 16.9199Z"
      fill="currentColor"
      fillRule="evenodd"
      key="2"
    />,
  ],
  viewBox: '0 0 24 24',
})
