import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconTimeWithGradient = createIcon({
  displayName: 'IconTime',
  path: [
    <path
      d="M18.31 7.11L19.74 5.68C21.15 7.4 22 9.6 22 12C22 17.51 17.51 22 12 22C6.49 22 2 17.51 2 12C2 6.49 6.49 2 12.01 2C14.4 2 16.61 2.85 18.33 4.26L16.9 5.69C15.54 4.63 13.85 4 12 4C7.59 4 4 7.6 4 12.01C4 16.42 7.59 20.01 12 20.01C16.41 20.01 20 16.42 20 12.01C20 10.16 19.36 8.47 18.31 7.11Z"
      fill="url(#paint0_linear_1092_5215)"
      key="0"
    />,
    <path
      d="M11 12.41L14.29 15.71L15.71 14.29L13 11.59V6H11V12.41Z"
      fill="url(#paint0_linear_1092_5215)"
      key="1"
    />,
    <defs key={2}>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1092_5215"
        x1="7"
        x2="42.99"
        y1="48"
        y2="12.01">
        <stop stopColor="#C815E0" />
        <stop offset="1" stopColor="#0C72ED" />
      </linearGradient>
    </defs>,
  ],
  viewBox: '0 0 24 24',
})
