import React, { FC } from 'react'

import { ColorProps } from '@chakra-ui/react'

import { IconNames as UIIconNames } from '@brenntag/ui/components/Icon'

import { Pill } from '#shared/components/Pill/Pill.js'
import { IconNames } from '#shared/icons/brand/index.js'

export interface StatusStyle {
  color: ColorProps['color'] & string
  icon: IconNames
  iconName?: UIIconNames
  statusColor?: string
  text: string
}

export const StatusPill: FC<{ status: StatusStyle }> = ({ status }) => {
  return (
    <Pill
      alignSelf="flex-start"
      bgColor={status.color}
      border="none"
      color="white"
      data-testid="card-item-status"
      fontWeight="700"
      icon={status.icon}
      iconAlign="left"
      iconSize="16px"
      m={0}
      variant="chip-solid">
      {status.text}
    </Pill>
  )
}
