import { theme as chakraTheme, ComponentStyleConfig } from '@chakra-ui/react'

const { baseStyle } = chakraTheme.components.Tooltip

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    bg: 'neutral.90',
    borderRadius: 'none',
    boxShadow: 'none',
    color: 'white',
  },
  defaultProps: {
    variant: 'solid',
  },
  variants: {
    solid: () => ({
      ...baseStyle,
      bg: 'neutral.90',
      color: 'white',
    }),
  },
}
