import { useMemo } from 'react'

import { useSession } from '#lib/auth/useSession.js'
import { useGetSalesGroups } from '#modules/customer/hooks/sales-groups/useGetSalesGroups.js'
import { useLoggedInUser } from '#shared/hooks/globalState/useLoggedInUser.js'
import { useSelectedCustomer } from '#shared/hooks/globalState/useSelectedCustomer.js'
import { useSelectedShipToLocation } from '#shared/hooks/globalState/useSelectedShipToLocation.js'

/**
 * Gathers the context for Sentry
 */
export const useSentryContext = () => {
  const { selectedCustomer } = useSelectedCustomer()
  const { user } = useSession()
  const loggedInUser = useLoggedInUser()
  const salesGroups = useGetSalesGroups()
  const selectedSalesGroup = useMemo(
    () => salesGroups?.data?.find(sg => sg.id === selectedCustomer?.salesGroupId),
    [salesGroups?.data, selectedCustomer],
  )
  const { shipToLocation } = useSelectedShipToLocation()

  const sentryContext = useMemo(() => {
    return {
      loggedInUserId: loggedInUser?.id,
      selectedCustomerId: selectedCustomer?.id,
      selectedSalesGroupId: selectedSalesGroup?.id,
      selectedShipToLocation: shipToLocation?.id,
      userSub: user?.sub,
    }
  }, [loggedInUser, selectedCustomer, selectedSalesGroup, shipToLocation, user])

  return sentryContext
}
