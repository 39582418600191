import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFinance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m3 7.45 9-5.63 9 5.63V10h-8V8h5.11L12 4.18 5.89 8H11v2H3zM19 12h-2v6h2zM21 22v-2H3v2zM11 18v-6h2v6zM7 12H5v6h2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFinance
