import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconChemistry = createIcon({
  displayName: 'IconChemistry',
  path: [
    <path
      d="M2 21H22V16.59L15 9.59V2H13V10.41L20 17.41V19H4V17.41L11 10.41V2H9V9.59L2 16.59V21Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M14.7583 15L16.7583 17H7.23828L9.23828 15H14.7583Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
