import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconRepeat = createIcon({
  displayName: 'IconRepeat',
  path: [
    <path
      d="M16.2891 9.29004L17.7091 10.71L22.4091 6.00004L17.7091 1.29004L16.2891 2.71004L19.5891 6.00004L16.2891 9.29004Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M1.58984 18L6.28984 22.71L7.70984 21.29L4.40984 18L7.70984 14.71L6.28984 13.29L1.58984 18Z"
      fill="currentColor"
      key="1"
    />,
    <path
      d="M18.9983 13V17H8.23828L7.23828 18L8.23828 19H20.9983V13H18.9983Z"
      fill="currentColor"
      key="2"
    />,
    <path d="M15.76 5L16.76 6L15.76 7H5V11H3V5H15.76Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
