import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconSearch = createIcon({
  displayName: 'IconSearch',
  path: [
    <path
      d="M14.88 6.53001L16.31 5.10001C17.37 6.46001 18 8.15001 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10C2 5.59001 5.59 2.00001 9.99 1.99001C11.84 1.99001 13.53 2.63001 14.89 3.68001L13.46 5.11001C12.48 4.42001 11.28 4.00001 9.99 4.00001C6.68 4.00001 3.99 6.69001 3.99 10C3.99 13.31 6.68 16 9.99 16C13.3 16 15.99 13.31 15.99 10C15.99 8.71001 15.58 7.51001 14.88 6.53001Z"
      fill="currentColor"
      key="0"
    />,
    <path
      d="M16.33 17.73C16.84 17.31 17.32 16.84 17.74 16.32L21.71 20.29L20.3 21.7L16.33 17.73Z"
      fill="currentColor"
      key="1"
    />,
  ],
  viewBox: '0 0 24 24',
})
