import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { fetchWebstores, FetchWebstoresResponse } from '@brenntag/api-customer/fetch-webstores'

export const useFetchWebstores = (): UseQueryResult<FetchWebstoresResponse> => {
  return useQuery({
    // we never want to garbage collect this query
    gcTime: Infinity,
    queryFn: () => fetchWebstores(),
    queryKey: ['FetchWebstores'],
    // we never need to refetch this query
    staleTime: Infinity,
  })
}
