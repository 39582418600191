import { Country, getCountries } from './getCountries'

const countries = getCountries()
/**
 * Get a country by its ISO 3166-1 alpha-2 country code or any string representation that could be the country code
 * (useful when the country code is coming from a URL parameter or a query parameter which is always a string)
 * @param code string - ISO 3166-1 alpha-2 country code or a string representation of the country code
 */
export const getCountryByCode = (code?: string): Country | undefined => {
  return countries.find(country => country.code === code?.toUpperCase())
}
