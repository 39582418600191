import React from 'react'

import { Slot } from '@radix-ui/react-slot'

import { cn } from '#utils/cn.js'

export interface LinkProps extends React.LinkHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  isActive?: boolean
}

const AppHeaderNavigationItem = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children = 'AppHeaderNavigationItem', className, isActive, ...props }, ref) => {
    return (
      <Slot
        className={cn(
          'px-4 py-2 font-semibold lg:font-normal',
          'lg:relative lg:px-0 lg:py-4 text-white lg:after:absolute lg:after:inset-x-0 lg:after:bottom-0 lg:after:h-1 lg:after:bg-gradient-to-r lg:hover:from-neutral-20 lg:hover:to-neutral-20',
          isActive &&
            'bg-neutral-70 lg:bg-transparent lg:from-violet-50 lg:to-blue-50 lg:font-bold lg:hover:from-violet-50 lg:hover:to-blue-50',
          className,
        )}
        ref={ref}
        {...props}>
        {children}
      </Slot>
    )
  },
)
AppHeaderNavigationItem.displayName = 'AppHeaderNavigationItem'

export { AppHeaderNavigationItem }
