import { http, HttpResponse } from 'msw'

import { VasResponse } from '@brenntag/connect-apis/api-object/types'
import { buildService } from '@brenntag/legacy-mock-generators/generators/services/buildService.js'

import { CreateValueAddedServiceParams } from '#shared/apis/api-vas/endpoints/createValueAddedService.js'
import { config } from '#src/config.js'

import { valueAddedServices } from './data'

export const postService = () => {
  return http.post<never, CreateValueAddedServiceParams, VasResponse>(
    `${config.apiGatewayUrl}/vas-service`,
    async ({ request }) => {
      const body = await request.json()
      const item = buildService({ overrides: body })

      valueAddedServices.items[item.id] = item

      return HttpResponse.json(item)
    },
  )
}
