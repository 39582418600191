import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M22 21H2V3h8.41l2 2H22v2H11.59l-2-2H4v14h16V9h2z" fill="currentColor" />
  </svg>
)
export default SvgFolder
