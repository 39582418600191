import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconDelete = createIcon({
  displayName: 'IconDelete',
  path: [
    <path d="M11 2H13V4H21V6H3V4H11V2Z" fill="currentColor" key="0" />,
    <path d="M19 22H5V8H7V20H17V8H19V22Z" fill="currentColor" key="1" />,
    <path d="M13 8H15V18H13V8Z" fill="currentColor" key="2" />,
    <path d="M11 8H9V18H11V8Z" fill="currentColor" key="3" />,
  ],
  viewBox: '0 0 24 24',
})
