import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArchive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M13 5V3h9v6H2V3h9v2H4v2h16V5zM18 20H6v-9H4v11h16V11h-2z" fill="currentColor" />
    <path d="M15 12H9v2h6z" fill="currentColor" />
  </svg>
)
export default SvgArchive
