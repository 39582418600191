import { http, HttpResponse } from 'msw'

import { ProfileResponse } from '@brenntag/connect-apis/api-user/types'

import { config } from '#src/config.js'

import { profiles } from './data/profiles'

export const fetchProfiles = () => {
  return http.get<never, null, ProfileResponse>(
    `${config.apiBaseUrl}/api-user/profiles`,
    async () => {
      return HttpResponse.json({
        data: profiles,
      })
    },
  )
}
