import { SingleIncludedProductResourceWithPricebookProductUnion } from '@brenntag/connect-apis/api/types'
import { buildProduct } from '@brenntag/legacy-mock-generators/generators/products/buildProduct.js'

import { pricebooks } from '#src/mocks/apis/data/pricebooks.js'
const id = 'noPricePoint'

export const noPricePoint: SingleIncludedProductResourceWithPricebookProductUnion = buildProduct({
  attributes: {
    name: 'No price',
  },
  id,
  included: {
    pricebook: pricebooks.allLocations,
    pricebookProduct: null,
    pricePoints: null,
  },
})
