import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconLogout = createIcon({
  displayName: 'IconLogout',
  path: [
    <path d="M10 21H3V3H10V5H5V19H10V21Z" fill="currentColor" key="0" />,
    <path
      d="M15.7091 18.71L14.2891 17.29L19.5891 12L14.2891 6.71004L15.7091 5.29004L22.4091 12L15.7091 18.71Z"
      fill="currentColor"
      key="1"
    />,
    <path d="M15.76 11L16.76 12L15.76 13H7V11H15.76Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
