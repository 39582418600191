import { SingleIncludedQuoteResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildLocation } from '@brenntag/legacy-mock-generators/generators/buildLocation.js'
import { buildQuote } from '@brenntag/legacy-mock-generators/generators/quotes/buildQuote.js'
import { buildQuoteLine } from '@brenntag/legacy-mock-generators/generators/quotes/buildQuoteLine.js'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

import { products } from '../../products/data'

const id = 'quoteForMultipleProducts'

export const quoteForMultipleProducts: SingleIncludedQuoteResourceUnion = buildQuote({
  id,
  included: {
    quoteLines: [
      [
        buildQuoteLine({
          included: { product: products.items.manyPricePoints.data },
          quoteId: id,
        }),
        products.items.manyPricePoints,
      ],

      [
        buildQuoteLine({
          included: { product: products.items.multipleDocuments.data },
          quoteId: id,
        }),
        products.items.multipleDocuments,
      ],

      [
        buildQuoteLine({
          included: { product: products.items.multipleTankLocations.data },
          quoteId: id,
        }),
        products.items.multipleTankLocations,
      ],
    ],
    shipTo: buildLocation({
      attributes: { name: locations.showPrices.name },
      id: locations.showPrices.id,
    }),
  },
})
