import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M20 22H4V2h7v2H6v16h12V9h-5V2h2.41L20 6.59zM15 7h2.59L15 4.41z" fill="currentColor" />
  </svg>
)
export default SvgFile
