import * as React from 'react'
import type { SVGProps } from 'react'
const SvgZoomIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m16.31 5.1-1.43 1.43c.7.98 1.11 2.18 1.11 3.47 0 3.31-2.69 6-6 6s-6-2.69-6-6 2.69-6 6-6c1.29 0 2.49.42 3.47 1.11l1.43-1.43a8 8 0 0 0-4.9-1.69C5.59 2 2 5.59 2 10s3.59 8 8 8 8-3.59 8-8c0-1.85-.63-3.54-1.69-4.9M17.738 16.32c-.42.52-.9.99-1.41 1.41l3.97 3.97 1.41-1.41z"
      fill="currentColor"
    />
    <path d="M11 9h3v2h-3v3H9v-3H6V9h3V6h2z" fill="currentColor" />
  </svg>
)
export default SvgZoomIn
