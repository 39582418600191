import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeLocation,
  DataTypePricebook,
  DataTypePricebookProduct,
  DataTypeSalesGroup,
  LocationKey,
  PricebookAttributes,
  PricebookProductKey,
  PricebookResource,
  PricebookType,
} from '@brenntag/connect-apis/api/types'

interface Props {
  attributes?: Partial<PricebookAttributes>
  id?: string
  relationships?: {
    locationIds?: string[]
    pricebookProductIds?: string[]
    salesGroupId?: string
  }
}

export const buildPricebook = ({
  attributes,
  id = faker.string.uuid(),
  relationships,
}: Props = {}): PricebookResource => {
  return {
    attributes: merge(
      {
        createdAt: faker.date.past().toISOString(),
        isActive: true,
        name: faker.lorem.word(),
        type: faker.helpers.arrayElement([
          PricebookType.Customer,
          PricebookType.Regional,
          PricebookType.ShipTo,
        ]),
        updatedAt: faker.date.past().toISOString(),
      } satisfies PricebookAttributes,
      attributes,
    ),
    id,
    relationships: {
      locations: relationships?.locationIds
        ? {
            data: relationships.locationIds.map(
              (id): LocationKey => ({
                id,
                type: DataTypeLocation.Location,
              }),
            ),
          }
        : undefined,
      pricebookProducts: relationships?.pricebookProductIds
        ? {
            data: relationships.pricebookProductIds.map(
              (id): PricebookProductKey => ({
                id,
                type: DataTypePricebookProduct.PricebookProduct,
              }),
            ),
          }
        : undefined,
      salesGroup: relationships?.salesGroupId
        ? {
            data: {
              id: relationships.salesGroupId,
              type: DataTypeSalesGroup.SalesGroup,
            },
          }
        : undefined,
    },
    type: DataTypePricebook.Pricebook,
  }
}
