import { APILoggedInUser } from '@brenntag/connect-apis/api-user'
import { LoggedInUser } from '@brenntag/connect-apis/api-user/models/user'
import { MeResponse } from '@brenntag/connect-apis/api-user/types'

import { transformAPILoggedInUserToLoggedInUser } from '#shared/apis/api-user/me/transforms/user.transforms.js'
import { request } from '#shared/apis/request.js'
import { deserialize } from '#shared/utils/deserialize.js'
import { config } from '#src/config.js'

export const fetchLoggedInUser = async (): Promise<LoggedInUser> => {
  const response = await request<MeResponse>({
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
    url: `${config.apiBaseUrl}/api-user/me`,
  })
  return transformAPILoggedInUserToLoggedInUser(deserialize<APILoggedInUser>(response.body))
}
