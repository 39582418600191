import React, { type ComponentType, type FC } from 'react'

import {
  withAuthenticationRequired as withAuth0AuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react'

import { Loading } from './Loading'
import { useSalesforceSession } from './salesforce/useSession'
import { useSession } from './useSession'

/**
 * This function is a wrapper around the Auth0 withAuthenticationRequired function.
 * It will check if the user is already logged in to Salesforce. If so and isInternal is true, we will allow access regardless of the Auth0 session.
 * If the user is not logged in to Salesforce, we will follow the default Auth0 withAuthenticationRequired behavior.
 * @param Component
 * @returns The component with authentication required.
 */
export function withAuthenticationRequired(
  Component: ComponentType,
  options: WithAuthenticationRequiredOptions = {},
): FC {
  const ComponentWithAuthenticationRequired = () => {
    const salesforce = useSalesforceSession()
    const { isAuthenticated } = useSession()

    // Before we apply default Auth0 behavior (redirect when no session and return to the same page after login),
    // We will check if the user is already logged in to Salesforce. If so and isInternal is true, we will allow access,
    // because this means the user is an admin and has already logged in using Salesforce directly. It also means we can
    // assume that the user has no Auth0 account, so we should not redirect to the Auth0 login page.
    if (salesforce.isInternal) {
      return <Component />
    }

    // When the user has an Auth0 session established, it could be that the Salesforce session is still being established.
    // In this case we don't want the page to load yet. Future development could lead to Salesforce becoming non-blocking,
    // which means we can remove this check. For now, if salesforce needs to be authenticated,
    // it will be done directly after logging in with auth0 (see withAuthenticationRequired.tsx).
    if (isAuthenticated && salesforce.isAuthenticating) {
      return <Loading text="Initiating E-commerce profile..." />
    }

    // This is the default behavior for Auth0. It will redirect to the login page if no session is found.
    // The onRedirecting prop is used to show loading state while the user is being redirected to Auth0.
    const ComponentWithAuthRequired = withAuth0AuthenticationRequired(Component, {
      onRedirecting: () => <Loading text="Redirecting to the login page...." />,
      ...options,
    })

    return <ComponentWithAuthRequired />
  }

  return ComponentWithAuthenticationRequired
}
