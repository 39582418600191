import * as React from 'react'
import type { SVGProps } from 'react'
const SvgGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.74 5.68 18.31 7.1a7.94 7.94 0 0 1 1.62 3.9H16a19.2 19.2 0 0 0-1.22-6.5 8 8 0 0 1 2.12 1.19l1.42-1.43a10 10 0 1 0 1.42 1.42M12 20c-.48 0-1.81-2.4-2-7h4c-.19 4.6-1.52 7-2 7m2-9h-4c.17-4.6 1.5-7 2-7s1.81 2.4 2 7M9.24 4.5A19.2 19.2 0 0 0 8 11H4.07a8 8 0 0 1 5.17-6.5M4.07 13H8c.027 2.22.44 4.42 1.22 6.5A8 8 0 0 1 4.07 13m10.69 6.5A19.2 19.2 0 0 0 16 13h3.95a8 8 0 0 1-5.19 6.5"
      fill="currentColor"
    />
  </svg>
)
export default SvgGlobe
