import { useQuery } from '@tanstack/react-query'

import { LoggedInUser } from '@brenntag/connect-apis/api-user/models/user'

import { fetchLoggedInUser } from '#shared/apis/api-user/me/endpoints/fetchLoggedInUser.js'
import { QueryCacheKey } from '#shared/utils/enums.js'

interface Params {
  isEnabled?: boolean
}

export const useFetchLoggedInUser = ({ isEnabled = true }: Params = {}) => {
  const result = useQuery({
    enabled: isEnabled,
    meta: { toastMessageKey: 'toast.error.fetchMe' },
    queryFn: async (): Promise<LoggedInUser> => fetchLoggedInUser(),
    queryKey: [QueryCacheKey.LoggedInUser],
  })
  return { ...result, loggedInUser: result.data }
}
