import { uniqBy } from 'lodash'

import {
  ManyIncludedDocumentResourceProductResource,
  ManyMeta,
  SingleIncludedDocumentResourceProductResource,
} from '@brenntag/connect-apis/api/types'

interface Props {
  documents: SingleIncludedDocumentResourceProductResource[]
  meta?: Partial<ManyMeta>
}

export const buildDocuments = ({
  documents,
  meta,
}: Props): ManyIncludedDocumentResourceProductResource => {
  return {
    data: documents.map(document => document.data),
    included: uniqBy(
      [...documents.flatMap(document => document.included || [])].filter(Boolean),
      resource => resource.id,
    ),
    meta: { count: documents.length, ...meta },
  }
}
