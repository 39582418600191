import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPointer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 16 8"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Pointer_svg__a)">
      <path d="M16 0 8 8 0 0z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="Pointer_svg__a">
        <path d="M0 0h16v8H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPointer
