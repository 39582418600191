import { APICheckout } from '@brenntag/connect-apis/api-cart'

import { locations } from '#src/mocks/apis/customer-api/data/locations.js'

import { cartForHidePricesLocation } from './cartforHidePrices'
import { cartForShowPricesLocation } from './cartforShowPrices'

export const carts = {
  [locations.hidePrices.id]: cartForHidePricesLocation,
  [locations.showPrices.id]: cartForShowPricesLocation,
}

export const checkouts: Record<string, APICheckout> = {}
