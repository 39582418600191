import { useMemo } from 'react'

import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
// We explicitly import here directly from the SDK, afterwards this is illegal in eslint
// eslint-disable-next-line no-restricted-imports
import { useFlags as origUseFlags } from 'launchdarkly-react-client-sdk'

export type FeatureFlags = {
  enableCartV2: boolean
  enableCreditLimit: boolean
  enableInvoicesV2: boolean
  enableMaintenanceOverlay: boolean
  enableOnlinePayment: boolean
  enablePimSpecifications: boolean
  enableServices: boolean
}

const defaultFeatureFlags: Partial<FeatureFlags> = {}

const atom = atomWithStorage<Partial<FeatureFlags>>(
  'featureFlags',
  defaultFeatureFlags,
  undefined,
  { getOnInit: true },
)

export type SetFeatureFlag = <K extends keyof FeatureFlags>(key: K, value: FeatureFlags[K]) => void

export const useFeatureFlags = () => {
  const launchDarklyFlags = origUseFlags()
  const [localFeatureFlags, setLocalFeatureFlags] = useAtom(atom)
  const setLocalFeatureFlag = <K extends keyof FeatureFlags>(key: K, value: FeatureFlags[K]) => {
    setLocalFeatureFlags(prev => {
      const newFeatureFlags = { ...prev, [key]: value }
      return newFeatureFlags
    })
  }
  const isLocalFeatureFlag = (key: keyof FeatureFlags): boolean => {
    return key in localFeatureFlags
  }
  const clearLocalFeatureFlag = (key: keyof FeatureFlags) => {
    setLocalFeatureFlags(prev => {
      const newFeatureFlags = { ...prev }
      delete newFeatureFlags[key]
      return newFeatureFlags
    })
  }

  const featureFlags = useMemo((): FeatureFlags => {
    return {
      enableCartV2: Boolean(localFeatureFlags.enableCartV2 ?? launchDarklyFlags.enableCartV2),
      enableCreditLimit: Boolean(
        localFeatureFlags.enableCreditLimit ?? launchDarklyFlags.enableCreditLimit,
      ),
      enableInvoicesV2: Boolean(
        localFeatureFlags.enableInvoicesV2 ?? launchDarklyFlags.enableInvoicesV2,
      ),
      enableMaintenanceOverlay: Boolean(
        localFeatureFlags.enableMaintenanceOverlay ?? launchDarklyFlags.enableMaintenanceOverlay,
      ),
      enableOnlinePayment: Boolean(
        localFeatureFlags.enableOnlinePayment ?? launchDarklyFlags.enableOnlinePayment,
      ),
      enablePimSpecifications: Boolean(
        localFeatureFlags.enablePimSpecifications ?? launchDarklyFlags.enablePimSpecifications,
      ),
      enableServices: localFeatureFlags.enableServices ?? launchDarklyFlags.enableServices,
    }
  }, [localFeatureFlags, launchDarklyFlags])

  return { clearLocalFeatureFlag, featureFlags, isLocalFeatureFlag, setLocalFeatureFlag }
}
