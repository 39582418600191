import React, { FC } from 'react'

import { Box } from '@chakra-ui/react'

import { TranslationKey } from '@brenntag/connect-translations'

import { RouterLink } from '#shared/components/RouterLink/RouterLink.js'
import { StandardText } from '#shared/components/Typography/Typography.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

export interface ViewMoreLinkProps {
  onClick: () => void
  to: string
  total: number
  type: 'cases' | 'documents' | 'invoices' | 'orders' | 'products' | 'services'
}

export const ViewMoreLink: FC<ViewMoreLinkProps> = ({ total, type, ...props }) => {
  const t = useTranslations()
  return (
    <RouterLink {...props}>
      <Box _hover={{ bg: 'neutral.10', cursor: 'pointer' }} px={2} py={4}>
        <StandardText color="blue.50" fontWeight={600} textAlign="center">
          {t('show_more.results')
            .replace('@@TOTAL@@', total.toString())
            .replace('@@TYPE@@', t(`label.${type}` as TranslationKey).toLowerCase())}
        </StandardText>
      </Box>
    </RouterLink>
  )
}
