import React from 'react'

import { Flex } from '@chakra-ui/react'

import { StandardText } from '#shared/components/Typography/Typography.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

export const NoResultsMessage = () => {
  const t = useTranslations()
  return (
    <Flex alignItems="center" direction="column">
      <StandardText color="neutral.80" fontSize="md">
        {t('message.nomatchingresults')}
      </StandardText>
      <StandardText color="neutral.80" fontSize="sm">
        {t('message.nomatchingresults.subtitle')}
      </StandardText>
    </Flex>
  )
}
