import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconFlag = createIcon({
  displayName: 'IconFlag',
  path: [
    <path
      d="M5 22H3V3H22.87L18.2 10L22.87 17H7V15H19.13L15.8 10L19.13 5H5V22Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
