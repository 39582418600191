import { http, HttpResponse } from 'msw'

import { SingleIncludedQuoteResourceUnion } from '@brenntag/connect-apis/api/types'

import { config } from '#src/config.js'

import { quotes } from './data'

export const fetchQuote = () => {
  return http.get<{ id: string }, null, SingleIncludedQuoteResourceUnion>(
    `${config.apiBaseUrl}/api/quotes/:id`,
    async ({ params }) => {
      const id = params.id as keyof typeof quotes.items

      if (!quotes.items[id]) {
        return HttpResponse.json(null, { status: 404 })
      }

      const quote = quotes.items[id]

      return HttpResponse.json(quote)
    },
  )
}
