import qs, { ParsedQuery, ParseOptions } from 'query-string'
import { useLocation } from 'react-router'

export function useLocationSearch(
  options: ParseOptions & { parseBooleans: true; parseNumbers: true },
): ParsedQuery<boolean | number | string>
export function useLocationSearch(
  options: ParseOptions & { parseBooleans: true },
): ParsedQuery<boolean | string>
export function useLocationSearch(
  options: ParseOptions & { parseNumbers: true },
): ParsedQuery<number | string>
export function useLocationSearch(options?: ParseOptions): ParsedQuery
export function useLocationSearch(options?: qs.ParseOptions) {
  const { search } = useLocation()
  return qs.parse(search, options)
}
