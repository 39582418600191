import { FC, useEffect } from 'react'

import { LDContext } from 'launchdarkly-react-client-sdk'

import { useLaunchDarklyClient } from './useLaunchDarklyClient'
import { useLaunchDarklyContext } from './useLaunchDarklyContext'

type Props = {
  children: React.ReactNode
}

/**
 * Identifies the user in LaunchDarkly with the current context, this updates
 * whenever something in the context changes
 */
export const LaunchDarklyIdentifier: FC<Props> = ({ children }) => {
  const { isInitialized, ldClient } = useLaunchDarklyClient()
  const context = useLaunchDarklyContext()

  useEffect(() => {
    if (!ldClient || !isInitialized) return

    const validContexts = Object.entries(context)
      .filter(([_, value]) => value?.key) // Filter out empty contexts
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    const validContextsCount = Object.keys(validContexts).length

    if (!validContextsCount) return

    if (validContextsCount === 1) {
      // If there's only one valid context, return it directly (without kind: 'multi')
      ldClient.identify(Object.values(validContexts)[0] as LDContext)
    } else {
      ldClient.identify({ kind: 'multi', ...validContexts })
    }
  }, [ldClient, context, isInitialized])

  return <>{children}</>
}
