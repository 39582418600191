import { LinkedAttachment } from '@brenntag/connect-apis/api-attachment/types'
import { OrderStatus, SingleIncludedOrderResourceUnion } from '@brenntag/connect-apis/api/types'
import { buildOrder } from '@brenntag/legacy-mock-generators/generators/orders/buildOrder.js'
import { buildOrderLine } from '@brenntag/legacy-mock-generators/generators/orders/buildOrderLine.js'

import { manyPricePoints } from '#src/mocks/apis/api/products/data/manyPricePoints.js'

import { attachments, dummyBlob } from '../../../api-attachment/data'

const orderId = 'withSingleAttachment'
const orderWithSingleAttachmentAttachment: LinkedAttachment = {
  createdAt: '2021-09-01T00:00:00Z',
  id: 'orderWithSingleAttachmentAttachment',
  name: 'attachment for document with single attachment',
  owner: {
    id: orderId,
    name: 'order with single attachment',
  },
  updatedAt: '2021-09-01T00:00:00Z',
}

attachments.add(orderWithSingleAttachmentAttachment, dummyBlob)

export const withSingleAttachment: SingleIncludedOrderResourceUnion = buildOrder({
  attributes: {
    salesOrderNumber: 'With single attachment',
    status: OrderStatus.Entered,
  },
  id: orderId,
  included: {
    deliveries: [],
    orderLines: [
      [
        buildOrderLine({
          id: 'orderLine1',
          included: { product: manyPricePoints.data },
          orderId: 'entered',
        }),
        manyPricePoints,
      ],
    ],
  },
})
