import React, { MouseEventHandler } from 'react'

import {
  Box,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react'

import { Currency } from '@brenntag/connect-apis/api/models/price'

import { Helper } from '#shared/components/Helper/Helper.js'
import { useSharedForwardedRef } from '#shared/hooks/dom/useSharedForwardedRef.js'
import {
  IconMail,
  IconPhone,
  IconSearch,
  IconSquareDelete,
  IconUser,
} from '#shared/icons/brand/index.js'

export interface InputProps extends ChakraInputProps {
  'aria-label'?: string
  'aria-labelledby'?: string
  currency?: Currency
  'data-testid'?: string
  error?: boolean
  helper?: React.ReactNode | string
  leftAddon?: React.ReactElement | string
  leftInsert?: React.ReactElement | string
  onEnterKeyPressed?: (value: string) => void
  onRightInsertClick?: MouseEventHandler
  onValueChange?: (value: string) => void
  rightAddon?: React.ReactElement | string
  rightInsert?: React.ReactElement | string
  showClearButton?: boolean
  type?: 'currency' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'user'
  value?: string | undefined
}

const iconProps = { boxSize: '24px' }
const sxInput = {
  '&:placeholder-shown': { textOverflow: 'ellipsis' },
}

// TODO: fix complexity
// eslint-disable-next-line complexity
export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    currency,
    error,
    helper,
    isDisabled,
    leftAddon,
    leftInsert,
    onChange,
    onEnterKeyPressed,
    onRightInsertClick,
    onValueChange,
    pattern,
    rightAddon,
    rightInsert,
    showClearButton,
    size = 'md',
    type = 'text',
    value,
    width = '100%',
    ...rest
  } = props

  const sharedRef = useSharedForwardedRef(ref)

  const onClear = () => {
    // Clear uncontrolled input
    if (sharedRef?.current && value === undefined) sharedRef.current.value = ''
    // and call onVChange (for both controlled and uncontrolled)
    onValueChange?.('')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const value = e.target.value

    if (value && pattern && type === 'text' && !new RegExp(pattern).test(value)) {
      return // input not allowed
    }

    onChange?.(e)
    onValueChange?.(value)
  }

  let fixedBugHeight = '40px'
  switch (size) {
    case 'lg':
      fixedBugHeight = '48px'
      break
    case 'sm':
      fixedBugHeight = '32px'
      break
    case 'xs':
      fixedBugHeight = '24px'
      break
    case 'md':
    default:
      fixedBugHeight = '40px'
  }

  const leftAddonComponent = leftAddon

  const leftElement =
    leftInsert ??
    (type === 'currency' && currency ? (
      currency
    ) : type === 'tel' ? (
      <IconPhone data-testid="icon-phone" {...iconProps} mx={2} />
    ) : type === 'email' ? (
      <IconMail data-testid="icon-mail" {...iconProps} mx={2} />
    ) : type === 'user' ? (
      <IconUser data-testid="icon-user" {...iconProps} mx={2} />
    ) : null)

  const rightElement =
    rightInsert ||
    (showClearButton && value?.length ? (
      <IconSquareDelete
        cursor="pointer"
        data-testid="icon-square-delete"
        onClick={onClear}
        {...iconProps}
      />
    ) : type === 'search' ? (
      <IconSearch
        color={error ? 'red.70' : 'primary'}
        data-testid="icon-search"
        {...iconProps}
        mx={2}
      />
    ) : null)

  const inputType = type === 'currency' || type === 'search' || type === 'user' ? 'text' : type

  return (
    <Box width={width}>
      <InputGroup>
        {leftElement ? (
          <InputLeftElement
            color={error ? 'red.50' : 'primary'}
            height={fixedBugHeight}
            pl={2}
            zIndex={2}>
            {leftElement}
          </InputLeftElement>
        ) : (
          leftAddonComponent && (
            <InputLeftAddon borderColor={isDisabled ? 'neutral.30' : 'primary'}>
              {leftAddonComponent}
            </InputLeftAddon>
          )
        )}
        <ChakraInput
          height={fixedBugHeight}
          isDisabled={isDisabled}
          isInvalid={error}
          onChange={handleChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              if (value) {
                onEnterKeyPressed?.(value)
              }
              e.preventDefault()
            }
          }}
          ref={sharedRef}
          size={size}
          sx={sxInput}
          type={inputType}
          value={value}
          variant="outline"
          zIndex="1"
          {...rest}
        />
        {rightElement ? (
          <InputRightElement
            color={error ? 'red.50' : 'primary'}
            height={fixedBugHeight}
            onClick={onRightInsertClick}
            pr={2}
            zIndex={2}>
            {rightElement}
          </InputRightElement>
        ) : (
          rightAddon && (
            <InputRightAddon
              background="indigo.5"
              borderColor={isDisabled ? 'neutral.30' : 'primary'}>
              {rightAddon}
            </InputRightAddon>
          )
        )}
      </InputGroup>
      {helper && (
        <Helper
          alignItems="center"
          color={error ? 'red.70' : 'neutral.80'}
          display="flex"
          error={error}
          fontSize="12px"
          fontWeight="400">
          {helper}
        </Helper>
      )}
    </Box>
  )
})

Input.displayName = 'Input'
