import * as React from 'react'
import type { SVGProps } from 'react'
const SvgParcelScan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m13 2.28-1-.33-7.34 2.44 3.16 1.06L12 4.05l1 .34zM15 2h1v6h-1zM18 2h-1v6h1zM19 2h1v6h-1zM21 2h1v6h-1z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M20 17.28V10h2v8.72l-10 3.33-10-3.33V5.61l10 3.34 2-.67v2.11l-1 .33v8.89zm-16 0 7 2.33v-8.89L4 8.39z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgParcelScan
