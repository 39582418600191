import { APICart } from '@brenntag/connect-apis/api-cart'
import { Cart, CartMessage } from '@brenntag/connect-apis/api-cart/models/cart'

export const transformMessageSummary = (apiCart: APICart): Cart['messageSummary'] => {
  if (!apiCart.messagesSummary) {
    return undefined
  }

  return {
    errorCount: apiCart.messagesSummary.errorCount,
    hasErrors: apiCart.messagesSummary.hasErrors,
    limitedMessages: apiCart.messagesSummary.limitedMessages?.map(
      (message): CartMessage => ({
        message: message.message,
        relatedToId: message.relatedToId,
        severity: message.severity,
        type: message.type,
        visible: message.visible,
      }),
    ),
    relatedEntityId: apiCart.messagesSummary.relatedEntityId,
    totalLineItemsWithErrors: apiCart.messagesSummary.totalLineItemsWithErrors,
  }
}
