import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconShop = createIcon({
  displayName: 'IconShop',
  path: [
    <path
      d="M17 6V2H7V6H3V22H21V6H17ZM19 20H5V8H7V11H9V8H11V6H9V4H15V6H13V8H15V11H17V8H19V20Z"
      fill="currentColor"
      key="0"
    />,
  ],
  viewBox: '0 0 24 24',
})
