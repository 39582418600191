import * as React from 'react'
import type { SVGProps } from 'react'
const SvgThumbsDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2 4h2v10H2zM8 22h4.41L14 20.41V17h8V6.59L19.41 4H6v2h12.59L20 7.41V15h-8v4.59l-.41.41H10v-3.3l-2-3V8H6v6.3l2 3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgThumbsDown
