import { http, HttpResponse } from 'msw'

import { CaseCommentResponse } from '@brenntag/connect-apis/api-case/types'
import { buildCaseComment } from '@brenntag/legacy-mock-generators/generators/cases/buildCaseComment.js'

import { config } from '#src/config.js'

import { cases } from './data/cases'

export const postCaseComment = () => {
  return http.post<{ id: string }, { comment: string }, CaseCommentResponse>(
    `${config.apiGatewayUrl}/cases/:id/comments`,
    async ({ params, request }) => {
      const body = await request.json()
      const comment = buildCaseComment({
        overrides: { comment: body.comment },
      })
      const caseItem = cases.items[params.id]
      caseItem.comments.push(comment)

      return HttpResponse.json({
        id: comment.id,
      })
    },
  )
}
