import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconContacts = createIcon({
  displayName: 'IconContacts',
  path: [
    <path d="M4 22H21V2H4V4H19V20H4V22Z" fill="currentColor" key="0" />,
    <path d="M3 15H6V17H3V15Z" fill="currentColor" key="1" />,
    <path d="M6 7H3V9H6V7Z" fill="currentColor" key="2" />,
    <path d="M3 11H6V13H3V11Z" fill="currentColor" key="3" />,
    <path
      clipRule="evenodd"
      d="M12 12C10.35 12 9 10.65 9 9C9 7.35 10.35 6 12 6C13.65 6 15 7.35 15 9C15 10.65 13.65 12 12 12ZM12 8C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10C12.55 10 13 9.55 13 9C13 8.45 12.55 8 12 8Z"
      fill="currentColor"
      fillRule="evenodd"
      key="4"
    />,
    <path d="M14 17H16V13H8V17H10V15H14V17Z" fill="currentColor" key="5" />,
  ],
  viewBox: '0 0 24 24',
})
