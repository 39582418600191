import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import {
  DataTypeUser,
  MeResponse,
  PhoneType,
  UserStatus,
  WebstoreResource,
} from '@brenntag/connect-apis/api-user/types'
import { SyncStatusEnum, UserAttributes, UserRole } from '@brenntag/connect-apis/api/types'

import { buildWebstore } from './buildWebstore'

interface Props {
  attributes?: Partial<UserAttributes>
  id?: string
  included?: { webstore: WebstoreResource }
}

export const buildUser = ({
  attributes = {},
  id = faker.string.uuid(),
  included,
}: Props = {}): MeResponse => {
  const firstName = attributes.firstName ?? faker.person.firstName()
  const lastName = attributes.lastName ?? faker.person.lastName()
  const name = attributes.name ?? `${firstName} ${lastName}`

  const webstore = included?.webstore || buildWebstore()

  return {
    data: {
      attributes: merge(
        {
          createdAt: faker.date.recent().toISOString(),
          email: faker.internet.email(),
          firstName,
          isActive: true,
          language: 'en_US',
          lastName,
          locale: 'en_US',
          name,
          phoneNumbers: [
            {
              isPrimary: true,
              number: faker.string.numeric(),
              type: PhoneType.Mobile,
            },
          ],
          role: UserRole.Customer,
          status: UserStatus.Active,
          syncStatus: SyncStatusEnum.Pending,
          timezone: 'America/North_Dakota/Beulah',
          updatedAt: faker.date.recent().toISOString(),
          username: faker.internet.email(),
        } satisfies UserAttributes,
        attributes,
      ),
      id,
      relationships: {
        webstore: {
          data: { id: webstore.id, type: webstore.type },
        },
      },
      type: DataTypeUser.User,
    },
    included: [webstore],
  }
}
