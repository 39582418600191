import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { CaseList } from '@brenntag/connect-apis/api-case/models/case'
import { ServiceList } from '@brenntag/connect-apis/api-vas/models/service'
import { InvoiceList } from '@brenntag/connect-apis/api/models/invoice'
import { OrderList } from '@brenntag/connect-apis/api/models/order'
import { ProductList } from '@brenntag/connect-apis/api/models/product'
import { DocumentList } from '@brenntag/connect-apis/api/models/productDocument'

import { fetchCases } from '#shared/apis/api-case/cases/endpoints/fetchCases.js'
import { fetchServices } from '#shared/apis/api-vas/endpoints/fetchServices.js'
import { fetchDocuments } from '#shared/apis/api/documents/endpoints/fetchDocuments.js'
import { fetchInvoices } from '#shared/apis/api/invoices/endpoints/fetchInvoices.js'
import { fetchOrders } from '#shared/apis/api/orders/endpoints/fetchOrders.js'
import { fetchProducts } from '#shared/apis/api/products/endpoints/fetchProducts.js'
import { SEARCH_MIN_CHARACTERS } from '#shared/constants.js'
import { useSelectedShipToLocation } from '#shared/hooks/globalState/useSelectedShipToLocation.js'
import { QueryCacheKey } from '#shared/utils/enums.js'

export interface UseSearchData {
  caseList: CaseList
  documentList: DocumentList
  invoiceList: InvoiceList
  orderList: OrderList
  productList: ProductList
  search: string | undefined
  serviceList: ServiceList
}

interface UseSearchParams {
  pageNumber: number
  pageSize: number
  search: string
}

export const useSearch = ({
  pageNumber,
  pageSize,
  search,
}: UseSearchParams): UseQueryResult<UseSearchData> => {
  const { shipToLocation } = useSelectedShipToLocation()
  const shipToLocationId = shipToLocation?.id

  return useQuery({
    enabled: Boolean(search && search.length >= SEARCH_MIN_CHARACTERS),
    queryFn: async () => {
      const noProducts: ProductList = {
        brands: [],
        categories: [],
        categoriesHash: '',
        packagingTypes: {},
        products: [],
        total: 0,
        totalPages: 0,
      }

      const searchProducts = fetchProducts({
        filters: {
          searchQuery: search,
          shipToLocationId: shipToLocationId!,
        },
        pageNumber,
        pageSize,
        sort: 'name',
      }).catch(() => noProducts)

      const noOrders: OrderList = { orders: [], total: 0, totalPages: 0 }
      const searchOrders = fetchOrders({
        filters: {
          searchQuery: search,
        },
        include: 'user,order_lines.product.customer_context_products',
        pageNumber,
        pageSize,
        sort: '-orderCreationDate',
      }).catch(() => noOrders)

      const noInvoices: InvoiceList = { invoices: [], total: 0, totalPages: 0 }
      const searchInvoices = fetchInvoices({
        filters: {
          searchQuery: search,
        },
        include: 'payment',
        pageNumber,
        pageSize,
        sort: '-invoiceDate',
      }).catch(() => noInvoices)

      const noDocuments: DocumentList = { documents: [], total: 0, totalPages: 0, types: [] }
      const searchDocuments = shipToLocationId
        ? fetchDocuments({
            filters: {
              searchQuery: search,
              shipToLocationId,
            },
            pageNumber,
            pageSize,
            sort: 'productName',
          }).catch(() => noDocuments)
        : Promise.resolve(noDocuments)

      const noCases: CaseList = {
        content: [],
        filters: [],
        page: {
          number: 0,
          size: 0,
          total: 0,
        },
        searchQuery: '',
        sort: '',
        sorts: [],
        totalPages: 0,
      }
      const searchCases = fetchCases({
        filters: [],
        pageNumber,
        pageSize,
        searchQuery: search,
        sort: '-createdDate',
      }).catch(() => noCases)

      const noServices: ServiceList = {
        content: [],
        filters: [],
        page: {
          number: 0,
          size: 0,
          total: 0,
        },
        searchQuery: '',
        sort: '',
        sorts: [],
        totalPages: 0,
      }
      const searchServices = fetchServices({
        filters: [],
        pageNumber,
        pageSize,
        searchQuery: search,
        sort: '-createdDate',
      }).catch(() => noServices)

      const [productList, orderList, invoiceList, documentList, caseList, serviceList] =
        await Promise.all([
          searchProducts,
          searchOrders,
          searchInvoices,
          searchDocuments,
          searchCases,
          searchServices,
        ])

      return {
        caseList,
        documentList,
        invoiceList,
        orderList,
        productList,
        search,
        serviceList,
      } satisfies UseSearchData
    },
    queryKey: [QueryCacheKey.Search, search, shipToLocationId],
  })
}
