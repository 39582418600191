import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconMenu = createIcon({
  displayName: 'IconMenu',
  path: [
    <path d="M21 6H3V8H21V6Z" fill="currentColor" key="0" />,
    <path d="M21 11H3V13H21V11Z" fill="currentColor" key="1" />,
    <path d="M3 16H21V18H3V16Z" fill="currentColor" key="2" />,
  ],
  viewBox: '0 0 24 24',
})
