import { ComponentStyleConfig } from '@chakra-ui/react'

const borderColor = 'neutral.20'

export const Popover: ComponentStyleConfig = {
  baseStyle: {
    arrow: {
      borderColor,
      boxShadow: 'md',
    },
    body: {
      px: 5,
      py: 4,
    },
    closeButton: {
      color: 'primary',
      insetEnd: 5,
      position: 'absolute',
      top: 4,
    },
    content: {
      border: '1px solid',
      borderColor,
      boxShadow: 'md',
    },
    footer: {
      px: 5,
      py: 4,
    },
    header: {
      border: 0,
      fontSize: '2xl',
      fontWeight: 'bold',
      lineHeight: 'normal',
      pb: 0,
      pt: 4,
      px: 5,
    },
  },
  sizes: {
    '2xl': { popper: { maxW: '2xl' } },
    '2xs': { popper: { maxW: '2xs' } },
    '3xl': { popper: { maxW: '3xl' } },
    '3xs': { popper: { maxW: '3xs' } },
    '4xl': { popper: { maxW: '4xl' } },
    '5xl': { popper: { maxW: '5xl' } },
    '6xl': { popper: { maxW: '6xl' } },
    lg: { popper: { maxW: 'lg' } },
    md: { popper: { maxW: 'md' } },
    sm: { popper: { maxW: 'sm' } },
    xl: { popper: { maxW: 'xl' } },
    xs: { popper: { maxW: 'xs' } },
  },
}
