import { faker } from '@faker-js/faker'
import { merge } from 'lodash'

import { CaseGetCaseResponseComments } from '@brenntag/connect-apis/api-case/types'

interface Props {
  overrides?: Partial<CaseGetCaseResponseComments>
}

export const buildCaseComment = ({ overrides }: Props): CaseGetCaseResponseComments => {
  return merge(
    {
      comment: faker.lorem.sentence(),
      createdByName: faker.person.firstName() + ' ' + faker.person.lastName(),
      createdDate: faker.date.past().toISOString(),
      id: faker.string.uuid(),
    } satisfies CaseGetCaseResponseComments,
    overrides,
  )
}
