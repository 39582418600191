import { CustomerShipToLocation } from './location'
import { Amount } from './price'
import { Product } from './product'
import { User } from './user'

export type SampleFilterKey = keyof SampleFilters

export interface SampleFilters {
  deliveryDateGreaterEqual?: string
  deliveryDateLessEqual?: string
  page?: string
  searchQuery?: string
  shipToLocationId?: string
  sort?: string
  status?: string
}

export const SampleFilter = {
  deliveryDateGreaterEqual: 'filter[requestedDate.gte]',
  deliveryDateLessEqual: 'filter[requestedDate.lte]',
  searchQuery: 'search[query]',
  shipToLocationId: 'filter[shipTo.id.equals]',
  sort: 'sort',
  status: 'filter[status.in]',
}

export interface Sample {
  id: string
  number: string
  product: Product
  requestedDate: string | undefined
  requestedDeliveryDate: string | undefined
  requestQuantity: Amount | undefined
  shipTo: CustomerShipToLocation | undefined
  status: SampleStatus
  user: User
}

export interface SampleList {
  samples: Sample[]
  total: number
  totalPages: number
}

export type SampleStatus = 'In Process' | 'New' | 'Requested' | 'Shipped'
