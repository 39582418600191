import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInbound = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M18 20v-8h2v10H2V11.59l9-9 4.09 4.08-1.42 1.42L11 5.41l-7 7V20z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M20.295 4.291 11 13.586V10H9v7h7v-2h-3.585l9.295-9.295zM11 15v-.007l.007.007z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgInbound
