import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconArrowLeft = createIcon({
  displayName: 'IconArrowLeft',
  path: [
    <path
      d="M1.59 12L10.29 20.71L11.71 19.29L4.41 12L11.71 4.70999L10.29 3.28999L1.59 12Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M8.23999 13L7.23999 12L8.23999 11H22V13H8.23999Z" fill="currentColor" key="1" />,
  ],
  viewBox: '0 0 24 24',
})
