import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUserCsr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.7 11.992H14v10h8v-7.7zm.3 8h-4v-6h2v2h2zM5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.5 2.5 0 0 0 10 3.991a2.503 2.503 0 0 0-2.5 2.5c0 1.379 1.122 2.5 2.5 2.5s2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5a4.505 4.505 0 0 1-4.5-4.5m6.5 11.5v2H3v-8h5v2H5v4z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUserCsr
