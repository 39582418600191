import { OrderStatus as OrderStatusType } from '@brenntag/connect-apis/api/models/order'

import { StatusStyle } from '#shared/components/StatusPill/StatusPill.js'
import { Translate } from '#shared/locale/hooks/useTranslations.js'

import { StatusStyle as StatusStyle2 } from './types.js'

export const getOrderStatusStyles = (t: Translate, status?: OrderStatusType): StatusStyle => {
  switch (status) {
    case 'Confirmed':
      return {
        color: 'indigo.50',
        icon: 'IconShipments',
        text: t('order.status.Confirmed'),
      }
    case 'Delivered/Invoiced':
      return {
        color: 'primary',
        icon: 'IconFlag',
        text: t('order.status.Delivered/Invoiced'),
      }
    case 'Entered':
      return {
        color: 'blue.50',
        icon: 'IconCart',
        text: t('order.status.Entered'),
      }
    case 'Processing':
      return {
        color: 'blue.70',
        icon: 'IconChange',
        text: t('order.status.Processing'),
      }
    case 'Picked':
    default:
      return {
        color: 'violet.50',
        icon: 'IconParcelCheck',
        text: t('order.status.Picked'),
      }
  }
}

/* TODO remove above getOrderStatusStyles when we replace status pills in:
  - src/shared/components/GlobalSearch/SearchResults/OrderSearchResult.tsx
  - src/shared/components/OrderInvoiceSearch/OrderSearchResultItem.tsx
  */

export const getOrderStatusStylesV2 = (t: Translate, status?: OrderStatusType): StatusStyle2 => {
  switch (status) {
    case 'Confirmed':
      return {
        icon: 'Shipments',
        name: 'success',
        text: t('order.status.Confirmed'),
        type: 'Confirmed',
      }
    case 'Delivered/Invoiced':
      return {
        icon: 'Flag',
        name: 'neutral',
        text: t('order.status.Delivered/Invoiced'),
        type: 'Delivered/Invoiced',
      }
    case 'Entered':
      return {
        icon: 'Cart',
        name: 'info',
        text: t('order.status.Entered'),
        type: 'Entered',
      }
    case 'Processing':
      return {
        icon: 'Change',
        name: 'info',
        text: t('order.status.Processing'),
        type: 'Processing',
      }
    case 'Picked':
    default:
      return {
        icon: 'ParcelCheck',
        name: 'info',
        text: t('order.status.Picked'),
        type: 'Picked',
      }
  }
}
