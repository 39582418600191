import { consola } from 'consola/browser'

import { getAccessTokenSilently } from './lib/getAccessTokenSilently'
import { salesforceSession } from './salesforceSession'

/**
 * Acquires Salesforce tokens and stores them using Jotai so components can "react" to the changed session state.
 * @param params
 */
export const startSession = async (state: Record<string, unknown>) => {
  salesforceSession.setIsAuthenticating(true)

  getAccessTokenSilently(state).catch(error => {
    consola.error('Error starting Salesforce session:', error)
  })
}
