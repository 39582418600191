import React from 'react'

import { createIcon } from '@chakra-ui/react'
export const IconWarehouse = createIcon({
  displayName: 'IconWarehouse',
  path: [
    <path
      d="M20 20.9998H22V8.4598L12 1.7998L2 8.4598V20.9998H4V9.5398L12 4.1998L20 9.5398V20.9998Z"
      fill="currentColor"
      key="0"
    />,
    <path d="M18 21H16V13H8V21H6V11H18V21Z" fill="currentColor" key="1" />,
    <path
      d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
      fill="currentColor"
      key="2"
    />,
    <path
      d="M13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z"
      fill="currentColor"
      key="3"
    />,
  ],
  viewBox: '0 0 24 24',
})
