/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    Cypress: any
    embedded_svc: any
    isMockEnabled: boolean
  }
}

export const setPageTitle = (title: string | undefined) =>
  title ? (document.title = `${title} | Brenntag Connect`) : undefined
