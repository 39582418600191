import React from 'react'

import { Text } from '@chakra-ui/react'

interface Props {
  children?: React.ReactNode
}

export const LargeHeading = ({ children }: Props) => {
  return (
    <Text fontSize="16px" fontWeight="bold">
      {children}
    </Text>
  )
}
