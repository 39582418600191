import { APICart } from '@brenntag/connect-apis/api-cart'
import { ProductList } from '@brenntag/connect-apis/api/models/product'

import { fetchProducts } from '#shared/apis/api/products/endpoints/fetchProducts.js'

interface Params {
  apiCart: APICart
  shipToLocationId: string
}

export const fetchCartProducts = ({ apiCart, shipToLocationId }: Params): Promise<ProductList> => {
  const cartProductIds = apiCart?.items?.map(item => item.productId)

  if (!cartProductIds?.length) {
    return Promise.resolve({
      brands: [],
      categories: [],
      categoriesHash: '',
      packagingTypes: {},
      products: [],
      total: 0,
      totalPages: 0,
    })
  }

  return fetchProducts({
    filters: {
      productIds: cartProductIds,
      shipToLocationId,
    },
    pageNumber: 1,
    pageSize: cartProductIds?.length || 1,
    sort: 'name',
  })
}
