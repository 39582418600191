import { useMemo } from 'react'

import { useAtomValue } from 'jotai'

import { endSession } from './endSession'
import { salesforceSessionAtom } from './salesforceSession'
import { startSession } from './startSession'

export type SalesforceSession = {
  accessToken?: string
  endSession: () => void
  idToken?: string
  isAuthenticated: boolean
  isAuthenticating: boolean
  isInternal: boolean
  startSession: (state: Record<string, unknown>) => Promise<void>
}

export const useSalesforceSession = (): SalesforceSession => {
  const { accessToken, idToken, isAuthenticating, isInternal } = useAtomValue(salesforceSessionAtom)
  const isAuthenticated = useMemo(() => Boolean(accessToken && idToken), [accessToken, idToken])

  return {
    accessToken,
    endSession,
    idToken,
    isAuthenticated,
    isAuthenticating,
    isInternal,
    startSession,
  }
}
