import { ofetch } from 'ofetch'

import { config } from '#config'
import { Webstore } from '#types/webstore.js'

export type FetchWebstoresResponse = { data: Webstore[]; meta: { count: number } }

export const fetchWebstores = async (): Promise<FetchWebstoresResponse> => {
  const { getBaseUrl } = config

  return await ofetch<FetchWebstoresResponse>(`${getBaseUrl()}/customer/webstores`)
}
