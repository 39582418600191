import React, { FC, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { Button } from '@brenntag/ui/components/Button'
import { Icon } from '@brenntag/ui/components/Icon'

import { useSession } from '#lib/auth/useSession.js'
import genericError from '#shared/assets/errors/generic-error.svg'
import { useKeyPress } from '#shared/hooks/dom/useKeyPress.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { setPageTitle } from '#shared/utils.js'
import { isEnvironment } from '#src/env.js'

let showErrorDetails = false

interface ErrorPageProps {
  children?: React.ReactNode
  contactUsText?: string
  'data-testid'?: string
  heading?: string
  subtitle?: string
  title?: string
}

interface UntranslatedErrorPageProps extends ErrorPageProps {
  translations: {
    'label.contactus': string
    'label.error': string
    'label.error.subtitle1': string
    'label.iftheproblempersists': string
    'label.somethingwentwrong': string
  }
}

export const UntranslatedErrorPage: React.FC<UntranslatedErrorPageProps> = ({
  children,
  translations: t,
  ...rest
}) => {
  setPageTitle(t['label.error'])

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <div
        className="flex w-full max-w-[800px] flex-col items-center justify-center"
        data-testid={rest['data-testid'] || 'page-error'}>
        <img className="mb-4 size-4/5" src={genericError} />
        <h1 className="mb-2 text-lg font-bold">{t['label.somethingwentwrong']}</h1>
        <p>{t['label.error.subtitle1']}</p>
        <p className="mb-2 text-center">
          {t['label.iftheproblempersists']}
          {', '} {t['label.contactus']} via{' '}
          <a className="font-semibold text-blue-50" href="mailto:connect@brenntag.com">
            connect@brenntag.com
          </a>
          .
        </p>
        {children}
      </div>
    </div>
  )
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  children,
  contactUsText,
  heading,
  subtitle,
  title,
  ...rest
}) => {
  const t = useTranslations()
  return (
    <UntranslatedErrorPage
      data-testid={rest['data-testid']}
      heading={heading}
      subtitle={subtitle}
      translations={{
        'label.contactus': t('label.contactus'),
        'label.error': t('label.error'),
        'label.error.subtitle1': subtitle !== undefined ? subtitle : t('label.error.subtitle1'),
        'label.iftheproblempersists': contactUsText || t('label.iftheproblempersists'),
        'label.somethingwentwrong': title || t('label.somethingwentwrong'),
      }}>
      {children}
    </UntranslatedErrorPage>
  )
}

interface MonospacedTextProps {
  children?: React.ReactNode
  title: string
}

const MonospacedText: FC<MonospacedTextProps> = ({ children, title }) => {
  return (
    <>
      <h2 className="text-lg">{title}</h2>
      <p className="mb-2 whitespace-pre-wrap text-xs">{children}</p>
    </>
  )
}

interface ErrorPageContentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
  errorCode?: number
  errorInfo?: null | React.ErrorInfo
  reason: string
}
interface UntranslatedErrorPageContentProps extends ErrorPageContentProps {
  translations: {
    'label.errorcode': string
    'label.reason': string
    'label.reloadpage': string
    'label.unauthorized': string
  }
}

export const UntranslatedErrorPageContent: FC<UntranslatedErrorPageContentProps> = ({
  error,
  errorCode,
  errorInfo,
  reason,
  translations: t,
}) => {
  const { isAuthenticated } = useAuth0()
  const { logout } = useSession()
  const pressed = useKeyPress({ ctrlKey: true, key: 'E', shiftKey: true })

  useEffect(() => {
    if (!pressed) return
    showErrorDetails = !showErrorDetails
  }, [pressed])

  const showReloadButton = errorCode !== 401
  const showLogoutButton = errorCode === 401 && isAuthenticated

  return (
    <>
      {reason.match(/timeout/i) ? (
        <p className="mb-4 text-neutral-50">{`${t['label.reason']}: ${reason}`}</p>
      ) : (
        errorCode && (
          <p className="mb-4 text-neutral-50">{`${t['label.errorcode']}: ${errorCode || 500}`}</p>
        )
      )}
      {showReloadButton && (
        <Button onClick={window.location.reload} variant="solid">
          <Icon name="Reload" />
          {t['label.reloadpage']}
        </Button>
      )}
      {showLogoutButton && (
        <Button onClick={logout} variant={'solid'}>
          {t['label.unauthorized']}
        </Button>
      )}
      {error && ((!isEnvironment('production') && showErrorDetails) || isEnvironment('local')) && (
        <div className="w-[90%] flex-col items-start gap-4">
          {error.message && (
            <MonospacedText title="Message">
              {JSON.stringify(error.message, null, 2).replace(/\\n/g, '\n')}
            </MonospacedText>
          )}
          {error.stack && (
            <MonospacedText title="Stack">
              {JSON.stringify(error.stack, null, 2).replace(/\\n/g, '\n')}
            </MonospacedText>
          )}
          {JSON.stringify(error) !== '{}' && (
            <MonospacedText title="Meta data">
              {JSON.stringify(error, null, 2).replace(/\\n/g, '\n')}
            </MonospacedText>
          )}
          {Boolean(error.data) && (
            <MonospacedText title="Message">
              {JSON.stringify(error.data, null, 2).replace(/\\n/g, '\n')}
            </MonospacedText>
          )}
          {errorInfo?.componentStack && (
            <MonospacedText title="Component Stack">
              {JSON.stringify(errorInfo.componentStack, null, 2).replace(/\\n/g, '\n')}
            </MonospacedText>
          )}
        </div>
      )}
    </>
  )
}

export const ErrorPageContent: FC<ErrorPageContentProps> = ({
  error,
  errorCode,
  errorInfo,
  reason,
}) => {
  const t = useTranslations()
  return (
    <UntranslatedErrorPageContent
      error={error}
      errorCode={errorCode}
      errorInfo={errorInfo}
      reason={reason}
      translations={{
        'label.errorcode': t('label.errorcode'),
        'label.reason': t('label.reason'),
        'label.reloadpage': t('label.reloadpage'),
        'label.unauthorized': 'Restart session',
      }}
    />
  )
}
