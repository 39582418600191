import React, { useState } from 'react'

import DebugPanel from '#components/DebugPanel/DebugPanel.js'

import { InfoWidget } from './InfoWidget'

const EnvironmentInfo = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="fixed bottom-4 right-20 z-20">
      <button onClick={() => setIsOpen(!isOpen)}>
        <InfoWidget />
      </button>
      <DebugPanel isOpen={isOpen} onOpenChange={() => setIsOpen(!isOpen)} />
    </div>
  )
}

export default EnvironmentInfo
