import React, { FC } from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { CountryLanguageSelectorOption } from '#shared/components/CountryLanguageSelector/types/types.js'
import { IconByName } from '#shared/icons/brand/index.js'
import { translateLanguageInLanguage } from '#shared/locale/hooks/useIntl.js'

interface Props {
  isActive?: boolean
  onOptionSelect?: (selected: CountryLanguageSelectorOption) => void
  option: CountryLanguageSelectorOption
}

export const Option: FC<Props> = ({ isActive, onOptionSelect, option }) => {
  const translatedLanguage = translateLanguageInLanguage({
    fromLanguageCode: option.languageCode,
    toLanguageCode: option.languageCode,
  })

  return (
    <Flex alignItems="center">
      {isActive && <IconByName boxSize={6} color="blue.50" mr={1} name="IconCircleCheckmark" />}
      <a
        href={`/${option.countryCode}-${option.languageCode}`}
        onClick={e => {
          if (!onOptionSelect) return

          e.preventDefault()
          onOptionSelect(option)
        }}>
        <Text borderBottom="2px solid" borderColor="blue.50" color="blue.50" fontWeight={600}>
          {translatedLanguage} ({option.languageCode})
        </Text>
      </a>
    </Flex>
  )
}
