import * as React from 'react'
import type { SVGProps } from 'react'
const SvgServer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M3 21h18v-6h-2v4H5v-6h16V3H3v6h2V5h14v6H3z" fill="currentColor" />
    <path
      d="M8 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2M8 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
      fill="currentColor"
    />
  </svg>
)
export default SvgServer
