import React, { useEffect, useState } from 'react'

import { Box, Center, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useLocation, useMatch } from 'react-router'

import { ScrollingTabList } from '#shared/components/GlobalSearch/ScrollingTabList.js'
import { CaseSearchResult } from '#shared/components/GlobalSearch/SearchResults/CaseSearchResult.js'
import { DocumentSearchResult } from '#shared/components/GlobalSearch/SearchResults/DocumentSearchResult.js'
import { InvoiceSearchResult } from '#shared/components/GlobalSearch/SearchResults/InvoiceSearchResult.js'
import { OrderSearchResult } from '#shared/components/GlobalSearch/SearchResults/OrderSearchResult.js'
import { ProductSearchResult } from '#shared/components/GlobalSearch/SearchResults/ProductSearchResult.js'
import { ServiceSearchResult } from '#shared/components/GlobalSearch/SearchResults/ServiceSearchResult.js'
import { TabWithCount } from '#shared/components/GlobalSearch/TabWithCount.js'
import { LoaderRound } from '#shared/components/Loader/LoaderRound.js'
import { NoResultsMessage } from '#shared/components/NoResultsMessage/NoResultsMessage.js'
import { UseSearchData } from '#shared/hooks/search/useSearch.js'
import { useSearchQuery } from '#shared/hooks/search/useSearchQuery.js'
import { useTranslations } from '#shared/locale/hooks/useTranslations.js'

interface SearchResultsProps {
  data?: UseSearchData
  isFetching: boolean
  onClick: VoidFunction
  search: string
}

export const SearchResults = ({ data, isFetching, onClick, search }: SearchResultsProps) => {
  const t = useTranslations()
  const [tabIndex, setTabIndex] = useState(0)
  const location = useLocation()
  const { updateSearchQuery: onSearchQueryChange } = useSearchQuery()
  const matches = useMatch(location.pathname)

  const onItemClick = () => {
    onSearchQueryChange('')
    onClick()
  }

  const onViewMoreClick = () => {
    onSearchQueryChange(search)
    onClick()
  }

  useEffect(() => {
    if (data) {
      const { caseList, documentList, invoiceList, orderList, productList, serviceList } = data
      const caseTotal = caseList.page?.total ?? 0
      const serviceTotal = serviceList.page?.total ?? 0
      const tabEnabled = ([] as boolean[])
        .concat(productList.total > 0)
        .concat(orderList.total > 0)
        .concat(invoiceList.total > 0)
        .concat(documentList.total > 0)
        .concat(caseTotal > 0)
        .concat(serviceTotal > 0)

      const match = matches?.pathname.split('/')[1] || ''
      const tabActive = ([] as boolean[])
        .concat(match.includes('products'))
        .concat(match.includes('orders'))
        .concat(match.includes('invoices'))
        .concat(match.includes('documents'))
        .concat(match.includes('cases'))
        .concat(match.includes('services'))

      const firstEnabledTab = tabEnabled.indexOf(true)
      const firstActiveTab = tabActive.indexOf(true)

      if (firstActiveTab !== -1 && tabEnabled[firstActiveTab]) {
        setTabIndex(firstActiveTab)
      } else if (firstEnabledTab !== -1) {
        setTabIndex(firstEnabledTab)
      }
    }
  }, [data, matches?.pathname])

  if (!data || isFetching) {
    return (
      <Center p={5}>
        <LoaderRound display="inline" />
      </Center>
    )
  }
  const { caseList, documentList, invoiceList, orderList, productList, serviceList } = data
  const caseTotal = caseList.page?.total ?? 0
  const serviceTotal = serviceList.page?.total ?? 0
  if (
    productList.total +
      orderList.total +
      invoiceList.total +
      caseTotal +
      documentList.total +
      serviceTotal ===
    0
  ) {
    return (
      <Box p={5}>
        <NoResultsMessage />
      </Box>
    )
  }

  return (
    <Tabs index={tabIndex} onChange={setTabIndex} position="relative" size="sm" variant="gradient">
      <ScrollingTabList controlsOffsetY="7px" flexWrap="nowrap" width="100%">
        <TabWithCount
          count={productList.total}
          data-testid="products-search-result-tab"
          height="56px">
          {t('page.ProductList')}
        </TabWithCount>
        <TabWithCount count={orderList.total} data-testid="orders-search-result-tab" height="56px">
          {t('page.OrderList')}
        </TabWithCount>
        <TabWithCount
          count={invoiceList.total}
          data-testid="invoices-search-result-tab"
          height="56px">
          {t('page.InvoiceList')}
        </TabWithCount>
        <TabWithCount
          count={documentList.total}
          data-testid="documents-search-result-tab"
          height="56px">
          {t('page.DocumentList')}
        </TabWithCount>
        <TabWithCount count={caseTotal} data-testid="cases-search-result-tab" height="56px">
          {t('page.CaseList')}
        </TabWithCount>
        <TabWithCount count={serviceTotal} data-testid="services-search-result-tab" height="56px">
          {t('page.ServiceList')}
        </TabWithCount>
      </ScrollingTabList>
      <TabPanels overflow="hidden" px={2}>
        <TabPanel p={0} tabIndex={-1}>
          <ProductSearchResult
            onItemClick={onItemClick}
            onViewMoreClick={onViewMoreClick}
            productList={productList}
            search={search}
          />
        </TabPanel>

        <TabPanel p={0} tabIndex={-1}>
          <OrderSearchResult
            onItemClick={onItemClick}
            onViewMoreClick={onViewMoreClick}
            orderList={orderList}
            search={search}
          />
        </TabPanel>

        <TabPanel p={0} tabIndex={-1}>
          <InvoiceSearchResult
            invoiceList={invoiceList}
            onItemClick={onItemClick}
            onViewMoreClick={onViewMoreClick}
            search={search}
          />
        </TabPanel>

        <TabPanel p={0} tabIndex={-1}>
          <DocumentSearchResult
            documentList={documentList}
            onViewMoreClick={onViewMoreClick}
            search={search}
          />
        </TabPanel>

        <TabPanel p={0} tabIndex={-1}>
          <CaseSearchResult
            caseList={caseList}
            onItemClick={onItemClick}
            onViewMoreClick={onViewMoreClick}
            search={search}
          />
        </TabPanel>

        <TabPanel p={0} tabIndex={-1}>
          <ServiceSearchResult
            onItemClick={onItemClick}
            onViewMoreClick={onViewMoreClick}
            search={search}
            serviceList={serviceList}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
