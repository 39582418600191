import { SalesGroup } from '@brenntag/api-customer'

export const salesGroup = {
  anotherSalesgroup: {
    id: 'another-salesgroup',
    name: 'Another Salesgroup',
    organizationCode: 'AnotherSalesgroupCode',
    shortName: 'ASG',
  },
  generic: {
    id: 'generic',
    name: 'Generic',
    organizationCode: 'GenericCode',
    shortName: 'BGL',
  },
} satisfies Record<string, SalesGroup>

export const salesGroups = {
  basic: [salesGroup.generic],
  manyCustomers: [salesGroup.generic, salesGroup.anotherSalesgroup],
} satisfies Record<string, SalesGroup[]>
