import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.5 4c-.77 0-1.48.26-2.05.68-.26-.63-.6-1.21-1.01-1.75.88-.59 1.93-.93 3.06-.93C19.53 2 22 4.47 22 7.5V8c0 2.2-.86 4.27-2.41 5.83L12 21.42l-7.59-7.59A8.18 8.18 0 0 1 2 8v-.5C2 4.47 4.47 2 7.5 2S13 4.47 13 7.5V9h-2V7.5C11 5.57 9.43 4 7.5 4S4 5.57 4 7.5V8c0 1.67.65 3.24 1.83 4.41L12 18.58l6.17-6.17A6.2 6.2 0 0 0 20 8v-.5C20 5.57 18.43 4 16.5 4"
      fill="currentColor"
    />
  </svg>
)
export default SvgHeart
