import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUserCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.5 7.5c0-.32-.07-.63-.18-.91l1.48-1.48c.44.7.7 1.51.7 2.39 0 2.48-2.02 4.5-4.5 4.5S5.5 9.98 5.5 7.5 7.52 3 10 3c.88 0 1.7.26 2.39.7l-1.48 1.48A2.5 2.5 0 0 0 10 5a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 2.5-2.5M18.5 8.91l-2.71-2.7 1.42-1.42 1.29 1.3 2.79-2.8 1.42 1.42zM18 12v9H2v-9h4v2H4v5h12v-5h-2v-2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgUserCheck
