import React from 'react'

import { useTranslations } from '#shared/locale/hooks/useTranslations.js'
import { setPageTitle } from '#shared/utils.js'

export const AccessRestricted: React.FC = () => {
  const t = useTranslations()
  setPageTitle(t('label.accessdenied'))
  return (
    <div className="flex grow flex-col items-center justify-center">
      <h1 className="mb-6 text-2xl font-bold" data-testid="page-header">
        {t('page.AccessRestricted')}
      </h1>
      <p className="mb-5 whitespace-pre-line text-wrap text-center text-sm text-neutral-90">
        {t('message.accessrestricted')}
      </p>
    </div>
  )
}
