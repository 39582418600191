import { LinkAttachment } from '@brenntag/connect-apis/api-attachment/models/attachment'
import { LinkedAttachment } from '@brenntag/connect-apis/api-attachment/types'
import {
  CaseComment,
  CaseCommentType,
  TimelineAttachment,
  TimelineComment,
  TimelineItem,
} from '@brenntag/connect-apis/api-case/models/case'
import { CaseGetCaseResponseComments } from '@brenntag/connect-apis/api-case/types'
import { Attachment } from '@brenntag/connect-apis/api-object/models/attachment'

import { groupWith } from '#shared/utils/object-utils.js'

const transformAPICaseCommentToCaseComment = (
  apiComment: CaseGetCaseResponseComments,
): CaseComment => {
  return {
    comment: apiComment.comment,
    createdByName: apiComment.createdByName,
    createdDate: apiComment.createdDate,
    id: apiComment.id,
  }
}

const transformAPIAttachmentToAttachment = (apiAttachment: Attachment): Attachment => ({
  createdAt: apiAttachment.createdAt ?? '',
  filename: apiAttachment.filename ?? '',
  id: apiAttachment.id,
  ownerName: apiAttachment.ownerName,
  updatedAt: apiAttachment.updatedAt ?? '',
  uploadedAt: apiAttachment.uploadedAt ?? '',
  url: apiAttachment.url,
})
export const transformLinkedAPIAttachment = (apiAttachment: LinkedAttachment): LinkAttachment => ({
  contentSize: apiAttachment.contentSize,
  createdAt: apiAttachment.createdAt ?? '',
  id: apiAttachment.id,
  name: apiAttachment.name ?? '',
  ownerName: apiAttachment.owner.name ?? '',
  updatedAt: apiAttachment.updatedAt ?? '',
  uploadedAt: apiAttachment.createdAt ?? '',
})

export const transformLinkedAttachmentToAttachment = (
  apiAttachment: LinkAttachment,
): Attachment => ({
  createdAt: apiAttachment.createdAt ?? '',
  filename: apiAttachment.name ?? '',
  id: apiAttachment.id,
  ownerName: apiAttachment.ownerName,
  size: apiAttachment.contentSize,
  updatedAt: apiAttachment.updatedAt ?? '',
  uploadedAt: apiAttachment.createdAt ?? '',
})

export const transformAPIAttachmentsToAttachments = (
  apiAttachments: Attachment[] = [],
): Attachment[] => apiAttachments.map(transformAPIAttachmentToAttachment)

export const transformAPIAttachmentsAPICommentsToCaseTimeline = (
  apiAttachment: LinkAttachment[] = [],
  apiCaseComment: CaseGetCaseResponseComments[] = [],
): TimelineItem[] => {
  const attachments: Attachment[] = apiAttachment.map(transformLinkedAttachmentToAttachment)
  const sortableAttachments = attachments.map(attachment => ({
    ...attachment,
    timeToConsider: attachment.uploadedAt || new Date().toISOString(),
  }))
  const sortedAttachments = sortableAttachments.sort(
    (a, b) => new Date(b.timeToConsider).getTime() - new Date(a.timeToConsider).getTime(),
  )

  const comments: CaseComment[] = apiCaseComment.map(transformAPICaseCommentToCaseComment)
  const newComments: TimelineComment[] = comments.map(comment => ({
    ...comment,
    timeToConsider: comment.createdDate ? comment.createdDate : new Date().toISOString(),
    type: CaseCommentType.Comment,
  }))
  const groupedAttachments = groupWith(
    (a, b) => new Date(a.uploadedAt).getMinutes() === new Date(b.uploadedAt).getMinutes(),
    sortedAttachments,
  )
  const groupedSortableAttachments: TimelineAttachment[] = groupedAttachments.map(attachments => ({
    attachments,
    timeToConsider: attachments[0].uploadedAt || new Date().toISOString(),
    type: CaseCommentType.Attachment,
  }))
  const timeline: (TimelineAttachment | TimelineComment)[] = [
    ...groupedSortableAttachments,
    ...newComments,
  ].sort((a, b) => new Date(b.timeToConsider).getTime() - new Date(a.timeToConsider).getTime())
  return timeline
}
