import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFilters = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6 11h2V5H6v2H1v2h5zM10 7h13v2H10zM1 15h13v2H1zM16 13h2v2h5v2h-5v2h-2z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFilters
