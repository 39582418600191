import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTouchpoint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M13 3v2h6v6h2V3zM11 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M11 8c1.01 0 1.96.31 2.75.83L15.58 7h1.41v1.41l-1.83 1.83c.52.79.83 1.73.83 2.75 0 2.76-2.24 5-5 5-1.01 0-1.96-.31-2.75-.83L3.7 21.7l-1.41-1.41 4.54-4.54C6.31 14.96 6 14.02 6 13c0-2.76 2.24-5 5-5m-3 5c0 1.65 1.35 3 3 3s3-1.35 3-3-1.35-3-3-3-3 1.35-3 3"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgTouchpoint
