import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHappy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m18.31 7.11 1.43-1.43A9.94 9.94 0 0 1 22 12c0 5.51-4.49 10-10 10S2 17.51 2 12 6.49 2 12.01 2c2.39 0 4.6.85 6.32 2.26L16.9 5.69A7.92 7.92 0 0 0 12 4c-4.41 0-8 3.6-8 8.01s3.59 8 8 8 8-3.59 8-8c0-1.85-.64-3.54-1.69-4.9"
      fill="currentColor"
    />
    <path
      d="M8.29 10.71c.19.19.44.29.71.29a.99.99 0 0 0 1-1c0-.27-.11-.52-.29-.71-.28-.28-.73-.37-1.09-.21q-.195.075-.33.21c-.18.19-.29.44-.29.71s.1.52.29.71M14.62 10.92a.995.995 0 0 0 1.09-.21c.19-.19.29-.45.29-.71s-.11-.52-.29-.71a1 1 0 0 0-1.09-.21c-.13.05-.23.12-.33.21-.18.19-.29.44-.29.71s.1.52.29.71q.15.135.33.21M12 17c-1.6 0-3.11-.62-4.24-1.76l-.51-.59 1.51-1.31.46.54c.71.7 1.71 1.12 2.78 1.12s2.07-.42 2.83-1.17l.42-.48 1.51 1.31-.46.54c-1.18 1.19-2.69 1.81-4.29 1.81z"
      fill="currentColor"
    />
  </svg>
)
export default SvgHappy
