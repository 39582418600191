import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrucks = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.54 5H16V3H2v2h12v3H2v2h12v7H8.22c-.55-.61-1.34-1-2.22-1-.77 0-1.47.3-2 .78V12H2v7h1c0 1.65 1.35 3 3 3s3-1.35 3-3h6c0 1.65 1.35 3 3 3s3-1.35 3-3h1V8.7zM6 20c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m12 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m2-3.22c-.53-.48-1.23-.78-2-.78s-1.47.3-2 .78V12h2.46L20 14.3zM19.54 10H16V7h2.46L20 9.3v1.39l-.46-.7z"
      fill="currentColor"
    />
  </svg>
)
export default SvgTrucks
